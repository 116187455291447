export const matchesSearchTerm = (text: string, searchTerm: string): boolean =>
{
	const tokens = searchTerm.split(' ');
	const textNormalized = text.toLowerCase();

	for (const token of tokens)
	{
		if (!textNormalized.includes(token.toLowerCase()))
			return false
	}

	return true;
};
