import React from 'react';
import AnimatedGraphic from './animated-graphic.js'
import GlueOSConfirmationAnimData from '../../animation/GlueOS_Confirm.json';

const ConfirmationIndicator = (props) => {
	return (
		<AnimatedGraphic data={GlueOSConfirmationAnimData} loop={props.loop} onComplete={props.onComplete}/>
	);
};

export default ConfirmationIndicator;
