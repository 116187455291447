import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import GlueDrawer from './common/glue-drawer';
import CloseButton from './common/close-button';
import GlueDropdown from './common/glue-dropdown';
import WebInputfield from '../standalone-web/common/web-inputfield';
import GlueInputfield from './common/glue-inputfield';
import GlueToggle from './common/glue-toggle';
import LoadingIndicator from './common/loading-indicator';
import GlueScroll from './common/glue-scroll';

import SortByAlphaIcon from '@material-ui/icons/SortByAlpha';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { Organization, Team } from '../graphql/types-generated';
import { useQuery } from '@apollo/client';
import queries from '../graphql/queries';
import { useRecentTeams } from '../service/choose-current-team';
import { sttActive } from '../util/speech-utils';
import { isAtHome } from '../util/space-utils';
import GlueListItem from './common/glue-list-item';
import GlueIcon from './common/glue-icon';

type TeamSelectorDrawerProps = {
	web?: boolean
	open: boolean
	loading: boolean
	organizations: Organization[]
	currentOrgId?: string
	currentTeamId?: string
	onClose: () => void
	onChange: (value: string) => void
}

type TeamFilter = {
	method: 'alpha' | 'recent'
	param: number
}

const useStyles = makeStyles((theme) => ({
	drawerPaper: {
		display: 'flex',
		position: 'relative',
		flexFlow: 'column',
		height: '100%',
		width: theme.custom.teamSelector.drawerWidth,
		paddingLeft: theme.glueSpacing('xl'),
		paddingTop: theme.glueSpacing('s'),
		paddingBottom: theme.glueSpacing('s')
	},

	selectorHeader: {
		display: 'grid',
		gridTemplateColumns: '1fr min-content',
		alignItems: 'center',
		marginRight: theme.glueSpacing('s')
	},

	controls: {
		marginTop: theme.glueSpacing('xl'),
		marginRight: theme.glueSpacing('xl'),
		marginBottom: theme.glueSpacing('m'),
		display: 'flex',
		flexFlow: 'column',
		gap: theme.glueSpacing('m'),
	},

	divider: {
		height: '2px',
		background: '#fff',
		opacity: '20%',
	},

	filters: {
		display: 'flex',
		flexFlow: 'row nowrap',
		gap: theme.glueSpacing('m'),

		'&>.MuiGlueWebInputfield-root': {
			width: '100%'
		},
	},

	content: {
		display: 'flex',
		flexFlow: 'column',
		gap: theme.glueSpacing('m'),
	},

	loadingIndicator: {
		display: 'flex',
		height: '100%',
		justifyContent: 'center',
		alignItems: 'center',

		'&> img': {
			width: '100px',
			height: '100px'
		}
	},

	listing: {
		flex: '1 1 auto',

		display: 'flex',
		flexFlow: 'column wrap',
		alignItems: 'stretch',
		gap: theme.glueSpacing('xs')
	},

	notification: {
		display: 'flex',
		flexFlow: 'row',

		'& p': {
			marginTop: theme.glueSpacing('m')
		},
	}
}), { name: 'MuiGlueTeamSelectorDrawer' });

const TeamSelectorDrawer = (props: TeamSelectorDrawerProps) =>
{
	const classes = useStyles();

	const { recentTeams } = useRecentTeams();

	const currentSpaceServerKeyResult = useQuery(queries.currentSpaceServerKey);
	const atHome = isAtHome(currentSpaceServerKeyResult);

	const sttRes = useQuery(queries.speechRecognition);
	const searchSTTSinkId = "TeamSelectorSearch";

	const [filter, setFilter] = useState<TeamFilter>({ method: 'alpha', param: 1 });
	const [searchWord, setSearchWord] = useState('');
	const [selectedOrgId, setSelectedOrgId] = useState(props.currentOrgId);
	const [selectedTeamId, setSelectedTeamId] = useState(props.currentTeamId);

	const teamsRes = useQuery<{ myTeams: Team[] }>(queries.myTeams, {
		skip: !selectedOrgId,
		variables: {
			orgId: selectedOrgId
		}
	});

	const orgListItems = props.organizations.map(org => ({
		id: org.orgId,
		name: org.name
	}));

	let teams = teamsRes.data?.myTeams ?? [];
	
	// Apply filters
	if (atHome) {
		teams = teams.filter(item => 
			!searchWord || 
			item.name?.toLowerCase().includes(searchWord.toLowerCase())
		);
	
		if (filter.method === 'alpha') {
			teams.sort((a, b) => {
				const compareResult = String(a.name ?? '').localeCompare(b.name ?? '');
				return compareResult * filter.param;
			});
		}
		else if (filter.method === 'recent') {
			teams = teams.filter(team => recentTeams.includes(team.teamId));
			teams.sort((a, b) => recentTeams.indexOf(a.teamId) - recentTeams.indexOf(b.teamId));
		}
	} else {
		teams = teams.filter(item => item.teamId == props.currentTeamId);
	}

	const onSetAlphaFilter = () =>
	{
		setFilter({
			method: 'alpha',
			// Toggle direction if alpha is already active
			param: (filter.method === 'alpha' ? filter.param * -1 : 1)
		});
	};

	const onClose = () =>
	{
		setSelectedOrgId(props.currentOrgId);
		if (!!selectedTeamId) props.onChange(selectedTeamId);
		props.onClose();
	}

	useEffect(() => {
		setSelectedOrgId(props.currentOrgId);
	}, [props.currentOrgId])

	useEffect(() => {
		setSelectedTeamId(props.currentTeamId);
	}, [props.currentTeamId])

	const closeaudiomessage = "Menu/CloseTeams/Press";
	const closehoveraudiomessage = "Menu/CloseTeams/HL"
	const afnaudiomessage = "Menu/Teams/AZFilter/Normal"
	const afraudiomessage = "Menu/Teams/AZFilter/Reversed"
	const afhoveraudiomessage = "Menu/Teams/AZFilter/HL"
	const recentaudiomessage = "Menu/Teams/Recent/Press"
	const recenthoveraudiomessage = "Menu/Teams/Recent/HL"

	const isLoading = props.loading || (teamsRes.networkStatus === 4 || !teamsRes.data);

	return (
		<GlueDrawer
				classes={{ paper: classes.drawerPaper }}
				open={props.open}
				onClose={onClose}
			>
				<div className={classes.selectorHeader}>
					<h2>My Teams</h2>
					<CloseButton 
						onClose={onClose}
						uiAudioMessage={closeaudiomessage}
						uiHoverAudioMessage={closehoveraudiomessage}
					/>
				</div>

				<div className={classes.controls}>
					<GlueDropdown 
						disabled={!atHome || isLoading}
						items={orgListItems}
						value={selectedOrgId}
						onChange={(id, checked) => checked && setSelectedOrgId(id as string)}
					/>

					<div className={classes.divider}/>

					{atHome && (
						<div className={classes.filters}>
							{props.web ? (
								<WebInputfield
									search
									autoFocus
									value={searchWord}
									onClear={() => { setSearchWord(""); }}
									onChange={(value: string) => { setSearchWord(value.toLowerCase()); }}
									onClose={() => { setSearchWord(""); }}
									showClearButton={searchWord ? true : false}
									placeholder='Search...'
								/>
							) : (
								<GlueInputfield
									search
									autoFocus
									value={searchWord}
									speechInput={sttActive(sttRes, searchSTTSinkId)}
									onClear={() => { setSearchWord(""); }}
									onChange={(value: string) => { setSearchWord(value.toLowerCase()); }}
									onClose={() => { setSearchWord(""); }}
									showClearButton={searchWord ? true : false}
									placeholder='Search...'
									sinkId={searchSTTSinkId}
									onSpeechChange={(value: string) => { setSearchWord(value? value: "");}}
								/>
							)}

							<GlueToggle
								toggled={filter.method === 'alpha'}
								onPointerDown={onSetAlphaFilter}
								uiAudioMessage = {filter.param === 1 ? afraudiomessage : afnaudiomessage}
								uiHoverAudioMessage = {afhoveraudiomessage}
							>
								<SortByAlphaIcon />
							</GlueToggle>

							<GlueToggle
								toggled={filter.method === 'recent'}
								onPointerDown={() => { setFilter({ method: 'recent', param: 0 }); }}
								uiAudioMessage = {recentaudiomessage}
								uiHoverAudioMessage = {recenthoveraudiomessage}
							>
								<AccessTimeIcon />
							</GlueToggle>
						</div>
					)}
				</div>

				{isLoading ? 
					<div className={classes.loadingIndicator}>
						<LoadingIndicator/>
					</div> : <GlueScroll persistent>
						<div className={classes.content}>
							<div className={classes.listing}>
								{teams.map((team => (
									<GlueListItem
										key={team.teamId}
										selected={team.teamId === selectedTeamId}
										onPointerDown={() => setSelectedTeamId(team.teamId)}
									>
										{team.name}
									</GlueListItem>
								)))}
							</div>
							{!atHome && (
								<div className={classes.notification}>
									<GlueIcon>
										<InfoOutlinedIcon />
									</GlueIcon>
									<p>Switching to another organization or team is only possible in Home.</p>
								</div>
							)}
						</div>
					</GlueScroll>}
			</GlueDrawer>
	);
}

export default TeamSelectorDrawer;
