/* eslint-disable no-unused-vars */
import queries from '../graphql/queries';
import { updateDSPSettings, updateDSPToggles } from '../component/common/common-vuplex-messages';
import { getUserSettings } from '../service/user-settings';
import { ApolloClient } from '@apollo/client';
import { ClientPlatformInfo } from '../graphql/types-generated';

export type DspValues = {
	dspPreset: number[],
	dspLevels: number[][]
}

export type DspToggles = {
	normalization: number,
	noiseGate: number,
	echoCancellation: number
}

export type DspSettings = {
	normalizationLevel: number,
	noiseGateLevel: number,
	echoCancellationLevel: number,
	lowerThreshold: number,
	upperThreshold: number,
	holdTime: number,
	attack: number,
	release: number,
	energyThreshold: number,
	gainOneThreshold: number,
	gainTwoThreshold: number,
	muMax: number,
	muAfterSilence: number,
	thresholdBackground: number,
	thresholdForeground: number,
	thresholdBackgroundDetectionOff: number,
	thresholdForegroundDetectionOff: number,
	thresholdSilence: number,
	resetSpeed: number,
	adaptationTimeAfterSilence: number
}

export enum Device {
	Desktop = 0,
	Standalone = 1
}

export enum Preset {
	Headphones = 0,
	Loudspeakers = 1,
	HMD = 2,
	Custom = 3,
	Developer = 4
}

export const presetNames: Record<Preset, string> = {
	[Preset.Headphones]: 'Headphones',
	[Preset.Loudspeakers]: 'Loudspeakers',
	[Preset.HMD]: 'VR headset speakers',
	[Preset.Custom]: 'Custom',
	[Preset.Developer]: 'Developer'
}

let apollo : ApolloClient<unknown> | undefined = undefined;
export let currentDevice: Device = Device.Desktop;
export let dspValues : DspValues = {
	"dspPreset": [0,2],
	"dspLevels": [[2,2,0],[0,2,0]]
};
export let userDspLevels = [0,0,0];
export let presetValue : Preset = Preset.Headphones;
export let showDeveloperControls = false; 
export let showCustomControls = false; 
export const noiseGateSettings = [
	[0.0, 0.0, 1, 1, 1],
	[2.0, 7.0, 10, 5, 10],
	[0.0, 10.0, 15, 5, 10],
	[-2.0, 13.0, 1, 5, 10]
];
export const normalizationSettings = [
	[-40.0, 0.0, 0.0],
	[-26.0, 2.0, 30,0],
	[-26.0, 5.0, 30.0],
	[-26.0, 10.0, 30.0]
];
export const echoCancellationSettings = [
	[0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1, 1],
	[1.0, 1.0, 0.4, 0.6, 0.65, 0.45, 0.05, 8, 4],
	[1.0, 1.0, 0.25, 0.45, 0.55, 0.35, 0.05, 6, 3],
	[1.0, 1.0, 0.15, 0.35, 0.45, 0.25, 0.05, 4, 2]	
];
export const dspPresets = [
	[2,2,0],  // Headphones, levels for Normalization, NoiseGate, Echo
	[2,2,2],  // Loudspeakers
	[0,2,0]   // VR Headset
];

export const initDSP = async (apollo: ApolloClient<unknown>) =>
{
	const platformRes = apollo.readQuery<{ clientPlatform: ClientPlatformInfo }>({query: queries.clientPlatform});
	console.log(platformRes?.clientPlatform);
	const saHS = platformRes?.clientPlatform ? (platformRes?.clientPlatform.PlatformType === "VR" && !platformRes?.clientPlatform?.Capabilities?.includes("KBAM")) : false;
	currentDevice = saHS ? Device.Standalone : Device.Desktop;

	const userSettings = await getUserSettings(apollo);
	dspValues = userSettings.dspValues;

	userDspLevels = dspValues.dspLevels[currentDevice];
	handlePresetChange(dspValues.dspPreset[currentDevice] as Preset);
}

export const setupDSP = async (apolloparameter: ApolloClient<unknown>) =>
{
	apollo = apolloparameter;
	await initDSP(apollo);
	updateDSPSettings(apollo);
	updateDSPToggles(apollo);
};

export const DSPSwitchChange = (value: DspToggles) => {
	apollo?.writeQuery({
		query: queries.dspToggles,
		data: {
			dspToggles: {
				__typename: 'DspToggles',
				normalization: value.normalization,
				noiseGate: value.noiseGate,
				echoCancellation: value.echoCancellation
			}
		}
	});
	updateDSPToggles(apollo);
};

export const updateDSPLevels = (preset: Preset) =>
{
	const dspToggleRes = apollo?.readQuery({ query: queries.dspToggles });
	const dspToggles = dspToggleRes.dspToggles;

	const value = {
		normalization: dspToggles.normalization,
		noiseGate: dspToggles.noiseGate,
		echoCancellation: dspToggles.echoCancellation
	};

	// Normalization
	const newNormalizationLevel = (preset === Preset.Custom || preset === Preset.Developer) ? userDspLevels[0] : dspPresets[preset][0];
	const newEnergyThreshold = normalizationSettings[newNormalizationLevel][0];
	const newGainOneThreshold = normalizationSettings[newNormalizationLevel][1];
	const newGainTwoThreshold = normalizationSettings[newNormalizationLevel][2];
	value.normalization = newNormalizationLevel;
	
	// Noisegate
	const newNoiseGateLevel = (preset === Preset.Custom || preset === Preset.Developer) ? userDspLevels[1] : dspPresets[preset][1];
	const newLowerThreshold = noiseGateSettings[newNoiseGateLevel][0];
	const newUpperThreshold = noiseGateSettings[newNoiseGateLevel][1];
	const newHoldTime = noiseGateSettings[newNoiseGateLevel][2];
	const newAttack = noiseGateSettings[newNoiseGateLevel][3];
	const newRelease = noiseGateSettings[newNoiseGateLevel][4];
	value.noiseGate = newNoiseGateLevel;
	
	// Echo cancellation
	const newEchoCancellationLevel = (preset === Preset.Custom || preset === Preset.Developer) ? userDspLevels[2] : dspPresets[preset][2];
	const newMuMax = echoCancellationSettings[newEchoCancellationLevel][0];
	const newMuAfterSilence = echoCancellationSettings[newEchoCancellationLevel][1];
	const newThresholdBackground = echoCancellationSettings[newEchoCancellationLevel][2];
	const newThresholdForeground = echoCancellationSettings[newEchoCancellationLevel][3];
	const newThresholdBackgroundDetectionOff = echoCancellationSettings[newEchoCancellationLevel][4];
	const newThresholdForegroundDetectionOff = echoCancellationSettings[newEchoCancellationLevel][5];
	const newThresholdSilence = echoCancellationSettings[newEchoCancellationLevel][6];
	const newResetSpeed = echoCancellationSettings[newEchoCancellationLevel][7];
	const newAdaptationTimeAfterSilence = echoCancellationSettings[newEchoCancellationLevel][8];
	value.echoCancellation = newEchoCancellationLevel;
	
	DSPSwitchChange(value);

	apollo?.writeQuery({
		query: queries.dspSettings,
		data: {
			dspSettings: {
				__typename: 'DspSettings',
				normalizationLevel: newNormalizationLevel,
				noiseGateLevel: newNoiseGateLevel,
				echoCancellationLevel: newEchoCancellationLevel,
				lowerThreshold: newLowerThreshold,
				upperThreshold: newUpperThreshold,
				holdTime: newHoldTime,
				attack: newAttack,
				release: newRelease,
				energyThreshold: newEnergyThreshold,
				gainOneThreshold: newGainOneThreshold,
				gainTwoThreshold: newGainTwoThreshold,
				muMax: newMuMax,
				muAfterSilence: newMuAfterSilence,
				thresholdBackground: newThresholdBackground,
				thresholdForeground: newThresholdForeground,
				thresholdBackgroundDetectionOff: newThresholdBackgroundDetectionOff,
				thresholdForegroundDetectionOff: newThresholdForegroundDetectionOff, 
				thresholdSilence: newThresholdSilence,
				resetSpeed: newResetSpeed,
				adaptationTimeAfterSilence: newAdaptationTimeAfterSilence
			}
		}
	});

	updateDSPSettings(apollo);
};

const handleDSPFlags = (type: keyof DspSettings, newValue: DspSettings[keyof DspSettings]) =>
{
	const dspToggleRes = apollo?.readQuery({ query: queries.dspToggles });
	const dspToggles = dspToggleRes.dspToggles;

	const value : DspToggles = {
		normalization: dspToggles.normalization,
		noiseGate: dspToggles.noiseGate,
		echoCancellation: dspToggles.echoCancellation
	};
	
	switch (type)
	{
		case 'normalizationLevel':
			value.normalization = newValue;
			break;
		case 'noiseGateLevel':
			value.noiseGate = newValue;
			break;
		case 'echoCancellationLevel':
			value.echoCancellation = newValue;
			break;
		default:
			return;

	}

	DSPSwitchChange(value);
};

export const handleDSPChange = (slider: keyof DspSettings, newValue: DspSettings[keyof DspSettings]) =>
{
	const dspSettingsRes = apollo?.readQuery({ query: queries.dspSettings });
	const newDspSettings = { ...dspSettingsRes.dspSettings };

	switch (slider as string)
	{
		case 'normalizationLevel':
			newDspSettings.normalizationLevel = newValue;
			newDspSettings.energyThreshold = normalizationSettings[newValue][0];
			newDspSettings.gainOneThreshold = normalizationSettings[newValue][1];
			newDspSettings.gainTwoThreshold = normalizationSettings[newValue][2];
			handleDSPFlags(slider, newValue);
			break;
		case 'noiseGateLevel':
			newDspSettings.noiseGateLevel = newValue;
			newDspSettings.lowerThreshold = noiseGateSettings[newValue][0];
			newDspSettings.upperThreshold = noiseGateSettings[newValue][1];
			newDspSettings.holdTime = noiseGateSettings[newValue][2];
			newDspSettings.attack = noiseGateSettings[newValue][3];
			newDspSettings.release = noiseGateSettings[newValue][4];
			handleDSPFlags(slider, newValue);
			break;
		case 'echoCancellationLevel':
			newDspSettings.echoCancellationLevel = newValue;
			newDspSettings.muMax = echoCancellationSettings[newValue][0];
			newDspSettings.muAfterSilence = echoCancellationSettings[newValue][1];
			newDspSettings.thresholdBackground = echoCancellationSettings[newValue][2];
			newDspSettings.thresholdForeground = echoCancellationSettings[newValue][3];
			newDspSettings.thresholdBackgroundDetectionOff = echoCancellationSettings[newValue][4];
			newDspSettings.thresholdForegroundDetectionOff = echoCancellationSettings[newValue][5];
			newDspSettings.thresholdSilence = echoCancellationSettings[newValue][6];
			newDspSettings.resetSpeed = echoCancellationSettings[newValue][7];
			newDspSettings.adaptationTimeAfterSilence = echoCancellationSettings[newValue][8];
			handleDSPFlags(slider, newValue);
			break;
		default:
			newDspSettings[slider] = newValue;
			return;
		
	}

	userDspLevels = [newDspSettings.normalizationLevel, newDspSettings.noiseGateLevel, newDspSettings.echoCancellationLevel];

	apollo?.writeQuery({
		query: queries.dspSettings,
		data: {
			dspSettings: newDspSettings
		}
	});

	updateDSPSettings(apollo);
};

export const getDSPSettingsValue = (type: keyof DspSettings): DspSettings[keyof DspSettings] => {
	const dspSettingsRes = apollo?.readQuery({ query: queries.dspSettings });

	const settingsValue = dspSettingsRes.dspSettings[type];
	return settingsValue;
};

export const handlePresetChange = (preset: Preset) => 
{
	console.log('HandlePresetChange', preset);

	switch (preset)
	{
		case Preset.Headphones:
			presetValue = preset;
			showCustomControls = false;
			showDeveloperControls = false;
			break;
		case Preset.Loudspeakers:
			presetValue = preset;
			showCustomControls = false;
			showDeveloperControls = false;
			break;
		case Preset.HMD:
			presetValue = preset;
			showCustomControls = false;
			showDeveloperControls = false;
			break;
		case Preset.Custom:
			presetValue = preset;
			showCustomControls = true;
			showDeveloperControls = false;
			break;
		case Preset.Developer:
			presetValue = preset;
			showCustomControls = true;
			showDeveloperControls = true;
			break;
		default:
			return;
	}
	
	updateDSPLevels(preset);
};
