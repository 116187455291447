import { CSSProperties } from "@material-ui/core/styles/withStyles";

export type Type = {
	textContainer: CSSProperties
	root?: CSSProperties
	promoImage?: CSSProperties
};

const common: Type = {
	textContainer: {
		height: '792px'
	}
};

export const web: Type = {
	...common,
		
	root: {
		gap: '40px',
		padding: '64px 0 0 0'
	},

	promoImage: {
		width: '100%',
		height: '144px'
	},

};

export const tablet: Type = {
	...common,

	promoImage: {
		width: '100%',
		height: '168px'
	},
};