import React from 'react';
import { makeStyles } from '@material-ui/core';

import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import GlueIcon from './common/glue-icon';

type ParticipantCountProps = {
	hideNumber?: boolean
	participantCount: number
};

const useStyles = makeStyles(theme => ({
	root: {
		...theme.custom.icon,
		display: 'flex',
		flexFlow: 'row nowrap',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
}));

const ParticipantCountIndicator = (props: ParticipantCountProps) =>
{
	const classes = useStyles();

	return (
		<div className={classes.root}>
			{props.participantCount > 0 && (
				<>
					<GlueIcon>
						<SupervisorAccountIcon />
					</GlueIcon>
		
					{!props.hideNumber && (
						<h3>{props.participantCount}</h3>
					)}
				</>
			)}
		</div>
	);
};

export default ParticipantCountIndicator;
