import React from 'react';

const Version = (props) =>
{
	const envValue = process.env.REACT_APP_GLUEOS_VERSION;
	const versionText = envValue ? envValue : 'unknown';

	return (
		<p>{versionText}</p>
	);
};

export default Version;
