import React, { useState, useReducer } from 'react';
import moment from 'moment';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
import GlueEllipsisMenu from './glue-ellipsis-menu';
import GlueMenuItem from './glue-menu-item';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import GlueScroll from './glue-scroll';
import GlueIcon from './glue-icon';

import { clickAudio, hoverAudio } from './common-vuplex-messages';

export enum SortDirection {
	Ascending = 1,
	Descending = -1
}

type ListItemId = string | number;

type ListSortSettings<T> = {
	key: keyof T,
	dir: SortDirection
}

type ListColumn<T> = {
	key: keyof T,
	label?: string,
	width: string | number,
	noSorting?: boolean,
	icon?: React.ReactNode,
	contentFunc?: (item: T, key: keyof T) => React.ReactNode,
	sortFunc?: (a: T, b: T, key: keyof T, dir: SortDirection) => number
}

type ListMenuOption<T> = {
	key: string,
	content: ((item: T) => React.ReactNode) | React.ReactNode,
	callback: (item: T) => void
	hide?: ((item: T) => boolean) | boolean
	disabled?: ((item: T) => boolean) | boolean
}

type ListStyleProps = {
	active?: boolean,
	disabled?: boolean,
	gridTemplateColumns?: string,
	web?: boolean
}

const idIsValid = (id?: ListItemId) =>
{
	if (!id)
		return id === 0 ? true : false;
	return true;
}

// Ready made sort functions
export const simpleSortFunc = <T,>(a: T, b: T, key: keyof T, dir: SortDirection): number =>
{
	return a[key] < b[key] ? -dir : dir;
}

export const alphabeticalSortFunc = <T,>(a: T, b: T, key: keyof T, dir: SortDirection): number => 
{
	return (a[key] as string).localeCompare(b[key] as string) * dir;
}

export const dateSortFunc = <T,>(a: T, b: T, key: keyof T, dir: SortDirection): number => 
{
	const dateA = moment(a[key] as string);
	const dateB = moment(b[key] as string);

	if (dateA.isSame(dateB))
		return 0;
	
	if (dateA.isValid() && !dateB.isValid())
		return dir;
	
	if (!dateA.isValid() && dateB.isValid())
		return -dir;

	return dateA.isAfter(dateB) ? dir : -dir;
}

const useStyles = makeStyles<Theme, ListStyleProps>((theme) => ({
	root: {
		width: '100%',
		height: '100%',
		overflow: 'hidden',
		display: 'flex',
		flexFlow: 'column nowrap'
	},

	row: ({active, disabled}) => ({
		height: theme.custom.listView.rowHeight,
		display: 'flex',
		gap: theme.glueSpacing('m'),
		padding: `0 ${theme.glueSpacing('s')}`,
		opacity: disabled ? '30%' : '100%',
		...theme.custom.hoverOverlay(!!active)
	}),

	col: {
		width: '100%',
		display: 'grid',
		gridTemplateColumns: ({gridTemplateColumns}) => gridTemplateColumns,
		gap: theme.glueSpacing('m'),
	},

	columnHeader: {
		opacity: ({active}) => active ? '100%' : '60%',
	},

	item: {
		minWidth: theme.custom.icon.width,
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		whiteSpace: 'nowrap',
		overflow: 'hidden',

		color: theme.palette.secondary.contrastText,

		'&> p': {
			overflow: 'hidden',
			textOverflow: 'ellipsis'
		},
	},

	divider: {
		width: '100%',
		height: theme.custom.listView.dividerThickness,
		background: 'white',
		opacity: '20%',
	},

	headerPadding: {
		minWidth: ({web}) => web ? theme.custom.icon.width : `calc(${theme.custom.icon.width} + ${theme.custom.glueScroll.width})`
	}

}), { name: 'MuiGlueListView' });

const GlueListViewColumnHeader = <T,>(props: {
	sortSettings: ListSortSettings<T>,
	column: ListColumn<T>
	dispatchSortSettings: (key: keyof T) => void
}) =>
{
	const active = props.column?.key === props.sortSettings?.key;
	const allowSorting = props.column && !props.column.noSorting;
	const classes = useStyles({active});

	const onClick = () => {
		if (allowSorting && props.dispatchSortSettings)
		{
			props.dispatchSortSettings(props.column?.key);
		}
	}

	return (
		<div className={classes.item} style={{width: props.column?.width, cursor: allowSorting ? 'pointer' : 'default'}} key={props.column?.key as string} onPointerDown={onClick}>
			{props.column?.label && <Typography className={classes.columnHeader} variant='overline'>{props.column.label}</Typography>}
			{props.column?.icon && <GlueIcon>{props.column.icon}</GlueIcon>}
			{!!active && allowSorting &&
				<GlueIcon>
					{props.sortSettings.dir === SortDirection.Ascending  ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
				</GlueIcon>
			}
		</div>
	);
}

const GlueListViewItem = <T,>(props: {
	item: T,
	columns: ListColumn<T>[],
	disableSelection?: boolean,
	active?: boolean,
	menuOptions?: ListMenuOption<T>[],
	gridTemplateColumns: string,
	disabled?: boolean
	menuDisabled?: boolean
	onClick: () => void
	onSelect: () => void
}) =>
{
	const classes = useStyles(props);

	// By default just draws property as text
	const defaultContentFunc = (item: T, key: keyof T) => (
		<p key={key as React.Key}>{item[key] as string}</p>
	);

	const [ menuOpen, setMenuOpen ] = useState(false);

	const audiomessage = "Menu/TeamFiles/File/Press";
	const hoveraudiomessage = "Menu/TeamFiles/File/HL";

	const onClick = () =>
	{
		clickAudio(audiomessage);
		if (props.onClick)
		{
			props.onClick();
		}
	}

	return (
		<>
			<div className={classes.divider}/>
			<div className={classes.row}>
				{!props.disableSelection && (
					<div className={classes.item}>
						<GlueIcon>
							<Checkbox 
								disabled={props.disabled}
								checked={props.active}
								onChange={props.onSelect}
							/>
						</GlueIcon>
					</div>
				)}
				<div className={classes.col} 
					onPointerDown={onClick}
					onPointerEnter={() => {hoverAudio(hoveraudiomessage)}}>
					{props.columns.map((column) => (
						<div className={classes.item} style={{width: column.width}} key={column.key as React.Key}>
							{column.contentFunc ? column.contentFunc(props.item, column.key) : defaultContentFunc(props.item, column.key)}
						</div>
					))}
				</div>
				<div className={classes.item}>
					<GlueIcon>
						{!props.disabled && !props.menuDisabled && !!props.menuOptions &&
							(<GlueEllipsisMenu vertical color={'stealth'} open={menuOpen} onToggleOpen={() => setMenuOpen(!menuOpen)}>
								{props.menuOptions.filter(opt => (opt.hide instanceof Function && opt.hide(props.item) !== true) || !opt.hide).map((option) => (
									<GlueMenuItem
										key={option.key}
										disabled={option.disabled instanceof Function ? option.disabled(props.item) : option.disabled}
										onPointerDown={() => { setMenuOpen(false); option.callback(props.item); }}
									>
										{option.content instanceof Function ? option.content(props.item) : option.content}
									</GlueMenuItem>
									)
								)
						}
						</GlueEllipsisMenu>)}
					</GlueIcon>
				</div>
			</div>
		</>
	);
}

const GlueListView = <T,>(props: {
	items: T[],
	columns: ListColumn<T>[],
	selected: ListItemId[],
	overrideIdKey: keyof T,
	defaultSortKey: keyof T,
	defaultSortDir?: SortDirection,
	web?: boolean,
	menuOptions?: ListMenuOption<T>[],
	start?: number,
	count?: number,
	disableSelection?: boolean,
	onChangeSelection?: (selection: ListItemId[]) => void,
	menuDisabledFunc?: (item: T) => boolean
	itemDisabledFunc?: (item: T) => boolean
	onClickItem?: (item: T) => void
}) => 
{
	const gridTemplateColumns = props.columns.map((col) => col.width).join(' ');
	const classes = useStyles({...props, gridTemplateColumns});

	const selected = props.selected ?? [];
	const idKey = props.overrideIdKey ?? 'id';

	const [sortSettings, dispatchSortSettings] = useReducer(
		(state: ListSortSettings<T>, sortKey: keyof T): ListSortSettings<T> => {
			const dir = (sortKey === state.key ? -1 : 1);
			return {
				key: sortKey,
				dir: state.dir * dir
			}
		},
		{
			key: props.defaultSortKey,
			dir: props.defaultSortDir ?? SortDirection.Descending
		}
	);

	const itemsAll = props.items.slice() ?? [];

	// Sort items...
	const sortFunc = props.columns?.find((col) => col.key === sortSettings.key)?.sortFunc;
	if (sortFunc) {
		itemsAll.sort((a: T, b: T) => sortFunc(a,b,sortSettings.key,sortSettings.dir));
	}

	// Slice according to start and count props
	const start = !!props.start ? props.start : 0;
	const count = !!props.count ? props.count : (itemsAll.length - start);
	const itemsSliced = itemsAll.slice(start, start + count);

	const itemsSelectable = itemsAll.filter(item => !props.itemDisabledFunc || !props.itemDisabledFunc(item));

	const selectItem = (id: ListItemId) => 
	{
		const newSelected = [...selected];
		const existingIndex = newSelected.findIndex(i => i === id);
		if (existingIndex !== -1)
		{
			newSelected.splice(existingIndex, 1);
		}
		else
		{
			newSelected.push(id);
		}

		if (props.onChangeSelection)
		{
			props.onChangeSelection(newSelected);
		}
	}

	const selectAll = () => 
	{
		let newSelected: ListItemId[] = [];

		if (selected.length < itemsAll.length)
		{
			newSelected = itemsSelectable.map(item => item[idKey] as ListItemId);
		}

		if (props.onChangeSelection)
		{
			props.onChangeSelection(newSelected);
		}
	}

	const itemIsSelected = (id: ListItemId) => 
	{
		if (!idIsValid(id))
			return false;

		return selected.includes(id);
	}

	const onClickItem = (item: T) =>
	{
		if (props.onClickItem)
		{
			props.onClickItem(item);
		}
	}

	return (
		<div className={classes.root}>
			<div className={classes.row}>
				{!props.disableSelection && (
					<div className={classes.item}>
						<GlueIcon>
							<Checkbox 
								checked={selected.length === itemsAll.length && selected.length > 0}
								indeterminate={selected.length > 0 && selected.length < itemsAll.length}
								onChange={selectAll}
							/>
						</GlueIcon>
					</div>
				)}
				<div className={classes.col}>
					{props.columns?.map((column) => (
						<GlueListViewColumnHeader 
							key={column.key as React.Key}
							column={column}
							sortSettings={sortSettings}
							dispatchSortSettings={dispatchSortSettings}
						/>
					))}
				</div>
				{/* Empty spot here to keep columns aligned... */}
				<div className={classes.headerPadding}/>
			</div>
			{props.web ? (
				<>
					{itemsSliced.map((item) => (
						<GlueListViewItem
							key={item[idKey] as React.Key}
							item={item}
							disabled={props.itemDisabledFunc && props.itemDisabledFunc(item)}
							columns={props.columns}
							onSelect={() => selectItem(item[idKey] as ListItemId)}
							active={itemIsSelected(item[idKey] as ListItemId)}
							menuOptions={props.menuOptions}
							menuDisabled={props.menuDisabledFunc && props.menuDisabledFunc(item)}
							onClick={() => onClickItem(item)}
							disableSelection={props.disableSelection}
							gridTemplateColumns={gridTemplateColumns}
						/>
					))}
				</>
			) : (
				<GlueScroll persistent>
					{itemsSliced.map((item) => (
						<GlueListViewItem
							key={item[idKey] as React.Key}
							item={item}
							disabled={props.itemDisabledFunc && props.itemDisabledFunc(item)}
							columns={props.columns}
							onSelect={() => selectItem(item[idKey] as ListItemId)}
							active={itemIsSelected(item[idKey] as ListItemId)}
							menuOptions={props.menuOptions}
							menuDisabled={props.menuDisabledFunc && props.menuDisabledFunc(item)}
							onClick={() => onClickItem(item)}
							disableSelection={props.disableSelection}
							gridTemplateColumns={gridTemplateColumns}
						/>
					))}
				</GlueScroll>
			)}
		</div>
	);
}

export default GlueListView;