import React from 'react';
import AppHeader from '../common/app-header';
import VideoControlFooter from './video-control-footer';
import VideoTimeline from './video-timeline';
import { makeStyles } from '@material-ui/core';
import SwitchSelector from '../common/switch-selector';
import CloseButton from '../common/close-button';
import postVuplexMessage from '../../service/message-vuplex';
import { useQuery } from '@apollo/client';
import queries from '../../graphql/queries';
// import { convertToHHMMSS } from '../../util/time-util'; // Remove comment for debugging
import { VideoPlayerState, VideoMetadata, InventoryItem, QueryInventoryItemInfoArgs } from '../../graphql/types-generated';
import { showClosePresentationControlsDialog } from '../../util/sharing-utils';
import { usePromptDialogContext } from '../../util/prompt-dialog-context';

const useStyles = makeStyles(theme => ({
	root: {

	},

	webContent: {
		width: '1366px',
		height: '768px',
		margin: '8px 0',
		padding: `0 ${theme.glueSpacing('s')}`,
		background: 'black'
	},

}), { name: 'MuiGlueVideoControl' });

const VideoControl = () => {
	const classes = useStyles();

	const dialogContext = usePromptDialogContext();

	const instanceId = window.sessionStorage.getItem('instance-id');
	const stateRes = useQuery<{ extVideoState: VideoPlayerState }>(queries.extVideoState, {
		variables: {
			instanceId: instanceId
		}
	});
	const state = stateRes.data?.extVideoState;

	const metadataRes = useQuery<{ extVideoMetadata: VideoMetadata }>(queries.extVideoMetadata, {
		variables: {
			instanceId: instanceId
		}
	});
	const metadata = metadataRes.data?.extVideoMetadata;
	if (!metadata) {
		console.log('Requesting metadata', instanceId);
		postVuplexMessage('VideoPlayer.MetadataRequest', { instanceId: instanceId });
	}

	const itemRes = useQuery<{ inventoryItemInfo: InventoryItem }, QueryInventoryItemInfoArgs>(queries.inventoryItemInfo, {
		skip: !state?.inventoryItemId,
		variables: {
			inventoryItemId: state?.inventoryItemId ?? ''
		}
	});

	const play = () => {
		postVuplexMessage('VideoPlayer.Play', { instanceId: instanceId });
	}

	const pause = () => {
		postVuplexMessage('VideoPlayer.Pause', { instanceId: instanceId });
	}

	const seek = (value: number, commit?: boolean) => {
		const clampedValue = Math.min(Math.max(value, 0.0), metadata?.duration ?? 0.0);
		postVuplexMessage('VideoPlayer.Seek', { instanceId: instanceId, time: clampedValue });
	}

	const skip = (value: number) => {
		if (!state) {
			return;
		}

		seek(state.time + value, true);
	}

	const setMuted = (value: boolean) => {
		postVuplexMessage('VideoPlayer.SetMuted', { instanceId: instanceId, muted: value });
	}

	const setVolume = (value: number) => {
		postVuplexMessage('VideoPlayer.SetVolume', { instanceId: instanceId, volume: value });
	}

	const setLoop = (value: boolean) => {
		postVuplexMessage('VideoPlayer.SetLoop', { instanceId: instanceId, loop: value });
	}

	const setSharing = (value: boolean) => {
		postVuplexMessage('VideoPlayer.SetPresentationActive', { instanceId: instanceId, active: value });
	}

	const tryClose = () => {
		if (state?.sharing ?? false) {
			showClosePresentationControlsDialog(dialogContext, cancelClose, close);
			postVuplexMessage('VideoPlayer.HideContent', { instanceId: instanceId });
		}
		else {
			postVuplexMessage('VideoPlayer.CloseView', { instanceId: instanceId });
		}
	}

	const cancelClose = () => {
		postVuplexMessage('VideoPlayer.ShowContent', { instanceId: instanceId });
	}

	const close = () => {
		postVuplexMessage('VideoPlayer.CloseView', { instanceId: instanceId });
	}

	return (
		<div className={classes.root}>
			<AppHeader
				selected={false}
				hideBackButton
				title={itemRes.data?.inventoryItemInfo.name}
				children={
					<SwitchSelector
						text="Present"
						onChange={setSharing}
						// uiAudioMessage={sharing ? stopPresentingAudioMessage : presentAudioMessage}
						// uiHoverAudioMessage={sharing ? stopPresentingHoverAudioMessage : presentHoverAudioMessage}
						checked={state?.sharing ?? false}
					/>
				}
				secondChildren={
					<CloseButton onClose={() => tryClose()} />
				}
			/>
			<div className={classes.webContent}>
				{
					// Remove comments for debugging
					// <>
					// 	<p>Playing: {state?.playing ? 'true' : 'false'}</p>
					// 	<p>Paused: {state?.paused ? 'true' : 'false'}</p>
					// 	<p>Finished: {state?.finished ? 'true' : 'false'}</p>
					// 	<p>Current time: {convertToHHMMSS(state?.time ?? 0)}</p>
					// 	<p>Current frame: {state?.frame}</p>
					// 	<p>Playback rate: {state?.playbackRate}</p>
					// 	<p>Seeking: {state?.seeking ? 'true' : 'false'}</p>
					// 	<p>Buffering: {state?.buffering ? 'true' : 'false'}</p>
					// 	<p>Sharing: {state?.sharing ? 'true' : 'false'}</p>
					// 	<p>Buffered ranges:</p>
					// 	{state?.bufferedTimes && state.bufferedTimes.map(range => <>
					// 		<p>{convertToHHMMSS(range.start)} - {convertToHHMMSS(range.end)}</p>
					// 	</>)}
					// </>
				}
			</div>
			<VideoTimeline time={state?.time ?? 0} duration={metadata?.duration ?? 0} onSetTime={seek} />
			<VideoControlFooter
				muted={state?.muted ?? true}
				volume={state?.volume ?? 0}
				paused={state?.finished ?? false ? true : state?.paused ?? true}
				loop={state?.loop ?? false}
				onSetMuted={setMuted}
				onSetVolume={setVolume}
				onSetPaused={(value) => value ? pause() : play()}
				onSetLooped={setLoop}
				onSkip={skip}
			/>
		</div>
	)
}

export default VideoControl;
