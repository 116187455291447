import { useQuery } from "@apollo/react-hooks";
import React, { useContext, useEffect } from "react";
import queries from "../graphql/queries";
import { updateFacilitatorStatus } from "../component/facilitation/facilitation-app";

const UserContext = React.createContext();

/**
 * @typedef UserTeamPermissionsState
 * 
 * If properties are missing, consider them to be false.
 * 
 * @property {boolean} [teamInfo] Can query basic metadata about the team
 * @property {boolean} [spaceEdit] Can edit (rename, reset etc.) and crate Spaces
 * @property {boolean} [spaceRead] Can see and join existing Spaces
 * @property {boolean} [assetCreate] Can create assets with team permission
 * @property {boolean} [assetVisibilitySet] Can hide/unhide team assets
 * @property {boolean} [teamFilesReadOwned] Can read owned Team Files
 * @property {boolean} [teamFilesReadPublic] Can read public Team Files
 * @property {boolean} [teamFilesReadPrivate] Can read other's private Team Files
 * @property {boolean} [teamFilesEditOwned] Can add and delete owned Team Files
 * @property {boolean} [teamFilesEditOthers] Can read, edit and delete non-owned and other's private Team Files
 * @property {boolean} [membersEdit] Can add new users to the Team and choose which access level they have and remove existing members including other than self
 * @property {boolean} [isFacilitator] Can act as a Facilitator in the Team
 * 
 * @readonly
 */

/**
 * @typedef UserOrgPermissionState
 * 
 * If properties are missing, consider them to be false.¨
 * 
 * @property {boolean} [membersRead] Can access org member list.
 * @property {boolean} [membersEdit] Can add and remove members of the organization and update their role.
 * @property {boolean} [teamsRead] Can see organization teams and join/leave them.
 * @property {boolean} [teamsEdit] Can modify teams of the organization.
 * @property {boolean} [teamsJoin] Can join a public team in the org.
 * @property {boolean} [teamCreate] Can create new teams to the organization.
 * @property {boolean} [assetVisibilitySet] Can hide/unhide org assets
 * @property {boolean} [clustersRead] Can access clusters limited to the organization.
 * @property {boolean} [orgInfoRead] Can get information about the org
 * @property {boolean} [orgInfoReadLimited] Allow minimum read privileges for the user (ie. getting the org ID of their current team)
 * @property {boolean} [orgEdit] Can edit organization.
 * @property {boolean} [subscriptionRead] Can see subscription info of the org.
 * 
 * @readonly
 */

/**
 * @typedef UserContext
 * 
 * @property {string} [email] the user email
 * @property {Boolean} [administrator] has system admin privileges
 * @property {String} [orgRole] the users role in the organization
 * @property {String} [teamRole] the users rolen in the team
 * @property {UserOrgPermissionState} [org]
 * @property {UserTeamPermissionsState} [team]
 * 
 */

/**
 * 
 * @returns {UserContext}
 */
export const useUserContext = () => {
	return useContext(UserContext);
}

const UserContextProvider = (props) => {
	const myEmailRes = useQuery(queries.myEmail);
	const currentTeamRes = useQuery(queries.currentTeamId);

	const userAccessRes = useQuery(queries.myAccessInfo, {
		skip: !currentTeamRes.data,
		variables: {
			teamId: currentTeamRes.data.currentTeamId
		},
		fetchPolicy: 'network-only'
	});

	useEffect(() => {

		const timer = setInterval(() => {
			console.log('fetching access')
			userAccessRes.refetch();
			updateFacilitatorStatus();
			
		}, 30000)

		return () => clearInterval(timer)
	}, [userAccessRes]);

	return (
		<UserContext.Provider value={{...userAccessRes.data?.myAccessInfo, email: myEmailRes.data?.myEmail}}>
			{props.children}
		</UserContext.Provider>
	)
}

export default UserContextProvider;