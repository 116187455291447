import React from 'react'
import { useState } from 'react'
import { makeStyles } from "@material-ui/core";
import GlueButton from '../../common/glue-button'
import PolicyDialog from '../../policy-dialog'

const useStyles = makeStyles((theme) => ({
	buttonArea: {
		display: 'flex',
		justifyContent: 'center',
		paddingTop: theme.glueSpacing('xl')
	}
}), { name: 'MuiGlueCustomConsent' });

const CustomConsent = (props) => {
	const classes = useStyles()
	const [ page, setPage ] = useState(0)

	const onPressAccept = () => {
		if (props.pages && Array.isArray(props.pages) && props.pages.length > page + 1) {
			setPage(previous => previous + 1)
		} else {
			props.onClose()
		}
	}

	const pageCount = props.pages && Array.isArray(props.pages) && props.pages.length

	return (
		<PolicyDialog open={props.open} title={pageCount > page && props.pages[page].title} disableCloseBtn currentPage={page} pageCount={pageCount}>
			{pageCount > page && props.pages[page].content}
			<div className={classes.buttonArea}>
				<GlueButton color='primary' onPointerDown={onPressAccept}>
					Accept
				</GlueButton>
			</div>
		</PolicyDialog>
	)
}

export default CustomConsent
