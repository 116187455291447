import React from 'react';
import { makeStyles } from '@material-ui/core';
import GlueButton from './glue-button';
import { usePromptDialogContext } from '../../util/prompt-dialog-context';

const useStyles = makeStyles((theme) => ({
	root: (props) => ({
		...theme.custom.errorDialog.root,
		width: props.width ? props.width : null
	}),

	image: theme.custom.errorDialog.image,

	header: {
		...theme.custom.errorDialog.header,
		display: 'flex',
		padding: `0 ${theme.glueSpacing('l')}`,
		marginBottom: theme.glueSpacing('m'),
		'&> *:first-child': {
			margin: 'auto'
		}
	},

	body: {
		...theme.custom.errorDialog.body,
		padding: `0 ${theme.glueSpacing('l')}`,
		margin: `${theme.glueSpacing('xl')} 0`
	},

	buttons: {
		...theme.custom.errorDialog.buttons,
		gap: theme.glueSpacing('m')
	}

}));

const GeneralDialogTemplate = (props) =>
{
	const { closePromptDialog } = usePromptDialogContext();
	const classes = useStyles(props);

	return (
		<div className={classes.root}>
			<div className={classes.header}>
				<h2>{props.header}</h2>
			</div>
			{props.image && <img className={classes.image} src={props.image} alt={""} />}
			<div className={classes.body}>
				{props.body}
			</div>
			<div className={classes.buttons}>
				{props.callbacks ? props.callbacks.map((btn, i) => (
					<GlueButton key={i}
						onPointerDown={() => { btn.callback(); closePromptDialog() }}
						color={btn.color ? btn.color : null} >
						{btn.label ? btn.label : 'Submit'}
					</GlueButton>)) 
				: <GlueButton color="primary" onPointerDown={() => closePromptDialog()}>OK</GlueButton>}
			</div>

		</div>
	);
}

export default GeneralDialogTemplate;