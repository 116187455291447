import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import queries from '../graphql/queries';
import postVuplexMessage from '../service/message-vuplex';
import HeroIcon from './common/hero-icon';

import PassthroughIcon from '../icons/hero/Passthrough.png';

const questprotag = 'Meta_Quest_Pro'

const spawnPassthrough = () =>
{
	const objectToSpawn = "passthrough";
	console.log("Spawn object passthrough.");
	postVuplexMessage("Spawn object", { id: objectToSpawn, args: null });
};

const PassthroughHeroIcon = (props) =>
{
	const clientPlatformResult = useQuery(queries.clientPlatform);
	const clientPlatform = clientPlatformResult.data?.clientPlatform;
	const deviceModel = clientPlatform ? clientPlatform.DeviceModel : "None";

	if(deviceModel !== questprotag)
		return null;

	const hoveraudiomessage = "Menu/DesktopView/HL";
	const audiomessage = "Menu/DesktopView/Press";

	return (
		<HeroIcon
			name="Passthrough Window"
			icon={PassthroughIcon}
			route="/"
			onPointerDown={spawnPassthrough}
			uiAudioMessage={audiomessage}
			uiHoverAudioMessage={hoveraudiomessage}
		/>
	);
}

export default PassthroughHeroIcon;
