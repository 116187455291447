import React, { useState } from 'react';

import GlueMenuItem from './glue-menu-item';
import { makeStyles } from '@material-ui/core';
import GlueInputfield from './glue-inputfield';
import GlueEllipsisMenu from './glue-ellipsis-menu';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'grid',
		gridTemplateColumns: '1fr min-content',
		gap: '12px'
	}
}));

const CommonTextValuePicker = (props) => {
	const classes = useStyles();

	const [open, setOpen] = useState(false);

	const onSelect = (option) =>
	{
		setOpen(false);
		props.onChange(option);
	};
	
	return (
		<div className={classes.root}>
			<GlueInputfield
				simpleInput
				width={'auto'}
				value={props.value}
				onChange={props.onChange}
				placeholder={props.placeholder}
			/>
			<GlueEllipsisMenu
				open={open}
				onToggleOpen={() => setOpen(!open)}
			>
				{props.options.map((option, i) => (
					<GlueMenuItem key={option} onPointerDown={() => onSelect(option)}>
						{option}
					</GlueMenuItem>
				))}
			</GlueEllipsisMenu>
		</div>
	);
};

export default CommonTextValuePicker;
