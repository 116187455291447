import React, { useEffect, useState } from 'react';

import { makeStyles, useTheme } from '@material-ui/core';
import GlueButton from './glue-button';
import GlueInputfield from './glue-inputfield';
import GlueDropdown from './glue-dropdown';
import SwitchSelector from './switch-selector';
import ColorSelector from './color-selector';
import DeleteIcon from '@material-ui/icons/Delete';
import EditorPanel from '../common/editor-panel';

import ColorSliders from './colorsliders';
import tinycolor from 'tinycolor2';

const useStyles = makeStyles(theme => ({
	hexas: {
		height: theme.custom.editorPanel.rowHeight,
		marginTop: theme.glueSpacing('m'),
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',

		'& .MuiGlueInputField-searchfield': theme.custom.colorPicker.searchField,
		'& input': theme.custom.colorPicker.inputField,
	},

	sliders: {
		marginTop: theme.glueSpacing('xl'),
	},

	presets: {
		display: 'flex',
		flexDirection: 'column',
		marginBottom: theme.glueSpacing('l'),
	},

	presetSwitch: {
		height: theme.custom.editorPanel.rowHeight,
		display: 'flex',
		marginTop: theme.glueSpacing('m'),
		marginBottom: theme.glueSpacing('s'),
		justifyContent: 'center',
	},

	presetSwatch: {
		display: 'grid',
		minHeight: theme.custom.editorPanel.rowHeight,
		gridTemplateColumns: `repeat(8, ${theme.custom.editorPanel.rowHeight})`,
		gap: `${theme.glueSpacing('m')} 20px`,
		width: '100%'
	},

	noSwatchesText: {
		textAlign: 'center',
		height: theme.custom.editorPanel.rowHeight,
		display: 'flex',

		'& > h3': {
			margin: 'auto',
		},
	},

	buttons: {
		display: 'grid',
		gridAutoFlow: 'column'
	},

	buttonsLeft: {
		display: 'grid',
		gap: theme.glueSpacing('m'),
		gridAutoFlow: 'column',
		justifyContent: 'left',
		
		'& button:first-child': {
			padding: '0',
			width: theme.custom.colorPicker.saveColorButtonWidth
		},

	},

	buttonsRight: {
		display: 'grid',
		gap: theme.glueSpacing('m'),
		gridAutoFlow: 'column',
		justifyContent: 'right'
	},

	colorIndicator: {
		width: theme.custom.colorPicker.colorIndicatorWidth,
		height: theme.custom.editorPanel.rowHeight,
		borderRadius: '32px',
		backgroundColor: props => props.hexString,
		alignSelf: 'center'
	}
}), { name: "MuiGlueColorPicker" });

const ColorSliderArea = (props) =>
{
	const [ colorModeIsHSV, setColorModeIsHSV ] = useState(true);
	const hsv = props.hsv;
	const hexString = tinycolor(hsv).toHexString();
	const [ editableHexString, setEditableHexString ] = useState(null);
	const [ editableHsv, setEditableHsv ] = useState(hsv);

	const classes = useStyles({hexString});

	const sortOptions = [
		{ id: 'HSV', name: 'HSV' },
		{ id: 'RGB', name: 'RGB'}
	];

	useEffect(() => {
		setEditableHsv(hsv);
	}, [hsv]);

	const selectSortingMethod = (event) => {
		if(event === "HSV")
		{
			setColorModeIsHSV(true);
		}
		if(event === "RGB")
		{
			setColorModeIsHSV(false);
		}
	};

	const onSliderChange = (hsv, commit) =>
	{
		setEditableHsv(hsv);
		
		if (commit === true)
		{
			props.onCommit(hsv, null, -1);
		}
	}

	const onCommitChange = () =>
	{
		props.onCommit(editableHsv, null, -1);
	}

	const submitCustomHexValue = (hex) =>
	{
		// Check validity by doing conversion
		const color = tinycolor(hex);
		const hsv = color.toHsv();
		props.onCommit(hsv, null, -1);
		setEditableHexString(null);
	}

	const trimString = (string, maxLength) =>
	{
		const length = Math.min(string.length, maxLength);
		return string.substring(0, length);
	}

	const handleInputFocus = (focusValue) =>
	{
		if (focusValue === true)
		{
			setEditableHexString(hexString);
		}
		else
		{
			setEditableHexString(null);
		}
	}

	return (<>
		<div className={classes.hexas}>
				<GlueDropdown
					width={props.theme.custom.colorPicker.sliderColorModeDropdownWidth} 
					items={sortOptions} 
					defaultValue={sortOptions[0].id} 
					onChange={(id, checked) => checked && selectSortingMethod(id)}
				/>
				<div className={classes.colorIndicator}/>
				<GlueInputfield
					simpleInput
					width={props.theme.custom.colorPicker.fieldWidth}
					backgroundColor={'black'}
					centerText
					value={editableHexString ? trimString(editableHexString, 7) : hexString}
					setFocus={handleInputFocus}
					onChange={setEditableHexString}
					onSubmit={submitCustomHexValue}
					InputLabelProps={{
						style: { color: '#fff' }
					}}
				>

				</GlueInputfield>
			</div>
			<div className={classes.sliders}>
				<ColorSliders
					hsv={editableHsv}
					colorMode={colorModeIsHSV}
					onChange={onSliderChange}
					onCommitChange={onCommitChange}
				/>
			</div>
		</>
	);
}

const ColorSwatchItem = (props) => {

	const selectColorSwatch = (hexValue, index) =>
	{
		const mode = !!props.presetMode ? "Saved" : "Preset";
		props.onSetColor(hexValue, mode, index);
	}

	return <ColorSelector
		onPointerDown={() => selectColorSwatch(props.value, props.index)}
		color={props.value}
		toggled={props.active}
	/>;
}

const ColorPresets = (props) => {

	const classes = useStyles();

	let swatches = props.presetColors;
	if (!!props.presetMode)
	{
		swatches = props.savedColors;
	}

	return (
		<>
			{ swatches ? 
				<div className={classes.presetSwatch}>
					{swatches.map((color, index) => 
						<ColorSwatchItem 
							key={index}
							index={index} 
							value={color} 
							active={color === props.selectedColorValue}
							presetMode={props.presetMode}
							onSetColor={props.onSetColor}
						/>)}
				</div>
				:
				<div className={classes.noSwatchesText}>
					{!!props.presetMode ? 
						<h3>No colors have been saved yet.</h3>
					:
						<h3>No presets available for this color.</h3>}
				</div>
			}
		</>
	);
}

const ColorPicker = (props) =>
{
	const classes = useStyles();
	const theme = useTheme();

	const [ presetMode, setPresetMode ] = useState(false); // False = presets, true = saved
	const [ startingPointInHistory, setStartingPoint ] = useState(props.latestEditIndex);

	if (props.latestEditIndex < startingPointInHistory)
	{
		setStartingPoint(props.latestEditIndex);
	}

	const switchPresets = (value) => {
		setPresetMode(value);
	}

	const [ savedColors, setSavedColors ] = useState(props.savedColors);
	const [ hsvValue, setHsvValue ] = useState(tinycolor(props.color).toHsv());
	const editedHexValue = tinycolor(hsvValue).toHexString();
	const presetColors = props.presetColors;

	useEffect(() => {
		setHsvValue(tinycolor(props.color).toHsv());
	}, [props.color]);

	useEffect(() => {
		setSavedColors(props.savedColors);
	}, [props.savedColors]);

	const applyHsvColor = (hsv, modeString, selection) =>
	{
		const newHex = tinycolor(hsv).toHexString();
		props.onSetColor(newHex, modeString, selection);
		setHsvValue(hsv);
	}

	const applyHexColor = (hex, modeString, selection) =>
	{
		const newHsv = tinycolor(hex).toHsv();
		props.onSetColor(hex, modeString, selection);
		setHsvValue(newHsv);
	}

	const saveColor = (value) =>
	{
		const newSavedColors = savedColors ? [...savedColors] : [];
		newSavedColors.push(value);

		setSavedColors(newSavedColors);
		props.onSaveColor(newSavedColors);
		// Update color information (selected color is now saved)
		props.onSetColor(value, "Saved", newSavedColors.length - 1);
	}

	const deleteColor = (index) =>
	{
		const newSavedColors = [...savedColors];
		newSavedColors.splice(index,1);

		setSavedColors(newSavedColors);
		props.onDeleteColor(newSavedColors);
	}

	const presetArea = (
		<>
		<div className={classes.presetSwitch}>
			<SwitchSelector
				toggle
				offText={'Presets'}
				onText={'Saved'}
				onChange={switchPresets}
				checked={presetMode}
			/>
		</div>
			<ColorPresets
				identity={props.id}
				index={props.currentSelection ? props.currentSelection : props.presetIndex}
				selectedColorValue={editedHexValue}
				presetMode={presetMode}
				savedColors={savedColors}
				presetColors={presetColors}
				onSetColor={applyHexColor}
			/>
		</>
	)

	const canSaveColor = () =>
	{
		if (savedColors && editedHexValue)
		{
			const foundSavedColor = savedColors.find(value => value === editedHexValue);
			const foundPresetColor = presetColors && presetColors.find(value => value === editedHexValue);
			return !foundSavedColor && !foundPresetColor;
		}
		return false;
	}

	const canDeleteColor = () =>
	{
		return !!presetMode && props.currentSelection !== -1;
	}

	const saveCurrentColor = () =>
	{
		saveColor(editedHexValue);
		if (!presetMode)
		{
			setPresetMode(true);
		}
	}

	const deleteSavedColor = () =>
	{
		const index = props.currentSelection;
		deleteColor(index);
	}

	return (
		<EditorPanel title={`Adjust ${props.displayName}`}>
			<ColorSliderArea
				theme={theme}
				hsv={hsvValue}
				onCommit={applyHsvColor}
				/>
			<div className={classes.presets}>
				{presetArea}
			</div>
			<div className={classes.buttons}>
				<div className={classes.buttonsLeft}>
					<GlueButton
						disabled={!canSaveColor()}
						onPointerDown={saveCurrentColor}
						letterSpacing={'0px'}
					>
						Save this color
					</GlueButton>
					<GlueButton
						disabled={!canDeleteColor()}
						onPointerDown={deleteSavedColor}
						variant='icon'
					>
						<DeleteIcon/>
					</GlueButton> 
				</div>
				<div className={classes.buttonsRight}>
					<GlueButton
						onPointerDown={() => props.onCancel(startingPointInHistory)}
					>
						Cancel
					</GlueButton>
					<GlueButton
						onPointerDown={props.onConfirm}
						color="primary"
					>
						OK
					</GlueButton>	
				</div>
			</div>
		</EditorPanel>
	);
}

export default ColorPicker;
