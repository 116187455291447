import React, { useState } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { usePromptDialogContext } from '../../util/prompt-dialog-context';
import SimpleSAUP, { SAUPHandlingStatus } from './simple-saup';
import SpaceTemplateDetailsDialog from './space-template-details-dialog';
import { Asset, SignedUploadUrlResponse } from '../../graphql/types-generated';
import InfoDialogTemplate from '../common/info-dialog-template';
import mutations from '../../graphql/mutations';
import { assignCustomThumbnail, uploadSpaceAssetPackageAsync, checkSpaceAssetUploadUploadStatus } from '../space-asset/space-asset-common';
import { glueDateDisplayFormat } from '../../util/app-utils';

const EditTemplateDialog = (props: {
	asset: Asset
	teamId: string
	web?: boolean
}) =>
{
	const apollo = useApolloClient();
	const { addDialog, closePromptDialog } = usePromptDialogContext();

	const [ errorMsg, setErrorMsg ] = useState<string>('');
	const [ newVersion, setNewVersion ] = useState<File | undefined>();
	const [ thumbnailCleared, setThumbnailCleared ] = useState(false);
	const [ handlingStatus, setHandlingStatus ] = useState<SAUPHandlingStatus | undefined>();
	const [ progress, setProgress ] = useState<number>(0);

	const editTemplate = async (name: string, customThumbnail?: File) => {
		setHandlingStatus('mutating');

		try {
			if (!!newVersion) {
				const updateAssetRes = await apollo.mutate({
					mutation: mutations.updateAssetDeferred,
					variables: {
						assetId: props.asset.assetId,
						name: name,
						assignVersionPointer: 'production'
					}
				});

				const signedUploadURLBody: SignedUploadUrlResponse = updateAssetRes.data.updateAssetDeferred;

				setHandlingStatus('uploading');
				const uploadRes = await uploadSpaceAssetPackageAsync(
					signedUploadURLBody,
					newVersion,
					setProgress,
					() => null
				);

				if (uploadRes) {
					setHandlingStatus('processing');
	
					await checkSpaceAssetUploadUploadStatus(
						uploadRes.statusItemId,
						apollo,
						() => null
					);
				}
			} else {
				await apollo.mutate({
					mutation: mutations.updateAsset,
					variables: {
						assetId: props.asset.assetId,
						name: name,
					}
				});
			}

			if (customThumbnail) {
				await assignCustomThumbnail(customThumbnail, props.asset.assetId, apollo);
			}
		}
		catch (err) {
			setErrorMsg((err as Error).message);
			setHandlingStatus(undefined);
			return;
		}

		closePromptDialog();
		addDialog(<InfoDialogTemplate
			header={'Success!'}
			message={'Space template was successfully updated.'}
		/>);
	}

	const productionVersionPointer = props.asset.versionPointers?.find(item => item.name === "production");
	const productionVersionNumber = productionVersionPointer?.version;
	const productionVersion = props.asset.versions?.find(version => version.version === productionVersionNumber);

	return (
		<SpaceTemplateDetailsDialog
			title='Template settings'
			defaultName={props.asset.name}
			buttonLabel='Save'
			web={props.web}
			canCreate
			onCreate={editTemplate}
			onCancel={closePromptDialog}
			errorMsg={errorMsg}
			loading={!!handlingStatus}
			thumbnailUrl={thumbnailCleared ? undefined : props.asset.thumbnailUrl}
			noDefaultThumbnail
			existingCustomThumbnail
			onUseDefaultThumbnail={() => setThumbnailCleared(true)}
		>
			{!!props.web && <SimpleSAUP 
				asset={props.asset}
				teamId={props.teamId}
				onPackageSelected={setNewVersion}
				package={newVersion}
				lastModified={!!productionVersion ? glueDateDisplayFormat(productionVersion.date) : undefined}
				handlingState={handlingStatus}
				progress={progress*100}
				errorMsg={errorMsg}
			/>}
		</SpaceTemplateDetailsDialog>
	);
};
export default EditTemplateDialog;
