import firebase from "firebase/app";
import "firebase/analytics";
import sha3 from 'crypto-js/sha3';
import queries from "../../graphql/queries";
import { postVuplexMessage } from '../../service/message-vuplex';

const firebaseConfig = {
	apiKey: "AIzaSyC-xxPsSxYBQY6DMf5XrOW8AsLq0o8GOzQ",
	authDomain: "glue-a041f.firebaseapp.com",
	databaseURL: "https://glue-a041f.firebaseio.com",
	projectId: "glue-a041f",
	storageBucket: "glue-a041f.appspot.com",
	messagingSenderId: "353104682290",
	appId: "1:353104682290:web:cfba60ae5a4dd5cdbd4b2b",
	measurementId: "G-5DN4M4QXT3"
};

let apollo = null;

const firebaseApp = firebase.initializeApp(firebaseConfig);
let analyticsInstance = null;

// Set Firebase UserId to sha3 hash of user's email address + seedYear
export const setFirebaseUserId = async (apolloparam) =>
{
	apollo = apolloparam;

	const seedYear = 2022;

	const myEmailResult = await apollo.query({ query: queries.myEmail });
	const analyticsUserID = sha3(myEmailResult.data.myEmail + seedYear).toString();

	apollo.writeQuery({
		query: queries.analyticsUserID,
		data: { analyticsUserID: analyticsUserID }
	});

	apollo.writeQuery({
		query: queries.uptime,
		data: {
			__typename: 'Uptime',
			uptime: 0
		}
	});

	analyticsInstance = firebaseApp.analytics();
	analyticsInstance.setUserId(analyticsUserID);
	postVuplexMessage("Set analytics user id", { userId: analyticsUserID});
	
	console.log('Firebase analytics initialized with userId set as ', myEmailResult.data.myEmail, analyticsUserID);

};

export const logFirebaseEvent = async (eventName, parameters) =>
{
	const clientPlatformResult = await apollo.query({ query: queries.clientPlatform });
	const clientPlatform = clientPlatformResult.data?.clientPlatform;
	const os = clientPlatform ? clientPlatform.OS : "None";
	const deviceModel = clientPlatform ? clientPlatform.DeviceModel : "None";
	const analyticsUserIDRes = await apollo.query({ query: queries.analyticsUserID });
	const analyticsUserID = analyticsUserIDRes.data.analyticsUserID;
	const orgIdRes = await apollo.query({ query: queries.currentOrgId });
	const orgId = orgIdRes.data?.currentOrgId;
	const currentTeamIdRes = await apollo.query({ query: queries.currentTeamId });
	const teamId = currentTeamIdRes.data?.currentTeamId;
	const uptimeRes = await apollo.query({query: queries.uptime});
	const uptime = uptimeRes.data.uptime;

	const logMessage = {
		...parameters, 
		hashedUserID: analyticsUserID,
		orgId: orgId,
		teamId: teamId,
		sessionDuration: uptime,
		deviceModel: deviceModel,
		os: os
	};

	if (analyticsInstance == null)
	{
		console.log("logFirebaseEvent: instance is null");
		return;
	}
	analyticsInstance.logEvent('glueos::' + eventName, logMessage);
	console.log('Logging analytics Firebase event: glueos::', eventName, logMessage);
};

export default firebase
