import { makeStyles, useTheme } from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState, forwardRef } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import getAssignerFnForRefs from '../../util/assigner-for-refs';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		overflow: 'hidden',
		height: '100%',
		maxHeight: 'inherit'
	},

	content: {
		width: '100%',
		height: 'fit-content',
		transform: ({contentPos}) => `translateY(-${contentPos}px)`,
		transition: ({dragging}) => !dragging && 'transform 0.1s',
		overflow: 'hidden'
	},

	scrollArea: {
		padding: `${theme.glueSpacing('s')} 0px`,
		width: ({visible, persistent}) => (visible || persistent) ? theme.custom.glueScroll.width : 0
	},

	scrollbar: {
		height: '100%',
	},

	thumb: {
		width: '100%',
		height: ({thumbHeight}) => `min(100%, ${thumbHeight}px)`,
		transform: ({scrollbarPos}) => `translateY(${scrollbarPos}px)`,
		opacity: ({visible}) => visible ? '100%' : '0%',
		transitionProperty: ({dragging}) => !dragging ? 'transform, opacity, height' : 'opacity, height',
		transitionDuration: '0.1s',

		'&:hover': {
			filter: 'brightness(1.25)',
		},

		'&:active': {
			filter: 'brightness(1.50)'
		},
	},

	thumbVisual: {
		width: theme.custom.glueScroll.thumbWidth,
		height: '100%',
		background: theme.palette.secondary.light,
		borderRadius: theme.custom.glueScroll.thumbRounding,
		margin: 'auto',
	}

}), { name: 'MuiGlueScroll' });

const GlueScroll = (props, ref) =>
{
	const scrollbarRef = useRef();
	const scrollAreaRef = useRef();
	const contentRef = useRef();
	const theme = useTheme();
	
	const [thumbHeight, setThumbHeight] = useState(0);
	const [scroll, setScroll] = useState(0); // Normalized 0 - 1, where 1 is maximum range
	const [visible, setVisible] = useState(false);

	const updateBar = useCallback(() => {
		const contentHeight = contentRef.current?.scrollHeight ?? 0;
		const scrollAreaHeight = scrollAreaRef.current?.scrollHeight ?? 0;
		const scrollbarHeight = scrollbarRef.current?.offsetHeight ?? 0;
		const thumbMinHeight = parseFloat(theme.custom.glueScroll.thumbMinHeight);

		const scrollAreaContentRatio = scrollAreaHeight / contentHeight;
		const thumbHeightNormalized = Math.min(1, scrollAreaContentRatio);
		if (thumbHeightNormalized < 1)
		{
			setVisible(true);
			if (props.autoScrollToEnd)
				setScroll(1);
		}
		else
		{
			setVisible(false);
			// Reset scroll when all content fits into the scroll panel
			setScroll(0);
		}
		const thumbHeightPx = scrollbarHeight * thumbHeightNormalized;
		setThumbHeight(Math.max(thumbHeightPx, thumbMinHeight));
	}, [scrollbarRef, contentRef, theme]);

	const [contentResizeObserver] = useState(
		new ResizeObserver(entries => updateBar())
	);

	useEffect(() => {
		if (contentRef.current)
			contentResizeObserver.observe(contentRef.current);
		else
			contentResizeObserver.disconnect();

		updateBar();

		return () => {
			contentResizeObserver.disconnect();
		};
	}, [contentRef, contentResizeObserver, updateBar]);

	const [scrollbarResizeObserver] = useState(
		new ResizeObserver(entries => updateBar())
	);

	useEffect(() => {
		if (scrollbarRef.current)
		scrollbarResizeObserver.observe(scrollbarRef.current);
		else
		scrollbarResizeObserver.disconnect();

		updateBar();

		return () => {
			scrollbarResizeObserver.disconnect();
		};
	}, [scrollbarRef, scrollbarResizeObserver, updateBar]);

	// Drag related things:
	const [drag, setDrag] = useState(null);
	const [dragPosition, setDragPosition] = useState(null);

	const beginDrag = (element, event) => {
		event.stopPropagation();
		setDrag({ element: element, startScreenPos: event.screenY, startScrollPos: scroll });
	}

	useEffect(() => {
		if (drag)
		{
			const diff = dragPosition - drag.startScreenPos;
			let newScrollValue = 0;

			if (drag.element === 'scrollbar')
			{
				const scrollbarHeight = scrollbarRef.current?.offsetHeight ?? 0;
				const scrollbarRange = scrollbarHeight - thumbHeight;
	
				const proportionalDiff = diff / scrollbarRange;
				newScrollValue = drag.startScrollPos + proportionalDiff;
			}
			else if (drag.element === 'content')
			{
				const contentHeight = contentRef.current?.scrollHeight ?? 0;
				const scrollAreaHeight = scrollAreaRef.current?.offsetHeight ?? 0;
				const contentRange = Math.max(0, contentHeight - scrollAreaHeight);
	
				const proportionalDiff = diff / contentRange;
				newScrollValue = drag.startScrollPos - proportionalDiff;
			}

			newScrollValue = Math.min(newScrollValue, 1);
			newScrollValue = Math.max(newScrollValue, 0);
			if (isFinite(newScrollValue))
				setScroll(newScrollValue);
		}
	}, [drag, dragPosition, thumbHeight])

	useEffect(() => {
		const onDrag = (e) =>
		{
			setDragPosition(e.screenY);
		};
	
		const onDragEnd = () =>
		{
			setDrag(null);
		};

		document.addEventListener('pointermove', onDrag);
		document.addEventListener('pointerup', onDragEnd);

		return () => {
			document.removeEventListener('pointermove', onDrag);
			document.removeEventListener('pointerup', onDragEnd);
		};
	}, [])

	const onWheelScroll = (event) => {
		event.stopPropagation();

		const contentHeight = contentRef.current?.scrollHeight ?? 0;
		const scrollAmount = event.deltaY / contentHeight;

		let newScrollValue = scroll + scrollAmount;
		newScrollValue = Math.min(newScrollValue, 1);
		newScrollValue = Math.max(newScrollValue, 0);
		if (isFinite(newScrollValue))
			setScroll(newScrollValue);
	};

	const contentHeight = contentRef.current?.scrollHeight ?? 0;
	const scrollAreaHeight = scrollAreaRef.current?.scrollHeight ?? 0;
	const scrollbarHeight = scrollbarRef.current?.offsetHeight ?? 0;

	const scrollbarRange = scrollbarHeight - thumbHeight;
	const contentRange = contentHeight - scrollAreaHeight;

	const scrollbarPos = scroll * scrollbarRange;
	let contentPos = scrollbarPos * (contentRange / scrollbarRange);
	if (!isFinite(contentPos))
		contentPos = 0;

	const dragging = !!drag;

	const classes = useStyles({...props, visible, thumbHeight, scrollbarPos, contentPos, dragging});

	return (
		<div className={classes.root} onWheel={onWheelScroll}>
			<div className={classes.content} ref={getAssignerFnForRefs([contentRef, props.contentRef])} onPointerDown={(e) => beginDrag('content', e)}>
				{props.children}
			</div>
			<div className={classes.scrollArea} ref={scrollAreaRef}>
				<div className={classes.scrollbar} ref={scrollbarRef}>
					<div className={classes.thumb} onPointerDown={(e) => beginDrag('scrollbar', e)}>
						<div className={classes.thumbVisual}/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default forwardRef(GlueScroll);