import React from 'react';
import queries from '../../graphql/queries';
import { useNavigate } from "react-router-dom";
import AppHeader from '../common/app-header';
import { makeStyles } from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import GlueButton from '../common/glue-button';
import FacilitationSession from './session';
import postVuplexMessage from '../../service/message-vuplex';
import { ApolloClient } from '@apollo/client';
import { setKeyboardActive } from '../../util/control-util';

const useStyles = makeStyles(theme => ({

	contentArea: {
		height: '100%',
		margin: 'auto',
		display: 'flex',
		justifyContent: 'center',
		padding: `0 ${theme.glueSpacing('s')} 0`,
	},

}));

let apollo : ApolloClient<unknown>;

export const setupFacilitation = (apolloparam: ApolloClient<unknown>) =>
{
	apollo = apolloparam;
}

export const updateFacilitatorStatus = async () =>
{

	const isFacilitatorResult = apollo?.readQuery({
		query: queries.isFacilitator
	});

	const currentFacilitatorStatus = isFacilitatorResult.isFacilitator;

	const currentTeamId = apollo?.readQuery({
		query: queries.currentTeamId
	});

	const myAccessInfo = await apollo?.query({
		query: queries.myAccessInfo,
			variables: {
				teamId: currentTeamId?.currentTeamId
			},		
	});

	const updatedFacilitatorStatus = myAccessInfo?.data.myAccessInfo?.team.isFacilitator;

	if(updatedFacilitatorStatus != currentFacilitatorStatus)
	{
		apollo?.writeQuery({
			query: queries.isFacilitator,
			data: { isFacilitator: updatedFacilitatorStatus }
		})

		if (updatedFacilitatorStatus)  
		{
			// MuteAll / MicrophoneLocked
			const microphoneLocked = apollo?.readQuery({
				query: queries.microphoneLocked
			});

			apollo?.writeQuery({
				query: queries.muteAllEnabled,
				data: { muteAllEnabled: microphoneLocked?.microphoneLocked}
			});

			apollo?.writeQuery({
				query: queries.microphoneLocked,
				data: { microphoneLocked: false}
			});
			
			// uiDisabledState / uiDisabled
			const uiDisabled = apollo?.readQuery({
				query: queries.uiDisabled
			});

			if(uiDisabled?.uiDisabled)
			{
				postVuplexMessage('Facilitation.TabletDisabled', { value: false });  // release tablet
			}

			apollo?.writeQuery({
				query: queries.uiDisabledState,
				data: { uiDisabledState: uiDisabled?.uiDisabled }
			});

			apollo?.writeQuery({
				query: queries.uiDisabled,
				data: { uiDisabled: false }
			})
		}
		else
		{
			// MuteAll / MicrophoneLocked
			const muteAllEnabled = apollo?.readQuery({
				query: queries.muteAllEnabled
			});

			apollo?.writeQuery({
				query: queries.microphoneLocked,
				data: { microphoneLocked: muteAllEnabled?.muteAllEnabled}
			})

			if(muteAllEnabled?.muteAllEnabled)
			{
				apollo?.writeQuery({
					query: queries.microphoneEnabled,
					data: { microphoneEnabled: false }
				});
				postVuplexMessage('Facilitation.DisableMicrophone');
			}

			apollo?.writeQuery({
				query: queries.muteAllEnabled,
				data: { muteAllEnabled: false }
			})

			// uiDisabledState / uiDisabled
			const uiDisabledState = apollo?.readQuery({
				query: queries.uiDisabledState
			});

			apollo?.writeQuery({
				query: queries.uiDisabled,
				data: { uiDisabled: uiDisabledState?.uiDisabledState }
			});

			if(uiDisabledState?.uiDisabledState)
			{
				postVuplexMessage('Close tablet');
				postVuplexMessage('Facilitation.TabletDisabled', { value: true });

				setKeyboardActive(false, apollo);

				apollo?.writeQuery({
					query: queries.toolbarMode,
					data: { toolbarMode: 'main' }
				});
			}

			apollo?.writeQuery({
				query: queries.uiDisabledState,
				data: { uiDisabledState: false }
			})
		}
	}	
}

const FacilitationApp = () =>
{
	const classes = useStyles();
	const navigate = useNavigate();

	const title = "Facilitation";

	const launchExternalControls = () =>
	{
		postVuplexMessage("Spawn object", {id: 'sessioncontrol' });
		postVuplexMessage("Close tablet", null);
		navigate('/');
	}
	
	const facToolRight =
	(
		<GlueButton variant='icon' onPointerDown={launchExternalControls} >  
			<LaunchIcon />
		</GlueButton>
	);

	return (<>
		<AppHeader 
			title={title}
			secondChildren={facToolRight} 
		/>

		<div className={classes.contentArea}>
			<FacilitationSession />
		</div>
	</>);
};

export default FacilitationApp;
