import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import postVuplexMessage from '../service/message-vuplex';
import HeroIcon from './common/hero-icon';
import queries from '../graphql/queries';

import MouselookOnIcon from '../icons/hero/Mouselook_ON.png';
import MouselookOffIcon from '../icons/hero/Mouselook_OFF.png';

const toggleMouselook = () =>
{
	postVuplexMessage('Toggle mouselook', null);
};

const MouselookHeroIcon = (props) =>
{
	const { data } = useQuery(queries.mouselookEnabled);

	const clientPlatformResult = useQuery(queries.clientPlatform);
	const clientPlatform = clientPlatformResult.data?.clientPlatform;
	const vr = clientPlatform?.PlatformType === "VR";
	
	if (!data)
		return null;

	const toggleOffSound = "Tools/Mouselook/Toggle/Off/Press";
	const toggleOnSound = "Tools/Mouselook/Toggle/On/Press";
	const audiomessage = data.mouselookEnabled ? toggleOffSound : toggleOnSound;
	const hoveraudiomessage = "Tools/Mouselook/HL";

	return (
		<HeroIcon
			disableName={props.disableName}
			noBackground={props.noBackground}
			name="Mouselook"
			icon={MouselookOnIcon}
			iconOff={MouselookOffIcon}
			onPointerDown={toggleMouselook}
			uiAudioMessage={audiomessage}
			uiHoverAudioMessage={hoveraudiomessage} 
			toggled={data.mouselookEnabled}
			disabled={vr}
			hotkey="Esc"
		/>
	);
};

export default MouselookHeroIcon;
