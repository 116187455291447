import React, { useRef, useState } from 'react';
import { Popover, makeStyles, useTheme, PopoverActions } from '@material-ui/core';
import GlueScroll from './glue-scroll';

type ScrollablePopoverProps = {
	width?: string
	open: boolean
	invert: boolean
	setInvert: (state: boolean) => void
	horizontal: 'left' | 'center' | 'right'
	vertical: 'bottom' | 'center' | 'top'
	verticalOffset?: string | number
	onClose: () => void
	anchorRef: React.RefObject<HTMLElement>
	firstElement?: React.ReactNode
	children?: React.ReactNode
};

type ScrollablePopoverStyleProps = { 
	listWidth?: string
	listHeight?: string
};

const useStyles = makeStyles((theme) => ({
	popover: {
		background: theme.palette.secondary.main,
		borderRadius: '4px',
		width: (props: ScrollablePopoverStyleProps) => props.listWidth,
		oveflow: 'hidden',

		transition: 'none !important'
	},

	list: {
		maxHeight: (props: ScrollablePopoverStyleProps) => props.listHeight,
	},
}), { name: 'MuiGlueEllipsisMenu' });

const GlueScrollablePopover = (props: ScrollablePopoverProps) =>
{
	const theme = useTheme();

	const [ listHeight, setListHeight ] = useState<number>(0);

	const calculateListHeight = (content?: HTMLDivElement) => {
		if (props.anchorRef.current && content)
		{
			const itemHeight = parseFloat(theme.custom.glueListItem.height as string);
			const preferredHeight = content.offsetHeight;
			const boundingRect = props.anchorRef.current.getBoundingClientRect();

			let height = 0;
			let inv = false;

			let possibleHeight = window.innerHeight - boundingRect.bottom;
			if (possibleHeight < preferredHeight)
			{
				// Is there more room on top, invert
				if (boundingRect.top > possibleHeight)
				{
					inv = true;
					possibleHeight = boundingRect.top;
				}

				height = Math.min(preferredHeight, Math.floor(possibleHeight / itemHeight) * itemHeight);
			}	
			else height = preferredHeight;

			setListHeight(height);
			props.setInvert(inv);
		}
		actionRef.current?.updatePosition();
	}

	const calculateVerticalTransformOrigin = (offset: number) => {
		switch(props.vertical) {
		case 'bottom':
			return props.invert ? listHeight - offset : 0 - offset;
		case 'center':
			return props.invert ? listHeight + offset : 0 - offset;
		case 'top':
			return props.invert ? listHeight + offset : 0 + offset;
		default: 
			return props.vertical;
		}
	}

	const verticalTransformOrigin = !!props.verticalOffset ? 
		calculateVerticalTransformOrigin(typeof props.verticalOffset === 'string' ? parseFloat(props.verticalOffset) : props.verticalOffset) : 
			props.vertical;

	const classes = useStyles({ listWidth: props.width, listHeight: `${listHeight}px` });
	const actionRef = useRef<PopoverActions>(null);

	return (
		<Popover
			action={actionRef}
			open={props.open}
			onClose={() => props.onClose()}
			anchorEl={props.anchorRef.current}
			anchorOrigin={{
				vertical: props.vertical,
				horizontal: props.horizontal,
				}}
			transformOrigin={{
				vertical: verticalTransformOrigin,
				horizontal: props.horizontal,
				}}
			classes={{ paper: classes.popover }}
			BackdropProps={{ invisible: true, onWheel: (e) => e.stopPropagation(), onPointerDown: (e) => e.stopPropagation() }} // Prevent scrolling in the background
		>
			{!props.invert && !!props.firstElement && props.firstElement}
			<div className={classes.list}>
				<GlueScroll contentRef={calculateListHeight}>
					{props.children}
				</GlueScroll>
			</div>
			{props.invert && !!props.firstElement && props.firstElement}
		</Popover>
	);
}

export default GlueScrollablePopover;
