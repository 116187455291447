import React from "react";
import { makeStyles } from "@material-ui/core";
import GlueButton from './glue-button';

import ErrorIconWeb from '../../images/ErrorIcon_Web.png';

import { usePromptDialogContext } from "../../util/prompt-dialog-context";

import { Palette } from "@material-ui/core/styles/createPalette";

type InfoDialogProps = {
	header?: string,
	isError?: boolean,
	message: string,
	variable?: string,
	callbacks?: Array<ButtonProps>
}

type ButtonProps = {
	label?: string,
	color?: keyof Palette,
	callback: () => void
}

const useStyles = makeStyles((theme) => ({
	root: theme.custom.infoDialog.root,

	header: theme.custom.infoDialog.header,

	icon: theme.custom.infoDialog.icon,

	body: theme.custom.infoDialog.body,

	variableString: theme.custom.infoDialog.variableString,

	buttons: theme.custom.infoDialog.buttons
}));

const InfoDialogTemplate = (props: InfoDialogProps) =>
{
	const classes = useStyles();

	const { closePromptDialog } = usePromptDialogContext();

	return(
		<div className = { classes.root } >
			{props.isError ? <img className={classes.icon} src={ErrorIconWeb} alt='' /> :
				<div className={classes.header}>
					<h2>{props.header}</h2>
				</div>}
			< div className = { classes.body } >
				{ props.message }
			</div >
			{props.variable && <div className={classes.variableString}>
					{props.variable}
				</div>
			}
			<div className = { classes.buttons } >
			{props.callbacks ? props.callbacks.map((btn: ButtonProps, i: number) => (
				<GlueButton key={i}
					onPointerDown={() => { btn.callback(); closePromptDialog() }}
					color={btn.color ? btn.color : 'secondary'} >
					{btn.label ? btn.label : 'Submit'}
				</GlueButton>
			)) : <GlueButton color="primary" onPointerDown={() => closePromptDialog()}>OK</GlueButton>
			}
			</div >
		</div >
	);
}

export default InfoDialogTemplate;