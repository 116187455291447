export const orgUserSelection = [
	{ id: 'guest', name: 'Guest' },
	{ id: 'member', name: 'Member' },
	{ id: 'admin', name: 'Admin' }
];

/**
 * 
 * @param {Array} orgTeamsRes 
 * @param {string} memberEmail 
 * @returns 
 */
export const getMemberOrgTeams = (orgTeams, memberEmail) =>
{
	let memberOrgTeams = orgTeams.filter(team => team.members.some(member => member.email === memberEmail)) ?? [];

	return memberOrgTeams;
}