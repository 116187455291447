import postVuplexMessage from '../../service/message-vuplex';
import queries from '../../graphql/queries';

export const hoverAudio = (message) => 
{
	//console.log("hoverAudio ", message);
	postVuplexMessage('Trigger audio', { UIAudioMessage: message });
}

export const clickAudio = (message) => 
{
	//console.log("clickAudio", message);
	postVuplexMessage('Trigger audio', { UIAudioMessage: message });
}

export const updateDSPSettings = (apollo) =>
{
	const settingsData = apollo.readQuery({ query: queries.dspSettings });
	postVuplexMessage('Set DSP settings', {
		lowerThreshold: settingsData.dspSettings.lowerThreshold,
		upperThreshold: settingsData.dspSettings.upperThreshold,
		holdTime: settingsData.dspSettings.holdTime,
		attack: settingsData.dspSettings.attack,
		release: settingsData.dspSettings.release,
		energyThreshold: settingsData.dspSettings.energyThreshold,
		gainOneThreshold: settingsData.dspSettings.gainOneThreshold,
		gainTwoThreshold: settingsData.dspSettings.gainTwoThreshold,
		muMax: settingsData.dspSettings.muMax,
		muAfterSilence: settingsData.dspSettings.muAfterSilence,
		thresholdBackground: settingsData.dspSettings.thresholdBackground,
		thresholdForeground: settingsData.dspSettings.thresholdForeground,
		thresholdBackgroundDetectionOff: settingsData.dspSettings.thresholdBackgroundDetectionOff,
		thresholdForegroundDetectionOff: settingsData.dspSettings.thresholdForegroundDetectionOff, 
		thresholdSilence: settingsData.dspSettings.thresholdSilence,
		resetSpeed: settingsData.dspSettings.resetSpeed,
		adaptationTimeAfterSilence: settingsData.dspSettings.adaptationTimeAfterSilence
	});	
}

export const updateDSPToggles = (apollo) =>
{
	const toggleData = apollo.readQuery({ query: queries.dspToggles });
	postVuplexMessage('Set DSP toggles', {
		normalization: toggleData.dspToggles.normalization,
		noiseGate: toggleData.dspToggles.noiseGate,
		echoCancellation: toggleData.dspToggles.echoCancellation,
	});

}

export const updateEncodingSettings = (apollo) =>
{
	const settingsData = apollo.readQuery({ query: queries.encodingSettings });
	postVuplexMessage('Set encoding settings', {
		targetFramerate: settingsData.encodingSettings.targetFramerate,
		targetBitrate: settingsData.encodingSettings.targetBitrate,
		threadCount: settingsData.encodingSettings.threadCount,
		framebufferCount: settingsData.encodingSettings.framebufferCount,
		outputBufferSize: settingsData.encodingSettings.outputBufferSize,
		qMin: settingsData.encodingSettings.qMin,
		qMax: settingsData.encodingSettings.qMax,
		slices: settingsData.encodingSettings.slices,
		profile: settingsData.encodingSettings.profile,
		keyIntMin: settingsData.encodingSettings.keyIntMin,
		fallbackQuality: settingsData.encodingSettings.fallbackQuality,
		fallbackSendInterval: settingsData.encodingSettings.fallbackSendInterval,
		fallbackReceiveInterval: settingsData.encodingSettings.fallbackReceiveInterval,
		fallbackWaitThreshold: settingsData.encodingSettings.fallbackWaitThreshold
	});
}
