import React, { useRef, useState } from 'react';
import { useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { MoreHoriz, MoreVert } from '@material-ui/icons';
import { Palette, PaletteColor } from '@material-ui/core/styles/createPalette';
import { clickAudio, hoverAudio } from './common-vuplex-messages';
import GlueScrollablePopover from './glue-scrollable-popover';

type EllipsisMenuProps = {
	width?: string
	vertical?: boolean
	open?: boolean
	color?: keyof Palette
	uiAudioMessage?: string
	uiHoverAudioMessage?: string
	onToggleOpen?: () => void
	children?: React.ReactNode
};

const useStyles = makeStyles((theme) => ({
	button: {
		...theme.custom.glueButton['icon'],
		background: (props: EllipsisMenuProps) => props.open ? (theme.palette[props.color ?? 'secondary'] as PaletteColor).light : (theme.palette[props.color ?? 'secondary'] as PaletteColor).dark,
	},

	clickArea: {
		...theme.custom.glueButton['icon'],
		width: '100%',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		cursor: 'pointer',

		'&:hover': {
			filter: 'brightness(107%)'
		},

		'&:active': {
			filter: 'brightness(120%)'
		},
	},
}), { name: 'MuiGlueEllipsisMenu' });

const GlueEllipsisMenu = (props: EllipsisMenuProps) => {
	const theme = useTheme();
	const [ invert, setInvert ] = useState(false);

	const audiomessage = !!props.uiAudioMessage ? props.uiAudioMessage : "Menu/Generic/Button/Press";
	const hoveraudiomessage = !!props.uiHoverAudioMessage ? props.uiHoverAudioMessage : "Menu/Generic/Button/HL";

	const anchorRef = useRef<HTMLDivElement>(null);

	const toggleOpen = () => {
		clickAudio(audiomessage);
		if (props.onToggleOpen) {
			props.onToggleOpen();
		}
	}

	const classes = useStyles(props);

	return (
		<>
			<div className={classes.button}>
				<div className={classes.clickArea}
					ref={anchorRef}
					onPointerDown={toggleOpen}
					onPointerEnter={() => {hoverAudio(hoveraudiomessage)}}	
				>
					{props.vertical ? <MoreVert/> : <MoreHoriz/>}
				</div>
			</div>
			<GlueScrollablePopover
				width={props.width}
				open={!!props.open}
				invert={invert}
				setInvert={setInvert}
				horizontal='right'
				vertical={invert ? 'top' : 'bottom'}
				verticalOffset={theme.glueSpacing('s')}
				onClose={() => props.onToggleOpen && props.onToggleOpen()}
				anchorRef={anchorRef}
			>
				{props.children}
			</GlueScrollablePopover>
		</>
	);
}

export default GlueEllipsisMenu;