import React from 'react';
import HeroIcon from './common/hero-icon';

import LocationsIcon from '../icons/hero/Locations.png';

const LocationsHeroIcon = (props) =>
{
	const hoveraudiomessage = "Menu/Locations/HL";
	const audiomessage = "Menu/Locations/Press";

	return (
		<HeroIcon
			name="Locations"
			icon={LocationsIcon}
			route="/app/locations" 
			disabled={props.disabled}
			uiAudioMessage={audiomessage}
			uiHoverAudioMessage={hoveraudiomessage} 
		/>
	);
};

export default LocationsHeroIcon;