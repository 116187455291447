import React from 'react';

import { useAvatar } from './avatar-hooks';
import AvatarCanvas from './avatarcanvas';

const AvatarViewer = (props: {
	width: string,
	height: string,
	currentTab?: string,
}) =>
{
	const avatar = useAvatar();

	return (
		<AvatarCanvas 
			avatar={avatar}
			width={props.width}
			height={props.height}
			id={"mainAvatarView"}
			targetZoomLevel={props.currentTab === "Appearance" ? 0.65 : 1 }
			targetHeight={props.currentTab === "Appearance" ? 1.6 : 1.5 }
		/>
	);
}

export default AvatarViewer;