import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';

import AppHeader from './common/app-header';
import postVuplexMessage from '../service/message-vuplex';
import { useQuery } from '@apollo/react-hooks';
import queries from '../graphql/queries';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexFlow: 'column nowrap',
	},

	appContent: {
		flex: '1 0 auto',
		display: 'flex',
		flexFlow: 'column nowrap',
		justifyContent: 'center',
		alignItems: 'center',

		paddingTop: '32px'
	},

	pickArea: {
		marginTop: '32px',
		height: '512px',
		width: '96px',
		background: 'none',
		border: '1px solid white',
		borderRadius: '99999999px',
	}
}));

const LaserPointerApp = (props) => {
	const classes = useStyles(props);

	const tabletOpenResult = useQuery(queries.tabletOpen);

	const resetTool = () =>
	{
		postVuplexMessage("Change tool", { name: 'Pointer', state: 1 });
	};

	const despawnAttachedTool = () =>
	{
		postVuplexMessage("Change tool", { name: 'Pointer', state: 2 });
	};

	useEffect(() => {
		console.log("tablet open: " + tabletOpenResult.data.tabletOpen);
		if (tabletOpenResult.data.tabletOpen)
			resetTool();

		return () => {
			despawnAttachedTool();
		}
	}, [tabletOpenResult.data.tabletOpen]);

	return (
		<div className={classes.root}>
			<AppHeader title="Laser Pointer"></AppHeader>

			<div className={classes.appContent}>
				<h2>Pick the laser pointer to point at things!</h2>
				<div className={classes.pickArea} onPointerDown={resetTool}></div>
			</div>
		</div>
	);
};

export default LaserPointerApp;
