import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";

import GlueRadioGroup from "../../standalone-web/common/glue-radio-group";
import GlueButton from "../common/glue-button";

const useStyles = makeStyles((theme) => ({
	root: {
		width: theme.custom.infoDialog.root.width,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		gap: theme.glueSpacing('l'),
		textAlign: 'center',
		padding: `0 ${theme.glueSpacing('l')}`
	},

	check: {
		display: 'flex',
		flexDirection: 'column',
		gap: theme.glueSpacing('s')
	},

	buttons: {
		display: 'flex',
		flexDirection: 'row',
		gap: theme.glueSpacing('m')
	}
}))

const RemoveMemberDialog = (props) => 
{
	const classes = useStyles();
	const options = [
		{ id: 'orgOnly', name: 'Remove from this organization only'},
		{ id: 'account', name: 'Remove completely from Glue'}
	]
	const [check, setCheck] = useState(options[0].id);

	return (
		<div className={classes.root}>
			<h2>{props.header}</h2>
			{props.message && props.message}
			{props.removeAccoMsg &&
				<div className={classes.check}>
					{props.removeAccoMsg}
					<GlueRadioGroup 
						value={check}
						options={options}
						onChange={setCheck}
					/>
				</div>}
			{props.notice && props.notice}
			<div className={classes.buttons}>
				<GlueButton onPointerDown={() => props.onClose()}>Cancel</GlueButton>
				<GlueButton onPointerDown={() => props.callback(check === 'orgOnly' ? false : true)} color="destructive">Remove</GlueButton>
			</div>
		</div>
	)
}

export default RemoveMemberDialog;