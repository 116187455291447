import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from "react-router-dom";

import AvatarEditor from '../avatarconfigurator/avatar-editor';

import postVuplexMessage from '../../service/message-vuplex';

import mutations from '../../graphql/mutations';
import { useMutation, useApolloClient } from '@apollo/react-hooks';

import { configuratorInitialized, initializeConfigurator, deinitializeConfigurator } from '../../service/avatar-configurator';
import AvatarViewHeader from './avatar-view-header';
import { useUserSettings } from '../../service/user-settings';
import GlueButton from '../common/glue-button';
import { usePromptDialogContext } from '../../util/prompt-dialog-context';
import InfoDialogTemplate from '../common/info-dialog-template';
import { logOut } from '../../util/app-utils';
import { useUserContext } from '../../util/user-context';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexFlow: 'column nowrap',
		alignItems: 'center',
		overflow: 'hidden',
	},

	deleteAccountBtn: {
		...theme.custom.contentBase,
		display: 'flex',
		justifyContent: 'end',
		marginBottom: theme.glueSpacing('m'),
	},
}), { name: 'MuiGlueMyAvatarView' });

const MyAvatarView = (props: {
	web?: boolean
}) => {
	const classes = useStyles();
	const navigate = useNavigate();

	const { addDialog } = usePromptDialogContext();

	const apolloClient = useApolloClient();

	useEffect(() => {
		postVuplexMessage("Open avatar app", null);

		return () => {
			postVuplexMessage("Close avatar app", null);
			deinitializeConfigurator();
		}
	}, [apolloClient]);

	const leaveAvatarApp = () =>
	{
		navigate('/');
	}

	const onConfirmAnimCompleted = () =>
	{
		if (mutationLoading)
		{
			return;
		}

		hideConfirmAnim();
	}

	const onMutationCompleted = () =>
	{
		hideConfirmAnim();
	}

	const hideConfirmAnim = () =>
	{
		setShowConfirm(false);

		if (leaving === true)
		{
			leaveAvatarApp();
		}
	}

	const [ currentTab, setCurrentTab ] = useState("Overview");

	const user = useUserContext();

	const { userSettings, setUserSettings, settingsLoading, mutationLoading } = useUserSettings({onMutationCompleted: onMutationCompleted});
	const [ showConfirm, setShowConfirm ] = useState(false);
	const [ leaving, setLeaving ] = useState(false);
	const [ removeAccount ] = useMutation(mutations.deleteUserAccount);

	if (settingsLoading || !userSettings || !user.email)
		return null;

	if (!configuratorInitialized())
	{
		initializeConfigurator(userSettings.avatar, userSettings.nickname ? userSettings.nickname : user.email);
	}

	const deleteAccount = async() =>
	{
		await removeAccount({
			variables: {
				userId: user.email
			}
		});

		logOut();
	}

	const deleteConfirm = () =>
	{
		addDialog(<InfoDialogTemplate 
			header={'Delete account?'}
			message={'You are about to delete your user account in Glue. Are you sure you want to? This action is not reversable and your account and all information will be lost.'}
			callbacks={[
				{ label: 'Cancel', callback: () => null },
				{ label: 'Delete', color: 'destructive', callback: () => deleteAccount()}
			]}
		/>)
	}

	return (
		<div className={classes.root}>
			<AvatarViewHeader web={props.web} currentTab={currentTab} setCurrentTab={setCurrentTab} updateSettings={setUserSettings} showConfirm={() => setShowConfirm(true)} leave={() => setLeaving(true)} leaveImmediate={leaveAvatarApp}/>
			<AvatarEditor 
				currentTab={currentTab}
				email={!props.web ? user.email : ''}
				avatarViewWidth={'628px'}
				avatarViewHeight={'848px'}
				showConfirm={showConfirm}
				onConfirmAnimCompleted={onConfirmAnimCompleted}
			/>
			{props.web && 
				<div className={classes.deleteAccountBtn}>
					<GlueButton color="destructive" onPointerDown={() => deleteConfirm()}>Delete Account</GlueButton>
				</div>}
		</div>
	);
}

export default MyAvatarView;