import React from 'react';

import { Dialog, DialogProps } from '@material-ui/core';

const GlueDialog = (props: DialogProps) =>
{
	const handleClose = (event: React.PointerEvent, reason: string) =>
	{
		if (reason === 'backdropClick') {
			return;
		}
	}
//
	return (<>
		<Dialog
			onClose={handleClose}
			{...props}
		>
			{props.children}
		</Dialog>
	</>);
}

export default GlueDialog;