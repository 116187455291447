import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { makeStyles } from "@material-ui/core";
import WebInputfield from "../../standalone-web/common/web-inputfield";
import GlueDropdown from "../common/glue-dropdown";

import mutations from "../../graphql/mutations";

import { licenseTiers } from "../../defines";
import { usePromptDialogContext } from "../../util/prompt-dialog-context";
import GlueButton from "../common/glue-button";
import InfoDialogTemplate from "../common/info-dialog-template";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.glueSpacing('m'),
		width: '500px',
		display: 'flex',
		flexDirection: 'column',
		gap: theme.glueSpacing('s')
	}
}));

const CreateOrg = (props) =>
{
	const classes = useStyles();

	const { addDialog, closePromptDialog } = usePromptDialogContext();

	const [ newOrgName, setNewOrgName ] = useState('');
	const [ licenseTier, setLicenseTier ] = useState(0);
	const [ numberOfSeats, setNumberOfSeats ] = useState(10);

	const [createNewOrganization] = useMutation(mutations.createOrg)

	const createOrganization = async () => {
		if (!newOrgName || newOrgName === '') {
			console.error("No Name for org, canceling....")
			return;
		}

		if (typeof licenseTier !== 'number') {
			console.error(typeof licenseTier)
			console.log(licenseTier)
			return;
		}
		try {
			let mut = await createNewOrganization({
				variables: {
					name: newOrgName,
					licenseTier: licenseTier,
					numberOfSeats: numberOfSeats
				}
			});
			if (mut.data) {
				props.refetch()
				addDialog(<InfoDialogTemplate
					header={'Success'}
					message={`New organization ${newOrgName} was created`}
				/>);
			}
		} catch (e) {
			addDialog(<InfoDialogTemplate 
				isError
				message={e.message}
			/>);
		} finally {
			closePromptDialog()
		}
	}

	return(
		<div className={classes.root}>
			Organization name:
			<WebInputfield 
				placeholder={'Organization name...'}
				value={newOrgName}
				onChange={setNewOrgName}
				maxLength={60}
			/>
			License tier:
			<GlueDropdown 
				width={'200px'}
				onChange={(id, checked) => { checked && setLicenseTier(id) }}
				items={licenseTiers}
				defaultValue={licenseTier ?? ' '}
			/>
			Number of seats:
			{licenseTier !== 0 && <WebInputfield 
					value={numberOfSeats}
					onChange={e => setNumberOfSeats(e)}
				/>}
			<GlueButton onPointerDown={() => closePromptDialog()}>Cancel</GlueButton>
			<GlueButton color="primary"
				onPointerDown={() => createOrganization()}>Create</GlueButton>
		</div>
	);
}

export default CreateOrg;