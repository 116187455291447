import React, { useState } from 'react'
import { makeStyles } from "@material-ui/core";
import GlueEllipsisMenu from '../common/glue-ellipsis-menu';
import GlueMenuItem from '../common/glue-menu-item';
import SpaceThumbnail_Placeholder from '../../images/SpaceThumbnail_Placeholder.png';
import ArchiveIcon from '@material-ui/icons/Archive';
import AddIcon from '@material-ui/icons/Add';
import GlueIcon from '../common/glue-icon';
import { useQuery } from '@apollo/client';
import queries from '../../graphql/queries';
import { Asset, Maybe } from '../../graphql/types-generated';
import { assetVisibility } from '../space-asset/space-asset-common';
import { useUserContext } from '../../util/user-context';

type TemplateListItemProps = {
	asset?: Asset
	title: string
	visible?: boolean
	create?: boolean
	selected?: boolean
	showOptions: boolean
	thumbnailUrl?: Maybe<string>
	onSelectAsset: () => void
	onEditAsset: () => void
	onSetVisible: (visible: boolean) => void
	onPromote: () => void
	onEditDescription: () => void
}

const useStyles = makeStyles((theme) => ({
	root: (props: TemplateListItemProps) => ({
		...theme.custom.spaceTemplatesDialog.listItem,
		borderRadius: '4px',
		display: 'flex',
		alignItems: 'center',
		padding: `${theme.glueSpacing('xs')} ${theme.glueSpacing('s')} ${theme.glueSpacing('xs')} ${theme.glueSpacing('xs')}`,
		justifyContent: 'space-between',
		cursor: 'pointer',

		background: theme.palette.secondary.dark,
		color: theme.palette.secondary.contrastText,
		...theme.custom.selectOutline(props.selected, { borderRadius: '4px' }),

		'& p, img': {
			opacity: !props.visible ? '50%' : '100%',
		},

		'&:hover': {
			filter: 'brightness(107%)',
		},

		'&:active': {
			filter: 'brightness(120%)',
		},
	}),

	info: {
		display: 'flex',
		alignItems: 'center',
		gap: theme.glueSpacing('m'),
		overflow: 'hidden',
		whiteSpace: 'nowrap',

		'& p': {
			overflow: 'hidden',
			textOverflow: 'ellipsis'
		},
	},

	tumbnailBox: {
		...theme.custom.spaceTemplatesDialog.listItemThumb,
		position: 'relative'
	},

	thumbnail: {
		width: '100%',
		height: '100%',
		borderRadius: '4px',
		objectFit: 'cover',
		opacity: (props: TemplateListItemProps) => !props.visible ? '50%' : '100%'
	},

	visibleIcon: {
		display: (props: TemplateListItemProps) => !props.visible || !!props.create ? 'flex' : 'none',
		width: '100%',
		height: '100%',
		justifyContent: 'center',
		alignItems: 'center',
		zIndex: 1,
		position: 'absolute',
		bottom: 0,
		left: 0,
		color: theme.palette.secondary.contrastText
	},

	createIconCircle: {
		...theme.custom.icon,
		background: theme.palette.primary.main,
		borderRadius: `calc(${theme.custom.icon.width} / 2)`
	}
}), { name: 'MuiGlueSpaceTemplateListItem' });

const SpaceTemplateListItem = (props: TemplateListItemProps) => {
	const classes = useStyles(props);
	const user = useUserContext();
	const [ menuOpen, setMenuOpen ] = useState<boolean>(false);
	const [ highlighted, setHighlighted ] = useState(false);
	const accessRes = useQuery(queries.assetAccess, {
		skip: !props.asset,
		variables: {
			assetId: props.asset?.assetId
		}
	});

	const editable: boolean = accessRes.data?.assetAccess.edit ?? false;
	const promotable: boolean = props.asset?.permission === assetVisibility.team && accessRes.data?.assetAccess.permissionSet.includes(assetVisibility.org);
	const canSetVisibility: boolean = (((props.asset?.permission === assetVisibility.team || props.asset?.permission === assetVisibility.limited) && user.team?.assetVisibilitySet) || 
		((props.asset?.permission === assetVisibility.org || props.asset?.permission === assetVisibility.public) && user.org?.assetVisibilitySet)) ?? false;

	const showOptions = props.showOptions && (editable || promotable || canSetVisibility);

	return (
		<div className={classes.root}
			onPointerDown={() => props.onSelectAsset()}
			onPointerEnter={() => setHighlighted(true)}
			onPointerLeave={() => setHighlighted(false)}
		>
			<div className={classes.info}>
				<div className={classes.tumbnailBox}>
					{!!props.create ? (
						<div className={classes.visibleIcon}>
							<div className={classes.createIconCircle}>
								<AddIcon />
							</div>
						</div>
					) : (
						<>
							<img className={classes.thumbnail} src={props.thumbnailUrl ?? SpaceThumbnail_Placeholder} alt={''}/>
							<div className={classes.visibleIcon}>
								<GlueIcon>
									<ArchiveIcon />
								</GlueIcon>
							</div>
						</>
					)}
				</div>
				<p>{props.title}</p>
			</div>
			{showOptions && highlighted === true && (
				<GlueEllipsisMenu vertical open={menuOpen} onToggleOpen={() => setMenuOpen(!menuOpen)}>
					{editable && props.visible && (
						<GlueMenuItem 
							onPointerDown={props.onEditAsset}
						>
							Edit
						</GlueMenuItem>
					)}
					{editable && props.visible && (
						<GlueMenuItem 
							onPointerDown={props.onEditDescription}
						>
							Edit description
						</GlueMenuItem>
					)}
					{canSetVisibility && 
						<GlueMenuItem
							onPointerDown={() => props.onSetVisible(!props.visible)}
						>
							{props.visible ? 'Archive' : 'Unarchive'}
						</GlueMenuItem>
					}
					{promotable && props.visible && (
						<GlueMenuItem
							onPointerDown={props.onPromote}
						>
							Promote to org
						</GlueMenuItem>
					)}
				</GlueEllipsisMenu>
			)}
		</div>
	);
}

export default SpaceTemplateListItem;
