import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import GlueButton from '../common/glue-button';
import GlueRadioGroup from '../../standalone-web/common/glue-radio-group';
import { isStandaloneDevice } from '../../util/platform-utils';
import { useApolloClient } from '@apollo/client';

const useStyles = makeStyles(theme => ({
	root: {
		overflow: 'hidden',
	},

	header: {
		height: theme.custom.errorDialog.header.height,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		marginBottom: theme.glueSpacing('m')
	},

	body: {
		margin: `0 ${theme.glueSpacing('l')}`,
		width: '480px',
		display: 'flex',
		flexFlow: 'column',
		alignItems: 'center',
		textAlign: 'center',
		gap: theme.glueSpacing('xl')
	},

	dialogCheckbox: {
		width: 'fit-content',
		justifySelf: 'center',
	},

	buttons: {
		margin: 'auto',
		width: 'fit-content',
		display: 'grid',
		gridAutoFlow: 'column',
		gap: theme.glueSpacing('m')
	},

	warning: {
		color: theme.palette.warning.main
	},

}));


const SelectUploadLocation = (props: {
	onSelect: (files: boolean) => void
	onCancel: () => void
}) =>
{
	const classes = useStyles();
	const apollo = useApolloClient();
	const [selection, setSelection] = useState('files');

	const standalone = isStandaloneDevice(apollo);

	const uploadOptions = [
		{ id: 'local', name: 'From this device', disabled: standalone },
		{ id: 'files', name: 'From this team\'s Files' }
	];

	return (
		<div className={classes.root}>
			<div className={classes.header}>
				<h2>Select upload location</h2>
			</div>
			<div className={classes.body}>
				<div className={classes.dialogCheckbox}>
					<GlueRadioGroup 
						value={selection}
						options={uploadOptions}
						onChange={setSelection}
					/>
				</div>
				{standalone && (
					<p className={classes.warning}>
						Uploading from standalone VR<br />devices is not currently supported,<br />but you can use Glue Web instead.
					</p>
				)}
				<div className={classes.buttons}>
					<GlueButton
						onPointerDown={props.onCancel}
					>
						Cancel
					</GlueButton>
					<GlueButton
						color="primary"
						onPointerDown={() => props.onSelect(selection === 'files')}
					>
						<p>Select</p>
					</GlueButton>
				</div>
			</div>
		</div>
	);
};

export default SelectUploadLocation;
