export type Type = {
	invitationPendingColor: string
	dialogWidth: string
	baseGap: string
	avatarPictureMargin: string
	emailAddressMargin: string
	roleBoxMargin: string
	invitationPendingMessageMargin: string
	invitationActionsMargin: string
	actionColumnWidth: string
	mainActionsMargin: string
};

const common: Type =
{
	invitationPendingColor: '#FCC21F',

	dialogWidth: '400px',
	baseGap: '12px',
	avatarPictureMargin: '12px 0 24px 0',
	emailAddressMargin: '0 0 18px 0',
	roleBoxMargin: '0 0 36px 0',
	invitationPendingMessageMargin: '0 0 50px 0',
	invitationActionsMargin: '0 0 0 0',
	actionColumnWidth: '210px',
	mainActionsMargin: '50px 0 0 0'
};

export const web: Type =
{
	...common
};

export const tablet: Type =
{
	...common,

	dialogWidth: '512px',
	baseGap: '24px',
	emailAddressMargin: '0 0 48px 0',
	roleBoxMargin: '0 0 48px 0',
	invitationPendingMessageMargin: '0 0 64px 0',
	actionColumnWidth: '312px',
	mainActionsMargin: '48px 0 0 0'
};
