import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Route, Routes, useNavigate } from "react-router-dom";

import SpaceAssetsApp from '../space-asset/space-assets-app';
import SearchUtility from '../../standalone-web/search-utility';
import Organizations from './organizations';
import SysAdmins from './sys-admins';
import TeamView from './team-view';
import AdminOrgView from './admin-org-view';
import TabHeader from '../common/tab-header';
import ViewHeader from '../views/view-header';
import HostNodes from './host-nodes';
import HostGroups from './host-groups';

const useStyles = makeStyles((theme) => ({
	root: {
		...theme.custom.contentBase,
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		margin: 'auto'
	},

	tabs: {
		paddingBottom: theme.glueSpacing('xl')
	}
}));

const AdministrativeView = () =>
{
	const classes = useStyles();
	const navigate = useNavigate();

	const tabInfos = [
		{ path: 'organizations', name: "Organizations" },
		{ path: 'space-assets', name: "Space Assets" },
		{ path: 'search-utility', name: "Search Utility" },
		{ path: 'sys-admins', name: "System Administrators" },
		{ path: 'orchestrators', name: 'Host Nodes'},
		{ path: 'clusters', name: 'Host Groups' },
	];

	return(
		<div className={classes.root}>
			<ViewHeader title={'Administration'} onClose={() => navigate('/')} />

			<div className={classes.tabs}>
				<TabHeader tabInfos={tabInfos} />
			</div>

			<Routes>
				<Route path={"space-assets/*"} element={<SpaceAssetsApp />} />
				<Route path={"search-utility"} element={<SearchUtility />} />
				<Route path={"organizations"} element={<Organizations />} />
				<Route path={'organizations/:orgId'} element={<AdminOrgView return={() => navigate('organizations')} />} />
				<Route path={"teams/:teamId"} element={<TeamView />} />
				<Route path={"sys-admins"} element={<SysAdmins />} />
				<Route path={"orchestrators"} element={<HostNodes />} />
				<Route path={"clusters"} element={<HostGroups />} />
			</Routes>
		</div>
	);
};

export default AdministrativeView;