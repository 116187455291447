import React, { useState } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core';

import queries from '../graphql/queries';
import WebInputfield from '../standalone-web/common/web-inputfield';
import GlueScroll from './common/glue-scroll';

const useStyles = makeStyles((theme) => ({
	root: {

	},

	listing: {
		height: '5em',
		background: theme.palette.background.paper
	},

	listItem: {
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
		gap: theme.glueSpacing('m'),

		'&:hover': {
			filter: 'brightness(107%)'
		},

		'&:active': {
			filter: 'brightness(120%)'
		},

		'& > h3': {
			color: theme.palette.warning.main
		}
	}
}));

const TeamSearch = (props) =>
{
	const classes = useStyles();
	const apollo = useApolloClient();

	const [searchFieldValue, setSearchFieldValue] = useState("");
	const [foundTeams, setFoundTeams] = useState(null);

	const search = async () => {
		const foundTeams = [];
		const res = await apollo.query({
			query: queries.findTeamsByName,
			variables: { name: searchFieldValue },
			fetchPolicy: 'network-only'
		}).catch(e => null);

		await Promise.all(res.data.findTeamsByName.map(async(team) => {

			await apollo.query({
				query: queries.getOrgIdOfTeam,
				variables: { teamId: team.teamId }
			})
			.then(async(res) => {
				if (!res.data.getOrgIdOfTeam) {
					console.error("Org ID not found!");
					return;
				}
				await apollo.query({
					query: queries.getOrg,
					variables: { orgId: res.data.getOrgIdOfTeam }
				}).catch(e => null)
				.then(res => {
					if (!res.data.getOrg) {
						console.error("No org data found.");
						return;
					}
					foundTeams.push({
						...team,
						parentOrg: res.data.getOrg.name
					}).catch(e => null)
				})
			}).catch(e => null);
		})).then(res => {
			setFoundTeams(foundTeams.length > 0 ? foundTeams : null);
		})
	};

	const clear = () => {
		setSearchFieldValue("");
		setFoundTeams(null);
	};

	const onSelect = (item) =>
	{
		const onChangeRes = props.onChange?.(item) ?? { clear: false };

		if (onChangeRes.clear)
			clear();
	};

	return (
		<div className={classes.root}>
			<div>
				<WebInputfield
					search
					value={searchFieldValue}
					onClear={() => clear()}
					onChange={(value) => setSearchFieldValue(value)}
					onSubmitKey={() => search()}
					showClearButton={Boolean(searchFieldValue)}
					placeholder='Search by Team name (Press "Enter" key to search)'
				/>
			</div>

			{!!foundTeams && (
				<div className={classes.listing}>
					<GlueScroll>
						{(foundTeams ?? []).map((item) => (
							<div key={item.teamId} className={classes.listItem} onPointerDown={() => onSelect(item)}>
								<h3>{item.name}</h3>
								<p>{'  Parent Org: ' + item.parentOrg}</p>
							</div>
						))}
					</GlueScroll>
				</div>
			)}
		</div>
	);
};

export default TeamSearch;
