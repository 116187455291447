import React from 'react';
import { makeStyles } from '@material-ui/core';
import TabRow, { TabInfoProps } from './tab-row';

const useStyles = makeStyles(theme => ({
	tabs: {
		width: '100%',
		height: theme.custom.header.height,
		minHeight: theme.custom.header.height,
		padding: theme.custom.header.padding,
		display: 'flex',
		flexFlow: 'row nowrap',
		justifyContent: 'center',
		alignItems: 'center',
		gap: theme.glueSpacing('xl'),

		background: theme.palette.background.default,
	},
}), { name: 'MuiGlueTabHeader' });

type TabHeaderProps = {
	tabInfos: TabInfoProps[]
}

const TabHeader = (props: TabHeaderProps) => {
	const classes = useStyles();

	return (
		<div className={classes.tabs}>
			<TabRow
				tabInfos={props.tabInfos}
			/>
		</div>
	)
};

export default TabHeader;
