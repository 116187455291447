import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import queries from '../graphql/queries';

import HeroIcon from './common/hero-icon';

import Finger from '../icons/Gesture_ON.png';
import GesturesDisabledIcon from '../icons/Gesture_FacLimit.png'

const GesturesHeroIcon = (props) =>
{
	const clickSound = "Tools/Settings/Press";
	const hoveraudiomessage = "Tools/Settings/HL";
	const uiDisabledResult = useQuery(queries.uiDisabled);
	const uiDisabled = uiDisabledResult.data?.uiDisabled;

	return (
		<HeroIcon
			name="Gestures"
			noBackground={props.noBackground}
			disabled={uiDisabled}
			icon={uiDisabled ? GesturesDisabledIcon : Finger}
			iconOff={uiDisabled ? GesturesDisabledIcon : Finger}
			uiAudioMessage={clickSound}
			uiHoverAudioMessage={hoveraudiomessage}
			onPointerDown={props.onPointerDown}
			hotkey="1"
			facLocked={uiDisabled}
		/>
	);
};

export default GesturesHeroIcon;