import { CSSProperties } from "@material-ui/core/styles/withStyles";

export type Type = {
	inputWidth: string
	dropdownWidth: string
	body: CSSProperties
};

const common: Type =
{
	inputWidth: '256px',
	dropdownWidth: '256px',

	body: {
		display: 'grid',
		gridTemplateColumns: 'repeat(4, 296px)',
		justifyContent: 'center'
	},

};

export const web: Type =
{
	...common,
	
	inputWidth: '200px',
	dropdownWidth: '200px',

	body: {
		display: 'flex',
		flexFlow: 'row wrap',
		justifyContent: 'flex-start',
		gap: '40px'
	},

};

export const tablet: Type =
{
	...common,

};