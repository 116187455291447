import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core';

import queries from '../../graphql/queries';
import GlueButton from '../common/glue-button';
import GlueDropdown from '../common/glue-dropdown';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		gap: '12px'
	}
}));

const SpaceSelector = (props) =>
{
	const classes = useStyles();

	const myRoomsRes = useQuery(queries.myRooms, {
		skip: !props.teamId,
		variables: {
			teamId: props.teamId,
			first: 0
		},
		fetchPolicy: "network-only"
	});

	const refresh = () =>
	{
		myRoomsRes.refetch();
	};

	const selectorItems = !myRoomsRes.data ? [] : (
		myRoomsRes.data.myRooms.map((item) => ({
			id: item.roomId,
			name: item.name
		}))
	);
	selectorItems.sort((a, b) => a.name.localeCompare(b.name));
	selectorItems.unshift({
		id: '',
		name: 'None'
	});

	return (
		<div className={classes.root}>
			<GlueButton onPointerDown={() => refresh()}>Refresh</GlueButton>
			<GlueDropdown
				defaultValue={null}
				onChange={(id, checked) => { checked && props.onSelect(id)}}
				items={selectorItems}
			/>
		</div>
	);
};

export default SpaceSelector;
