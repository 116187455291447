import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import PlayArrow from '@material-ui/icons/PlayArrow';

import GlueDialog from '../common/glue-dialog';
import FileViewVideo from '../files/file-view-video';

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.glueSpacing('m'),
		marginBottom: theme.glueSpacing('s')
	},

	thumbnail: {
		width: '400px',
		height: '224px'
	},

	playButton: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		boxShadow: '5px 5px 10px grey',

		width: '64px',
		height: '64px',
		background: 'white',
		borderRadius: '50%',
		position: 'relative',
		bottom: '90px',
		left: '320px'
	},

	descriptionText: {
		fontStyle: 'italic'
	},

	playArrow: {
		color: theme.palette.primary.main
	}
}));

const ThumbnailVideo = (props) => 
{
	const classes = useStyles();
	const [thumbnailURL, setThumbnailURL] = useState('');

	const [showVideoModal, setShowVideoModal] = useState(false);

	const toggleVideo = (toggleState) => {
		setShowVideoModal(toggleState)
	}

	useEffect(() => {
		if (!props.id)
		{
			return;
		}
		fetch(encodeURI(`https://vimeo.com/api/oembed.json?url=`) +encodeURIComponent(`https://vimeo.com/${props.id}`))
		.then(response => response.json())
		.then(function(data){setThumbnailURL(data.thumbnail_url)});
	}, [props.id])

	return (
		<div className={classes.root}>
			<h2>{props.title && props.title}</h2>
			<div
				className={classes.thumbnail}
				onPointerDown={() => toggleVideo(true)}
			>
				<img className={classes.thumbnail} src={thumbnailURL} alt=""/>
				<div className={classes.playButton}>
					<PlayArrow className={classes.playArrow}/>
				</div>
			</div>
			{props.description &&
				<p className={classes.descriptionText}>{"Video: " + props.description}</p>
			}
			
			<GlueDialog PaperProps={{ style: { padding: '0px', borderRadius: '44px' } }}
				fullWidth={true}
				maxWidth={'lg'}
				open={showVideoModal}
			>
				<FileViewVideo
					noPresenting
					closePreview={() => toggleVideo(false)}
					videoName={props.description}
					videoUrl={props.url}
				/>
			</GlueDialog>
		</div>
	);
}

export default ThumbnailVideo;