import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core';
import GlueButton from '../common/glue-button';
import { InventoryItem } from '../../graphql/types-generated';
import GlueInputfield from '../common/glue-inputfield';
import GlueToggle from '../common/glue-toggle';
import PersonIcon from '@material-ui/icons/Person';
import GlueDropdown from '../common/glue-dropdown';
import PageSelector from '../common/page-selector';
import { useQuery } from '@apollo/client';
import queries from '../../graphql/queries';
import { sttActive } from '../../util/speech-utils';
import { fileTypeOptions, getFileType, filterTypeAll, getHumanizedFileSize } from '../../util/file-utils';
import { useUserContext } from '../../util/user-context';
import { matchesSearchTerm } from '../../util/search-utils';
import LoadingIndicator from '../common/loading-indicator';
import GlueListView, { dateSortFunc, simpleSortFunc } from '../common/glue-list-view';
import FileThumbnail from '../files/file-thumbnail';
import { alphabeticalSortFunc } from '../common/glue-list-view';
import { glueDateDisplayFormat } from '../../util/app-utils';
import GlueIcon from '../common/glue-icon';
import { Maybe } from 'graphql/jsutils/Maybe';

const useStyles = makeStyles(theme => ({
	root: {
		height: '960px',
		overflow: 'hidden',
		display: 'flex',
		flexFlow: 'column'
	},

	header: {
		height: theme.custom.errorDialog.header.height,
		display: 'flex',
		flexShrink: 0,
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		marginBottom: theme.glueSpacing('m')
	},

	body: {
		padding: `0 ${theme.glueSpacing('m')}`,
		width: '1304px',
		display: 'flex',
		flexFlow: 'column',
		flexGrow: 1,
		textAlign: 'center',
		gap: '8px',
		overflow: 'hidden'
	},

	loadingIndicator: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		minHeight: '600px'
	},

	actions: {
		height: theme.custom.header.height,
		minHeight: theme.custom.header.height,
		display: 'flex',
		flexFlow: 'row nowrap'
	},

	actionsLeft: {
		flex: '1 0 auto',
		display: 'grid',
		gridAutoColumns: 'min-content',
		gridAutoFlow: 'column',
		gap: theme.glueSpacing('m'),
		alignItems: 'center'
	},

	actionsRight: {
		flex: '1 1 auto',
		display: 'flex',
		justifyContent: 'flex-end'
	},

	buttons: {
		margin: 'auto',
		width: 'fit-content',
		display: 'grid',
		gridAutoFlow: 'column',
		gap: theme.glueSpacing('m')
	},
}));

// This is a lot of copypasta but I wasn't able to figure out a better way...
const UploadFileBrowser = (props: {
	teamId: string
	multipleAllowed?: boolean
	acceptFilters?: string[]
	loading?: boolean
	onSelect: (items: InventoryItem[]) => void
	onCancel: () => void
}) =>
{
	const classes = useStyles();
	const theme = useTheme();
	const user = useUserContext();

	const [selected, setSelected] = useState<(string | number)[]>([]);
	const [ page, setPage ] = useState(0);
	const [ searchTerm, setSearchTerm ] = useState("");
	const [ personalOnly, setPersonalOnly ] = useState(false);
	const [ typeFilter, setTypeFilter ] = useState(filterTypeAll);

	const sttRes = useQuery(queries.speechRecognition);
	const searchSTTSinkId = 'UploadFileBrowser';

	const filesRes = useQuery<{ teamInventoryItems: InventoryItem[] }>(queries.teamInventoryItems, {
		variables: {
			teamId: props.teamId
		},
		fetchPolicy: 'cache-and-network',
		errorPolicy: 'all'
	});

	let filesFiltered = filesRes.data?.teamInventoryItems ?? [];
	filesFiltered = filesFiltered.filter(item => {
		if (!item) {
			return false;
		}

		const fileType = getFileType(item);

		if (personalOnly && item.creator !== user.email) {
			return false;
		}

		if (typeFilter !== filterTypeAll && fileType !== typeFilter) {
			return false;
		}

		if (searchTerm && searchTerm.length > 0 && !matchesSearchTerm(item.name, searchTerm)) {
			return false;
		}

		return true;
	});

	const itemsPerPageMax = 48;
	const pageCount = Math.ceil(filesFiltered.length / itemsPerPageMax);

	const getThumbnailContent = (item: InventoryItem) =>
	{
		return (
			<FileThumbnail item={item}/>
		);
	}

	const ownerSortFunc = (a: InventoryItem, b: InventoryItem, key: keyof InventoryItem, dir: number) =>
	{
		return a.creator === user.email ? -dir : dir
	}

	const columns = [
		{key: 'thumbnailUrl' as const, width: '64px', contentFunc: getThumbnailContent, noSorting: true},
		{key: 'name' as const, label: 'name', width: '3fr', contentFunc: undefined, sortFunc: alphabeticalSortFunc},
		{key: 'fileSize' as const, label: 'size', width: '96px', contentFunc: (item: InventoryItem) => getHumanizedFileSize(item.fileSize ?? 0), sortFunc: simpleSortFunc},
		{key: 'createdDate' as const, label: 'created', width: '1fr', contentFunc: (item: InventoryItem, key: keyof InventoryItem) => glueDateDisplayFormat(item[key] as Maybe<string>), sortFunc: dateSortFunc},
		{key: 'creator' as const, icon: (<PersonIcon/>), width: '128px', contentFunc: (item: InventoryItem) => (item.creator === user.email) && (<GlueIcon><PersonIcon/></GlueIcon>), sortFunc: ownerSortFunc},
	];

	const itemDisabled = (item: InventoryItem) => {
		if (!props.acceptFilters || props.acceptFilters.length === 0) {
			return false;
		}

		const passedFilters: boolean[] = props.acceptFilters.map(filter => {
			// Filter can either be a file extension or a mime type (with wildcards)

			// If file extension
			if (filter.startsWith('.')) {
				const periodSplit = item.itemUrl.split('.');
				const fileExtension = periodSplit[periodSplit.length - 1];
				if (fileExtension === filter.split('.')[1]) {
					console.log(`File extension ${fileExtension} matches filter ${filter}`);
				}
			}
			
			// Otherwise, it's a mime
			const match = (str: string, rule: string) => {
				const escapeRegex = (str: string) => str.replace(/([.*+?^=!:${}()|[\]/\\])/g, "\\$1");
				return new RegExp("^" + rule.split("*").map(escapeRegex).join(".*") + "$").test(str);
			}

			if (match(item.itemType, filter)) {
				return true;
			}

			return false;
		});

		return !passedFilters.some(result => result === true);
	}

	const onSelect = (newSelected: (string | number)[]) => {
		if (!props.multipleAllowed && newSelected.length > 1) {
			return;
		}
		setSelected(newSelected);
	}

	const onSubmit = () => {
		const files: InventoryItem[] = selected.map(id => filesFiltered.find(item => item.inventoryItemId === id)).filter(item => !!item) as InventoryItem[];
		props.onSelect(files);
	}

	return (
		<div className={classes.root}>
			<div className={classes.header}>
				<h2>{props.multipleAllowed ? 'Select files to upload' : 'Select a file to upload'}</h2>
			</div>
			<div className={classes.body}>
				<div className={classes.actions}>
					<div className={classes.actionsLeft}>
						<GlueInputfield
							width={theme.custom.files.inputWidth}
							search 
							value={searchTerm}
							sinkId={searchSTTSinkId} 
							speechInput={sttActive(sttRes, searchSTTSinkId)}
							onClear={()=>{ setSearchTerm("") }}
							onChange={(value: string) => {setSearchTerm(value); setPage(0)}}
							onSpeechChange={(value: string)=>{ setSearchTerm(value) }}
							showClearButton={searchTerm ? true : false}
							margin={"0px 0px 0px 0px"}
						/>

						<GlueToggle
							toggled={personalOnly}
							onPointerDown={() => {setPersonalOnly((value) => !value); setPage(0)}}
							uiAudioMessage={personalOnly ? "Menu/TeamFiles/MyFiles/Toggle/Off" : "Menu/TeamFiles/MyFiles/Toggle/On"}
							uiHoverAudioMessage="Menu/TeamFiles/MyFiles/HL" 
						>
							<PersonIcon />
						</GlueToggle>

						<GlueDropdown
							width={theme.custom.files.dropdownWidth} 
							defaultValue={'all'}
							onChange={(id, checked) => {checked && setTypeFilter(id as string); setPage(0)}}
							items={fileTypeOptions}
						/>
					</div>

					<div className={classes.actionsRight}>
						<PageSelector
							current={page}
							pageCount={pageCount}
							onPageChange={(pageNew) => setPage(pageNew)}
						/>
					</div>
				</div>
				{filesRes.loading ? (
					<div className={classes.loadingIndicator}>
						<LoadingIndicator />
					</div>
				) : (
					<GlueListView
						items={filesFiltered}
						overrideIdKey={'inventoryItemId'}
						columns={columns}
						start={page * itemsPerPageMax}
						count={itemsPerPageMax}
						selected={selected}
						onChangeSelection={onSelect}
						// onClickItem={onClickFile}
						defaultSortKey='createdDate'
						defaultSortDir={1}
						itemDisabledFunc={itemDisabled}
					/>
				)}
				<div className={classes.buttons}>
					<GlueButton
						onPointerDown={props.onCancel}
						disabled={props.loading}
					>
						Cancel
					</GlueButton>
					<GlueButton
						color="primary"
						disabled={!selected || selected.length === 0 || props.loading}
						onPointerDown={onSubmit}
					>
						{props.loading ? <LoadingIndicator variant='button' /> : <p>Select</p>}
					</GlueButton>
				</div>
			</div>
		</div>
	);
};

export default UploadFileBrowser;
