import { CSSProperties } from "@material-ui/core/styles/withStyles";

export type Type = {
	overview: CSSProperties
	featureTileSize: string
	featureTilesPerPage: number
	featurePageSelectorWidth: string
	featurePageSelectorHeight: string
};

const common: Type =
{
	overview: {
		display: 'flex',
		flex: '1 0 auto',
		flexFlow: 'column nowrap',
		justifyContent: 'center',
		overflow: 'hidden',
		width: '716px',
	},

	featureTileSize: '210px',
	featureTilesPerPage: 6,
	featurePageSelectorWidth: '64px',
	featurePageSelectorHeight: '64px',
};

export const web: Type =
{
	...common,

	overview: {
		paddingTop: '240px',
		width: '436px',
	},

	featureTileSize: '140px',
	featureTilesPerPage: 9,
	featurePageSelectorWidth: '36px',
	featurePageSelectorHeight: '36px',
};

export const tablet: Type =
{
	...common,
};
