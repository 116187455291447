import React, { useEffect, useState } from "react";

import { makeStyles, Typography } from "@material-ui/core";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import GlueListItem from "../common/glue-list-item";
import OrgIcon from "./org-icon";

import { hoverAudio, clickAudio } from "../common/common-vuplex-messages";
import GlueIcon from "../common/glue-icon";
import { Organization, Team } from '../../graphql/types-generated';

type OrgAccordionProps = {
	width?: string
	orgId: string
	organization: Organization
	teams?: Team[]
	currentOrg?: Organization
	selectedTeams?: string[]
	showId?: boolean
	onSelect?: (team: Team) => void
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: (props: OrgAccordionProps) => props.width ?? theme.custom.orgAccordion.width,
		background: theme.palette.secondary.dark,
		borderRadius: '4px'
	},

	header: {
		width: '100%',
		height: theme.custom.orgAccordion.height,

		display: 'flex',
		paddingLeft: theme.glueSpacing('s'),

		'&:hover': {
			filter: 'brightness(107%)'
		},

		'&:active': {
			filter: 'brightness(120%)'
		},
	},

	title: {
		flex: '1',
		marginLeft: theme.glueSpacing('s'),
		marginRight: theme.glueSpacing('m'),
		display: 'flex',
		alignItems: 'center',
		overflow: 'hidden'
	},

	titleType: {
		flex: '1',
		color: theme.palette.secondary.contrastText,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},

	divider: {
		height: '2px',
		background: theme.palette.secondary.main, // Could be a separate definition
	},

	listing: {
		flex: '1 1 auto',

		display: 'flex',
		flexFlow: 'column wrap',
		alignItems: 'stretch',
	},

}), { name: 'MuiGlueOrgAccordion' });

// This is a component for team selector specifically, but could become a common component in the future
// It's different enough from GlueAccordion to be its own thing, it's more like a dropdown really but not quite
const OrganizationAccordion = (props: OrgAccordionProps) => {

	const [ open, setOpen ] = useState(false);

	const clickAudioMessage = "Menu/Generic/Button/Press";
	const hoverAudioMessage = "Menu/Generic/Button/HL";
	const selaudiomessage = "Menu/Teams/Select/Press"
	const selhoveraudiomessage = "Menu/Teams/Select/HL"
			
	const toggleOpen = (event: React.PointerEvent<HTMLDivElement>) => {
		event.stopPropagation();
		
		setOpen(!open);
		clickAudio(clickAudioMessage);
	}
	
	const onSelect = (team: Team) =>
	{
		if (props.onSelect)
			props.onSelect(team);
	};
	
	const classes = useStyles(props);

	useEffect(() => {
		if (!open && props.currentOrg?.orgId === props.orgId) {
			setOpen(true);
		}
	}, [open, props.currentOrg, props.orgId])

	return (
		<div className={classes.root}>
			<div className={classes.header} onPointerDown={toggleOpen} onPointerEnter={() => {hoverAudio(hoverAudioMessage)}}>
				<GlueIcon>
					<OrgIcon
						name={props.organization.name}
						id={props.organization.orgId.slice(4)}
						variant='small'
						typeVariant='h3'
					/>
				</GlueIcon>
				<div className={classes.title}>
					<Typography variant='overline' classes={{root: classes.titleType}}>{props.organization.name} {props.showId && `(${props.organization.orgId})`}</Typography>
				</div>
				<GlueIcon>
					{open ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
				</GlueIcon>
			</div>
			{open && (
				<div className={classes.listing}>
					<div className={classes.divider}/>
					{props.teams?.map((team) => (
						<GlueListItem
							key={team.teamId}
							selected={props.selectedTeams?.some(selected => selected === team.teamId)}
							onPointerDown={() => onSelect(team)}
							uiAudioMessage = {selaudiomessage}
							uiHoverAudioMessage = {selhoveraudiomessage}
						>
							{team.name} {props.showId && `(${team.teamId})`}
						</GlueListItem>
					))}
				</div>
			)}
		</div>
	);
}

export default OrganizationAccordion;