import React from 'react';
import HeroIcon from './common/hero-icon';

import NoteIcon from '../icons/hero/Note.png';

const NoteHeroIcon = (props) =>
{
	const hoveraudiomessage = "Menu/Note/HL";
	const audiomessage = "Menu/Note/Press";

	return (
		<HeroIcon
			name="Notes"
			icon={NoteIcon}
			route="/app/note"
			uiAudioMessage={audiomessage}
			uiHoverAudioMessage={hoveraudiomessage}
		/>
	);
};

export default NoteHeroIcon;