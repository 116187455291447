import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';

import ColorPicker from '../common/color-picker';

import NoteStyle from './note-style';
import TextStyle from './text-style';
import NoteDefaultColors from './DefaultColors.json'

import { GlueAccordionStack } from '../common/glue-accordion';
import GlueScroll from '../common/glue-scroll';
import { useUserSettings } from '../../service/user-settings';
import { getCurrentIndex, setNoteProperty, revertNoteHistory } from '../../service/note-editor';
import { useNote } from './note-hooks';
import { Note } from './note-types';

const useStyles = makeStyles(theme => ({
	root: {
		// width: '716px'
	},

	scrollArea: {
		height: '888px'
	}
}));

type NoteEditedColor = {
	name: string
	prop: keyof Note
	value?: string
	presetColors: string[]
	currentSelection: number
	presetMode: boolean
}

const TextMenu = (props: {
	toggleColorPickerOpen: () => void
	onSaveColors: (val: string[]) => void
}) =>
{
	const [ containerState, setContainerState ] = useState([false, false]);
	const [ editedColor, setEditedColor ] = useState<NoteEditedColor | null>(null);

	const classes = useStyles();
	const note = useNote();

	const editNoteColor = () => 
	{
		setEditedColor({
			name: 'note color',
			prop: 'noteColor',
			presetColors: NoteDefaultColors.NoteColors,
			currentSelection: NoteDefaultColors.NoteColors.findIndex(color => color === note?.noteColor),
			presetMode: false
		});
		props.toggleColorPickerOpen();
	}

	const editTextColor = () =>
	{
		setEditedColor({
			name: 'text color',
			prop: 'fontColor',
			presetColors: NoteDefaultColors.TextColors,
			currentSelection:  NoteDefaultColors.TextColors.findIndex(color => color === note?.noteColor),
			presetMode: false
		});
		props.toggleColorPickerOpen();
	}

	const closeColorPicker = () => 
	{
		setEditedColor(null);
		props.toggleColorPickerOpen();
	}

	const revertAndCloseColorPicker = (pointInHistory: number) =>
	{
		revertNoteHistory(pointInHistory);
		closeColorPicker();
	}

	const containerOpen = (index: number) =>
	{
		return containerState[index];
	}

	const toggleContainerState = (index: number) =>
	{
		const newContainerState = [...containerState];
		newContainerState[index] = !newContainerState[index];
		setContainerState(newContainerState);
	}

	const editColor = (color: string, mode: boolean, selection: number) =>
	{
		if (!editedColor) {
			return;
		}

		const newColor: NoteEditedColor = { ...editedColor };
		newColor.presetMode = mode;
		newColor.currentSelection = selection;
		setEditedColor(newColor);
		setNoteProperty(editedColor.prop, color);
	}

	useEffect(() => {
		if (note && editedColor && editedColor.value !== note[editedColor.prop])
		{
			setEditedColor((old) => (old ? {...old, value: note[old.prop] as string} : null));
		}
	}, [note, editedColor])

	const { userSettings } = useUserSettings();

	return(
		<GlueScroll persistent>
			<div className={classes.root}>
				{!editedColor &&
					(<div>
						<GlueAccordionStack>
							<NoteStyle
								open={containerOpen(0)}
								toggleOpen={() => toggleContainerState(0)}
								editNoteColor={editNoteColor}
							/>
							<TextStyle
								open={containerOpen(1)}
								toggleOpen={() => toggleContainerState(1)}
								editTextColor={editTextColor}
							/>
						</GlueAccordionStack>
					</div>)}
				{editedColor &&
					(<ColorPicker
						saveColors={(val: string[]) => props.onSaveColors(val)}
						latestEditIndex={getCurrentIndex()}
						displayName={editedColor.name}
						presetColors={editedColor.presetColors}
						savedColors={userSettings?.savedNoteColors ?? []}
						color={note ? note[editedColor.prop] : '#000'}
						colorPresetMode={editedColor.presetMode}
						currentSelection={editedColor.currentSelection}
						onConfirm={closeColorPicker}
						onCancel={revertAndCloseColorPicker}
						onSetColor={editColor}
						onSaveColor={props.onSaveColors}
						onDeleteColor={props.onSaveColors}
					/>)}
			</div>	
		</GlueScroll>
	);
	
}

export default TextMenu;