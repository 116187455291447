import React from 'react'

const SiemensEmployeesConsentForm = () => (
	<>
			<h2 align='center'>For Employees and Contractors</h2>
			<br />
			<p>
				Siemens [AG], its affiliate or parent (collectively, “<strong>Siemens</strong>”) or its third party service provider hosts a
				platform, application or tool (referred to herein as “Site”) on a
				remote server. accessible via a web site portal address, IP address
				and/or credentials designated or provided by Siemens. These Siemens
				Terms of Use for Employees and Contractors (“Terms” or “User Terms”)
				are between Siemens and You (referred to herein as “<strong>User</strong>” or “<strong>You</strong>”) and You (i) are an
				individual employed by or a contractor of Siemens, (ii) authorized by
				Siemens to access the Site and Content thereon solely using the
				Siemens-provided Intranet, Application or credentials. “Content” is
				defined as all video, audio, data, images, postings, images, graphics,
				ideas, writing, software, innovations, product developments and/or
				information that may be accessible to persons authorized to use the
				Site.
			</p>
			<br />
			<p>
				<strong>
					IF YOU DO NOT AGREE TO BE BOUND BY THESE USER TERMS, DO NOT ACCESS
					OR USE THE SITE, OR CLICK THE “ACCEPT” BUTTON IF PRESENTED.
				</strong>
			</p>
			<br />
			<h2 align="center">Preamble</h2>
			<br />
			<p>
				<strong>IMPORTANT:</strong>
			</p>
			<br />
			<p>
					<strong>
							YOU ARE PROHIBITED FROM USING THIS SITE AND ANY APPLICATION
							ACCESSIBLE VIA THIS SITE INCLUDING BUT NOT LIMITED TO CREATING,
							STORING, PROCESSING, OR UPLOADING DATA THAT IS SUBJECT TO EXPORT
							CONTROL LAWS.
					</strong>
			</p>
			<br />
			<p>
					<strong>
							PRIOR TO SERVICE READINESS OR USE OF THE SITE, YOU MUST OBTAIN A
							SIEMENS CYBERSECURITY REVIEW INCLUDING BUT NOT LIMITED TO RECEIVING
							AN ACP LEVEL. YOU MUST UNDERSTAND AND COMMUNICATE THE ACP LEVEL AND
							ALL LIMITATIONS ON SITE USE TO ALL USERS OF THE SITE BEFORE FIRST
							USE AND PERIODICALLY.
					</strong>
			</p>
			<br />
			<p>
					<strong>
							USER IS NEVER PERMITTED TO INPUT, PRESENT OR OTHERWISE HOST ON THE
							SERVICE, WHETHER SECURE OR INSECURE, STRICTLY CONFIDENTIAL DATA OR
							ACP LEVEL III DATA INCLUDING BUT NOT LIMITED TO DATA GOVERNED BY EXPORT CONTROL LAWS.
					</strong>
			</p>
			<br />
			<p>
					<strong>
							THE SITE MAY HAVE FEATURES THAT PERMIT SIEMENS OR ITS
							REPRESENTATIVES TO MONITOR, RECORD, INTERRUPT, SHARE, PROCESS OR
							STORE YOUR USE INCLUDING BUT NOT LIMITED TO VOICE, IMAGE,
							TELEMATICS, WRITTEN COMMUNICATIONS SUCH AS EMAILS, TEXTS AND SOCIAL
							MEDIA ACTIVITY. CHECK WITH ADMINISTRATORS IF YOU HAVE ANY QUESTIONS
							ABOUT SITE MONITORING.
					</strong>
			</p>
			<br />
			<p>
					BY CLICKING THE “ACCEPT” BUTTON OR OTHERWISE USING THE SITE, YOU AGREE
					THAT (1) YOU HAVE READ, AND AGREE TO COMPLY WITH AND BE BOUND BY THESE
					USER TERMS WITHOUT LIMITATION OR QUALIFICATION AS WELL AS ALL
					APPLICABLE LAWS, REGULATIONS, EXECUTIVE ORDERS, AND THE RULES
					CONCERNING THE OFFICE OF FOREIGN ASSET CONTROL, SANCTIONED OR EMBARGOED
					COUNTRIES, DENIED PERSONS, REGARDLESS OF WHERE YOU RESIDE, USE THE
					SITE, OR ANY OTHER LAWS THAT MAY APPLY TO YOU; (2) IN ADDITION TO OTHER
					REQUIREMENTS HEREIN, USER
			</p>
			<br />
			<p>
					(A) IS NOT PERMITTED TO USE THE SITE FOR ANY PURPOSE OTHER THAN TO
					CONDUCT SIEMENS&apos; BUSINESS; (B) UNDERSTANDS THAT THIRD PARTIES MAY HAVE
					ACCESS TO CONTENT AND USER HEREBY CONSENTS TO SUCH ACCESS; AND (C)
					OTHER TERMS OR CONDITIONS, WHEREVER LOCATED, SHALL NOT AMEND OR IMPACT
					THESE USER TERMS.
			</p>
			<br />
			<p align="left">
					1. <strong>Mutual Acceptance</strong>. These Terms are based on
					the mutual exchange of promises between User and Siemens and acceptance
					is required for User to access the Site. Failure to comply with these
					Terms may result in termination of access to the Site, claims and/or
					penalties under applicable law as well as a violation of Siemens&apos;
					policies. Siemens may modify the Site and/or these Terms any time at
					its sole discretion, and by accessing the Site, User accepts the
					current version of the Terms. All Siemens policies applicable to You
					are incorporated herein by reference and shall govern your conduct in
					addition to these Terms.
			</p>
			<br />
			<p>
					<strong>2. </strong>
					<strong>Term and Termination</strong>
					.
					The Term of these Terms commences with Your acceptance hereof or first
					use of the Site whichever occurs first, and continues through any
					subsequent use of the Site. Siemens may immediately terminate Your
					access to and use of the Site, Your password or account and may delete
					any data on the Site with or without cause, at any time without penalty
					or liability.<strong></strong>
			</p>
		<br clear="all" />
			<p>
					<strong>3. </strong>
					<strong>Authorization and License</strong>
					. Siemens has all right, title or license to the Site and authorizes
					User to access and use the Site on a limited, non-exclusive,
					non-transferable, non-sublicenseable, revocable basis solely for
					Siemens&apos; business purpose. User shall not, or permit other individuals
					or entities to:<strong></strong>
			</p>
			<br />
			<p>
					(A) copy, reproduce, translate, alter, display, modify, decompile,
					reverse engineer, disassemble, attempt to discover the source code or
					algorithms of, manipulate or create derivative work based on, the Site,
					or any Content, software, products, services or any other material
					(including text, graphics, logos, button icons, images, audio clips,
					data, photographs, graphs, videos, typefaces, and sounds) made
					available using the Site, or any part thereof, or use the Site to run
					or as part of a service bureau, outsourced or managed services. or
					access the Site in violation of any applicable laws;
			</p>
			<br />
			<p>
					(B) disable or circumvent any access control or related device, process
					or procedure established with respect to the Site or any part thereof
					including, without limitation, unauthorized access to the Site, other
					user accounts, computer systems or networks connected to the Site, test
					the security measures on the Site and/or attempt to identify system
					vulnerabilities, or to attempt to disable the Site; or
			</p>
			<br />
			<p>
					(C) link, distribute, transfer, sell and resell, (sub-)license, rent,
					lease, lend, assign or otherwise transfer any rights to, or
					commercially exploit or otherwise make available the Site in whole or
					in part to any third party in any way.
			</p>
			<br />
			<p>
					The Site may contain third- party Content, including commercial and
					open source software. Such third- party Content may be subject to
					additional or differing terms and conditions and, in such case, there
					will be a link to such third party terms which shall prevail over these
					Terms as to third- party Content.
			</p>
			<br />
			<p>
					<strong>4. Account Access and Security.</strong>
			</p>
			<br />
			<p align="left">
					(A) Connection and access to the Site requires You to be authorized by
					and provided access credentials by Siemens. You shall keep Your
					credentials, password or registration
			</p>
			<br />
			<p>
					information (collectively referred to herein as “User Access Data”)
					confidential, in a safe place and not disclose to any third party.
					<strong>
							User agrees to be solely responsible for all Site activity
							occurring using Your User Access Data.
					</strong>
					User shall immediately notify Siemens&apos; administrators if (i) User
					Access Data has been stolen, leaked, compromised or otherwise used
					without User&apos;s consent; (ii) User no longer requires access to the
					Site, or (iii) User suspects a security breach of the Site. Only
					Siemens may authorize new users and grant access to the Site.
			</p>
			<br />
			<p align="left">
					(B) User shall know the Siemens Cybersecurity ACP Level for the Site at
					all times and particularly <em>prior to </em>uploading or inputting
					data or communications onto the Site, and inviting other persons to
					access the Site.
			</p>
			<br />
			<p>
					<strong>5. Intellectual Property.</strong>
			</p>
			<br />
			<p align="left">
					(A) User hereby grants Siemens, and Siemens´ service provider( s)
					acting on Siemens´ behalf, a non-exclusive, transferable,
					sublicenseable, worldwide, royalty-free, perpetual, non-revocable
					license under applicable copyrights and other intellectual property
					rights, if
			</p>
		<br clear="all" />
			<p>
					any, in all of User&apos;s Content and to create derivative works and
					aggregated data derived from User&apos;s Content including without
					limitation, comparative data sets, statistical analyses, reports and
					related services and utilize such as it sees fit for any purpose. User,
					not Siemens nor Siemens&apos; service provider(s) has sole responsibility
					for the accuracy, quality, integrity,
			</p>
			<br />
			<p>
					legality, reliability, and appropriateness, of all User&apos;s Content and
					Siemens shall not be responsible or liable for the deletion,
					correction, destruction, damage, loss or failure to store any of User&apos;s
					Content. Upon termination of User&apos;s access to the Site, Siemens shall
					have no obligation to maintain User&apos;s Content or provide to You. User
					agrees that all Content on the Site may be subject to collection,
					review or use by Siemens or its parent in legal or compliance matters.
			</p>
			<br />
			<p align="left">
					(B) All right, title and interest in and to the Site, including
					technology and trade secrets embodied therein, and any custom
					developments created or provided in connection with or related to this
					Site, including all copyrights, patents, and other proprietary rights,
					and derivative works thereof, shall belong solely and exclusively to
					Siemens and/or its licensors and User shall have no rights whatsoever
					in any of the foregoing. User has no ownership interest in the Site of
					Content thereon in whole or in part.
			</p>
			<br />
			<p align="left">
					<strong>6. </strong>
					<strong>Conditions of Use</strong>
					. In addition to all other obligations herein, You agree as follows:        <strong></strong>
			</p>
			<br />
			<p>
					(A) Your Content and conduct shall not be unlawful, harmful,
					threatening, abusive, harassing, tortious, defamatory, vulgar, obscene,
					libelous, racially, ethnically, or otherwise objectionable, and shall
					not concern minors;
			</p>
			<br />
			<p>
					(B) You have obtained the consent of all persons whose image, voice,
					audio, personal address or contact information You post and/or upload;
			</p>
			<br />
			<p>
					(C) You and Your access and use of the Site are subject to Siemens&apos;
					policies and applicable laws;
			</p>
			<br />
			<p>
					(D) You shall not to force headers or otherwise manipulate identifiers
					in order to disguise the origin of any Content;
			</p>
			<br />
			<p>
					(E) You shall not upload or input Content that infringes any patent,
					trademark, trade secret, copyright, rights of privacy or publicity, or
					other proprietary rights of any third party or Siemens;
			</p>
			<br />
			<p>
					(F) You shall not promote illegal activities on the Site;
			</p>
			<br />
			<p>
					(G) You shall not breach confidentiality obligations You have with
					Siemens or any third party using the Site;
			</p>
			<br />
			<p>
					(H) You shall avoid any actions that may compromise Siemens&apos; security
					measures for the Site.
			</p>
			<br />
			<p>
				<strong>7. No Warranty.</strong>
			</p>
			<br />
			<p>
					The Site and Content thereon are provided “as is” and Siemens makes no
					representations or warranties, express or implied, to User or any third
					party concerning the Site or any Content. Siemens disclaims all
					responsibility for any loss of data, errors in or omissions in the
					Site, third- party web sites or Content, or the unavailability of the
					Site.
			</p>
		<br clear="all" />
		<p>
				<strong>8. General Provisions.</strong>
		</p>
		<br />
		<p>
				(A) No Waiver<strong>. </strong>Should User or Siemens fail to
				exercise or enforce any provision of these User Terms or to waive any
				rights in respect thereto, such waiver shall not be construed as
				constituting a continuing waiver or waiver of any other right.
		</p>
		<br />
		<p>
				(B) Choice of Law. These User Terms shall be governed, construed and
				interpreted in accordance with the laws of the laws of Germany without
				regard to conflict of laws principles thereof. The application of the
				United Nations Convention on Contracts for the International Sales of Goods
				(CISG) of 11 April 1980 is excluded.
		</p>
		<br />
		<p>
				(C) Assignment. User shall not assign these Terms or any permission
				to use the Site.
		</p>
		<br />
		<p>
				(D) Other Terms. These Terms are separate from any terms or
				conditions contained in any hyperlinks or third party websites or documents
				that may be accessible on or through the Site. User shall have no right to
				amend these Terms.
		</p>
		<br />
		<p>
				(E) Entire Agreement. The User Terms, as may be amended from time to
				time in the sole discretion of Siemens, including all applicable Siemens
				policies, constitute the entire agreement between User and Siemens
				concerning the Site and supersede all prior or contemporaneous writings,
				discussions, agreements, and understandings of any kind, with respect to
				the Site and subject matter hereof.
		</p>
		<br />
		<p>
				(F) Severability. If any provision of these User Terms shall be held
				to be unenforceable or invalid by any court of competent jurisdiction, the
				other provisions of these User Terms will remain in full force and effect
				to the extent not held invalid or unenforceable.
		</p>
		<br />
		<p>
				(G) Headings. The headings in the User Terms are for convenience of
				reference only and shall not limit or otherwise affect the meaning hereof.
		</p>
		<br />
		<p>
				(H) Survival. This Section, <strong>General Provisions</strong>, and
				Sections entitled <strong>No Warranty</strong>,<strong>Intellectual Property, Term and Termination, </strong>and the    <strong>Preamble </strong>shall survive termination or expiration of these
				Terms.
		</p>
	</>
)

export default SiemensEmployeesConsentForm
