import React from "react";
import { useQuery } from "@apollo/react-hooks";

import queries from "../../graphql/queries";

import GlueDialog from "../common/glue-dialog";
import AddOrgMemberDialogContent from "./add-org-member-dialog-content";

const AddOrgMemberDialog = (props: {
	orgId: string,
	open: boolean
	onClose: () => void,
	refetch: () => void
}) =>
{
	const uiModeRes = useQuery(queries.ui);

	return(
		<GlueDialog
			fullWidth={false}
			maxWidth={'lg'}
			open={props.open}
			onClose={props.onClose}
		>
			<AddOrgMemberDialogContent 
				web={uiModeRes.data?.ui === 'web'}
				orgId={props.orgId}
				onClose={props.onClose}
				refetch={props.refetch}
			/>
		</GlueDialog>
	);
}

export default AddOrgMemberDialog;