import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core';

import FeatureCard from './feature-card';
import PageSelector from '../common/page-selector';
import GlueButton from '../common/glue-button';
import EditorPanel from '../common/editor-panel';

import AvatarFeatures from './avatarfeatures';
import { setAvatarFeature } from '../../service/avatar-configurator';
import { AssetDescriptor } from './avatar-types';

const useStyles = makeStyles(theme => ({
	listing: {
		display: 'grid',
		gridTemplateColumns: `repeat(3, ${theme.custom.avatarEditor.featureTileSize})`,
		gridTemplateRows: `repeat(${theme.custom.avatarEditor.featureTilesPerPage / 3}, ${theme.custom.avatarEditor.featureTileSize})`,
		gap: theme.glueSpacing('s'),
		marginTop: theme.glueSpacing('m'),
	},

	pageSelector: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: theme.glueSpacing('s'),
		marginBottom: theme.glueSpacing('s'),
		height: theme.custom.editorPanel.rowHeight,
	},

	infoText: {
		textAlign: 'center',
		height: theme.custom.editorPanel.rowHeight,

		'& > p': {
			margin: 'auto',
		},
	},

	buttons: {
		display: 'grid',
		gridTemplateColumns: 'auto auto',
		gap: theme.glueSpacing('m'),
		justifyContent: 'center',
		marginTop: theme.glueSpacing('l'),
	}
}), { name: 'MuiGlueFeatureList' });



const FeatureList = (props: {
	featureType: string
	latestEditIndex: number
	selection?: string
	onConfirm: () => void
	onCancel: (startingPoint: number) => void
}) =>
{
	const classes = useStyles();
	const theme = useTheme();

	const tilesPerPageCount = theme.custom.avatarEditor.featureTilesPerPage;

	const featureType = props.featureType;

	const [currentPage, setCurrentPage] = useState(0);

	const [ startingPointInHistory, setStartingPoint ] = useState(props.latestEditIndex);

    if (props.latestEditIndex < startingPointInHistory)
    {
        setStartingPoint(props.latestEditIndex);
    }

	const pageContents = [];
	let pageCount = 0;

	const displayName = AvatarFeatures[featureType].DisplayName.toLowerCase();
	const features = AvatarFeatures[featureType].Assets;
	if (features)
	{
		for (let i = 0; i < tilesPerPageCount; i++)
		{
			const presetIndex = tilesPerPageCount * currentPage + i;
			if (presetIndex < features.length)
			{
				pageContents.push(features[presetIndex]);
			}
		}
	
		pageCount = Math.ceil(features.length / tilesPerPageCount)
	}
	
	if (currentPage > pageCount - 1) {
		const newPage = Math.max(0, pageCount - 1);
		if (newPage !== pageCount)
			setCurrentPage(newPage);
	}

	const featureIsSelected = (feature: AssetDescriptor) =>
	{
		if (feature.Type !== "Shape")
		{
			return props.selection === feature.Prefab;
		}
		else return props.selection === feature.PresetName;
	}

	const getSelectedFeature = () =>
	{
		if (featureType !== "Shape")
		{
			return props.selection ? features.find(feature => feature.Prefab === props.selection) : null;
		}
		else return props.selection ? features.find(feature => feature.PresetName === props.selection) : null;
	}

	const InfoTextArea = () =>
	{
		const infoText = getSelectedFeature()?.InfoText;
		return (
			<>
				{infoText && 
					(<div className={classes.infoText}>
						<p>{infoText}</p>
					</div>)}
			</>
		);
	}
 
	return (
		<EditorPanel title={`Select your ${displayName}`}>
			<div className={classes.listing}>
				{pageContents.map((feature, i) => (<FeatureCard key={feature.Prefab} image={feature.ThumbnailUrl} onPointerDown={(e) => setAvatarFeature(feature)} toggled={featureIsSelected(feature)}/>))}
			</div>
			<div className={classes.pageSelector}>
				<PageSelector current={currentPage} pageCount={pageCount} onPageChange={(i) => setCurrentPage(i)} />
			</div>
			<InfoTextArea/>
			<div className={classes.buttons}>
				<GlueButton
					onPointerDown={() => props.onCancel(startingPointInHistory)}
				>
					Cancel
				</GlueButton>
				<GlueButton
					onPointerDown={props.onConfirm}
					color="primary"
				>
					OK
				</GlueButton>
			</div>
		</EditorPanel>
	);
}

export default FeatureList;