import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import queries from '../graphql/queries';
import WhiteboardHeroIcon from './whiteboard-hero-icon.js';
import DesktopViewHeroIcon from './desktopview-hero-icon.js';
import NoteHeroIcon from './note-hero-icon.js';
import Draw3DHeroIcon from './draw-3d-hero-icon.js';
import LaserPointerHeroIcon from './laser-pointer-hero-icon.js';
import BrowserHeroIcon from './browser-hero-icon.tsx';
import FacilitationHeroIcon from './facilitation-hero-icon.tsx';
import CameraHeroIcon from './camera-hero-icon.js';
import ClockHeroIcon from './clock-hero-icon.js';
import LocationsHeroIcon from './locations-hero-icon.js';
import PassthroughHeroIcon from './passthrough-hero-icon';
import ShowExternalApps from './externalapps/show-external-apps';
import ExternalApps from './externalapps//external-apps';
import PresentationViewHeroIcon from './presentation-view/presentation-view-hero-icon';

import postVuplexMessage from '../service/message-vuplex'
import SpaceAssetManageHeroIcon from './space-asset-manage-hero-icon';
import GlueButton from './common/glue-button';
import SVGIconWrapper from './common/svg-icon-wrapper';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useUserSettings } from '../service/user-settings';

import { useUserContext } from '../util/user-context';

import { setKeyboardActive } from '../util/control-util';

// variables to skip Slack for other standalone headsets except Quest2
const blackListedApp = 'Slack';
const quest2tag = 'Oculus_Quest_2'
const questprotag = 'Meta_Quest_Pro'
const pico3tag = 'Pico Pico Neo 3'
const pico4tag = 'Pico A8150'

const useStyles = makeStyles((theme) => ({ 
	mainMenu: {
		marginTop: theme.glueSpacing('m'),
		display: 'grid',
		gap: '35px',
		gridTemplateColumns: '64px 1fr 64px',
		alignItems: 'center',
	}, 

	mainItems: {
		display: 'grid',
		gridTemplateColumns: 'repeat(4, min-content)',
		gridTemplateRows: 'repeat(3, 160px)',
		columnGap: '30px',
		rowGap: '32px',
		height: '564px',
		width: '1120px',
	},
	
	navigation: {
		height: '60px',
		display: 'flex', 
		justifyContent: 'center', 
		alignItems: 'center',
	},

	dot:{
		display: 'inline-block',
		width: '12px',
		height: '12px', 
		borderRadius: '99px', 
		background: theme.palette.primary.contrastText, 
		marginRight: '6px', 
		marginLeft: '6px',
	}
}), { name: 'MuiGlueAppGrid' });

let AppGrid = (props) =>
{
	const { userSettings } = useUserSettings();
	const apollo = useApolloClient();

	const [page, setPage] = useState(0);
	const classes = useStyles();

	const clientPlatformResult = useQuery(queries.clientPlatform);
	const clientPlatform = clientPlatformResult.data?.clientPlatform;
	const vr = clientPlatform ? clientPlatform.PlatformType === "VR" : false;
	const deviceModel = clientPlatform ? clientPlatform.DeviceModel : "None";
	const clientOS = clientPlatform ? clientPlatform.OS : "None";

	const nameIndex = 0;
	const infoIndex = 1;
	let slackInd = 0;

	if (clientOS === "Android")
	{
		if (deviceModel !== quest2tag && deviceModel !== questprotag && deviceModel !== pico3tag && deviceModel != pico4tag)
		{
			slackInd = ExternalApps.findIndex(app => app.AppName === blackListedApp)
			if (slackInd > -1)
			{
				ExternalApps.splice(slackInd,1);
			}
		}
	}
	const user = useUserContext();
	const passthroughApp = deviceModel === questprotag;
	const baseMenuItems = user.administrator ? 12 : 11;
	const fixedMenuItems = passthroughApp ? baseMenuItems + 1 : baseMenuItems;
	const maxMenuItems = 12;
	const emptyMenuItems = maxMenuItems - fixedMenuItems;

	useEffect(() => {
		setKeyboardActive(false, apollo);
		
		postVuplexMessage('Enable hotkeys', { value: true });
		
		return () => {
			postVuplexMessage('Enable hotkeys', { value: false });
		};
	}, []);

	const changeBy = (delta) =>
	{
		if (page + delta < 0)
		{
			setPage(maxPage - 1);
		}
		else if (page + delta > maxPage - 1)
		{
			setPage(0);
		}
		else
		{
			setPage(page + delta);
		}
	}

	const selectedApps = userSettings?.externalApps ?? [[],[]];
		
	let saIndex = 0;

	let appsToShow = ExternalApps.filter(element => selectedApps[nameIndex].includes(element.AppName));
	appsToShow.forEach((item, i) => {

		saIndex = selectedApps[nameIndex].indexOf(item.AppName);

		if (selectedApps[infoIndex][saIndex].length > 0)
		{
			item.AppUrl = selectedApps[infoIndex][saIndex];
		}

	})

	const maxPage = Math.ceil((appsToShow.length - emptyMenuItems) / maxMenuItems + 1);
	
	if (page === 0)
		appsToShow = appsToShow.slice(page, maxMenuItems - fixedMenuItems);
	else
		appsToShow = appsToShow.slice(maxMenuItems * (page - 1) + maxMenuItems - fixedMenuItems, (maxMenuItems * page + maxMenuItems - fixedMenuItems ));

	return (
		<>
			<div className={classes.mainMenu}>
				<div className={classes.navigateIcon}>
					{ maxPage > 1 && ( 
							<GlueButton variant='icon' onPointerDown={()=>{changeBy(-1)}}>
								<SVGIconWrapper><NavigateBeforeIcon/></SVGIconWrapper>
							</GlueButton>
					)}
				</div>
				<div className={classes.mainItems}>
					{ page < 1 && ( 
						<>
							<PassthroughHeroIcon />
							<WhiteboardHeroIcon />
							<NoteHeroIcon />
							<Draw3DHeroIcon disabled={!vr} /> 
							<LaserPointerHeroIcon disabled={!vr} />
							<BrowserHeroIcon />
							<PresentationViewHeroIcon />
							<DesktopViewHeroIcon disabled = {clientOS === "Android"}/>
							<CameraHeroIcon />
							<ClockHeroIcon />
							<LocationsHeroIcon />
							<FacilitationHeroIcon />
							<SpaceAssetManageHeroIcon />
						</>
					)}
					<ShowExternalApps appsSelected = {appsToShow}/>
				</div>
				<div className={classes.navigateIcon}>
					{ maxPage > 1 && ( 						
							<GlueButton variant='icon' onPointerDown={()=>{changeBy(1)}}>
								<SVGIconWrapper><NavigateNextIcon/></SVGIconWrapper>
							</GlueButton>
					)}
				</div>
			</div>
			<div className={classes.navigation}>
				{maxPage > 1 && Array.from(Array(maxPage)).map((x, index) => 
					<div
						key={index}
						className={classes.dot}
						style={{ opacity: index === page ? null : '0.5' }}
					/>
				)}
			</div>
		</>
	);
}

export default AppGrid;
