import { useEffect, useState } from "react";
import { editorInitialized, getCurrentNote } from "../../service/note-editor"

export const useNote = () => 
{
	const defaultNote = editorInitialized() ? getCurrentNote() : null;
	const [ note, setNote ] = useState(defaultNote);

	const updateNote = (e: Event) =>
	{
		setNote(getCurrentNote());
	}

	useEffect(() => {
		window.addEventListener('onNoteChanged', updateNote);
		return () => {
			window.removeEventListener('onNoteChanged', updateNote);
		}
	});

	return note;
}