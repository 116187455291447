import React from 'react';

import { useApolloClient, useQuery } from '@apollo/react-hooks';
import queries from '../../graphql/queries';
import FileViewSwitcher from './file-view-switcher';

import GlueDialog from '../common/glue-dialog';

const FilePreviewModal = (props) => 
{
	const apollo = useApolloClient();
	const { data: fileData } = useQuery(queries.teamFilePreview);

	const closePreview = () => {
		apollo.writeQuery({
			query: queries.teamFilePreview,
			data: { teamFilePreview: null }
		});
	}

	return (<>
		<GlueDialog PaperProps={{ style: { padding: '0px', borderRadius: '44px' } }}
			fullWidth={true}
			maxWidth={'lg'}
			open={!!fileData && !!fileData.teamFilePreview}
			onClose={closePreview}
		>
			<FileViewSwitcher id={fileData.teamFilePreview} closePreview={closePreview}/>
		</GlueDialog>
	</>
	);
}

export default FilePreviewModal;