import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import CloseButton from '../common/close-button';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		height: theme.custom.header.height,
		minHeight: theme.custom.header.height,
		background: `linear-gradient(${theme.palette.background.paper}, ${theme.palette.background.default})`,
		padding: theme.custom.header.padding,
		display: 'flex',
		justifyContent: 'center',
	},

	content: {
		...theme.custom.contentBase,
		display: 'grid',
		gridTemplateColumns: '1fr 2fr 1fr',
		justifyContent: 'center',
		alignItems: 'center',
		gap: theme.glueSpacing('xl')
	},

	titleArea: {
		display: 'flex',
		gap: theme.glueSpacing('m'),
		alignItems: 'center',
	},

}), { name: 'MuiGlueViewHeader' });

type ViewHeaderProps = {
	title?: string,
	centerContent?: React.ReactNode,
	rightContent?: React.ReactNode,
	onClose?: () => void
}

const ViewHeader = (props: ViewHeaderProps) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<div className={classes.content}>
				<div className={classes.titleArea}>
					<CloseButton onClose={props.onClose}/>
					<h2>{props.title}</h2>
				</div>
				<div>
					{props.centerContent}
				</div>
				<div>
					{props.rightContent}
				</div>
			</div>
		</div>
	);
}

export default ViewHeader;