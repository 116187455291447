import React, { useCallback, useEffect, useState } from 'react';
import { useApolloClient, useQuery } from '@apollo/react-hooks';

import queries from "../../graphql/queries";
import GlueButton from "../common/glue-button";
import {ReactComponent as ClearIcon} from '../../icons/Close.svg';
import SVGIconWrapper from '../common/svg-icon-wrapper';
import { makeStyles } from '@material-ui/core';
import SwitchSelector from '../common/switch-selector';
import { COMMON_ASSET_VERSION_POINTER_NAMES, setVersionPointer } from './space-asset-common';
import CommonTextValuePicker from '../common/common-text-value-picker';


const useStyles = makeStyles((theme) => ({
	root: {

	},

	assetPanel: {
		background: theme.palette.background.paper,
		marginBottom: '32px',

		display: 'grid',
		gridTemplateColumns: '1fr min-content',
		alignItems: 'center'
	}
}));

const MassCopyAssetVersionPointers = (props) =>
{
	const classes = useStyles();
	const apollo = useApolloClient();

	const [fromPointerName, setFromPointerName] = useState('');
	const [toPointerName, setToPointerName] = useState('');
	const [targetAssetIds, setTargetAssetIds] = useState([]);
	const [publicOnly, setPublicOnly] = useState(false);

	const assetsRes = useQuery(queries.myAssets, {
		variables: {
			typeFilter: 'space'
		}
	});

	const populateTargetAssetsList = useCallback(
		() => {
			setTargetAssetIds(
				assetsRes.data?.myAssets.filter(
					(assetInfo) => (
						assetInfo.versionPointers?.some(
							(pointer) => pointer.name === fromPointerName
						) && (
							!publicOnly || assetInfo.permission === 'public'
						)
					)
				).map(
					(assetInfo) => assetInfo.assetId
				) ?? []
			);
		},
		[assetsRes.data, fromPointerName, publicOnly]
	);

	const copyPointers = async () =>
	{
		for (const assetId of targetAssetIds)
		{
			const assetInfo = assetsRes.data?.myAssets.find(
				(item) => item.assetId === assetId
			) ?? null;

			if (!assetInfo)
				continue;

			const fromVersionPointer = assetInfo.versionPointers?.find(
				(item) => item.name === fromPointerName
			) ?? null;

			if (!fromVersionPointer)
				continue;

			await setVersionPointer(
				apollo,
				assetId,
				toPointerName,
				fromVersionPointer.version
			);
		}

		assetsRes.refetch();
	};

	const removeFromQueue = (assetId) =>
	{
		setTargetAssetIds(
			targetAssetIds.filter((item) => item !== assetId)
		);
	};

	useEffect(
		() => {
			populateTargetAssetsList();
		},
		[populateTargetAssetsList]
	);

	return (
		<div className={classes.root}>
			<div>
				<label>
					From pointer name:
					<CommonTextValuePicker
						value={fromPointerName}
						onChange={(value) => setFromPointerName(value)}
						placeholder={"From pointer name"}
						options={COMMON_ASSET_VERSION_POINTER_NAMES}
					/>
				</label>
			</div>

			<div>
				<label>
					To pointer name:
					<CommonTextValuePicker
						value={toPointerName}
						onChange={(value) => setToPointerName(value)}
						placeholder={"To pointer name"}
						options={COMMON_ASSET_VERSION_POINTER_NAMES}
					/>
				</label>
			</div>

			<SwitchSelector
				checked={publicOnly}
				onChange={() => setPublicOnly(!publicOnly)}
				onText="Public only"
				offText="All"
			/>

			<GlueButton
				disabled={!Boolean(fromPointerName)}
				onPointerDown={populateTargetAssetsList}
			>
				Find assets
			</GlueButton>

			<h2>Target assets</h2>
			<ul>
				{targetAssetIds.map((assetId, index) => (
					<li key={assetId} className={classes.assetPanel}>
						<div>{assetsRes.data?.myAssets.find((item) => item.assetId === assetId)?.name}</div>
						<GlueButton variant='icon' onPointerDown={() => removeFromQueue(assetId)}>
							<SVGIconWrapper><ClearIcon /></SVGIconWrapper>
						</GlueButton>
					</li>
				))}
			</ul>

			<GlueButton
				disabled={!Boolean(fromPointerName) || !Boolean(toPointerName)}
				onPointerDown={copyPointers}
			>
				Apply pointers
			</GlueButton>
		</div>
	);
};

export default MassCopyAssetVersionPointers;
