import React from 'react'
import { makeStyles } from "@material-ui/core";
import GlueScroll from '../common/glue-scroll';
import { Asset } from '../../graphql/types-generated';
import SpaceTemplateListItem from './space-template-list-item';

const useStyles = makeStyles((theme) => ({
	root: {
		width: theme.custom.spaceTemplatesDialog.listItem.width,
		display: 'flex',
		flexFlow: 'column',
		gap: theme.glueSpacing('xs'),
		marginRight: theme.glueSpacing('m')
	}
}), { name: 'MuiGlueSpaceTemplateList' });

const SpaceTemplateList = (props: {
	web?: boolean
	canCreate?: boolean
	items: Asset[]
	hidden: string[]
	selectedId: string
	showOptions: boolean
	page: number
	templatesPerPage: number
	onSelectAsset: (id: string) => void
	onEditAsset: (asset: Asset) => void
	onSetVisible: (asset: Asset, visible: boolean) => void
	onPromote: (asset: Asset) => void
	onEditDescription: (asset: Asset) => void
	onCreateAsset: () => void
}) => {
	const classes = useStyles({ web: props.web });

	const start = Math.min(props.page * props.templatesPerPage, props.items.length);
	const end = Math.min((props.page + 1) * props.templatesPerPage, props.items.length);

	return (
		<GlueScroll persistent>
			<div className={classes.root}>
				{(!!props.canCreate && !!props.web) && (
					<SpaceTemplateListItem 
						title='Create a new template'
						visible
						create
						onSelectAsset={props.onCreateAsset}
						onEditAsset={() => null}
						onSetVisible={() => null}
						onPromote={() => null}
						onEditDescription={() => null}
						showOptions={false}
					/>
				)}
				{props.items.slice(start, end).map(item => (
					<SpaceTemplateListItem 
						key={item.assetId}
						asset={item}
						title={item.name}
						visible={!props.hidden.some(id => id === item.assetId)}
						thumbnailUrl={item.thumbnailUrl}
						selected={item.assetId === props.selectedId}
						onSelectAsset={() => props.onSelectAsset(item.assetId)}
						onEditAsset={() => props.onEditAsset(item)}
						onEditDescription={() => props.onEditDescription(item)}
						onSetVisible={(visible: boolean) => props.onSetVisible(item, visible)}
						onPromote={() => props.onPromote(item)}
						showOptions={props.showOptions}
					/>
				))}
			</div>
		</GlueScroll>
	);
}

export default SpaceTemplateList;
