import { CSSProperties } from "@material-ui/core/styles/withStyles";

export type Type = CSSProperties;

export const common: Type =
{
	width: '464px',
	height: '64px',
};

export const web: Type =
{
	...common,

	width: '400px',
	height: '36px',
};

export const tablet: Type =
{
	...common
};
