import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core';

import queries from '../../graphql/queries';
import GlueButton from '../common/glue-button';
import GlueDropdown from '../common/glue-dropdown';
import GlueToggle from '../common/glue-toggle';
import ArchiveIcon from '@material-ui/icons/Archive';
import PersonIcon from '@material-ui/icons/Person';
import { Asset } from '../../graphql/types-generated';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'grid',
		gap: '12px',
		gridTemplateColumns: '1fr repeat(3, min-content)'
	}
}));

const AssetSelector = (props: {
	selectedId: string
	onSelect: (id: string) => void
}) =>
{
	const classes = useStyles();
	const [ showArchived, setShowArchived ] = useState(false);
	const [ showCustom, setShowCustom ] = useState(false);

	const assetsRes = useQuery(queries.assetsWithFullPathName, {
		variables: {
			typeFilter: 'space',
			getArchived: showArchived,
			getCustom: showCustom
		}
	});

	const refresh = () =>
	{
		assetsRes.refetch();
	};

	const assets: Asset[] = assetsRes.data?.assetsWithFullPathName ?? [];

	const selectorItems = assets.map(
		(item: Asset) => ({
			id: item.assetId,
			name: `${item.name} ${item.archived ? '(Archived)' : ''}`
		}
	)) ?? [];
	
	selectorItems.sort((a: {id: string, name:string}, b: {id: string, name:string}) => a.name.localeCompare(b.name));
	selectorItems.unshift({
		id: '',
		name: 'None'
	});

	const onPressArchived = (value: boolean) => {
		setShowArchived(value);
		props.onSelect('');
	}

	const onPressCustom = (value: boolean) => {
		setShowCustom(value);
		props.onSelect('');
	}

	return (
		<div className={classes.root}>
			<GlueDropdown
				value={props.selectedId}
				onChange={(id, checked) => { checked && props.onSelect(id as string) }}
				items={selectorItems}
			/>
			<GlueButton onPointerDown={() => refresh()}>Refresh</GlueButton>
			<GlueToggle toggled={showArchived} onPointerDown={() => onPressArchived(!showArchived)}>
				<ArchiveIcon/>
			</GlueToggle>
			<GlueToggle toggled={showCustom} onPointerDown={() => onPressCustom(!showCustom)}>
				<PersonIcon/>
			</GlueToggle>
		</div>
	);
};

export default AssetSelector;
