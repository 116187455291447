import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: props => ({
		height: '48px',

		fontSize: '24px',
		fontWeight: 400,
		lineHeight: '24px',

		display: 'grid',
		gridTemplateColumns: props.icon ? 'min-content 1fr' : 'auto',
		alignItems: 'center',
		padding: props.icon ? '0 20px 0 12px' : '0 12px 0 12px',
		gap: '16px',

		background: props.alarm ? 'red' : theme.palette.background.paper,
		color: props.alarm ? 'white' : '#FCC21F',
		borderRadius: '99999999px'
	}),

	iconContainer: {
		fontSize: '32px'
	},

	text: {
		textAlign: 'center'
	}
}));


const ClockStatusIndicator = (props) =>
{
	const classes = useStyles(props);

	return (
		<div className={classes.root} onPointerDown={props.onPointerDown}>
			{props.icon && <div className={classes.iconContainer}>{props.icon}</div>}
			<div className={classes.text}>{props.text}</div>
		</div>
	);
};

export default ClockStatusIndicator;
