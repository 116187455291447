import { CSSProperties } from "@material-ui/core/styles/withStyles";

export type Type = {
	mainContent: CSSProperties
};

const common: Type = {
	mainContent: {
		overflow: 'hidden',
	}
};

export const web: Type = {
    ...common,

	mainContent: {
		padding: 0,
	}
};

export const tablet: Type = {
    ...common
};