import untar from 'js-untar';

/**
 * Reads a Space Asset Package and returns its metadata object.
 * @param {File} packageFile 
 * @returns {*} Space Asset Package metadata object
 */
export const getMetadataFromSpaceAssetPackage = async (packageFile) =>
{
	let metaDoc = null;

	//console.log("Reading archive", packageFile);

	const packageArrayBuffer = await packageFile.arrayBuffer().catch(e => null);
	if (!packageArrayBuffer)
	{
		console.error("Failed to read the package file");
		return metaDoc;
	}

	await untar(packageArrayBuffer).then(
		// All files
		(allFiles) => {
			// nop
		},

		(err) => {
			console.error("Parsing package failed", err);
		},

		// Single file
		(file) => {
			if (String(file.name).endsWith(".json"))
			{
				metaDoc = file.readAsJSON();
			}
		}
	);

	return metaDoc;
};
