export type Type = {
	inputWidth: string
	dropdownWidth: string
};

const common: Type =
{
	inputWidth: '256px',
	dropdownWidth: '240px',
};

export const web: Type =
{
	...common,
	
	inputWidth: '200px',
	dropdownWidth: '144px',
};

export const tablet: Type =
{
	...common,

};