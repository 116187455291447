import React from 'react';
import GeneralDialogTemplate from '../component/common/general-dialog-template';
import postVuplexMessage from '../service/message-vuplex';

import errorImage from '../images/ErrorIcon_Tablet.png';
import GlueDialog from '../component/common/glue-dialog';

const UpdateAvailableDialog = (props: {
  open: boolean
	forceUpdate: boolean
  onClose: () => void
}) =>
{
	const quit = () => {
		postVuplexMessage('Quit', null);
	}

	const errorBody = (
		<p>
			It seems that you are not using the most recent version of Glue. 
			<br/>
			The latest version of Glue is available to download<br /> at https://glue.work/get-started-download-glue.
			<br/><br/>
			Thank you!
		</p>
	)

	const headerText = props.forceUpdate ? "Incompatible application version" : "Update available!";
	const callbacks = props.forceUpdate ? [ { label: 'Exit', color: "destructive", callback: quit } ] : [ {label: 'OK', color: 'primary', callback: () => props.onClose()} ];

	return(
		<GlueDialog
			open={props.open}
			onClose={props.onClose}
		>
			<GeneralDialogTemplate
				header={headerText}
				body={errorBody}
				image={errorImage}
				callbacks={callbacks}
			/>
		</GlueDialog>
	)
}

export default UpdateAvailableDialog;
