import React from 'react';
import { usePromptDialogContext } from '../util/prompt-dialog-context';
import GeneralDialogTemplate from '../component/common/general-dialog-template';

import errorImage from '../images/ErrorIcon_Tablet.png';
import { useUserContext } from '../util/user-context';

let roleChecked = false;

const SysAdminCheck = () =>
{
	const { addDialog } = usePromptDialogContext();

	const user = useUserContext();

	const errorBody = (
		<p>
			You are using a system admin account.<br/>
			This can cause all kinds of issues and weird behaviour.<br/> 
			It&apos;s recommended that you switch to a different account.
		</p>
	)

	if (!roleChecked && !user.loading && user.administrator)
	{
		roleChecked = true;
		addDialog(
			<GeneralDialogTemplate
				header="System admin found!"
				body={errorBody}
				image={errorImage}
			/>
		);
	}

	return null;
}

export default SysAdminCheck;