
import queryString from 'query-string';

import { initVuplexConnection, requestInitData } from './service/message-vuplex';
import { ApolloClient, InMemoryCache, HttpLink, ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import typeDefs from './graphql/types';
import { restorePreviousTeam } from './service/choose-current-team';
import postVuplexMessage from './service/message-vuplex';
import { registerDataListeners } from './service/message-vuplex';
import { setupStateBroadcasting } from './service/state-broadcast';
import { initializeClient } from './service/initialize-client';
import { setupMessageBroadcast } from './service/message-broadcast';
import { setupAlarmMonitoring } from './component/clock/clock-timer';
import { setFirebaseUserId, logFirebaseEvent } from './service/firebase/firebase';
import { setupDSP } from './util/dsp-utils';
import queries from './graphql/queries';
import { endTutorial, userHasTutorialOrg } from './util/tutorial-utils';
import { getUserSettings, setUserSettings } from './service/user-settings';
import { exchangeCodeToToken, validateToken } from './util/authentication-util';
import { setupSharing } from './util/sharing-utils';
import { CachePersistor } from 'apollo3-cache-persist';
import { ClientStorageWrapper } from './util/client-cache-persist';
import { setupFacilitation } from './component/facilitation/facilitation-app';

/**
 * @type {ApolloClient}
 */
let apollo = null;

const initBroadcastChannel = new BroadcastChannel('GlueOS init');

const hasClientConnection = () =>
{
	return typeof window.vuplex !== 'undefined';
};

const guessBackendAddress = () =>
{
	const COLLAB_BACKEND = 'https://collab.glue.work';
	const STAGING_BACKEND = 'https://staging.glue.work';
	const DEV_BACKEND = 'https://dev.glue.work';

	const knownBackendsMap = [
		[ 'web.glue.work', COLLAB_BACKEND ],
		[ 'glueos.glue.work', COLLAB_BACKEND ],
		[ 'webstaging.glue.work', STAGING_BACKEND ],
		[ 'glueos.internal.glue.work', STAGING_BACKEND ],
		[ 'webdev.glue.work', DEV_BACKEND ],
	];

	return knownBackendsMap.find(
		(pair) => pair[0] === window.location.hostname
	)?.[1] ?? null;
};

const getAuthAddress = () =>
{
	const backendURL = window.localStorage.getItem("backend-url");
	
	if (backendURL)
		return backendURL;

	// Try to guess
	return guessBackendAddress();
};

const saveBackendInfo = (info) =>
{
	if (info.token)
	{
		window.localStorage.setItem('backend-token', info.token);
	}

	if (info.url)
	{
		window.localStorage.setItem('backend-url', info.url);
	}
};

const onLogOut = async () =>
{
	console.log("Go to logout");

	const ui = apollo.readQuery({
		query: queries.ui
	});

	const authAddress = getAuthAddress();
	if (!authAddress)
	{
		console.error("Unknown auth address. Cannot logout.");
		return;
	}

	const userEmail = (await apollo.query({ query: queries.myEmail })).data?.myEmail ?? null;

	const logoutURL = new URL(authAddress);
	logoutURL.searchParams.append('logout', true);
	logoutURL.searchParams.append('userEmail', userEmail);

	if (ui.ui === 'web') {
		logoutURL.searchParams.append('redirectUri', window.location.origin);
		logoutURL.searchParams.append('browserOnly', true);
	} else {
		logFirebaseEvent("log_out");
		logoutURL.searchParams.append('ui', 'tablet');
	}

	window.localStorage.clear();

	window.location = logoutURL;
};

const logIn = () =>
{
	console.log("Go to login");

	const authAddress = getAuthAddress();
	if (!authAddress)
	{
		console.error("Could not figure out a login address.");
		return;
	}

	const loginURL = new URL(authAddress);
	loginURL.searchParams.append('redirectUri', window.location.origin);
	loginURL.searchParams.append('browserOnly', true);

	window.location = loginURL;
};

const initState = async (backend, token, isMainInstance) =>
{
	const ui = window.sessionStorage.getItem('ui');

	console.log("Init Apollo:", { backend: backend, token: token });

	const cache = new InMemoryCache({
		typePolicies: {
			Transform: { merge: true },
			Vector3: { merge: true },
			Query: {
				fields: {
					speechRecognition: { merge: true },
					clientPlatform: { merge: true },
				}
			}
		}
	});

	apollo = new ApolloClient({
		cache,
		typeDefs,
		link: ApolloLink.from([
			onError(({ graphQLErrors, networkError }) => {
				if (graphQLErrors)
				{
					graphQLErrors.forEach(e => {
						console.error("GraphQL error", e);
					});
				}

				if (networkError)
				{
					console.error("Network error", networkError);
					if (window.vuplex === undefined && networkError.statusCode === 401)
					{
						console.log("Auth is rejected by the server. Moving to login...");
						window.localStorage.removeItem('backend-token');
						logIn();
					}
				}
			}),

			new HttpLink({
				uri: backend + '/api/graphql',
				headers: {
					"Authorization": "Bearer " + token
				},
			})
		]),
		connectToDevTools: true
	});

	apollo.writeQuery({
		query: queries.currentTeamId,
		data: { currentTeamId: '' }
	});

	apollo.writeQuery({
		query: queries.currentOrgId,
		data: { currentOrgId: '' }
	});

	apollo.writeQuery({
		query: queries.backendInfo,
		data: {
			backendUri: backend,
			backendToken: token,
		}
	});

	apollo.writeQuery({
		query: queries.handUIActive,
		data: { handUIActive: false }
	});

	apollo.writeQuery({
		query: queries.uiDisabled,
		data: { uiDisabled: false }
	});

	apollo.writeQuery({
		query: queries.microphoneEnabled,
		data: { microphoneEnabled: false }
	});

	apollo.writeQuery({
		query: queries.microphoneLocked,
		data: { microphoneLocked: false }
	});

	apollo.writeQuery({
		query: queries.muteAllEnabled,
		data: { muteAllEnabled: false }
	})

	apollo.writeQuery({
		query: queries.uiDisabledState,
		data: { uiDisabledState: false }
	})

	apollo.writeQuery({
		query: queries.isFacilitator,
		data: { isFacilitator: false }
	})

	apollo.writeQuery({
		query: queries.announcerModeEnabled,
		data: { announcerModeEnabled: false }
	})

	apollo.writeQuery({
		query: queries.microphoneSettings,
		data: {
			microphoneList: [],
			currentAudioDevice: null
		}
	});

	apollo.writeQuery({
		query: queries.microphoneActivity,
		data: {
			microphoneActivity: 0
		}
	});

	apollo.writeQuery({
		query: queries.mouselookEnabled,
		data: { mouselookEnabled: false }
	});

	apollo.writeQuery({
		query: queries.currentSpaceServerKey,
		data: { currentSpaceServerKey: '' }
	});

	apollo.writeQuery({
		query: queries.tabletOpen,
		data: { tabletOpen: false }
	});

	apollo.writeQuery({
		query: queries.batteryStatus,
		data: {
			batteryStatus: {
				__typename: 'BatteryStatus',
				present: false,
				charging: false,
				chargeLevel: 0
			},
		}
	});

	apollo.writeQuery({
		query: queries.pointingToolStatus,
		data: {
			pointingToolStatus: {
				__typename: 'PointingToolStatus',
				active: false,
				attached: false
			}
		}
	});

	apollo.writeQuery({
		query: queries.draw3DToolStatus,
		data: {
			draw3DToolStatus: {
				__typename: 'Draw3DToolStatus',
				active: false,
				attached: false,
				color: '#FFFFFF'
			}
		}
	});

	apollo.writeQuery({
		query: queries.clockStopwatch,
		data: {
			clockStopwatch: {
				__typename: 'ClockStopwatch',
				elapsed: 0,
				startedAt: 0,
				running: false
			}
		}
	});

	apollo.writeQuery({
		query: queries.clockTimer,
		data: {
			clockTimer: {
				__typename: 'ClockTimer',
				timeLeft: 0,
				startedAt: 0,
				running: false,
				alarm: false
			}
		}
	});

	apollo.writeQuery({
		query: queries.masterVolume,
		data: { masterVolume: 0 }
	});

	apollo.writeQuery({
		query: queries.environmentVolume,
		data: { environmentVolume: 0 }
	});

	apollo.writeQuery({
		query: queries.toolVolume,
		data: { toolVolume: 0 }
	});

	apollo.writeQuery({
		query: queries.displaySettings,
		data: {
			displaySettings: {
				__typename: 'DisplaySettings',
				availableResolutions: [],
				currentResolution: '',
				availableRefreshRates: [],
				currentRefreshRate: '',
				fullScreen: true
			}
		}
	});

	apollo.writeQuery({
		query: queries.dspToggles,
		data: {
			dspToggles: {
				__typename: 'DspToggles',
				normalization: 0,
				noiseGate: 0,
				echoCancellation: 0
			}
		}
	});

	apollo.writeQuery({
		query: queries.dspSettings,
		data: {
			dspSettings: {
				__typename: 'DspSettings',
				normalizationLevel: 3,
				noiseGateLevel: 3,
				echoCancellationLevel: 3,
				lowerThreshold: -2.0,
				upperThreshold: 13.0,
				holdTime: 1,
				attack: 5,
				release: 10,
				energyThreshold: -26.0,
				gainOneThreshold: 10.0,
				gainTwoThreshold: 30.0,
				muMax: 1.0,
				muAfterSilence: 1.0,
				thresholdBackground: 0.15,
				thresholdForeground: 0.35,
				thresholdBackgroundDetectionOff: 0.45,
				thresholdForegroundDetectionOff: 0.25,
				thresholdSilence: 0.05,
				resetSpeed: 4,
				adaptationTimeAfterSilence: 2
			}
		}
	});

	apollo.writeQuery({
		query: queries.encodingSettings,
		data: {
			encodingSettings: {
				__typename: 'EncodingSettings',
				qualityLevel: 1,
				targetFramerate: 5,
				targetBitrate: 100000,
				threadCount: 1,
				framebufferCount: 1,
				outputBufferSize: 1024,
				qMin: 0,
				qMax: 50,
				slices: 1,
				profile: 0,
				keyIntMin: 1,
				fallbackQuality: 50,
				fallbackSendInterval: 1.0,
				fallbackReceiveInterval: 0.5,
				fallbackWaitThreshold: 5
			}
		}
	});

	apollo.writeQuery({
		query: queries.clientVersion,
		data: { clientVersion: '0.0.0' }
	});

	apollo.writeQuery({
		query: queries.clientHash,
		data: { clientHash: 'null' }
	});

	apollo.writeQuery({
		query: queries.clientPlatform,
		data: {
			clientPlatform: {
				__typename: 'ClientPlatform',
				OS: null,
				DeviceModel: null,
				PlatformType: null,
				Capabilities: null
			}
		}
	});

	apollo.writeQuery({
		query: queries.speechRecognition,
		data: {
			speechRecognition: {
				__typename: 'SpeechRecognition',
				previewText: '',
				text: '',
				loading: false,
				running: false,
				listening: false,
				sinkId: null,
				dialogOpen: false,
				failed: false
			}
		}
	});

	apollo.writeQuery({
		query: queries.fileImport,
		data: {
			fileImport: {
				__typename: 'FileImport',
				loading: false
			}
		}
	});

	apollo.writeQuery({
		query: queries.fileUpload,
		data: {
			fileUpload: {
				__typename: 'FileUpload',
				uploadStatus: ""
			}
		}
	});

	apollo.writeQuery({
		query: queries.crashReport,
		data: { crashReport: false }
	});

	apollo.writeQuery({
		query: queries.teamFilePreview,
		data: { teamFilePreview: null }
	});

	apollo.writeQuery({
		query: queries.viewDownloadedFile,
		data: { viewDownloadedFile: false }
	});

	apollo.writeQuery({
		query: queries.takeGroupValue,
		data: { takeGroupValue: false }
	});

	apollo.writeQuery({
		query: queries.penResetRequest,
		data: { penResetRequest: false }
	});

	apollo.writeQuery({
		query: queries.context,
		data: { 
			context: {
				__typename: 'Context',
				header: "",
				contextList: []
			}
		}
	});

	apollo.writeQuery({
		query: queries.sessionTimeRemaining,
		data: { sessionTimeRemaining: 21600000 }
	});

	apollo.writeQuery({
		query: queries.ui,
		data: { ui: ui }
	});

	apollo.writeQuery({
		query: queries.noteEdited,
		data: { noteEdited: false }
	});

	apollo.writeQuery({
		query: queries.toolbarMode,
		data: { toolbarMode: 'main' }
	});

	apollo.writeQuery({
		query: queries.objectTransformLock,
		data: { objectTransformLock: false }
	});

	apollo.writeQuery({
		query: queries.objectWorldSpace,
		data: { objectWorldSpace: true }
	});

	apollo.writeQuery({
		query: queries.objectTransform,
		data: {
			transform: {
				__typename: 'Transform',
				position: {
					__typename: 'Vector3',
					x: 0,
					y: 0,
					z: 0
				},
				rotation: {
					__typename: 'Vector3',
					x: 0,
					y: 0,
					z: 0
				},
				scale: {
					__typename: 'Vector3',
					x: 1,
					y: 1,
					z: 1
				},
			}
		}
	});

	apollo.writeQuery({
		query: queries.promptDialogOpen,
		data: { promptDialogOpen: false }
	});

	apollo.writeQuery({
		query: queries.exitDialogOpen,
		data: { exitDialogOpen: false }
	});

	apollo.writeQuery({
		query: queries.glueOSIsSharing,
		data: { glueOSIsSharing: false }
	});

	apollo.writeQuery({
		query: queries.analyticsUserID,
		data: { analyticsUserID: '' }
	});

	apollo.writeQuery({
		query: queries.presentationViewControls,
		data: { presentationViewControls: false }
	});

	apollo.writeQuery({
		query: queries.externalBrowserInfo,
		data: {
			externalBrowserInfo: {
				__typename: 'ExternalBrowserInfo',
				instanceId: '',
				sharing: false
			}
		}
	});

	apollo.writeQuery({
		query: queries.joinSpaceError,
		data: { joinSpaceError: '' }
	});

	if (hasClientConnection())
	{
		console.log("Setting up broadcasts...")
		setupStateBroadcasting(apollo, isMainInstance);
		setupMessageBroadcast();
	}
	else
	{
		console.log("No state broadcasting")
	}

	// For instances that have an instance ID assigned by the client, persist apollo cache on the client side
	// If the browser process dies, it can be revived with the cache intact
	const instanceId = window.sessionStorage.getItem('instance-id');
	if (hasClientConnection() && instanceId) {
		const persistor = new CachePersistor({
			cache,
			storage: new ClientStorageWrapper(),
			// This can be used to filter the cache, I'm removing the backend token just in case...
			persistenceMapper: async (data) => {
				const parsedData = JSON.parse(data);
				delete parsedData.ROOT_QUERY.backendToken;
				return JSON.stringify(parsedData);
			}
		});
		await persistor.restore();
	}

	registerDataListeners(apollo);
	requestInitData();

	await setFirebaseUserId(apollo);
	logFirebaseEvent('login', { method: 'Glue authentication' });

	if (isMainInstance && hasClientConnection())
	{
		setupAlarmMonitoring(apollo);
		setupFacilitation(apollo);
		await initializeClient(apollo);
		await setupDSP(apollo);
		await setupSharing(apollo);

		// If the client crashed or the user quit Glue while in the tutorial, the tutorial org has not been deleted
		// If that's the case, delete it here when initializing GlueOS
		// This won't happen in the web to prevent the tutorial from being deleted in the edge case where the user wants to use Glue Web while in the tutorial
		const hasTutorialOrg = await userHasTutorialOrg(apollo);
		if (hasTutorialOrg) {
			await endTutorial(apollo);
		}
	}

	// Handle first time login
	const userSettings = await getUserSettings(apollo);
	if (!userSettings.firstLogin) {
		console.log('Logging first time login...');
		await setUserSettings(apollo, { firstLogin: true });
		await logFirebaseEvent('firstLogin');
	}

	if (isMainInstance)
	{
		await restorePreviousTeam(apollo);
	}
};

export const initGlueOS = async () => {
	const hashParams = queryString.parse(document.location.hash);
	let originalSearchParams = (new URL(document.location)).searchParams;
	if (originalSearchParams.get('state')) {
		// Decode state to search params
		const stateParams = new URLSearchParams(atob(originalSearchParams.get('state')));
		originalSearchParams = new URLSearchParams({
			...Object.fromEntries(originalSearchParams),
			...Object.fromEntries(stateParams)
		});
	}

	const copyDefinedParamToSession = (name, defaultValue) =>
	{
		const value = originalSearchParams.get(name);
		if (!!value || !window.sessionStorage.getItem(name))
			window.sessionStorage.setItem(name, !!value ? value : defaultValue);
	};

	copyDefinedParamToSession('ui', 'web');
	copyDefinedParamToSession('instance-id', '');
	copyDefinedParamToSession('wait-for-vuplex', '');
	copyDefinedParamToSession('enableDevTools', '');
	
	// Presentation parameters
	copyDefinedParamToSession('sessionId', '');
	copyDefinedParamToSession('objectId', '');
	copyDefinedParamToSession('inventoryItemId', '');
	copyDefinedParamToSession('document-controls', 'false');

	// Browser parameters
	copyDefinedParamToSession('appname', '');
	copyDefinedParamToSession('appurl', '');
	copyDefinedParamToSession('isProdTool', '');

	const isMainInstance = ['tablet', 'web'].some((ui) => ui === window.sessionStorage.getItem('ui'));

	await initVuplexConnection();

	let backendTokenFromCodeExchange = null;
	const getKnownBackendInfo = () => ({
		token: (
			backendTokenFromCodeExchange ??
			hashParams.id_token ??
			originalSearchParams.get('backend-token') ??
			window.localStorage.getItem('backend-token') ??
			null
		),
	
		url: (
			originalSearchParams.get('backend-url') ??
			window.localStorage.getItem('backend-url') ??
			guessBackendAddress() ??
			null
		)
	});

	// Check if authorization code exists instead of backend token. If yes, fetch backend token with code
	const authorizationCode = originalSearchParams.get('code');
	if (authorizationCode) {
		backendTokenFromCodeExchange = await exchangeCodeToToken(authorizationCode, getKnownBackendInfo().url);
	}

	//  Possibly wait for auth to complete in another GlueOS instance
	const backendInfo = await new Promise((resolve, reject) => {
		const knownBackendInfo = getKnownBackendInfo();

		// Info might have come from ephemeral sources like query params etc.
		saveBackendInfo(knownBackendInfo);

		const waitForAuthInfo = () => {
			if (isMainInstance)
			{
				console.warn("Auth info is missing. Cannot proceed without login.");
				logIn();
				return reject();
			}
			else
			{
				console.log("Login info is missing. Waiting until auth info becomes available.");

				initBroadcastChannel.onmessage = (msg) => {
					console.log('Received GlueOS init message', msg);
					if (msg.data.topic === 'Authenticated')
					{
						resolve(getKnownBackendInfo());
					}
				};
			}
		}

		if (knownBackendInfo.token && knownBackendInfo.url)
		{
			console.log("Auth info is available", knownBackendInfo);
			validateToken(knownBackendInfo.token, knownBackendInfo.url).then(tokenValid => {
				if (tokenValid) {
					console.log("Backend token is valid!");
					resolve(getKnownBackendInfo());
				}
				else {
					console.log("Backend token is invalid!");
					waitForAuthInfo();
				}
			});

			return;
		}

		return waitForAuthInfo();
	}).catch(e => {
		return null;
	});

	if (!backendInfo)
	{
		console.error("Aborting GlueOS startup. Backend info is not available.");
		return null;
	}

	if (isMainInstance)
	{
		saveBackendInfo(backendInfo);
		await new Promise((resolve) => setTimeout(resolve)); // Must wait for Local Storage writes to be shared across windows.
		initBroadcastChannel.postMessage({ topic: 'Authenticated' });
	}

	if (hasClientConnection() && backendInfo.token)
	{
		postVuplexMessage("User token", { value: backendInfo.token });
	}

	await initState(backendInfo.url, backendInfo.token, isMainInstance);

	document.addEventListener("logOut", onLogOut);
	// Disable right click
	document.addEventListener("contextmenu", event => event.preventDefault());

	return { apollo };
};
