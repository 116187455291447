import React, { useState } from 'react';
import tinycolor from 'tinycolor2';

import { makeStyles } from '@material-ui/core';
import SliderSelector from './slider-selector';

import ColorSelector from '../../icons/Color_Selector.png'
import GlueInputfield from './glue-inputfield';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'grid',
		gap: theme.glueSpacing('s'),
	},

	sliderRow: {
		display: 'grid',
		gridTemplateColumns: `${theme.custom.colorPicker.fieldWidth} auto`,
		gap: theme.glueSpacing('m'),

		'& .MuiGlueInputField-searchfield': theme.custom.colorPicker.searchField,
		'& input': theme.custom.colorPicker.inputField,
	},

	slider: {
		margin: 'auto 16px'
	},

	thumbGraphic: {
		width: '64px',
		height: '64px'
	}
}), { name: "MuiGlueColorSliders" });

const ColorSliders = (props) => 
{
	const classes = useStyles();

	const hsv = props.hsv;

	const [editedInput, setEditedInput] = useState(null);
	
	const hue = hsv.h;
	const saturation = hsv.s;
	const value = hsv.v;
	
	const saturationColor = tinycolor(`hsv(${hue}, 100%, 100%)`).toHexString();
	const lightnessColor = tinycolor(`hsv(${hue}, ${saturation}, 100%)`).toHexString();
	
	const rgb = tinycolor(hsv).toRgb();
	const red = rgb.r;
	const green = rgb.g;
	const blue = rgb.b;

	const firstSliderValue = props.colorMode ? Math.trunc(Math.round(hue)) : red;
	const secondSliderValue = props.colorMode ? Math.trunc(Math.round(saturation * 100)) : green;
	const thirdSliderValue = props.colorMode ? Math.trunc(Math.round(value * 100)) : blue;

	const firstSliderGradient = props.colorMode ?
			"linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(246,255,0,1) 17%, rgba(0,255,24,1) 34%, rgba(0,247,255,1) 50%, rgba(0,6,255,1) 66%, rgba(255,0,229,1) 83%, rgba(255,0,0,1) 100%)"
			: `linear-gradient(90deg, ${tinycolor(`rgb(0, ${green}, ${blue})`)}, ${tinycolor(`rgb(255, ${green}, ${blue})`)}`;

	const secondSliderGradient = props.colorMode ?
			`linear-gradient(90deg, #FFF, ${saturationColor})`
			: `linear-gradient(90deg, ${tinycolor(`rgb(${red}, 0, ${blue})`)},  ${tinycolor(`rgb(${red}, 255, ${blue})`)})`;

	const thirdSliderGradient = props.colorMode ?
			`linear-gradient(90deg, #000000, ${lightnessColor})`
			: `linear-gradient(90deg, ${tinycolor(`rgb(${red}, ${green}, 0)`)}, ${tinycolor(`rgb(${red}, ${green}, 255)`)})`;

	const thumbGraphic = (props) => {
		return (
			<span {...props}>
				<img className={classes.thumbGraphic} src={ColorSelector} alt="Logo" />
			</span>
		);
	}

	const setHue = (newHue, commit) =>
	{
		setEditedInput(null);
		
		if (!isFinite(newHue))
			return;
		
		const newhsv = {h: Math.min(newHue, 360), s: saturation, v: value};
		props.onChange(newhsv, commit);
	}

	const setSaturation = (newSaturation, commit) =>
	{
		setEditedInput(null);

		if (!isFinite(newSaturation))
			return;

		const newhsv = {h: hue, s: Math.min(newSaturation, 1), v: value};
		props.onChange(newhsv, commit);
	}

	const setValue = (newValue, commit) =>
	{
		setEditedInput(null);
		
		if (!isFinite(newValue))
			return;

			const newhsv = {h: hue, s: saturation, v: Math.min(newValue, 1)};
			props.onChange(newhsv, commit);
	}

	const setRed = (newRed, commit) =>
	{
		setEditedInput(null);
		
		if (!isFinite(newRed))
			return;

		const newhsv = tinycolor(`rgb(${newRed}, ${green}, ${blue})`).toHsv();
		props.onChange(newhsv, commit);
	}

	const setGreen = (newGreen, commit) =>
	{
		setEditedInput(null);
		
		if (!isFinite(newGreen))
			return;

		const newhsv = tinycolor(`rgb(${red}, ${newGreen}, ${blue})`).toHsv();
		props.onChange(newhsv, commit);
	}

	const setBlue = (newBlue, commit) =>
	{
		setEditedInput(null);
		
		if (!isFinite(newBlue))
			return;

		const newhsv = tinycolor(`rgb(${red}, ${green}, ${newBlue})`).toHsv();
		props.onChange(newhsv, commit);
	}

	const handleInputFocus = (focusValue, index, defaultValue) =>
	{
		if (focusValue === true)
		{
			setEditedInput({index: index, value: defaultValue});
		}
		else if (editedInput?.index === index)
		{
			setEditedInput(null);
		}
	}

	const sliders = (
		<>
			<div className={classes.sliderRow}>
				<GlueInputfield
					simpleInput
					width={'144px'}
					backgroundColor={'black'}
					centerText
					value={editedInput?.index === 0 ? editedInput?.value : firstSliderValue}
					setFocus={(value) => handleInputFocus(value, 0, firstSliderValue)}
					onChange={(value) => setEditedInput({index: 0, value: value})}
					onSubmit={(value) => props.colorMode ? setHue(value, true) : setRed(value, true)}
				/>
				<div className={classes.slider}>
					<SliderSelector
						ThumbComponent={thumbGraphic}
						thumbColor={props.colorMode ? 
							tinycolor(`hsl(${hue}, 100%, 50%)`)
							: tinycolor(`rgb(${red}, ${green}, ${blue})`)}
						transparent
						backgroundImage={firstSliderGradient}
						height={16}
						min={0}
						max={props.colorMode ? 360 : 255}
						step={1}
						defaultValue={firstSliderValue}
						value={firstSliderValue}
						onChange={props.colorMode ? (e, value) => setHue(value) : (e, value) => setRed(value)}
						onChangeCommitted={props.onCommitChange}
					/>
				</div>
			</div>
			<div className={classes.sliderRow}>
				<GlueInputfield
					simpleInput
					width={'144px'}
					backgroundColor={'black'}
					centerText
					value={editedInput?.index === 1 ? editedInput?.value : secondSliderValue}
					setFocus={(value) => handleInputFocus(value, 1, secondSliderValue)}
					onChange={(value) => setEditedInput({index: 1, value: value})}
					onSubmit={(value) => props.colorMode ? setSaturation(value / 100, true) : setGreen(value, true)}
				/>
				<div className={classes.slider}>
					<SliderSelector
						ThumbComponent={thumbGraphic}
						thumbColor={props.colorMode ?
							lightnessColor
							: tinycolor(`rgb(${red}, ${green}, ${blue})`)}
						transparent
						backgroundImage={secondSliderGradient}
						height={16}
						min={0}
						max={props.colorMode ? 100 : 255}
						step={1}
						defaultValue={secondSliderValue}
						value={secondSliderValue}
						onChange={props.colorMode ? (e, value) => setSaturation(value / 100) : (e, value) => setGreen(value)}
						onChangeCommitted={props.onCommitChange}
					/>
				</div>
			</div>
			<div className={classes.sliderRow}>
				<GlueInputfield
					simpleInput
					width={'144px'}
					backgroundColor={'black'}
					centerText
					value={editedInput?.index === 2 ? editedInput?.value : thirdSliderValue}
					setFocus={(value) => handleInputFocus(value, 2, thirdSliderValue)}
					onChange={(value) => setEditedInput({index: 2, value: value})}
					onSubmit={(value) => props.colorMode ? setValue(value / 100, true) : setBlue(value, true)}
				/>
				<div className={classes.slider}>
					<SliderSelector
						ThumbComponent={thumbGraphic}
						thumbColor={tinycolor(`rgb(${red}, ${green}, ${blue})`)}
						transparent
						backgroundImage={thirdSliderGradient}
						height={16}
						min={0}
						max={props.colorMode ? 100 : 255}
						step={1}
						defaultValue={thirdSliderValue}
						value={thirdSliderValue}
						onChange={props.colorMode ? (e, value) => setValue(value / 100) : (e, value) => setBlue(value)}
						onChangeCommitted={props.onCommitChange}
					/>
				</div>
			</div>
		</>
	);

	return(
		<div className={classes.root}>
			{sliders}
		</div>
	)
}

export default ColorSliders;