import React, { useState } from 'react';
import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core';

import queries from '../../graphql/queries';
import WebInputfield from '../../standalone-web/common/web-inputfield';
import GlueInputfield from '../common/glue-inputfield';

import { sttActive } from '../../util/speech-utils';

import GlueListView, { alphabeticalSortFunc } from '../common/glue-list-view';
import { useUserContext } from '../../util/user-context';
import GlueButton from '../common/glue-button';
import mutations from '../../graphql/mutations';

import MemberAddDialog from './member-add-dialog';

import { Check } from '@material-ui/icons';
import { usePromptDialogContext } from '../../util/prompt-dialog-context';
import InfoDialogTemplate from '../common/info-dialog-template';
import { changeTeamMemberRole } from '../../util/team-utils';

const themeVar = (theme, key) =>
	theme.custom.manageTeamMembers?.[key] ?? null;

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		height: '100%',
		padding: themeVar(theme, 'rootPadding'),
		display: 'flex',
		flexFlow: 'column nowrap'
	},

	header: {
		display: 'flex',
		flexDirection: 'row',
		gap: themeVar(theme, 'actionGap'),
		marginBottom: themeVar(theme, 'actionBarGap'),

		'& button:last-child': {
			marginLeft: 'auto'
		}
	},

	roleInvitationPending: {
		color: theme.custom.manageTeamMembers.invitationPendingColor
	}
}));

const ManageTeamMembers = (props) =>
{
	const classes = useStyles({ props });
	const apollo = useApolloClient();

	const user = useUserContext();
	const { addDialog } = usePromptDialogContext();

	const uiMode = useQuery(queries.ui);
	const sttRes = useQuery(queries.speechRecognition);
	const currentTeamIdRes = useQuery(queries.currentTeamId);
	const currentOrgIdRes = useQuery(queries.currentOrgId);
	const teamInfoRes = useQuery(queries.teamInfo,
		{
			skip: !currentTeamIdRes.data,
			variables: { 
				teamId: currentTeamIdRes.data?.currentTeamId
			},
			fetchPolicy: 'cache-and-network'
		}
	);

	const orgInfoRes = useQuery(queries.getOrg, {
		skip: !currentOrgIdRes.data,
		variables: {
			orgId: currentOrgIdRes.data.currentOrgId
		}
	});

	const [leaveMut] = useMutation(mutations.userLeaveTeam);

	const changeRole = async (member) => {
		// TODO: Get rid of these hardcoded strings!
		const newRole = member.memberRole === 'superuser' ? 'member' : 'superuser';

		try {
			await changeTeamMemberRole(apollo, currentTeamIdRes.data.currentTeamId, member.email, newRole);
			teamInfoRes.refetch();
		}
		catch (err) {
			addDialog(<InfoDialogTemplate 
				isError={true}
				message={err.message}
			/>)
		}
	}

	const canRemoveCheck = (member) => {
		addDialog(<InfoDialogTemplate
			header={'Remove user from this team?'}
			message={`Are you sure you want to remove ${member.email} from this team?`}
			callbacks={[
				{ label: 'Cancel', callback: () => null },
				{ label: 'Remove', color: 'destructive', callback: () => removeUser(member.email) }
			]}
		/>)
	}

	const removeUser = async (email) => {

		await leaveMut({
			variables: {
				teamId: currentTeamIdRes.data?.currentTeamId,
				memberId: email
			},
			refetchQueries: [{
				query: queries.teamInfo,
				variables: {
					teamId: currentTeamIdRes.data?.currentTeamId
				}
			}]
		}).catch(err => {
			console.error(err);
			addDialog(<InfoDialogTemplate
				isError={true}
				message={err.message}
			/>)
		});
	}

	const [filterTerm, setFilterTerm] = useState('');

	const changeFilterTerm = (rawInput) => 
	{
		setFilterTerm(String(rawInput).toLowerCase().trim());
	};

	/** @type {Array} */
	let members = teamInfoRes.data?.teamInfo.members ?? [];

	// Filter
	const filterKeys = ['email', 'nickname'];
	members = members.filter((member) =>
		!filterTerm || filterKeys.some((key) => String(member[key]).includes(filterTerm))
	);

	const roleSortOrder = [ 'superuser', 'member', 'guest' ];

	const roleSortFunc = (a, b, key, dir) =>
	{
		if (isOrgAdmin(a)) {
			return -1 * dir;
		}

		return (roleSortOrder.indexOf(a.memberRole) < roleSortOrder.indexOf(b.memberRole) ? -1 : 1) * dir;
	}

	const getRoleDisplay = (teamMember, key) =>
	{
		if (teamMember.memberRole === 'superuser' || isOrgAdmin(teamMember)) {
			return(
				<Check />
			)
		}
		return ''
	}

	const isOrgAdmin = (member) => {
		const orgRole = orgInfoRes.data?.getOrg.members.find(item => item.email === member.email)?.memberRole;

		if (orgRole === 'admin') {
			return true;
		}

		return false;
	}

	const getButtonContent = (member) => {
		return member.memberRole === 'superuser' ? 'Remove as Team Admin' : 'Set as Team Admin'
	}

	const columns = [
		{key: 'email', label: 'member', width: '2fr', contentFunc: undefined, sortFunc: alphabeticalSortFunc},
		{key: 'memberRole', label: 'team admin', width: '1fr', contentFunc: getRoleDisplay, sortFunc: roleSortFunc},
	];

	const menuOptions = [
		{ key: 'changeRole', content: getButtonContent, width: '0.5fr', callback: changeRole, hide: (item) => isOrgAdmin(item) },
		{ key: 'removeMember', content: 'Remove member', width: '0.5fr', callback: canRemoveCheck }
	]

	const searchSTTSinkId = 'ManageTeamMembersFilterTerm';
	const searchTermInputFieldCommonParams = {
		search: true,
		showClearButton: filterTerm ? true : false,
		placeholder: "Search Members",
		value: filterTerm,
		onChange: changeFilterTerm,
		onClear: () => changeFilterTerm('')
	};

	const menuDisabledFunction = (item) =>
	{
		if ((user.team?.membersEdit || user.org?.membersEdit)) {
			return false;
		}

		return true;
	}

	return (
		<div className={classes.root}>
			<div className={classes.header}>
				{(user.team?.membersEdit || user.org?.membersEdit) && 
					<GlueButton color='primary' onPointerDown={() => addDialog(
						<MemberAddDialog 
							web={props.web} 
							teamId={currentTeamIdRes.data?.currentTeamId} 
							orgId={currentOrgIdRes.data?.currentOrgId} 
							refresh={() => teamInfoRes.refetch()}
							teamAdmin={true} />)}
						>Manage Members</GlueButton>}
				{uiMode.data?.ui === 'web' ? (
					<WebInputfield {...searchTermInputFieldCommonParams} />
				) : (
					<GlueInputfield
						{...searchTermInputFieldCommonParams}
						width={'auto'}
						sinkId={searchSTTSinkId}
						speechInput={sttActive(sttRes, searchSTTSinkId)}
						onSpeechChange={changeFilterTerm}
					/>
				)}
			</div>

			<GlueListView
				disableSelection={true}
				columns={columns}
				items={members}
				defaultSortKey={'email'}
				overrideIdKey={'email'}
				menuOptions={menuOptions}
				menuDisabledFunc={menuDisabledFunction}
				defaultSortDir={1}
			/>
		</div>
	);
};

export default ManageTeamMembers;