import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useQuery } from '@apollo/react-hooks';
import AppHeader from '../common/app-header';
import LaunchIcon from '@material-ui/icons/Launch';
import GlueButton from '../common/glue-button';
import { makeStyles } from '@material-ui/core';
import postVuplexMessage from '../../service/message-vuplex';
import queries from '../../graphql/queries';

const useStyles = makeStyles(theme => ({
	surface: {
		width: '1350px',
		height: '760px',
		background: '#000',
		margin: '52px 8px',
	},
}), { name: 'MuiGluePresentationViewApp' });

const PresentationViewApp = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const tabletOpenResult = useQuery(queries.tabletOpen);

	const stateRes = useQuery(queries.presentationViewControls);
	const controlsActive = stateRes?.data?.presentationViewControls ?? false;

	useEffect(() => {
		if (tabletOpenResult.data.tabletOpen)
			postVuplexMessage('Enable hotkeys', { value: false });
		else
			postVuplexMessage('Enable hotkeys', { value: true });
		return () => {
			postVuplexMessage('Enable hotkeys', { value: true });
		};
	}, [tabletOpenResult.data.tabletOpen]);

	useEffect(() => {
		postVuplexMessage('Tablet.SetPresentationViewerVisible', { value: true });
		return () => {
			postVuplexMessage('Tablet.SetPresentationViewerVisible', { value: false });
		}
	});

	const onTakeControl = () => {
		postVuplexMessage('PresentationView.OpenControls', null);
		postVuplexMessage("Close tablet", null);
		navigate('/');
	}

	const onDetach = () => {
		postVuplexMessage('PresentationView.OpenViewer', null);
		postVuplexMessage("Close tablet", null);
		navigate('/');
	}

	return (
		<>
			<AppHeader
				title='Presentation View'
				secondChildren={
					<>
						<GlueButton
							disabled={!controlsActive}
							color='secondary'
							onPointerDown={onTakeControl}
						>
							Control presentation
						</GlueButton>
						<GlueButton variant='icon' color='stealth' onPointerDown={onDetach}>
							<LaunchIcon />
						</GlueButton>
					</>
				}
				selected={false}
			/>
			<div className={classes.surface} />
		</>
	);
}

export default PresentationViewApp;
