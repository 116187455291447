/* Collection of utility functions that are to be used to refresh the local Apollo cache.
 * Call these when you know their data might have changed on the server.
 *
 * This is a temp solution for keeping Apollo data cache somewhat up-to-date.
 * All this should be removed when we have server side support for GraphQL subscriptions.
*/

import queries from "../graphql/queries";

export const refreshTeamFiles = async (apollo) =>
{
	const teamIdRes = await apollo.query({
		query: queries.currentTeamId
	});

	if (!teamIdRes.data.currentTeamId)
		return false;

	await apollo.query({
		query: queries.teamInventoryItems,
		fetchPolicy: 'network-only',
		errorPolicy: 'all',
		skip: teamIdRes.error,
		variables: {
			teamId: teamIdRes.data.currentTeamId
		},
	});

	return true;
};
