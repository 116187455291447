import React, { useState } from "react";

import { makeStyles } from "@material-ui/core";
import WebInputfield from "../../standalone-web/common/web-inputfield";
import { useMutation, useQuery } from "@apollo/react-hooks";
import queries from "../../graphql/queries";
import GlueDropdown from "../common/glue-dropdown";
import GlueButton from "../common/glue-button";
import mutations from "../../graphql/mutations";
import { usePromptDialogContext } from "../../util/prompt-dialog-context";
import InfoDialogTemplate from "../common/info-dialog-template";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowBack } from "@material-ui/icons";
import SpaceTile from "../spaces/space-tile";
import AddSpaceDialog from "../spaces/space-template-details-dialog";
import MemberDetailsDialog from "../members/member-details-dialog";
import MemberAddDialog from "../members/member-add-dialog";

const useStyles = makeStyles((theme) => ({
	root: {

	},

	header: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		gap: theme.glueSpacing('xl'),
		height: '60px',
		borderBottom: `2px solid ${theme.palette.secondary.main}`,
		borderTop: `2px solid ${theme.palette.secondary.main}`
	},

	overview: {
		height: '300px',
		display: 'flex',
		flexDirection: 'column',
		gap: theme.glueSpacing('m'),
		borderLeft: `2px solid ${theme.palette.secondary.main}`,
		borderRight: `2px solid ${theme.palette.secondary.main}`
	},

	subHeader: {
		display: 'flex',
		flexDirection: 'row',
		gap: theme.glueSpacing('s')
	},

	content: {
		paddingTop: theme.glueSpacing('m'),
		height: '85%',
		display: 'grid',
		gridTemplateColumns: 'repeat(3, 1fr)'
	},

	section: {
		display: 'flex',
		flex: '1 0 auto',
		flexDirection: 'column',
		alignItems: 'center',
		gap: theme.glueSpacing('m'),
		borderRight: `2px solid ${theme.palette.secondary.main}`
	},

	buttons: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		gap: theme.glueSpacing('m'),
	},

	listing: {
		width: '100%',
		display: 'flex',
		flex: '1 1 auto',
		flexFlow: 'column wrap',
		gap: theme.glueSpacing('l'),
		padding: `${theme.glueSpacing('m')}`
	}
}));

const teamTiers = [
	{ id: 0, name: 'Free' },
	{ id: 1, name: 'Paid' },
	{ id: 9, name: 'Restrictions Removed' },
]

const isValidTeamTier = (val) =>
{
	if (teamTiers.some(tier => tier.id === val))
		return true;
	return false;
}

const TeamView = (props) =>
{
	const classes = useStyles();
	const navigate = useNavigate();
	const { teamId } = useParams();

	const { addDialog } = usePromptDialogContext();

	const [memberDetailsOpen, setMemberDetailsOpen] = useState(false);

	const [roomSearchWord, setRoomSearchWord] = useState('');
	const [memberSearchWord, setMemberSearchWord] = useState('');

	const teamInfoRes = useQuery(queries.teamInfo, {
		skip: !teamId,
		variables: {
			teamId: teamId
		}
	});

	const currentOrg = useQuery(queries.getOrgIdOfTeam, {
		skip: !teamId,
		variables: {
			teamId: teamId
		}
	});

	const myRoomsRes = useQuery(queries.myRooms, {
		skip: !teamId,
		variables: {
			teamId: teamId
		}
	});

	const assetsResult = useQuery(queries.myTeamAssets, {
		skip: !teamId,
		variables: {
			teamId: teamId,
			typeFilter: 'space'
		},
		fetchPolicy: 'network-only'
	});

	const availableAssets = assetsResult.data?.myTeamAssets ?? [];

	const [teamName, setTeamName] = useState('')
	const [teamTier, setTeamTier] = useState('');

	const [ updateTeamMut ] = useMutation(mutations.updateTeam);
	const [ deleteTeamMut ] = useMutation(mutations.deleteTeam);

	let rooms = myRoomsRes.data?.myRooms ?? [];
	let members = teamInfoRes.data?.teamInfo.members ?? [];

	members = members.filter(member => member.email.toLowerCase().includes(memberSearchWord.toLowerCase()));
	rooms = rooms.filter(room => room.name.toLowerCase().includes(roomSearchWord.toLowerCase()))

	const updateTeam = async () =>
	{
		if (!isValidTeamTier(teamTier) && !teamName) {
			console.warn("Nothing changed, returning...");
			return;
		}

		await updateTeamMut({
			variables: {
				teamId: teamInfoRes.data.teamInfo.teamId,
				name: teamName.trim() ? teamName : teamInfoRes.data.teamInfo.name,
				teamTier: isValidTeamTier(teamTier) ? teamTier : teamInfoRes.data.teamInfo.teamTier
			},
			refetchQueries: [{
				query: queries.teamInfo,
				variables: {
					teamId: teamId
				}
			}]
		}).then(res => {
			setTeamName('')
			addDialog(<InfoDialogTemplate
				header={'Updated'}
				message={'Team was updated'}
			/>)
		}).catch(e => {
			addDialog(<InfoDialogTemplate
				isError={true}
				message={e.message}
			/>)
		});
	}

	const deleteCheck = () =>
	{
		addDialog(<InfoDialogTemplate 
			header={'Delete?'}
			message={'Are you sure you want to delete this team?'}
			callbacks={[
				{ label: 'Cancel', callback: () => null },
				{ label: 'Delete', color: 'destructive', callback: () => deleteTeam()}
			]}
		/>)
	}

	const deleteTeam = async () =>
	{
		await deleteTeamMut({
			variables: {
				teamId: teamInfoRes.data.teamInfo.teamId,
			}
		}).then(res => {
			addDialog(<InfoDialogTemplate
				header={'Success'}
				message={'Team was deleted'}
			/>)
			navigate(-1);
		}).catch(e => {
			addDialog(<InfoDialogTemplate
				isError={true}
				message={e.message}
			/>)
		});
	}

	if (teamInfoRes.loading) {
		return(
			<div>
				Loading...
			</div>
		)
	}

	return(
		<div className={classes.root}>
			<div className={classes.header}>
				<GlueButton
					variant='icon'
					onPointerDown={() => navigate(-1)}
				>
					<ArrowBack />
				</GlueButton>
				<h2>{teamInfoRes.data?.teamInfo.name}</h2>
				<h2>{teamInfoRes.data?.teamInfo.teamId}</h2>
			</div>
			<div className={classes.content}>
				<div className={classes.overview}>
					<div className={classes.listing}>
						<h3>Team name:</h3>
						<WebInputfield maxLength={100} placeholder={teamInfoRes.data?.teamInfo.name} width={'350px'} value={teamName} onChange={e => setTeamName(e)} />
						<h3>Team tier:</h3>
						<GlueDropdown width={'350px'} items={teamTiers} onChange={(id, checked) => checked && setTeamTier(id)} defaultValue={teamInfoRes.data.teamInfo.teamTier ?? ' '} />
						<div className={classes.buttons}>
							<GlueButton color="primary" onPointerDown={() => updateTeam()}>Update Team</GlueButton>
							<GlueButton color="destructive" onPointerDown={() => deleteCheck()}>Delete Team</GlueButton>
						</div>
					</div>
				</div>
				<div className={classes.section}>
					<div className={classes.subHeader}>
						<WebInputfield placeholder={'Search for members...'} onChange={setMemberSearchWord} />
						<GlueButton
							onPointerDown={() => addDialog(
								<MemberAddDialog
									web
									team={teamInfoRes.data?.teamInfo}
									orgId={currentOrg.data?.getOrgIdOfTeam}
									refresh={() => teamInfoRes.refetch()}
									teamAdmin={false}
								/>)}
						>Add Member</GlueButton>
					</div>
					<div className={classes.listing}>
						{members.length > 0 && members.map((member, i) => (
							<h3 onPointerDown={() => setMemberDetailsOpen(member.email)} key={i}>{member.email}</h3>
						))}
					</div>
				</div>
				<div className={classes.section}>
					<div className={classes.subHeader}>
						<WebInputfield placeholder={'Search for rooms...'} onChange={setRoomSearchWord} />
						{availableAssets.length > 0 && <GlueButton
							onPointerDown={() => addDialog(<AddSpaceDialog 
								currentTeamId={teamId}
								refreshRooms={() => myRoomsRes.refetch()}
								availableAssets={availableAssets} />)}
						>Add Room</GlueButton>}
					</div>
					<div className={classes.listing}>
						{myRoomsRes.data && rooms.map((room, i) => (
							<SpaceTile
								key={i}
								isAdmin={true}
								teamId={teamId}
								roomId={room.roomId}
								asset={room.assetName}
								web={true}
							/>
						))}
					</div>
				</div>
			</div>

			<MemberDetailsDialog
				teamId={teamId}
				userId={memberDetailsOpen}
				onClose={() => setMemberDetailsOpen(null)}
			/>
		</div>
	)
}

export default TeamView;