import React, { SyntheticEvent, useEffect, useState } from 'react';
import { makeStyles, Snackbar, SnackbarCloseReason, Tooltip } from '@material-ui/core';
import { useLocation, useNavigate } from 'react-router-dom';
import AppHeader from '../common/app-header';
import NoteEditor from './note-editor';

import { useQuery, useApolloClient } from '@apollo/react-hooks';

import queries from '../../graphql/queries';

import SpeechToTextButton from '../common/speech-to-text-button';
import GlueButton from '../common/glue-button';

import UndoIcon from '@material-ui/icons/Undo';
import RedoIcon from '@material-ui/icons/Redo';
import postVuplexMessage from '../../service/message-vuplex';

import { initializeEditor, deinitializeEditor, editorInitialized, reloadEditor, canUndo, canRedo, undo, redo, setNoteText, resetNote, commitDelayedText, getCurrentNote, hasDelayedText } from '../../service/note-editor';
import { useUserSettings } from '../../service/user-settings';
import { useNote } from './note-hooks';
import { Note } from './note-types';

const useStyles = makeStyles((theme) => ({
	root: {
		flex: '1 1 flex',

		display: 'flex',
		height: '100%',
		alignItems: 'center',
		justifyContent: 'center',
	},

	sbAnchorElement: {  //For Snackbar position fine tuning
		top: '90px'
	}
}));

type NoteLocationState = {
	noteState: Note
};

const NoteApp = () =>
{
	const classes = useStyles();
	const location = useLocation();
	const apollo = useApolloClient();
	const navigate = useNavigate();
	const instanceId = window.sessionStorage.getItem('instance-id');

	const state = (location && location.state) ? (location.state as NoteLocationState).noteState : undefined;
	const sttRes = useQuery(queries.speechRecognition, {
		variables: {
			instanceId: instanceId
		}
	});

	const { setUserSettings } = useUserSettings();

	const note = useNote();

	const [noteCreated, setNoteCreated] = useState(true)

	const reload = () =>
	{
		navigate('/app/note');
	};

	const releaseEditLock = () =>
	{
		postVuplexMessage("Release note", {
			instanceId: state ? state.instanceId : 0
		});
		navigate('/');
	}

	const leaveNoteApp = () =>
	{
		if (editorInitialized())
			deinitializeEditor();
		
		releaseEditLock();
	}

	useEffect(() => {
		apollo.writeQuery({
			query: queries.noteEdited,
			data: { noteEdited: true }
		});

		return () => {
			apollo.writeQuery({
				query: queries.noteEdited,
				data: { noteEdited: false }
			});
		};
	});

	useEffect(() => {
		if (editorInitialized())
			reloadEditor(state);
	}, [state]);

	const convertAlignmentToClient = (hor: string, ver: string) =>
	{
		let alignmentForClient = "";

		if (hor === "left")
		{
			if (ver === "flex-start")
			{
				alignmentForClient = "TopLeft";
			}
			if (ver === "center")
			{
				alignmentForClient = "Left";
			}
			if (ver === "flex-end")
			{
				alignmentForClient = "BottomLeft";
			}
		}

		if (hor === "center")
		{
			if (ver === "flex-start")
			{
				alignmentForClient = "Top";
			}
			if (ver === "center")
			{
				alignmentForClient = "Center";
			}
			if (ver === "flex-end")
			{
				alignmentForClient = "Bottom";
			}
		}

		if (hor === "right")
		{
			if (ver === "flex-start")
			{
				alignmentForClient = "TopRight";
			}
			if (ver === "center")
			{
				alignmentForClient = "Right";
			}
			if (ver === "flex-end")
			{
				alignmentForClient = "BottomRight";
			}
		}
		
		return alignmentForClient;
	}
 
	const placeNote = () =>
	{
		if (!note) {
			return;
		}
		
		if (state && state.instanceId !== 0)
		{
			setNoteCreated(false)
		}
		else 
		{
			setNoteCreated(true)
		}

		let textContent = note.text;
		// If there's delayed text, commit it first
		if (hasDelayedText())
		{
			commitDelayedText();
			textContent = getCurrentNote()?.text ?? ''; // This should never be null but typescript doesn't know that
		}

		postVuplexMessage("Place note", {
			content: textContent,
			alignment: convertAlignmentToClient(note.horizontalTextAlignment, note.verticalTextAlignment),
			fontColor: note.fontColor,
			font: note.fontFamily,
			fontStyle: note.fontWeight + "|" + note.fontStyle,
			width: note.width / 100,  // Conversion from centimeters to meters
			height: note.height / 100,
			fontSize: note.fontSize,
			bgColor: note.noteColor,
			instanceId: note.instanceId,
		});

		reloadEditor();
		reload();
	}

	const getCreateButtonText = () =>
	{
		if (state && state.instanceId !== 0)
		{
			return "Save";
		}
		else 
			return "Create";
	}

	const firstHeaderButtons = (
		<>
			<GlueButton
				variant='icon'
				disabled={!canUndo()}
				onPointerDown={() => undo()}
			>
				<UndoIcon/>
			</GlueButton>

			<GlueButton
				variant='icon'
				disabled={!canRedo()}
				onPointerDown={() => redo()}
			>
				<RedoIcon/>
			</GlueButton>

			<SpeechToTextButton
				key='speechButton'
				sinkId={"NoteApp"}
				setFocus={() => null}	
			/>
		</>
	)

	const createAudiomessage = "Tools/Tablet/OpenTablet/Press";
	const [sbOpen, setSbOpen] = useState(false);

	const handleClick = () =>
	{
		setSbOpen(true);
	}

	const handleClose = (event: SyntheticEvent, reason: SnackbarCloseReason) => 
	{
		if (reason === 'clickaway') {
		return;
		}
		setSbOpen(false);
	};

	const secondHeaderButtons = (
		<>
			<Tooltip 
				title="Reset settings to their default values."
				arrow={true}
				enterDelay={300}
			>
				<div>
					<GlueButton onPointerDown={() => resetNote()}>
						Reset
					</GlueButton>
				</div>
			</Tooltip>

			<Tooltip 
				title="Clear note content."
				arrow={true}
				enterDelay={300}
			>
				<div>
					<GlueButton onPointerDown={() => setNoteText('')}>
						Clear
					</GlueButton>
				</div>
			</Tooltip>

			<GlueButton
				onPointerDown={()=>{placeNote(); handleClick()}}
				color={'primary'}
				width={'150px'}
				uiAudioMessage = {createAudiomessage}
				disabled={sttRes.data?.speechRecognition.running}
			>
				{getCreateButtonText()}
			</GlueButton>
			<Snackbar 
				classes={{anchorOriginTopCenter:classes.sbAnchorElement}}
				anchorOrigin={{vertical: 'top', horizontal: 'center'}}
				open={sbOpen}
				autoHideDuration={6000}
				onClose={handleClose}
				message={noteCreated ? "Note created. Start typing to create a new one." : "Note updated. Start typing to create a new one."}
			/>
		</>
	)

	// It should really be possible to undo/redo saved colors, as in my avatar.
	// This just immediately saves them to mySettings
	// Because of this, there's a lot of UI lag as well
	const handleColorSave = (colors: string[]) => {
		setUserSettings({ savedNoteColors: colors });
	}

	if (!editorInitialized())
	{
		initializeEditor(state);
	}

	return(
		<div style={{display: 'contents'}}>
			<AppHeader
				children={firstHeaderButtons}
				secondChildren={secondHeaderButtons}
				onBackButtonAction={leaveNoteApp}
				title={'Notes'}
				selected={false}
			/>
			<NoteEditor 
				sinkId={"NoteApp"} 
				onSaveColors={handleColorSave}
			/>
		</div>
	);
}

export default NoteApp;