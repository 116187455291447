import { ApolloClient } from '@apollo/client';

import mutations from '../../graphql/mutations';
import postVuplexMessage from '../../service/message-vuplex';
import { DocumentType } from '../document';

export type DocumentViewProps = {
    apollo: ApolloClient<unknown>,
    inventoryItemId: string,
    itemUrl: string,
    itemType: string,
    sharing: boolean,
}

const openDocumentView = async (props: DocumentViewProps) => {

    const { v4 } = require("uuid");
    const objectId = location.protocol === 'https' ? self.crypto.randomUUID() : v4();
    const sessionId = location.protocol === 'https' ? self.crypto.randomUUID() : v4();

    const docType = props.itemType === 'application/pdf' ? DocumentType.PDF : DocumentType.Image;

    console.log('Open document view, inventoryItemId', props.inventoryItemId, 'itemUrl', props.itemUrl, 'sharing', props.sharing, ', objectId', objectId, 'sessionId', sessionId, 'type', docType);

    const contentUrl = props.itemUrl;
    const contentType = props.itemType;
    await props.apollo.mutate({
        mutation: mutations.updateSessionObjectInfo,
        variables: {
            sessionId,
            objectId,
            contentUrl,
            contentType
        },
    });

    postVuplexMessage('Document.OpenView', {
        inventoryItemId: props.inventoryItemId,
        objectId: objectId,
        sessionId: sessionId,
        sharing: props.sharing,
        type: docType
    });
}

export default openDocumentView;
