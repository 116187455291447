export type Type = {
    width: string
    circleRadius: string
}

const common: Type =
{
	width: '432px',
    circleRadius: '100px'
};

export const web: Type =
{
	...common
};

export const tablet: Type =
{
	...common,

    width: '560px',
    circleRadius: '120px'
};