import { CSSProperties } from "@material-ui/core/styles/withStyles";

export type Type = CSSProperties;

const common: Type = {
	minWidth: '256px',
    height: '64px',
};

export const web: Type = {
    ...common,
    
	minWidth: '200px',
    height: '36px',
};

export const tablet: Type = {
    ...common
};