import React, { useReducer, useState } from 'react';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core';

import queries from "../../graphql/queries";
import GlueDropdown from '../common/glue-dropdown';
import GlueButton from "../common/glue-button";
import { COMMON_ASSET_VERSION_POINTER_NAMES, setVersionPointer } from './space-asset-common';
import CommonTextValuePicker from '../common/common-text-value-picker';
import GlueInputfield from '../common/glue-inputfield';
import { usePromptDialogContext } from '../../util/prompt-dialog-context';
import InfoDialogTemplate from '../common/info-dialog-template';


const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		gap: '12px'
	}
}));

const SetAssetVersionPointer = (props) =>
{
	const classes = useStyles();
	const apollo = useApolloClient();

	const { addDialog } = usePromptDialogContext();

	const [selectedPointerName, setSelectedPointerName] = useState('');

	const resetFormState = () => ({
		name: '',
		version: '',
		serverComponentsImageTagOverride: ''
	});
	const [formState, dispatchFormState] = useReducer(
		(state, action) =>
		{
			if (!action)
				return resetFormState();

			return { ...state, ...action };
		},
		null,
		resetFormState
	);

	const backendInfoRes = useQuery(queries.backendInfo);

	const selectPointer = (assetInfo, pointerName) =>
	{
		const existingPointer = assetInfo.versionPointers?.find(
			(pointer) => pointer.name === pointerName
		) ?? {};
		
		dispatchFormState(existingPointer);

		if (existingPointer)
			setSelectedPointerName(pointerName);
		else
			setSelectedPointerName('');
	};

	const assetInfoRes = useQuery(queries.assetInfo, {
		variables: {
			assetId: props.assetId
		},
		skip: !props.assetId,
		fetchPolicy: "network-only",
		onCompleted: (data) => {
			selectPointer(data.assetInfo, selectedPointerName);
		}
	});

	if (!props.assetId || !assetInfoRes.data || !backendInfoRes.data)
	{
		return null;
	}

	if (!Array.isArray(assetInfoRes.data.assetInfo.versions))
	{
		return (
			<div>The asset does not have any versions.</div>
		);
	}

	const pointersDropdownItems = [
		{ id: '', name: "Create new" },
	];

	if (Array.isArray(assetInfoRes.data.assetInfo.versionPointers))
	{
		pointersDropdownItems.push(
			...assetInfoRes.data.assetInfo.versionPointers.map(
				(item) => ({ id: item.name, name: item.name + " -> " + item.version })
			)
		);
	}

	const versionsDropdownItems = [
		{ id: '', name: "None" },
		...assetInfoRes.data.assetInfo.versions.map(
			(item) => ({ id: item.version, name: String(item.version) })
		)
	];

	const isCreatingNew = !selectedPointerName;

	const savePointer = async () =>
	{
		const name = isCreatingNew ? formState.name : selectedPointerName;

		const saveOk = await setVersionPointer(
			apollo,
			props.assetId,
			name,
			formState.version,
			formState.serverComponentsImageTagOverride
		);

		if (saveOk)
			addDialog(<InfoDialogTemplate
				header={"Pointer saved"}
				message={"Note that changes to all relevant Spaces may take some time to apply."}
			/>);
		else
			addDialog(<InfoDialogTemplate
				isError={true}
				message={"Saving pointer failed"}
			/>);

		assetInfoRes.refetch();
	};

	return (
		<div className={classes.root}>
			<GlueDropdown
				defaultValue={''}
				onChange={(id, checked) => checked && selectPointer(assetInfoRes.data.assetInfo, id)}
				items={pointersDropdownItems}
			/>

			{isCreatingNew && (<>
				<div>Pointer name:</div>
				<CommonTextValuePicker
					value={formState.name}
					onChange={(value) => dispatchFormState({ name: value })}
					placeholder={"Pointer name"}
					options={COMMON_ASSET_VERSION_POINTER_NAMES}
				/>
			</>)}

			<div>Point at version:</div>
			<GlueDropdown
				defaultValue={''}
				onChange={(id, checked) => checked && dispatchFormState({ version: id })}
				items={versionsDropdownItems}
			/>

			<div>Server Components image tag override:</div>
			<GlueInputfield
				simpleInput
				width={'auto'}
				value={formState.serverComponentsImageTagOverride ?? ''}
				onChange={(value) => dispatchFormState({ serverComponentsImageTagOverride: value })}
			/>

			<GlueButton onPointerDown={savePointer}>Save pointer</GlueButton>
		</div>
	);
};

export default SetAssetVersionPointer;
