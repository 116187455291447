import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

import ViewHeader from './view-header';
import GlueButton from '../common/glue-button';
import postVuplexMessage from '../../service/message-vuplex';

import { commitAvatarName, revertAvatarName, clearAvatarHistory, revertAvatarAll } from '../../service/avatar-configurator';
import { useAvatar, useAvatarDirty, useNickname } from '../avatarconfigurator/avatar-hooks';
import { usePromptDialogContext } from '../../util/prompt-dialog-context';
import InfoDialogTemplate from '../common/info-dialog-template';
import TabRow from '../common/tab-row';

const useStyles = makeStyles(theme => ({
	buttons: {
		display: 'flex',
		gap: '24px',
		justifyContent: 'flex-end'
	},
}), { name: 'MuiGlueAvatarViewHeader' });

const AvatarViewHeader = (props) => {
	const classes = useStyles();
	const navigate = useNavigate();
	
	const avatar = useAvatar();
	const [nickname] = useNickname();
	const { nicknameDirty, avatarDirty } = useAvatarDirty();
	const dirty = (!!avatarDirty || !!nicknameDirty);

	const { addDialog } = usePromptDialogContext();
	
	const revertaudiomessage = "Apps/Avatar/Revert/Press"; 
	const reverthoveraudiomessage = "Apps/Avatar/Revert/HL";
	const saveaudiomessage = "Apps/Avatar/Save/Press"; 
	const savehoveraudiomessage = "Apps/Avatar/Save/HL";
	
	const save = (exit) =>
	{
		props.showConfirm();

		const settingsToUpdate = {};
		
		if (nicknameDirty)
		{
			settingsToUpdate.nickname = nickname;
			postVuplexMessage("Set nickname", { value: nickname });
		}

		if (avatarDirty)
		{
			settingsToUpdate.avatar = avatar.avatar;
			postVuplexMessage("Set avatar", avatar.avatar?.config);
			postVuplexMessage("Respawn avatar", null);
		}
	
		props.updateSettings(settingsToUpdate);
		clearAvatarHistory();
		commitAvatarName();
	
		if (exit === true)
		{
			props.leave();
		}
	}
	
	const revert = (exit) =>
	{
		console.log("Avatar changes reverted!");
	
		// Revert to first point in history
		revertAvatarAll();
		revertAvatarName();
	
		if (exit === true)
		{
			props.leaveImmediate();
		}
	}
	
	const tabInfosTablet = [
		{ path: '/view/avatarconfigurator/overview', name: "Overview", audiomessage: "Apps/Avatar/Overview/Press", hoveraudiomessage: "Apps/Avatar/Overview/HL" },
		{ path: '/view/avatarconfigurator/appearance', name: "Appearance", audiomessage: "Apps/Avatar/Styles/Press", hoveraudiomessage: "Apps/Avatar/Styles/HL" },
		{ path: '/view/avatarconfigurator/clothing', name: "Clothing", audiomessage: "Apps/Avatar/Colors/Press", hoveraudiomessage: "Apps/Avatar/Colors/HL" },
	];

	const tabInfosWeb = [
		{ path: '/my-avatar/overview', name: "Overview"},
		{ path: '/my-avatar/appearance', name: "Appearance"},
		{ path: '/my-avatar/clothing', name: "Clothing"},
	];

	const tabInfos = props.web ? tabInfosWeb : tabInfosTablet;
	
	const onClickTab = (tabName) =>
	{	
		console.log("Changing tab to " + tabName);
		postVuplexMessage("Set avatar tab", tabName);
		props.setCurrentTab(tabName);
	}

	const showSaveDialog = () => {
		addDialog(<InfoDialogTemplate
			header={'Do you want to save before exiting?'}
			message={'You have made unsaved changes to your Avatar, which will be lost if you exit now.'}
			callbacks={[
				{ callback: exitWithoutSaving, label: 'Just exit' },
				{ callback: saveAndExit, color: "primary", label: 'Save and exit' }
			]}
		/>);
	}
	
	const openSaveDialog = () =>
	{
		postVuplexMessage("Open avatar save dialog", null);
		showSaveDialog();
	}
	
	const saveAndExit = () =>
	{
		save(true); 
	}
	
	const exitWithoutSaving = () =>
	{
		revert(true);
	}
	
	const onBackButtonAction = () =>
	{
		if (dirty && !props.web)
		{
			openSaveDialog();
		}
		else
		{
			navigate('/');
		}
	};
	
	return (
		<>
			<ViewHeader title={"My Avatar"} onClose={onBackButtonAction}
				rightContent={<div className={classes.buttons}>
					<GlueButton 
						onPointerDown={revert}
						uiAudioMessage = {revertaudiomessage}
						uiHoverAudioMessage = {reverthoveraudiomessage}
						disabled={!dirty}>
						Revert
					</GlueButton>
					<GlueButton 
						color="primary"
						onPointerDown={save}
						uiAudioMessage = {saveaudiomessage}
						uiHoverAudioMessage = {savehoveraudiomessage}
						disabled={!dirty}>
						Save
					</GlueButton>
				</div>}
				centerContent={<TabRow tabInfos={tabInfos} onClickTab={onClickTab}/>}
			/>

			{/** 
			 * React Router DOM v6 dropped support for the <Prompt> component. They have said that they are going to add it back in some later v6.x version.
			 * For now the save prompt is thus out of order.
			 * 
			{props.web && (
				<Prompt
					when={!!dirty}
					message={(location) => {
						return location.pathname.startsWith("/my-avatar") ? (
							true
						) : (
							"You have made unsaved changes to your Avatar, which will be lost if you exit now. Are you sure you want to leave?"
						)
					}}
				/>
			)}
			*/}
		</>
	)
};

export default AvatarViewHeader;