import EditorHistory from './editor-history';
import AvatarFeatures from '../component/avatarconfigurator/avatarfeatures';
import DefaultColors from '../component/avatarconfigurator/DefaultColors.json'
import DefaultAvatars from '../component/avatarconfigurator/default-avatars';
import { Avatar, AssetDescriptor, ColorOverride, SavedColors, Asset, Color, AvatarDirtiness, OnAvatarChangedEvent, OnNameChangedEvent } from '../component/avatarconfigurator/avatar-types';

let initialized = false;
let nicknameOld = "";
let nickname = "";
let editHistory: EditorHistory<Avatar> | undefined = undefined;

export const configuratorInitialized = () =>
{
	return initialized;
}

export const getAvatarName = () =>
{
	return nickname;
}

export const setAvatarName = (name: string) =>
{
	nickname = name;

	dispatchOnNameChangedEvent({ nickname: name });
}

export const commitAvatarName = () => 
{
	nicknameOld = nickname;
}

export const revertAvatarName = () =>
{
	nickname = nicknameOld;

	dispatchOnNameChangedEvent({ nickname: nicknameOld });
}

const dispatchOnAvatarChangedEvent = (data: OnAvatarChangedEvent) =>
{
	const event = new CustomEvent('onAvatarChanged', { detail: data });
	window.dispatchEvent(event);
}

const dispatchOnNameChangedEvent = (data: OnNameChangedEvent) =>
{
	const event = new CustomEvent('onAvatarNameChanged', { detail: data });
	window.dispatchEvent(event);
}

const findAssetType = (name: string) : string =>
{
	for (const type in AvatarFeatures)
	{
		const asset = AvatarFeatures[type].Assets.find(a => a.Prefab && a.Prefab === name);
		if (asset)
		{
			return type;
		}
	}
	return "None";
}

export const pickRandomDefaultAvatar = () : Avatar =>
{
	const count = DefaultAvatars.length;
	const randomIndex = Math.floor(Math.random() * count);
	return { 
		config: DefaultAvatars[randomIndex],
		savedColors: []
	};
}

export const initializeConfigurator = (avatar: Avatar, name: string) =>
{
	if (initialized === true)
		return;
	
	console.log("Initializing avatar configurator!");

	const initialState = {...avatar};

	// Handle missing saved colors
	if (!initialState.savedColors) {
		// Import old edited colors as saved colors
		if (initialState.colorOverrides) {
			const newSavedColors: string[] = [];
			initialState.colorOverrides.ColorClasses.forEach(cc => {
				cc.Overrides.forEach((override: ColorOverride) => newSavedColors.push(override.Override));
			});
			initialState.savedColors = newSavedColors;
		}
		else {
			initialState.savedColors = [];
		}
	}
	else
	{
		if (!Array.isArray(initialState.savedColors))
		{
			const oldSavedColors: SavedColors = initialState.savedColors;
			const newSavedColors: string[] = [];
			for (const cc in initialState.savedColors)
			{
				oldSavedColors[cc].forEach(color => newSavedColors.push(color));
			}
			initialState.savedColors = newSavedColors;
		}
	}

	// Remove any "None" assets that might exist in older configs
	const filteredAssets = initialState.config.Assets.filter(asset => !asset.AssetName || asset.AssetName.toUpperCase() !== "None");
	initialState.config.Assets = filteredAssets;

	// Fill in asset type information since older configs don't have it
	if (initialState.config && initialState.config.AssetTypesChecked !== true)
	{
		initialState.config.Assets.forEach(asset => {
			if (!asset.Type)
			{
				asset.Type = findAssetType(asset.AssetName);
			}
		});

		initialState.config.AssetTypesChecked = true;
	}

	// Get correct displays name for colors
	// The things we do for grammatical correctness...
	initialState.config.Colors.forEach(color => {
		initialState.config.Assets.forEach(a => {
			if (!a.Type)
			{
				return;
			}

			const asset = AvatarFeatures[a.Type]?.Assets?.find(f => f.Prefab === a.AssetName);

			if (asset && asset.ColorOptions)
			{
				const protoColor = asset.ColorOptions.find(c => c.Id === color.Id);
				if (protoColor)
				{
					color.DisplayName = protoColor.DisplayName;
				}
			}
		});
		// Also what idiot decided to make "Base" its own property? (It was me)
		const baseAsset = AvatarFeatures["Base"].Assets.find(f => f.Prefab === initialState.config.Base.AssetName);
		if (baseAsset && baseAsset.ColorOptions)
		{
			const protoColor = baseAsset.ColorOptions.find(c => c.Id === color.Id);
			if (protoColor)
			{
				color.DisplayName = protoColor.DisplayName;
			}
		}
	});

	editHistory = new EditorHistory(initialState);
	nicknameOld = name;
	nickname = name;
	initialized = true;

	const event : OnAvatarChangedEvent = {
		avatar: getCurrentAvatar(),
		respawn: true
	}

	dispatchOnAvatarChangedEvent(event);
}

export const deinitializeConfigurator = () =>
{
	console.log("Deinitializing avatar configurator!");

	initialized = false;
	nicknameOld = "";
	nickname = "";
	editHistory = undefined;
}

export const avatarIsDirty = (): AvatarDirtiness =>
{
	if (!editHistory) {
		throw new Error('Avatar configurator has not been initialized');
	}
	const dirtiness : AvatarDirtiness = { 
		nicknameDirty: nickname !== nicknameOld, 
		avatarDirty: editHistory.dirty(),
	};

	return dirtiness;
}

export const getCurrentAvatar = () : Avatar =>
{
	if (!editHistory) {
		throw new Error('Avatar configurator has not been initialized');
	}
	return editHistory.currentState();
}

export const getCurrentIndex = () =>
{
	if (!editHistory) {
		throw new Error('Avatar configurator has not been initialized');
	}
	return editHistory.length() - 1;
}

export const pushHistory = (state: Avatar) =>
{
	if (!editHistory) {
		throw new Error('Avatar configurator has not been initialized');
	}
	editHistory.pushState(state);
}

export const revertAvatarHistory = (pointInHistory: number) =>
{
	if (!editHistory) {
		throw new Error('Avatar configurator has not been initialized');
	}
	editHistory.revertToPoint(pointInHistory);

	dispatchOnAvatarChangedEvent({
		avatar: getCurrentAvatar(),
		respawn: true
	});
}

export const revertAvatarAll = () =>
{
	if (!editHistory) {
		throw new Error('Avatar configurator has not been initialized');
	}
	editHistory.revertAll();

	dispatchOnAvatarChangedEvent({
		avatar: getCurrentAvatar(),
		respawn: true
	});
}

export const clearAvatarHistory = () =>
{
	if (!editHistory) {
		throw new Error('Avatar configurator has not been initialized');
	}
	editHistory.clear();
	nicknameOld = nickname;

	dispatchOnAvatarChangedEvent({
		avatar: getCurrentAvatar(),
		respawn: false
	});
	dispatchOnNameChangedEvent({
		nickname: nickname
	});
}

export const setAvatarColor = (color: Color) =>
{
	const newState = getCurrentAvatar();

	const existingIndex = newState.config.Colors.findIndex(c => c.Id === color.Id);
	if (existingIndex !== -1)
	{
		newState.config.Colors[existingIndex] = color;
	}
	else 
	{
		newState.config.Colors.push(color);
	}

	pushHistory(newState);
	
	dispatchOnAvatarChangedEvent({
		avatar: getCurrentAvatar(),
		colorMutation: { Id: color.Id, ColorValue: color.ColorValue },
		respawn: false
	});
}

export const setAvatarSavedColors = (colors: string[]) =>
{
	const newState = getCurrentAvatar();

	newState.savedColors = colors;

	pushHistory(newState);

	dispatchOnAvatarChangedEvent({
		avatar: getCurrentAvatar(),
		respawn: false
	});
}

export const findMostSuitableVariant = (assetInfo: AssetDescriptor, variantOverrides: string) => {
	
	const disableTag = `disable_${assetInfo?.Type}`;
	if (variantOverrides && variantOverrides.includes(disableTag))
	{
		return "__Disabled";
	}
	
	let variantName = null;
	if (assetInfo.Variants && assetInfo.Variants.length !== 0)
	{
		let mostSuitableIndex = -1;
		let mostSuitableTagCount = 0;
		for (let i = 0; i < assetInfo.Variants.length; i++)
		{
			let matchingTagCount = 0;
			const tags : string[] = assetInfo.Variants[i].Tags.split(',');
			tags.forEach(tag => {
				if (variantOverrides && variantOverrides.includes(tag))
				{
					matchingTagCount++;
				}
			});

			if (matchingTagCount > mostSuitableTagCount)
			{
				mostSuitableIndex = i;
				mostSuitableTagCount = matchingTagCount;
			}
		}

		if (mostSuitableIndex !== -1)
			variantName = assetInfo.Variants[mostSuitableIndex].Name;
	}

	return variantName;
}

const gatherVariantOverrides = (assets: Asset[], disabledTypes?: string[]) => {
	const overrides: string[] = [];
	assets.forEach(asset => {
		const disabled = disabledTypes && disabledTypes.includes(asset.Type);
		
		const assetInfo = AvatarFeatures[asset.Type]?.Assets?.find(info => info.Prefab === asset.AssetName);
		if (assetInfo?.VariantOverrides)
		{
			const assetVariantOverrides = assetInfo.VariantOverrides.split(',');
			assetVariantOverrides.forEach(str => {
				// Split to find out if there's a special prefix
				const split = str.split(':');
				const variantOverride = split[split.length - 1];
				let prefixes: string[] = [];
				if (split.length >= 2)
				{
					prefixes = split.slice(0, -1);
				}

				if (disabled && !prefixes.includes("__BypassDisable"))
					return;

				if (!overrides.includes(variantOverride))
				{
					overrides.push(variantOverride);
				}
			});
		}
	});

	return overrides;
}

const getMissingTypes = (assets: Asset[]) => {
	const supportedAssetTypes = Object.keys(AvatarFeatures);
	const missingTypes: string[] = [];
	supportedAssetTypes.forEach(type => {
		const assetWithType = assets.find(asset => asset.Type === type && asset.AssetName !== "None");
		if (!assetWithType && !missingTypes.includes(type))
		{
			missingTypes.push(type);
		}
	});

	return missingTypes;
}

const recalculateVariants = (assets: Asset[]) => {
	const variantOverrides = gatherVariantOverrides(assets);

	const disabledTypes: string[] = [];
	variantOverrides.forEach(variantOverride => {
		if (variantOverride.startsWith("disable_"))
		{
			const disabledType = variantOverride.substring(8);
			if (!disabledTypes.includes(disabledType))
			{
				disabledTypes.push(disabledType);
			}
		}
	})

	// Do a second pass ignoring disabled assets
	const finalOverrides = gatherVariantOverrides(assets, disabledTypes);

	// Add missing type overrides
	const missingTypes = getMissingTypes(assets);
	missingTypes.forEach(type => {
		const missingTag = `missing_${type}`;
		finalOverrides.push(missingTag);
	});

	return finalOverrides.join();
}

export const setAvatarFeature = (newFeature: AssetDescriptor) =>
{
	let respawn = false;
	let shapeMutation = undefined;
	let assetMutation = undefined;
	let colorMutation = undefined;

	const newState = getCurrentAvatar();

	if(newFeature.Type === "Shape")
	{
		if (!newFeature.ShapeJoints || !newFeature.BlendShapes || !newFeature.PresetName) {
			throw new Error('Attempting to assign invalid shape to avatar')
		}
		newState.config.ShapeJoints = newFeature.ShapeJoints;
		newState.config.BlendShapes = newFeature.BlendShapes;
		newState.config.PresetName = newFeature.PresetName;

		shapeMutation = { ShapeJoints: newFeature.ShapeJoints, BlendShapes: newFeature.BlendShapes };
		respawn = true;
	}
	else
	{
		const existingIndex = newState.config.Assets.findIndex(asset => {
			if (asset.Type)
			{
				return asset.Type === newFeature.Type;
			}
			else
			{
				const foundType = findAssetType(asset.AssetName);
				return foundType === newFeature.Type;
			}
		});

		if (existingIndex !== -1)
		{
			newState.config.Assets[existingIndex] = { Type: newFeature.Type, AssetName: newFeature.Prefab };
		}
		else newState.config.Assets.push({ Type: newFeature.Type, AssetName: newFeature.Prefab });

		// Check that all the associated colors are defined
		if (newFeature.ColorOptions)
		{
			newFeature.ColorOptions.forEach(option => {
				const foundColor = newState.config.Colors.find(color => color.Id === option.Id);
				// If color is not defined in the avatar, add it
				if (!foundColor)
				{
					
					let defaultIndex = 0;
					let defaultValue = "#000000";
					const colorClass = DefaultColors.ColorClasses.find(cc => cc.Id === option.Id);
					if (colorClass)
					{
						defaultIndex = option.DefaultIndex ? option.DefaultIndex : 0;
						defaultValue = colorClass.PresetColors[defaultIndex];
					}
					newState.config.Colors.push({
						Id: option.Id,
						ColorValue: defaultValue,
						DisplayName: option.DisplayName,
						PresetMode: "Preset",
						CurrentSelection: defaultIndex
					});

					colorMutation = { Id: option.Id, ColorValue: defaultValue };
				}
				else
				{
					// Make sure the display name is correct
					// This is a bit dumb though, why is the display name even stored in the avatar
					foundColor.DisplayName = option.DisplayName;
				}
			});
		}

		newState.config.VariantOverrides = recalculateVariants(newState.config.Assets);

		const variantName = findMostSuitableVariant(newFeature, newState.config.VariantOverrides);
		
		assetMutation = { Type: newFeature.Type, AssetName: newFeature.Prefab, VariantName: variantName ?? "None" };
		respawn = true; // The easiest way to get all the right variants for all assets is to just respawn the whole avatar
	}

	pushHistory(newState);

	const event: OnAvatarChangedEvent = {
		avatar: getCurrentAvatar(),
		respawn: respawn,
		colorMutation: colorMutation,
		assetMutation: assetMutation,
		shapeMutation: shapeMutation,
	};

	dispatchOnAvatarChangedEvent(event);
}