import { CSSProperties } from "@material-ui/core/styles/withStyles";

export type Type = {
	borderRadius: string
	rowHeight: string
	root: CSSProperties
};

const common =
{
	borderRadius: '44px',
	rowHeight: '64px',
};

export const web =
{
	...common,
	borderRadius: '26px',
	rowHeight: '36px',

	root: {
		width: '452px',
		filter: 'drop-shadow(0px 10px 10px rgba(0,0,0,0.5))',
	},
};

export const tablet =
{
	...common,

	root: {
		width: '680px',
	},
};
