import React from 'react';
import HeroIcon from './common/hero-icon';

import ClockIcon from '../icons/hero/Clock.png';

const ClockHeroIcon = (props) =>
{
	const hoveraudiomessage = "Menu/Clock/HL";
	const audiomessage = "Menu/Clock/Press";

	return (
		<HeroIcon
			name="Clock"
			route="/app/clock"
			icon={ClockIcon}
			disabled={props.disabled}
			uiAudioMessage={audiomessage}
			uiHoverAudioMessage={hoveraudiomessage} 
		/>
	);
};

export default ClockHeroIcon;