import queries from "../graphql/queries";
import { logFirebaseEvent } from './firebase/firebase';
import postVuplexMessage from "./message-vuplex";
import { getUserSettings, setUserSettings, useUserSettings } from "./user-settings";

const recentTeamsCacheCountMax = 20;

/**
 * @returns {Promise<Array<String>>} Array of team IDs or an empty array
 */
const getRecentTeams = async (apollo) =>
{
	const userSettings = await getUserSettings(apollo);
	const recentTeams = userSettings.recentTeams.length > 0 ? userSettings.recentTeams : JSON.parse(localStorage.getItem('recentTeams')); // Fall back to old local storage
	
	if (Array.isArray(recentTeams))
		return recentTeams;
	else
		return [];
};

const setRecentTeams = async (apollo, recentTeams) =>
{
	await setUserSettings(apollo, { recentTeams: recentTeams });
}

/**
 * Automatically set team selection based on persistent previously selected team info.
 * @param {*} apollo 
 */
export const restorePreviousTeam = async (apollo) =>
{
	let myTeamsResult = await apollo.query({
		query: queries.myTeams
	});

	if (myTeamsResult.data.myTeams.length === 0)
	{
		console.warn("User is not a member of any team.");
	}
	else
	{
		const recentTeams = await getRecentTeams(apollo);
		const recentTeamId = recentTeams.length > 0 ? recentTeams[0] : null;
		const recentIsValid = myTeamsResult.data.myTeams.some((team) => team.teamId === recentTeamId);

		const chosenTeamId = recentIsValid ? recentTeamId : myTeamsResult.data.myTeams[0].teamId;

		await setCurrentTeam(apollo, chosenTeamId);
	}
};

// Update settings with last selected teams
export const addToRecentTeamsCache = async (apollo, teamId) =>
{
	let recentTeams = await getRecentTeams(apollo);

	// Remove old duplicate
	recentTeams = recentTeams.filter((recentTeamId) => recentTeamId !== teamId);

	// Add the team as the first entry
	recentTeams.unshift(teamId);

	// Limit the total number of teams on the list to something sensible
	if (recentTeams.length > recentTeamsCacheCountMax)
		recentTeams.pop();

	await setRecentTeams(apollo, recentTeams);
};

export const setCurrentTeam = async (apollo, teamId) =>
{
	let orgId = null;
	await apollo.query({
		query: queries.getOrgIdOfTeam,
		variables: { teamId: teamId }
	})
	.then(res => {orgId = res.data.getOrgIdOfTeam})
	.catch(e => console.log('Parent organization for team not found'))

	apollo.writeQuery({
		query: queries.currentTeamId,
		data: { currentTeamId: teamId }
	});

	apollo.writeQuery({
		query: queries.currentOrgId,
		data: { currentOrgId: orgId ?? null }
	});

	if (teamId && orgId) {
		const orgInfo = await apollo.query({
			query: queries.getOrg,
			variables: { orgId: orgId }
		});
		if (orgInfo && !orgInfo.isTutorial) {
			await addToRecentTeamsCache(apollo, teamId);
		}
	}

	postVuplexMessage('Set analytics team and org info', { teamId: teamId, orgId: orgId });
	logFirebaseEvent("chooseTeam", { teamId: teamId });
};

// React hook version of above
export const useRecentTeams = () =>
{
	const { userSettings, setUserSettings, settingsLoading } = useUserSettings();

	let recentTeams = userSettings?.recentTeams ?? JSON.parse(localStorage.getItem('recentTeams')); // Fall back to old local storage
	if (!Array.isArray(recentTeams)) {
		recentTeams = [];
	}

	const setTeams = (newTeams) => {
		setUserSettings({ recentTeams: newTeams });
	}

	return {
		recentTeams: recentTeams,
		setRecentTeams: setTeams,
		loading: settingsLoading
	};
}
