import React from 'react';
import clsx from 'clsx';

import { makeStyles, Radio } from '@material-ui/core';

const themeVar = (theme, key) => theme.custom?.glueRadio?.[key] ?? null;

const useStyles = makeStyles((theme) => ({
	root: {
		margin: '0 5px',
		'&:hover': {
			//backgroundColor: 'transparent',
		},
	},
	icon: {
		borderRadius: '50%',
		width: themeVar(theme, 'width'),
		height: themeVar(theme, 'height'),
		boxShadow: `inset 0 0 0 ${themeVar(theme, 'outline')} #83898F`,
		backgroundColor: 'transparent',
		'$root.Mui-focusVisible &': {
			outline: `${themeVar(theme, 'outline')} auto rgba(19,124,189,.6)`,
			outlineOffset: 2,
		},
		'input:hover ~ &': {
			filter: 'brightness(1.5)'
		},
		'input:disabled ~ &': {
			boxShadow: `inset 0 0 0 ${themeVar(theme, 'outline')} #83898F`,
			opacity: '50%'
		},
	},
	checkedIcon: {
		backgroundColor: theme.palette.secondary.textActive,
		backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
		boxShadow: `inset 0 0 0 ${themeVar(theme, 'outline')} white`,
		'&:before': {
			display: 'block',
			width: themeVar(theme, 'width'),
			height: themeVar(theme, 'height'),
			content: '""',
		},
		'input:hover ~ &': {
			filter: 'brightness(1.5)'
		},
	},
}));


const GlueRadioButton = (props) => {
	const classes = useStyles(props);

	return (
		<Radio
			className={classes.root}
			color="default"
			checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
			icon={<span className={classes.icon} />}
			{...props}
		/>
	);
}

export default GlueRadioButton;