import React, { useRef, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { createAvatarView, 
	loadAvatar, 
	clearScene, 
	applyColorMutation, 
	applyShapeMutation, 
	applyAssetMutation, 
	setCameraTargetHeight,
	setCameraTargetZoom,
	deleteAvatarView, 
	disableRendering,
	enableRendering,
	AvatarView} from './avatarview';
import LoadingIndicator from '../common/loading-indicator';
import { OnAvatarChangedEvent } from './avatar-types';

type AvatarCanvasStyleProps = {
	loading: boolean
	width: string
	height: string
}

const useStyles = makeStyles((theme) => ({
	root: (props: AvatarCanvasStyleProps) => ({
		width: props.width,
		height: props.height,
		display: 'flex',
		margin: 'auto',
		background: `radial-gradient(circle closest-side at center, ${theme.palette.secondary.main}, ${theme.palette.background.default})`
	}),

	canvas: (props: AvatarCanvasStyleProps) => (props.loading ? {
		display: 'none'
	} : {}),

	loadingIndicator: {
		margin: 'auto'
	}
}), { name: 'MuiGlueAvatarCanvas' });

const useLoading = () => 
{
	const [ loading, setLoading ] = useState(false);

	const updateLoading = (e: Event) =>
	{
		setLoading((e as CustomEvent<boolean>).detail);
	}

	useEffect(() => {
		window.addEventListener('onAvatarViewLoadingChanged', updateLoading);
		return () => {
			window.removeEventListener('onAvatarViewLoadingChanged', updateLoading);
		}
	});

	return loading;
}

const AvatarCanvas = (props: {
	width: string
	height: string
	avatar: OnAvatarChangedEvent
	targetZoomLevel: number
	targetHeight: number
	id: string
}) =>
{
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const avatar = props.avatar;
	
	const [ avatarView, setAvatarView ] = useState<AvatarView | null>(null);
	const [ sceneInitialized, setSceneInitialized ] = useState(false);
	const loading = useLoading();

	const classes = useStyles({loading, width: props.width, height: props.height});

	useEffect(() => {
		const view = createAvatarView(canvasRef.current);

		if (view)
		{
			setAvatarView(view);
		}

		setSceneInitialized(false);

		return () => { 
			deleteAvatarView(view); 
		};
	}, [canvasRef]);

	useEffect(() => {
		if (!avatarView)
		{
			return;
		}

		if (!sceneInitialized && avatar.avatar.config)
		{
			clearScene(avatarView);
			loadAvatar(avatarView, avatar.avatar.config);
			setSceneInitialized(true);
		}

		if (avatar.shapeMutation)
		{
			applyShapeMutation(avatarView, avatar.shapeMutation);
		}
		if (avatar.colorMutation)
		{
			applyColorMutation(avatarView, avatar.colorMutation);
		}
		if (avatar.assetMutation)
		{
			applyAssetMutation(avatarView, avatar.assetMutation, avatar.avatar.config.Colors, avatar.avatar.config.ShapeJoints, avatar.avatar.config.BlendShapes);
		}
		if (avatar.respawn)
		{
			clearScene(avatarView);
			loadAvatar(avatarView, avatar.avatar.config);
		}

	}, [sceneInitialized, avatarView, avatar]);

	useEffect(() => {
		setCameraTargetZoom(avatarView, props.targetZoomLevel);
		enableRendering(avatarView, 120);
	},[avatarView, props.targetZoomLevel]);

	useEffect(() => {
		setCameraTargetHeight(avatarView, props.targetHeight);
		enableRendering(avatarView, 120);
	},[avatarView, props.targetHeight]);

	return (
		<div className={classes.root}>
			<canvas className={classes.canvas} ref={canvasRef} id={props.id} width={props.width} height={props.height} onPointerDown={() => enableRendering(avatarView)} onPointerUp={() => disableRendering(avatarView)}></canvas>
			{loading && (<div className={classes.loadingIndicator}><LoadingIndicator/></div>)}
		</div>
	);
}

export default AvatarCanvas;