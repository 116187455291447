import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LoadingIndicator from './common/loading-indicator';

const useStyles = makeStyles(theme => ({
	root: {
		overflow: 'hidden',
		height: '100%',

		display: 'flex',
		justifyContent: 'flex-start',
		flexDirection: 'column',
		alignItems: 'stretch',

		background: theme.palette.background.default,
	},

	loadingView: {
		background: '#000',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
	}
}), { name: 'MuiGlueTabletRoot' });


const TabletRoot = (props) => 
{
	const classes = useStyles();

	return (
		<div className={classes.root}>
			{ props.loading ? (
				<div className={classes.loadingView}>
					<LoadingIndicator />
				</div>
			) : (
				<>
					{props.children}
				</>
			)}
		</div>
	);
}

export default TabletRoot;