import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	root: {
		...theme.custom.editorPanel.root,
		backgroundColor: theme.palette.background.paper,
		padding: theme.glueSpacing('s'),
		borderRadius: theme.custom.editorPanel.borderRadius,
		height: 'fit-content'
	},

	title: {
		width: '100%',
		height: theme.custom.editorPanel.rowHeight,
		paddingLeft: theme.glueSpacing('l'),
		paddingRight: theme.glueSpacing('l'),
		textTransform: 'capitalize',
		display: 'flex',
		justifyContent: 'space-around',

		'& h2': {
			marginBottom: 'auto',
			marginTop: 'auto',
		},
	}
}), { name: "MuiGlueEditorPanel" });

const EditorPanel = (props) =>
{
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<div className={classes.title}>
				<h2>{props.title}</h2>
			</div>
			{props.children}
		</div>
	);
}

export default EditorPanel;