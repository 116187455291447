import { ApolloClient } from "@apollo/client"
import mutations from "../graphql/mutations"

export const changeTeamMemberRole = async (apollo: ApolloClient<unknown>, teamId: string, email: string, newRole: string) => {
	await apollo.mutate({
    mutation: mutations.userJoinTeam,
    variables: {
      teamId: teamId,
      memberId: email,
      role: newRole,
      isUpdate: true
    }
  });
}