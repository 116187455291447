import React from 'react';

import HeroIcon from './common/hero-icon';
import { useUserContext } from '../util/user-context';

const SpaceAssetManageHeroIcon = (props) =>
{
	const user = useUserContext();

	if (!user.administrator)
	{
		return null;
	}

	return (
		<HeroIcon
			name="Space Assets"
			route="/app/space-assets"
		/>
	);
};

export default SpaceAssetManageHeroIcon;
