import postVuplexMessage from "../service/message-vuplex";
import { ApolloClient } from "@apollo/client";
import queries from "../graphql/queries";
import { InventoryItem } from "../graphql/types-generated";

export const sendWebControlMsg = (msgName: string, args?: Record<string, unknown>) => {
	const instanceId = window.sessionStorage.getItem('instance-id');
	if (!instanceId) {
		console.error("Instance ID not defined.");
		return;
	}

	postVuplexMessage(`WebControl.${msgName}`, { instanceId: instanceId, ...args });
}

export const sendWebControlMsgToInstance = (msgName: string, instanceId: string, args?: Record<string, unknown>) => {
	if (!instanceId) {
		console.error("Instance ID not defined.");
		return;
	}

	postVuplexMessage(`WebControl.${msgName}`, { instanceId: instanceId, ...args });
}

export const setKeyboardActive = (state: boolean, apollo: ApolloClient<unknown>, delayInSeconds = 0.0) => {
	const uiRes = apollo.readQuery({
		query: queries.ui
	});

	if (uiRes.ui === 'web') {
		return;
	}

	const instanceId = window.sessionStorage.getItem('instance-id');
	if (!instanceId) {
		console.error("Instance ID not defined.");
		return;
	}

	sendWebControlMsg('SetKeyboardActive',
	{
		active: state,
		delayInSeconds: delayInSeconds
	});
}

export const urlIsBookmarked = async (url: string, apollo: ApolloClient<unknown>) => {
	const teamIdRes = apollo.readQuery({query: queries.currentTeamId});
	const teamId = teamIdRes.data.currentTeamId;
	
	const linksRes = await apollo.query({
		query: queries.teamInventoryItems,
		variables: {
			teamId: teamId,
			first: 0,
			offset: 0,
			typeFilter: 'link'
		}
	});
	const links: InventoryItem[] = linksRes.data.teamInventoryItems;
	return links.some(item => item.itemUrl === url);
}