import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core';

import queries from '../../graphql/queries';

import FileViewVideo, { isViewable as isViewableVideo } from './file-view-video';
import FileViewImage, { isViewable as isViewableImage, importFile as importImage } from './file-view-image';
import FileViewDocument, { isViewable as isViewableDocument } from './file-view-document';
import FileViewNotSupported from './file-view-not-supported';
import FileView3D, { isViewable as isViewable3dObject, importFile as import3dObject } from './file-view-3d';
import LoadingIndicator from './../common/loading-indicator';

const useStyles = makeStyles((theme) => ({
	root: {
		height: '962px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	}
}));

class RegisteredViewer {
	/**
	 * @param {*} component React component function
	 * @param {*} isViewable isViewable function of the component module
	 * @param {*} importFile import function of the component module
	 */
	constructor(component, isViewable, importFile)
	{
		this.isViewable = isViewable;
		this.component = component;
		this.importFile = importFile;
	}
}

// Add specialized file viewer components here.
const specializedViewers = [
		new RegisteredViewer(FileViewImage, isViewableImage, importImage),
		new RegisteredViewer(FileViewDocument, isViewableDocument, null),
		new RegisteredViewer(FileViewVideo, isViewableVideo, null),
		new RegisteredViewer(FileView3D, isViewable3dObject, import3dObject),
	//	new RegisteredViewer(FileViewDocument, isViewableDocument),
	//	new RegisteredViewer(FileViewVideo, isViewableVideo),
	
	// Do not include the generic viewer on this list as it would match everything.
	//new RegisteredViewer(FileViewGeneric, isViewableGeneric)
];

/**
 * Try to find a specialized viewer component for the given file.
 * @param {*} itemInfo
 * @returns {RegisteredViewer|null} Matching viewer info or null if nothing was found
 */
export const findSpecializedViewer = (itemInfo) =>
{
	for (let viewer of specializedViewers)
	{
		if (viewer.isViewable(itemInfo))
			return viewer;
	}

	return null;
};

// Takes a file ID as an URL param and figures out which viewer component
// should handle it. Renders what ever file viewer is apropriate for the
// given file. A generic viewer is used if nothing else if found.
const FileViewSwitcher = (props) =>
{
	const classes = useStyles();

	const id = props.id;

	const teamIdRes = useQuery(queries.currentTeamId);

	const itemRes = useQuery(
		queries.inventoryItemInfo,
		{
			skip: !id,
			variables: {
				inventoryItemId: id
			}
		}
	);

	const signInventoryItemGetRes = useQuery(
		queries.signInventoryItemGet,
		{
			skip: !id,
			variables: { inventoryItemId: id },
			fetchPolicy: 'cache-and-network'
		}
	);

	const viewer = itemRes.data ? findSpecializedViewer(itemRes.data.inventoryItemInfo) : null;

	if (!id)
	{
		return null;
	}

	if (teamIdRes.loading || itemRes.loading || signInventoryItemGetRes.loading)
	{
		return (
			<div className={classes.root}>
				<LoadingIndicator />
			</div>
		);
	}

	if (!viewer)
	{
		if (itemRes.error)
			console.log(itemRes.error);

		// No specialized viewer was found. Use the generic one.
		return (<FileViewNotSupported itemId={id} closePreview={props.closePreview} />);
	}

	return React.createElement(
		viewer.component,
		{
			teamId: teamIdRes.data.currentTeamId,
			itemId: id,
			closePreview: props.closePreview,
			itemInfo: itemRes.data.inventoryItemInfo,
			signedURLs: signInventoryItemGetRes.data.signInventoryItemGet
		}
	);
};

export default FileViewSwitcher;
