import React from 'react';
import HeroIcon from '../common/hero-icon';

import PresentationViewIcon from '../../icons/hero/Presenter.png';

const PresentationViewHeroIcon = () =>
{
	const hoveraudiomessage = "Menu/Note/HL";
	const audiomessage = "Menu/Note/Press";

	return (
		<HeroIcon
			name="Presentation View"
			icon={PresentationViewIcon}
			route="/app/presentation-view"
			uiAudioMessage={audiomessage}
			uiHoverAudioMessage={hoveraudiomessage}
		/>
	);
};

export default PresentationViewHeroIcon;