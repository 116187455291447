import { CSSProperties } from "@material-ui/core/styles/withStyles";

export type Type = ( active?: boolean, props?: CSSProperties ) => CSSProperties;

const common: Type = ( active?: boolean, props?: CSSProperties ) => 
({
	position: props?.position ?? 'relative',
	'&::after': {
		display: !active && 'none',
		pointerEvents: 'none',
		width: '100%',
		height: '100%',
		borderRadius: props?.borderRadius,
		borderTopLeftRadius: props?.borderTopLeftRadius,
		borderTopRightRadius: props?.borderTopRightRadius,
		borderBottomLeftRadius: props?.borderBottomLeftRadius,
		borderBottomRightRadius: props?.borderBottomRightRadius,
		position: 'absolute',
		top: 0,
		left: 0,
		outline: '2px solid #003259',
		outlineOffset: '-3px',
		border: '1px solid #2e9df2',
		content: '""'
	}
});

export const web: Type = ( active?: boolean, props?: CSSProperties ) => 
({
	...common(active, props)
});

export const tablet: Type = ( active?: boolean, props?: CSSProperties ) => 
({
	...common(active, props),

	'&::after': {
		display: !active && 'none',
		pointerEvents: 'none',
		width: '100%',
		height: '100%',
		borderRadius: props?.borderRadius,
		borderTopLeftRadius: props?.borderTopLeftRadius,
		borderTopRightRadius: props?.borderTopRightRadius,
		borderBottomLeftRadius: props?.borderBottomLeftRadius,
		borderBottomRightRadius: props?.borderBottomRightRadius,
		position: 'absolute',
		top: 0,
		left: 0,
		outline: '2px solid #003259',
		outlineOffset: '-4px',
		border: '2px solid #2e9df2',
		content: '""'
	}
});