import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import GlueDropdown from '../common/glue-dropdown';
import SwitchSelector from '../common/switch-selector';
import { useQuery } from '@apollo/react-hooks';
import queries from '../../graphql/queries';
import GlueButton from '../common/glue-button';

const useStyles = makeStyles(theme => ({
	root: {
		width: '886px',
		margin: 'auto',
	},

	title: {
		marginBottom: '36px',
		marginTop: '48px',
	},

	row: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: '64px',
		marginBottom: '12px',

		'& > p': {
			opacity: '70%',
		},
	},

	topMarginRow: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: '64px',
		marginTop: '48px',
		marginBottom: '12px',

		'& > p': {
			opacity: '70%',
		},
	},

	applyBtn: {
		height: '64px',
		marginTop: '48px',
		marginBottom: '12px',
		float: 'right'
	},
	

	switch: {
		marginRight: '-60px'
	}
}));



const Display = (props) =>
{
	const classes = useStyles(props);
	const settings = useQuery(queries.displaySettings);

	const inputaudiomessage = "Tools/Settings/Sound/Input/Press";
	const inputhoveraudiomessage = "Tools/Settings/Sound/Input/HL";
	const inputselaudiomessage = "Tools/Settings/Sound/Input/Select/Press";
	const inputselhoveraudiomessage = "Tools/Settings/Sound/Input/Select/HL";

	const [isFullscreen, setIsFullscreen] = useState(settings.data.displaySettings.fullScreen);

	const saveaudiomessage = "Apps/Avatar/Save/Press"; 
	const savehoveraudiomessage = "Apps/Avatar/Save/HL";

	const saveChanges = () =>
	{
		props.applyChanges();
	}

	const HandleResChange = (newValue) => 
	{
		props.setResolution(newValue);
	};
	
	const HandleRefreshRateChange = (newValue) => 
	{
		props.setRefreshRate(newValue);
	};

	const saveButton = 
		<GlueButton
			disabled={props.dirty}
			onPointerDown={saveChanges}
			uiAudioMessage = {saveaudiomessage}
			uiHoverAudioMessage = {savehoveraudiomessage} 
		>
			Apply
		</GlueButton>;

	const DisplayResolutions = () =>
	{
		const resList = settings.data.displaySettings.availableResolutions.map((res) => ({ id: res, name: res }));
		return(
			<div>
				<GlueDropdown
					width={'280px'}
					items={resList}
					defaultValue={settings.data.displaySettings.currentResolution}
					uiAudioMessage = {inputaudiomessage}
					uiHoverAudioMessage = {inputhoveraudiomessage} 
					uiSelAudioMessage = {inputselaudiomessage}
					uiSelHoverAudioMessage = {inputselhoveraudiomessage} 
					onChange={(id, checked) => checked && HandleResChange(id)}
				/>
			</div> 
		)
	}
	
	const DisplayRates = () =>
	{
		const rateList = settings.data.displaySettings.availableRefreshRates.map((rate) => ({ id: rate + "Hz", name: rate + "Hz" }));
		const currRate = settings.data.displaySettings.currentRefreshRate;
		return(
			<div>
				<GlueDropdown
					width={'280px'}
					items={rateList}
					defaultValue={currRate + "Hz"}
					uiAudioMessage = {inputaudiomessage}
					uiHoverAudioMessage = {inputhoveraudiomessage} 
					uiSelAudioMessage = {inputselaudiomessage}
					uiSelHoverAudioMessage = {inputselhoveraudiomessage} 
					onChange={(id, checked) => checked && HandleRefreshRateChange(id)}
				/>
			</div> 
		)
	}

	const ToggleFullscreen = (checked) => 
	{
		props.setFullscreen(checked);
		if (checked)
		{
			setIsFullscreen(true);
		}
		else
		{
			setIsFullscreen(false);
		}
	};

	const FullScreen = (
		<div className={classes.switch}>
			<SwitchSelector 
				checked={isFullscreen}
				onChange={ToggleFullscreen}
				offText={'Off'}
				onText={'On'}
			/>
		</div>
	);

	return (
		<div className={classes.root}>
			<div className={classes.topMarginRow}>
				<p>Full screen</p>
				{FullScreen}
			</div>
			<h1 className={classes.title}>Display Settings</h1>
			<div className={classes.row}>
				<p>Resolutions</p>
				<div>
					<DisplayResolutions />
				</div>
			</div>
			<div className={classes.row}>
				<p>Refresh Rate</p>
				<div>
					<DisplayRates />
				</div>
			</div>
			<div className={classes.applyBtn}>
				{saveButton}
			</div>
		</div>
	)
}

export default Display;
