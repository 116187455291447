import React from 'react';
import Grid from '@material-ui/core/Grid';

import { makeStyles } from '@material-ui/core/styles';

import { HeroIconContext } from './common/hero-icon';

import HeroIcon from './common/hero-icon';
import Handshake from '../icons/Gesture_Handshake.png';
import HighFive from '../icons/Gesture_HighFive.png';
import FistBump from '../icons/Gesture_Fistbump.png';
import Applause from '../icons/Gesture_Applause.png';
import OkSign from '../icons/Gesture_OK.png';
import ThumbUp from '../icons/Gesture_ThumbUp.png';

import postVuplexMessage from '../service/message-vuplex';

const useStyles = makeStyles(theme => ({
	actions: {
		flex: '1 0 auto',
		height: '144px',
		background: theme.palette.background.paper,
		display: 'flex',
		justifyContent: 'center',
		
		flexDirection: 'row',
		padding: '12px 56px 0 56px',
	},

	gridContainer: {
		display: 'grid',
		gridAutoFlow: 'column',
		gap: '95px'
	}
}));

const GestureMenu = (props) => {
	const classes = useStyles(props);

	const clickSound = "Tools/Settings/Press";
	const hoveraudiomessage = "Tools/Settings/HL";

	const sendToVuplex = (msgValue) =>
	{
		postVuplexMessage("Activate gesture", {value: msgValue});
		if (props.vr === false)
		{
			postVuplexMessage("Close tablet", null);
		}
	}

	return (
		<div className={classes.actions}>
			<HeroIconContext.Provider value={props.heroIconContext}>
				<Grid className={classes.gridContainer} container justify="center" spacing={0}>
					<Grid item>
						<HeroIcon
							name="Handshake"
							noBackground={props.noBackground}
							icon={Handshake}
							iconOff={Handshake}
							uiAudioMessage={clickSound}
							uiHoverAudioMessage={hoveraudiomessage}
							onPointerDown={() => sendToVuplex("OfferHandshakeR")}
							hotkey="1"
							disabled={props.vr}
						/>
					</Grid>
					<Grid item>
						<HeroIcon
							name="High Five"
							noBackground={props.noBackground}
							icon={HighFive}
							iconOff={HighFive}
							uiAudioMessage={clickSound}
							uiHoverAudioMessage={hoveraudiomessage}
							onPointerDown={() => sendToVuplex("OfferHighFiveR")}
							hotkey="2"
							disabled={props.vr}
						/>
					</Grid>
					<Grid item>
						<HeroIcon
							name="Fist Bump"
							noBackground={props.noBackground}
							icon={FistBump}
							iconOff={FistBump}
							uiAudioMessage={clickSound}
							uiHoverAudioMessage={hoveraudiomessage}
							onPointerDown={() => sendToVuplex("OfferFistBumpR")}
							hotkey="3"
							disabled={props.vr}
						/>
					</Grid>
					<Grid item>
						<HeroIcon
							name="Applause"
							noBackground={props.noBackground}
							icon={Applause}
							iconOff={Applause}
							uiAudioMessage={clickSound}
							uiHoverAudioMessage={hoveraudiomessage}
							onPointerDown={() => sendToVuplex("Applause")}
							hotkey="4"
						/>
					</Grid>
					<Grid item>
						<HeroIcon
							name="OK"
							noBackground={props.noBackground}
							icon={OkSign}
							iconOff={OkSign}
							uiAudioMessage={clickSound}
							uiHoverAudioMessage={hoveraudiomessage}
							onPointerDown={() => sendToVuplex("OkSign")}
							hotkey="5"
							disabled={props.vr}
						/>
					</Grid>
					<Grid item>
						<HeroIcon
							name="ThumbUp"
							noBackground={props.noBackground}
							icon={ThumbUp}
							iconOff={ThumbUp}
							uiAudioMessage={clickSound}
							uiHoverAudioMessage={hoveraudiomessage}
							onPointerDown={() => sendToVuplex("ThumbUp")}
							hotkey="6"
							disabled={props.vr}
						/>
					</Grid>
				</Grid>
			</HeroIconContext.Provider>
		</div>
	)
}

export default GestureMenu;