import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import queries from '../graphql/queries';
import postVuplexMessage from '../service/message-vuplex';
import HeroIcon from './common/hero-icon';
import GlueButton from './common/glue-button'

import VRModeOnIcon from '../icons/hero/VR_Mode_ON.png';
import VRModeOffIcon from '../icons/hero/VR_Mode_OFF.png';

const toggleVR = (vrModeEnabled) =>
{
	postVuplexMessage('Toggle VR', { value: vrModeEnabled });
};

const VRHeroIcon = (props) =>
{
	const clientPlatformResult = useQuery(queries.clientPlatform);
	const clientPlatform = clientPlatformResult.data?.clientPlatform;
	
	if (!clientPlatform)
		return null;
	
	const vr = clientPlatform.PlatformType === "VR";
	const allowVRToggle = (vr && clientPlatform.Capabilities?.includes("KBAM")) || (!vr && clientPlatform.Capabilities?.includes("VR"));

	const toggleOffSound = "Tools/VRMode/Toggle/Off/Press";
	const toggleOnSound = "Tools/VRMode/Toggle/On/Press";
	const audiomessage = vr ? toggleOffSound :  toggleOnSound; 
	const hoveraudiomessage = "Tools/VRMode/HL";
	const vrhotkey = !allowVRToggle ? "" : "1";

	return (
		<>
			{props.toolbarHeader ? 
			(
				<GlueButton
					variant='compact'
					onPointerDown={() => toggleVR(!vr)}
				>
					VR
				</GlueButton>
			) : (
				<HeroIcon
					name="VR Mode"
					icon={VRModeOnIcon}
					iconOff={VRModeOffIcon}
					uiAudioMessage={audiomessage}
					uiHoverAudioMessage={hoveraudiomessage} 
					onPointerDown={() => toggleVR(!vr)} 
					toggled={vr}
					disabled={!allowVRToggle}
					hotkey={vrhotkey}
				/>
			)}
		</>
	);
};

export default VRHeroIcon;
