import React, {useState} from 'react';
import { useMutation } from '@apollo/react-hooks';
import mutations from '../graphql/mutations';
import WebInputfield from './common/web-inputfield';
import { makeStyles } from '@material-ui/core';

import GlueButton from '../component/common/glue-button';
import Check from '@material-ui/icons/Check';
import DialogHeader from '../component/common/dialog-header';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'grid',
		gridAutoFlow: 'row',
		gap: '36px',
		justifyContent: 'center',
		alignItems: 'center'
	},

	loginDisplay: {
		height: '160px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',

		'& > div:last-child': {
			alignSelf: 'flex-end'
		}
	},

	successField: {
		height: '160px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'flex-start',
	},

	loginButton: {
		margin: '0 auto 0 auto',
		padding: '0 16px 0 16px'
	},

	checkMark: {
		fontSize: '96px',
		color: '#00FF84'
	}
}));

const CodeLogin = (props) => {

	const classes = useStyles();
	const [ inputText, setInputText ] = useState('');
	const [messageContent, setMessageContent] = useState('');
	const [ verifyingCode, setVerifyingCode ] = useState(false);

	const [loginState, setloginState] = useState(0)

	const closeDialog = e => props.closeDialog(e);

	const [ codeLogin ] = useMutation(
		mutations.confirmCodeLogin,
		{
			deviceCode: inputText,
		}
	);

	const onInputChange = (input) =>
	{
		setloginState(0);
		setInputText(input.toUpperCase());
	}

	const submitCode = async() =>
	{
		setVerifyingCode(true);
		try {
			const login = await codeLogin({ variables: { deviceCode: inputText } })
			if (login && login.data && login.data.confirmCodeLogin && login.data.confirmCodeLogin > 0)
			{
				setloginState(1);
				setMessageContent("You are logged in!")
			} else {
				setloginState(2);
				setMessageContent("This code is invalid or expired. Check the code and try again.")
			}
		} catch (err) {
			console.log('Failed to confirm device code:', err)
			setMessageContent("An error occured. Please try again.")
			setloginState(2);
		} finally {
			setVerifyingCode(false);
		}
	}

	return (
	<div className={classes.root}>
			<DialogHeader
				width={'65%'}
				header={loginState !== 1 ? 'Enter the code displayed on your device:' : 'You are logged in!'}
				closeDialog={e => closeDialog(e)}
			/>
			{loginState === 1 ? <div className={classes.successField}>
				<Check className={classes.checkMark} />
			
			</div> : <div className={classes.loginDisplay}>
					<WebInputfield
						centerText
						simpleInput
						width={"160px"}
						maxLength={4}
						value={inputText}
						onSubmitKey={() => submitCode()}
						onChange={(e) => onInputChange(e)}
						placeholder={"Enter Code"}
						margin='0 auto'
						errorMessage={messageContent}
						error={loginState === 2 && inputText.length === 4}
						/>
					<div className={classes.loginButton}>
						<GlueButton
							disabled={inputText.length < 4 || verifyingCode}
							color="primary"
							onPointerDown={() => submitCode()}
						>
							Log in Your Device
						</GlueButton>
					</div>
				</div>}
	</div>);
}

export default CodeLogin;