import React, { useState } from "react";
import { useQuery, useApolloClient, useMutation } from "@apollo/react-hooks";

import { makeStyles} from "@material-ui/core";

import queries from "../../graphql/queries";
import mutations from "../../graphql/mutations";

import { usePromptDialogContext } from "../../util/prompt-dialog-context";

import GlueButton from "../common/glue-button";
import WebInputfield from "../../standalone-web/common/web-inputfield";
import AddOrgMemberDialog from "./add-org-member";

import InfoDialogTemplate from "../common/info-dialog-template";
import { useUserContext } from "../../util/user-context";

import RemoveMemberDialog from "./remove-member-dialog";
import GlueDropdown from "../common/glue-dropdown";
import { orgUserSelection } from "../../util/org-utils";

import LoadingIndicator from "../common/loading-indicator";
import GlueListView, { alphabeticalSortFunc, dateSortFunc } from "../common/glue-list-view";
import { glueDateDisplayFormat } from "../../util/app-utils";
import GlueInputfield from "../common/glue-inputfield";
import { orgMemberRoleNameMap } from "../../defines";
import clsx from 'clsx';
import { sttActive } from "../../util/speech-utils";


const useStyles = makeStyles((theme) => ({
	root: {
		height: '100%',
		width: '100%',
		boxSizing: 'border-box',
		display: 'flex',
		flexFlow: 'column nowrap',
		overflow: 'hidden',
	},

	actionBar: {
		width: '100%',
		height: theme.custom.header.height,
		display: 'flex',
		flexDirection: 'row',
		gap: theme.glueSpacing('m'),
		alignItems: 'center',

		'& button:last-child': {
			marginLeft: 'auto'
		}
	},

	selfWarning: {
		color: theme.palette.warning.main
	},

	roleInvitationPending: {
		color: theme.custom.manageTeamMembers.invitationPendingColor
	}
}), { name: 'MuiGlueOrgMembers' });


const OrgMembers = (props) =>
{
	const classes = useStyles();
	const apollo = useApolloClient();
	const user = useUserContext();

	const { addDialog, closePromptDialog } = usePromptDialogContext();

	const [addMemberDialogOpen, setAddMemberDialogOpen] = useState(false);
	const [filterTerm, setFilterTerm] = useState('');

	const [ selected, setSelected ] = useState([]);
	const orgIdRes = useQuery(queries.currentOrgId);
	const uiMode = useQuery(queries.ui);

	const searchSTTSinkId = 'OrgTeamsFilterTerm';
	const sttRes = useQuery(queries.speechRecognition);

	const [resendInvitation] = useMutation(mutations.resendInvitationEmail);

	const getOrgRes = useQuery(queries.getOrg, {
		skip: !orgIdRes.data,
		variables: {
			orgId: orgIdRes.data.currentOrgId
		}
	});

	/** @type {Array} */
	let members = getOrgRes.data?.getOrg.members ?? [];

	// Filter
	const filterKeys = ['email', 'name'];
	members = members.filter((member) =>
		!filterTerm || filterKeys.some((key) => String(member[key]).includes(filterTerm))
	);

	// Sorting functions
	const roleSortFunc = (a, b, key, dir) => {
		const roleSortOrder = ['guest', 'member', 'admin'];
		if (a.invitationStatus === 'pending')
			return -1 * dir;
		
		return (roleSortOrder.indexOf(a.memberRole) < roleSortOrder.indexOf(b.memberRole) ? -1 : 1) * dir;
	}

	const getRoleDisplay = (item, key) =>
	{
		const roleClasses = [];
		let text = null;

		const hasInvitationPending = item.invitationStatus === 'pending';
		if (hasInvitationPending)
		{
			text = "Invitation pending";
			roleClasses.push(classes.roleInvitationPending);
		}
		else
		{
			text = orgMemberRoleNameMap[item.memberRole] ?? "Unknown";
		}

		return (<span className={clsx(roleClasses)}>{text}</span>)
	}

	const resendInvite = async (member) => {
		console.log(member)
		await resendInvitation({
			variables: {
				orgId: orgIdRes.data?.currentOrgId,
				memberEmail: member.email
			}
		}).then(res => {
			addDialog(
				<InfoDialogTemplate
					header={'Success'}
					message={`A new invitation has been sent to ${member.email}`}
				/>
			)
		}).catch(err => {
			addDialog(
				<InfoDialogTemplate
					isError={true}
					message={`The new invitation could not be sent to ${member.email}`}
				/>
			)
		});
	}

	const columns = [
		{key: "email", label: "user", width: "1.5fr", contentFunc: undefined, sortFunc: alphabeticalSortFunc}, 
		{key: "memberRole", label: "role", width: "1fr", contentFunc: getRoleDisplay, sortFunc: roleSortFunc}, 
		{key: "lastLogin", label: "last login", width: "1fr", contentFunc: (item, key) => glueDateDisplayFormat(item[key]), sortFunc: dateSortFunc},
	];

	const removeMember = async (removable, removeAccount = false) => 
	{
		closePromptDialog();
		if (removable instanceof Array) {
			await apollo.mutate({
				mutation: mutations.removeOrganizationMembers,
				variables: {
					orgId: orgIdRes.data.currentOrgId,
					memberEmails: removable.map(item => item.memberEmail),
					removeAccount: removeAccount
				}
			}).then(res => {
				addDialog(<InfoDialogTemplate
					header={'Removed:'}
					message={`${removable.length} people`}
				/>);
			}).catch(err => {
				addDialog(<InfoDialogTemplate
					isError
					message={err.message}
				/>);
			}).finally(() => {
				getOrgRes.refetch();
				setSelected(prev => prev.filter(email => !removable.find(item => item.memberEmail === email)));
			});
		}
	}

	const removeMemberRequest = () => 
	{
		let hasOwnableDoms = false;

		let selfSelect = false;
		let removables = [];
		selected.forEach(item => {
			if (item !== user.email) {
				const userDom = item.split('@');
				if (getOrgRes.data.getOrg.emailDomains.includes('@' + userDom[1])) {
					hasOwnableDoms = true;
				}
				removables.push({
					orgId: orgIdRes.data.currentOrgId,
					memberEmail: item
				})
				selfSelect = true;
			}
		});
		let message = null;
		let selfWarning = null;
		let removeAccountsMsg = null;
		let header = removables.length > 1 ? 'Remove users' : 'Remove user';
		if (removables.length > 0)
		{
			message = removables.length > 1 ? <p>{`You are about to remove ${removables.length} user accounts from your organization.`}</p> : 
				<p>{`You are about to remove 1 user account from your organization.`}</p>
		}
		if (selfSelect) {
			selfWarning = <p className={classes.selfWarning}>You can&apos;t remove your own user account.</p>
		}
		if (hasOwnableDoms) {
			removeAccountsMsg = removables.length > 1 ? <p>Some of them are <b>{`${getOrgRes.data.getOrg.emailDomains}`}</b> accounts. How would you like to deal with those?</p> : 
				<p>This is a <b>{`${getOrgRes.data.getOrg.emailDomains}`}</b> account. How would you like to deal with it?</p>
		}

		addDialog(<RemoveMemberDialog
			callback={(b) => removeMember(removables, b)}
			onClose={() => closePromptDialog()}
			header={header}
			removeAccoMsg={removeAccountsMsg}
			notice={selfWarning}
			message={message}
		/>)
	}

	const setNewUserRole = async (newRole) =>
	{
		await apollo.mutate({
			mutation: mutations.updateOrgMembers,
			variables: {
				orgId: orgIdRes.data.currentOrgId,
				memberEmails: selected,
				memberRole: newRole
			}
		}).then(res => {
			addDialog(<InfoDialogTemplate 
				header={'Success'}
				message={`${selected.length} users updated.`}
			/>)
			getOrgRes.refetch();
		}).catch(err => {
			addDialog(<InfoDialogTemplate
				isError={true}
				message={err.message}
			/>);
		});
	}

	const menuOptionsEdit = [
		{ key: null, content: "Resend invitation", width: "0.5fr", callback: (member) => addDialog(<InfoDialogTemplate header={"Resend invitation?"} message={`Are you sure you want to resend the invitation email to ${member.email}?`} callbacks={[
			{ label: 'Cancel', callback: () => null },
			{ label: 'Resend invitation', color: 'primary', callback: () => resendInvite(member)}
		]} />) }
	];

	const menuDisabledFunc = (item) => 
	{
		if (!user.org?.membersEdit) return true;
		return item.invitationStatus !== 'pending';
	}

	if (getOrgRes.loading) {
		return(
			<LoadingIndicator />
		)
	}

	return(
		<div className={classes.root}>
			<div className={classes.actionBar}>
				{user.org?.membersEdit &&
					<GlueButton
						width={'200px'}
						color="primary"
						onPointerDown={() => setAddMemberDialogOpen(true)}
					>Invite Users</GlueButton>
				}
				{uiMode.data?.ui === 'web' ? (
					<WebInputfield
						width={'200px'}
						search={true}
						showClearButton={!!filterTerm}
						placeholder={"Search"}
						value={filterTerm}
						onChange={setFilterTerm}
						onClear={() => setFilterTerm('')}
					/>) : (
					<GlueInputfield
						width={'256px'}
						search={true}
						showClearButton={!!filterTerm}
						placeholder={"Search"}
						value={filterTerm}
						onChange={setFilterTerm}
						onClear={() => setFilterTerm('')}
						sinkId={searchSTTSinkId}
						speechInput={sttActive(sttRes, searchSTTSinkId)}
						onSpeechChange={(value) => { setFilterTerm(value.toLowerCase()); }}
					/>
				)}
				{user.org?.membersEdit && selected.length > 0 &&
						<GlueDropdown
							label={'Assign a role'}
							width={'200px'}
							items={orgUserSelection}
							onChange={(id, checked) => { checked && setNewUserRole(id) }}
						/>}
				{user.org?.membersEdit && selected.length > 0 && 
					<GlueButton color="destructive" onPointerDown={() => removeMemberRequest()}>Remove Users</GlueButton> 
				}
			</div>
			<GlueListView
				web={props.web}
				disableSelection={!user.org?.membersEdit}
				columns={columns} 
				items={members} 
				selected={selected}
				defaultSortKey={'email'}
				overrideIdKey={'email'}
				onChangeSelection={(val) => setSelected(val)}
				menuOptions={menuOptionsEdit}
				menuDisabledFunc={menuDisabledFunc}
				defaultSortDir={1}
			/>

			<AddOrgMemberDialog
				orgId={orgIdRes.data?.currentOrgId}
				open={addMemberDialogOpen}
				onClose={() => setAddMemberDialogOpen(false)}
				refetch={() => getOrgRes.refetch()}
			/>
		</div>
	);
}

export default OrgMembers;