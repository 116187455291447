import React from 'react';
import { makeStyles } from "@material-ui/core";
import AcronymIcon from '../common/acronym-icon';

const useStyles = makeStyles((theme) => ({
	icon: props => ({
		width: theme.custom.orgIcon[props.variant ?? 'medium'].size,
		height: theme.custom.orgIcon[props.variant ?? 'medium'].size,
		borderRadius: '50%',
		overflow: 'hidden'
	}),

	image: {
		width: '100%',
		height: '100%'
	}

}), { name: 'MuiGlueOrgIcon' });

const OrgIcon = (props) => {
	const classes = useStyles(props);

	return (
		<div className={classes.icon}>
			{ props.imageUrl ? (
				<img className={classes.image} src={props.imageUrl} alt='' />
			) : (
				<AcronymIcon
					text={props.name}
					id={props.id}
					typeVariant={props.typeVariant ?? 'h3'}
				/>
			)}
		</div>
	)
}

export default OrgIcon;