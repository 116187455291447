import queries from "../graphql/queries";
import mutations from "../graphql/mutations";
import { setCurrentTeam, restorePreviousTeam } from "../service/choose-current-team";
import postVuplexMessage from "../service/message-vuplex";
import { setUserSettings } from "../service/user-settings";
import { logFirebaseEvent } from "../service/firebase/firebase";

const onTutorialOrgCreated = async (apollo, data) => {
	const tutorialRoomInfo = await apollo.query({
		query: queries.roomInfo,
		variables: {
		roomId: data.roomId
		}
	});

	if (!tutorialRoomInfo.data.roomInfo) {
		throw new Error('Failed to fetch tutorial space info');
	}

	postVuplexMessage('Join space', { room: tutorialRoomInfo.data.roomInfo, takeEveryone: false });

	await setCurrentTeam(apollo, data.teamId);
	await setUserSettings(apollo, { tutorialStarted: true });
	await logFirebaseEvent('tutorialStarted');
}

export const startTutorial = async (apollo) => {
	const existingTutorialOrgRes = await apollo.query({
		query: queries.getTutorialOrganization,
		fetchPolicy: 'network-only'
	});

	if (existingTutorialOrgRes.data.getTutorialOrganization) {
		await onTutorialOrgCreated(apollo, existingTutorialOrgRes.data.getTutorialOrganization);
		return;
	}

	const res = await apollo.mutate({
		mutation: mutations.createTutorialOrganization,
	});
	onTutorialOrgCreated(apollo, res.data.createTutorialOrganization);
}

export const endTutorial = async (apollo) => {
	await restorePreviousTeam(apollo);
	const existingTutorialOrgRes = await apollo.query({
		query: queries.getTutorialOrganization,
		fetchPolicy: 'network-only'
	});

	if (!existingTutorialOrgRes.data.getTutorialOrganization) {
		return;
	}

	await apollo.mutate({ mutation: mutations.deleteTutorialOrganization,
		refetchQueries: [{ query: queries.myTeams }, { query: queries.getUserOrganizations }] });
	await logFirebaseEvent('tutorialCompleted');
}

export const userHasTutorialOrg = async (apollo) => {
	const userOrgsRes = await apollo.query({
		query: queries.getUserOrganizations,
		fetchPolicy: 'network-only'
	});

	return userOrgsRes.data.getUserOrganizations.some(org => org.isTutorial);
}