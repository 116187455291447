import React, { useState } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { usePromptDialogContext } from '../../util/prompt-dialog-context';
import SimpleSAUP, { SAUPHandlingStatus } from './simple-saup';
import SpaceTemplateDetailsDialog from './space-template-details-dialog';
import { Asset, SignedUploadUrlResponse } from '../../graphql/types-generated';
import InfoDialogTemplate from '../common/info-dialog-template';
import mutations from '../../graphql/mutations';
import { AssetProcessingResult, assignCustomThumbnail, uploadSpaceAssetPackageAsync, checkSpaceAssetUploadUploadStatus, assetVisibility } from '../space-asset/space-asset-common';

const CreateTemplateDialog = (props: {
	teamId: string
	web?: boolean
}) =>
{
	const apollo = useApolloClient();
	const { addDialog, closePromptDialog } = usePromptDialogContext();

	const [ errorMsg, setErrorMsg ] = useState<string>('');
	const [ selectedFile, setSelectedFile ] = useState<File | undefined>();
	const [ handlingStatus, setHandlingStatus ] = useState<SAUPHandlingStatus | undefined>();
	const [ progress, setProgress ] = useState<number>(0);

	const createTemplate = async (name: string, customThumbnail?: File) => {
		if (!selectedFile || !props.teamId) {
			return;
		}

		setHandlingStatus('mutating');


		let asset: Asset | undefined = undefined;
		try {
			const createTeamAssetRes = await apollo.mutate({
				mutation: mutations.createAssetDeferred,
				variables: {
					name: name,
					type: 'space',
					permission: assetVisibility.team,
					linkToTeam: props.teamId,
					assignVersionPointer: 'production'
				}
			});
			const signedUploadURLBody: SignedUploadUrlResponse = createTeamAssetRes.data.createAssetDeferred;
			console.log(signedUploadURLBody)

			setHandlingStatus('uploading');
			const uploadRes = await uploadSpaceAssetPackageAsync(
				signedUploadURLBody,
				selectedFile,
				setProgress,
				() => null
			);

			if (uploadRes) {
				setHandlingStatus('processing');

				const processingResult: AssetProcessingResult = await checkSpaceAssetUploadUploadStatus(
					uploadRes.statusItemId,
					apollo,
					() => null
				);

				asset = processingResult.asset;
			}

			if (asset && customThumbnail) {
				await assignCustomThumbnail(customThumbnail, asset.assetId, apollo);
			}
		}
		catch (err) {
			setErrorMsg((err as Error).message);
			setHandlingStatus(undefined);
			return;
		}

		closePromptDialog();
		addDialog(<InfoDialogTemplate
			header={'Success!'}
			message={'New space template was created with name'}
			variable={asset?.name}
		/>);
	}

	return (
		<SpaceTemplateDetailsDialog
			title='New space template'
			defaultName='New space template'
			buttonLabel='Create'
			web={props.web}
			canCreate={!!selectedFile}
			onCreate={createTemplate}
			onCancel={closePromptDialog}
			errorMsg={errorMsg}
			loading={!!handlingStatus}
			thumbnailUrl={undefined}
			noDefaultThumbnail
		>
			{!!props.web && <SimpleSAUP 
				teamId={props.teamId}
				onPackageSelected={setSelectedFile}
				package={selectedFile}
				handlingState={handlingStatus}
				progress={progress*100}
			/>}
		</SpaceTemplateDetailsDialog>
	);
};
export default CreateTemplateDialog;
