import queries from "../graphql/queries";
import CryptoJS from 'crypto-js';

class QuerySyncer
{
	constructor(id, query)
	{
		this.id = id;
		this.query = query;

		this.channel = null;
		this.subscription = null;
		this.watchQuery = null;

		this.apollo = null;
		this.isMain = false;

		this.initialStateHash = null;
		this.wroteHashes = [];
	}

	start(_apollo, _isMain)
	{
		this.apollo = _apollo;
		this.isMain = _isMain;

		this.channel = new BroadcastChannel(this.id);

		this.setupInitState();

		this.subscription = this.apollo.watchQuery({ query: this.query })
			.subscribe(this.onQueryResult.bind(this));

		this.channel.onmessage = this.onBroadcasteMessage.bind(this);

		if (!this.isMain)
		{
			//console.log("Request init data");

			this.channel.postMessage({
				topic: 'init',
				data: null
			});
		}
	}

	setupInitState()
	{
		// The subsription returns the initial state on the first time.
		// Only the main view should take this result and broadcast it to others.
		// Secondary views will have to ignore the first result as otherwise
		// they'd just push their initial state to everyone and thus wipe 
		// whatever state existed before. These secondary views might come and go
		// whenever, so they must not override the already established state.

		this.initialStateHash = this.getCurrentStateHash();
		//console.log("Inital state hashed", this.initialStateHash);

		if (!this.isMain)
		{
			//console.log("This is not main, push inital state hash to ignore list");
			this.wroteHashes.push(this.initialStateHash);
		}
	}

	onQueryResult(res)
	{
		if (res.data)
		{
			let skipBroadcast = false;

			//console.log("Query chang!", res.data, this.wroteHashes);

			if (this.wroteHashes.length > 0)
			{
				const dataHash = CryptoJS.SHA1(JSON.stringify(res.data)).toString();

				this.wroteHashes = this.wroteHashes.filter(
					(wroteHash) =>
					{
						if (dataHash === wroteHash)
						{
							skipBroadcast = true;
							return false;
						}
						else
						{
							return true;
						}
					}
				);

				if (skipBroadcast)
				{
					//console.log("Skip broadcasting this update of", dataHash, this.wroteHashes);
					return;
				}
			}

			if (!skipBroadcast)
			{
				//console.log("Broadcast query change", res.data);
				this.postUpdate(res.data);
			}
			else
			{
				//console.log("Skip broadcast of query change", res.data);
			}

			//console.log("Query res handled", this.wroteHashes);
		}
	}

	onBroadcasteMessage(msg)
	{
		if (msg.data.topic === 'update')
		{
			const currentStateHash = this.getCurrentStateHash();
			if (msg.data.hash !== currentStateHash)
			{
				//console.log("Got broadcast update. Write to cache.", msg.data.hash, msg.data.data);

				this.wroteHashes.push(msg.data.hash);

				this.apollo.writeQuery({
					query: this.query,
					data: msg.data.data
				});
			}
			else
			{
				//console.log("Don't write received duplicate state", msg.data.hash);
			}
		}
		else if (msg.data.topic === 'init' && this.isMain)
		{
			// Some new GlueOS instance just initalized it self.
			// This is the main view so send state.

			//console.log("Sending out init data");
			const data = this.apollo.readQuery({ query: this.query });
			this.postUpdate(data);
		}
	}

	postUpdate(data)
	{
		const msg = {
			topic: 'update',
			hash: CryptoJS.SHA1(JSON.stringify(data)).toString(),
			data: data
		};

		//console.log("Broadcast update", msg);
		this.channel.postMessage(msg);
	}

	getCurrentStateHash()
	{
		const data = this.apollo.readQuery({ query: this.query });
		const hash = CryptoJS.SHA1(JSON.stringify(data)).toString();
		return hash;
	}
}

/**
 * 
 * @param {ApolloClient} apollo 
 */
export const setupStateBroadcasting = (apollo, isMain) =>
{
	for (const syncQuery of syncQueries)
	{
		syncQuery.start(apollo, isMain);
	}
};

// Add queries to be synced here.
const syncQueries = [
	new QuerySyncer("clockStopwatch", queries.clockStopwatch),
	new QuerySyncer("clockTimer", queries.clockTimer),
	new QuerySyncer("context", queries.context),
	new QuerySyncer("currentTeamId", queries.currentTeamId),
	new QuerySyncer("currentSpaceServerKey", queries.currentSpaceServerKey),
	new QuerySyncer("userSettings", queries.mySettings),
	new QuerySyncer("externalBrowserInfo", queries.externalBrowserInfo),
	new QuerySyncer("muteAllEnabled", queries.muteAllEnabled),
	new QuerySyncer("uiDisabledState", queries.uiDisabledState),
	new QuerySyncer("uiDisabled", queries.uiDisabled),
	new QuerySyncer("announcerModeEnabled", queries.announcerModeEnabled),
	new QuerySyncer("isFacilitator", queries.isFacilitator),
	new QuerySyncer("microphoneLocked", queries.microphoneLocked)
];
