import React from 'react';
import { makeStyles, ButtonBase } from '@material-ui/core';
import { hoverAudio, clickAudio } from './common-vuplex-messages';

type ColorSelectorProps = {
	onPointerDown?: (e: React.PointerEvent) => void
	toggled?: boolean
	color: string
	uiAudioMessage?: string
	uiHoverAudioMessage?: string
};

const useStyles = makeStyles((theme) => ({
	swatch: (props: ColorSelectorProps) => ({
		width: theme.custom.glueButton.icon.width,
		height: theme.custom.glueButton.icon.height,
		borderRadius: theme.custom.glueButton.icon.borderRadius,
		background: props.color, 
		...theme.custom.selectOutline(props.toggled, { borderRadius: theme.custom.glueButton.icon.borderRadius }),

		'&:hover': {
			filter: 'brightness(1.25)'
		},

		'&:active': {
			filter: 'brightness(1.50)'
		},

		'&:disabled': {
			opacity: '50%'
		}
	})
}), { name: "MuiGlueColorSelector" });

const onClickIcon = (props: ColorSelectorProps, e: React.PointerEvent) =>
{
	if (props.onPointerDown)
	{
		props.onPointerDown(e);
	}
};

const ColorSelector = (props: ColorSelectorProps) => {
	const classes = useStyles(props);
	const audiomessage = !!props.uiAudioMessage ? props.uiAudioMessage : "Tools/ColorSelector/Press";
	const hoveraudiomessage = !!props.uiHoverAudioMessage ? props.uiHoverAudioMessage : "Tools/ColorSelector/HL";

	return (
		<ButtonBase className={classes.swatch}
			onPointerDown={(e) => {onClickIcon(props,e); clickAudio(audiomessage)} }
			onPointerEnter={() => {hoverAudio(hoveraudiomessage)}}
		/>
	);
};

export default ColorSelector;
