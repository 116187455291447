import { CSSProperties } from "@material-ui/core/styles/withStyles";

export type Type = {
	size: string
	borderRadius: string
	font: CSSProperties // Should be the same as ButtonBase overrides, if you change this, also change ButtonBase
};

const common: Type = {
	size: '296px',
	borderRadius: '34px',
	font: {
		fontWeight: 600,
		letterSpacing: 'normal',
		fontSize: '24px',
		lineHeight: '32px',
	}
};

export const web: Type = {
	...common,

	size: '180px',
	borderRadius: '22px',
	font: {
		fontWeight: 600,
		letterSpacing: 'normal',
		fontSize: '16px',
		lineHeight: '20px',
	}
};

export const tablet: Type = {
	...common,

};
