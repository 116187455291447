import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { makeStyles, IconButton } from '@material-ui/core';

import queries from '../../graphql/queries';

import moment from 'moment';
import { getHumanizedFileSize } from '../../util/file-utils';
import SVGIconWrapper from '../common/svg-icon-wrapper';
import { ReactComponent as ClearIcon } from '../../icons/Close.svg';
import { clickAudio, hoverAudio } from '../common/common-vuplex-messages';

import Error_UnknownFiletype from '../../images/Error_UnknownFiletype.png';

const useStyles = makeStyles((theme) => ({
	root: {
		flex: '1 0 auto',

		display: 'flex',
		flexFlow: 'column nowrap',
		height: '928px',
		justifyContent: 'flex-start',
		alignItems: 'center',
		textAlign: 'center',
		position: 'relative',
		borderRadius: '25px',

		background: theme.palette.secondary.dark,

		'&> button': {
			float: "right",
			position: 'absolute',
			top: '0px',
			right: '0px',
			padding: '0',
			margin: '12px',

			'&:hover': {
				color: theme.palette.primary.main,
				background: 'transparent'
			}
		},

		'&> h2': {
			margin: '20px',
		}
	},

	displayWindow: {
		margin: '4% 20% 0% 20%',
	},

	fileInfo: {
		height: '200px',
		width: '500px',
		justifyContent: 'flex-end',
		alignItems: 'center',
		display: 'flex',
		flexFlow: 'column',
		borderRadius: '25px',
		margin: '25px',

		'&> p': {
			marginTop: '0px',
			marginBottom: '5px'
		}
	},

	image: (props) => {
		const size = props.size ? props.size : '280px';

		return {
			width: size,
			height: size,
			display: 'block',
			margin: '75px 0 35px 0'
		};
	}
}));

const FileViewNotSupported = (props) =>
{
	const classes = useStyles();

	const itemRes = useQuery(
		queries.inventoryItemInfo,
		{
			variables: {
				inventoryItemId: props.itemId
			}
		}
	);

	const closePreview = e => props.closePreview(e);

	const closeaudiomessage = "Menu/TeamFiles/NotSupported/Close/Press";
	const closehoveraudiomessage = "Menu/TeamFiles/NotSupported/Close/HL";

	return (
		<div className={classes.root}>
			<h2>{itemRes.data?.inventoryItemInfo.name}</h2>
			<IconButton 
				onPointerDown={e => {closePreview(e); clickAudio(closeaudiomessage)}}
				onPointerEnter={() => {hoverAudio(closehoveraudiomessage)}}
			>
				<SVGIconWrapper><ClearIcon /></SVGIconWrapper>
			</IconButton>
			<img className={classes.image} src={Error_UnknownFiletype} alt={""} />
			<div className={classes.displayWindow}>
				<h2>
					Sorry, this file type is not currently supported in Glue.
				</h2>
				
				<p style={{marginBottom: '2px'}}>
					You can use Team Files to host this file, but it can not be imported into Glue spaces
				</p>
			</div>
			<div className={classes.fileInfo}>
				<p>filesize: {getHumanizedFileSize(itemRes.data?.inventoryItemInfo.fileSize)}</p>
				<p>last modified: {moment(itemRes.data?.inventoryItemInfo.updatedDate).format('YYYY-MM-DD HH:mm')}</p>
			</div>
		</div>
	);
};

export default FileViewNotSupported;
