import React, { useState, useEffect } from 'react';
import DialogHeader from '../common/dialog-header';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import queries from '../../graphql/queries';
import { DialogActions, makeStyles, Typography } from '@material-ui/core';
import GlueDialog from './glue-dialog';
import GlueButton from './glue-button';
import { StopSpeechRecognition, RestartSpeechRecognitionContinuous } from '../../service/speech-to-text';
import SmsIcon from '@material-ui/icons/Sms';
import HourGlassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import LoadingIndicator from '../common/loading-indicator';
import GlueScroll from './glue-scroll';
import { GlueTheme } from '../../service/glue-theme';

const useStyles = makeStyles(theme => ({
	dialogActions: {
		gap: '16px',
		marginTop: '48px'
	},

	statusRow: {
		gap: theme.glueSpacing('m'),
		marginTop: theme.glueSpacing('m'),
		marginBottom: theme.glueSpacing('m'),
		display: 'flex',
		flexFlow: 'row nowrap',
		justifyContent: 'center',
		alignItems: 'center',
	},

	statusCard: {
		width: '128px',
		height: '72px',
		display: 'flex',
		gap: theme.glueSpacing('s'),
		flexFlow: 'column nowrap',
		justifyContent: 'center',
		alignItems: 'center',
	},

	statusIconActive: {
		width: '36px',
		height: '36px',
		justifyContent: 'center',
		alignItems: 'center',
		color: '#f5ab88'
	},

	statusIcon: {
		width: '36px',
		height: '36px',
		justifyContent: 'center',
		alignItems: 'center',
		color: '#929597'
	},

	statusTextActive: {
		color: '#f5ab88',
		textDecorationLine: 'underline',
		textDecorationThickness: '2px',
		textUnderlineOffset: '4px',
		opacity: '100%'
	},

	statusTextInactive: {
	},

	previewArea: {
		height: '240px',
		display: 'flex',
		flexFlow: 'row nowrap',
		justifyContent: 'left',
		marginLeft: theme.glueSpacing('xl'),
		marginRight: theme.glueSpacing('xl'),
	},

	errorArea: {
		height: '240px',
		display: 'flex',
		flexFlow: 'row nowrap',
		alignItems: 'center',
		justifyContent: 'center',
		marginLeft: theme.glueSpacing('xl'),
		marginRight: theme.glueSpacing('xl'),
	},

	previewText: {
		color: theme.palette.common.white,
		opacity: '100%',
		fontSize: '28px',
		lineHeight: '40px',
	},

	previewTextHighlighted: {
		color: '#f5ab88',
		opacity: '100%',
		fontSize: '28px',
		lineHeight: '40px',
	},

	errorText: {
		color: GlueTheme.palette.error.main,
		opacity: '100%',
		fontSize: '28px',
		lineHeight: '40px'
	},

	loadingIndicator: {
		flex: '1 0 auto',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	}
}));

const cancelaudiomessage = "Menu/ReturnHome/Cancel/Press";
const cancelhoveraudiomessage = "Menu/ReturnHome/Cancel/HL";
const okaudiomessage = "Menu/ReturnHome/Ok/Press";
const okhoveraudiomessage = "Menu/ReturnHome/Ok/HL";

const paper = {
	style: {
		width: '720px',
		height: '560px',
		margin: '12px',
		borderRadius: '44px',
		justifyContent: 'center',
	}
};

type StatusCardProps = {
	children: React.ReactNode
	text: string
	active: boolean
}

const StatusCard = (props: StatusCardProps) =>
{
	const classes = useStyles();
	return(
		<div className={classes.statusCard}>
			<div className={props.active ? classes.statusIconActive : classes.statusIcon}>
				{props.children ? props.children : ''}
			</div>
			<Typography
				variant='overline'
				className={props.active ? classes.statusTextActive : classes.statusTextInactive}
			>
					{props.text}
			</Typography>
		</div>
	);
}

const SpeechToTextModal = () => 
{
	const classes = useStyles();
	const apollo = useApolloClient();
	const instanceId = window.sessionStorage.getItem('instance-id') ?? '';

	const sttRes = useQuery(queries.speechRecognition, {
		variables: {
			instanceId: instanceId
		}
	});
	const isOpen = sttRes.data?.speechRecognition.dialogOpen;
	const isLoading = sttRes.data?.speechRecognition.loading;
	const isRunning = sttRes.data?.speechRecognition.running;
	const isListening = sttRes.data?.speechRecognition.listening;
	const previewText = sttRes.data?.speechRecognition.previewText;
	const failed = sttRes.data?.speechRecognition.failed;

	const [previousHypothesis, setPreviousHypothesis] = useState('');
	const [addedHypothesis, setAddedHypothesis] = useState('');
	const [baseHypothesis, setBaseHypothesis] = useState('');

	const closePreview = () => {
		apollo.writeQuery({
			query: queries.speechRecognition,
			data: {
				speechRecognition: {
					previewText: '',
					dialogOpen: false
				}
			},
            variables: {
                instanceId: instanceId
            }
		});

		if (isRunning)
			StopSpeechRecognition(apollo, instanceId);
	}

	const retryRecognition = () => {
		setAddedHypothesis('');
		setBaseHypothesis(previewText);
		setPreviousHypothesis('');
		RestartSpeechRecognitionContinuous(apollo, instanceId);
	}

	const applyResult = () => {
		apollo.writeQuery({
			query: queries.speechRecognition,
			data: {
				speechRecognition: {
					previewText: '',
					text: previewText,
					dialogOpen: false
				}
			},
            variables: {
                instanceId: instanceId
            }
		});

		if (isRunning)
			StopSpeechRecognition(apollo, instanceId);
	}

	const getAddedText = (newText: string, oldText: string) => {
		if (newText.length < oldText.length)
			return '';
		const i = [...newText].findIndex((chr, i) => chr !== oldText[i]);
		return newText.substring(i);
	}

	useEffect(
		() => {
			if (isRunning && isListening && !isLoading && previousHypothesis !== previewText)
			{
				const newText = getAddedText(previewText, previousHypothesis);
				setAddedHypothesis(newText);
				setBaseHypothesis(previousHypothesis);
				setPreviousHypothesis(previewText);
			}
			else
			{
				setAddedHypothesis('');
				setBaseHypothesis(previewText);
				setPreviousHypothesis(previewText);
			}
		},
		[previewText]
	);

	const onKeyDown = (event: KeyboardEvent) => {
		if (!!!isOpen || !!isLoading)
			return;
		if (event.key === 'Enter') {
			applyResult();
		}
	}

	useEffect(
		() => {
			document.addEventListener("keydown", onKeyDown);

			return () => {
				document.removeEventListener("keydown", onKeyDown);
			}
		}
	);

	return (<>
		<GlueDialog PaperProps={paper}
			fullWidth={false}
			maxWidth={'lg'}
			open={!!isOpen}
			onClose={closePreview}
		>
			<DialogHeader header={'Speech to text'} closeDialog={closePreview}/>
			<div className={classes.statusRow}>
				<StatusCard active={!failed && isLoading} children={<HourGlassEmptyIcon/>} text="INITIALIZING"/>
				<StatusCard active={!failed && isRunning && !isLoading} children={<SmsIcon/>} text="LISTENING"/>
			</div>
			{isLoading ?
			<div className={classes.loadingIndicator}>
				<LoadingIndicator/>
			</div> :
			<div className={failed ? classes.errorArea : classes.previewArea}>
				<GlueScroll autoScrollToEnd>
				{!failed ?
					<Typography className={classes.previewText}>{baseHypothesis}
					<span className={classes.previewTextHighlighted}>{addedHypothesis}</span></Typography> :
					<div className={classes.errorArea}>
					<Typography align='center' className={classes.errorText}>Unfortunately the speech to text service did not respond. Please try again</Typography>
					</div>
				}
				</GlueScroll>
			</div>
			}
			<DialogActions className={classes.dialogActions}>
				<GlueButton
					width='144px'
					onPointerDown={() => retryRecognition()}
					uiAudioMessage = {cancelaudiomessage}
					uiHoverAudioMessage = {cancelhoveraudiomessage} 
				>
					Retry
				</GlueButton>
				{!failed && <GlueButton
					color="primary"
					width='144px'
					onPointerDown={() => applyResult()}
					uiAudioMessage = {okaudiomessage}
					uiHoverAudioMessage = {okhoveraudiomessage} 
				>
					OK
				</GlueButton>}
			</DialogActions>
		</GlueDialog>
	</>
	);
}

export default SpeechToTextModal;