import React, { useRef, useState } from 'react';
import { makeStyles } from "@material-ui/core";
import GlueButton from '../common/glue-button';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexFlow: 'column',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '32px',
		paddingTop: '64px',
	},

	video: {
		position: 'fixed',
		left: '0%',
		top: '128px',
	},

	buttons: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-around',
	},
}), { name: 'MuiGlueOldTutorialVideoPlayer' });

const OldTutorialVideoPlayer = (props: {
	url: string
	posterUrl: string
	onCompleteTutorial: () => void
}) => {
	const classes = useStyles();
	const videoRef = useRef<HTMLVideoElement>(null);
	const [ paused, setPaused ] = useState(false);

	const setVideoPaused = (value: boolean) => {
		setPaused(value);
		if (videoRef.current != null) {
			if (value === true) {
				videoRef.current.pause();
			}
			else {
				videoRef.current.play();
			}
		}
	}

	return (
		<div className={classes.root}>
			<h1>Intro to Glue</h1>
			<video 
				autoPlay
				playsInline
				ref={videoRef}
				className={classes.video}
				width='100%'
				height='auto'
				poster={props.posterUrl} 
				onEnded={() => setVideoPaused(true)}
			>
				<source src={props.url} type='video/mp4' />
			</video>
			<div className={classes.buttons}>
				<GlueButton color='primary' onPointerDown={() => setVideoPaused(!videoRef.current?.paused)}>
					{paused ? <p>
							Play video
						</p> : <p>
							Pause video
						</p>}
				</GlueButton>
				<GlueButton onPointerDown={props.onCompleteTutorial}>
					Start using Glue
				</GlueButton>
			</div>
		</div>
	)
}

export default OldTutorialVideoPlayer