import { CSSProperties } from "@material-ui/core/styles/withStyles";

export type Type = {
	inputWidth: string
	sortWidth: string
	filterPadding: string
	root: CSSProperties
	listItem: CSSProperties
	listItemThumb: CSSProperties
	tempContentWidth: string
};

const common: Type =
{
	inputWidth: '200px',
	sortWidth: '200px',
	filterPadding: '52px',
	root: {
		width: '856px',
		height: '738px'
	},
	listItem: {
		width: '452px',
		height: '64px',	
	},
	listItemThumb: {
		width: '98px',
		height: '59px',
		flexShrink: 0,
	},
	tempContentWidth: '328px'
};

export const web: Type =
{
	...common
};

export const tablet: Type =
{
	...common,
	inputWidth: '256px',
	sortWidth: '296px',
	filterPadding: '88px',
	root: {
		width: '1294px',
		height: '952px'
	},
	listItem: {
		width: '662px',
		height: '88px',
	},
	listItemThumb: {
		width: '144px',
		height: '80px',
		flexShrink: 0,
	},
	tempContentWidth: '508px'
};