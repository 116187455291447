import React, { useEffect, useState } from 'react';
import queries from '../../graphql/queries';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core';
import LoadingIndicator from './loading-indicator';
import { clickAudio } from './common-vuplex-messages';
import GlueButton from './glue-button';
import MicIcon from '@material-ui/icons/Mic';
import { SetCurrentSpeechRecognitionSinkId, StartSpeechRecognitionContinuous, StopSpeechRecognition } from '../../service/speech-to-text';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
	},
}));

const SpeechToTextButton = (props) =>
{
	const classes = useStyles();
	const apollo = useApolloClient();
	const instanceId = window.sessionStorage.getItem('instance-id') ?? '';

	const sttRes = useQuery(queries.speechRecognition, {
		variables: {
			instanceId: instanceId
		}
	});

	const sinkIsThis = sttRes.data?.speechRecognition.sinkId === props.sinkId;
	const newText = sttRes.data?.speechRecognition.text;
	const sttFinished = !sttRes.data?.speechRecognition.dialogOpen;
	const audiomessage = "Tools/SpeechToTextButton/Press";
	const readyaudiomessage = "Tools/SpeechToTextButton/Ready";
	const hoveraudiomessage = "Tools/SpeechToTextButton/HL";

	const [previousText, setPreviousText] = useState(sttRes.data?.speechRecognition.text);

	const startOperation = async () =>
	{
		console.log('Start speech-to-text operation. InstanceId: ' + instanceId + ' SinkId: ' + props.sinkId);
		SetCurrentSpeechRecognitionSinkId(apollo, instanceId, props.sinkId);
		StartSpeechRecognitionContinuous(apollo, instanceId);
		props.setFocus?.();
		setPreviousText('');
	};

	useEffect(
		() =>
		{
			if (sttFinished && sinkIsThis)
				props.setFocus?.();

			if (newText === previousText ||
				newText === '' ||
				!sinkIsThis)
			{
				return;
			}

			console.log('Write final phrase');
			props.onSpeechChange?.(sttRes.data.speechRecognition.text);
			setPreviousText(sttRes.data.speechRecognition.text);

			clickAudio(readyaudiomessage);
		},
		[apollo, newText, sttFinished, sinkIsThis]
	);

	useEffect(
		() =>
		{
			return () =>
			{
				if (sttRes.data?.speechRecognition.running && sinkIsThis)
				{
					console.log('Unmount and abort current speech-to-text operation. SinkId: ' + props.sinkId);
					StopSpeechRecognition(apollo, instanceId);
				}
			};
		},
		[apollo, props.sinkId]
	);

	return (
		<div className={classes.root}>
			{sinkIsThis && sttRes.data?.speechRecognition.loading && (
				<LoadingIndicator variant='icon'/>
			)}

			<GlueButton
				variant='icon'
				color={props.invertedSpeechButton ? 'invertedSpeechToggle' : 'speechToggle'}
				toggled={sinkIsThis && sttRes.data?.speechRecognition.running && !sttRes.data?.speechRecognition.loading}
				onPointerDown={startOperation}
				uiAudioMessage = {audiomessage}
				uiHoverAudioMessage = {hoveraudiomessage}
			>
			<MicIcon />
			</GlueButton>
		</div>
	)
};

export default SpeechToTextButton;
