import React, { useState} from 'react';
import AppHeader from './common/app-header';
import { makeStyles, ButtonBase } from '@material-ui/core';
import PageSelector from './common/page-selector';
import { useQuery } from '@apollo/react-hooks';
import queries from "../graphql/queries";
import postVuplexMessage from '../service/message-vuplex';
import GlueButton from './common/glue-button';
import { clickAudio, hoverAudio } from './common/common-vuplex-messages';
import SwitchSelector from './common/switch-selector';

const tilesPerPageCount = 8;

const useStyles = makeStyles(theme => ({

	toolbar: {
		display: 'grid',
		gridTemplateColumns: '27% 46% 27%',
		width: '100%',
		height: '100px',
		alignContent: 'center',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '0px 48px',

		'& > *': {
			display: 'flex',
			flexFlow: 'row nowrap',
			justifyContent: 'center',

			'&:last-child': {
				justifyContent: 'flex-end'
			}
		}
	},

	body: {
		display: 'grid',
		gridTemplateColumns: '25% 25% 25% 25%',
		width: '100%',
		padding: '32px 32px',
		justifyContent: 'center',
		alignContent: 'center'
	},

	locationButton: {
		alignContent: 'center',
		justifyContent: 'center',
		width: '296px',
		height: '324px',
		background: theme.palette.background.paper,
		borderRadius: '25px',
		fontSize: '32px',
		fontWeight: 600,
		padding: '8px 8px',
		margin: '12px',
		textTransform: 'none',
		overflow: 'hidden',
		'&:hover' : {
			filter: 'brightness(1.25)'
		}
	},

	othersToggle: {
		color: '#bfc0c2',
		fontSize: '24px'
	},

	regroupButton: {
		width: '200px',
	},

	noLocationsMessage: {
		height: '100%',
		display: 'flex',
		flexFlow: 'column nowrap',
		justifyContent: 'center',
		alignContent: 'center',

		'& > h1': {
			margin: 'auto',
		}	
	}	
}));

const GoToLocation = (location, group) =>
{
	postVuplexMessage('Go to location', {name: location, group: group});
};

const Regroup = ()=>
{
	postVuplexMessage('Regroup');
};

const LocationsApp = (props) =>
{
	postVuplexMessage('Request locations');

	const classes = useStyles();
	const { data } = useQuery(queries.locations, {variables: {}});

	const title = "Locations";

	const [group, setGroup] = useState(false);

	const [page, setPage] = useState(0);

	let locations = data && data.locations ? data.locations.names.sort() : [];

	const tilesTotalCount = locations.length;
	const pageCount = tilesTotalCount > 0 ? Math.ceil(tilesTotalCount / tilesPerPageCount) : 0;

	const audiomessage = "Apps/Locations/Regroup/Press"; 
	const hoveraudiomessage = "Apps/Locations/Regroup/HL";
	const locaudiomessage = "Apps/Locations/Location/Press"; 
	const lochoveraudiomessage = "Apps/Locations/Location/HL";
	const grouponaudiomessage = "Apps/Locations/Group/Toggle/On"; 
	const groupoffaudiomessage = "Apps/Locations/Group/Toggle/Off"; 
	const groupaudiomessage = group ? groupoffaudiomessage : grouponaudiomessage;
	const grouphoveraudiomessage = "Apps/Locations/Group/Tooggle/HL";

	const locationButton = (name) =>
	{
		return (
			<ButtonBase
				key={name}
				className={classes.locationButton} 
				onPointerDown={() => { GoToLocation(name, group); clickAudio(locaudiomessage) }}
				onPointerEnter={() => {hoverAudio(lochoveraudiomessage)}}
			>
				{name}
			</ButtonBase>
		);
	};

	const locationButtons = () =>
	{
		if (!data || !data.locations)
			return null;

		let startIndex = page * tilesPerPageCount;

		return (<>
			{locations.slice(startIndex,startIndex+tilesPerPageCount).map((location, i) => (locationButton(location))) }
		</>)
	};

	function toggleOthers(checked)
	{
		setGroup(!group);
	}

	const othersToggle = () =>
	{
		return (
			<div className={classes.othersToggle}>
				<SwitchSelector 
					toggle
					onChange={toggleOthers} 
					onPointerDown={() => { clickAudio(groupaudiomessage) }}
					onPointerEnter={() => {hoverAudio(grouphoveraudiomessage)}}
					offText={'Go by myself'}
					onText={'Bring everyone'}
					checked={group}
				/>
			</div>
		);
	};

	const regroupButton = 
	(
		<GlueButton className={classes.regroupButton}
			onPointerDown={() => { Regroup() }}
			uiAudioMessage = {audiomessage}
			uiHoverAudioMessage = {hoveraudiomessage}
		>
			Regroup
		</GlueButton>
	);

	return (<>
		{pageCount > 0 ?
		(<>
			<AppHeader className={classes.root} children={regroupButton} title={title} message={""} />
			<div className={classes.toolbar}>
				<div></div>

				<div>{othersToggle()}</div>

				<div className={classes.toolbarGroupRight}>
					<PageSelector current={page} pageCount={pageCount} onPageChange={setPage}/>
				</div>
			</div>
			<div className={classes.body}>
				{locationButtons()}
			</div>
		</>) : (<>
			<AppHeader className={classes.root} children={regroupButton} title={title} message={""} />
			<div className={classes.noLocationsMessage}>
				<h1>{data == null ? "Retrieving location marker data..." : "This space has no location markers"}</h1>
			</div>
		</>)}
	</>);
};

export default LocationsApp;
