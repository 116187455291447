import { ApolloClient, ApolloQueryResult, QueryResult } from "@apollo/client";
import { setTrackVersionPointer } from "../component/space-asset/space-asset-common";
import { Asset, BackendInfo, Room, SessionData, SessionStartStatus } from "../graphql/types-generated";
import mutations from "../graphql/mutations";
import queries from "../graphql/queries";
import postVuplexMessage, { registerMessageListener, unregisterMessageListener } from "../service/message-vuplex";
import { Maybe } from "graphql/jsutils/Maybe";

type SessionStartStatusMessage = {
	serverKey: string
	status: SessionStartStatus
}

export const joinSpace = (room: Room, sessionData: SessionData, takeEveryone: boolean) => 
{
	postVuplexMessage('Join space', { room: room, sessionData: sessionData, takeEveryone: takeEveryone });
};

const assetHasProductionPointer = (asset: Asset) =>
{
	const productionVersion = asset.versionPointers && asset.versionPointers.find((item) => item.name === "production");
	return !!productionVersion;
}

const setTrackedVersionPointer = async (apollo: ApolloClient<unknown>, roomId: string, pointerName: string) =>
{
	await setTrackVersionPointer(apollo, roomId, pointerName);
}

export const setSpaceThumbnail = async (apollo: ApolloClient<unknown>, roomId: string, thumbnailUrl: string) =>
{
	await apollo.mutate({
		mutation: mutations.updateSpaceInfo,
		variables: {
			roomId: roomId,
			thumbnailUrl: thumbnailUrl
		}
	});
}

export const createSpace = async (apollo: ApolloClient<unknown>, name: string, teamId: string, asset: Asset): Promise<Room> =>
{
	if (!assetHasProductionPointer(asset)) {
		throw new Error(`Asset ${asset.assetId} has no production pointer`);
	}

	const createRoomRes = await apollo.mutate({
		mutation: mutations.createRoom,
		variables: {
			name: name,
			teamId: teamId,
			assetName: asset.name,
			assetId: asset.assetId,
			sceneUrl: undefined,
			sceneName: asset.sceneName
		}
	});

	const room = createRoomRes.data.createRoom;
	await setTrackedVersionPointer(apollo, room.roomId, 'production');
	return room;
}

export const isAtHome = (currentSpaceServerKeyResult: QueryResult<{ currentSpaceServerKey: string }>) =>
{
	const atHome = !currentSpaceServerKeyResult.data?.currentSpaceServerKey || currentSpaceServerKeyResult.data.currentSpaceServerKey.length === 0;
	return atHome
};

export const requestCachedSpaceTemplates = () =>
{
	postVuplexMessage('Space.GetCachedTemplateUrls');
};

export const refreshSpaces = async (apollo: ApolloClient<unknown>) =>
{
	requestCachedSpaceTemplates();
	await apollo.refetchQueries({
		include: [ queries.myRooms, queries.sessionInfo ]
	});
};

let sessionStartInProgress = false;

export const startSession = (serverKey: string, onStatus: (status: string) => void, abortSignal?: AbortSignal): Promise<SessionData> => new Promise((resolve, reject) => {
	if (sessionStartInProgress) {
		reject(new Error('Another session start already in progress'));
		return;
	}

	sessionStartInProgress = true;

	registerMessageListener('Session.Status', (msg: { args: SessionStartStatusMessage }) => {
		if (abortSignal?.aborted) {
			return;
		}

		if (msg.args.serverKey !== serverKey) return;

		const status = msg.args.status;
		if (!!status.error) {
			unregisterMessageListener('Session.Status');
			sessionStartInProgress = false;
			reject(new Error(status.error.errorDescription));
			return;
		}

		if (!!status.data) {
			unregisterMessageListener('Session.Status');
			sessionStartInProgress = false;
			resolve(status.data);
			return;
		}

		if (!!status.message) {
			onStatus(status.message);
		}
	});

	postVuplexMessage('Session.Start', serverKey);

	if (!!abortSignal) {
		abortSignal.addEventListener('abort', () => {
			registerMessageListener('Session.CancelResponse', (msg: { args: { serverKey: string } }) => {
				if (msg.args.serverKey !== serverKey) return;

				console.log(`Session start cancelled for serverKey ${serverKey}`);

				sessionStartInProgress = false;
				unregisterMessageListener('Session.CancelResponse');
				reject(abortSignal.reason);
			});
			unregisterMessageListener('Session.Status');
			postVuplexMessage('Session.Cancel', serverKey);
		}, { once: true });
	}
});

export const forceCloseSession = async (backendInfoRes: ApolloQueryResult<BackendInfo>, serverKey: string, status?: Maybe<string>) =>
{
	const request = {
		serverKey: serverKey,
		forceClose: status === 'Starting' ? true : false
	}

	await fetch(backendInfoRes.data.backendUri + '/api/session-services-closing', {
		method: 'POST',
		cache: 'no-cache',
		body: JSON.stringify(request),
		headers: {
			'Authorization': 'Bearer ' + backendInfoRes.data.backendToken,
			'Content-Type': 'application/json',
			Accept: 'application/json'
		}
	})
};
