import EditorHistory from './editor-history';
import { Note } from '../component/note/note-types';

export const colorPresets = [
	'#f2f2f2',
	'#ffea63',
	'#ffab66',
	'#ff8787',
	'#fa98d6',
	'#8c90ff',
	'#70dafa',
	'#a5f26d'
];

export const fontSizes = (
	[
		{id: "24", name: "24", value: 24},
		{id: "36", name: "36", value: 36},
		{id: "48", name: "48", value: 48},
		{id: "64", name: "64", value: 64},
		{id: "96", name: "96", value: 96},
		{id: "136", name: "136", value: 136},
		{id: "200", name: "200", value: 200},
	]
);

export const fontFamilies = [
	{id: 'Source Sans Pro', name: 'Sans', value: 'Source Sans Pro'},
	{id: 'Source Serif Pro', name: 'Serif', value: 'Source Serif Pro'},
	{id: 'Sriracha', name: 'Handwritten', value: 'Sriracha'}
];

// Dimensions in centimeters, as shown in the UI
export const noteSizes = [
	{id: "squareSmall", name: "Square - Small", width: 15, height: 15},
	{id: "squareMedium", name: "Square - Medium", width: 30, height: 30},
	{id: "squareLarge", name: "Square - Large", width: 60, height: 60},
	{id: "a6Landscape", name: "A6 - Landscape", width: 14.8, height: 10.5},
	{id: "a4Landscape", name: "A4 - Landscape", width: 29.7, height: 21},
	{id: "a1Landscape", name: "A1 - Landscape", width: 84.1, height: 59.4},
	{id: "a6Portrait", name: "A6 - Portrait", width: 10.5, height: 14.8},
	{id: "a4Portrait", name: "A4 - Portrait", width: 21, height: 29.7},
	{id: "a1Portrait", name: "A1 - Portrait", width: 59.4, height: 84.1},
	{id: "169LandscapeSmall", name: "16 x 9 - Landscape - Small", width: 45.1, height: 25.4},
	{id: "169LandscapeMedium", name: "16 x 9 - Landscape - Medium", width: 67.7, height: 38.1},
	{id: "169LandscapeLarge", name: "16 x 9 - Landscape - Large", width: 90.3, height: 50.8},
	{id: "169PortraitSmall", name: "16 x 9 - Portrait - Small", width: 25.4, height: 45.1},
	{id: "169PortraitMedium", name: "16 x 9 - Portrait - Medium", width: 38.1, height: 67.7},
	{id: "169PortraitLarge", name: "16 x 9 - Portrait - Large", width: 50.8, height: 90.3},
];

const defaultNote : Note = {
	fontFamily: 'Source Sans Pro',
	fontSize: fontSizes[3].value,
	horizontalTextAlignment: 'left',
	verticalTextAlignment: 'flex-start',
	noteColor: colorPresets[1],
	fontColor: '#000',
	fontWeight: 'lighter',
	fontStyle: '',
	text: '',
	width: 15,
	height: 15,
	instanceId: 1 // This is a bit sus but client also assigns 1 by default so...
};

let initialized = false;
let editHistory: EditorHistory<Note> | undefined = undefined;
let textTimeout: NodeJS.Timeout | undefined = undefined;
let delayedText: string | undefined = undefined;

export const editorInitialized = () =>
{
	return initialized;
}

const clientStateToOSState = (clientState?: Note) => {
	let state;
	if (!clientState)
	{
		state = defaultNote;
	}
	else
	{
		state = clientState;
	}

	return state;
}

export const initializeEditor = (clientState?: Note) => {
	const initialState = clientStateToOSState(clientState);

	editHistory = new EditorHistory(initialState);
	initialized = true;
	textTimeout = undefined;
	delayedText = undefined;
}

export const deinitializeEditor = () => {
	initialized = false;
	editHistory = undefined;
	textTimeout = undefined;
	delayedText = undefined;
}

export const reloadEditor = (clientState?: Note) => {
	clearTimeout(textTimeout);
	textTimeout = undefined;
	delayedText = undefined;
	
	editHistory = new EditorHistory(clientState ? clientStateToOSState(clientState) : defaultNote);
	dispatchOnNoteChangedEvent({});
	dispatchNoteInputRefreshEvent();
}

const dispatchOnNoteChangedEvent = (data : Partial<Note>) =>
{
	const event = new CustomEvent('onNoteChanged', { detail: data });
	window.dispatchEvent(event);
}

// This will force refresh of input field to get MUI to set the input area height correctly
const dispatchNoteInputRefreshEvent = () =>
{
	const event = new CustomEvent('refreshNoteInput');
	window.dispatchEvent(event);
}

export const getNoteProperty = (prop: keyof Note) => {
	if (!editHistory)
		return null;

	return editHistory.currentValue(prop);
}

const setNotePropertyInternal = (prop: keyof Note, value: Note[keyof Note]) => {
	if (!editHistory) {
		throw new Error('Note editor has not been initialized');
	}
	if (editHistory.currentValue(prop) !== value)
	{
		editHistory.pushValue(prop, value);
		dispatchOnNoteChangedEvent({ [prop]: value });
	}
}

export const setNoteProperty = (prop: keyof Note, value: Note[keyof Note]) => {
	if (prop === 'text')
	{
		throw new Error('Trying to set note text using \'setNoteProperty\', use \'setNoteText\' instead!');
	}

	setNotePropertyInternal(prop, value);
}

export const setNotePropertyMultiple = (props: Partial<Note>) => {
	if (!editHistory) {
		throw new Error('Note editor has not been initialized');
	}
	if (props.text)
	{
		throw new Error('Trying to set note text using \'setNotePropertyMultiple\', use \'setNoteText\' instead!');
	}

	let changed = false;
	for (const key in props)
	{
		const prop = key as keyof Note;
		changed ||= editHistory.currentValue(prop) !== props[prop];
	}
	if (changed)
	{
		editHistory.pushValueMultiple(props);
		dispatchOnNoteChangedEvent(props);
	}
}

export const setNoteText = (newText: string) => {
	if (textTimeout)
	{
		clearTimeout(textTimeout);
		textTimeout = undefined;
		delayedText = undefined;
	}

	setNotePropertyInternal('text', newText);
}

// Sets note text only after delay (in ms) has completed
// Used so that user doesn't have to undo single characters at a time
export const setNoteTextDelayed = (newText: string, delay: number) => {
	if (textTimeout)
	{
		clearTimeout(textTimeout);
	}
	delayedText = newText.slice(); // Strings are refs so need a copy
	textTimeout = setTimeout(setNoteText, delay, delayedText);
}

export const hasDelayedText = () => !!textTimeout && !!delayedText;

export const commitDelayedText = () => {
	if (!textTimeout || !delayedText)
		return;

	setNoteText(delayedText);
}

export const resetNote = () => {
	if (!editHistory)
		return;

	editHistory.pushState(defaultNote);
	dispatchOnNoteChangedEvent(defaultNote);
}

export const getCurrentNoteShape = () => {
	if (!editHistory)
		return null;

	const dimensions = editHistory.currentValueMultiple(['width', 'height']);
	const matchingShape = noteSizes.find(shape => shape.width === dimensions.width && shape.height === dimensions.height);
	if (!matchingShape)
	{
		return "Custom";
	}
	else return matchingShape.id;
}

export const setNoteShapePreset = (id: string) => {
	const foundShape = noteSizes.find(shape => shape.id === id);
	if (foundShape)
	{
		const changedValues = {
			width: foundShape.width,
			height: foundShape.height
		};

		setNotePropertyMultiple(changedValues);
		dispatchNoteInputRefreshEvent();
	}
}

export const setFont = (id: string) => {
	const foundFont = fontFamilies.find(font => font.id === id);
	if (foundFont)
	{
		setNoteProperty('fontFamily', foundFont.value);
	}
}

export const setFontSize = (id: string) => {
	const foundFontSize = fontSizes.find(size => size.id === id);
	if (foundFontSize)
	{
		setNoteProperty('fontSize', foundFontSize.value);
		dispatchNoteInputRefreshEvent();
	}
}

export const getCurrentFontSize = () => {
	if (!editHistory)
		return null;

	const fontSize = editHistory.currentValue('fontSize');
	const matchingSize = fontSizes.find(size => size.value === fontSize);
	if (!matchingSize)
	{
		return "Custom";
	}
	else return matchingSize.id;
}

export const getCurrentNote = () => {
	if (!editHistory)
		return null;

	return editHistory.currentState();
}

export const getCurrentIndex = () =>
{
	if (!editHistory) {
		throw new Error('Note editor has not been initialized');
	}
	return editHistory.length() - 1;
}

export const revertNoteHistory = (pointInHistory: number) =>
{
	if (!editHistory) {
		throw new Error('Note editor has not been initialized');
	}
	editHistory.revertToPoint(pointInHistory);
	dispatchOnNoteChangedEvent({});
}

export const canUndo = () => {
	if (!editHistory)
		return false;
	
	return editHistory.currentPosition() > 0;
}

export const undo = () => {
	if (editHistory)
	{
		editHistory.undo();
		dispatchOnNoteChangedEvent({}); 
		dispatchNoteInputRefreshEvent();
	}
}

export const canRedo = () => {
	if (!editHistory)
		return false;
	
	return editHistory.currentPosition() < editHistory.length() - 1;
}

export const redo = () => {
	if (editHistory)
	{
		editHistory.redo();
		dispatchOnNoteChangedEvent({});
		dispatchNoteInputRefreshEvent();
	}
}