import { CSSProperties } from "@material-ui/core/styles/withStyles";

export type Type = {
	preview: CSSProperties
};

const common: Type =
{
	preview: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginLeft: 'auto',
		marginRight: 'auto'
	},
};

export const web: Type =
{
	...common,

	preview: {
		flex: '1 1 auto',

		display: 'flex',
		flexFlow: 'column nowrap',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',

		'& > canvas': {
			flex: '1 1 auto',
			objectFit: 'contain',
			maxHeight: '100%',
			maxWidth: '100%'
		}
	},
};

export const tablet: Type =
{
	...common
};
