export type Type = {
	rowHeight: string
	dividerThickness: string
};

const common: Type =
{
	rowHeight: '88px',
	dividerThickness: '2px'
};

export const web: Type =
{
	...common,

	rowHeight: '52px',
	dividerThickness: '1px'
	
};

export const tablet: Type =
{
	...common,
};
