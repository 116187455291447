import React, { useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import queries from '../graphql/queries';

const canvasHeight = 8; // px
const canvasWidth = 200; // Normalize using this value! Actual size may vary.
const activityAmplitudeMax = 80; // Use to normalize the activity value

// The mic is a global resource anyway
let currentLevel = 0;

const useStyles = makeStyles((theme) => ({
	root: (props) => ({
		width: props.widthSize,
    }),

	bar: {
		display: 'block',
		height: canvasHeight + 'px',
		width: '100%',
		borderRadius: '4px',
	}
}));

const drawBar = (canvasElem, value, bgColor) =>
{
	const ctx = canvasElem.getContext('2d');

	let canvasValue = value * canvasWidth;

	ctx.fillStyle = bgColor;
	ctx.fillRect(0, 0, canvasWidth, canvasHeight);

	ctx.fillStyle = '#00B55E';
	ctx.fillRect(0, 0, canvasValue, canvasHeight);
};

const MicActivityBar = (props) =>
{
	const classes = useStyles(props);
	const canvasRef = useRef(null);

	const micEnabledResult = useQuery(queries.microphoneEnabled);
	const micActivityResult = useQuery(queries.microphoneActivity);

	useEffect(() => {
		if (!canvasRef.current)
			return;

		const normalizedLevel = Number(micActivityResult.data?.microphoneActivity) / activityAmplitudeMax;
		const targetLevel = Number.isFinite(normalizedLevel) ? Math.max(0, Math.min(normalizedLevel, 1)) : 0;
		currentLevel = targetLevel;
		drawBar(canvasRef.current, currentLevel, props.bgColor);
	}, [micEnabledResult, micActivityResult, props.bgColor]);

	return (
		<div className={classes.root}>
			{(micEnabledResult.data?.microphoneEnabled || props.alwaysVisible) &&
				<canvas className={classes.bar} ref={canvasRef} height={canvasHeight} width={canvasWidth} />
			}
		</div>
	);
};

export default MicActivityBar;
