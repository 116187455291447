import React from 'react';

import { makeStyles, ButtonBase } from '@material-ui/core';

import TuneIcon from '@material-ui/icons/Tune';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles((theme) => ({
	editor: {
		...theme.custom.contentBase,
		display: 'flex',
		height: '100%',
		overflow: 'hidden',
		paddingTop: theme.glueSpacing('m'),
		paddingLeft: theme.glueSpacing('s'),
		paddingBottom: theme.glueSpacing('m'),
	},

	tabContent: {
		display: 'flex',
	},

	preview: {
		...theme.custom.glueEditor.preview,
	},

	confirmIndicatorWrapper: {
		display: 'flex',
		position: 'absolute',
		background: '#00000080',
		left: '0',
		right: '0',
		top: '0',
		bottom: '0',
		alignItems: 'center',
		justifyContent: 'center',
	},

	confirmIndicator: {
		backgroundColor: 'transparent',
		height: '300px',
		width: '300px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-around',
	},

	divider: {
		backgroundColor: theme.palette.secondary.dark,
		width: '4px',
		minWidth: '4px',
		minHeight: '800px',
		position: 'relative'
	},

	// No web styling for this yet! And I dread for whoever has to do it
	drawerControls: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: theme.glueSpacing('m'),
		position: 'absolute',
		left: 0,
		backgroundColor: theme.palette.secondary.dark,
		width: '64px',
		height: '64px',
		margin: props => props.floatControls ? '8px' : '0',
		borderRadius: props => props.floatControls ? '32px' : '0 32px 32px 0',
		'&:hover': {
			filter: 'brightness(1.25)'
		},
		'&:active': {
			filter: 'brightness(1.50)'
		},
		zIndex: 1
	},

	colorIndicator: (props) => ({
		width: '128px',
		height: '64px',
		background: props.color,
		position: 'absolute',
		left: '96px',
		top: '120px'
	})
}), { name: 'MuiGlueEditor' });

const GlueEditor = (props) =>
{
	const classes = useStyles(props);

	const DrawerControls = (props) =>
	{
		return (
			<>
				{props.open ? (
					<div className={classes.divider}>
						<ButtonBase className={classes.drawerControls} onPointerDown={props.onToggleOpen}>
							{props.isMenu ? <MenuIcon/> : <TuneIcon/>}
						</ButtonBase>
					</div>
				) : (
					<ButtonBase className={classes.drawerControls} onPointerDown={props.onToggleOpen}>
						{props.isMenu ? <MenuIcon/> : <TuneIcon/>}
					</ButtonBase>
				)} 
			</>
		);
	}

	return (
		<div className={classes.editor}>
			{props.open && 
				(<div className={classes.tabContent}>
					{props.children}
				</div>)}
			{props.showDrawerControls && 
				(<DrawerControls
					isMenu={props.isMenu}
					open={props.open}
					onToggleOpen={props.onToggleOpen}
				/>)}
			<div className={classes.preview}>
				{props.previewContent}
			</div>
		</div>
	);
}

export default GlueEditor;