import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardMedia, CardActionArea } from '@material-ui/core';
import { hoverAudio } from '../common/common-vuplex-messages';

type FeatureCardProps = {
	image?: string
	toggled?: boolean
	onPointerDown?: (e: React.PointerEvent) => void

};

const useStyles = makeStyles((theme) => ({
	root: (props: FeatureCardProps) => ({
		borderRadius: '4px',
		...theme.custom.selectOutline(props.toggled, { borderRadius: '4px' }),
	}),

	cardActionArea: {
		width: theme.custom.avatarEditor.featureTileSize,
		height: theme.custom.avatarEditor.featureTileSize,
		backgroundImage: `linear-gradient(0, #63686e, #454b52)`
	},

	headerRoot: {
		paddingBottom: 0
	},

	headerTitle: {
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden'
	},

	contentRoot: {
		paddingBottom: 0
	},

	media: {
		height: '100%',
		width: '100%',
	}
}));

const FeatureCard = (props: FeatureCardProps) => {
	const classes = useStyles(props);
	const hoveraudiomessage = "Apps/Avatar/PresetCard/HL";

	return (
		<Card className={classes.root}>
			<CardActionArea className={classes.cardActionArea}>
				<CardMedia
					onPointerDown={props.onPointerDown}
					onPointerEnter={() => {hoverAudio(hoveraudiomessage)}} 
					classes={{ root: classes.media }}
					image={props.image}
				/>
			</CardActionArea>
		</Card>
	);
};

export default FeatureCard;