import React, { useRef, useState } from 'react';
import { Theme, makeStyles, Checkbox } from '@material-ui/core';
import { ApolloClient, ApolloError, ServerError, useApolloClient, useQuery } from '@apollo/react-hooks';

import queries from '../../graphql/queries';
import mutations from '../../graphql/mutations';
import GlueButton from '../common/glue-button';
import WebInputfield from '../../standalone-web/common/web-inputfield';
import SpeechToTextButton from '../common/speech-to-text-button';
import GlueInputfield from '../common/glue-inputfield';
import InfoDialogTemplate from '../common/info-dialog-template';
import { usePromptDialogContext } from '../../util/prompt-dialog-context';

import GlueDropdown from '../common/glue-dropdown';
import { useUserContext } from "../../util/user-context";
import { orgUserSelection } from '../../util/org-utils';

import { logOut, validateEmail } from '../../util/app-utils';
import errorImage from '../../images/ErrorIcon_Web.png';
import GeneralDialogTemplate from '../common/general-dialog-template'
import LoadingIndicator from '../common/loading-indicator';
import { Organization, Team } from '../../graphql/types-generated';

const messageLengthMax = 200;

type AddOrgMemberStyleProps = {
	web: boolean
}

type EmailValidationResult = {
	verdict: string,
	errorMessage?: string,
	warningMessage?: string
}

const useStyles = makeStyles<Theme, AddOrgMemberStyleProps>((theme) => ({
	root: ({web}) => ({
		width: web ? '400px' : '568px',
		display: 'flex',
		flexDirection: 'column',
		gap: theme.glueSpacing('l'),
		overflow: 'hidden',
	}),

	header: {
		height: theme.custom.errorDialog.header.height,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center'
	},

	body: {
		padding: `0 ${theme.glueSpacing('l')}`,
		display: 'flex',
		flexDirection: 'column',
		gap: theme.glueSpacing('m')
	},

	inputArea: {
		display: 'flex',
		flexFlow: 'column',
		gap: theme.glueSpacing('xs'),

		'& p:first-child': {
			opacity: '60%'
		},
	},

	messageAreaHeader: {
		display: 'flex',
		justifyContent: 'space-between',
		'& p': {
			opacity: '60%'
		},
	},

	messageAreaText: {
		position: 'relative'
	},

	speechToTextButtonContainer: {
		position: 'absolute',
		bottom: '12px',
		right: '12px'
	},

	mainActionButtons: {
		display: 'flex',
		justifyContent: 'center',
		gap: theme.glueSpacing('m'),
	},

	checkboxArea: {
		padding: `${theme.glueSpacing('s')} 0`,
		display: 'flex',
		flexFlow: 'column',
		gap: theme.glueSpacing('m'),
	},

	checkbox: {
		display: 'flex',
		gap: theme.glueSpacing('m'),
		alignItems: 'center',
	},

	warningText: {
		color: theme.palette.warning?.main
	},

	errorText: {
		color: theme.palette.error?.main
	},
}));

const inviteMessageSTTSinkId = 'OrgMemberInviteMessage';

const checkCanInvite = async (mail: string, orgId: string, apollo: ApolloClient<unknown>): Promise<EmailValidationResult> =>
{
	if (!mail)
	{
		return { verdict: 'Invalid', errorMessage: 'Email address cannot be empty' };
	}

	const orgInfoResult = await apollo.query<{ getOrg: Organization }>({
		query: queries.getOrg,
		variables: {
			orgId: orgId
		}
	}).catch(error => console.error(`Error getting org info: ${error}`));

	if (!orgInfoResult)
	{
		return { verdict: 'Invalid', errorMessage: 'Organization not found, please contact Glue support' };
	}

	const orgMembers = orgInfoResult.data.getOrg.members ?? [];

	if (orgMembers.some((member) => member?.email === mail))
	{
		return { verdict: 'Invalid', errorMessage: `${mail} is already a member in this organization` };
	}

	const validationResult = await validateEmail(apollo, mail, 'orgMemberInvite');
	return {
		verdict: validationResult.verdict,
		errorMessage: validationResult.verdict === 'Invalid' ? 'Please enter a valid email address' : undefined,
		warningMessage: validationResult.verdict === 'Risky' ? (!!validationResult.suggestion ? `Did you mean "${validationResult.suggestion}"?` : 'Please confirm that the email address is valid and deliverable.') : undefined
	};
}

const AddOrgMemberDialogContent = (props: {
	web: boolean
	orgId: string
	onClose: () => void
	refetch: () => void
}) =>
{
	const classes = useStyles({ web: props.web });
	const apollo = useApolloClient();
	const user = useUserContext();
	const { addDialog } = usePromptDialogContext();

	const [ memberRole, setMemberRole ] = useState<string | number>('member');
	const [ selectedTeams, setSelectedTeams ] = useState<(string | number)[]>([]);

	const teamsRes = useQuery<{ getOrgTeams: Team[] }>(queries.getOrgTeams, {
		skip: !props.orgId,
		variables: {
			orgId: props.orgId
		}
	});

	const teams: {id: string | number, name: string}[] = teamsRes.data?.getOrgTeams.map(
		(team) => ({
			id: team.teamId,
			name: team.name ?? ''
		})
	) ?? [];

	const inviteMessageInputRef = useRef<HTMLInputElement>();

	const [memberEmail, setMemberEmail] = useState('');
	const [invitationMessage, setInvitationMessage] = useState('');
	const [validationResult, setValidationResult] = useState<EmailValidationResult | null>(null);
	const [addWithoutInvite, setAddWithoutInvite] = useState(false);
	const [keepInviting, setKeepInviting] = useState(false);
	const [loading, setLoading] = useState(false);

	const onChangeMemberEmail = (value: string) => {
		setMemberEmail(value);
		setValidationResult(null);
	};

	const showInviteErrorDialog = (message: string) => 
	{
		addDialog(
			<GeneralDialogTemplate
				header={'Invitation failed'}
				message={message}
				image={errorImage}
				callbacks={[
					{ label: 'Later', callback: () => null },
					{ label: 'Log out', color: 'primary', callback: () => logOut() }
				]}
			/>
		);
	}

	const verificationErrorMessage = (
		<p>
			For the verification to come into effect,
			<br />
			you&apos;ll need to log out and log in again.
		</p>
	);

	const showVerificationErrorDialog = () =>
	{
		addDialog(
			<GeneralDialogTemplate
				header={'Have you verified your email?'}
				body={verificationErrorMessage}
				image={errorImage}
				callbacks={[
					{ label: 'Later', callback: () => null },
					{ label: 'Log out', color: 'primary', callback: () => logOut() }
				]}
			/>
		);
	}

	const invite = async (email: string) =>
	{
		if (selectedTeams.length === 0)
		{
			console.error("No teams selected");
			return;
		}

		await apollo.mutate({
			mutation: mutations.inviteOrgMembers,
			variables: {
				orgId: props.orgId,
				memberEmails: [email],
				memberRole: memberRole,
				invitationMessage: invitationMessage ?? '',
				teams: selectedTeams,
				createMemberWithoutInvitation: addWithoutInvite ?? false,
			},
		}).then(result => {
			addDialog(<InfoDialogTemplate
				header={'Success!'}
				message={`An invitation has been sent to ${email}`}
			/>);
			props.refetch();
			if (!keepInviting)
			{
				props.onClose();
			}
			onChangeMemberEmail('');
		}).catch((error: ApolloError) => {
			if (error.networkError && (error.networkError as ServerError).statusCode === 422)
			{
				showVerificationErrorDialog();
			}
			else
			{
				showInviteErrorDialog(error.message);
			}
		});
	}

	const tryInvite = async () => 
	{
		setLoading(true);
		const cleanEmail = memberEmail.toLowerCase().trim();
		const validation = await checkCanInvite(cleanEmail, props.orgId, apollo);

		// If user has already been warned before, they can ignore the warning and invite anyway
		const canInvite = validation.verdict === 'Valid' || (validation.verdict === 'Risky' && !!validationResult);
		setValidationResult(validation);
		if (canInvite)
		{
			await invite(cleanEmail);
		}

		setLoading(false);
	}

	const onInviteMessageSpeechChange = (text: string, isHypothesis: boolean) => {
		if (!isHypothesis) {
			setInvitationMessage(invitationMessage + text);
		}
	};

	const emailInputFieldCommonOptions = {
		maxLength: 254,
		simpleInput: true,
		showClearButton: true,
		width: '100%',
		onChange: onChangeMemberEmail,
		onClear: () => onChangeMemberEmail(''),
		value: memberEmail,
		error: !!validationResult && validationResult.verdict === 'Invalid',
		warning: !!validationResult && validationResult.verdict === 'Risky',
		placeholder: "Enter an email address"
	};

	const inviteMessageInputFieldCommonOptions = {
		simpleInput: true,
		value: invitationMessage,
		onChange: setInvitationMessage,
		multiline: true,
		width: '100%',
		rows: 4,
		maxLength: messageLengthMax,
		placeholder: "Enter a message to be sent with the invitation"
	};

	const toggleTeamAdd = (value: string | number, checked: boolean) =>
	{
		if (checked)
		{
			setSelectedTeams([...selectedTeams, value]);
		}
		else
		{
			const newArray = selectedTeams.filter(item => item !== value)
			setSelectedTeams(newArray)
		}
	}

	const errorWarningText = () => {
		if (!validationResult || validationResult.verdict === 'Valid')
			return null;

		if (validationResult.verdict === 'Invalid')
		{
			return (
				<p className={classes.errorText}>{validationResult.errorMessage}</p>
			)
		}
		else if (validationResult.verdict === 'Risky')
		{
			return (
				<p className={classes.warningText}>{validationResult.warningMessage}</p>
			)
		}
		else return null;
	}

	const selectTeamsLabel = selectedTeams.length === 0 ? "Select team(s)" : (selectedTeams.length > 1 ? `${selectedTeams.length} teams selected` : teams.find(team => team.id === selectedTeams[0])?.name);

	return(
		<div className={classes.root}>
			<div className={classes.header}>
				<h2>Invite a new user</h2>
			</div>
			<div className={classes.body}>
				<div className={classes.inputArea}>
					<p>Email address</p>
					{props.web ? (
						<WebInputfield {...emailInputFieldCommonOptions} />
					) : (
						<GlueInputfield {...emailInputFieldCommonOptions} />
					)}
					{errorWarningText()}
				</div>

				<div className={classes.inputArea}>
					<p>Assign a role on invite</p>
					<GlueDropdown
						width={'100%'}
						items={orgUserSelection}
						value={memberRole}
						onChange={(id, checked) => !!checked && setMemberRole(id)}
					/>
				</div>

				<div className={classes.inputArea}>
					<p>Invite to teams (Select at least 1)</p>
					<GlueDropdown
						label={selectTeamsLabel}
						multiple
						width={'100%'}
						items={teams}
						onChange={(id, checked) => toggleTeamAdd(id, checked)}
					/>
				</div>

				<div className={classes.inputArea}>
					<div className={classes.messageAreaHeader}>
						<p>Invitation message (optional)</p>
						<p>{invitationMessage.length}/{messageLengthMax}</p>
					</div>

					<div className={classes.messageAreaText}>
						{props.web ? (
							<WebInputfield {...inviteMessageInputFieldCommonOptions} />
						) : (<>
							<GlueInputfield
								{...inviteMessageInputFieldCommonOptions}
								sinkId={inviteMessageSTTSinkId}
								ref={inviteMessageInputRef}
							/>

							<div className={classes.speechToTextButtonContainer}>
								<SpeechToTextButton
									sinkId={inviteMessageSTTSinkId}
									onSpeechChange={onInviteMessageSpeechChange}
									setFocus={() => inviteMessageInputRef.current?.focus()}
								/>
							</div>
						</>)}
					</div>
				</div>

				<div className={classes.checkboxArea}>
					<div className={classes.checkbox}>
						<Checkbox checked={keepInviting} onChange={(e, checked) => setKeepInviting(checked)} />
						<p>Keep dialog open to add more users</p>
					</div>

					{user.administrator && (
						<div className={classes.checkbox}>
							<Checkbox checked={addWithoutInvite} onChange={(e, checked) => setAddWithoutInvite(checked)} />
							<p>Add without invitation</p>
						</div>
					)}
				</div>
			</div>

			<div className={classes.mainActionButtons}>
				<GlueButton
					width='100%'
					onPointerDown={props.onClose}
				>
					<p>Cancel</p>
				</GlueButton>

				<GlueButton
					width='100%'
					color='primary'
					disabled={selectedTeams.length === 0 || (validationResult && validationResult.verdict === 'Invalid') || loading}
					onPointerDown={tryInvite}
				>
					{loading ? <LoadingIndicator variant='button' /> : <p>Send invitation</p>}
				</GlueButton>
			</div>
		</div>
	);
}

export default AddOrgMemberDialogContent;