import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import { ThemeProvider, CssBaseline, makeStyles } from '@material-ui/core';

import queries from './graphql/queries';
import { GlueTheme, WebTheme } from './service/glue-theme';
import Toolbar from './component/toolbar';
import MainMenu from './component/main-menu';
import FileViewSwitcher from './component/files/file-view-switcher';
import FilePreviewModal from './component/files/file-preview-modal';
import ExitDialog from './component/exit-dialog';
import Clock3D from './component/clock/clock-3d';
import SessionControls3D from './component/facilitation/session-3d';
import ContextMenu from './component/context-menu';
import Hand from './component/hand';
import AppRoute from './component/app-route';
import PromptDialogProvider from './util/prompt-dialog-context';
import GlueScroll from './component/common/glue-scroll';
import SysAdminCheck from './service/sys-admin-check';
import TabletRoot from './component/tablet-root';
import UserContextProvider from './util/user-context';
import WelcomeToGlue from './component/welcome/welcome-to-glue';
import { useUserSettings } from './service/user-settings';
import UpdateAvailableDialog from './component/update-available-dialog';
import { useClientVersionCheck } from './service/version-check';
import Document from './component/document';
import VideoControl from './component/video-control/video-control';
import SpeechToTextModal from './component/common/speech-to-text-modal';

const useStyles = makeStyles({
	root: {
		overflow: 'hidden',
		height: '100%',

		display: 'flex',
		justifyContent: 'flex-start',
		flexDirection: 'column',
		alignItems: 'stretch',
	},
});

const App = (props) =>
{
	const classes = useStyles();

	const { newVersionAvailable, forceUpdate, showPromptOnThisPlatform } = useClientVersionCheck();
	const [ updateVersionDialogClosed, setUpdateVersionDialogClosed ] = useState(false);
	const { userSettings } = useUserSettings();

	const navigate = useNavigate();

	const onRouteToEvent = (event) => {
		navigate(event.detail.location, { state: event.detail.state });
	};

	useEffect(() => {
		document.addEventListener("routeTo", onRouteToEvent);

		return () => {
			document.removeEventListener('routeTo', onRouteToEvent);
		}
	}, [onRouteToEvent, navigate]);

	const ui = useQuery(queries.ui).data?.ui ?? false;

	if (ui === 'toolbar')
	{
		return (
			<ThemeProvider theme={GlueTheme}>
				<CssBaseline />
				<div className={classes.root}>
					<Toolbar/>
				</div>
			</ThemeProvider>
		);
	}
	else if (ui === 'clock')
	{
		return (
			<ThemeProvider theme={GlueTheme}>
				<CssBaseline />
				<div className={classes.root}>
					<Clock3D />
				</div>
			</ThemeProvider>
		);
	}
	else if (ui === 'context')
	{
		return (
			<ThemeProvider theme={GlueTheme}>
				<CssBaseline />
				<div className={classes.root}>
					<ContextMenu />
				</div>
			</ThemeProvider>
		);
	}
	else if (ui === 'document')
	{
		return (
			<ThemeProvider theme={GlueTheme}>
				<CssBaseline />
				<PromptDialogProvider>
					<div className={classes.root}>
						<Document />
					</div>
				</PromptDialogProvider>
			</ThemeProvider>
		);
	}
	else if (ui === 'web')
	{
		return (
			<ThemeProvider theme={WebTheme}>
				<CssBaseline />
				<UserContextProvider>
					<PromptDialogProvider>
						<div className={classes.root}>
							<GlueScroll>
								<MainMenu />
							</GlueScroll>
						</div>
					</PromptDialogProvider>
				</UserContextProvider>
			</ThemeProvider>
		);
	}
	else if (ui === 'tablet')
	{
		return (
			<ThemeProvider theme={GlueTheme}>
				<CssBaseline />
				<UserContextProvider>
					<PromptDialogProvider>
						<UpdateAvailableDialog 
							open={newVersionAvailable && showPromptOnThisPlatform && !updateVersionDialogClosed}
							forceUpdate={forceUpdate}
							onClose={() => setUpdateVersionDialogClosed(true)} 
						/>
						<SysAdminCheck/>
						<FilePreviewModal fileSelector={FileViewSwitcher} />
						<SpeechToTextModal/>
						<ExitDialog />

						<TabletRoot loading={!userSettings}>
							{ userSettings?.tutorialStarted ? (
								<Routes>
									<Route path="/" element={<Navigate to="/view/main/apps" />} />
									<Route path="view/*" element={<MainMenu />} />
									<Route path="app/*" element={<AppRoute/>} />
								</Routes>
							) : (
								<WelcomeToGlue />
							)}
						</TabletRoot>
					</PromptDialogProvider>
				</UserContextProvider>
			</ThemeProvider>
		);
	}
	else if (ui === 'webcontrol')
	{
		return (
			<ThemeProvider theme={GlueTheme}>
				<CssBaseline />
				<UserContextProvider>
					<PromptDialogProvider>
						<div className={classes.root}>
							<Routes>
								<Route path="/" element={<Navigate to="/app/web-control" />} />
								<Route path="app/*" element={<AppRoute/>} />
							</Routes>
						</div>
						</PromptDialogProvider>
					</UserContextProvider>
			</ThemeProvider>
		);
	}
	else if (ui === 'videocontrol')
	{
		return (
			<ThemeProvider theme={GlueTheme}>
				<CssBaseline />
				<PromptDialogProvider>
					<div className={classes.root}>
						<VideoControl />
					</div>
				</PromptDialogProvider>
			</ThemeProvider>
		);
	}
	else if (ui === 'sessioncontrol')
	{
		return (
			<ThemeProvider theme={GlueTheme}>
				<CssBaseline />
					<div className={classes.root}>
						<SessionControls3D />
					</div>
			</ThemeProvider>
		);
	}
	else if (ui === 'hand')
	{
		return (
			<ThemeProvider theme={GlueTheme}>
				<CssBaseline />
				<div className={classes.root}>
					<Hand />
				</div>
			</ThemeProvider>
		);
	}
	else
	{
		console.log("Unknown UI: ", ui);
		return (<div></div>);
	}
};

export default App;
