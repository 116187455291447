import React, { useLayoutEffect, useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core'
import Inputfield from './glue-inputfield';
import WebInputfield from '../../standalone-web/common/web-inputfield';

type PaginationProps = {
	dimensions?: number
	onSetSpecificPage: (arg0: number) => void
	targetRef?: React.MutableRefObject<HTMLDivElement>
	offsetWidth?: number
	pageCount: number
	current: number
	useInput?: boolean
	web?: boolean
}

const useStyles = makeStyles(theme => ({
	root: {
		height: theme.custom.pageSelector.buttonSize,
		display: 'flex',
		alignItems: 'center'
	},

	textContainer: {
		marginLeft: theme.glueSpacing('s'),
		marginRight: theme.glueSpacing('s'),
		display: 'grid',
		gridTemplateColumns: 'repeat(3, min-content)',
		gap: '0',
		alignItems: 'center'
	},

	currentPage: (props: { dimensions: number | string }) => ({
		minWidth: props.dimensions,
		
		'&> p': {
			float: 'right',
		}
	}),
}), { name: "MuiGluePagination" });

const Pagination = (props: PaginationProps) => {
	const targetRef = useRef<HTMLDivElement>(null);
	const [dimensions, setDimensions] = useState<number>(0);
	const classes = useStyles({dimensions});

	const [ inputValue, setInputValue ] = useState<{value: string} | null>(null);

	useLayoutEffect(()=> {
		if (targetRef.current) {
			setDimensions(targetRef.current.offsetWidth);
		}
	}, []);

	const getInputValue = () => {
		if (inputValue && inputValue.value)
		{
			return inputValue.value;
		}
		else return props.current ?? "1";
	}

	const submit = () => {
		if (inputValue)
		{
			let intValue = parseInt(inputValue.value ? inputValue.value : '0');
			intValue = Math.min(Math.max(intValue, 1), props.pageCount) - 1;
			setInputValue(null);
			props.onSetSpecificPage(intValue);
		}
	}

	return (
		<div className={classes.root}>
			<div className={classes.textContainer}>
				<div className={classes.currentPage}>
					{props.useInput && props.useInput === true ?
						props.web ? (
							<WebInputfield
								simpleInput
								centerText
								width={'48px'}
								value={inputValue ? inputValue.value : props.current + 1}
								inputValue={getInputValue()}
								onInputChange={(val: string) => setInputValue({value: val})}
								onSubmit={() => submit()}
								onBlur={() => submit()}
							/>
						) : (
							<Inputfield
								simpleInput
								centerText
								width={'64px'}
								value={inputValue ? inputValue.value : props.current + 1}
								inputValue={getInputValue()}
								onInputChange={(val: string) => setInputValue({value: val})}
								onSubmit={() => submit()}
								onBlur={() => submit()}
							/>
						)
					: <p>{props.current + 1}</p>}
				</div>
				<div>
					<p>&nbsp;of&nbsp;</p>
				</div>
				<div ref={targetRef}>
					<p>{props.pageCount}</p>
				</div>
			</div>
		</div>
	)
}

export default Pagination
