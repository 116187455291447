import React, { useState } from "react";
import { useApolloClient, useQuery } from "@apollo/react-hooks";
import { makeStyles, Checkbox } from "@material-ui/core";

import mutations from "../../graphql/mutations";
import queries from "../../graphql/queries";
import GlueButton from "../common/glue-button";
import WebInputfield from "../../standalone-web/common/web-inputfield";
import { usePromptDialogContext } from "../../util/prompt-dialog-context";
import LoadingIndicator from "../common/loading-indicator";
import InfoDialogTemplate from "../common/info-dialog-template";
import { Organization, Team } from "../../graphql/types-generated";

const useStyles = makeStyles((theme) => ({
	root: {
		width: '600px',
		minHeight: '300px',
		display: 'flex',
		flexDirection: 'column',
		gap: theme.glueSpacing('l'),
		padding: theme.glueSpacing('s')
	},

	block: {
		display: 'flex',
		gap: theme.glueSpacing('m')
	}
}));

const DestinationOrg = (props: {
	org: Organization
	teamName: string
}) =>
{
	const [isDuplicate, setIsDuplicate] = useState(false);
	const orgTeamsRes = useQuery(queries.getOrgTeams, {
		skip: !props.org,
		variables: {
			orgId: props.org.orgId
		}
	});

	const teams: Team[] = orgTeamsRes.data?.getOrgTeams ?? [];
	const teamNames = teams.map(team => team.name);

	if (teamNames.includes(props.teamName) && !isDuplicate) {
		setIsDuplicate(true);
	}

	if (orgTeamsRes.loading) {
		return(
			<LoadingIndicator />
		)
	}

	return(
		<div>
			<h3>Destination organisation</h3>
			<h2>{props.org.name}</h2>
			{isDuplicate && 
			<h2 style={{color: 'yellow'}}>NOTE! Destination organisation already has a team by this name. This team will be renamed with an index at the end</h2>}
		</div>
	)
};

const MoveTeamDialog = (props: {
	teamId: string
	orgId: string
	refetch: () => void
}) =>
{
	const classes = useStyles();
	const apollo = useApolloClient();

	const { addDialog, closePromptDialog } = usePromptDialogContext();

	const [newOrgId, setNewOrgId] = useState('');
	const [deleteOldOrg, setDeleteOldOrg] = useState(true);
	const [processing, setProcessing] = useState(false);

	const teamRes = useQuery(queries.teamInfo, {
		skip: !props.teamId,
		variables: {
			teamId: props.teamId
		},
		fetchPolicy: 'network-only'
	});

	const oldOrgRes = useQuery(queries.getOrg, {
		skip: !props.orgId,
		variables: {
			orgId: props.orgId
		},
		fetchPolicy: 'network-only'
	});

	const newOrgRes = useQuery(queries.getOrg, {
		skip: !newOrgId,
		variables: {
			orgId: newOrgId
		},
		fetchPolicy: 'network-only'
	});

	const handleChange = async() =>
	{
		setProcessing(true);

		await apollo.mutate({
			mutation: mutations.moveTeam,
			variables: {
				teamId: props.teamId,
				dstOrgId: newOrgId,
				newUserRole: null, // This will "upgrade" team roles to org roles
				deleteOldOrg: deleteOldOrg
			},
		}).then(res => {
			addDialog(<InfoDialogTemplate 
				header={'Success!'}
				message={`Team moved to organization ${newOrgId} with name ${res.data.moveTeam.teamName}. ${res.data.moveTeam.addedUserEmails.length} users have been added to the organization!`}
			/>);
		}).catch(err => {
			addDialog(<InfoDialogTemplate 
				isError={true}
				message={err.message}
			/>);
		}).finally(() => {
			props.refetch();
			setProcessing(false);
			closePromptDialog();
		});
	}

	if (processing) {
		return(
			<div className={classes.root}>
				<h3>Working on the migration. Please wait...</h3>
				<LoadingIndicator />
			</div>
		)
	}

	return(
		<div className={classes.root}>
			<div >
				<div className={classes.block}>
					<h2>MOVE TEAM: </h2>
					{teamRes.data && <h2>{teamRes.data.teamInfo.name}</h2>}
				</div>
				<div className={classes.block}>
					<h3>ORG: </h3>
					{oldOrgRes.data && <h3>{oldOrgRes.data.getOrg.name}</h3>}
				</div>
				Delete remaining organisation
				<Checkbox checked={deleteOldOrg} onChange={() => {oldOrgRes.data?.getOrg.teams.length > 1 && setDeleteOldOrg(!deleteOldOrg)}} />
			</div>
			
			Set new Org id (this should be copied)
			<WebInputfield value={newOrgId} onChange={(value: string) => setNewOrgId(value)} />
			{newOrgRes.data && 
				<DestinationOrg 
					org={newOrgRes.data.getOrg}
					teamName={teamRes.data.teamInfo.name}
				/>}
			<GlueButton onPointerDown={() => closePromptDialog()}>Cancel</GlueButton>
			<GlueButton 
				color="primary"
				disabled={!newOrgRes.data}
				onPointerDown={() => handleChange()}
			>
				Migrate Team
			</GlueButton>
		</div>
	)
}

export default MoveTeamDialog;