import React, { useReducer, useState } from 'react';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core/styles';

import GlueButton from '../common/glue-button';
import queries from '../../graphql/queries';
import mutations from '../../graphql/mutations';
import { uploadSpaceAssetPackageAsync, checkSpaceAssetUploadUploadStatus } from './space-asset-common';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexFlow: 'column nowrap',
		gap: '24px'
	},

	handlingStatusBox: {
		background: theme.palette.background.paper,
		padding: '24px'
	},

	uploadStatusBox: {
		display: 'flex',
		flexFlow: 'row nowrap',
		alignItems: 'center',
		gap: '24px'
	}
}));

const UploadSpaceAsset = (props) =>
{
	const classes = useStyles();

	const apollo = useApolloClient();

	const assetInfoRes = useQuery(queries.assetInfo, {
		variables: { assetId: props.targetAssetId },
		skip: !props.targetAssetId
	});

	const [file, setFile] = useState(null);

	const initHandlingState = (oldState) =>
	{
		if (oldState)
		{
			if (oldState.cancelSource)
			{
				// Cancel before discarding the handle
				oldState.cancelSource.cancelSource.cancel();
			}
		}

		return {
			uploadProgress: -1,
			uploadStatus: null,

			processingProgress: -1,
			processingStatus: null,

			uploadCancelSource: null
		}
	};
	const [handlingState, dispatchHandlingState] = useReducer(
		(state, action) =>
		{
			if (!action)
				return initHandlingState(state);

			return { ...state, ...action }
		},
		null,
		initHandlingState
	);

	const isUploading = handlingState.uploadProgress >= 0 && handlingState.uploadProgress < 1;
	const isProcessing = handlingState.processingStatus && handlingState.processingStatus !== 'Finished' && handlingState.processingStatus !== 'Failed';
	const isHandling = isUploading || isProcessing;

	const onFileChange = (e) =>
	{
		setFile(e.target.files[0]);
		// Reset
		dispatchHandlingState();
	};

	const onClickUpload = async () =>
	{
		if (!file)
		{
			console.error("File not selected");
			return;
		}

		dispatchHandlingState({
			uploadStatus: "Uploading",
			processingStatus: "Waiting"
		});

		const signedUploadURLRes = await apollo.mutate({
			mutation: mutations.generateAssetUploadURL,
			variables: {
				fileType: file.type,
				assetId: props.targetAssetId
			}
		});
	
		const signedUploadURLBody = signedUploadURLRes.data.generateAssetUploadURL;
		console.log(signedUploadURLBody);

		const uploadRes = await uploadSpaceAssetPackageAsync(
			signedUploadURLBody,
			file,
			(uploadProgress) => dispatchHandlingState({ uploadProgress }),
			(uploadCancelSource) => dispatchHandlingState({ uploadCancelSource })
		).catch(e => {
			console.error("Space Asset Package upload failed", e);
			dispatchHandlingState({ uploadStatus: 'Failed' });
			return null;
		});

		if (uploadRes)
		{
			dispatchHandlingState({ uploadStatus: 'Finished' });

			const processingResult = await checkSpaceAssetUploadUploadStatus(
				uploadRes.statusItemId,
				apollo,
				(statusInfo) => dispatchHandlingState({
					processingStatus: statusInfo.statuscode,
					processingProgress: statusInfo.progress
				})
			).catch(e => {
				console.error("Space Asset Package processing monitoring failed", e);
				dispatchHandlingState({ processingStatus: 'Failed' });
				return null;
			});

			console.log("Upload processsing result", processingResult);
		}

		assetInfoRes.refetch();
	};

	return (
		<div className={classes.root}>
			<div>
				<div>Target asset name: {assetInfoRes.data?.assetInfo.name}</div>
				<input type="file" multiple={false} disabled={isHandling} onChange={onFileChange} />
				<GlueButton disabled={isHandling || !file} onPointerDown={onClickUpload}>Upload</GlueButton>
			</div>

			<div className={classes.handlingStatusBox}>
				<div>Handling status: {!isHandling && "Idle"}</div>

				{isHandling && (<>
					<div className={classes.uploadStatusBox}>
						<div>Upload: {Math.round(handlingState.uploadProgress * 100)}% ({handlingState.uploadStatus})</div>
						<GlueButton disabled={!isUploading} onPointerDown={() => dispatchHandlingState()}>Cancel upload</GlueButton>
					</div>
					<div>Processing: {Math.round(handlingState.processingProgress * 100)}% ({handlingState.processingStatus})</div>
				</>)}

				{handlingState.processingStatus === "Finished" && (
					<div>Finished uploading and processing. The new version should now be available.</div>
				)}
			</div>
		</div>
	);
};

export default UploadSpaceAsset;
