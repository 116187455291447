import React, { useState } from 'react';

import DefaultColors from './DefaultColors.json'

import ColorPicker from '../common/color-picker';

import FeatureList from './featurelist';
import FeatureContainer from './feature-container';

import { getCurrentIndex, revertAvatarHistory, setAvatarColor, setAvatarSavedColors } from '../../service/avatar-configurator';
import { useAvatar } from './avatar-hooks';
import { GlueAccordionStack } from '../common/glue-accordion';
import GlueScroll from '../common/glue-scroll';
import { Color, FeatureContainerContents, EditedProperty } from './avatar-types';

const AvatarEditorTab = (props: {
	contents: FeatureContainerContents[]
}) =>
{
	const contentsCount = props.contents ? props.contents.length : 0;
	const [ editedProperty, setEditedProperty ] = useState<EditedProperty | undefined>(undefined);
	const [ containerState, setContainerState ] = useState<boolean[]>(new Array(contentsCount));
	const avatar = useAvatar();
	const latestEditIndex = getCurrentIndex();

	const closeModal = () => 
	{
		setEditedProperty(undefined);
	}
	
	const revertAndCloseModal = (pointInHistory: number) =>
	{
		revertAvatarHistory(pointInHistory);
		closeModal();
	}

	const containerOpen = (index: number) =>
	{
		return containerState[index];
	}

	const toggleContainerOpen = (index: number) =>
	{
		const newContainerState = [...containerState];
		newContainerState[index] = !newContainerState[index];
		setContainerState(newContainerState);
	}

	const findColor = (id: string) =>
	{
		const color = avatar.avatar.config.Colors.find(c => c.Id === id);
		return color ? color : null;
	}

	const findPresetColors = (id: string | undefined) =>
	{
		if (!id) {
			return null;
		}
		const colorClass = DefaultColors.ColorClasses.find(cc => cc.Id === id);
		return colorClass ? colorClass.PresetColors : null;
	}

	const findAssetName = (type: string) =>
	{
		if (type !== "Shape")
		{
			const asset = avatar.avatar.config.Assets.find(a => a.Type === type);
			return asset?.AssetName;
		}
		else return avatar.avatar.config.PresetName;
	}

	const foundColor = editedProperty?.Color ? findColor(editedProperty.Color.Id) : null;
	if (editedProperty?.Color && !foundColor)
	{
		setEditedProperty(undefined);
	}

	const onSetColor = (color: string, mode: boolean, selection: number, base: Color) =>
	{
		const newColor = {...base};
		newColor.ColorValue = color;
		newColor.PresetMode = mode ? "Saved" : "Preset";
		newColor.CurrentSelection = selection;
		setAvatarColor(newColor);
	}

	return (
		<GlueScroll persistent>
			{!editedProperty &&
				(<GlueAccordionStack>
					{props.contents.map((c, i) => 
						<FeatureContainer
							key={i}
							avatar={avatar.avatar}
							contents={c}
							setEditedProperty={setEditedProperty}
							open={containerOpen(i)}
							toggleOpen={() => toggleContainerOpen(i)}
						/>)}
				</GlueAccordionStack>)}
			{foundColor &&
				(<ColorPicker
					displayName={foundColor.DisplayName.toLowerCase()}
					color={foundColor.ColorValue}
					currentSelection={foundColor.CurrentSelection}
					presetColors={findPresetColors(editedProperty?.Color?.Id)}
					presetsIndex={foundColor.CurrentSelection}
					savedColors={avatar?.avatar.savedColors}
					latestEditIndex={latestEditIndex}
					onConfirm={closeModal}
					onCancel={revertAndCloseModal}
					onSetColor={(color: string, mode: boolean, selection: number) => onSetColor(color, mode, selection, foundColor)}
					onSaveColor={setAvatarSavedColors}
					onDeleteColor={setAvatarSavedColors}
				/>)}
			{editedProperty && editedProperty.Feature &&
				(<FeatureList
					latestEditIndex={getCurrentIndex()}
					featureType={editedProperty.Feature.Type}
					onConfirm={closeModal}
					onCancel={revertAndCloseModal}
					selection={findAssetName(editedProperty.Feature.Type)}
				/>)}
		</GlueScroll>
	);
};

export default AvatarEditorTab;