import React from 'react';
import { makeStyles, IconButton } from '@material-ui/core';
import GlueDropdown from '../common/glue-dropdown';
import SliderSelector from '../common/slider-selector';
import { clickAudio, hoverAudio } from '../common/common-vuplex-messages';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import {
	handleDSPChange,
	currentDevice,
	presetValue,
	showCustomControls,
	showDeveloperControls,
	getDSPSettingsValue,
	handlePresetChange,
	dspValues,
	userDspLevels,
	DspSettings,
	presetNames,
	Preset,
	DspValues,
} from '../../util/dsp-utils';
import { useUserSettings } from '../../service/user-settings';

const useStyles = makeStyles(theme => ({
	row: {
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
		alignItems: 'center',
		height: '64px',
		marginBottom: '12px',

		'& > p': {
			opacity: '70%',
		},
	},

	dspControl: {
		display: 'grid',
		gridTemplateColumns: '1fr 2fr',
		alignItems: 'center',
		height: '64px',
		marginBottom: '12px',

		'& > p': {
			opacity: '70%',
		},
	},

	dspControlRow: {
		display: 'grid',
		alignItems: 'center',
		gridTemplateColumns: '2fr 1fr 5fr 1fr',
		margin: 0,
		gridColumnGap: '12px',

		'&> button': {
			opacity: '70%',
			transform: 'scale(0.7)',

			'&:disabled': {
				visibility: 'hidden'
			}
		}
	},

	switch: {
		marginRight: '-60px'
	}

}));

const DSPControls = (props: never) => {
	const classes = useStyles();
	const { setUserSettings } = useUserSettings();
	const settingsLevel = ["None", "Low", "Middle", "High"];
	// Filter out developer mode (Remove for developer controls)
	const presetKeys = Object.keys(presetNames).filter(key => parseInt(key) as Preset !== Preset.Developer);
	const presetList = presetKeys.map((key) => ({id: key, name: presetNames[parseInt(key) as Preset]}));

	const controlIconClick = (type: keyof DspSettings, delta: number, min: number, max: number) => {
		let value = calculateDSPChange(getDSPSettingsValue(type), delta);
		value = value < min ? min : value;
		value = value > max ? max : value;
		handleDSPChange(type, value);
		setDspLevels(type,value);
	};

	const controlSlider = (type: keyof DspSettings, defaultValue: number, delta: number, min: number, max: number, step: number) => (
		<div className={classes.dspControlRow}>
			({defaultValue}) {getDSPSettingsValue(type)} 
			<IconButton
				onPointerDown={() => {controlIconClick(type, -1 * delta, min, max); clickAudio("Tools/Settings/Sound/MasterVol/Less/Press")}}
				onPointerEnter={() => hoverAudio("Tools/Settings/Sound/MasterVol/Slider/HL")}
			>
				<RemoveIcon />
			</IconButton>
			<SliderSelector 
				style={{ textAlign: 'center' }}
				disabled={true}
				isEnabled={true} // Probably to be removed 
				isActive={true} // Color change
				value={getDSPSettingsValue(type)}
				height={24}
				width={360}
				min={min}
				max={max}
				step={step}
				onChange={(e: never, newValue: number) => handleDSPChange(type, newValue)}
				uiAudioMessage = {"Tools/Settings/Sound/MasterVol/Slider/Press"}
				uiReleaseAudioMessage = {"Tools/Settings/Sound/MasterVol/Slider/Release"}
				uiHoverAudioMessage = {"Tools/Settings/Sound/MasterVol/Slider/HL"}
			/>
			<IconButton 
				onPointerDown={() => {controlIconClick(type, delta, min, max); clickAudio("Tools/Settings/Sound/MasterVol/More/Press")}}
				onPointerEnter={() => hoverAudio("Tools/Settings/Sound/MasterVol/More/HL")}
			>
				<AddIcon />
			</IconButton>
		</div>
	);

	const calculateDSPChange = (value: number, delta: number) => {
		return value = Math.round((value + delta) * 1000) / 1000;
	};

	const marks = [  // show only marks for values between min and max and not for them
		{
			value: 1,
		},
		{
			value: 2,
		},
	];

	const discreteSlider = (type: keyof DspSettings, defaultValue: number, delta: number, min: number, max: number, step: number) => (
		<div className={classes.dspControlRow}>
			{settingsLevel[getDSPSettingsValue(type)]} 
			<IconButton
				disabled={!showCustomControls}
				onPointerDown={() => {controlIconClick(type, -1 * delta, min, max); clickAudio("Tools/Settings/Sound/MasterVol/Less/Press")}}
				onPointerEnter={() => hoverAudio("Tools/Settings/Sound/MasterVol/Slider/HL")}
			>
				<RemoveIcon />
			</IconButton>
			<SliderSelector 
				style={{ textAlign: 'center' }}
				disabled={!showCustomControls}
				isActive={showCustomControls} 
				value={getDSPSettingsValue(type)}
				height={24}
				width={360}
				marks={marks}
				min={min}
				max={max}
				step={step}
				markBackgroundColor={'#ffffff'}
				markHeight={24}
				markWidth={'2px'}
				markBottom={'0'}
				markTop={'0'}
				markOpacity={0.4}    
				onChange={(e: never, newValue: number) =>  {handleDSPChange(type, newValue); setDspLevels(type, newValue)}}
				uiAudioMessage = {"Tools/Settings/Sound/MasterVol/Slider/Press"}
				uiReleaseAudioMessage = {"Tools/Settings/Sound/MasterVol/Slider/Release"}
				uiHoverAudioMessage = {"Tools/Settings/Sound/MasterVol/Slider/HL"}
			/>
			<IconButton 
				disabled={!showCustomControls}
				onPointerDown={() => {controlIconClick(type, delta, min, max); clickAudio("Tools/Settings/Sound/MasterVol/More/Press")}}
				onPointerEnter={() => hoverAudio("Tools/Settings/Sound/MasterVol/More/HL")}
			>
				<AddIcon />
			</IconButton>
		</div>
	);

	const DSPEndUserControls = (
		<div>
			<div className={classes.dspControl}>
				<p>Normalization level</p>
				{discreteSlider('normalizationLevel' as keyof DspSettings, 0, 1, 0, 3, 1)}
			</div>
			<div className={classes.dspControl}>
				<p>Noise reduction level</p>
				{discreteSlider('noiseGateLevel' as keyof DspSettings, 1, 1, 0, 3, 1)}
			</div>
			<div className={classes.dspControl}>
				<p>Echo reduction level</p>
				{discreteSlider('echoCancellationLevel' as keyof DspSettings, 2, 1, 0, 3, 1)}
			</div>
		</div>
	);

	const DSPDeveloperControls = (
		<div>
			<div className={classes.row}>
				<p>Lower Threshold</p>
				{controlSlider('lowerThreshold' as keyof DspSettings, 2.0, 0.5, -2.0, 15.0, 0.5)}
			</div>
			<div className={classes.row}>
				<p>Upper Threshold</p>
				{controlSlider('upperThreshold' as keyof DspSettings, 7.0, 0.5, 0.0, 15.0, 0.5)}
			</div>
			<div className={classes.row}>
				<p>Hold time</p>
				{controlSlider('holdTime' as keyof DspSettings, 15, 1, 1, 30, 1)}
			</div>
			<div className={classes.row}>
				<p>Attack</p>
				{controlSlider('attack' as keyof DspSettings, 5, 1, 1, 30, 1)}
			</div>
			<div className={classes.row}>
				<p>Release</p>
				{controlSlider('release' as keyof DspSettings, 10, 1, 1, 30, 1)}
			</div>
			<div className={classes.row}>
				<p>Energy threshold</p>
				{controlSlider('energyThreshold' as keyof DspSettings, -26.0, 1.0, -40.0, -23.0, 1.0)}
			</div>
			<div className={classes.row}>
				<p>Gain one threshold</p>
				{controlSlider('gainOneThreshold' as keyof DspSettings, 10.0, 0.5, 0.0, 15.0, 0.5)}
			</div>
			<div className={classes.row}>
				<p>Gain two threshold</p>
				{controlSlider('gainTwoThreshold' as keyof DspSettings, 30.0, 0.5, 10.0, 40.0, 0.5)}
			</div>
			<div className={classes.row}>
				<p>Mu Max</p>
				{controlSlider('muMax' as keyof DspSettings, 1.0, 0.05, 0.0, 1.0, 0.05)}
			</div>
			<div className={classes.row}>
				<p>Mu after silence</p>
				{controlSlider('muAfterSilence' as keyof DspSettings, 1.0, 0.05, 0.0, 1.0, 0.05)}
			</div>
			<div className={classes.row}>
				<p>Threshold background</p>
				{controlSlider('thresholdBackground' as keyof DspSettings, 0.4, 0.05, 0.0, 1.0, 0.05)}
			</div>
			<div className={classes.row}>
				<p>Threshold foreground</p>
				{controlSlider('thresholdForeground' as keyof DspSettings, 0.2, 0.05, 0.0, 1.0, 0.05)}
			</div>
			<div className={classes.row}>
				<p>Threshold bg detection off</p>
				{controlSlider('thresholdBackgroundDetectionOff' as keyof DspSettings, 0.7, 0.05, 0.0, 1.0, 0.05)}
			</div>
			<div className={classes.row}>
				<p>Threshold fg detection off</p>
				{controlSlider('thresholdForegroundDetectionOff' as keyof DspSettings, 0.5, 0.05, 0.0, 1.0, 0.05)}
			</div>
			<div className={classes.row}>
				<p>Threshold silence</p>
				{controlSlider('thresholdSilence' as keyof DspSettings, 0.05, 0.01, 0.000, 0.2, 0.01)}
			</div>
			<div className={classes.row}>
				<p>Reset speed</p>
				{controlSlider('resetSpeed' as keyof DspSettings, 8, 1, 1, 16, 1)}
			</div>
			<div className={classes.row}>
				<p>Adaptation time after silence</p>
				{controlSlider('adaptationTimeAfterSilence' as keyof DspSettings, 8, 1, 1, 32, 1)}
			</div>
		</div>
	);

	const setDspLevels = (type: keyof DspSettings, newLevel: number) =>
	{
		switch (type)
		{
			case 'normalizationLevel':
				userDspLevels[0] = newLevel;
				break;
			case 'noiseGateLevel':
				userDspLevels[1] = newLevel;
				break;
			case 'echoCancellationLevel':
				userDspLevels[2] = newLevel;
				break;
			default:
				return;
		}
		setDspValues(Preset.Custom);
	};

	const setDspValues = (preset: Preset) => 
	{
		const newDspValues: DspValues = {...dspValues};
		newDspValues.dspPreset[currentDevice] = preset;
		newDspValues.dspLevels[currentDevice] = userDspLevels;
		setUserSettings({ dspValues: newDspValues });
	};

	const SignalProcessingPresets = () =>
	{
		const inputaudiomessage = "Tools/Settings/Sound/Input/Press";
		const inputhoveraudiomessage = "Tools/Settings/Sound/Input/HL";
		const inputselaudiomessage = "Tools/Settings/Sound/Input/Select/Press";
		const inputselhoveraudiomessage = "Tools/Settings/Sound/Input/Select/HL";


		return (
			<GlueDropdown
				width={'360px'}
				items={presetList}
				defaultValue={presetValue.toString()}
				uiAudioMessage={inputaudiomessage}
				uiHoverAudioMessage={inputhoveraudiomessage}
				uiSelAudioMessage={inputselaudiomessage}
				uiSelHoverAudioMessage={inputselhoveraudiomessage}
				onChange={(id:string | number, checked: boolean) => { 
					if (checked) { 
						const preset: Preset = parseInt(id as string) as Preset;
						handlePresetChange(preset); 
						setDspValues(preset);
					} 
				}}
			/>
		)
	};
		
	return (
		<div>
			<div className={classes.row}>
				<p>Settings optimized for </p>
				<SignalProcessingPresets />
			</div>
			{DSPEndUserControls}
			{showDeveloperControls && (
				DSPDeveloperControls
			)}
		</div>
	)
};

export default DSPControls;
