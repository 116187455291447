import React from 'react';
import ProdToolHeroIcon from './prod-tool-hero-icon';

let ShowExternalApps = (props) =>
{
	return (<> 
		{props.appsSelected.map((app,i) => (
			<ProdToolHeroIcon 
				key={i}
				appname={app.AppName}
				iconurl={app.IconUrl}
				appurl={app.AppUrl}
			/>
		))}
	</>);
}

export default ShowExternalApps;
