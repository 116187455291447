import { CSSProperties } from "@material-ui/core/styles/withStyles";

export type Type = CSSProperties;

const common: Type =
{
	width: '18px',
	height: '18px',
	outline: '2px',
};

export const web: Type =
{
	...common
};

export const tablet: Type =
{
	...common,

	width: '32px',
	height: '32px',
	outline: '4px'
};