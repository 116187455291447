import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useQuery, useApolloClient } from '@apollo/react-hooks';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';

import queries from '../../graphql/queries';
import GlueButton from '../common/glue-button';
import ClockStatusIndicator from './clock-statusindicator';
import { broadcastMessage } from '../../service/message-broadcast';
import postVuplexMessage from '../../service/message-vuplex';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexFlow: 'column nowrap',
		justifyContent: 'flex-start',
		alignItems: 'center',

		position: 'relative'
	},

	elapsed: {
		...theme.custom.clock.displayFont.full,
		...theme.custom.clock.displayRow
	},

	units: {
		position: 'absolute',
		top: '145px',
		left: '80px',
		width: '500px',

		display: 'flex',
		flexFlow: 'row nowrap',
		justifyContent: 'space-between',

		fontSize: '24px',
		textTransform: 'uppercase',
		opacity: '0.7',
		fontWeight: 700,
	},

	controls: {
		display: 'grid',
		gap: '24px',
		gridTemplateColumns: "160px 160px",
		justifyContent: 'center'
	}
}));

const getTotal = (clockStopwatchData) =>
{
	const startedAt = moment(clockStopwatchData.clockStopwatch.startedAt);
	const elapsedRunning = clockStopwatchData.clockStopwatch.running ? moment.duration(moment().diff(startedAt)) : moment.duration(0);
	const elapsedOld = moment.duration(clockStopwatchData.clockStopwatch.elapsed);

	return elapsedOld.add(elapsedRunning);
};

export const ClockStopwatchDisplay = (props) =>
{
	const classes = useStyles(props);
	const apollo = useApolloClient();
	const navigate = useNavigate();

	const [ elapsedDisplay, setElapsedDisplay ] = useState(moment.duration(0));

	useEffect(() => {
		let intervalId = 0;

		const updateDisplay = () =>
		{
			//console.log("STOPWATCH DISPLAY: Update", intervalId);

			const data = apollo.readQuery({ query: queries.clockStopwatch });
			const elapsedTotal = getTotal(data);

			setElapsedDisplay(elapsedTotal);
		};

		intervalId = setInterval(updateDisplay, 500);
		//console.log("STOPWATCH DISPLAY: Start", intervalId);

		return () => 
		{
			//console.log("STOPWATCH DISPLAY: Stop", intervalId);
			clearInterval(intervalId);
		};
	}, [apollo]);

	const elapsedHours = elapsedDisplay.hours();
	const elapsedMinutes = elapsedDisplay.minutes();
	const elapsedSeconds = elapsedDisplay.seconds();

	if (props.toolbar)
	{
		const onClickToolbar = () =>
		{
			if (sessionStorage.getItem('ui') === 'tablet')
				navigate('/app/clock/stopwatch');
			else
			{
				postVuplexMessage('Open tablet', null);
				broadcastMessage({ topic: 'Navigate to', args: { ui: 'tablet', path: '/app/clock/stopwatch' }});
			}
		};

		const displayHours = String(elapsedHours).padStart(1, '0');
		const displayMinutes = String(elapsedMinutes).padStart(2, '0');
		const displaySeconds = String(elapsedSeconds).padStart(2, '0');
		const displayText = `${displayHours}:${displayMinutes}:${displaySeconds}`;

		return (
			<ClockStatusIndicator
				icon={<AccessAlarmIcon />}
				text={displayText}
				onPointerDown={onClickToolbar}
			/>
		);
	}
	else
	{
		return (
			<div className={classes.root}>
				<div className={classes.elapsed}>
					<div>{Math.floor(elapsedHours / 10)}</div>
					<div>{elapsedHours % 10}</div>
					<div>:</div>
					<div>{Math.floor(elapsedMinutes / 10)}</div>
					<div>{elapsedMinutes % 10}</div>
					<div>:</div>
					<div>{Math.floor(elapsedSeconds / 10)}</div>
					<div>{elapsedSeconds % 10}</div>
				</div>

				<div className={classes.units}>
					<div>Hours</div>
					<div>Minutes</div>
					<div>Seconds</div>
				</div>
			</div>
		);
	}
};

export const ClockStopwatchControls = (props) =>
{
	const classes = useStyles();
	const apollo = useApolloClient();

	const clockStopwatchResult = useQuery(queries.clockStopwatch);

	const start = () =>
	{
		apollo.writeQuery({
			query: queries.clockStopwatch,
			data: {
				clockStopwatch: {
					__typename: 'ClockStopwatch',
					elapsed: 0,
					startedAt: moment().valueOf(),
					running: true
				}
			}
		});
	};

	const pause = () =>
	{
		const data = apollo.readQuery({ query: queries.clockStopwatch });
		const elapsedTotal = getTotal(data);

		apollo.writeQuery({
			query: queries.clockStopwatch,
			data: {
				clockStopwatch: {
					__typename: 'ClockStopwatch',
					elapsed: elapsedTotal.valueOf(),
					startedAt: 0,
					running: false
				}
			}
		});
	};

	const resume = () =>
	{
		const data = apollo.readQuery({ query: queries.clockStopwatch });

		apollo.writeQuery({
			query: queries.clockStopwatch,
			data: {
				clockStopwatch: {
					__typename: 'ClockStopwatch',
					elapsed: data.clockStopwatch.elapsed,
					startedAt: moment().valueOf(),
					running: true
				}
			}
		});
	};

	const reset = () =>
	{
		apollo.writeQuery({
			query: queries.clockStopwatch,
			data: {
				clockStopwatch: {
					__typename: 'ClockStopwatch',
					elapsed: 0,
					startedAt: 0,
					running: false
				}
			}
		});
	};

	const resetaudiomessage = "Apps/Clock/StopWatch/Reset/Press"; 
	const resethoveraudiomessage = "Apps/Clock/StopWatch/Reset/HL";
	const startaudiomessage = "Apps/Clock/StopWatch/Start/Press"; 
	const starthoveraudiomessage = "Apps/Clock/StopWatch/Start/HL";
	const stopaudiomessage = "Apps/Clock/StopWatch/Stop/Press"; 
	const stophoveraudiomessage = "Apps/Clock/StopWatch/Stop/HL";

	return (
		<div className={classes.controls}>
			<GlueButton 
				disabled={clockStopwatchResult.data.clockStopwatch.elapsed === 0} 
				onPointerDown={reset}
				uiAudioMessage = {resetaudiomessage}
				uiHoverAudioMessage = {resethoveraudiomessage} 
			>
				Reset
			</GlueButton>

			{clockStopwatchResult.data.clockStopwatch.running ? (
				<GlueButton 
					color="primary"
					onPointerDown={pause}
					uiAudioMessage = {stopaudiomessage}
					uiHoverAudioMessage = {stophoveraudiomessage} 
				>
					Stop
				</GlueButton>
			) : (
				<GlueButton 
					color="primary"
					onPointerDown={() => { clockStopwatchResult.data.clockStopwatch.elapsed > 0 ? resume() : start() }}
					uiAudioMessage = {startaudiomessage}
					uiHoverAudioMessage = {starthoveraudiomessage} 
				>
					Start
				</GlueButton>
			)}
		</div>
	);
};
