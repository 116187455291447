import React from 'react';

import { Drawer, DrawerProps } from '@material-ui/core';

type GlueDrawerProps = DrawerProps

const GlueDrawer = (props: GlueDrawerProps) =>
{
	return (<>
		<Drawer
			{...props}
		/>
	</>);
}

export default GlueDrawer;
