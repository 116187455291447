const SpecialApps =
[
    {
        "AppName":"Salesforce",
        "InfoText":"Salesforce URL",
        "InputFieldText":"your Salesforce domain",
        "endOfUrl":".my.salesforce.com/"
    },
    {
        "AppName":"Slack",
        "InfoText":"Slack workspace URL",
        "InputFieldText":"your Slack workspace URL",
        "endOfUrl":".slack.com/"
    },
    {
        "AppName":"Jira",
        "InfoText":"Jira URL",
        "InputFieldText":"your Jira domain",
        "endOfUrl":".atlassian.net/jira/"
    }
];

export default SpecialApps;