export type Type = {
	width: string
	thumbWidth: string
	thumbRounding: string
	thumbMinHeight: string
};

const common: Type =
{
	width: '48px',
	thumbWidth: '12px',
	thumbRounding: '6px',
	thumbMinHeight: '64px'
};

export const web: Type =
{
	...common,
	width: '36px',
	thumbWidth: '8px',
	thumbRounding: '4px',
	thumbMinHeight: '36px'
};

export const tablet: Type =
{
	...common
};
