import React, { useCallback, useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import queries from "../../graphql/queries";

import { Card, makeStyles } from "@material-ui/core";
import { Storage } from "@material-ui/icons";
import { usePromptDialogContext } from "../../util/prompt-dialog-context";
import WebInputfield from "../../standalone-web/common/web-inputfield";
import GlueRadioGroup from "../../standalone-web/common/glue-radio-group";
import GlueButton from "../common/glue-button";
import GlueDropdown from "../common/glue-dropdown";
import mutations from "../../graphql/mutations";
import { useUserContext } from "../../util/user-context";
import SingleTeamSearch from "../single-team-search";
import InfoDialogTemplate from "../common/info-dialog-template";
import LoadingIndicator from "../common/loading-indicator";
import DialogHeader from "../common/dialog-header";

import { Orchestrator, Cluster } from "../../graphql/types-generated";

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',

		'& > p': {
			marginTop: '10px'
		},

		'& > h2': {
			margin: `${theme.glueSpacing('xl')} 0`
		}
	},

	clusterMap: {
		display: 'flex',
		flexFlow: 'row wrap',
		gap: theme.glueSpacing('m'),

		'& h2:first-child': {
			marginBottom: '25px'
		}
	},

	clusterCard: {
		width: '300px',
		height: '150px',
		padding: theme.glueSpacing('s')
	},

	clusterCardHeader: {
		display: 'flex',
		height: '60%',
		gap: theme.glueSpacing('s'),

		'& > h3': {
			width: 'fit-content',
			maxWidth: '250px',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		}
	},

	divider: {
		height: '2px',
		width: '100%',
		background: 'white'
	},

	addModal: {
		display: 'flex',
		flexDirection: 'column',
		minWidth: '500px',
		padding: theme.glueSpacing('s'),

		'& > h2': {
			marginBottom: '20px'
		},

		'& > div:last-child': {
			marginTop: theme.glueSpacing('l')
		}
	},

	addModalBtns: {
		display: 'flex',
		gap: theme.glueSpacing('s'),
		justifyContent: 'space-between'
	},

	linkHeaders: {
		display: 'flex',
		alignItems: 'center',
		background: theme.palette.secondary.dark,
		justifyContent: 'space-between',
		border: '2px solid white',
		padding: `0 ${theme.glueSpacing('s')}`,
		margin: `${theme.glueSpacing('s')} 0`
	},

	linkItem: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		border: '1px solid ' + theme.palette.secondary.dark,
		padding: `0 ${theme.glueSpacing('s')}`,
		margin: `${theme.glueSpacing('s')} 0`
	},

	headerBar: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between'
	},

	teamModal: {
		display: 'flex',
		flexDirection: 'column',
		padding: theme.glueSpacing('m'),
		gap: theme.glueSpacing('m'),
		alignItems: 'center',
		justifyContent: 'center',

		'&> p': {
			color: theme.palette.warning.main
		}
	},

	teamSearch: {
		maxWidth: '600px',
		display: 'flex',
		flexDirection: 'row',
		gap: theme.glueSpacing('m')
	}
}));

const hostGroupStatus = [
	{ id: 'public', name: 'Active (available for new teams)' },
	{ id: 'private', name: 'Inactive (available only where already in use)' }
]

const OrchestratorItem = (props: { orchestratorId: string, unlinkOrch: (p: string) => void, key: string | number }) =>
{
	const classes = useStyles();

	const orchRes = useQuery(queries.orchestratorInfo, {
		variables: {
			orchestratorId: props.orchestratorId
		}
	});

	const unlinkFromClust = () =>
	{
		if (props.unlinkOrch) {
			props.unlinkOrch(props.orchestratorId)
		}
	}

	if (orchRes.loading) {
		return (
			<div>Loading...</div>
		)
	}

	return (
		<div key={props.key} className={classes.linkItem}>
			<p>{orchRes.data?.orchestratorInfo.name}</p>
			<GlueButton
				onPointerDown={() => unlinkFromClust()}
			>
				Detach
			</GlueButton>
		</div>
	)
}

type ServerSelection = {
	id: string,
	name: string
}

const HostGroupDialog = (props: { cluster?: Cluster, close: () => void, refetch?: () => void, loading?: boolean }) =>
{
	const classes = useStyles();
	const updateMode = props.cluster !== undefined;

	const {addDialog, closePromptDialog} = usePromptDialogContext();

	const clusterRes = useQuery(queries.clusterInfo, {
		variables: {
			clusterId: props.cluster?.clusterId
		},
		fetchPolicy: 'network-only'
	})

	const user = useUserContext();

	const [clustName, setClustName] = useState<string>(props.cluster?.name ?? '');
	const [permission, setPermission] = useState<string>(props.cluster?.permission ?? hostGroupStatus[1].id);

	const [selectedOchestrator, setSelectedOrchestrator] = useState<string | null>(null);

	const [selectableSessionHosts, setSelectableSessionHosts] = useState<ServerSelection[]>([]);
	const [attachedSessionHosts, setAttachedSessionHosts] =useState<ServerSelection[] | []>([])

	const adminTeamIdRes = useQuery(queries.adminTeamId);

	const orchestratorsRes = useQuery(queries.myOrchestrators, {
		fetchPolicy: 'network-only'
	});

	const sortServersIntoCategories = (item: Orchestrator) => {
		if (!clusterRes.data.clusterInfo.attachedOrchestrators.includes(item.orchestratorId)) {
					if (item.type !== 'mediaserver') 
					{
						setSelectableSessionHosts((prevSel) => [
							...prevSel,
							{
								id: item.orchestratorId,
								name: item.name ?? ''
							}
						])
					}
				} 
				else 
				{
					if (item.type !== 'mediaserver') 
					{
						setAttachedSessionHosts((prevSel) => [
							...prevSel,
							{
								id: item.orchestratorId,
								name: item.name ?? ''
							}
						])
					}
				}
	}

	const updateCallback = useCallback(() => {
		if (updateMode && orchestratorsRes.data && clusterRes.data && selectableSessionHosts.length === 0) {
			orchestratorsRes.data.myOrchestrators.forEach((item: Orchestrator ) => {
				sortServersIntoCategories(item);
			})
		}

	}, [setSelectableSessionHosts, 
		setAttachedSessionHosts, orchestratorsRes, clusterRes,
		selectableSessionHosts, updateMode])

	useEffect(() => {
		const timer = setTimeout(() => {

			clearTimeout(timer);
			updateCallback()

		}, 3000)

		return () => clearTimeout(timer);
	}, [clusterRes, updateCallback])

	const clearData = () =>
	{
		setSelectableSessionHosts([])
		setAttachedSessionHosts([])
	}

	const [createClustMut] = useMutation(mutations.createCluster);
	const [updateClustMut] = useMutation(mutations.updateCluster);
	const [deleteClusterMut] = useMutation(mutations.removeCluster);
	const [attachOrchToClusterMut] = useMutation(mutations.attachOrchestratorToCluster);
	const [detatchOrchFromClustMut] = useMutation(mutations.detachOrchestratorFromCluster);

	const attachOrchToCluster = async (item: string) =>
	{
		if (!adminTeamIdRes.data.adminTeamId)
		{
			console.error("No admin team ID!");
			return;
		}
		if (!props.cluster) {
			console.error('Props does not contain a valid cluster');7
			return;
		}
		await attachOrchToClusterMut({
			variables: {
				teamId: adminTeamIdRes.data.adminTeamId,
				orchestratorId: item,
				userId: user.email,
				clusterId: props.cluster.clusterId,
				clusterName: props.cluster.name
			},
			refetchQueries: [{
				query: queries.clusterInfo,
				variables: {
					clusterId: props.cluster.clusterId
				},
				fetchPolicy: "network-only"
			}]
		}).then(res => {
			console.log(res);
		}).catch(err => {
			console.error(err);
		}).finally(() => {
			clearData()
			setSelectedOrchestrator(null)
			if (props.refetch)
				props.refetch();
		});
	}

	const detatchOrchFromCluster = async (item: string) =>
	{
		if (!adminTeamIdRes.data.adminTeamId)
		{
			console.error("No admin team ID found!");
		}
		if (!props.cluster) {
			console.error('Props does not contain a valid cluster');7
			return;
		}
		await detatchOrchFromClustMut({
			variables: {
				teamId: adminTeamIdRes.data.adminTeamId,
				orchestratorId: item,
				userId: user.email,
				clusterId: props.cluster.clusterId,
				clusterName: props.cluster.name
			},
			refetchQueries: [{
				query: queries.clusterInfo,
				variables: {
					clusterId: props.cluster.clusterId
				},
				fetchPolicy: "network-only"
			}]
		}).then(res => {
			console.log(res);
		}).catch(err => {
			console.error(err);
		}).finally(() => {
			if (props.refetch)
				props.refetch()
			clearData()
		});
	}

	const createNewCluster = async () =>
	{
		if (!clustName.trim())
			return;

		await createClustMut({
			variables: {
				name: clustName,
				permission: permission
			}
		}).then(res => {
			console.log(res)
			addDialog(<InfoDialogTemplate 
				header={'Success'}
				message={`The new cluster "${clustName}" was created.`}
			/>)
		}).catch(err => {
			console.error(err);
		});
		if (props.refetch)
			props.refetch();
		if (props.close)
			props.close();
	}

	const updateExisting = async () =>
	{
		if (!props.cluster) {
			console.error('Props does not contain a valid cluster');7
			return;
		}
		await updateClustMut({
			variables: {
				clusterId: props.cluster.clusterId,
				name: clustName.trim() ? clustName : props.cluster.name,
				permission: permission
			}
		}).then(res => {
			console.log(res);
			addDialog(<InfoDialogTemplate 
				header={'Success'}
				message={`The cluster "${clustName.trim() ? clustName : props.cluster?.name}" was updated.`}
			/>)
		}).catch(err => {
			console.error(err);
		});
		if (props.refetch)
			props.refetch();
		if (props.close)
			props.close();
	}

	const deleteCluster = async (clusterId?: string) =>
	{
		if (!clusterId)
			return;
		if (!adminTeamIdRes.data.adminTeamId)
		{
			console.error("No admin team ID found!");
			return;
		}
		console.log(user.email)
		console.log(adminTeamIdRes.data.adminTeamId)
		await deleteClusterMut({
			variables: {
				clusterId: clusterId,
				userId: user.email,
				accessTeamId: adminTeamIdRes.data.adminTeamId
			}
		}).then(res => {
			console.log(res.data);
		}).catch(err => {
			console.error(err);
		}).finally(() => {
			if (props.refetch)
				props.refetch();
			if (props.close)
				props.close();
		});
		addDialog(<InfoDialogTemplate 
				header={'Success'}
				message={`The cluster was deleted.`}
			/>)
	}

	return(
		<div className={classes.addModal}>
			{updateMode ? <h2>Host Group Details</h2> : <h2>Create New Host Group</h2>}
				Host group name
				<WebInputfield 
					value={clustName}
					onChange={(e: React.SetStateAction<string>) => setClustName(e)}
				/>
				Select status of host group
				<GlueRadioGroup 
					options={hostGroupStatus}
					value={permission}
					onChange={(e: React.SetStateAction<string>) => setPermission(e)}
				/>
			{(updateMode && props.cluster !== undefined) &&
				<div>
					<h3>{clusterRes.data?.clusterInfo.allowedTeams.length} teams linked</h3>
					<div className={classes.linkHeaders}>
						<h3>Linked Host Nodes</h3>
						{selectedOchestrator && 
						<GlueButton
							color="primary"
							onPointerDown={() => attachOrchToCluster(selectedOchestrator)}
						>
							Attach
						</GlueButton>}
						<GlueDropdown
							label={'Link host node'}
							items={selectableSessionHosts}
							onChange={(id, checked) => { checked && setSelectedOrchestrator(id as string) }}
						/>
					</div>
					{attachedSessionHosts.map((item: { id: string }, i) => (
						<OrchestratorItem 
							key={item.id}
							orchestratorId={item.id}
							unlinkOrch={() => detatchOrchFromCluster(item.id)}
						/>
					))}
				</div>
			}
			<div className={classes.addModalBtns}>
				<GlueButton onPointerDown={() => props.close()}>Cancel</GlueButton>
				<GlueButton
					color="primary"
					onPointerDown={() => { updateMode ? updateExisting() : createNewCluster() }}
				>
					{updateMode ? 'Save Changes' : 'Create'}
				</GlueButton>
				{updateMode &&
					<GlueButton
						color="destructive"
						onPointerDown={() => {props.close(); addDialog(
							<InfoDialogTemplate 
								header={'Delete?'}
								message={`Are you sure you want to delete Host Node: ${props.cluster && props.cluster.name} permanently?`}
								callbacks={[
									{ label: 'Cancel', callback: () => closePromptDialog() },
									{ label: 'Delete', color: 'destructive', callback: () => deleteCluster(props.cluster?.clusterId)}
								]}
							/>)}}
					>
						Delete
					</GlueButton>}
			</div>
		</div>
	)
}

const TeamModifyDialog = (props: { teamId: string, clusters: Cluster[]}) =>
{
	const classes = useStyles();
	const user = useUserContext();
	const { addDialog, closePromptDialog } = usePromptDialogContext();

	const teamRes = useQuery(queries.teamInfo, {
		variables: {
			teamId: props.teamId,
		},
		fetchPolicy: 'network-only'
	});
	const adminTeamIdRes = useQuery(queries.adminTeamId);

	const [attatchTeamToClustMut] = useMutation(mutations.attachTeamToCluster);
	const [detatchTeamFromClustMut] = useMutation(mutations.detachTeamFromCluster);

	if (!props.teamId) {
		closePromptDialog()
	}

	if (teamRes.loading) {
		return (
			<div>Loading...</div>
		)
	}

	const clusters = props.clusters?.map((item) => ({
		id: item.clusterId,
		name: item.name ?? ''
	})) ?? []

	const attach = async (cluster: { id: string, name: string}) =>
	{
		await attatchTeamToClustMut({
			variables: {
				userTeamId: adminTeamIdRes.data.adminTeamId,
				attachTeamId: props.teamId,
				userId: user.email,
				clusterId: cluster.id,
				clusterName: cluster.name
			}
		}).then(res => {
			console.log("Repsonse", res.data)
			addDialog(<InfoDialogTemplate 
				header={'Success'}
				message={`The team was successfully attached to the ${cluster.name} cluster.`}
			/>)
		}).catch(err => {
			console.error("Error:", err);
			addDialog(<InfoDialogTemplate
				isError={true}
				message={'An error occured and the team cound not be attached to the cluster.'}
			/>)
		});
	}

	const detatch = async (cluster: { id: string, name: string }) =>
	{
		await detatchTeamFromClustMut({
			variables: {
				userTeamId: adminTeamIdRes.data.adminTeamId,
				detachTeamId: props.teamId,
				userId: user.email,
				clusterId: cluster.id,
				clusterName: cluster.name
			}
		}).then(res => {
			console.log("Repsonse", res.data)
			addDialog(<InfoDialogTemplate
				header={'Success'}
				message={`The team was successfully detatched from the ${cluster.name} cluster.`}
			/>)
		}).catch(err => {
			console.error("Error:", err);
			addDialog(<InfoDialogTemplate
				isError={true}
				message={'An error occured and the team cound not be detatched from the cluster.'}
			/>)
		});
	}

	const removeAttachCheck = (id: string, checked: boolean) =>
	{
		const cluster = clusters.find((it: { id: string }) => it.id === id);

		if (!cluster || !cluster.name) {
			addDialog(<InfoDialogTemplate 
				isError={true}
				message={'The cluster could not be found.'}
			/>)
			return;
		}

		const callbackSelect = () =>
		{
			if (checked) 
				return attach(cluster)

			return detatch(cluster)
		}

		addDialog(
			<InfoDialogTemplate 
				header={checked ? 'Attach Team?' : 'Detach Team?'}
				message={checked ? `Attatch ${props.teamId} to cluster ${cluster.name}?` : `Detach ${props.teamId} from cluster ${cluster.name}?`}
				callbacks={[
					{ label: 'Cancel', callback: () => closePromptDialog() },
					{ label: 'Ok', callback: () => callbackSelect(), color: 'primary'}
				]}
			/>
		)
		closePromptDialog()
	}

	const checkedItems = teamRes.data?.teamInfo.clusters.map((item: { clusterId: string }) => (item.clusterId)) as (string | number)[];

	return(
		<div className={classes.teamModal}>
			<DialogHeader header={teamRes.data?.teamInfo.name} closeDialog={() => closePromptDialog()} />
			<h3>Attached to clusters:</h3>
			{(teamRes.data.teamInfo.clusters.length > 0) ? teamRes.data.teamInfo.clusters.map((cluster: { name: string }, i: number) => (
				<p key={i}>{cluster.name}</p>
			)) : <p>Warning! Team is not attached to any clusters and may be without a proper orchesrator.</p>}
			{props.clusters && 
				<GlueDropdown 
					label={'Select Clusters for Team'}
					items={clusters} 
					onChange={(id, checked) => removeAttachCheck(id as string, checked)}
					multiple={true}
					defaultValue={checkedItems}
				/>}

		</div>
	)
}

const HostGroups = () =>
{
	const classes = useStyles();
	const { addDialog, closePromptDialog } = usePromptDialogContext();

	const [selectedTeam, setSelectedTeam] = useState<string | null>(null);

	const clustersRes = useQuery(queries.myClusters, {
		variables: {
			userRole: 'administrator'
		},
		fetchPolicy: 'network-only'
	});

	const selectedTeamRes = useQuery(queries.teamInfo, {
		skip: !selectedTeam,
		variables: {
			teamId: selectedTeam
		}
	})

	return(
		<div className={classes.root}>
			<div className={classes.headerBar}>
				<GlueButton
					color="primary"
					onPointerDown={() => addDialog(<HostGroupDialog
						refetch={() => clustersRes.refetch()}
						close={() => closePromptDialog()}
					/>)}
				>
					Create New Host Group
				</GlueButton>
				<div className={classes.teamSearch}>
					{selectedTeam && <GlueButton 
						color="primary"
						onPointerDown={() => addDialog(
							<TeamModifyDialog teamId={selectedTeam} clusters={clustersRes.data?.myClusters} />
						)}>
							{selectedTeamRes.data ? selectedTeamRes.data.teamInfo.name : ''}
						</GlueButton>}
					<SingleTeamSearch
						onChange={(teamId: string) => { setSelectedTeam(teamId); { teamId && addDialog(<TeamModifyDialog teamId={teamId} clusters={clustersRes.data?.myClusters} />) } }}
						selected={selectedTeam} />
				</div>
			</div>
			<h2>Host Groups:</h2>
			<div className={classes.clusterMap}>
				{clustersRes.data ?
					clustersRes.data.myClusters.map((clust: Cluster, i: number) => (
						<Card key={i} className={classes.clusterCard}
							onPointerDown={() => addDialog(<HostGroupDialog 
								cluster={clust}
								refetch={() => {clustersRes.refetch(); console.log("Update parent...")}}
								loading={clustersRes.loading}
								close={() => closePromptDialog()}
							/>)}
						>
							<div className={classes.clusterCardHeader}>
								<Storage />
								<h3>{clust.name}</h3>
							</div>
							<div className={classes.divider} />
							{`${clust.attachedOrchestrators?.length} Node(s), ${clust.allowedTeams?.length} Team(s) Using`}
						</Card>
				)) : 
				<div>
					<LoadingIndicator />
				</div>}
			</div>
		</div>
	)
}

export default HostGroups;