import React, { useState } from 'react';
import ColorSelector from '../common/color-selector';
import GlueToggle from '../common/glue-toggle';
import GlueDropdown from '../common/glue-dropdown';
import DropdownSelectorInput from '../common/dropdown-selector-inputfield';

import { makeStyles } from '@material-ui/core';

import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';

import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight';

import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import VerticalAlignCenterIcon from '@material-ui/icons/VerticalAlignCenter';
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop';

import { GlueAccordion, GlueAccordionContentBlock } from '../common/glue-accordion';
import { fontFamilies, fontSizes, setFont, setFontSize, setNoteProperty, getCurrentFontSize } from '../../service/note-editor';
import { useNote } from './note-hooks';
import { Note } from './note-types';

const useStyles = makeStyles(theme => ({
	textProperty: {
		display: 'flex',
		justifyContent: 'flex-end',
		gap: theme.glueSpacing('m'),
	},
}));

const Font = (props: {
	note: Note | null
}) => 
{
	return (
		<GlueDropdown
			width={'304px'} 
			items={fontFamilies} 
			value={props.note?.fontFamily}
			onChange={(id, checked) => checked && setFont(id as string)}
		/>
	);
}

const Size = (props: {
	note: Note | null
}) => 
{
	const classes = useStyles();

	const [ text, setText ] = useState<string>('');

	const minFontSize = 1;
	const maxFontSize = 400;

	const getInputValue = () => {
		if (text)
		{
			return text;
		}
		else return props.note?.fontSize ?? 0;
	}

	const submit = () => {
		if (text)
		{
			let intValue = parseInt(text ? text : '0');
			intValue = Math.min(Math.max(intValue, minFontSize), maxFontSize);
			setNoteProperty('fontSize', intValue);
			setText('');
		}
	}

	return (
		<div className={classes.textProperty}>
			<DropdownSelectorInput
				contrast
				numbers={true}
				width={'160px'}
				items={fontSizes}
				value={getCurrentFontSize()}
				inputValue={getInputValue()}
				onInputChange={(val: string) => setText(val)}
				onChange={(id: string | number, checked: boolean) => checked && setFontSize(id as string)}
				onSubmit={submit}
				onBlur={submit}
			/>
		</div>
	);
}

const Style = (props: {
	note: Note | null
	editTextColor: () => void
}) => 
{
	const classes = useStyles();

	return (
		<div className={classes.textProperty}>
			<GlueToggle
				toggled={props.note?.fontWeight === 'bold'}
				onPointerDown={() => setNoteProperty('fontWeight', props.note?.fontWeight === 'bold' ? 'lighter' : 'bold')}
			>
				<FormatBoldIcon/>
			</GlueToggle>
			<GlueToggle
				toggled={props.note?.fontStyle === 'italic'}
				onPointerDown={() => setNoteProperty('fontStyle', props.note?.fontStyle === 'italic' ? '' : 'italic')}
			>
				<FormatItalicIcon/>
			</GlueToggle>
			<ColorSelector
				color={props.note?.fontColor ?? '#000'}
				onPointerDown={props.editTextColor}
			/>
		</div>	
	);
}

const Alignment = (props: {
	note: Note | null
}) => 
{
	const classes = useStyles();

	return (
		<div className={classes.textProperty}>
			<GlueToggle
				toggled={props.note?.horizontalTextAlignment === 'left'}
				onPointerDown={() => setNoteProperty('horizontalTextAlignment', 'left')}
			>
				<FormatAlignLeftIcon/>
			</GlueToggle>
			<GlueToggle
				toggled={props.note?.horizontalTextAlignment === 'center'}
				onPointerDown={() => setNoteProperty('horizontalTextAlignment', 'center')}
			>
				<FormatAlignCenterIcon/>
			</GlueToggle>
			<GlueToggle
				toggled={props.note?.horizontalTextAlignment === 'right'}
				onPointerDown={() => setNoteProperty('horizontalTextAlignment', 'right')}
			>
				<FormatAlignRightIcon/>
			</GlueToggle>
		</div>
	);
}

const VerticalAlignment = (props: {
	note: Note | null
}) => 
{
	const classes = useStyles();

	return (
		<div className={classes.textProperty}>
			<GlueToggle
				toggled={props.note?.verticalTextAlignment === 'flex-start'}
				onPointerDown={() => setNoteProperty('verticalTextAlignment', 'flex-start')}
			>
				<VerticalAlignTopIcon/>
			</GlueToggle>
			<GlueToggle
				toggled={props.note?.verticalTextAlignment === 'center'}
				onPointerDown={() => setNoteProperty('verticalTextAlignment', 'center')}
			>
				<VerticalAlignCenterIcon/>
			</GlueToggle>
			<GlueToggle
				toggled={props.note?.verticalTextAlignment === 'flex-end'}
				onPointerDown={() => setNoteProperty('verticalTextAlignment', 'flex-end')}
			>
				<VerticalAlignBottomIcon/>
			</GlueToggle>
		</div>	
	);
}

const TextStyle = (props: {
	open: boolean
	toggleOpen: () => void
	editTextColor: () => void
}) =>
{
	const note = useNote();

    return (
		<GlueAccordion title={"Text"} open={props.open} onToggleOpen={props.toggleOpen}>
			<GlueAccordionContentBlock label={"Font"} beginSubgroup>
				<Font note={note}/>
			</GlueAccordionContentBlock>
			<GlueAccordionContentBlock label={"Text Size"} beginSubgroup>
				<Size note={note}/>
			</GlueAccordionContentBlock>
			<GlueAccordionContentBlock label={"Text Style"} beginSubgroup>
				<Style note={note} editTextColor={props.editTextColor}/>
			</GlueAccordionContentBlock>
			<GlueAccordionContentBlock label={"Alignment"} beginSubgroup>
				<Alignment note={note}/>
			</GlueAccordionContentBlock>
			<GlueAccordionContentBlock>
				<VerticalAlignment note={note}/>
			</GlueAccordionContentBlock>
		</GlueAccordion>
    );

}

export default TextStyle;