import React from 'react';
import { makeStyles } from '@material-ui/core';
import WhiteCircle from '../../images/WhiteCircle.png';

const useStyles = makeStyles((theme) => ({
	// Ugly copypasta because of MUI bug where keyframes don't work if there's props...
	main: {
		...theme.custom.loadingIndicator.main,
		pointerEvents: 'none',
		
		animationName: '$spin',
		animationDuration: '1.5s',
		animationIterationCount: 'infinite',
		animationTimingFunction: 'linear'
	},

	icon: {
		...theme.custom.loadingIndicator.icon,
		pointerEvents: 'none',
		position: 'absolute',
		zIndex: '1',
		left: '-1px',
		bottom: '-1px',
		
		animationName: '$spin',
		animationDuration: '1.5s',
		animationIterationCount: 'infinite',
		animationTimingFunction: 'linear'
	},

	button: {
		...theme.custom.loadingIndicator.button,
		pointerEvents: 'none',
		
		animationName: '$spin',
		animationDuration: '1.5s',
		animationIterationCount: 'infinite',
		animationTimingFunction: 'linear'
	},

	'@keyframes spin': {
		from: {
			transform: 'rotate(0deg)'
		},
		to: {
			transform: 'rotate(360deg)'
		}
	}
}), { name: 'MuiGlueLoadingIndicator' });

const LoadingIndicator = (props) => {

	const classes = useStyles();

	return (
		<img className={classes[props.variant ?? 'main']} src={WhiteCircle} alt=''/>
	);
};

export default LoadingIndicator;
