import React from 'react';

import { makeStyles, ButtonBase } from '@material-ui/core';
import { hoverAudio, clickAudio } from './common-vuplex-messages';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const useStyles = makeStyles(theme => ({
	stack: {
		display: 'flex',
		flexDirection: 'column',
		gap: theme.glueSpacing('m')
	},

	root: {
		...theme.custom.glueAccordion.root,
		borderRadius: theme.custom.glueAccordion.borderRadius,
		background: theme.palette.background.paper,
	},

	header: {
		...theme.custom.glueAccordion.header,
		borderTopLeftRadius: theme.custom.glueAccordion.borderRadius,
		borderTopRightRadius: theme.custom.glueAccordion.borderRadius,
		borderBottomLeftRadius: (props) => props.open ? '0' : theme.custom.glueAccordion.borderRadius,
		borderBottomRightRadius: (props) => props.open ? '0' : theme.custom.glueAccordion.borderRadius,
		paddingLeft: theme.glueSpacing('l'),
		background: theme.palette.background.paper,
		display: 'flex',
		'&:hover': {
			filter: 'brightness(1.25)'
		},
		'&:active': {
			filter: 'brightness(1.50)'
		},
		'& > h2': {
			marginTop: 'auto',
			marginBottom: 'auto',
		},
	},
	
	content: {
		paddingLeft: theme.glueSpacing('l'),
		paddingRight: theme.glueSpacing('s'),
		paddingBottom: theme.glueSpacing('s'),
		'& > :first-child':{ // First block always has a small gap
			marginTop: theme.glueSpacing('s')
		}
	},

	contentBlock: {
		display: 'flex',
		marginTop: props => props.beginSubgroup ? theme.glueSpacing('l') : theme.glueSpacing('s')
	},

	contentLabelContainer: {
		height: theme.custom.glueAccordion.contentLabel.height,
		textTransform: 'capitalize',
		display: 'flex',
		'& > h3': {
			marginTop: 'auto',
			marginBottom: 'auto',
		},
	},

	contentItemContainer: {
		marginLeft: 'auto'
	},

	icon: {
		width: theme.custom.glueAccordion.header.height,
		height: theme.custom.glueAccordion.header.height,
		marginLeft: 'auto',
		display: 'flex',
		'& > :first-child': {
			margin: 'auto',
			background: theme.palette.background.paper,
		}
	},
}), { name: 'MuiGlueAccordion' });

export const GlueAccordionContentBlock = (props) =>
{
	const classes = useStyles(props);

	return (
		<div className={classes.contentBlock}>
			<div className={classes.contentLabelContainer}>
				<h3>{props.label}</h3>
			</div>
			<div className={classes.contentItemContainer}>
				{props.children}
			</div>
		</div>
	);
}

export const GlueAccordion = (props) =>
{
	const classes = useStyles(props);
	const clickAudioMessage = "Menu/Generic/Button/Press";
	const hoverAudioMessage = "Menu/Generic/Button/HL";

	const toggleOpen = (event) => {
		event.stopPropagation();

		if (props.onToggleOpen)
			props.onToggleOpen();
		
		clickAudio(clickAudioMessage);
	}

	return (
		<div className={classes.root}>
			<ButtonBase className={classes.header} onPointerDown={toggleOpen} onPointerEnter={() => {hoverAudio(hoverAudioMessage)}}>
				<h2>{props.title}</h2>
				<div className={classes.icon}>
					{props.open ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
				</div>
			</ButtonBase>
			{props.open && (<div className={classes.content}>
				{props.children}
				</div>)}
		</div>
	);
}

export const GlueAccordionStack = (props) =>
{
	const classes = useStyles();

	return (
		<div className={classes.stack}>
			{props.children}
		</div>
	);
}