import React from 'react';
import { useNavigate } from 'react-router-dom';
import HeroIcon from './common/hero-icon';
import BrowserIcon from '../icons/hero/Browser.png';
import { DefaultUrl } from './browser/glue-urls';
import { useQuery } from '@apollo/client';
import queries from '../graphql/queries';

// Ugly copypasta, basically the same as prod tool icon but with less features
const BrowserHeroIcon = (props: {
	disabled?: boolean
}) =>
{
	const hoveraudiomessage = "Menu/Browser/HL";
	const audiomessage = "Menu/Browser/Press";
	const navigate = useNavigate();

	const appname = 'Browser';

	const instanceId = window.sessionStorage.getItem('instance-id');
	const latestUrlRes = useQuery<{ browserLatestUrl: string }>(queries.browserLatestUrl, {
		skip: !instanceId,
		variables: {
			instanceId: instanceId,
			appId: appname
		}
	});

	return (
		<HeroIcon
			name="Browser"
			icon={BrowserIcon} 
			onPointerDown={() =>
				{
					navigate(
						'/app/web-control', {
							state: { appname: appname, appurl: latestUrlRes.data?.browserLatestUrl ?? DefaultUrl, isProdTool: false }
						}
					);
				}
			} 
			disabled={props.disabled}
			uiAudioMessage={audiomessage}
			uiHoverAudioMessage={hoveraudiomessage} 
		/>
	);
}

export default BrowserHeroIcon;