import React, { ChangeEvent, useRef, useState } from 'react';
import { LinearProgress, makeStyles, Typography } from '@material-ui/core';
import GlueButton from '../common/glue-button';
import { Asset } from '../../graphql/types-generated';
import PublishIcon from '@material-ui/icons/Publish';
import GlueIcon from '../common/glue-icon';
import { getMetadataFromSpaceAssetPackage } from '../../util/space-asset-package-utils';
import { getHumanizedFileSize } from '../../util/file-utils';

export type SAUPHandlingStatus = 'mutating' | 'uploading' | 'processing';

const useStyles = makeStyles((theme) => ({
	root: {
		textAlign: 'center',
		display: 'flex',
		flexFlow: 'column',
		gap: theme.glueSpacing('m')
	},

	errorText: {
		color: theme.palette.error?.main
	},

	input: {
		display: 'none'
	},

	upload: {
		height: '48px',
		display: 'flex',
		flexGrow: 0,
		gap: theme.glueSpacing('m'),
		alignItems: 'center',
		justifyContent: 'space-between',
	},

	progress: {
		width:'100%',
	},

	assetDetails: {
		overflow: 'hidden',
		textAlign: 'left',
		whiteSpace: 'nowrap',

		'& p': {
			overflow: 'hidden',
			textOverflow: 'ellipsis'
		}
	},

	textSecondary: {
		opacity: '60%'
	}
}), { name: 'MuiGlueSimpleSAUP' });

const SimpleSAUP = (props: {
	asset?: Asset
	teamId: string
	handlingState?: SAUPHandlingStatus
	progress: number
	package?: File
	lastModified?: string
	errorMsg?: string
	onPackageSelected: (packageFile?: File) => void
}) =>
{
	const classes = useStyles();
	const inputRef = useRef<HTMLInputElement>(null);
	const [ error, setError ] = useState<string>('');

	const validateSceneName = (name?: string) => {
		if (!name) {
			return false;
		}

		if (!!props.asset && name !== props.asset.sceneName) {
			return false;
		}

		return true;
	}

	const selectAssetPackage = async (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		if ('files' in event.target && event.target.files) {
			if (event.target.files.length > 1) {
				setError('Please select one file');
				return;
			}
			const file = event.target.files[0];
			if (file.type !== 'application/x-tar') {
				setError('Please choose a valid .tar file');
				return;
			}

			const metadata = await getMetadataFromSpaceAssetPackage(file);
			if (!metadata) {
				setError('Invalid asset package');
				return;
			}

			if (!validateSceneName(metadata.sceneName)) {
				setError('Scene name must match existing scene name');
				return;
			}
			
			setError('');
			props.onPackageSelected(file);
		}
	}

	const errorMsg = !!props.errorMsg ? props.errorMsg : (!!error ? error : '');

	return (
		<div className={classes.root}>
			<input
				className={classes.input}
				type="file"
				multiple={false}
				accept="application/x-tar"
				ref={inputRef}
				onChange={selectAssetPackage}
			/>
			<div>
				<h3>Asset package:</h3>
				{!!props.lastModified && <Typography variant='overline'>Last modified {props.lastModified}</Typography>}
			</div>
			<div className={classes.upload}>
				{!!props.handlingState && props.handlingState !== 'mutating' ? (
					<div className={classes.progress}>
						<LinearProgress 
							variant={props.handlingState === 'processing' ? 'indeterminate' : 'determinate'} 
							value={props.progress}
						/>
					</div>
				) : (<>
					<div className={classes.assetDetails}>
						{(!props.package) ? 
							!props.asset ? <p className={classes.textSecondary}>No package added yet.</p> : (<>
								<p>Scene name: {props.asset.sceneName}</p>
								<p className={classes.textSecondary}>Asset ID: {props.asset.assetId.substring(6)}</p>
							</>) : (<>
							<p>{props.package.name}</p>
							<p className={classes.textSecondary}>{getHumanizedFileSize(props.package.size)}</p>
						</>)}
					</div>
					<GlueButton
						variant='icon'
						disabled={!!props.handlingState}
						onPointerDown={() => inputRef.current?.click()}
					>
						<GlueIcon><PublishIcon /></GlueIcon>
					</GlueButton>
				</>)}
			</div>
			{!!errorMsg && <p className={classes.errorText}>{errorMsg}</p>}
		</div>
	);
}

export default SimpleSAUP;