import React, { useEffect, useRef, useState } from "react";
import { LinearProgress, makeStyles } from "@material-ui/core";
import GlueButton from "../common/glue-button";

import { usePromptDialogContext } from "../../util/prompt-dialog-context";
import { useApolloClient } from "@apollo/react-hooks";
import { useQuery } from "@apollo/react-hooks";
import queries from "../../graphql/queries";
import { clickAudio } from "../common/common-vuplex-messages";
import { joinSpace, startSession } from "../../util/space-utils";
import { Room, SessionData, SpaceTemplateDownloadState } from '../../graphql/types-generated';
import GlueRadioGroup from "../../standalone-web/common/glue-radio-group";
import postVuplexMessage from "../../service/message-vuplex";

type SessionUIStatus = {
	data?: SessionData,
	errorMsg?: string,
	statusMsg?: string
}

const useStyles = makeStyles((theme) => ({
	clippedMedia: {
		clipPath: `circle(${theme.custom.gotoSpaceDialog.circleRadius} at center)`,
		height: `calc(${theme.custom.gotoSpaceDialog.circleRadius} * 2)`,
		width: '100%',
		objectFit: 'contain',
	},

	root: 
	{
		...theme.custom.infoDialog.root,
		width: theme.custom.gotoSpaceDialog.width,
		overflow: 'hidden',
	},

	header:
	{
		...theme.custom.infoDialog.header,
		height: theme.custom.icon.height,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},

	spaceName: 
	{
		...theme.custom.infoDialog.header,
		margin: `${theme.glueSpacing('xl')} ${theme.glueSpacing('m')} !important`,

		'& h1': {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
	},

	body: {
		...theme.custom.infoDialog.body,
		display: 'flex',
		flexFlow: 'column',
		gap: theme.glueSpacing('m'),
	},

	othersToggle: {
		display: 'flex',
		justifyContent: 'center',
	},

	currentSpace: {
		display: 'flex',
		flex: '1 0 auto',
		flexDirection: 'column',
		justifyContent: 'space-between'
	},

	alertText: {
		textAlign: 'center',
		color: theme.palette.warning.main,
		margin: 'auto',
		marginBottom: theme.glueSpacing('m'),
		width: '80%'
	},

	buttons: theme.custom.infoDialog.buttons,

	download: {
		display: 'flex',
		gap: theme.glueSpacing('s'),
		flexFlow: 'column',
		paddingBottom: theme.glueSpacing('xs'),
	},

	errorText: {
		color: theme.palette.error.main,
	},
}), { name: 'MuiGlueGotoSpaceDialog' });

const GotoSpaceDialog = (props: {
	web?: boolean
	thumbnailUrl: string
	roomInfo: Room
	currentRoomServerKey: string
}) => {

	const classes = useStyles();
	const apollo = useApolloClient();
	const { data } = useQuery(queries.takeGroupValue);

	const abortRef = useRef(new AbortController());
	const [autoEnter, setAutoEnter] = useState(false);
	const [sessionStatus, setSessionStatus] = useState<SessionUIStatus>();

	const sceneUrl = props.roomInfo.sceneUrl ?? '';
	const cachedTemplatesRes = useQuery<{ cachedSpaceTemplateUrls: string[] }>(queries.cachedSpaceTemplateUrls);
	const cachedTemplateUrls = cachedTemplatesRes.data?.cachedSpaceTemplateUrls ?? [];
	const templateCached = !!cachedTemplateUrls && !!sceneUrl && cachedTemplateUrls.some(url => url === sceneUrl);

	const templateDownloadRes = useQuery<{ spaceTemplateDownloadState: SpaceTemplateDownloadState }>(queries.spaceTemplateDownloadState);
	const templateDownloadState = templateDownloadRes.data?.spaceTemplateDownloadState;
	const downloadStatus = !!templateDownloadState ? templateDownloadState.status : undefined;
	const downloadingThisTemplate = !!templateDownloadState && templateDownloadState?.sceneUrl.localeCompare(sceneUrl) === 0;
	const downloadProgress = templateDownloadState?.progress ?? 0;

	const { closePromptDialog } = usePromptDialogContext();

	const onSessionStartSuccess = (res: SessionData) => {
		setSessionStatus({ data: res });
	}

	const onSessionStartError = (error: Error) => {
		setSessionStatus({ errorMsg: error.message });
	}

	const onSessionStatus = (status: string) => {
		setSessionStatus({ statusMsg: status });
	}

	useEffect(() => {
		if (props.web) {
			return;
		}
		if (!props.roomInfo.serverKey) {
			return;
		}
		
		startSession(props.roomInfo.serverKey, onSessionStatus, abortRef.current.signal).then(onSessionStartSuccess).catch(onSessionStartError);

		return () => {
			if (!!props.roomInfo.serverKey) {
				abortRef.current.abort();
			}
		};
	}, [props.web, props.roomInfo.serverKey]);

	const options = [
		{ id: 0, name: 'Just me' },
		{ id: 1, name: 'Everyone' }
	]

	const toggleOthers = (checked: boolean) =>
	{
		apollo.writeQuery({
			query: queries.takeGroupValue,
			data: { takeGroupValue: !data.takeGroupValue }
		});

		const toggleaudiomessage = data.takeGroupValue ? "Menu/TeamSpaces/Team/JustMe" : "Menu/TeamSpaces/Team/Everyone";
		clickAudio(toggleaudiomessage);
	};

	const onClickCancel = () => {
		closePromptDialog(); 
	}

	const onClickGo = async () => {
		if (templateCached) {
			if (!props.roomInfo.serverKey) {
				return;
			}

			if (!sessionStatus?.data) {
				startSession(props.roomInfo.serverKey, onSessionStatus, abortRef.current.signal).then(onSessionStartSuccess).catch(onSessionStartError);
				return;
			}

			closePromptDialog(); 
			joinSpace(props.roomInfo, sessionStatus.data, data.takeGroupValue);
		}
		else {
			postVuplexMessage('Space.DownloadTemplate', sceneUrl);
			setAutoEnter(true);
		}
	}

	useEffect(() => {
		if (!props.roomInfo.roomId || !sessionStatus?.data || !templateCached) return;

		if (autoEnter) {
			closePromptDialog();
			joinSpace(props.roomInfo, sessionStatus.data,  data.takeGroupValue);
			setAutoEnter(false);
		}
	}, [templateCached, autoEnter, sessionStatus]);

	const cancelaudiomessage = "Menu/TeamSpaces/Team/Cancel/Press";
	const cancelhoveraudiomessage = "Menu/TeamSpaces/Team/Cancel/HL";
	const goaudiomessage = "Menu/TeamSpaces/Team/Go/Press";
	const gohoveraudiomessage = "Menu/TeamSpaces/Team/Go/HL";

	const bodyContent = (
		<>
			{props.web ? (
				<div className={classes.alertText}>
					<p>Please use the standalone Glue app if you want to access team spaces.</p>
				</div>
			) : props.currentRoomServerKey === props.roomInfo.serverKey ? (
				<p>You are here.</p>
			) : (
				<>
					<div className={classes.othersToggle}>
						<GlueRadioGroup 
							horizontal
							options={options}
							value={data.takeGroupValue ? 1 : 0}
							onChange={toggleOthers}
						/>
					</div>
					{!!downloadStatus && downloadStatus !== 'finished' ? (
						<div className={classes.download}>
							{downloadStatus === 'failed' ? (
								<>
									{downloadingThisTemplate && (
										<p className={classes.errorText}>
											{templateDownloadState?.error?.includes('corrupted') ? 
												'Space template validation failed' : 
												'Space template download failed'}
										</p>
									)}
								</>
							) : (
								<>
									{downloadingThisTemplate ? (
										<p>Downloading space template...</p>
									) : (
										<p>Waiting for another download to finish...</p>
									)}
									<LinearProgress 
										variant='determinate'
										value={downloadProgress}
									/>
								</>
							)}
						</div>
					) : !!templateCached && (!!sessionStatus?.errorMsg ? (
							<p className={classes.errorText}>{sessionStatus.errorMsg}</p>
						) : !!sessionStatus?.statusMsg && (
							<div className={classes.download}>
								<p>{sessionStatus.statusMsg}</p>
								<LinearProgress variant='indeterminate' />
							</div>
						))}
				</>
			)}
		</>
	);
	
	const buttonsContent = (
		<>
			{props.web || props.currentRoomServerKey === props.roomInfo.serverKey ? (
				<GlueButton
					onPointerDown={onClickCancel}
					uiAudioMessage = {goaudiomessage}
					uiHoverAudioMessage = {gohoveraudiomessage}
					color="primary"
				>
					OK
				</GlueButton>
			) : (
				<>
					<GlueButton
						onPointerDown={onClickCancel}
						uiAudioMessage = {cancelaudiomessage}
						uiHoverAudioMessage = {cancelhoveraudiomessage}
					>
						Cancel
					</GlueButton>
					
					<GlueButton
						disabled={downloadStatus === 'downloading' || (templateCached && !sessionStatus?.data && !sessionStatus?.errorMsg)}
						onPointerDown={onClickGo}
						uiAudioMessage = {goaudiomessage}
						uiHoverAudioMessage = {gohoveraudiomessage}
						color="primary"
					>
						{(downloadingThisTemplate && downloadStatus === 'failed') || (downloadStatus !== 'downloading' && !!sessionStatus?.errorMsg) ? 'Retry' : 'Go'}
					</GlueButton>
				</>
			)}
		</>
	)

	return (
		<div className={classes.root}>
			<div className={classes.header}>
				<h2>Go to this space</h2>
			</div>
			<img className={classes.clippedMedia} src={props.thumbnailUrl} alt={""}/>
			<div className={classes.spaceName}>
				<h1>
					{props.roomInfo.name}
				</h1>
			</div>
			
			<div className={classes.body}>
					{bodyContent}
			</div>

			<div className={classes.buttons}>
				{buttonsContent}
			</div>
		</div>
	);
}

export default GotoSpaceDialog;
