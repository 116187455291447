import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core";
import { useApolloClient, useMutation, useQuery } from "@apollo/react-hooks";

import mutations from "../../graphql/mutations";
import queries from "../../graphql/queries";

import { usePromptDialogContext } from "../../util/prompt-dialog-context";
import { useUserContext } from '../../util/user-context';

import InfoDialogTemplate from "../common/info-dialog-template";
import GlueInputfield from "../common/glue-inputfield";
import WebInputfield from "../../standalone-web/common/web-inputfield";
import GlueButton from "../common/glue-button";
import GlueDropdown from "../common/glue-dropdown";
import SwitchSelector from "../common/switch-selector";

import { teamAccessType } from "../../defines";

const useStyles = makeStyles((theme) => ({
	root: {
		width: theme.custom.createTeamDialog.width,
		display: 'flex',
		flexDirection: 'column',
		gap: theme.glueSpacing('xl')
	},

	header: {
		textAlign: 'center'
	},

	input: {
		display: 'flex',
		flexFlow: 'column',
		gap: theme.glueSpacing('xs'),
	},

	switch: {
		display: 'flex',
		justifyContent: 'center',
	},

	body: {
		padding: `0 ${theme.glueSpacing('l')}`,
		display: 'flex',
		flexDirection: 'column',
		gap: theme.glueSpacing('xl'),
		alignItems: 'stretch'
	},

	buttons: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		gap: theme.glueSpacing('s'),
		marginTop: 'auto',
	},

	'@keyframes opacity': {
		'0%': {
			opacity: 1
		},
		'50%': {
			opacity: 0.25
		},
		'100%': {
			opacity: 1
		}
	}
}));

export const isValidTeamName = async (teamName, apollo, orgId) =>
{
	const teamNameTrimmed = teamName.trim();

	if (teamNameTrimmed.length === 0) {
		return false;
	}

	const existingNames = await apollo.query({
		query: queries.getOrgTeams,
		variables: {
			orgId: orgId,
			first: 0
		}
	});

	if (existingNames.data?.getOrgTeams.filter(team => team.name === teamNameTrimmed).length > 0) {
		return false;
	}

	return true;
}

const CreateTeamDialog = (props) =>
{
	const classes = useStyles();
	const theme = useTheme();
	const user = useUserContext();
	const apollo = useApolloClient();

	const uiMode = useQuery(queries.ui);
	const web = uiMode.data?.ui === 'web';

	const { addDialog, closePromptDialog } = usePromptDialogContext();

	const [ teamName, setTeamName ] = useState('');
	const [ cluster, setCluster ] = useState();
	const [ teamAccess, setTeamAccess ] = useState(teamAccessType.find(item => item.name === 'Private').id);

	const [createTeam] = useMutation(mutations.createTeam);

	const clusters = useQuery(queries.myClusters,
		{
			variables: {
				userRole: '',
				orgId: props.orgId
			}
		});

	useEffect(() => {
		if (user.administrator) {
			clusters.refetch({ userRole: 'administrator' })
		}
	}, [user.administrator, clusters])

	const clusterSelection = clusters.data ? 
		clusters.data.myClusters.map((item, i) => (
			{ id: item.clusterId, name: item.name }
		)) : [];

	const createNewTeam = async (teamName, clusterId, accessType) => {
		if (!clusterId) {
			console.error("No cluster ID!");
			return;
		}
		const valid = await isValidTeamName(teamName, apollo, props.orgId);
		if (!valid) {
			console.error("Team must have a valid name");
			addDialog(<InfoDialogTemplate
				isError={true}
				message={'The team name must contain 1-60 characters and be unique in your organization.'}
			/>)
			return;
		}
		await createTeam({
			variables: {
				orgId: props.orgId,
				name: teamName,
				defaultClusterId: clusterId,
				streamingServerUrl: null,
				teamAccessType: accessType
			}
		}).then(res => {
			console.log(res.data)
			if (props.refetch)
				props.refetch();
		}).catch(err => {
			addDialog(
				<InfoDialogTemplate
					isError={true}
					message={"ERROR!!! " + err.message}
				/>
			)
		});
	}

	const switchAccessType = (checked) =>
	{
		if (checked)
		{
			setTeamAccess(teamAccessType.find(item => item.name === 'Private').id);
		}
		else
		{
			setTeamAccess(teamAccessType.find(item => item.name === 'Public').id);
		}
	}

	return(
		<div className={classes.root}>
			<div className={classes.header}>
				<h2>Create a new team</h2>
			</div>
			<div className={classes.body}>
				<div className={classes.input}>
					<p>Team name</p>
					{web ? (
						<WebInputfield
							width={theme.custom.createTeamDialog.inputWidth}
							maxLength={60}
							onChange={(e) => setTeamName(e)}
						/>
					) : (
						<GlueInputfield
							simpleInput
							width={theme.custom.createTeamDialog.inputWidth}
							value={teamName}
							maxLength={60}
							onChange={(e) => setTeamName(e)}
						/>
					)}
				</div>
				<div className={classes.switch}>
					<SwitchSelector 
						text="Private team" 
						checked={teamAccess === teamAccessType.find(item => item.name === 'Private').id}
						onChange={switchAccessType}
					/>
				</div>
				<div className={classes.input}>
					<p>Select region</p>
						<GlueDropdown 
							width={theme.custom.createTeamDialog.inputWidth}
							label={'Select regional cluster'}
							items={clusterSelection}
							onChange={(id, checked) => { checked && setCluster(id)}}
						/>
				</div>
			</div>
			<div className={classes.buttons}>
				<GlueButton onPointerDown={() => closePromptDialog()}>Cancel</GlueButton>
				<GlueButton 
					disabled={!clusters.data || !cluster} 
					color="primary" 
					onPointerDown={() => { createNewTeam(teamName, cluster, teamAccess); closePromptDialog() }}
				>
					Create
				</GlueButton>
			</div>
		</div>
	)
}

export default CreateTeamDialog;
