import { CSSProperties } from "@material-ui/core/styles/withStyles";

export type Type = {
	root: CSSProperties
	header: CSSProperties
	icon: CSSProperties
	body: CSSProperties
	variableString: CSSProperties
	buttons: CSSProperties
};

const common: Type = {
	root: {
		display: 'flex',
		flexFlow: 'column',
		width: '368px',
		justifyContent: 'center',
	},

	header: {
		textAlign: 'center',
		margin: '0 24px', // Should be spacing('l')
		marginBottom: '16px'
	},

	icon: {
		width: '80px',
		height: '80px',
		margin: 'auto',
		marginBottom: '16px'
	},

	body: {
		textAlign: 'center',
		margin: '0 24px', // Should be spacing('l')
		whiteSpace: 'pre-line'
	},

	variableString: {
		textAlign: 'center',
		marginTop: '16px'
	},

	buttons: {
		margin: 'auto',
		marginTop: '36px', // Should be spacing('xl')
		width: 'fit-content',
		display: 'grid',
		gridAutoFlow: 'column',
		gap: '16px' // Should be spacing('m')
	}
};

export const web: Type = {
	...common
};

export const tablet: Type = {
	root: {
		display: 'flex',
		flexFlow: 'column',
		width: '500px',
		justifyContent: 'center'
	},

	header: {
		textAlign: 'center',
		margin: '0 36px',
		marginBottom: '48px'
	},

	icon: {
		width: '102px',
		height: '102px',
		margin: 'auto',
		marginBottom: '24px'
	},

	body: {
		textAlign: 'center',
		margin: '0 36px',
		whiteSpace: 'pre-line'
	},

	variableString: {
		textAlign: 'center',
		marginTop: '24px'
	},

	buttons: {
		margin: 'auto',
		marginTop: '48px',
		width: 'fit-content',
		display: 'grid',
		gridAutoFlow: 'column',
		gap: '24px'
	}
};
