import { CSSProperties } from "@material-ui/core/styles/withStyles";

export type Type = CSSProperties;

const common: Type =
{
	height: '64px',
};

export const web: Type =
{
	...common,

	height: '36px',
};

export const tablet: Type =
{
	...common
};
