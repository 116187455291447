import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { makeStyles, Typography } from "@material-ui/core";

import queries from "../../graphql/queries";

import { Route, useNavigate, Navigate, Routes } from "react-router-dom";

import OrgTeams from "../organization/org-teams";
import OrgMembers from "../organization/org-members";

import LoadingIndicator from "../common/loading-indicator";
import ViewHeader from "./view-header";
import OrgIcon from "../organization/org-icon";
import { useUserContext } from "../../util/user-context";
import TabHeader from "../common/tab-header";
import { licenseTiers } from "../../defines";

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		...theme.custom.contentBase,
		margin: 'auto',
		height: 'inherit'
	},

	rootLoading: {
		margin: 'auto',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		minHeight: '600px'
	},

	iconHeader: {
		display: 'flex',
		flexDirection: 'row',
		gap: theme.glueSpacing('m'),
		justifyContent: 'center',
		alignItems: 'center'
	},

	licenseInfo: {
		paddingRight: `calc(${theme.custom.icon.width} / 2)`,
		display: 'flex',
		flexFlow: 'column nowrap',
		textAlign: 'end',
	},

	contentArea: {
		...theme.custom.contentBase,
		height: '100%',
		margin: 'auto',
		display: 'flex',
		justifyContent: 'center',
		padding: `0 ${theme.glueSpacing('s')} 0`,
		...theme.custom.view.mainContent
	},

}), { name: 'MuiGlueOrganizationView' });

type OrganizationViewProps = {
	web?: boolean
}

const OrganizationView = (props: OrganizationViewProps) => {
	const classes = useStyles();
	const navigate = useNavigate();

	const user = useUserContext();

	const currentOrg = useQuery(queries.currentOrgId);
	const getOrgRes = useQuery(queries.getOrg, {
		skip: !currentOrg.data,
		variables: {
			orgId: currentOrg.data.currentOrgId
		}
	});

	const subscriptioRes = useQuery(queries.subscriptionOfOrg, {
		skip: !currentOrg.data,
		variables: {
			orgId: currentOrg.data.currentOrgId
		}
	});

	if (!user.org?.orgInfoRead)
	{
		navigate('/');
	}

	const tabInfos = [
		{ path: 'teams', name: "Teams"},
		{ path: 'members', name: "Users"},
	];

	const orgHeader = (
		<div className={classes.iconHeader}>
			<OrgIcon
				id={currentOrg.data?.currentOrgId.slice(4)}
				name={getOrgRes.data?.getOrg.name}
				// typeVariant="h1"
			/>
			<h2>{getOrgRes.data?.getOrg.name}</h2>
		</div>
	);

	const licenseInfo = (
		<div className={classes.licenseInfo}>
			<Typography variant='overline'>
				{licenseTiers.find(tier => tier.id === subscriptioRes.data?.subscriptionOfOrg?.licenseTier)?.name ?? 'Unknown'} license
			</Typography>
			<Typography variant='overline'>
				{getOrgRes.data?.getOrg?.members?.length ?? "-"} users / {getOrgRes.data?.getOrg?.teams?.length ?? "-"} team(s)
			</Typography>
		</div>
	);

	if (getOrgRes.loading) {
		return (
			<div className={classes.rootLoading}>
				<LoadingIndicator />
			</div>
		)
	}

	return(
		<>
			{getOrgRes.data && (
				<ViewHeader
					onClose={() => navigate('/')}
					title={'Org View'}
					centerContent={orgHeader}
					rightContent={user.org?.orgInfoRead ? licenseInfo : null}
				/>
			)}

			<TabHeader tabInfos={tabInfos} />

			<div className={classes.contentArea}>
				<Routes>
					<Route path={'/'} element={<Navigate to={'teams'} />} />
					<Route path={'members'} element={<OrgMembers web={props.web} />} />
					<Route path={'teams'} element={<OrgTeams web={props.web} />} />
				</Routes>
			</div>
		</>
	)
}

export default OrganizationView;