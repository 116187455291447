import { ApolloClient, useApolloClient, useMutation, useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import mutations from '../graphql/mutations';
import queries from '../graphql/queries';
import LoadingIndicator from './common/loading-indicator';
import PageSelector from './common/page-selector';
import PdfViewer from './files/pdf-viewer';
import AppHeader from './common/app-header';
import postVuplexMessage from '../service/message-vuplex';
import SwitchSelector from './common/switch-selector';
import CloseButton from './common/close-button';
import GlueButton from './common/glue-button';
import { maxAndroidImageSize } from '../defines';
import { InventoryItem, QueryInventoryItemInfoArgs, QuerySessionObjectInfoArgs, SessionObject } from '../graphql/types-generated';
import { usePromptDialogContext } from '../util/prompt-dialog-context';
import { showClosePresentationControlsDialog } from '../util/sharing-utils';

type DocumentStyleProps = {
	controlsVisible: boolean,
	documentType: DocumentType
}

type DocumentState = {
	sharing: boolean
}

export enum DocumentType { Image, PDF }

const useStyles = makeStyles(theme => ({
	root: (props: DocumentStyleProps) => ({
		height: props.controlsVisible ? '' : '100%',
		display: 'flex',
		flexFlow: 'column nowrap',
		justifyContent: 'center',
		alignItems: 'center',
	}),

	header: (props: DocumentStyleProps) => ({
		display: props.controlsVisible ? 'grid' : 'none',
		width: '100%',
		height: theme.custom.header.height,
		minHeight: theme.custom.header.height,
		padding: theme.custom.header.padding,
		// This is kind of bad, but I don't know how else to do it
		background: `linear-gradient(${theme.palette.background.paper}, ${theme.palette.background.default})`,

		gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'center',
		margin: 0,
	}),

	pdfContainer: (props: DocumentStyleProps) => ({
		display: props.controlsVisible ? 'none' : 'null',
		width: '100%',
		flex: '1 1 auto',
	}),

	imageContainer: (props: DocumentStyleProps) => ({
		width: '100%',
		display: props.controlsVisible ? 'none' : 'null',
		flex: '1 1 auto',
		overflow: 'hidden',

		'& img': {
			width: '100%',
			height: '100%',
			objectFit: 'contain',
		}
	}),

	itemsLeft: {
		display: 'flex',
		gap: theme.glueSpacing('m')
	},

	itemsRight: {
		display: 'flex',
		gap: theme.glueSpacing('m'),
		marginLeft: 'auto'
	},

	emptyContainer: {
		width: '100%',
		height: '880px',
	}
}));

const contentChanged = (oldURL: URL | null, newURL: URL | null) => {
	return (oldURL !== newURL) && (oldURL?.pathname !== newURL?.pathname || oldURL?.origin !== newURL?.origin);
};

const Document = () => {
	const apollo = useApolloClient();

	const dialogContext = usePromptDialogContext();

	const sessionId = window.sessionStorage.getItem('sessionId');
	const objectId = window.sessionStorage.getItem('objectId');
	const inventoryItemId = window.sessionStorage.getItem('inventoryItemId') ?? '';

	const itemRes = useQuery<{ inventoryItemInfo: InventoryItem }, QueryInventoryItemInfoArgs>(
		queries.inventoryItemInfo,
		{
			skip: !inventoryItemId,
			variables: { inventoryItemId: inventoryItemId }
		}
	);

	const itemInfo = itemRes?.data?.inventoryItemInfo;
	if (!itemInfo)
		console.error('Failed to fetch item info', inventoryItemId);
	const itemName = itemInfo?.name ?? '';

	const controlsVisible = window.sessionStorage.getItem('document-controls') === 'true';

	const clientPlatformResult = useQuery(queries.clientPlatform);
	const clientPlatform = clientPlatformResult.data?.clientPlatform;

	const [documentType, setContentMode] = useState<DocumentType>(DocumentType.Image);
	const [contentURL, setContentURL] = useState<URL | null>(null);
	const [pageCountTotal, setPageCountTotal] = useState<number>(0);
	const [pageLoading, setPageLoading] = useState(false);

	const presentAudioMessage = "Menu/TeamFiles/ImageViewer/Present/Press";
	const presentHoverAudioMessage = "Menu/TeamFiles/ImageViewer/Present/HL";
	const stopPresentingAudioMessage = "Menu/TeamFiles/ImageViewer/StopPresenting/Press";
	const stopPresentingHoverAudioMessage = "Menu/TeamFiles/ImageViewer/StopPresenting/HL";
	const importaudiomessage = "Menu/TeamFiles/ImageViewer/Import/Press";
	const importhoveraudiomessage = "Menu/TeamFiles/ImageViewer/Import/HL";

	const [mutateSessionObject] = useMutation(mutations.updateSessionObjectInfo, {
		refetchQueries: [{
			query: queries.sessionObjectInfo,
			variables: {
				sessionId: sessionId,
				objectId: objectId,
			},
			fetchPolicy: 'network-only'
		}]
	});

	const classes = useStyles({ controlsVisible: controlsVisible, documentType: documentType });

	const instanceId = window.sessionStorage.getItem('instance-id');
	const documentStateRes = useQuery<{ documentState: DocumentState }>(queries.documentState, {
		variables: {
			instanceId: instanceId
		}
	});

	const documentState = documentStateRes.data?.documentState;
	if (!documentState) {
		console.log('Document: Requesting state for', instanceId);
		postVuplexMessage('Document.StateRequest', { instanceId: instanceId });
	}

	const importingRes = useQuery<{ documentImportActive: boolean }>(queries.documentImportActive, {
		variables: {
			instanceId: instanceId
		}
	});
	const importing = !!importingRes.data?.documentImportActive;

	const soiRes = useQuery<{ sessionObjectInfo?: SessionObject }, QuerySessionObjectInfoArgs>(queries.sessionObjectInfo, {
		skip: !sessionId || !objectId,
		variables: {
			sessionId: sessionId,
			objectId: objectId
		},
		fetchPolicy: 'network-only'
	});

	const soi = soiRes.data?.sessionObjectInfo;
	const pageNumber = (soi?.pageNumber ?? 0) - (soi?.pageNumberBase ?? 0);

	const closeInstance = () => {
		const instanceId = window.sessionStorage.getItem('instance-id');
		if (instanceId)
			postVuplexMessage('Document.Close', { instanceId: instanceId });
	};

	const changePage = async (pageNumber: number) => {
		if (pageLoading || !soi) {
			return;
		}

		setPageLoading(true);
		mutateSessionObject({
			variables: {
				sessionId,
				objectId,
				pageNumber,
			}
		}).then(res => {
			postVuplexMessage('Document.RefreshRequest', { instanceId: instanceId });
		}).catch(e => {
			console.error("Updating synced presentation state failed", e);
		}).finally(() => {
			setPageLoading(false);
		});
	};

	const setPresentation = (value: boolean) => {
		console.log('setPresentation', instanceId, 'active', value);
		if (!instanceId) {
			console.error("Instance ID not defined.");
			return;
		}

		postVuplexMessage('Document.SetPresentationActive', { instanceId: instanceId, active: value });
	};

	const importDocument = async (inventoryItemId: string, apollo: ApolloClient<unknown>) => {

		const itemRes = await apollo.query({
			query: queries.inventoryItemInfo,
			variables: { inventoryItemId: inventoryItemId },
		});

		const itemInfo = itemRes?.data?.inventoryItemInfo;
		if (!itemInfo) {
			console.error('Failed to fetch item info', inventoryItemId);
			return;
		}

		const signInventoryItemGetRes = await apollo.query({
			query: queries.signInventoryItemGet,
			variables: { inventoryItemId: inventoryItemId },
		});

		const signedURLs = signInventoryItemGetRes?.data?.signInventoryItemGet;
		if (!signedURLs) {
			console.error('Failed to generate signed urls', inventoryItemId);
			return;
		}
		const url = clientPlatform.platform === "Android" && // Do not allow Android devices to import >4k images due to memory constraints.
			(itemInfo.imageWidth > maxAndroidImageSize ||
				itemInfo.imageHeight > maxAndroidImageSize) ?
			signedURLs.thumbnailURL : signedURLs.itemURL;

		postVuplexMessage("Document.Import",
			{
				instanceId: instanceId,
				teamId: itemInfo.teamId,
				url: url
			});

	};

	const tryClose = () => {
		if (documentState?.sharing ?? false) {
			showClosePresentationControlsDialog(dialogContext, cancelClose, close);
			postVuplexMessage('Document.HideContent', { instanceId: instanceId });
		}
		else {
			postVuplexMessage('Document.Close', { instanceId: instanceId });
		}
	}

	const cancelClose = () => {
		postVuplexMessage('Document.ShowContent', { instanceId: instanceId });
	}

	const close = () => {
		postVuplexMessage('Document.Close', { instanceId: instanceId });
	}

	useEffect(() => {
		const newContentURL = soi?.contentUrl ? new URL(soi.contentUrl) : null;

		if (!!soi?.contentUrl && contentChanged(contentURL, newContentURL)) {
			setContentURL(newContentURL);

			const contentMode = soi.contentType === 'application/pdf' ? DocumentType.PDF : DocumentType.Image;
			setContentMode(contentMode);
		}
	}, [soi]);

	if (!contentURL) {
		return (
			<div className={classes.root}>
				{(!controlsVisible) && (
					<LoadingIndicator />
				)}
			</div>
		);
	}

	return (
		<div className={classes.root}>
			{(controlsVisible) && (
				<AppHeader
					title={itemName}
					selected={false}
					hideBackButton={true}
					onBackButtonAction={closeInstance}
					children={
						<SwitchSelector
							text="Present"
							onChange={setPresentation}
							uiAudioMessage={documentState?.sharing ? stopPresentingAudioMessage : presentAudioMessage}
							uiHoverAudioMessage={documentState?.sharing ? stopPresentingHoverAudioMessage : presentHoverAudioMessage}
							checked={documentState?.sharing ?? false}
						/>
					}
					secondChildren={
						<>
							{(documentType === DocumentType.Image) && (
								<GlueButton
									disabled={importing}
									onPointerDown={() => importDocument(inventoryItemId, apollo)}
									uiAudioMessage={importaudiomessage}
									uiHoverAudioMessage={importhoveraudiomessage}
								>
									{importing ? <LoadingIndicator variant='button' /> : <p>Import</p>}
								</GlueButton>
							)}
							<CloseButton onClose={() => tryClose()} />
						</>
					}
				/>
			)}


			{(documentType === DocumentType.Image) && (
				<div className={classes.imageContainer}>
					<img src={contentURL.href} />
				</div>
			)}

			{(controlsVisible) && (
				<div className={classes.emptyContainer} />
			)}

			{(documentType === DocumentType.PDF) && (<>
				<PdfViewer
					url={contentURL}
					pageNumber={pageNumber}
					pageClass={classes.pdfContainer}
					onSuccess={(pageCount) => setPageCountTotal(pageCount)}
					onError={(msg) => console.log(msg)}
				/>

				{(controlsVisible) && (
					<PageSelector
						useInput
						size='extraLarge'
						current={pageNumber}
						pageCount={pageCountTotal}
						onPageChange={(pageNumber) => changePage(pageNumber)}
					/>
				)}
			</>)}
		</div>
	);
};

export default Document;
