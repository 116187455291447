import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import queries from '../../graphql/queries';
import {  makeStyles } from '@material-ui/core';
import postVuplexMessage from '../../service/message-vuplex';
import CloseButton from '../common/close-button';
import FacilitationSession from './session';

const useStyles = makeStyles((theme => ({
	root: {
		margin: 'auto',
	},

	header: {
		height: theme.glueSpacing('xl'),
		display: 'grid',
		gridTemplateColumns: '680px 1fr',
		justifyContent: 'space-around',
		alignItems: 'center'
	},

})));

const SessionControls3D = () => {
	const classes = useStyles();
	const apolloIsFacilitatorResult = useQuery(queries.isFacilitator)
	const apolloIsFacilitator = apolloIsFacilitatorResult?.data.isFacilitator;

	const closeButtonClicked = () =>
	{
		const instanceId = window.sessionStorage.getItem('instance-id');

		if (!instanceId)
		{
			return;
		}

		postVuplexMessage("SessionControls.Close", { value: instanceId });
	};

	if(!apolloIsFacilitator)
	{
		closeButtonClicked();
	}

	return (
		<div className={classes.root}>
			<div className={classes.header}>
				<div/>
				<CloseButton onClose={closeButtonClicked}/>
			</div>
				<FacilitationSession spawned={true}/>
		</div>
	)
};

export default SessionControls3D;
