import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CardMedia, FormControlLabel, useTheme } from '@material-ui/core';
import { DialogTitle,
	DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import GlueCard from '../common/glue-card';
import { clickAudio, hoverAudio } from '../common/common-vuplex-messages';
import SpaceThumbnailPlaceholder from '../../images/SpaceThumbnail_Placeholder.png'
import GlueButton from '../common/glue-button';
import GlueDialog from '../common/glue-dialog';
import GlueInputfield from '../common/glue-inputfield';
import SpecialApps from './special-apps';
import { logFirebaseEvent } from '../../service/firebase/firebase';
import { useUserSettings } from '../../service/user-settings';

const useStyles = makeStyles((theme) => ({
	dialogmedia: {
		margin: '20px auto',
		width: '100px',
	},

	dialogContentText: {
		paddingBottom: '30px',
	},

	dialogInputfield: { 
		display: 'flex',
		justifyContent: 'center',
	},

	action: {
		textTransform: 'none',
		margin: 'auto',
		width: '100%',
		justifyContent: 'center',
	}
}));

const AddAppTile = (props) => {
	const classes = useStyles(props);	
	const theme = useTheme();

	const [ isAddAppDialogOpen, setIsAddAppDialogOpen ] = useState(false);
	const [ urlSpaceHolder, setUrlSpaceHolder] = useState(null);

	const { userSettings, setUserSettings } = useUserSettings();
	
	const nameIndex = 0;
	const infoIndex = 1;

	const selectedApps = userSettings?.externalApps ?? [[],[]];

	const appiconurl = props.appiconurl ? props.appiconurl : SpaceThumbnailPlaceholder; 
	const audiomessage = "Menu/TeamSpaces/Team/Press";
	const hoveraudiomessage = "Menu/TeamSpaces/Team/HL";
	const goaudiomessage = "Menu/TeamSpaces/Team/Go/Press"; 
	const gohoveraudiomessage = "Menu/TeamSpaces/Team/Go/HL"; 
	const rncaudiomessage = "Tools/FileDialog/Rename/Cancel/Press";
	const rnchoveraudiomessage = "Tools/FileDialog/Rename/Cancel/HL";
	const rnraudiomessage = "Tools/FileDialog/Rename/Rename/Press";
	const rnrhoveraudiomessage = "Tools/FileDialog/Rename/Rename/HL";
	

	let selected = selectedApps[nameIndex].includes(props.appname);

	const saInd = SpecialApps.findIndex(sa => sa.AppName === props.appname)

	const headerText = saInd > -1 ? "You're adding " + SpecialApps[saInd].AppName : "";
	const infoText = saInd > -1 ? "Please enter your " + SpecialApps[saInd].InfoText : "";
	const endUrl = saInd > -1 ? SpecialApps[saInd].endOfUrl : "";
		
	const toggleApps = () =>
	{
		if(selected)
		{
			removeApps();
			logFirebaseEvent("removeApp", { removedApp: props.appname });
		}
		else if(saInd < 0)
		{
			addApps();
		}
		else
		{
			setIsAddAppDialogOpen(true);
		}
		selected = !selected;
	};

	const addApps = (startOfUrl) =>
	{

		if (!selectedApps[nameIndex].includes(props.appname))
		{
			selectedApps[nameIndex].push(props.appname);
			const sa = SpecialApps.find(sa => sa.AppName === props.appname);
			const infoContent = sa ? 'https://' + startOfUrl : "";
			selectedApps[infoIndex].push(infoContent);
		}

		setUserSettings({ externalApps: selectedApps });
		logFirebaseEvent("addApp", { addedApp: props.appname });
	};

	const removeApps = () =>
	{
		let spliceIndex = 0;

		if(selectedApps[nameIndex].includes(null))
		{
			spliceIndex = selectedApps[nameIndex].indexOf(null);
			selectedApps[nameIndex].splice(spliceIndex,1);
			selectedApps[infoIndex].splice(spliceIndex,1);
		}
		
		if(selectedApps[nameIndex].includes(props.appname))
		{
			spliceIndex = selectedApps[nameIndex].indexOf(props.appname);
			selectedApps[nameIndex].splice(spliceIndex,1);
			selectedApps[infoIndex].splice(spliceIndex,1);
		}

		setUserSettings({ externalApps: selectedApps });
	};

	const chooseBgr = () =>
	{
		if (selected)
			return theme.palette.background.paper;
		else
			return theme.palette.secondary.dark;
	}

	const onClickAddApp = (inputText) =>
	{
		setIsAddAppDialogOpen(false);

		if (inputText && inputText.length > 0)
		{
			addApps(inputText);			
		}
		else
		{
			console.log("Special url not set");
		}

		setUrlSpaceHolder(null);
	};

	const AppInUse = (
		<div>
			<FormControlLabel
				className={classes.action}
				control={
					<GlueButton
						overrideColor = {chooseBgr()}
						onPointerDown={() => {
							toggleApps(); 
						}}
						uiAudioMessage = {goaudiomessage}
						uiHoverAudioMessage = {gohoveraudiomessage} 
					>
						{ selected ? 
							"Remove"
						:
							"Add"
						}
					</GlueButton>
				}
			/>
			{SpecialApps.filter(sa =>sa.AppName === props.appname).map(sa =>
				<GlueDialog
					key={sa.AppName}
					PaperProps={{ style: { maxWidth: '680px'}}}
					open={isAddAppDialogOpen} 
					onClose={() => { setIsAddAppDialogOpen(false); }}
				>
					<CardMedia
						classes={{ root: classes.dialogmedia }}
						component="img"
						image={appiconurl}
					/>
					<DialogTitle>{headerText}</DialogTitle>
					<DialogContent>
						<DialogContentText className={classes.dialogContentText}>{infoText}</DialogContentText>
					</DialogContent>
					<div className={classes.dialogInputfield}>
						<GlueInputfield
							autoFocus
							autoCursor
							simpleInput
							centerText
							value={urlSpaceHolder !== null ? urlSpaceHolder : endUrl}
							onChange={setUrlSpaceHolder}
							onSubmit={() => onClickAddApp(urlSpaceHolder)}
							placeholder={SpecialApps[saInd].InputFieldText}
						/> 
					</div>
					<DialogActions>
						<GlueButton 
							onPointerDown={() => { setIsAddAppDialogOpen(false); setUrlSpaceHolder(null);}}
							uiAudioMessage = {rncaudiomessage}
							uiHoverAudioMessage = {rnchoveraudiomessage}
						>
							Cancel
						</GlueButton>
						<GlueButton 
							onPointerDown={() => onClickAddApp(urlSpaceHolder)} color="primary"
							uiAudioMessage = {rnraudiomessage}
							uiHoverAudioMessage = {rnrhoveraudiomessage}
						>
							Add
						</GlueButton>
					</DialogActions>
				</GlueDialog>
			)}
		</div>
	);

	return (
		<GlueCard
			showIcon
			title={props.appname}
			image={appiconurl}
			onPointerDown={() => {clickAudio(audiomessage)}}
			onMouseEnter={() => {hoverAudio(hoveraudiomessage)}}
		>
			{AppInUse}
		</GlueCard>
	);
}

export default AddAppTile;
