import React, { useRef, useState } from 'react';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { DialogActions, IconButton, makeStyles, withStyles } from '@material-ui/core';

import queries from '../../graphql/queries';

import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import Check from '@material-ui/icons/Check';
import { ErrorOutline } from '@material-ui/icons';

import { checkTeamFileProcessingStatus, uploadTeamFile } from '../../util/file-backend-api-util';

import GlueRadioGroup from '../../standalone-web/common/glue-radio-group';
import LinearProgress from '@material-ui/core/LinearProgress';
import GlueButton from '../common/glue-button';

import GlueScroll from '../common/glue-scroll';
import { getHumanizedFileSize } from '../../util/file-utils';
import { usePromptDialogContext } from '../../util/prompt-dialog-context';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '512px',
		marginTop: '0px',
		display: 'block',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '15px'
	},

	header: {
		marginBottom: '18px',
		textAlign: 'center',
		position: 'relative',

		'& > button': {
			position: 'absolute',
			top: '0px',
			right: '0px'
		}
	},

	content: {
		width: '100%',
		padding: '10px',
		display: 'flex',
		flexDirection: 'column',
		gap: '15px',
		justifyContent: 'center',
		alignItems: 'center',
		margin: 'auto',

		'&> button': {
			height: '36px',
			width: 'fit-content',
			textTransform: 'capitalize',
			margin: 'auto'
		}
	},

	file: {
		maxHeight: '410px',
		padding: '0 10px',
		'&> button': {
			height: '36px',
			width: 'fit-content',
			margin: 'auto'
		}
	},

	fileUpload: {
		height: '49px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-end',

		'& > button': {
			backgrouond: theme.palette.secondary.main
		}
	},

	successIcon: {
		fill: theme.palette.success.main
	},

	errorIcon: {
		fill: theme.palette.error.main
	},

	dropField: {
		display: 'block',
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		opacity: 0,
		width: '100%',

		'&:focus': {
			outline: 'none'
		}
	},

	label: {
		position: 'absolute',
		top: '45px',
		textAlign: 'center',
	},

	dropContainer: {
		height: '180px',
		width: ({ width }) => width ? width : '100%',
		background: theme.palette.background.default,
		outline: '2px dashed white',
		display: 'flex',
		flexDirection: 'column',
		gap: '24px',
		alignItems: 'center',
		position: 'relative',

		'& > button':
		{
			position: 'absolute',
			bottom: '25px',
		}
	},

	itemDisplay: {
		display: 'flex',
		flexDirection: 'column',
		gap: '5px',
		'& > p': {
			margin: '0px',
			fontSize: '12px'
		}
	},

	uploadContent: {
		maxHeight: '294px'
	},

	footer: {
		marginTop: '12px',
		textAlign: 'center',

		'& > button': {
			margin: 'auto',
			marginTop: '12px'
		}
	}
}));

const BorderLinearProgress = withStyles((theme) => ({
	root: {
		height: 8,
		borderRadius: 4,
		width: '372px'
	},
	colorPrimary: {
		backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
	},
	bar: {
		borderRadius: 4,
		backgroundColor: '#1a90ff',
	},
}))(LinearProgress);

const AddFileDialog = (props) => {
	const classes = useStyles();
	const contentRef = useRef(null);
	const inputAreaRef = useRef(null);
	const [ uploadComplete, setUploadComplete ] = useState(false);

	const { closePromptDialog } = usePromptDialogContext();
	const [isUploading, setIsUploading] = useState(false);
	const [permission, setPermission] = useState('public');

	const [selectedFiles, setSelectedFiles] = useState({})
	const [fileProgressData, setFileProgressData] = useState({ val: [] })
	const progressRef = useRef(null);

	const teamIdRes = useQuery(queries.currentTeamId);
	const apollo = useApolloClient();

	let objectsCount = Object.keys(selectedFiles).length;
	let uploadStarted = fileProgressData && fileProgressData.val.length > 0;

	const permissionOptions = [
		{ id: 'public', name: 'Visible to all team members' },
		{ id: 'private', name: 'Only visible to myself' }
	]

	const clickSubmit = async () => {
		let uploadFiles = Object.values(selectedFiles);

		let _progressInfos = uploadFiles.map(file => ({
			fileName: file.name,
			cancelToken: null,
			progress: 0,
			completed: false,
			completedFileSize: null,
			error: false
		}));

		progressRef.current = {
			val: _progressInfos,
		}

		const uploadPromises = uploadFiles.map((file, i) => submitFile(i, file));

		Promise.all(uploadPromises)
			.then(() => {
				setIsUploading(false)
				setUploadComplete(true)
			})
	}

	const submitFile = async (id, file) => {

		let _progressInfos = [...progressRef.current.val];
		let wasCanceled = false;
		_progressInfos[id].fileSize = getHumanizedFileSize(file.size);

		const uploadProg = (value) => {
			_progressInfos[id].progress = value;
			setFileProgressData({ val: _progressInfos });
			console.log(value)
		}

		const setCancelSource = (value) => {
			if (_progressInfos[id])
			{
				_progressInfos[id].cancelToken = value;
				setFileProgressData({ val: _progressInfos });
			}
		}

		const onCancelUpload = () => {
			wasCanceled = true;
			_progressInfos.splice(id, 1);
			setFileProgressData({ val: _progressInfos })
		}

		const creationParams = {
			name: file.name,
			permission: permission,
			generateFilePostfix: false
		};

		const uploadRes = await uploadTeamFile(
			apollo,
			teamIdRes.data.currentTeamId,
			file,
			creationParams,
			uploadProg,
			setCancelSource,
			onCancelUpload)

		if (wasCanceled)
		{
			return;
		}

		if (!uploadRes || !uploadRes.statusItemId)
		{
			_progressInfos[id].error = true;
			setFileProgressData({ val: _progressInfos })
		}
		else 
		{
			await checkTeamFileProcessingStatus(uploadRes.statusItemId, apollo, () => null);
			_progressInfos[id].completed = true;
			setFileProgressData({ val: _progressInfos })
			await apollo.refetchQueries({
				include: ['TeamInventoryItems']
			});
		}
	}

	const handleChange = (value) => {
		setPermission(value);
	};

	const cancelUpload = (token) => {
		token.cancel("Cancel upload");
	}

	const handleNewFiles = (e) => {
		if (isUploading) {
			return;
		}
		if(uploadComplete && Object.keys(selectedFiles).length > 0)
		{
			Object.keys(selectedFiles).map(item => removeFile(item));
			setUploadComplete(false);
		}
		const newFiles = e.target.files;
		if (newFiles.length) {
			for (let file of newFiles) {
				selectedFiles[file.name] = file;
			}
			setSelectedFiles({ ...selectedFiles });
		}
		setFileProgressData({ val: [] })
	}

	const removeFile = (fileName) => {
		delete selectedFiles[fileName];
		setSelectedFiles({ ...selectedFiles });
	}

	return (
		<>
			<DialogActions classes={{ root: classes.root }}>
				<div className={classes.header}>
					<h2>Upload a New File</h2>
					<IconButton
						onPointerDown={() => { closePromptDialog(); props.refetch(); }}
					>
						<CloseOutlinedIcon />
					</IconButton>
				</div>
				<div className={classes.content}>
					<GlueRadioGroup
						options={permissionOptions}
						value={permission}
						onChange={value => handleChange(value)}
					/>
					<p>*File visibility can be changed later in Team Files</p>
					<div className={classes.dropContainer}>
						<div className={classes.label}>
							<h3>{"Drag & Drop files here"}</h3><br />
							<h3>OR</h3>
						</div>

						<input
							className={classes.dropField}
							type="file"
							ref={inputAreaRef}
							onChange={handleNewFiles}
							value=""
							multiple="multiple"
						/>
						<GlueButton
							color="primary"
							onPointerDown={() => inputAreaRef.current.click()}
						>
							Browse Files
						</GlueButton>
					</div>
				</div>
				{objectsCount > 0 &&
					<div className={classes.file}>
						<GlueScroll>
							<div className={classes.uploadContent} ref={contentRef}>
								{fileProgressData && fileProgressData.val.length > 0 ? (
									fileProgressData.val.map((pending, idx) => (
										<div className={classes.fileUpload} key={idx}>
											<div className={classes.itemDisplay}>
												<p>{pending.fileName}</p>
												{(pending.progress < 100 && !pending.error) && <BorderLinearProgress variant="determinate" value={pending.progress} />}
												{(pending.completed || pending.error) && <p>{pending.fileSize}</p>}
											</div>

											{pending.error && <ErrorOutline className={classes.errorIcon} />}

											{pending.completed && <Check className={classes.successIcon} />}

											{(!pending.error && !pending.completed && pending.cancelToken) &&
												<IconButton
													onPointerDown={() => { cancelUpload(pending.cancelToken) }}
												>
													<CloseOutlinedIcon />
												</IconButton>
											}
										</div>
									))
								) : (
									Object.keys(selectedFiles).map((fileName, idx) => (
										<div className={classes.fileUpload} key={idx}>
											<div className={classes.itemDisplay}>
												<p>{fileName}</p>
											</div>
											<IconButton
												onPointerDown={() => { removeFile(fileName) }}
											>
												<CloseOutlinedIcon />
											</IconButton>
										</div>
									))
								)}
							</div>
						</GlueScroll>

						<div className={classes.footer}>
							{uploadStarted ? <p>Uploading {fileProgressData.val.length} files</p> : <GlueButton onPointerDown={() => clickSubmit()}>
								Upload
							</GlueButton>}
						</div>
					</div>}
			</DialogActions>
		</>
	);
};
export default AddFileDialog;
