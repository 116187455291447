import React from "react";
import { makeStyles, Theme, Typography } from "@material-ui/core";
import { Variant } from "@material-ui/core/styles/createTypography";

// These are the 500 colors from the common palette
const lightColors = [
	'#54b5ff',
	'#a375ff',
	'#d9363b',
	'#09b363',
	'#e6b837',
	'#e9551c',
];

// These are the 900 colors from the common palette
const darkColors = [
	'#004880',
	'#402578',
	'#4a0c11',
	'#00542e',
	'#594304',
];

type AcronymIconProps = {
	id: string,
	dark?: boolean,
	text: string,
	typeVariant?: Variant
}

type ThemeProps = {
	color?: string,
	dark?: boolean
}

const useStyles = makeStyles<Theme, ThemeProps>((theme) => ({
	root: {
		width: '100%',
		height: '100%',
		margin: 'auto',
		display: 'flex',
		background: ({color}) => color,
	},

	type: ({dark}) => ({
		margin: 'auto',
		textTransform: 'uppercase',
		color: dark ? 'white' : 'black',
	})
}), { name: 'MuiGlueAcronymIcon' });

const AcronymIcon = (props: AcronymIconProps) => {

	const stringToIntHash = (str: string) => {
		if (!str || typeof str !== 'string')
			return 0;

		let result = 0;
		for (let i = 0; i < str.length; i++)
			result += str.charCodeAt(i);
		
		return result;
	}

	const getAcronym = (name: string) => {
		if (!name || typeof name !== 'string')
			return "";

		let acronym = name.split(/\s/).reduce((response,word)=> response+=word.slice(0,1),'');
		acronym = acronym.substring(0, 2);

		if (acronym.length < 2)
		{
			acronym = name.slice(0,2);
		}

		return acronym;
	}

	const intHash = stringToIntHash(props.id);
	const colorFromHash = props.dark ? darkColors[intHash % darkColors.length] : lightColors[intHash % lightColors.length];
	const acronym = getAcronym(props.text);

	const classes = useStyles({ color: colorFromHash, dark: props.dark });

	return (
		<div className={classes.root}>
			<Typography variant={props.typeVariant ?? 'body2'} classes={{root: classes.type}}>{acronym}</Typography>
		</div>
	);
}

export default AcronymIcon;