import { gql } from '@apollo/client';

const mutations = 
{
	addSysAdmin: gql`
		mutation AddSysAdmin ($email: String!) {
			addSysAdmin (email: $email)
		}
	`,

	removeSysAdmin: gql`
		mutation RemoveSysAdmin ($email: String!) {
			removeSysAdmin (email: $email)
		}
	`,

	removeInventoryItem: gql`
		mutation RemoveInventoryItem ($inventoryItemId: ID!) { 
			removeInventoryItem (inventoryItemId: $inventoryItemId) {
				inventoryItemId
			}
		}
	`,

	updateInventoryItem: gql`
		mutation UpdateInventoryItem($inventoryItemId: ID!, $name: String!, $permission: String) {
			updateInventoryItem(inventoryItemId: $inventoryItemId, name: $name, permission: $permission) {
				inventoryItemId
				updatedDate
			}
		}
	`,

	createInventoryItem: gql`
		mutation CreateInventoryItem( $name: String!, $itemUrl: String!, $itemType: String!, $teamId: ID, $fileSize: Int, $thumbnailUrl: String, $permission: String, $imageWidth: Int, $imageHeight: Int) {
			createInventoryItem(name: $name, itemUrl: $itemUrl, itemType: $itemType, teamId: $teamId, fileSize: $fileSize, thumbnailUrl: $thumbnailUrl, permission: $permission, imageWidth: $imageWidth, imageHeight: $imageHeight) {
				inventoryItemId
			}
		}
	`,

	createTeamFileDeferred: gql`
		mutation CreateTeamFileDeferred(
			$teamId: ID!
			$itemType: String!
			$creationParams: TeamFileCreationParams
		) {
			createTeamFileDeferred(
				teamId: $teamId
				itemType: $itemType
				creationParams: $creationParams
			) {
				uploadURL
				fileName
				statusItemId
				tagsStr
			}
		}
	`,

	updateUserSettings: gql`
		mutation UpdateUserSettings($blob: String!) {
			updateUserSettings(blob: $blob)
		}
	`,

	createAsset: gql`
		mutation CreateAsset(
			$name: String!
			$type: String!
			$thumbnailUrl: String
			$permission: String!
			$description: String
		) {
			createAsset(
				name: $name
				type: $type
				thumbnailUrl: $thumbnailUrl
				permission: $permission
				description: $description
			) {
				assetId
				name
			}
		}
	`,

	createAssetDeferred: gql`
		mutation CreateAssetDeferred(
			$name: String!
			$type: String!
			$permission: String!
			$linkToTeam: ID
			$assignVersionPointer: String
		) {
			createAssetDeferred(
				name: $name
				type: $type
				permission: $permission
				linkToTeam: $linkToTeam
				assignVersionPointer: $assignVersionPointer
			) {
				uploadURL
				fileName
				statusItemId
				tagsStr
			}
		}
	`,

	updateAsset: gql`
		mutation UpdateAsset(
			$assetId: ID!
			$name: String
			$thumbnailUrl: String
			$permission: String
			$archived: Boolean
			$description: String
		) {
			updateAsset(
				assetId: $assetId
				name: $name
				thumbnailUrl: $thumbnailUrl
				permission: $permission
				archived: $archived
				description: $description
			) {
				assetId
				name
			}
		}
	`,

	updateAssetDescription: gql`
		mutation UpdateAssetDescription(
			$assetId: ID!
			$description: String!
		) {
			updateAssetDescription(
				assetId: $assetId
				description: $description
			) {
				assetId
				name
			}
		}
	`,

	updateAssetDeferred: gql`
		mutation UpdateAssetDeferred(
			$assetId: ID!
			$name: String
			$assignVersionPointer: String
		) {
			updateAssetDeferred(
				assetId: $assetId
				name: $name
				assignVersionPointer: $assignVersionPointer
			) {
				uploadURL
				fileName
				statusItemId
				tagsStr
			}
		}
	`,

	assetAttachTeam: gql`
		mutation AssetAttachTeam(
			$teamId: ID!
			$assetId: ID!
		) {
			assetAttachTeam(
				teamId: $teamId
				assetId: $assetId
			) {
				assetId
				name
			}
		}
	`,

	assetDetachTeam: gql`
		mutation AssetDetachTeam(
			$teamId: ID!
			$assetId: ID!
		) {
			assetDetachTeam(
				teamId: $teamId
				assetId: $assetId
			) {
				assetId
				name
			}
		}
	`,

	assetAttachOrg: gql`
		mutation AssetAttachOrg(
			$orgId: ID!
			$assetId: ID!
		) {
			assetAttachOrg(
				orgId: $orgId
				assetId: $assetId
			) {
				assetId
				name
			}
		}
	`,

	assetDetachOrg: gql`
		mutation AssetDetachOrg(
			$orgId: ID!
			$assetId: ID!
		) {
			assetDetachOrg(
				orgId: $orgId
				assetId: $assetId
			) {
				assetId
				name
			}
		}
	`,

	setAssetVisibilityInTeam: gql`
		mutation SetAssetVisibilityInTeam(
			$teamId: ID!
			$assetId: ID!
			$visibility: Boolean!
		) {
			setAssetVisibilityInTeam(
				teamId: $teamId
				assetId: $assetId
				visibility: $visibility
			)
		}
	`,

	setAssetVisibilityInOrg: gql`
		mutation SetAssetVisibilityInOrg(
			$orgId: ID!
			$assetId: ID!
			$visibility: Boolean!
		) {
			setAssetVisibilityInOrg(
				orgId: $orgId
				assetId: $assetId
				visibility: $visibility
			)
		}
	`,

	promoteTeamAssetToOrg: gql`
		mutation PromoteTeamAssetToOrg(
			$assetId: ID!
		) {
			promoteTeamAssetToOrg(
				assetId: $assetId
			) {
				assetId
				name
			}
		}
	`,

	createServerComponent: gql`
		mutation CreateServerComponent(
			$assetId: ID!,
			$name: String!
			$imageUrl: String!
			$imageType: String!
			$startupArgs: String
			$downloadUsername: String
			$downloadPassword: String
			$imageTag: String
		) {
			createServerComponent(
				assetId: $assetId,
				name: $name
				imageUrl: $imageUrl
				imageType: $imageType
				startupArgs: $startupArgs
				downloadUsername: $downloadUsername
				downloadPassword: $downloadPassword
				imageTag: $imageTag
			) {
				name,
				componentId
			}
		}
	`,

	updateServerComponent: gql`
		mutation UpdateServerComponent(
			$componentId: ID!,
			$name: String!
			$imageUrl: String!
			$imageType: String!
			$startupArgs: String
			$downloadUsername: String
			$downloadPassword: String
			$imageTag: String
		) {
			updateServerComponent(
				assetId: $assetId,
				name: $name
				imageUrl: $imageUrl
				imageType: $imageType
				startupArgs: $startupArgs
				downloadUsername: $downloadUsername
				downloadPassword: $downloadPassword
				imageTag: $imageTag
			) {
				componentId
				name
			}
		}
	`,

	removeServerComponent: gql`
		mutation RemoveServerComponent(
			$componentId: ID!
			$assetId: ID!
		) {
			removeServerComponent(
				componentId: $componentId
				assetId: $assetId
			) {
				componentId
			}
		}
	`,

	createRoom: gql`
		mutation CreateRoom(
			$name: String!
			$teamId: ID!
			$serverKey: String
			$ip: String
			$port: Int
			$sceneName: String
			$sceneUrl: String
			$serviceUrl: String
			$orchestratorPort: Int
			$orchestratorUsername: String
			$orchestratorPassword: String
			$maxAllowedServicePort: Int
			$orchestratorId: ID
			$assetId: ID
			$assetName: String
			$orchestratorName: String
		) {
			createRoom(
				name: $name
				teamId: $teamId
				serverKey: $serverKey
				ip: $ip
				port: $port
				sceneName: $sceneName
				sceneUrl: $sceneUrl
				serviceUrl: $serviceUrl
				orchestratorPort: $orchestratorPort
				orchestratorUsername: $orchestratorUsername
				orchestratorPassword: $orchestratorPassword
				maxAllowedServicePort: $maxAllowedServicePort
				orchestratorId: $orchestratorId
				assetId: $assetId
				assetName: $assetName
				orchestratorName: $orchestratorName
			) {
				name,
				roomId
			}
		}
	`,

	/**
	 * Warning! Any unset fields will be deleted in the database!
	 * Be sure to fill them in with their current values whether
	 * you intend to change them or not.
	 */
	updateRoom: gql`
		mutation UpdateRoom(
			$roomId: ID!,
			$name: String!,
			$ip: String,
			$port: Int,
			$sceneName: String,
			$sceneUrl: String,
			$invitees: String,
			$updateOperationType: Int,
			$teamId: ID,
			$orchestratorUsername: String,
			$orchestratorPassword: String,
			$thumbnailUrl: String,
			$streamingServerUrl: String
		) {
			updateRoom(
				roomId: $roomId,
				name: $name,
				ip: $ip,
				port: $port,
				sceneName: $sceneName,
				sceneUrl: $sceneUrl,
				invitees: $invitees,
				updateOperationType: $updateOperationType,
				teamId: $teamId,
				orchestratorUsername: $orchestratorUsername,
				orchestratorPassword: $orchestratorPassword,
				thumbnailUrl: $thumbnailUrl,
				streamingServerUrl: $streamingServerUrl
			) {
				roomId
				name
				ip
				port
				sceneName
				sceneUrl
				invitees
			}
		}
	`,

	updateSpaceInfo: gql`
		mutation UpdateSpaceInfo(
			$roomId: ID!
			$name: String
			$ip: String
			$port: Int
			$invitees: String
			$thumbnailUrl: String
			$streamingServerUrl: String
		) {
			updateSpaceInfo(
				roomId: $roomId
				name: $name
				ip: $ip
				port: $port
				invitees: $invitees
				thumbnailUrl: $thumbnailUrl
				streamingServerUrl: $streamingServerUrl
			) {
				roomId
				name
				ip
				port
				sceneName
				sceneUrl
				invitees
			}
		}
	`,

	resetSpace: gql`
		mutation ResetSpace($roomId: ID!) {
			resetSpace(roomId: $roomId) {
				roomId
				name
				ip
				port
				sceneName
				sceneUrl
				invitees
			}
		}
	`,

	resetSessionJournal: gql`
		mutation ResetSessionJournal($roomId: ID!) {
			resetSessionJournal(roomId: $roomId) {
				roomId
				name
				ip
				port
				sceneName
				sceneUrl
				invitees
			}
		}
	`,

	removeSpace: gql`
		mutation RemoveSpace($roomId: ID!) {
			removeSpace(roomId: $roomId)
		}
	`,

	updateSessionObjectInfo: gql`
		mutation UpdateSessionObjectInfo($sessionId: ID!, $objectId: ID!, $contentUrl: String, $contentType: String, $pageNumber: Int, $pageNumberBase: Int) {
			updateSessionObjectInfo(sessionId: $sessionId, objectId: $objectId, contentUrl: $contentUrl, contentType: $contentType, pageNumber: $pageNumber, pageNumberBase: $pageNumberBase)
		}
	`,
	
	updateUserExternalAppsSettings: gql`
		mutation UpdateUserSettings($externalApps:String) {
			updateUserSettings(externalApps: $externalApps) {
				externalApps
			}
		}
	`,

	confirmCodeLogin: gql`
		mutation ConfirmCodeLogin($deviceCode: String) {
			confirmCodeLogin(deviceCode: $deviceCode)
		}
	`,

	userJoinTeam: gql`
		mutation UserJoinTeam($teamId: ID!, $memberId: String!, $role: String!, $isUpdate: Boolean) {
			userJoinTeam(teamId: $teamId, memberId: $memberId, role: $role, isUpdate: $isUpdate) {
				teamId
			}
		}
	`,

	userLeaveTeam: gql`
		mutation UserLeaveTeam($teamId: ID!, $memberId: String!) {
			userLeaveTeam(teamId: $teamId, memberId: $memberId) {
				teamId
			}
		}
	`,

	createOrg: gql`
		mutation CreateOrg($name: String!, $licenseTier: Int!, $numberOfSeats: Int, $startDate: String, $endDate: String) {
			createOrg(name: $name, licenseTier: $licenseTier, numberOfSeats: $numberOfSeats, startDate: $startDate, endDate: $endDate)
		}
	`,

	removeOrg: gql`
		mutation RemoveOrg($orgId: String!) {
			removeOrg(orgId: $orgId)
		}
	`,

	attachTeamToOrg: gql`
		mutation AttachTeamToOrg($orgId: ID!, $teamId: ID!) {
			attachTeamToOrg(orgId: $orgId, teamId: $teamId)
		}
	`,

	detatchTeamFromOrg: gql`
		mutation DetatchTeamFromOrg($orgId: ID!, $teamId: ID!) {
			detatchTeamFromOrg(orgId: $orgId, teamId: $teamId)
		}
	`,

	inviteOrgMembers: gql`
		mutation InviteOrgMembers($orgId: ID!, $memberEmails: [String!]!, $memberRole: String!, $teams: [ID!]!, $invitationMessage: String, $createMemberWithoutInvitation: Boolean) {
			inviteOrgMembers(orgId: $orgId, memberEmails: $memberEmails, memberRole: $memberRole, teams: $teams, invitationMessage: $invitationMessage, createMemberWithoutInvitation: $createMemberWithoutInvitation) {
				orgId
			}
		}
	`,

	resendInvitationEmail: gql`
		mutation ResendInvitationEmail($orgId: ID!, $memberEmail: String!) {
			resendInvitationEmail(orgId: $orgId, memberEmail: $memberEmail) {
				orgId
			}
		}
	`,

	updateOrgMembers: gql`
		mutation UpdateOrgMembers($orgId: ID!, $memberEmails: [String!]!, $memberRole: String!) {
			updateOrgMembers(orgId: $orgId, memberEmails: $memberEmails, memberRole: $memberRole) {
				orgId
			}
		}
	`,

	removeOrganizationMembers: gql`
		mutation RemoveOrganizationMembers($orgId: ID!, $memberEmails: [String!]!, $removeAccount: Boolean) {
			removeOrganizationMembers(orgId: $orgId, memberEmails: $memberEmails, removeAccount: $removeAccount) {
				orgId
			}
		}
	`,

	updateSubscription: gql`
		mutation UpdateSubscription($orgId: ID!, $licenseTier: Int!, $numberOfSeats: Int!, $startDate: String, $endDate: String) {
			updateSubscription(orgId: $orgId, licenseTier: $licenseTier, numberOfSeats: $numberOfSeats startDate: $startDate, endDate: $endDate)
		}
	`,

	createTeam: gql`
		mutation CreateTeam($orgId: ID!, $name: String!, $defaultClusterId: ID!, $streamingServerUrl: String, $teamAccessType: Int) {
			createTeam(orgId: $orgId, name: $name, defaultClusterId: $defaultClusterId, streamingServerUrl: $streamingServerUrl, teamAccessType: $teamAccessType) {
				name,
				teamId,
				teamAccessType
			}
		}
	`,

	deleteTeam: gql`
		mutation DeleteTeam($teamId: ID!) {
			deleteTeam(teamId: $teamId) {
				teamId
			}
		}
	`,

	updateTeam: gql`
		mutation UpdateTeam($teamId: ID!, $name: String!, $orchestratorUrl: String, $orchestratorPort: String, 
		$sessionPortRangeStart: Int, $sessionPortRangeEnd: Int, $orchestratorUsername: String, $orchestratorPassword: String,
		$defaultClusterId: ID, $streamingServerUrl: String, $teamAccessType: Int, $teamTier: Int) {
			updateTeam(teamId: $teamId, name: $name, 
			orchestratorUrl: $orchestratorUrl, 
			orchestratorPort: $orchestratorPort, 
			sessionPortRangeStart: $sessionPortRangeStart, 
			sessionPortRangeEnd: $sessionPortRangeEnd,
			orchestratorUsername: $orchestratorUsername,
			orchestratorPassword: $orchestratorPassword,
			defaultClusterId: $defaultClusterId,
			streamingServerUrl: $streamingServerUrl,
			teamAccessType: $teamAccessType,
			teamTier: $teamTier) {
				teamId,
				name,
				teamTier,
				teamCreatedTimestamp,
				teamAccessType
			}
		}
	`,

	moveTeam: gql`
		mutation MoveTeam($teamId: ID!, $dstOrgId: ID!, $newUserRole: String, $deleteOldOrg: Boolean) {
			moveTeam(teamId: $teamId, dstOrgId: $dstOrgId, newUserRole: $newUserRole, deleteOldOrg: $deleteOldOrg) {
				addedUserEmails
				teamName
			}
		}
	`,
	
	updateOrg: gql`
		mutation UpdateOrg($orgId: ID!, $name: String, $emailDomains: String) {
			updateOrg(orgId: $orgId, name: $name, emailDomains: $emailDomains)
		}
	`,

	deleteUserAccount: gql`
		mutation DeleteUserAccount($userId: String!) {
			deleteUserAccount(userId: $userId)
		}
	`,

	createOrchestrator: gql`
		mutation CreateOrchestrator(
		$name: String!
		$url: String!
		$managementPort: Int!
		$startPort: Int
		$endPort: Int
		$username: String!
		$password: String!
		$permission: String!
		$type: String!) {
			createOrchestrator(
			name: $name
			url: $url
			managementPort: $managementPort
			startPort: $startPort
			endPort: $endPort
			username: $username
			password: $password
			permission: $permission
			type: $type) {
				name,
				orchestratorId
			}
		}
	`,

	updateOrchestrator: gql`
		mutation UpdateOrchestrator(
		$orchestratorId: ID!
		$name: String!
		$url: String!
		$managementPort: Int!
		$startPort: Int
		$endPort: Int
		$username: String!
		$password: String!
		$permission: String!
		$type: String!) {
			updateOrchestrator(
			orchestratorId: $orchestratorId
			name: $name
			url: $url
			managementPort: $managementPort
			startPort: $startPort
			endPort: $endPort
			username: $username
			password: $password
			permission: $permission
			type: $type) {
				orchestratorId
				name
			}
		}
	`,

	removeOrchestrator: gql`
		mutation RemoveOrchestrator(
		$orchestratorId: ID!
		$userId: String!
		$accessTeamId: ID!) {
			removeOrchestrator(
			orchestratorId: $orchestratorId
			userId: $userId
			accessTeamId: $accessTeamId) {
				orchestratorId
			}
		}
	`,

	createCluster: gql`
		mutation createCluster(
		$name: String!
		$permission: String!) {
			createCluster(
			name: $name
			permission: $permission) {
				name,
				clusterId
			}
		}
	`,

	updateCluster: gql`
		mutation UpdateCluster(
		$clusterId: ID!
		$name: String!
		$permission: String!) {
			updateCluster(
			clusterId: $clusterId
			name: $name
			permission: $permission) {
				clusterId
				name
			}
		}
	`,

	removeCluster: gql`
		mutation RemoveCluster(
		$clusterId: ID!
		$userId: String!
		$accessTeamId: ID!) {
			removeCluster(
			clusterId: $clusterId
			userId: $userId
			accessTeamId: $accessTeamId) {
				clusterId
			}
		}
	`,

	attachOrchestratorToCluster: gql`
		mutation AttachOrchestratorToCluster(
		$teamId: ID!
		$orchestratorId: ID!
		$userId: String!
		$clusterId: ID!
		$clusterName: String!) {
			attachOrchestratorToCluster(
			teamId: $teamId
			orchestratorId: $orchestratorId
			userId: $userId
			clusterId: $clusterId
			clusterName: $clusterName) {
				orchestratorId
				name
			}
		}
	`,

	detachOrchestratorFromCluster: gql`
		mutation DetachOrchestratorFromCluster(
		$teamId: ID!
		$orchestratorId: ID!
		$userId: String!
		$clusterId: ID!
		$clusterName: String!) {
			detachOrchestratorFromCluster(
			teamId: $teamId
			orchestratorId: $orchestratorId
			userId: $userId
			clusterId: $clusterId
			clusterName: $clusterName) {
				orchestratorId
				name
			}
		}
	`,

	attachTeamToCluster: gql`
		mutation AttachTeamToCluster(
		$userTeamId: ID!
		$attachTeamId: ID!
		$userId: String!
		$clusterId: ID!
		$clusterName: String!) {
			attachTeamToCluster(
			userTeamId: $userTeamId
			attachTeamId: $attachTeamId
			userId: $userId
			clusterId: $clusterId
			clusterName: $clusterName) {
				teamId
				name
			}
		}
	`,

	detachTeamFromCluster: gql`
		mutation DetachTeamFromCluster(
		$userTeamId: ID!
		$detachTeamId: ID!
		$userId: String!
		$clusterId: ID!
		$clusterName: String!) {
			detachTeamFromCluster(
			userTeamId: $userTeamId
			detachTeamId: $detachTeamId
			userId: $userId
			clusterId: $clusterId
			clusterName: $clusterName) {
				teamId
				name
			}
		}
	`,

	testConnection: gql`
		mutation TestConnection(
		$orchestratorId: ID
		$orchestratorUrl: String
		$orchestratorType: String
		$orchestratorPort: String
		$orchestratorUsername: String
		$orchestratorPassword: String
		$orchestratorServerKey: String) {
			testConnection(
			orchestratorId: $orchestratorId
			orchestratorUrl: $orchestratorUrl
			orchestratorType: $orchestratorType
			orchestratorPort: $orchestratorPort
			orchestratorUsername: $orchestratorUsername
			orchestratorPassword: $orchestratorPassword
			orchestratorServerKey: $orchestratorServerKey) {
				statusCode,
				body
			}
	}
	`,
	
	createTutorialOrganization: gql`
		mutation CreateTutorialOrganization {
			createTutorialOrganization {
				orgId,
				teamId,
				roomId
			}
		}
	`,

	deleteTutorialOrganization: gql`
		mutation DeleteTutorialOrganization {
			deleteTutorialOrganization
		}
	`,

	generateAssetUploadURL: gql`
		mutation GenerateAssetUploadURL(
			$fileType: String!
			$assetId: ID
			$teamId: ID
		) {
			generateAssetUploadURL(
				fileType: $fileType
				assetId: $assetId
				teamId: $teamId
			) {
				uploadURL
				fileName
				statusItemId
				tagsStr
			}
		}
	`,

	upgradeAssetToVersioned: gql`
		mutation UpgradeAssetToVersioned($assetId: ID!) {
			upgradeAssetToVersioned(assetId: $assetId) {
				assetId
				name
			}
		}
	`,

	setAssetVersionPointer: gql`
		mutation SetAssetVersionPointer(
			$assetId: ID!
			$pointerName: String!
			$version: Int!
			$serverComponentsImageTagOverride: String
		) {
			setAssetVersionPointer(
				assetId: $assetId
				pointerName: $pointerName
				version: $version
				serverComponentsImageTagOverride: $serverComponentsImageTagOverride
			) {
				assetId
				name
			}
		}
	`,

	setSpaceTrackVersionPointer: gql`
		mutation SetSpaceTrackVersionPointer(
			$roomId: ID!
			$pointerName: String!
		) {
			setSpaceTrackVersionPointer(
				roomId: $roomId
				pointerName: $pointerName
			) {
				roomId
				name
			}
		}
	`,

	generateAssetThumbnailUploadURL: gql`
		mutation GenerateAssetThumbnailUploadURL(
			$fileType: String!
			$assetId: ID!
		) {
			generateAssetThumbnailUploadURL(
				fileType: $fileType
				assetId: $assetId
			) {
				uploadURL
				fileName
				statusItemId
				tagsStr
			}
		}
	`,
};

export default mutations;
