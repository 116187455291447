import React, { useState, useRef } from 'react';
import { makeStyles, IconButton, useTheme, InputBase, Tooltip } from '@material-ui/core';

import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

const calculateButtonSize = (theme, props) => {
	return props.height ? Math.floor(props.height * 0.87) : Math.floor(32 * 0.87);
};

const setOutlineFocused = (theme, props) => {
	if (props.warning === true)
		return '0 0 0 2px ' + theme.palette.warning.main;
	if (props.error === true)
		return '0 0 0 2px ' + theme.palette.error.main;
	if (props.focusedOutlineColor)
		return '0 0 0 2px ' + props.focusedOutlineColor;
	else
		return '0 0 0 2px ' + theme.palette.primary.active;
};

const setOutline = (theme, props) => {
	if (props.outlineColor)
		return '0 0 0 2px ' + props.outlineColor;
	else if (props.speechInput === true)
		return '0 0 0 2px ' + theme.palette.speech.main;
	else
		return '0 0 0 2px #808080';
};

const setBackgrouond = (theme, props) => {
	if (props.error === true)
		return theme.palette.background.error;
	else
		return theme.palette.background.default;
}

const setInputColor = (theme, props) => {
	if (props.error)
		return theme.palette.error.mainText;
	else if (props.color)
		return props.color;
	else
		return theme.palette.primary.contrastText;
}

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'block',
		flex: '0 1 auto',
		position: 'relative',
		padding: '2px 0 0 2px'
	},

	searchfield: ({ props }) => ({
		height: props.height,
		width: props.width,
		margin: props.margin ? props.margin : null,
		padding: props.search ? 0 : '0 12px 0 12px',

		borderRadius: '4px',
		boxShadow: setOutline(theme, props),
		backgroundColor: props.transparentBg ? 'transparent' : theme.palette.background.default,

		display: 'flex',
		flexFlow: 'row nowrap',
		alignItems: 'center',
	}),

	searchButton: {
		flex: '0 0 auto',
		width: ({ props }) => calculateButtonSize(theme, props),
		height: ({ props }) => calculateButtonSize(theme, props),
		margin: '0 6px 0 4px',

		color: '#808080',
		background: 'transparent',

		'&:hover': {
			color: ({ props }) => props.color ? props.color : theme.palette.primary.contrastText,
			background: 'transparent'
		}
	},

	clearButton: {
		flex: '0 0 auto',
		width: ({ props }) => calculateButtonSize(theme, props),
		height: ({ props }) => calculateButtonSize(theme, props),
		color: '#808080',
		background: 'transparent',
		padding: 0,

		'&:hover': {
			color: ({ props }) => props.color ? props.color : theme.palette.primary.contrastText,
			background: 'transparent'
		}
	},

	inputRoot: {
		flex: '1 1 auto'
	},

	input: ({ props }) => ({
		textAlign: props.centerText ? 'center' : 'left',
		color: setInputColor(theme, props),
		fontSize: props.fontSize ? props.fontSize : '16px',
		width: '100%'
	}),

}), { name: 'MuiGlueWebInputfield'});

/**
 * Passing in "search" as a prop enables the search field parameters
 * where a button with search icon and a clear field button appears
*/
const WebInputfield = (props) => {
	const theme = useTheme();
	const classes = useStyles({ props });

	const inputRef = useRef();
	const [hasFocus, setHasFocus] = useState(false);
	const { error, warning } = props;

	const setInputFocus = (value) => {
		inputRef.current.focus();
	};

	const onClickClose = () => {
		if (props.onClose)
			props.onClose();
	};

	const onClickClear = () => {
		props.onClear();
	};

	const handleTextChange = (event) => {
		if (props.onChange) {
			props.onChange(event.target.value);
		}
	}

	const handleKeyEventEnter = (event) => {
		if (props.onSubmitKey && event.key === 'Enter') {
			props.onSubmitKey();
		}
	}

	return (
		<div className={classes.root}>
			<Tooltip
				open={!!props.errorMessage}
				arrow
				title={props.errorMessage}
				enterDelay={300}
				disableHoverListener
				disableFocusListener
			>
				<div className={classes.searchfield}
					style={{
						boxShadow: (warning || error || hasFocus) && setOutlineFocused(theme, props),
						background: (warning || error || hasFocus) && setBackgrouond(theme, props)
					}}
				>
					{props.search && (
						<IconButton
							classes={{ root: classes.searchButton }}
							onPointerDown={onClickClose}
							onClick={setInputFocus}
						>
							<SearchRoundedIcon/>
						</IconButton>
					)}

					<InputBase
						className={classes.inputRoot}
						onFocus={() => setHasFocus(true)}
						onBlur={() => setHasFocus(false)}
						autoFocus={props.autoFocus}
						inputProps={{ className: classes.input, maxLength: props.maxLength }}
						onChange={e => handleTextChange(e)}
						value={props.value}
						ref={inputRef}
						onKeyUp={e => handleKeyEventEnter(e)}
						placeholder={props.placeholder}
						multiline={props.multiline}
						rows={props.rows}
						type={props.type}
					/>

					{props.showClearButton && (
						<IconButton
							classes={{ root: classes.clearButton }}
							onPointerDown={onClickClear}
							onClick={setInputFocus}
						>
							<CloseOutlinedIcon/>
						</IconButton>
					)}
				</div>
			</Tooltip>
		</div>
	);
};

export default WebInputfield;
