import React from 'react';

const SVGIconWrapper = (props) =>
{
	return (<>
			{React.cloneElement(props.children, 
				{
					className: "MuiSvgIcon-root", 
					style:
					{
						width: props.width ??  props.web ? '36px' : props.toolbar ? '48px' : '64px',
						height: props.height ?? props.web ? '36px' : props.toolbar ? '48px' : '64px',
						fill: 'currentcolor',
						flexShrink: 0 
					}
				}
			)}
			</>
	)
}

export default SVGIconWrapper;