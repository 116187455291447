import React from 'react';
import { makeStyles, ButtonBase, Theme } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import queries from '../../graphql/queries';

import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { FirstPage, LastPage } from '@material-ui/icons';
import { hoverAudio, clickAudio } from './common-vuplex-messages';
import Pagination from './pagination';

type PageSelectorProps = {
	dimensions?: number
	current: number
	onPageChange: (arg0: number) => void
	pageCount: number
	useInput?: boolean
	size?: 'small' | 'medium' | 'large' | 'extraLarge'
}

const useStyles = makeStyles<Theme, PageSelectorProps>(theme => ({
	root: {
		display: 'flex',
		flexFlow: 'row nowrap',
		width: props => theme.custom.pageSelector.sizes[props.size ?? 'medium'],
		alignItems: 'center',
		justifyContent: 'space-between',
	},

	button: {
		width: theme.custom.pageSelector.buttonSize,
		height: theme.custom.pageSelector.buttonSize,
		borderRadius: theme.custom.pageSelector.buttonRadius,
		'&:hover': {
			background: 'rgba(255,255,255,0.2)'
		},
		'&:active': {
			background: 'rgba(255,255,255,0.4)'
		},
	},
}), { name: "MuiGluePageSelector" });

const PageSelector = (props: PageSelectorProps) =>
{
	const classes = useStyles(props);
	const uiMode = useQuery(queries.ui);

	const prevaudiomessage = "Tools/PageSelector/Prev/Press";
	const prevhoveraudiomessage = "Tools/PageSelector/Prev/HL";
	const nextaudiomessage = "Tools/PageSelector/Next/Press";
	const nexthoveraudiomessage = "Tools/PageSelector/Next/HL";

	const changeBy = (delta: number) =>
	{
		if (props.current + delta < 0)
		{
			props.onPageChange(props.pageCount - 1);
		}
		else if (props.current + delta > props.pageCount - 1)
		{
			props.onPageChange(0);
		}
		else
		{
			props.onPageChange(props.current + delta);
		}
	}

	if (props.pageCount < 2)
	{
		return(<div className={classes.root}></div>);
	}

	return (
		<div className={classes.root}>
			{props.size === "extraLarge" &&
				<ButtonBase className={classes.button}
					onPointerDown={()=>{props.onPageChange(0); clickAudio(prevaudiomessage);}}
					onPointerEnter={() => {hoverAudio(prevhoveraudiomessage)}}
				>
					<FirstPage/>
				</ButtonBase>
			}
			<ButtonBase className={classes.button}
				onPointerDown={()=>{changeBy(-1); clickAudio(prevaudiomessage);}}
				onPointerEnter={() => {hoverAudio(prevhoveraudiomessage)}}
			>
				<NavigateBeforeIcon/>
			</ButtonBase>
			
			<Pagination
				web={uiMode.data?.ui === 'web'}
				useInput={props.useInput}
				onSetSpecificPage={(val) => props.onPageChange(val)}
				current={props.current}
				pageCount={props.pageCount}
			/>
			
			<ButtonBase className={classes.button}
				onPointerDown={()=>{changeBy(1); clickAudio(nextaudiomessage)}}
				onPointerEnter={() => {hoverAudio(nexthoveraudiomessage)}}
			>
				<NavigateNextIcon/>
			</ButtonBase>
			{props.size === "extraLarge" &&
				<ButtonBase className={classes.button}
					onPointerDown={()=>{props.onPageChange(props.pageCount - 1); clickAudio(nextaudiomessage)}}
					onPointerEnter={() => {hoverAudio(nexthoveraudiomessage)}}
				>
					<LastPage/>
				</ButtonBase>
			}
		</div>
	);
};

export default PageSelector;
