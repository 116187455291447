export type Type = {
	buttonWidth: string
	drawerWidth: string
	searchFieldWidth: string
	filterIconGap: string
	buttonSize: string
};

export const common: Type =
{
	buttonWidth: '200px',
	drawerWidth: '472px',
	searchFieldWidth: '256px',
	filterIconGap: '18px',
	buttonSize: '36px',
};

export const web: Type =
{
	...common
};

export const tablet: Type =
{
	...common,

	buttonWidth: '328px',
	drawerWidth: '600px',
	searchFieldWidth: '180px',
	filterIconGap: '24px',
	buttonSize: '64px',
};
