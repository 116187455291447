import React from 'react';
import { Route, Routes } from "react-router-dom";

import Camera from './camera';
import LaserPointerApp from './laser-pointer-app';
import Draw3DApp from './draw-3d-app';
import ClockApp from './clock-app';
import LocationsApp from './locations-app';
import Bookmarks from './browser/bookmarks';
import Note from './note/note';
import SpaceAssetsApp from './space-asset/space-assets-app';
import FacilitationApp from './facilitation/facilitation-app';
import TransformUITablet from './object-manipulation/transform-ui-tablet';
import WebControl from './web-control/web-control';
import PresentationViewApp from './presentation-view/presentation-view';

const AppRoute = (props) => {
	return (
		<Routes>
			<Route path="camera" element={<Camera />} />
			<Route path="clock/*" element={<ClockApp />} />
			<Route path="laser-pointer" element={<LaserPointerApp />} />
			<Route path="draw-3d" element={<Draw3DApp />} />
			<Route path="locations" element={<LocationsApp />} />
			<Route path="facilitation/*" element={<FacilitationApp />} />
			<Route path="note" element={<Note />} />
			<Route path="browser" element={<Bookmarks />} />
			<Route path="space-assets" element={<SpaceAssetsApp />} />
			<Route path="web-control" element={<WebControl/>} />
			<Route path="object-manipulation" element={<TransformUITablet />} />
			<Route path="presentation-view" element={<PresentationViewApp />} />
		</Routes>
	);
}

export default AppRoute;