import React from 'react'
import { makeStyles } from '@material-ui/core';
import queries from '../graphql/queries';
import { useQuery } from '@apollo/react-hooks';

import BatteryAlertIcon from '@material-ui/icons/BatteryAlert';
import BatteryUnknownIcon from '@material-ui/icons/BatteryUnknown';

import Battery20Icon from '@material-ui/icons/Battery20';
import Battery30Icon from '@material-ui/icons/Battery30';
import Battery50Icon from '@material-ui/icons/Battery50';
import Battery60Icon from '@material-ui/icons/Battery60';
import Battery80Icon from '@material-ui/icons/Battery80';
import Battery90Icon from '@material-ui/icons/Battery90';
import BatteryFullIcon from '@material-ui/icons/BatteryFull';

import BatteryCharging20Icon from '@material-ui/icons/BatteryCharging20';
import BatteryCharging30Icon from '@material-ui/icons/BatteryCharging30';
import BatteryCharging50Icon from '@material-ui/icons/BatteryCharging50';
import BatteryCharging60Icon from '@material-ui/icons/BatteryCharging60';
import BatteryCharging80Icon from '@material-ui/icons/BatteryCharging80';
import BatteryCharging90Icon from '@material-ui/icons/BatteryCharging90';
import BatteryChargingFullIcon from '@material-ui/icons/BatteryChargingFull';

import GlueIcon from './common/glue-icon';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexFlow: 'row nowrap',
		justifyContent: 'flex-end',
		alignItems: 'center',

		'&>.MuiGlueIcon-root': {
			width: '48px',
			minWidth: '48px'
		},
	},
}), { name: 'MuiGlueBatteryIndicator' });

const BatteryIndicator = (props) =>
{
	const classes = useStyles(props);
	const { data } = useQuery(queries.batteryStatus);

	if (!data)
		return null;

	if (props.hideIfNotPresent && !data.batteryStatus.present)
		return null;

	const chooseBatteryIcon = (present, charging, level) =>
	{
		if (!present)
			return <BatteryUnknownIcon />

		if (level > 0.95)
			return charging ? <BatteryChargingFullIcon /> : <BatteryFullIcon />;
		else if (level > 0.90)
			return charging ? <BatteryCharging90Icon /> : <Battery90Icon />;
		else if (level > 0.80)
			return charging ? <BatteryCharging80Icon /> : <Battery80Icon />;
		else if (level > 0.60)
			return charging ? <BatteryCharging60Icon /> : <Battery60Icon />;
		else if (level > 0.50)
			return charging ? <BatteryCharging50Icon /> : <Battery50Icon />;
		else if (level > 0.30)
			return charging ? <BatteryCharging30Icon /> : <Battery30Icon />;
		else if (level > 0.20)
			return charging ? <BatteryCharging20Icon /> : <Battery20Icon />;
		else
			return charging ? <BatteryCharging20Icon /> : <BatteryAlertIcon color="error" />;
	};

	return (
		<div className={classes.root}>
			<p>{Math.round(data.batteryStatus.chargeLevel * 100) + "%"}</p>
			<GlueIcon>{chooseBatteryIcon(data.batteryStatus.present, data.batteryStatus.charging, data.batteryStatus.chargeLevel)}</GlueIcon>
		</div>
	);
};

export default BatteryIndicator;
