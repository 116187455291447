import React from 'react'
import { useApolloClient } from '@apollo/client';
import { usePromptDialogContext } from '../../util/prompt-dialog-context';
import InfoDialogTemplate from '../common/info-dialog-template';
import mutations from '../../graphql/mutations';

const ResetSpaceDialog = (props: {
	roomId: string
	onSuccess?: () => void
	onError?: (error: Error) => void
}) =>
{
	const { addDialog } = usePromptDialogContext();
	const apollo = useApolloClient();

	const resetSpace = async () => {
		await apollo.mutate({
			mutation: mutations.resetSpace,
			variables: {
				roomId: props.roomId
			}
		}).then (res => {
			addDialog(<InfoDialogTemplate
				header={'Success!'}
				message={"The space was successfully reset."}
			/>);
			if (props.onSuccess) {
				props.onSuccess();
			}
		})
		.catch(err => {
			addDialog(<InfoDialogTemplate
				header={'Failed to reset space!'}
				message={(err as Error).message}
			/>);
			if (props.onError) {
				props.onError(err);
			}
		});
	}

	return (
		<InfoDialogTemplate
			header={"Reset?"}
			message={"Are you sure you want to reset the space? This action can not be undone."}
			callbacks={[
				{ callback: () => null, label: 'Cancel' },
				{ callback: resetSpace, label: 'Reset', color: "destructive" }
			]}
		/>
	);
}

export default ResetSpaceDialog;
