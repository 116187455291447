import React, { useState, useEffect } from 'react';
import { makeStyles, ButtonBase, FormControlLabel, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { postVuplexMessage } from '../service/message-vuplex';

import AppHeader from './common/app-header';
import SliderSelector from './common/slider-selector';
import GlueButton from './common/glue-button';
import GlueToggle from './common/glue-toggle';
import SwitchSelector from './common/switch-selector';
import GridOnIcon from '@material-ui/icons/GridOn';
import GridOffIcon from '@material-ui/icons/GridOff';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import FlipCameraAndroidIcon from '@material-ui/icons/FlipCameraAndroid';
import ShutterIcon from '../icons/Camera_Shutter.png'

import queries from '../graphql/queries';
import { useQuery } from '@apollo/react-hooks';

import MouselookHeroIcon from './mouselook-hero-icon';
import { HeroIconContext } from './common/hero-icon';
import LoadingIndicator from './common/loading-indicator';

import { hoverAudio, clickAudio } from './common/common-vuplex-messages';

const useStyles = makeStyles(theme => ({
	footer: {
		position: 'absolute',
		display: 'flex',
		left: 0,
		bottom: 0,
		right: 0,
		background: theme.palette.background.paper,
		justifyContent: 'space-between',
		height: '168px',
		padding: '16px 48px 24px 48px',
		alignItems: 'flex-end'
	},

	shutterButton: {
		margin: '0px 48px 0px 48px',
		height: 'auto'
	},

	formControl: {
		background: 'rgba(41, 45, 51, 1)',
		borderRadius: '50px',
		display: 'flex',
		justifyContent: 'center',
		padding: '4px 18px 4px 18px',
		height: '6vh',
	},

	teamfiles: {
		display: 'flex',
		alignItems: 'center',
		position: 'relative',

		'&> p': {
			marginRight: theme.glueSpacing('m'),
			width: '300px',
			position: 'absolute',
			right: '64px',
		}
	},

	controlButtons: {
		display: 'flex',
		justifyContent: 'flex-end',
		flexDirection: 'row',
		alignItems: 'center',
		gap: theme.glueSpacing('xl')
	},

	mouseLookBtn: {
		display: 'flex',
		width: '80px',
		height: '80px',
		background: theme.palette.background.paper,
		borderRadius: '32px',
		alignItems: 'center',
		justifyContent: 'center'
	},

	fovSlider: {
		display: 'flex',
		flexDirection: 'column',
		width: '527px',
		height: '80px',
		justifyContent: 'center'
	},

	loadingIndicator: {
		backgroundColor: 'transparent',
		height: '128px',
		width: '128px',
		position: 'absolute',
		right: '-32px',
		top: '-4px',
	},

	controls: {
		display: 'flex',
		alignItems: 'center'
	},

	thumbnailHolder: {
		width: '199px',
		height: '112px',
		borderRadius: '16px',
		marginRight: theme.spacing('l')
	},

	operators: {
		display: 'flex',
		gap: theme.glueSpacing('m')
	},

	thumbnail: {
		width: "119px",
		height: "112px",
		borderRadius: '4px'
	}
}));

const takePhoto = (teamId) => {
	postVuplexMessage('Take photo', { value: teamId });
};

const Camera = (props) => {

	const { data } = useQuery(queries.cameraStatus);
	const fileData = useQuery(queries.fileUpload);
	const teamIdRes = useQuery(queries.currentTeamId);
	
	const selectedResolution = data && data.cameraStatus.isUHD ? true : false;
	let selectedFovID = data && data.cameraStatus ? data.cameraStatus.fov : 35;
	const selfieCameraState = data && data.cameraStatus.selfieMode ? true : false;

	const classes = useStyles();

	const [gridOn, setGridOn] = useState(false);

	const clientPlatformResult = useQuery(queries.clientPlatform);
	const clientPlatform = clientPlatformResult.data?.clientPlatform;
	const vr = clientPlatform?.PlatformType === "VR";

	useEffect(() => {
		postVuplexMessage("Open camera", null);
		return () => {
			postVuplexMessage("Close camera", null);
		};
	}, []);

	const toggleGrid = (props) => {
		setGridOn(!gridOn);
		postVuplexMessage('Toggle grid', { value: !gridOn });
		
	};

	const flipFrontCamera = (props) => {
		postVuplexMessage('Toggle selfie camera', { value: !selfieCameraState });
		postVuplexMessage("Get camera status");
	};

	const title = "Camera";

	const [fvalue, setFValue] = React.useState(selectedFovID);
	const [isUHD, setIsUHD] = React.useState(selectedResolution);

	const handleFovSliderChange = (event, newValue) => {
		setFValue(newValue);

		postVuplexMessage('Change fov', { value: newValue });
		postVuplexMessage("Get camera status");
	};

	const handleResChange = (checked) => {
		
		if(checked)
		{
			setIsUHD(true);
			postVuplexMessage('Change resolution', { value: '3840x2160' });
		}
		else
		{
			setIsUHD(false);
			postVuplexMessage('Change resolution', { value: '1920x1080' });
		}
		console.log("Val: " + checked);
		postVuplexMessage("Get camera status");
	};

	const resSelect =
	(
		<FormControlLabel
			control={
				<SwitchSelector
					toggle
					offText={'HD'}
					onText={'UHD'}
					onChange={handleResChange}
					checked={isUHD}
				/>
			}
		/>
	);

	const fovslideraudiomessage = "Apps/Camera/FovSlider/Press";
	const fovsliderreleaseaudiomessage = "Apps/Camera/FovSlider/Release";
	const fovsliderhoveraudiomessage = "Apps/Camera/FovSlider/HL";

	const fovSlider =
	(
		<div className={classes.fovSlider}>
			<SliderSelector
				defaultValue={fvalue} min={18} max={135} value={fvalue} 
				isActive={true}
				uiAudioMessage = {fovslideraudiomessage}
				uiReleaseAudioMessage = {fovsliderreleaseaudiomessage}
				uiHoverAudioMessage = {fovsliderhoveraudiomessage}
				onChange={handleFovSliderChange}
			/>
			<Typography>{fvalue} mm</Typography>
		</div>
	);

	const takeaudiomessage = "Apps/Camera/Shutter/Press";
	const takehoveraudiomessage = "Apps/Camera/Shutter/HL";

	const takePhotoButton =
	(
		<ButtonBase
			classes={{ root: classes.shutterButton }}
			onPointerDown={() => {takePhoto(teamIdRes.data?.currentTeamId); clickAudio(takeaudiomessage)}}
			onPointerEnter={() => {hoverAudio(takehoveraudiomessage)}}
		>
			<img
				style={{width: '120px'}}
				src={ShutterIcon}
				alt={"Glue Collaboration"}
			/>
		</ButtonBase>
	);

	const gridtoggleOffSound = "Apps/Camera/GridView/Toggle/Off/Press";
	const gridtoggleOnSound = "Apps/Camera/GridView/Toggle/On/Press";
	const gridaudiomessage = gridOn ? gridtoggleOffSound :  gridtoggleOnSound; 
	const gridhoveraudiomessage = "Apps/Camera/GridView/HL";
	
	const gridButton = (
		<GlueToggle
			toggled={gridOn} 
			onPointerDown={() => toggleGrid(props)}
			uiAudioMessage = {gridaudiomessage}
			uiHoverAudioMessage = {gridhoveraudiomessage} 
		>
			{gridOn ? <GridOnIcon /> : <GridOffIcon />}
		</GlueToggle>
	);

	const selfietoggleOffSound = "Apps/Camera/Selfie/Toggle/Off/Press";
	const selfietoggleOnSound = "Apps/Camera/Selfie/Toggle/On/Press";
	const selfieaudiomessage = selfieCameraState ? selfietoggleOffSound :  selfietoggleOnSound; 
	const selfiehoveraudiomessage = "Apps/Camera/Selfie/HL";

	const flipCamera =
	(
		<div>
			<GlueToggle
				toggled={selfieCameraState} 
				onPointerDown={flipFrontCamera}
				uiAudioMessage = {selfieaudiomessage}
				uiHoverAudioMessage = {selfiehoveraudiomessage} 
			>
				<FlipCameraAndroidIcon />
			</GlueToggle>
		</div>
	);

	const galleryaudiomessage = "Apps/Camera/Gallery/Press";
	const galleryhoveraudiomessage = "Apps/Camera/Gallery/HL";

	const gallery =
	(
		<GlueButton 
			variant='icon'
			uiAudioMessage = {galleryaudiomessage}
			uiHoverAudioMessage = {galleryhoveraudiomessage} 
		>
			<PhotoLibraryIcon />
		</GlueButton>
	);

	const teamFileControls =
	(
		<div className={classes.teamfiles}>
			{fileData.data.fileUpload.uploadStatus === 'uploading' &&
				<>
					<Typography>Saving to Team Files...</Typography>
					<LoadingIndicator variant='icon'/>
				</>
			}
			<Link to={"/view/main/files"}>
				{gallery}
			</Link>
		</div>
	);

	const heroIconContext =
	{
		narrow: true,
		showHotkey: true
	};

	const mouseLookButton =
	(
		<HeroIconContext.Provider value={heroIconContext}>
			<MouselookHeroIcon narrow disableName noBackground/>
		</HeroIconContext.Provider>
	);
	
	return (<>
		<AppHeader 
			children={resSelect} 
			title={title} 
			secondChildren={teamFileControls} 
		/>
		
		<div className={classes.footer}>
			<div className={classes.controls}>
				<div className={classes.controlButtons}>
					<div className={classes.mouseLookBtn}>
						{vr ? null : mouseLookButton}
					</div>
					{/* This is a placeholder for last photo thumbnail.
					The new design has this area defined in the footer and thus this is here now.
					A branch has this feature, but a bug is present on Quest1 and needs to be sorted first */}
					<div className={classes.thumbnailHolder}>
					</div>
					<div className={classes.operators}>
						{flipCamera}
						{gridButton}
					</div>
				</div>
			</div>
			{takePhotoButton}
			{fovSlider}
		</div>
	</>);
}

export default Camera;

