import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/client';
import queries from '../../graphql/queries';
import GlueButton from '../common/glue-button';
import mutations from '../../graphql/mutations';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { usePromptDialogContext } from '../../util/prompt-dialog-context';
import InfoDialogTemplate from '../common/info-dialog-template';
import WebInputfield from '../../standalone-web/common/web-inputfield';
import OrganizationAccordion from '../organization/org-accordion';
import { Asset, AssetOrgLink, Organization, Team } from '../../graphql/types-generated';
import GlueIcon from '../common/glue-icon';
import PageSelector from '../common/page-selector';

const useStyles = makeStyles(theme => ({
	root: {

	},

	assetDetails: {
		display: 'flex',
		gap: '12px',
		flexFlow: 'column nowrap'
	},

	assetDetailsFormContainer: {
		display: 'grid',
		gridTemplateColumns: '1fr min-content',
		gap: '32px'
	},

	existingVersions: {
		display: 'flex',
		height: '280px'
	},

	listSlab: {
		background: theme.palette.background.paper,
		marginBottom: '1em',
		display: 'grid',
		gridTemplateColumns: '1fr min-content min-content',
		alignItems: 'center'
	},

	linkTeamsStaging: {
		width: '100%',
		display: 'grid',
		gridTemplateColumns: '70% auto',
		gap: theme.glueSpacing('l')
	},

	teamSlab: {
		background: theme.palette.background.paper,
		borderRadius: '16px',
		padding: '16px',
		marginBottom: '12px',
		display: 'grid',
		width: '80%',
		gridTemplateColumns: '1fr min-content',
		alignItems: 'center'
	},

	thumbnail: {
		display: 'block',
		width: '296px',
		height: '168px'
	},

	orgList: {
		padding: `${theme.glueSpacing('s')} 0`,
		display: 'flex',
		gap: theme.glueSpacing('s'),
		flexFlow: 'column'
	},

	orgDisplay: {
		display: 'flex',
		gap: theme.glueSpacing('m')
	}
}));

const OrgDisplay = (props: {
	org: Organization
	linkedOrgs: AssetOrgLink[]
	selectedTeams: string[]
	attachOrg: (orgId: string) => void
	stageTeam: (team: Team) => void
}) =>
{
	const classes = useStyles();
	const getTeamsRes = useQuery(queries.getOrgTeams, {
		variables: {
			orgId: props.org.orgId
		}
	});

	const teams: Team[] = getTeamsRes.data?.getOrgTeams ?? [];
	const displayTeams = teams.filter(team => !props.linkedOrgs.some(org => org.linkedTeams.some(linked => team.teamId === linked.teamId)));

	return(
		<div className={classes.orgDisplay}>
			<OrganizationAccordion
				showId
				width='75%'
				key={props.org.orgId}
				orgId={props.org.orgId}
				organization={props.org}
				teams={displayTeams}
				onSelect={props.stageTeam}
				selectedTeams={props.selectedTeams}
			/>
			{!props.linkedOrgs.some((org: AssetOrgLink) => org.orgId === props.org.orgId && org.linked) && (
				<GlueButton onPointerDown={() => props.attachOrg(props.org.orgId)}>Link org</GlueButton>
			)}
		</div>
	);
}

const LinkTeams = (props: {
	asset?: Asset
}) =>
{
	const classes = useStyles();
	const [ selectedTeams, setSelectedTeams ] = useState<Team[]>([]);
	const [ searchInputValue, setSearchInputValue ] = useState('');
	const [ searchWord, setSearchWord ] = useState('');
	const [ page, setPage ] = useState(0);
	const { addDialog } = usePromptDialogContext();

	const orgsRes = useQuery(queries.findOrganizationsByName, {
		skip: !searchWord,
		variables: {
			name: searchWord
		},
		fetchPolicy: 'network-only'
	});
	const linkedRes = useQuery(queries.assetLinkedOrgsAndTeams, {
		skip: !props.asset,
		variables: {
			assetId: props.asset?.assetId
		},
		fetchPolicy: 'network-only'
	});

	const [ assetAttachTeamMut ] = useMutation(mutations.assetAttachTeam);
	const [ assetDetachTeamMut ] = useMutation(mutations.assetDetachTeam);
	const [ assetAttachOrgMut ] = useMutation(mutations.assetAttachOrg);
	const [ assetDetachOrgMut ] = useMutation(mutations.assetDetachOrg);

	const attachTeams = async (teams: Team[]) =>
	{
		if (!props.asset) {
			return;
		}

		try {
			for (let index = 0; index < teams.length; index++) 
			{
				const team = teams[index];
				await assetAttachTeamMut({
					variables: {
						assetId: props.asset?.assetId,
						teamId: team.teamId,
					},
					refetchQueries: [{
						query: queries.myAssets,
						variables: { typeFilter: 'space' },
					}]
				});
			}
		}
		catch (err) {
			addDialog(<InfoDialogTemplate
				header={'Error linking team!'}
				message={(err as Error).message}
				isError
			/>);
		}

		setSelectedTeams([]);
		linkedRes.refetch();
	};

	const detachTeam = async (teamId: string) =>
	{
		if (!props.asset) {
			return;
		}

		await assetDetachTeamMut({
			variables: {
				assetId: props.asset?.assetId,
				teamId: teamId,
			},
			refetchQueries: [{
				query: queries.myAssets,
				variables: { typeFilter: 'space' },
			}]
		});

		linkedRes.refetch()
	};

	const attachOrg = async (orgId: string) => {
		if (!props.asset) {
			return;
		}

		try {
			await assetAttachOrgMut({
				variables: {
					assetId: props.asset?.assetId,
					orgId: orgId,
				},
				refetchQueries: [{
					query: queries.myAssets,
					variables: { typeFilter: 'space' },
				}]
			});
		}
		catch (err) {
			addDialog(<InfoDialogTemplate
				header={'Error linking org!'}
				message={(err as Error).message}
				isError
			/>);
		}

		linkedRes.refetch();
	}

	const detachOrg = async (org: AssetOrgLink) => {
		if (!props.asset) {
			return;
		}

		const detachOrg = async () => {
			await assetDetachOrgMut({
				variables: {
					assetId: props.asset?.assetId,
					orgId: org.orgId,
				},
				refetchQueries: [{
					query: queries.myAssets,
					variables: { typeFilter: 'space' },
				}]
			});

			linkedRes.refetch();
		}

		const detachOrgAndTeams = async () => {
			await Promise.all(org.linkedTeams.map(team => detachTeam(team.teamId)));
			await detachOrg();
		}

		if (org.linkedTeams.length !== 0) {
			addDialog(<InfoDialogTemplate
				header={"Detach teams?"}
				message={"This asset has also been linked to individual teams, do you want to unlink them as well?"}
				callbacks={[
					{ callback: () => null, label: 'Cancel' },
					{ callback: detachOrg, label: 'Only org', color: 'destructive' },
					{ callback: detachOrgAndTeams, label: 'Promote', color: 'destructive' }
				]}
			/>);
		} else {
			await detachOrg();
		}
	}


	const linkedOrgs: AssetOrgLink[] = linkedRes.data?.assetLinkedOrgsAndTeams ?? [];
	let displayOrgs: Organization[] = orgsRes.data?.findOrganizationsByName ?? [];
	const totalOrgCount = displayOrgs.length;
	const orgsPerPage = 20;
	displayOrgs = displayOrgs.slice(page * orgsPerPage, Math.min(totalOrgCount, page * orgsPerPage + orgsPerPage));

	const stageTeam = (team: Team) =>
	{
		if (selectedTeams.some((t => t.teamId === team.teamId)))
			return;

		setSelectedTeams([...selectedTeams, team]);
	};

	const unstageTeam = (team: Team) =>
	{
		setSelectedTeams(selectedTeams.filter(t => t.teamId !== team.teamId));
	};

	const selectedTeamIds = selectedTeams.map(team => team.teamId);

	return (
		<div className={classes.root}>
			<h2>Linked organizations/teams</h2>
			<ul>
				{linkedOrgs.map((org: AssetOrgLink) => (
					<li key={org.orgId}>
						<p>{org.name} ({org.orgId})</p>
						{org.linked && (
							<GlueButton onPointerDown={() => detachOrg(org)}>Unlink org</GlueButton>
						)}
						{org.linkedTeams.map((team: Team) => (
							<ul key={team.teamId}>
								<li>
									<p>{team.name} ({team.teamId})</p>
									<GlueButton onPointerDown={() => detachTeam(team.teamId)}>Unlink team</GlueButton>
								</li>
							</ul>
						))}
					</li>
				))}
			</ul>
	
			<h3>Add more linked orgs/teams</h3>
			<div>Search for orgs:</div>
			<WebInputfield
				search
				value={searchInputValue}
				onClear={() => setSearchInputValue('')}
				onChange={(value: string) => setSearchInputValue(value)}
				onSubmitKey={() => setSearchWord(searchInputValue)}
				showClearButton={!!searchInputValue}
				placeholder='Search by org name'
			/>
			<div className={classes.linkTeamsStaging}>
				<div className={classes.orgList}>
					{displayOrgs.map((org) => (
						<OrgDisplay 
							key={org.orgId} 
							org={org} 
							linkedOrgs={linkedOrgs} 
							attachOrg={attachOrg}
							stageTeam={stageTeam}
							selectedTeams={selectedTeamIds}
						/>
					))}        
				</div>
				<div>
					<div>Selected teams:</div>
					<div>
						{selectedTeams.map((team => (
							<div className={classes.teamSlab} key={team.teamId}>
								<div>{team.name} - {team.teamId}</div>
								<GlueButton variant='icon' onPointerDown={() => unstageTeam(team)}>
									<GlueIcon><CloseOutlinedIcon /></GlueIcon>
								</GlueButton>
							</div>
						)))}
					</div>
	
					<GlueButton
						disabled={selectedTeams.length < 1}
						onPointerDown={() => attachTeams(selectedTeams)}
					>
						{selectedTeams.length > 1 ? 'Link teams' : 'Link team'}
					</GlueButton>
				</div>
			</div>
			<PageSelector 
				useInput
				size='extraLarge'
				current={page}
				onPageChange={setPage}
				pageCount={Math.ceil(totalOrgCount / orgsPerPage)}
			/>
		</div>
	);
}

export default LinkTeams;
