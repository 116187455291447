import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import postVuplexMessage from '../service/message-vuplex';
import HeroIcon from './common/hero-icon';
import queries from '../graphql/queries';

import Tablet_OPEN_Wrist from '../icons/Tablet_OPEN_Wrist.png';
import Menu_CLOSE from '../icons/Menu_CLOSE.png';
import TabletDisabledIcon from '../icons/Tablet_FacLimit.png'

const TabletHeroIcon = (props) =>
{
	const openTablet = () => postVuplexMessage('Open tablet', null);
	const closeTablet = () => postVuplexMessage('Close tablet', null);

	const isToolbar = props.isToolbar;

	const clientPlatformResult = useQuery(queries.clientPlatform);
	const clientPlatform = clientPlatformResult.data?.clientPlatform;
	const vr = clientPlatform?.PlatformType === "VR";
	const uiDisabledResult = useQuery(queries.uiDisabled);
	const uiDisabled = uiDisabledResult.data?.uiDisabled;

	const toggleOffSound = "Tools/Tablet/CloseTablet/Press";
	const toggleOnSound = "Tools/Tablet/OpenTablet/Press";
	const audiomessage = isToolbar ? toggleOnSound :  toggleOffSound; 
	const hoveraudiomessage = "Tools/Tablet/HL";
	const tabletLabel = uiDisabled ? "Tablet" : isToolbar ? "Open Tablet" : "Close Tablet";
	const tabletToggleDisabled = uiDisabled ? true: isToolbar ? vr : false ; 
	const tabletIcon =  uiDisabled ? TabletDisabledIcon : isToolbar ? Tablet_OPEN_Wrist : Menu_CLOSE

	return (
		<HeroIcon
			name={tabletLabel}
			disabled={tabletToggleDisabled}
			noBackground={props.noBackground}
			icon={tabletIcon}
			onPointerDown={isToolbar ? openTablet : closeTablet}
			uiAudioMessage = {audiomessage}
			uiHoverAudioMessage = {hoveraudiomessage}
			hotkey="Tab"
			hotkeyCenter
			facLocked={uiDisabled}
		/>
	);
};

export default TabletHeroIcon;
