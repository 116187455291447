import React, { forwardRef, useCallback, useEffect, useRef } from 'react';

import { InputBase } from '@material-ui/core';
import { clickAudio } from './common-vuplex-messages';
import { useApolloClient } from '@apollo/react-hooks';
import { setKeyboardActive } from '../../util/control-util';

import getAssignerFnForRefs from '../../util/assigner-for-refs';

const InputField = (props, ref) =>
{
	const { autoFocus, autoCursor } = props;
	const apollo = useApolloClient();

	const inputRef = useRef();

	const handleInputState = (event, value) =>
	{
		console.log("Input is focused = " + value + "   " + event?.target)
		const audiomessage = value ? "Tools/InputField/Toggle/On" : "Tools/InputField/Toggle/Off";
		if (props.focusInput)
		{
			props.focusInput(value);
		}
		setKeyboardActive(value, apollo, props?.delayInSeconds ?? 0.0);
		clickAudio(audiomessage);
		
		if (event)
		{
			if (props.caretPosition !== undefined)
			{
				event.target.selectionStart = props.caretPosition;
			}
			else if (props.value !== undefined)
			{
				event.target.selectionStart = props.value.length;
			}
		}

		if (props.onBlur)
		{
			props.onBlur();
		}
	}

	const handleTextChange = (event) =>
	{
		if (props.onChange)
		{
			props.onChange(event.target.value);
		}
	}

	const handleKeyEventEnter = (event) =>
	{
		if (props.onSubmitKey && event.key === 'Enter')
		{
			props.onSubmitKey();
		}
		if (props.onSpaceKey && event.key === ' ')
		{
			props.onSpaceKey();
		}
	}
	
	const startCallback = useCallback(() => {
		if (autoFocus && inputRef)
		{
			inputRef.current.focus();
			if(autoCursor)
			{
				inputRef.current.setSelectionRange(0,0);
			}
		}
	},[autoFocus, inputRef, autoCursor])

	useEffect(() => {
		let timerID = setTimeout(() => {
			startCallback();
		}, 150);

		return () => {
			clearTimeout(timerID);
		}
	},[startCallback])

	return (
		<InputBase className={props.rootStyle}
			disabled={props.disabled}
			onFocus={e => handleInputState(e, true)}
			onBlur={e => handleInputState(e, false)}
			value={props.value}
			onChange={e => handleTextChange(e)}
			placeholder={props.placeholder}
			inputProps={{ className: props.inputStyle, style: props.style, maxLength: props.maxLength }}
			inputRef={getAssignerFnForRefs([inputRef, ref])}
			multiline={props.multiline}
			rows={props.rows}
			rowsMax={props.rowsMax}
			onKeyUp={e => handleKeyEventEnter(e)}
		/>
	)
}

export default forwardRef(InputField);