import React, { useState } from 'react';
import ColorSelector from '../common/color-selector';
import GlueToggle from '../common/glue-toggle';
import GlueDropdown from '../common/glue-dropdown';

import InputField from '../common/glue-inputfield';

import { makeStyles } from '@material-ui/core';

import LockOpenIcon from '@material-ui/icons/LockOpen';

import { GlueAccordion, GlueAccordionContentBlock } from '../common/glue-accordion';
import { getCurrentNoteShape, noteSizes, setNoteProperty, setNoteShapePreset } from '../../service/note-editor';
import { useNote } from './note-hooks';
import { Note } from './note-types';

const useStyles = makeStyles(theme => ({
	dimensionInput: {
		display: 'flex',
		justifyContent: 'flex-end',
		'&> button': {
			marginLeft: theme.glueSpacing('m')
		},
		'&> div> div': {
			marginLeft: theme.glueSpacing('m')
		},
	},

	noteDimensionText: {
		width: '370px',
		textAlign: 'center',
		textTransform: 'lowercase',
		color: theme.palette.secondary.contrastText
	}
}));

type NoteProp = {
	prop: keyof Note
	value: Note[keyof Note]
}

const WidthAndHeight = (props: {
	note: Note | null
}) =>
{
	const classes = useStyles();
	const [ editedField, setEditedField ] = useState<NoteProp | null>(null);
	const [ proportionalEditing, setProportionalEditing ] = useState(false);

	const minDimension = 5;
	const maxDimension = 200;

	const validateInputValue = (value: Note[keyof Note]) => {
		const floatValue = (typeof(value) === 'number') ? value : parseFloat(value ? value : "0.0");
		if (!isFinite(floatValue))
		{
			return 0;
		}
		
		return floatValue;
	}

	const editValue = (prop: keyof Note, value: string) => {
		setEditedField({ prop: prop, value: value });
	}

	const clampDecimals = (value: number) => {
		return Math.round((value + Number.EPSILON) * 100) / 100;
	}

	const submitValue = (prop: keyof Note) => {
		if (!props.note) {
			return;
		}

		if (editedField && editedField.prop === prop)
		{
			const validatedValue = validateInputValue(editedField.value);
			if (proportionalEditing)
			{
				const aspectRatio = props.note.width / props.note.height;
				let newWidth = prop === 'width' ? validatedValue : validatedValue * aspectRatio;
				let newHeight = prop === 'height' ? validatedValue : validatedValue / aspectRatio;
				
				// Clamp values based on which one is larger
				if (aspectRatio > 1)
				{
					if (validatedValue > props.note[prop])
					{
						const maxHeight = maxDimension / aspectRatio;
						newHeight = Math.min(newHeight, maxHeight);
						newWidth = newHeight * aspectRatio;
					}
					else
					{
						const minWidth = minDimension * aspectRatio;
						newWidth = Math.max(newWidth, minWidth);
						newHeight = newWidth / aspectRatio;
					}
				}
				else
				{

					if (validatedValue > props.note[prop])
					{
						const maxWidth = maxDimension * aspectRatio;
						newWidth = Math.min(newWidth, maxWidth);
						newHeight = newWidth / aspectRatio;
					}
					else
					{
						const minHeight = minDimension / aspectRatio;
						newHeight = Math.max(newHeight, minHeight);
						newWidth = newHeight * aspectRatio;
					}
				}

				setNoteProperty('width', clampDecimals(newWidth));
				setNoteProperty('height', clampDecimals(newHeight));
			}
			else
			{
				const clampedValue = Math.min(Math.max(validatedValue, minDimension), maxDimension);
				setNoteProperty(prop, clampDecimals(clampedValue));
			}

			setEditedField(null);
		}
	}

	const getValue = (prop: keyof Note) => {
		if (!props.note) {
			return null;
		}

		if (editedField && editedField.prop === prop)
		{
			return editedField.value;
		}
		else return props.note[prop] ?? 0;
	}

	return (
		<div className={classes.dimensionInput}>
			<InputField
				centerText
				backgroundColor={'black'}
				simpleInput
				width={'128px'}
				onChange={(val: string) => editValue('width', val)}
				onSubmit={() => submitValue('width')}
				onBlur={() => submitValue('width')}
				value={getValue('width')}
			/>
			<GlueToggle
				toggled={proportionalEditing === true}
				onPointerDown={() => setProportionalEditing(!proportionalEditing)}
			>
				<LockOpenIcon/>
			</GlueToggle>
			<InputField
				numbers
				centerText
				backgroundColor={'black'}
				simpleInput
				width={'128px'}
				onChange={(val: string) => editValue('height', val)}
				onSubmit={() => submitValue('height')}
				onBlur={() => submitValue('height')}
				value={getValue('height')}
			/>
		</div>	
	);
}

const Format = () => 
{	
	const shapeId = getCurrentNoteShape();

	return (
		<GlueDropdown
			width={'450px'}
			label={shapeId === "Custom" && "Custom"}
			items={noteSizes}
			value={shapeId}
			onChange={(id) => setNoteShapePreset(id as string)}
		/>
	);
}

const NoteStyle = (props: {
	open: boolean
	noteDimensions?: string
	toggleOpen: () => void
	editNoteColor: () => void
}) =>
{
	const classes = useStyles();

	const note = useNote();

    return (
		<GlueAccordion title={"Note Style"} open={props.open} onToggleOpen={props.toggleOpen}>
			<GlueAccordionContentBlock label={"Format"} beginSubgroup>
				<Format />
			</GlueAccordionContentBlock>
			<GlueAccordionContentBlock label={"Width and Height"} beginSubgroup>
				<WidthAndHeight note={note}/>
			</GlueAccordionContentBlock>
			<GlueAccordionContentBlock>
				<div className={classes.noteDimensionText}>
					{props.noteDimensions}
				</div>
			</GlueAccordionContentBlock>
			<GlueAccordionContentBlock label={"Color"} beginSubgroup>
				<ColorSelector
					color={note?.noteColor ?? '#000'}
					onPointerDown={props.editNoteColor}
				/>
			</GlueAccordionContentBlock>
		</GlueAccordion>
    );

}

export default NoteStyle;