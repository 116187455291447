import React, { useEffect } from "react";
import { Theme, makeStyles } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import queries from "../graphql/queries";
import GlueButton from "./common/glue-button";
import OpenTablet from '../icons/GlueSysIcon_OpenTablet.png'
import CloseIcon from '@material-ui/icons/Close';
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import MicDisabledIcon from '../icons/Mic_FacLimit.png';
import TabletDisabledIcon from '../icons/Tablet_FacLimit.png'

import postVuplexMessage from "../service/message-vuplex";

import BatteryIndicator from "./battery-indicator";
import ClockNow from "./clock/clock-now";
import MicActivityBar from "./mic-activity-bar";
import { GlueTheme } from "../service/glue-theme";

type HandStyleProps = {
	active: boolean
}

const useStyles = makeStyles<Theme, HandStyleProps>((theme) => ({
	root: {
		display: 'flex',
		width: '160px',
		background: theme.palette.background.paper,
		borderRadius: '40px',
		padding: '8px',
		gap: theme.glueSpacing('s'),
		flexDirection: 'column',
		justifyContent: 'flex-end'
	},

	micButton: {
		...theme.custom.icon,
		paddingBottom: theme.glueSpacing('xs'),
		justifyContent: 'flex-end',
		flexDirection: 'column'
	},

	tabletButton: {
		...theme.custom.icon,
		paddingBottom: theme.glueSpacing('xs'),
		justifyContent: 'flex-end',
		flexDirection: 'column'
	},

	activityHolder: ({active}) => ({
		height: '8px',
		opacity: active ? 1 : 0.6,
	}),

	item: ({active}) => ({
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: theme.custom.glueButton.main.height,

		'&>.MuiGlueBatteryIndicator-root': {
			opacity: active ? 1 : 0.6,
			marginRight: '7px',
			flexFlow: 'row-reverse nowrap',

			'& p': {
				fontSize: '32px', 
			},
		},
	}),
}));

const Hand = () =>
{
	const microphoneResult = useQuery(queries.microphoneEnabled);
	const tabletOpenResult = useQuery(queries.tabletOpen);
	const menuActive = useQuery(queries.handUIActive);
	const { data } = useQuery(queries.batteryStatus);
	const showBatteryindicator = data.batteryStatus.present ? true : false;
	const microphoneLockedResult = useQuery(queries.microphoneLocked);
	const microphoneLocked = microphoneLockedResult.data?.microphoneLocked;
	const uiDisabledResult = useQuery(queries.uiDisabled);
	const uiDisabled = uiDisabledResult.data?.uiDisabled;
	
	const classes = useStyles({ active: menuActive.data.handUIActive });

	const width = 160;
	const height = showBatteryindicator ? 308 : 232;

	useEffect(() => {

		postVuplexMessage(
			"Hand.SetSize",
			{
				width: width,
				height: height
			}
		);
	}, [width, height]);


	const toggleTablet = () =>
	{
		if (tabletOpenResult.data.tabletOpen)
		{
			postVuplexMessage('Close tablet', null)
		}
		else
		{
			postVuplexMessage('Open tablet', null)
		}
	}

	const toggleMic = () =>
	{
		postVuplexMessage('Toggle microphone', null);
	};

	return (
		<div className={classes.root}>
			<div className={classes.item}>
				<ClockNow variant='handui' />
			</div>
			<GlueButton 
				color={menuActive.data.handUIActive ? uiDisabled ? tabletOpenResult.data.tabletOpen ? "secondary" : "secondary" : "primary" : "secondary"} 
				onPointerDown={() => toggleTablet()}
				disabled={uiDisabled}
			>
				<div className={classes.tabletButton}>
					{uiDisabled ? <img src={TabletDisabledIcon} /> : tabletOpenResult.data.tabletOpen ? <CloseIcon/> : <img src={OpenTablet}/>}
				</div>
			</GlueButton>
			<GlueButton
				onPointerDown={() => toggleMic()}
				toggled={menuActive.data.handUIActive ? microphoneResult.data.microphoneEnabled : false}
				disabled={microphoneLocked}
				color={menuActive.data.handUIActive ? "secondary" : "background"}
			>
				<div className={classes.micButton}>
					{microphoneLocked ? <img src={MicDisabledIcon} /> : !microphoneResult.data.microphoneEnabled ? <MicOffIcon /> : <MicIcon />}
					<div className={classes.activityHolder}>
						<MicActivityBar faded={menuActive.data.handUIActive} bgColor={GlueTheme.palette.grey[200]} widthSize={'80px'}/>
					</div>
				</div>
			</GlueButton>
			<div className={classes.item}>
				{showBatteryindicator && <BatteryIndicator hideIfNotPresent handui/>}
			</div>
		</div>
	)
}

export default Hand;
