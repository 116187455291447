import React from 'react';
import GlueDialog from '../../component/common/glue-dialog';
import { useQuery } from '@apollo/react-hooks';
import queries from '../../graphql/queries';

//Usage 
//import useDialogContext (custom hook) from dialog-contex in any react component
//destructure addDialog and closeProomptDialog functions from the hook
//call addDialog takes a react element
//the button that should close the dialog should call the closePromptDialog callback

const PromptDialog = (props) =>
{
	const openRes = useQuery(queries.promptDialogOpen)
	const { currentDialog } = props;

	return(
		<GlueDialog maxWidth='xl' open={openRes.data?.promptDialogOpen}>
			{currentDialog}
		</GlueDialog>
	)
}

export default PromptDialog;