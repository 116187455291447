import React, { useState } from 'react';
import { DialogContent, makeStyles, useTheme } from '@material-ui/core';

import GlueButton from '../common/glue-button';
import GlueDropdown from '../common/glue-dropdown';
import { orgMemberRoleNameMap } from '../../defines';

import AvatarThumbnail from '../../icons/AvatarThumbnail_Placeholder.png';

import DialogHeader from '../common/dialog-header';
import { useUserContext } from '../../util/user-context';

const themeVar = (theme, key) =>
	theme.custom.teamMemberDetails?.[key] ?? null;

const useStyles = makeStyles((theme) => ({
	content: {
		width: themeVar(theme, 'dialogWidth'),
		margin: 0,

		display: 'flex',
		flexFlow: 'column nowrap',
		alignItems: 'center'
	},

	avatarPicture: {
		display: 'block',
		width: '240px',
		height: '240px',
		objectFit: 'contain',
		margin: themeVar(theme, 'avatarPictureMargin')
	},

	emailAddress: {
		margin: themeVar(theme, 'emailAddressMargin')
	},

	invitatonPendingText: {
		margin: themeVar(theme, 'invitationPendingMessageMargin'),
		textTransform: 'capitalize',
		color: themeVar(theme, 'invitationPendingColor')
	},

	invitationActions: {
		width: themeVar(theme, 'actionColumnWidth'),
		display: 'flex',
		flexFlow: 'column nowrap',
		gap: themeVar(theme, 'baseGap'),
		margin: themeVar(theme, 'invitationActionsMargin')
	},

	roleBox: {
		width: themeVar(theme, 'actionColumnWidth'),
		margin: themeVar(theme, 'roleBoxMargin')
	},

	plainRole: {
		textAlign: 'center'
	},

	mainActions: {
		margin: themeVar(theme, 'mainActionsMargin'),
		width: themeVar(theme, 'actionColumnWidth'),
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
		gap: themeVar(theme, 'baseGap')
	},

	media: ({ invitationPending }) => ({
		objectFit: 'cover',
		margin: invitationPending ? '50px 0 0 0' : 'auto'
	})
}));

const MemberDetailsDialogContent = (props) =>
{
	const user = useUserContext();
	const invitationPending = props.data?.invitationStatus === 'pending';

	const classes = useStyles({ invitationPending });
	const theme = useTheme();

	const [selectedMemberRole, setSelectedMemberRole] = useState(props.data.memberRole);
	const isDirty = selectedMemberRole !== props.data.memberRole;

	const resendInvite = () => {
		if (invitationPending) {
			if (props.onResendInvite) {
				props.onResendInvite(props.data);
			}
		}
	};

	const removeMember = () => {
		props.onRemoveMember(props.data.email);
	};

	const saveChanges = () =>
	{
		if(selectedMemberRole !== props.data.memberRole)
		{
			props.onChangeRole(props.data, selectedMemberRole);
		}
	};

	const selectableRoles = Object.entries(orgMemberRoleNameMap)
		.filter(([ id ]) => id !== 'administrator')
		.map(([id, name]) => ({ id, name }));

	return (<>
		<DialogHeader 
			closeDialog={props.closeDialog}
			header={invitationPending ? "User Info" : props.data.name}
		/>
		<DialogContent className={classes.content}>
			<img className={classes.avatarPicture} src={AvatarThumbnail} alt={''} />

			<div className={classes.emailAddress}>{props.data.email}</div>

			{(invitationPending) ? (
				<h3 className={classes.invitatonPendingText}>{orgMemberRoleNameMap[props.data.memberRole]} - invitation pending</h3>
			) : (
				<div className={classes.roleBox}>
						{((user.team?.membersEdit && !props.self) || user.administrator) ? (<>
						<h4>Role</h4>
						<GlueDropdown
							width={themeVar(theme, 'actionColumnWidth')}
							items={selectableRoles}
							defaultValue={props.data.memberRole}
							onChange={(id, checked) => checked && setSelectedMemberRole(id)}
						/>
					</>) : (
						<h3 className={classes.plainRole}>{orgMemberRoleNameMap[props.data.memberRole]}</h3>
					)}
				</div>
			)}

			{((user.team?.membersEdit && !props.self) || user.administrator) && (<>
				<div className={classes.invitationActions}>
					{invitationPending && (
						<GlueButton
							color={"primary"}
							onPointerDown={() => { resendInvite(); props.closeDialog(); }}
						>
							Resend Invitation
						</GlueButton>
					)}

					<GlueButton
						color={'destructive'}
						onPointerDown={() => { removeMember(); props.closeDialog(); }}
					>
						{invitationPending ? (
							"Cancel Invitation"
						) : (
							"Remove from this team"
						)}
					</GlueButton>
				</div>

				{!invitationPending && (
					<div className={classes.mainActions}>
						<GlueButton
							onPointerDown={() => { props.closeDialog() }}
						>
							Cancel
						</GlueButton>

						<GlueButton
							color={'primary'}
							disabled={!isDirty}
							onPointerDown={() => { saveChanges(); props.closeDialog() }}
						>
							Save
						</GlueButton>
					</div>
				)}
			</>)}
		</DialogContent>
	</>);
};

export default MemberDetailsDialogContent;
