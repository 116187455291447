import React, { useState } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import InfoDialogTemplate from '../common/info-dialog-template';
import { createThumbnailUrl } from '../../util/file-backend-api-util';
import { usePromptDialogContext } from '../../util/prompt-dialog-context';
import { Asset, Room } from '../../graphql/types-generated';
import { createSpace, setSpaceThumbnail } from '../../util/space-utils';
import SpaceTemplateDetailsDialog from './space-template-details-dialog';

const CreateSpaceDialog = (props: {
	asset: Asset
	teamId: string
	web?: boolean
	refreshRooms: () => void
}) =>
{
	const apollo = useApolloClient();
	const { addDialog, closePromptDialog } = usePromptDialogContext();

	const [ errorMsg, setErrorMsg ] = useState<string>('');
	const [ loading, setLoading ] = useState<boolean>(false);

	const createRoom = async (name: string, customThumbnail?: File) =>
	{
		if (!props.teamId || !props.asset) {
			return;
		}

		setLoading(true);

		let room: Room | undefined = undefined;
		try {
			room = await createSpace(apollo, name, props.teamId, props.asset);
		}
		catch (err) {
			setErrorMsg((err as Error).message);
			setLoading(false);
			return;
		}

		if (customThumbnail && !!room) {
			const thumbnailUrl = await createThumbnailUrl(apollo, props.teamId, customThumbnail);
			await setSpaceThumbnail(apollo, room.roomId, thumbnailUrl ?? '');
		}

		props.refreshRooms();
		closePromptDialog();
		addDialog(<InfoDialogTemplate
			header={'Success!'}
			message={'New space was created with name'}
			variable={room?.name}
		/>);
	}

	return (
		<SpaceTemplateDetailsDialog
			title='New space details'
			defaultName={props.asset.name}
			buttonLabel='Create'
			web={props.web}
			canCreate={true}
			onCreate={createRoom}
			onCancel={closePromptDialog}
			errorMsg={errorMsg}
			onClearError={() => setErrorMsg('')}
			loading={loading}
			thumbnailUrl={props.asset.thumbnailUrl}
		/>
	);
};
export default CreateSpaceDialog;
