import React from 'react';
import { makeStyles } from '@material-ui/core';
import TeamSearch from './team-search';
import { useQuery } from '@apollo/react-hooks';
import queries from '../graphql/queries';
import GlueButton from './common/glue-button';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'grid',
		gridTemplateRows: 'auto',
		gap: '1em'
	},

	selectedContainer: {
		background: theme.palette.background.paper,
		padding: '1em'
	},
}));

const SingleTeamSearch = (props) =>
{
	const classes = useStyles();

	const teamInfoRes = useQuery(
		queries.teamInfo,
		{
			skip: !props.selected,
			variables: { teamId: props.selected ?? null }
		}
	);
	const teamInfo = teamInfoRes.data?.teamInfo ?? null;

	const orgIdRes = useQuery(
		queries.getOrgIdOfTeam,
		{
			skip: !props.selected,
			variables: { teamId: props.selected ?? null }
		}
	);

	const orgInfoRes = useQuery(
		queries.getOrg,
		{
			skip: !orgIdRes.data,
			variables: { orgId: orgIdRes.data?.getOrgIdOfTeam ?? null }
		}
	)

	const onSelected = (team) => {
		if (props.onChange)
			props.onChange(team?.teamId ?? null);

		return { clear: true };
	};

	return (
		<div className={classes.root}>
			<TeamSearch
				onChange={onSelected}
				selected={[props.selected]}
			/>

			<div className={classes.selectedContainer}>
				{teamInfo ? (<>
					<h3>Current selected team: {teamInfo?.name}</h3>
					<h3>Parent Organization: {orgInfoRes.loading ? '...' : orgInfoRes.data?.getOrg.name}</h3>
					<p>ID: {teamInfo?.teamId}</p>
					<GlueButton onClick={() => onSelected(null)}>Clear</GlueButton>
				</>) : (
					teamInfoRes.loading ? (
						<p>Loading selected team info...</p>
					) : (
						<p>No team has been selected! Plase use the search field to find some team and select it.</p>
					)
				)}
			</div>
		</div>
	);
};

export default SingleTeamSearch;
