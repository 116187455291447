import React, { useState } from 'react';
import queries from '../../graphql/queries';
import { useQuery } from '@apollo/react-hooks';

import { makeStyles } from '@material-ui/core';
import GlueInputfield from '../common/glue-inputfield';
import WebInputfield from '../../standalone-web/common/web-inputfield';
import GlueButton from '../common/glue-button';
import GlueRadioGroup from '../../standalone-web/common/glue-radio-group';
import { sttActive } from '../../util/speech-utils';
import { usePromptDialogContext } from '../../util/prompt-dialog-context';

const useStyles = makeStyles((theme) => ({
	root: theme.custom.infoDialog.root,

	header: theme.custom.infoDialog.header,

	body: {
		display: 'grid',
		gap: theme.glueSpacing('m'),
	},

	buttons: theme.custom.infoDialog.buttons,

	inputField: {
		display: 'grid',
		gridAutoFlow: 'column',
		gap: theme.glueSpacing('m')
	},

}));

const RenameFileDialog = (props) => {
	const classes = useStyles();

	const { closePromptDialog } = usePromptDialogContext();

	const [ fileName, setFileName ] = useState(props.filename);
	const [visibility, setVisibility] = useState(props.visibility);
	const renameSTTSinkId = "FileDialogRename";
	const sttRes = useQuery(queries.speechRecognition);
	const uiMode = useQuery(queries.ui);

	const visibilityOptions = [
		{ id: "public", name: 'Visible to all team members' },
		{ id: "private", name: 'Only visible to myself' }
	]

	const rncaudiomessage = "Tools/FileDialog/Rename/Cancel/Press";
	const rnchoveraudiomessage = "Tools/FileDialog/Rename/Cancel/HL";
	const rnraudiomessage = "Tools/FileDialog/Rename/Rename/Press";
	const rnrhoveraudiomessage = "Tools/FileDialog/Rename/Rename/HL";

	return (
		<div className = { classes.root } >
			<div className={classes.header}>
				<h2>Edit</h2>
			</div>
			< div className = { classes.body } >
				<p>File name:</p>
				<div className={classes.inputField}>
					{uiMode.data?.ui === 'web' ? (
						<WebInputfield
							autoFocus
							value={fileName !== null ? fileName : props.data.name}
							onChange={setFileName}
						/>
					) : (
						<GlueInputfield
							autoFocus
							invertedSpeechButton
							sinkId={renameSTTSinkId} 
							value={fileName}
							onChange={setFileName}
							speechInput={sttActive(sttRes, renameSTTSinkId)}
							onSpeechChange={(value)=>{  setFileName(value) }}
							onSubmit={() =>  props.onClickRename(fileName)}
						/>
					)}
				</div>
				<p>File visibility:</p>
				<GlueRadioGroup 
					options={visibilityOptions}
					value={visibility}
					onChange={setVisibility}
				/>
			</div>
			<div className={classes.buttons}>
				<GlueButton 
					onPointerDown={closePromptDialog}
					uiAudioMessage = {rncaudiomessage}
					uiHoverAudioMessage = {rnchoveraudiomessage}
				>
					Cancel
				</GlueButton>
				<GlueButton 
					onPointerDown={() => { closePromptDialog(); props.onSave(fileName, visibility); }}
					color="primary"
					uiAudioMessage = {rnraudiomessage}
					uiHoverAudioMessage = {rnrhoveraudiomessage}
				>
					Save
				</GlueButton>
			</div>
		</div>
	);
}

export default RenameFileDialog;