import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { matchPath, Route, Routes, useLocation, useNavigate } from "react-router-dom";

import TeamSelector from '../team-selector';
import GlueTab from '../common/glue-tab';
import AppGrid from '../app-grid';
import Toolbar from '../toolbar';
import Spaces from '../spaces/spaces';
import Files from '../files/files';
import ManageTeamMembers from '../members/manage-team-members';
import DevTools from '../devtools';
import HomePage from '../home-page';

import { setCurrentTeam } from '../../service/choose-current-team';
import { useApolloClient } from '@apollo/react-hooks';


const useStyles = makeStyles(theme => ({
	header: {
		width: '100%',
		height: theme.custom.header.height,
		minHeight: theme.custom.header.height,
		background: `linear-gradient(${theme.palette.background.paper}, ${theme.palette.background.default})`,
		padding: theme.custom.header.padding,
		display: 'flex',
		justifyContent: 'center',
	},

	headerContent: {
		...theme.custom.contentBase,
		display: 'grid',
		gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
		justifyContent: 'center',
		alignItems: 'center',
		gap: theme.glueSpacing('xl')
	},

	tabs: {
		display: 'flex',
		gap: theme.glueSpacing('xl'),
		alignItems: 'center',
		justifyContent: 'center'
	},

	contentArea: {
		...theme.custom.contentBase,
		height: '100%',
		margin: 'auto',
		display: 'flex',
		justifyContent: 'center',
		padding: `0 ${theme.glueSpacing('s')} 0`,
		...theme.custom.view.mainContent
	},

	toolbarWrapper: {
		display: 'flex',
		justifyContent: 'center',

		'& > div': {
			borderRadius: '32px'
		}
	}
}), { name: 'MuiGlueMainView' });

const MainView = (props) => {
	const classes = useStyles();
	const navigate = useNavigate();
	const location = useLocation();
	const apollo = useApolloClient();

	const tabInfosTablet = [
		{ path: '/view/main/apps', name: "Apps", audiomessage: "Menu/Apps/Press", hoveraudiomessage: "Menu/Apps/HL" },
		{ path: '/view/main/spaces', name: "Spaces", audiomessage: "Menu/TeamSpaces/Press", hoveraudiomessage: "Menu/TeamSpaces/HL" },
		{ path: '/view/main/files', name: "Files", audiomessage: "Menu/TeamFiles/Press", hoveraudiomessage: "Menu/TeamFiles/HL" },
		{ path: '/view/main/members', name: "Members", audiomessage: 'Menu/TeamMembers/Press', hoveraudiomessage: 'Menu/TeamMembers/HL' },
	];

	const tabInfosWeb = [
		{ path: '/main/team-spaces', name: "Spaces" },
		{ path: '/main/team-files', name: "Files" },
		{ path: '/main/team-members', name: "Members" },
	];

	if (window.sessionStorage.getItem("enableDevTools") && process.env.NODE_ENV == "development") {
		tabInfosTablet.push({ path: '/view/main/devtools', name: "DevTools", audiomessage: 'Menu/DevTools/Press', hoveraudiomessage: 'Menu/DevTools/HL' });
		tabInfosWeb.push({ path: '/main/devtools', name: "DevTools" });
	}

	const tabInfos = props.web ? tabInfosWeb : tabInfosTablet;

	const onClick = (tabInfo) => {
		navigate(tabInfo.path);
		if (props.onClickChild)
			props.onClickChild(tabInfo.name);
	}

	const routeTablet = (
		<Routes>
			<Route path={'/'} element={<HomePage />} />
			<Route path={'apps'} element={<>
				<div>
					<AppGrid />
					<div className={classes.toolbarWrapper}>
						<Toolbar />
					</div>
				</div>
			</>} />
			<Route path={'members'} element={<ManageTeamMembers />} />
			<Route path={'spaces'} element={<Spaces />} />
			<Route path={'files'} element={<Files />} />
			<Route path={'devtools'} element={<DevTools />}/>
		</Routes>
	);

	const routeWeb = (
		<Routes>
			<Route path={'/'} element={<HomePage web />} />
			<Route path={'team-members'} element={<ManageTeamMembers web/>} />
			<Route path={'team-spaces'} element={<Spaces web />} />
			<Route path={'team-files'} element={<Files web />} />
			<Route path={'devtools'} element={<DevTools web/>}/>
		</Routes>
	);

	return (
		<>
			<div className={classes.header}>
				<div className={classes.headerContent}>
					<TeamSelector 
						onChange={async (teamId) => await setCurrentTeam(apollo, teamId)}
					/>
					<div className={classes.tabs}>
						{tabInfos.map((tabInfo, index) => (
							<GlueTab
								key={index}
								label={tabInfo.name}
								toggled={matchPath(tabInfo.path, location.pathname)}
								onPointerDown={() => onClick(tabInfo)}
								uiAudioMessage = {tabInfo.audiomessage}
								uiHoverAudioMessage = {tabInfo.hoveraudiomessage}
								textTransform={'uppercase'}
								letterSpacing={'2px'}
							/>
						))}
					</div>
				</div>
			</div>
			<div className={classes.contentArea}>
				{props.web ? routeWeb : routeTablet}
			</div>
		</>
	);
}

export default MainView;
