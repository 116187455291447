import { gql } from '@apollo/client';

const queries =
{
	sysAdmins: gql`
		query SysAdmins {
			sysAdmins
		}
	`,

	myTeams: gql`
		query MyTeams ($userRole: String, $orgId: ID) {
			myTeams(userRole: $userRole, orgId: $orgId) {
				teamId,
				name,
				teamAccessType,
				defaultOrchestratorId,
				roomCount,
				members {
					email,
					memberRole
				},
				rooms {
					roomId,
					name
				}
			}
		}
	`,

	findTeamsByName: gql`
		query findTeamsByName($name: String!) {
			findTeamsByName(name: $name) {
				teamId
				name
			}
		}
	`,

	userTeams: gql`
		query UserTeams ($userEmail: String) {
			userTeams(userEmail: $userEmail) {
				teamId,
				name,
				selfRole
			}
		}
	`,

	emailAddressValidity: gql`
		query EmailAddressValidity ($email: String!, $source: String) {
			emailAddressValidity(email: $email, source: $source) {
				email,
				verdict,
				suggestion
			}
		}
	`,

	teamInfo: gql`
		query TeamInfo ($teamId: ID!) {
			teamInfo(teamId: $teamId) {
				teamId,
				name,
				orchestratorUrl,
				orchestratorPort,
				sessionPortRangeStart,
				sessionPortRangeEnd,
				orchestratorUsername,
				orchestratorPassword,
				defaultClusterId,
				defaultOrchestratorId,
				streamingServerUrl,
				teamTier,
				teamCreatedTimestamp,
				members {
					email,
					name,
					memberRole,
					invitationStatus,
					userCreatedTimestamp
				},
				rooms {
					roomId,
					name
				},
				clusters {
					clusterId,
					name,
					permission
				}
			}
		}
	`,


	myRooms: gql`
		query MyRooms ($teamId: ID, $first: Int, $offset: Int) {
			myRooms (teamId: $teamId, first: $first, offset: $offset) {
				roomId,
				sceneName,
				sceneUrl,
				assetName,
				assetId,
				trackVersionPointer,
				name,
				serverKey,
				thumbnailUrl,
				team {
					teamId,
					name
				},
				totalNumberOfRooms,
				session {
					startDate
					participants
					sessionState
				}
			}
		}
	`,

	roomInfo: gql`
		query RoomInfo ($roomId: ID!) {
			roomInfo(roomId: $roomId) {
				roomId,
				name,
				ip,
				port,
				sceneName,
				sceneUrl,
				serverKey,
				invitees,
				logicServerId,
				mqServerId,
				sessionServicesVersion,
				orchestratorUsername,
				orchestratorPassword,
				orchestratorId,
				orchestratorName,
				assetId,
				trackVersionPointer,
				assetName,
				sessionStatus,
				thumbnailUrl,
				streamingServerUrl
			}
		}
	`,

	spaceByServerKey: gql`
		query SpaceByServerKey($teamId: ID!, $serverKey: String!) {
			spaceByServerKey(teamId: $teamId, serverKey: $serverKey) {
				roomId,
				name
			}
		}
	`,

	cachedSpaceTemplateUrls: gql`
		query CachedSpaceTemplateUrls {
			cachedSpaceTemplateUrls @client
		}
	`,

	spaceTemplateDownloadState: gql`
		query SpaceTemplateDownloadState {
			spaceTemplateDownloadState @client {
				sceneUrl
				status
				progress
				error
			}
		}
	`,

	joinSpaceError: gql`
		query JoinSpaceError {
			joinSpaceError(serverKey: $serverKey) @client
		}
	`,

	sessionInfo: gql`
		query SessionInfo ($roomId: ID!) {
			sessionInfo(roomId: $roomId) {
				startDate,
				participants,
				sessionState,
			}
		}
	`,

	sessionObjectInfo: gql`
		query SessionObjectInfo($sessionId: String, $objectId: String) {
				sessionObjectInfo(sessionId: $sessionId, objectId: $objectId) {
					contentUrl
					contentType
					pageNumber
					pageNumberBase
			}
		}
	`,

	sessionStartStatus: gql`
		query SessionStartStatus($serverKey: ID!) {
			sessionStartStatus(serverKey: $serverKey) @client {
				data {
					sessionServicesPort
					sessionId
					sessionServicesUrl
					streamingServerUrl
					dailyMeetingToken
				}
				message
				error {
					errorCode
					errorDescription
					errorDetails
				}
			}
		}
	`,

	myAssets: gql`
		query MyAssets ($typeFilter: String, $getArchived: Boolean) {
			myAssets(typeFilter: $typeFilter, getArchived: $getArchived) {
				assetId,
				name,
				sceneName,
				thumbnailUrl,
				permission,
				allowedTeams,
				allowedOrgs,
				archived,
				description,
				versions {
					version
					baseUrl
					date
					userId
				}
				versionPointers {
					name
					version
				}
			}
		}
	`,

	myTeamAssets: gql`
		query MyTeamAssets ($teamId: ID!, $typeFilter: String, $getArchived: Boolean) {
			myTeamAssets(teamId: $teamId, typeFilter: $typeFilter, getArchived: $getArchived) {
				assetId,
				name,
				type,
				sceneName,
				thumbnailUrl,
				permission,
				allowedTeams,
				allowedOrgs,
				archived,
				description,
				versions {
					version
					baseUrl
					date
					userId
				}
				versionPointers {
					name
					version
				}
			}
		}
	`,

	assetsWithFullPathName: gql`
		query AssetsWithFullPathName ($typeFilter: String, $getArchived: Boolean, $getCustom: Boolean) {
			assetsWithFullPathName(typeFilter: $typeFilter, getArchived: $getArchived, getCustom: $getCustom) {
				assetId,
				name,
			}
		}
	`,

	hiddenAssetIds: gql`
		query HiddenAssetIds ($teamId: ID!) {
			hiddenAssetIds(teamId: $teamId)
		}
	`,

	assetServerComponents: gql`
		query AssetServerComponents ($assetId: ID!) {
			assetServerComponents(assetId: $assetId) {
				componentId,
				name,
				imageType,
				imageUrl,
				imageTag
			}
		}
	`,

	assetLinkedOrgsAndTeams: gql`
		query AssetLinkedOrgsAndTeams ($assetId: ID!) {
			assetLinkedOrgsAndTeams(assetId: $assetId) {
				orgId
				name
				linked
				linkedTeams {
					teamId
					name
				}
			}
		}
	`,

	assetInfo: gql`
		query AssetInfo ($assetId: ID!) {
			assetInfo(assetId: $assetId) {
				assetId,
				name,
				type,
				sceneName,
				thumbnailUrl,
				permission,
				allowedTeams,
				allowedOrgs,
				archived,
				description,
				versions {
					version
					baseUrl
					date
					userId
				}
				versionPointers {
					name
					version
					serverComponentsImageTagOverride
				}
			}
		}
	`,

	currentTeamId: gql`
		query CurrentTeamId {
			currentTeamId @client
		}
	`,

	currentOrgId: gql`
		query CurrentOrgId {
			currentOrgId @client
		}
	`,

	backendInfo: gql`
		query BackendInfo {
			backendUri @client
			backendToken @client
		}
	`,

	handUIActive: gql`
		query HandUIActive {
			handUIActive @client
		}
	`,

	microphoneEnabled: gql`
		query MicrophoneEnabled {
			microphoneEnabled @client
		}
	`,

	microphoneLocked: gql`
		query MicrophoneLocked {
			microphoneLocked @client
		}
	`,

	uiDisabled: gql`
		query UiDisabled {
			uiDisabled @client
		}
	`,

	muteAllEnabled: gql`
		query MuteAllEnabled {
			muteAllEnabled @client
		}
	`,

	uiDisabledState: gql`
		query UiDisabledState {
			uiDisabledState @client
		}
	`,

	isFacilitator: gql`
		query IsFacilitator {
			isFacilitator @client
		}
	`,

	announcerModeEnabled: gql`
		query AnnouncerModeEnabled {
			announcerModeEnabled @client
		}
	`,

	// Warning: This query result might change quite rapidly,
	// so please avoid hooking too much stuff to this.
	microphoneActivity: gql`
		query MicrophoneActivity {
			microphoneActivity @client
		}
	`,

	mouselookEnabled: gql`
		query MouselookEnabled {
			mouselookEnabled @client
		}
	`,

	microphoneSettings: gql`
		query MicrophoneSettings {
			microphoneSettings @client {
				microphoneList
				currentAudioDevice
			}
		}
	`,

	displaySettings: gql`
		query DisplaySettings {
			displaySettings @client {
				availableResolutions
				currentResolution
				availableRefreshRates
				currentRefreshRate
				fullScreen
			}
		}
	`,

	masterVolume: gql`
		query MasterVolume {
			masterVolume @client
		}
	`,

	environmentVolume: gql`
		query EnvironmentVolume {
			environmentVolume @client
		}
	`,

	toolVolume: gql`
		query ToolVolume {
			toolVolume @client
		}
	`,

	dspToggles: gql`
		query DspToggles {
			dspToggles @client {
				normalization
				noiseGate
				echoCancellation
			}
		}
	`,

	dspSettings: gql`
		query DspSettings {
			dspSettings @client {
				normalizationLevel
				noiseGateLevel
				echoCancellationLevel
				lowerThreshold
				upperThreshold
				holdTime
				attack
				release
				energyThreshold
				gainOneThreshold
				gainTwoThreshold
				muMax
				muAfterSilence
				thresholdBackground
				thresholdForeground
				thresholdBackgroundDetectionOff
				thresholdForegroundDetectionOff
				thresholdSilence
				resetSpeed
				adaptationTimeAfterSilence
			}
		}
	`,

	encodingSettings: gql`
		query EncodingSettings {
			encodingSettings @client {
				qualityLevel
				targetFramerate
				targetBitrate
				threadCount
				framebufferCount
				outputBufferSize
				qMin
				qMax
				slices
				profile
				keyIntMin
				fallbackQuality
				fallbackSendInterval
				fallbackReceiveInterval
				fallbackWaitThreshold
			}
		}
	`,

	clientVersion: gql`
		query ClientVersion {
			clientVersion @client
		}
	`,

	clientHash: gql`
		query ClientHash {
			clientHash @client
		}
	`,

	clientPlatform: gql`
		query ClientPlatform {
			clientPlatform @client {
				OS
				DeviceModel
				PlatformType
				Capabilities
			}
		}
	`,

	urlInputFocus: gql`
		query UrlInputFocus {
			urlInputFocus @client
		}
	`,

	scrollFocus: gql`
		query ScrollFocus {
			scrollFocus @client
		}
	`,

	batteryStatus: gql`
		query BatteryStatus {
			batteryStatus @client {
				present
				charging
				chargeLevel
			}
		}
	`,

	currentSpaceServerKey: gql`
		query CurrentSpaceServerKey {
			currentSpaceServerKey @client
		}
	`,

	pointingToolStatus: gql`
		query PointingToolStatus {
			pointingToolStatus @client {
				active
				attached
			}
		}
	`,

	draw3DToolStatus: gql`
		query Draw3DToolStatus {
			draw3DToolStatus @client {
				active
				attached
				color
			}
		}
	`,

	penResetRequest: gql`
		query PenResetRequest {
			penResetRequest @client
		}
	`,

	tabletOpen: gql`
		query TabeltOpen {
			tabletOpen @client
		}
	`,

	clockStopwatch: gql`
		query ClockStopwatch {
			clockStopwatch @client {
				elapsed
				startedAt
				running
			}
		}
	`,

	clockTimer: gql`
		query ClockTimer {
			clockTimer @client {
				timeLeft
				startedAt
				running
				alarm
			}
		}
	`,

	locations: gql`
		query Locations {
			locations @client {
				names
			}
		}
	`,

	cameraStatus: gql`
		query CameraStatus {
			cameraStatus @client {
				resolution
				fov
				selfieMode
				isUHD
			}
		}
	`,

	// Note that some more info might be available than what is currently listed in this query.
	// Check the backend if you feel like something you need is missing and then add it here!
	inventoryItemInfo: gql`
		query InventoryItemInfo ($inventoryItemId: ID!) {
			inventoryItemInfo(inventoryItemId: $inventoryItemId) {
				inventoryItemId,
				name,
				originalName,
				itemUrl,
				itemType,
				teamId,
				sceneId,
				creator,
				createdDate,
				fileSize,
				thumbnailUrl,
				permission,
				updatedDate,
				imageWidth,
				imageHeight
			}
		}
	`,

	// Note that some more info might be available than what is currently listed in this query.
	// Check the backend if you feel like something you need is missing and then add it here!
	teamInventoryItems: gql`
		query TeamInventoryItems ($teamId: String, $first: Int, $offset: Int, $typeFilter: String) {
			teamInventoryItems(teamId: $teamId, first: $first, offset: $offset, typeFilter: $typeFilter) {
				inventoryItemId,
				name,
				originalName,
				itemUrl,
				itemType,
				teamId,
				sceneId,
				creator,
				createdDate,
				fileSize,
				thumbnailUrl,
				permission,
				creatorName,
				updatedDate,
				imageWidth,
				imageHeight,
				totalNumberOfItems
			}
		}
	`,

	signInventoryItemGet: gql`
		query SignInventoryItemGet($inventoryItemId: ID!) {
			signInventoryItemGet(inventoryItemId: $inventoryItemId) {
				inventoryItemId,
				itemURL,
				thumbnailURL
			}
		}
	`,

	mySettings: gql`
		query MySettings {
			mySettings
		}
	`,

	myEmail: gql`
		query MyEmail {
			myEmail
		}
	`,
	
	globalSettings: gql`
		query GlobalSettings {
			globalSettings {
				minimumSupportedClientVersion,
				backendVersion,
				winClientDownloadLink,
				macosClientDownloadLink,
				androidClientDownloadLink,
				latestClientVersions {
					identifier,
					version,
					versionHash
				}
			}
		}
	`,
	
	hourTime: gql`
		query HourTime {
			hourTime @client
		}
	`,
	
	speechRecognition: gql`
		query SpeechRecognition($instanceId: ID!) {
			speechRecognition(instanceId: $instanceId) @client {
				previewText
				text
				loading
				running
				listening
				sinkId
				dialogOpen
				failed
			}
		}
	`,

	speechRecognitionToken: gql`
		query SpeechRecognitionToken {
			speechRecognitionToken {
				token
				region
			}
		}
	`,

	fileImport: gql`
		query FileImport {
			fileImport @client {
				loading
			}
		}
	`,

	fileUpload: gql`
		query FileUpload {
			fileUpload @client {
				uploadStatus
			}
		}
	`,

	uptime: gql`
		query Uptime {
			uptime @client
		}
	`,
	
	crashReport: gql`
		query CrashReport {
				crashReport @client
		}
	`,

	teamFilePreview: gql`
		query TeamFilePreview {
			teamFilePreview @client
		}
	`,

	exitDialogOpen: gql`
		query exitDialogOpen {
			exitDialogOpen @client
		}
	`,
	
	viewDownloadedFile: gql`
		query viewDownloadedFile {
			viewDownloadedFile @client
		}
	`,

	takeGroupValue: gql`
		query TakeGroupValue {
			takeGroupValue @client
		}
	`,

	noteEdited: gql`
		query NoteEdited {
			noteEdited @client
		}
	`,

	context: gql`
		query Context {
			context @client {
				header
				contextList
			}
		}
	`,

	objectTransform: gql`
		query ObjectTransform {
			transform @client {
				position {
					x
					y
					z
				}
				rotation {
					x
					y
					z
				}
				scale {
					x
					y
					z
				}
			}
		}
	`,

	objectTransformSupport: gql`
		query ObjectTransformSupport {
			objectTransformSupport @client {
				move
				rotate
				scale
			}
		}
	`,

	objectTransformLock: gql`
		query ObjectTransformLock {
			objectTransformLock @client
		}
	`,

	objectWorldSpace: gql`
		query ObjectWorldSpace {
			objectWorldSpace @client
		}
	`,

	sessionTimeRemaining: gql`
		query SessionTimeRemaining {
			sessionTimeRemaining @client
		}
	`,

	ui: gql`
		query UI {
			ui @client
		}
	`,
	
	toolbarMode: gql`
		query ToolbarMode {
			toolbarMode @client
		}
	`,

	promptDialogOpen: gql`
		query PromptDialogOpen {
			promptDialogOpen @client
		}
	`,

	keyboardActive: gql`
		query KeyboardActive($instanceId: ID!) {
			keyboardActive(instanceId: $instanceId) @client
		}
	`,

	captureScreenshotActive: gql`
		query CaptureScreenshotActive($instanceId: ID!) {
			captureScreenshotActive(instanceId: $instanceId) @client
		}
	`,

	externalBrowserInfo: gql`
		query ExternalBrowserInfo {
			externalBrowserInfo @client {
				instanceId
				sharing
			}
		}
	`,

	extBrowserState: gql`
		query ExtBrowserState($instanceId: ID!) {
			extBrowserState(instanceId: $instanceId) @client {
				url
				title
				sharing
			}
		}
	`,

	extBrowserNavigation: gql`
		query ExtBrowserNavigation($instanceId: ID!) {
			extBrowserNavigation(instanceId: $instanceId) @client {
				canGoBack
				canGoForward
			}
		}
	`,

	extBrowserLatestDownloadedFile: gql`
		query ExtBrowserLatestDownloadedFile($instanceId: ID!) {
			extBrowserLatestDownloadedFile(instanceId: $instanceId) @client
		}
	`,

	extBrowserDownloadState: gql`
		query ExtBrowserDownloadState($instanceId: ID!, $fileUrl: String!) {
			extBrowserDownloadState(instanceId: $instanceId, fileUrl: $fileUrl) @client {
				fileInfo {
					fileName
					fileType
					fileSizeBytes
				}
				status
				progress
			}
		}
	`,

	extBrowserFileImportState: gql`
		query ExtBrowserFileImportState($instanceId: ID!, $fileUrl: String!) {
			extBrowserFileImportState(instanceId: $instanceId, fileUrl: $fileUrl) @client {
				status
				spaceImportProgress
				filesImportProgress
				error
			}
		}
	`,

	extBrowserFileUploadState: gql`
		query ExtBrowserFileUploadState($instanceId: ID!) {
			extBrowserFileUploadState(instanceId: $instanceId) @client {
				status
				multipleAllowed
				acceptFilters
				error
			}
		}
	`,

	browserLatestUrl: gql`
		query BrowserLatestUrl($instanceId: ID!, $appId: ID!) {
			browserLatestUrl(instanceId: $instanceId, appId: $appId) @client
		}
	`,

	extVideoState: gql`
		query ExtVideoState($instanceId: ID!) {
			extVideoState(instanceId: $instanceId) @client {
				time
				frame
				playbackRate
				volume
				muted
				playing
				paused
				finished
				loop
				seeking
				buffering
				bufferedTimes
				sharing
				inventoryItemId
			}
		}
	`,

	extVideoMetadata: gql`
		query ExtVideoMetadata($instanceId: ID!) {
			extVideoMetadata(instanceId: $instanceId) @client {
				duration
				durationFrames
				frameRate
				hasAudio
			}
		}
	`,

	presentationViewControls: gql`
		query PresentationViewControls {
			presentationViewControls @client
		}
	`,

	documentState: gql`
		query DocumentState($instanceId: ID!) {
			documentState(instanceId: $instanceId) @client {
				sharing
			}
		}
	`,

	documentImportActive: gql`
		query DocumentImportActive($instanceId: ID!) {
			documentImportActive(instanceId: $instanceId) @client
		}
	`,

	processingStatusInfo: gql`
		query ProcessingStatusInfo($statusItemId: ID!) {
			processingStatusInfo(statusItemId: $statusItemId) {
				statusItemId,
				statuscode,
				progress,
				result
			}
		}
	`,

	glueOSIsSharing: gql`
		query GlueOSIsSharing {
			glueOSIsSharing @client
		}
	`,
	
	getAllOrgIds: gql`
		query GetAllOrgIds {
			getAllOrgIds
		}
	`,

	getOrg: gql`
		query GetOrg($orgId: ID!) {
			getOrg(orgId: $orgId) {
				orgId,
				name,
				members {
					email
					memberRole,
					invitationStatus,
					createdAt,
					lastLogin,
					picture
				},
				emailDomains,
				teams
				isTutorial
			}
		}
	`,

	getAllOrganisazions: gql`
		query GetAllOrganisazions {
			getAllOrganisazions {
				name,
				orgId
			}
		}
	`,

	findOrganizationsByName: gql`
		query FindOrganizationsByName($name: String!) {
			findOrganizationsByName(name: $name) {
				orgId
				name
				teams
			}
		}
	`,

	getOrgIdOfTeam: gql`
		query GetOrgIdOfTeam($teamId: ID!) {
			getOrgIdOfTeam(teamId: $teamId)
		}
	`,

	getOrgTeams: gql`
		query GetOrgTeams($orgId: ID!) {
			getOrgTeams(orgId: $orgId) {
				name,
				teamId,
				teamCreatedTimestamp,
				roomCount,
				teamAccessType,
				members {
					email,
					memberRole
				},
				rooms {
					roomId,
					name
				}
			}
		}
	`,

	getUserOrganizations: gql`
		query GetUserOrganizations($email: String) {
			getUserOrganizations(email: $email) {
				orgId,
				name,
				userRole,
				teams
				isTutorial
			}
		}
	`,

	subscriptionOfOrg: gql`
		query SubscriptionOfOrg($orgId: ID!) {
			subscriptionOfOrg(orgId: $orgId) {
				orgId,
				licenseTier,
				numberOfSeats,
				isActive
			}
		}
	`,

	analyticsUserID: gql`
	query analyticsUserID {
		analyticsUserID @client
	}
	`,

	myClusters: gql`
		query MyClusters($userRole: String!, $orgId: ID ) {
			myClusters(userRole: $userRole, orgId: $orgId ) {
				clusterId,
				name,
				permission,
				allowedTeams,
				attachedOrchestrators
			}
		}
	`,

	clusterInfo: gql`
		query ClusterInfo ($clusterId: ID!) {
			clusterInfo(clusterId: $clusterId) {
			clusterId,
			name,
			permission,
			allowedTeams,
			attachedOrchestrators
			}
		}
	`,

	myAccessInfo: gql`
		query MyAccessInfo($teamId: ID, $orgId: ID) {
			myAccessInfo(teamId: $teamId, orgId: $orgId) {
				administrator,
				team {
					teamInfo
					spaceRead
					spaceEdit
					assetCreate
					assetVisibilitySet
					teamFilesReadOwned
					teamFilesReadPublic
					teamFilesReadPrivate
					teamFilesEditOwned
					teamFilesEditOthers
					membersEdit
					isFacilitator
				}
				org {
					membersRead
					membersEdit
					teamsRead
					teamsEdit
					teamsJoin
					teamCreate
					assetVisibilitySet
					clustersRead
					orgInfoRead
					orgInfoReadLimited
					orgEdit
					subscriptionRead
				}
			}
		}
	`,

	assetAccess: gql`
		query AssetAccess($assetId: ID!) {
			assetAccess(assetId: $assetId) {
				read
				edit
				permissionSet
			}
		}
	`,

	teamAssetsAccess: gql`
		query TeamAssetsAccess($teamId: ID!, $typeFilter: String) {
			teamAssetsAccess(teamId: $teamId, typeFilter: $typeFilter) {
				assetId
				access {
					read
					edit
				}
			}
		}
	`,

	myOrchestrators: gql`
		query MyOrchestrators {
			myOrchestrators {
				orchestratorId,
				name,
				url,
				managementPort,
				startPort,
				endPort,
				username,
				password,
				permission,
				allowedTeams,
				attachedClusters {
					clusterId,
					name
				},
				type,
				mediaServerStreamCount,
				sessionCount
				}
		}
	`,

	orchestratorInfo: gql`
		query OrchestratorInfo ($orchestratorId: ID!) {
			orchestratorInfo(orchestratorId: $orchestratorId) {
			orchestratorId,
			name,
			url,
			managementPort,
			startPort,
			endPort,
			username,
			password,
			permission,
			allowedTeams,
			attachedClusters {
				clusterId,
				name
			},
			type
			}
		}
	`,

	adminTeamId: gql`
		query AdminTeamId {
			adminTeamId
		}
	`,
	
	getTutorialOrganization: gql`
		query GetTutorialOrganization {
			getTutorialOrganization {
				orgId,
				teamId,
				roomId
			}
		}
	`,

	canRemoveUserFromTeam: gql`
		query CanRemoveUserFromTeam($userId: String!, $teamId: ID!) {
			canRemoveUserFromTeam(userId: $userId, teamId: $teamId)
		}
	`,
};

export default queries;
