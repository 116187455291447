import React from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';

import queries from '../../graphql/queries';
import mutations from '../../graphql/mutations';
import GlueDialog from '../common/glue-dialog';
import MemberDetailsDialogContent from './member-details-dialog-content';
import { usePromptDialogContext } from '../../util/prompt-dialog-context';
import { orgMemberRoleNameMap } from '../../defines';
import InfoDialogTemplate from '../common/info-dialog-template';
import { useUserContext } from '../../util/user-context';

const MemberDetailsDialog = (props) =>
{
	const { addDialog } = usePromptDialogContext();

	const user = useUserContext();
	const orgInfoRes = useQuery(queries.getOrg,
		{
			skip: !props.orgId,
			variables: { orgId: props.orgId }
		}
	);

	const [ resendInvEmail ] = useMutation(mutations.resendInvitationEmail);
	const [ updateMember ] = useMutation(mutations.updateOrgMembers);

	const targetMemberInfo = orgInfoRes.data?.getOrg.members.find(
		(member) => member.email === props.userId
	) ?? null;

	if (!targetMemberInfo)
		return null;

	const resendInvite = async (member) =>
	{
		await resendInvEmail({
			variables: {
				orgId: props.orgId,
				memberEmail: member.email
			}
		}).then(res => {
			addDialog(
				<InfoDialogTemplate 
					header={'Success'}
					message={`A new invitation has been sent to ${member.email}`}
				/>
			)
			props.onClose();
		}).catch(err => {
			console.log(err)
			addDialog(
				<InfoDialogTemplate
					isError={true}
					message={`The new invitation could not be sent to ${member.email}`}
				/>
			)
		});
	}

	const removeMember = async (removeMemberEmail) =>
	{
		if (removeMemberEmail === user.email)
			return;

		if (props.onRemoveMember) {
			props.onRemoveMember()
		}
	};

	const changeRole = async (member, newRole) =>
	{
		await updateMember({
			variables: {
				orgId: props.orgId,
				memberEmails: [member.email],
				memberRole: newRole
			}
		})
		.then((res) => {
			addDialog(<InfoDialogTemplate
				header={"Success!"}
				message={`User role changed to ${orgMemberRoleNameMap[newRole]} for `}
				variable={member.email}
			/>);
		})
		.catch((err) => {
			addDialog(<InfoDialogTemplate 
				isError={true}
				message={`An error occurred when trying to change role for `}
				variable={member.email}
			/>);
		})
		.finally(() => {
			orgInfoRes.refetch();
		});
	};

	return (
		<GlueDialog
			fullWidth={false}
			maxWidth={'lg'}
			open={Boolean(props.userId)}
			onClose={props.onClose}
		>
			<MemberDetailsDialogContent
				closeDialog={props.onClose}
				data={targetMemberInfo}
				self={targetMemberInfo.email === user.email}
				onRemoveMember={removeMember}
				onChangeRole={changeRole}
				onResendInvite={resendInvite}
			/>
		</GlueDialog>
	);
};

export default MemberDetailsDialog;