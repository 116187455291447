import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import queries from '../../graphql/queries';

import { GetApp } from '@material-ui/icons';
import PublishIcon from '@material-ui/icons/Publish';
import { findSpecializedViewer} from './file-view-switcher';

const FileDownloadMenuItem = (props) =>
{
	const platformResult = useQuery(queries.clientPlatform);
	const clientPlatform = platformResult.data?.clientPlatform;
	const signInventoryItemGetRes = useQuery(
		queries.signInventoryItemGet,
		{
			variables: { inventoryItemId: props.item.inventoryItemId },
			fetchPolicy: 'cache-and-network'
		}
	);

	const signedURLs = signInventoryItemGetRes.data?.signInventoryItemGet;

	const importFileToSpace = () =>
	{
		const viewer = findSpecializedViewer(props.item);
		if (viewer && viewer.importFile)
		{
			viewer.importFile(props.item.teamId, { signedURLs: signedURLs, platform: clientPlatform});
		}
	};

	if (props.web)
	{
		return (
			<>
				<GetApp/>,
				<a role='button' tabIndex={0} style={{ display: 'none' }} ref={props.downloadRef} href={signedURLs?.itemURL ?? '#'} target='_blank' rel='noopener noreferrer' download ><div/></a>
			</>
		)
	}

	return (
		<>
			<PublishIcon/>,
			<div ref={props.downloadRef} style={{ display: 'none' }} onClick={importFileToSpace}/>
		</>
	);
}

export default FileDownloadMenuItem;