import { CSSProperties } from "@material-ui/core/styles/withStyles";

export type Type = CSSProperties;

const common: Type = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	width: '64px',
	height: '64px',
	minWidth: '64px',
	minHeight: '64px',

	'&>.MuiSvgIcon-root': {
		fontSize: '36px',
		margin: 'auto'
	},

	'&> img': {
		width: '36px',
		height: '36px',
		margin: 'auto'
	}
};

export const web: Type = {
	...common,
	
	width: '36px',
	height: '36px',
	minWidth: '36px',
	minHeight: '36px',

	'&>.MuiSvgIcon-root': {
		fontSize: '18px',
		margin: 'auto'
	},

	'&> img': {
		width: '18px',
		height: '18px',
		margin: 'auto'
	}
};

export const tablet: Type = {
    ...common
};