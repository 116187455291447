import React, { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';

import queries from '../graphql/queries';
import postVuplexMessage from '../service/message-vuplex';
import HeroIcon from './common/hero-icon';
import MicActivityBar from './mic-activity-bar';

import MicOnIcon from '../icons/hero/Mic_ON.png';
import MicOffIcon from '../icons/hero/Mic_OFF.png';
import MicDisabledIcon from '../icons/Mic_FacLimit.png';

const toggleMic = () =>
{
	postVuplexMessage('Toggle microphone', null);
};

const MicHeroIcon = (props) =>
{
	const { data } = useQuery(queries.microphoneEnabled);
	const microphoneEnabled = data?.microphoneEnabled;
	const microphoneLockedResult = useQuery(queries.microphoneLocked);
	const microphoneLocked = microphoneLockedResult.data?.microphoneLocked;

	const toggleOffSound = "Tools/Microphone/Toggle/Off/Press";
	const toggleOnSound = "Tools/Microphone/Toggle/On/Press";
	const audiomessage = microphoneEnabled ? toggleOffSound :  toggleOnSound; 
	const hoveraudiomessage = "Tools/Microphone/HL";
	const micLabel = microphoneLocked ? "Mic" : microphoneEnabled ? "Mic ON" : "Mic OFF";

	if (!data || !microphoneLockedResult)
		return null;

	useEffect(() => {
		postVuplexMessage("Microphone.SetUpdatesActive", { value: true });
		return () => {
			postVuplexMessage("Microphone.SetUpdatesActive", { value: false });
		}
	});

	return (
		<HeroIcon
			name={micLabel}
			disabled={microphoneLocked}
			noBackground={props.noBackground}
			icon={microphoneLocked ? MicDisabledIcon : MicOnIcon}
			iconOff={microphoneLocked ? MicDisabledIcon : MicOffIcon}
			onPointerDown={toggleMic}
			toggled={microphoneEnabled}
			uiAudioMessage={audiomessage}
			uiHoverAudioMessage={hoveraudiomessage}
			bottom={<MicActivityBar bgColor={'black'} widthSize={'80px'}/>}
			hotkey="2"
			facLocked={microphoneLocked}
		/>
	);
};

export default MicHeroIcon;
