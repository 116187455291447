import { CSSProperties } from "@material-ui/core/styles/withStyles";

export type Type = {
	sliderColorModeDropdownWidth: string
	cornerRounding: string
	fieldWidth: string
	colorIndicatorWidth: string
	saveColorButtonWidth: string
	searchField?: CSSProperties
	inputField?: CSSProperties
}

const common: Type =
{
	sliderColorModeDropdownWidth: '144px',
	cornerRounding: '44px',
	fieldWidth: '144px',
	colorIndicatorWidth: '210px',
	saveColorButtonWidth: '220px',
};

export const web: Type =
{
	...common,
	sliderColorModeDropdownWidth: '96px',
	cornerRounding: '26px',
	fieldWidth: '96px',
	colorIndicatorWidth: '120px',
	saveColorButtonWidth: '134px',

	searchField: {
		width: '96px',
		height: '36px',
		borderRadius: '8px',
		borderWidth: '2px'
	},

	inputField: {
		fontSize: '16px',
		fontWeight: 400,
		letterSpacing: '0',
		lineHeight: '20px',
	}
};

export const tablet: Type =
{
	...common
};
