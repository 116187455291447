const ExternalApps = [
    {
        "AppName":"Microsoft 365",
        "AppUrl":"https://office.com",
        "Selected":false,
        "IconUrl":"https://glue-public-resources.s3-eu-west-1.amazonaws.com/externalappicons/Microsoft365.png"
    },
    {
        "AppName":"Gmail",
        "AppUrl":"https://mail.google.com",
        "Selected":false,
        "IconUrl":"https://glue-public-resources.s3.eu-west-1.amazonaws.com/externalappicons/Gmail.png"
    },
    {
        "AppName":"Google Calendar",
        "AppUrl":"https://calendar.google.com",
        "Selected":false,
        "IconUrl":"https://glue-public-resources.s3-eu-west-1.amazonaws.com/externalappicons/GoogleCalendar.png"
    },
    {
        "AppName":"Google Docs",
        "AppUrl":"https://docs.google.com/document",
        "Selected":false,
        "IconUrl":"https://glue-public-resources.s3-eu-west-1.amazonaws.com/externalappicons/GoogleDocs.png"
    },
    {
        "AppName":"Google Drive",
        "AppUrl":"https://drive.google.com",
        "Selected":false,
        "IconUrl":"https://glue-public-resources.s3-eu-west-1.amazonaws.com/externalappicons/GoogleDrive.png"
    },
    {
        "AppName":"Google Images",
        "AppUrl":"https://images.google.com/",
        "Selected":false,
        "IconUrl":"https://glue-public-resources.s3-eu-west-1.amazonaws.com/externalappicons/GoogleImages.png"
    },
    {
        "AppName":"Google Photos",
        "AppUrl":"https://photos.google.com/",
        "Selected":false,
        "IconUrl":"https://glue-public-resources.s3-eu-west-1.amazonaws.com/externalappicons/GooglePhotos.png"
    },
    {
        "AppName":"Google Sheets",
        "AppUrl":"https://docs.google.com/spreadsheets",
        "Selected":false,
        "IconUrl":"https://glue-public-resources.s3.eu-west-1.amazonaws.com/externalappicons/GoogleSheets.png"
    },
    {
        "AppName":"Google Slides",
        "AppUrl":"https://docs.google.com/presentation",
        "Selected":false,
        "IconUrl":"https://glue-public-resources.s3-eu-west-1.amazonaws.com/externalappicons/GoogleSlides.png"
    },
    {
        "AppName":"Adobe Creative Cloud",
        "AppUrl":"https://assets.adobe.com/cloud-documents",
        "Selected":false,
        "IconUrl":"https://glue-public-resources.s3-eu-west-1.amazonaws.com/externalappicons/AdobeCreativeCloud.png"
    },
    // {
    //     "AppName":"Dropbox",
    //     "AppUrl":"https://www.dropbox.com/",
    //     "Selected":false,
    //     "IconUrl":"https://glue-public-resources.s3-eu-west-1.amazonaws.com/externalappicons/Dropbox.png"
    // },
    {
        "AppName":"Gitlab",
        "AppUrl":"https://gitlab.com/",
        "Selected":false,
        "IconUrl":"https://glue-public-resources.s3-eu-west-1.amazonaws.com/externalappicons/Gitlab.png"
    },
    {
        "AppName":"Hubspot",
        "AppUrl":"https://www.hubspot.com/",
        "Selected":false,
        "IconUrl":"https://glue-public-resources.s3-eu-west-1.amazonaws.com/externalappicons/Hubspot.png"
    },
    {
        "AppName":"iCloud",
        "AppUrl":"https://www.icloud.com/",
        "Selected":false,
        "IconUrl":"https://glue-public-resources.s3-eu-west-1.amazonaws.com/externalappicons/iCloud.png"
    },
    {
        "AppName":"Jira",
        "AppUrl":"https://universallyspeaking.atlassian.net/jira/",
        "Selected":false,
        "IconUrl":"https://glue-public-resources.s3-eu-west-1.amazonaws.com/externalappicons/Jira.png"
    },
    {
        "AppName":"Miro",
        "AppUrl":"https://miro.com/",
        "Selected":false,
        "IconUrl":"https://glue-public-resources.s3-eu-west-1.amazonaws.com/externalappicons/Miro.png"
    },
    {
        "AppName":"Mural",
        "AppUrl":"https://app.mural.co/",
        "Selected":false,
        "IconUrl":"https://glue-public-resources.s3-eu-west-1.amazonaws.com/externalappicons/Mural.png"
    },
    {
        "AppName":"Salesforce",
        "AppUrl":"https://glue.my.salesforce.com/",
        "Selected":false,
        "IconUrl":"https://glue-public-resources.s3-eu-west-1.amazonaws.com/externalappicons/Salesforce.png"
    },
    {
        "AppName":"Slack",
        "AppUrl":"https://fkae.slack.com/",
        "Selected":false,
        "IconUrl":"https://glue-public-resources.s3-eu-west-1.amazonaws.com/externalappicons/Slack.png"
    },
    {
        "AppName":"Trello",
        "AppUrl":"https://trello.com/",
        "Selected":false,
        "IconUrl":"https://glue-public-resources.s3-eu-west-1.amazonaws.com/externalappicons/Trello.png"
    },
];

export default ExternalApps;