import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import PageSelector from '../common/page-selector';
import AddAppTile from './addapp-tile';
import ExternalApps from '../externalapps/external-apps';
import { useQuery } from '@apollo/react-hooks';
import queries from '../../graphql/queries';

// variables to skip Slack for other standalone headsets except Quest2
const blackListedApp = 'Slack';
const quest2tag = 'Oculus_Quest_2'
const metaProTag = 'Meta_Quest_Pro'
const pico3tag = 'Pico Pico Neo 3'
const pico4tag = 'Pico A8150'

const tilesPerPageCount = 8;

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%'
	},

	toolbar: {
		display: 'grid',
		justifyContent: 'right',
		padding: '12px 48px',
	},

	body: {
		display: 'grid',
		gridTemplateColumns: '296px 296px 296px 296px',
		gap: '24px 28px',
		width: '100%',
		justifyContent: 'center',
		alignContent: 'center'
	},

	pageSelector: {
		justifyContent: 'right'
	},
}), { name: 'MuiGlueAppsSettings' });

const AddApp = (props) => {
	const classes = useStyles();
	const [page, setPage] = useState(0);
	
	const startIndex = page * tilesPerPageCount;
	const endIndex = startIndex + tilesPerPageCount;

	const clientPlatformResult = useQuery(queries.clientPlatform);
	const clientPlatform = clientPlatformResult.data?.clientPlatform;
	const deviceModel = clientPlatform ? clientPlatform.DeviceModel : "None";
	const clientOS = clientPlatform ? clientPlatform.OS : "None";

	let slackInd = 0;

	if (clientOS === "Android" && deviceModel !== quest2tag && deviceModel !== metaProTag && deviceModel !== pico3tag && deviceModel != pico4tag)
	{
		slackInd = ExternalApps.findIndex(app => app.AppName === blackListedApp)
		if(slackInd > -1)
		{
			ExternalApps.splice(slackInd,1);
		}
	}

	let pageCount = 0;
	const tilesTotalCount = ExternalApps.length;
	pageCount = Math.ceil(tilesTotalCount / tilesPerPageCount)

	const AppsSliced = ExternalApps.slice(startIndex, endIndex);

	return (
		<div className={classes.root}>
			<div className={classes.toolbar}>
				<div className={classes.pageSelector}>
					<PageSelector
						current={page}
						pageCount={pageCount}
						onPageChange={setPage}>
					</PageSelector>
				</div>
			</div>

			<div className={classes.body}>
				{AppsSliced.map((app, i) => (
					<AddAppTile
						key={i}
						appiconurl={app.IconUrl}
						appname={app.AppName}
					/>
				))}
			</div>
		</div>
	)
};

export default AddApp;
