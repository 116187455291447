/* eslint-disable no-unused-vars */
import React from "react";
import queries from '../graphql/queries';
import { updateEncodingSettings } from '../component/common/common-vuplex-messages';
import { getUserSettings } from '../service/user-settings';
import { ApolloClient } from '@apollo/client';
import { ClientPlatformInfo } from '../graphql/types-generated';
import { TDialogContext } from './prompt-dialog-context';
import InfoDialogTemplate from '../component/common/info-dialog-template';

export type EncodingValues = {
	encodingPreset: number[],
	encodingLevels: number[][]
}

export type EncodingSettings = {
	qualityLevel: number,
	targetFramerate: number,
	targetBitrate: number,
	threadCount: number,
	framebufferCount: number,
	outputBufferSize: number,
	qMin: number,
	qMax: number,
	slices: number,
	profile: number,
	keyIntMin: number,
	fallbackQuality: number,
	fallbackSendInterval: number,
	fallbackReceiveInterval: number,
	fallbackWaitThreshold: number
}

export enum Device {
	Desktop = 0,
	Standalone = 1
}

export enum Preset {
	DesktopBaseline = 0,
	AndroidBaseline = 1,
	Developer = 2
}

export const presetNames: Record<Preset, string> = {
	[Preset.DesktopBaseline]: 'DesktopBaseline',
	[Preset.AndroidBaseline]: 'AndroidBaseline',
	[Preset.Developer]: 'Developer',
}

export const encodingSettings = [
	[0, 30, 2500000, 1, 2, 1000000, 0, 60, 1, 0, 5, 50, 2, 1, 5],
	[0, 10, 200000, 1, 2, 131072, 0, 0, 60, 0, 5, 50, 2, 1, 5],
	[0, 60, 5000000, 1, 2, 2500000, 0, 63, 1, 0, 5, 50, 2, 1, 5]
];
export const encodingPresets = [
	0,
	1,
	2
];

let apollo: ApolloClient<unknown> | undefined = undefined;
export let currentDevice: Device = Device.Desktop;
export let encodingValues: EncodingValues = {
	"encodingPreset": [0, 0],
	"encodingLevels": [encodingSettings[Preset.DesktopBaseline], encodingSettings[Preset.DesktopBaseline]]
};
export let userEncodingLevels = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
export let presetValue: Preset = Preset.Developer;
export const showDeveloperControls = true;
export let showCustomControls = false;

export const initSharing = async (apollo: ApolloClient<unknown>) => {
	const platformRes = apollo.readQuery<{ clientPlatform: ClientPlatformInfo }>({ query: queries.clientPlatform });
	console.log(platformRes?.clientPlatform);
	const saHS = platformRes?.clientPlatform ? (platformRes?.clientPlatform.PlatformType === "VR" && !platformRes?.clientPlatform?.Capabilities?.includes("KBAM")) : false;
	currentDevice = saHS ? Device.Standalone : Device.Desktop;

	const userSettings = await getUserSettings(apollo);
	encodingValues = userSettings.encodingValues;

	userEncodingLevels = encodingValues.encodingLevels[currentDevice];
	handlePresetChange(encodingValues.encodingPreset[currentDevice] as Preset);
}

export const setupSharing = async (apolloparameter: ApolloClient<unknown>) => {
	apollo = apolloparameter;
	await initSharing(apollo);
	updateEncodingSettings(apollo);
};

export const updateEncodingLevels = (preset: Preset) => {
	// Normalization
	const newEncodingLevel = (preset === Preset.DesktopBaseline || preset === Preset.Developer) ? userEncodingLevels[0] : encodingPresets[preset];
	const newQualityLevel = encodingSettings[newEncodingLevel][0];
	const newTargetFramerate = encodingSettings[newEncodingLevel][1];
	const newTargetBitrate = encodingSettings[newEncodingLevel][2];
	const newThreadCount = encodingSettings[newEncodingLevel][3];
	const newFramebufferCount = encodingSettings[newEncodingLevel][4];
	const newOutputBufferSize = encodingSettings[newEncodingLevel][5];
	const newqQmin = encodingSettings[newEncodingLevel][6];
	const newQmax = encodingSettings[newEncodingLevel][7];
	const newSlices = encodingSettings[newEncodingLevel][8];
	const newProfile = encodingSettings[newEncodingLevel][9];
	const newKeyIntMin = encodingSettings[newEncodingLevel][10];
	const newFallbackQuality = encodingSettings[newEncodingLevel][11];
	const newFallbackSendInterval = encodingSettings[newEncodingLevel][12];
	const newFallbackReceiveInterval = encodingSettings[newEncodingLevel][13];
	const newFallbackWaitThreshold = encodingSettings[newEncodingLevel][14];

	apollo?.writeQuery({
		query: queries.encodingSettings,
		data: {
			encodingSettings: {
				__typename: 'EncodingSettings',
				encodingLevel: (preset === Preset.DesktopBaseline || preset === Preset.Developer) ? userEncodingLevels[0] : encodingPresets[preset],
				qualityLevel: newQualityLevel,
				targetFramerate: newTargetFramerate,
				targetBitrate: newTargetBitrate,
				threadCount: newThreadCount,
				framebufferCount: newFramebufferCount,
				outputBufferSize: newOutputBufferSize,
				qMin: newqQmin,
				qMax: newQmax,
				slices: newSlices,
				profile: newProfile,
				keyIntMin: newKeyIntMin,
				fallbackQuality: newFallbackQuality,
				fallbackSendInterval: newFallbackSendInterval,
				fallbackReceiveInterval: newFallbackReceiveInterval,
				fallbackWaitThreshold: newFallbackWaitThreshold
			}
		}
	});

	updateEncodingSettings(apollo);
};

export const handleSharingChange = (slider: keyof EncodingSettings, newValue: EncodingSettings[keyof EncodingSettings]) => {
	const encodingSettingsRes = apollo?.readQuery({ query: queries.encodingSettings });
	const newEncodingSettings = { ...encodingSettingsRes.encodingSettings };

	newEncodingSettings[slider] = newValue;

	userEncodingLevels = [newEncodingSettings.qualityLevel];

	apollo?.writeQuery({
		query: queries.encodingSettings,
		data: {
			encodingSettings: newEncodingSettings
		}
	});

	updateEncodingSettings(apollo);
};

export const getEncodingSettingsValue = (type: keyof EncodingSettings): EncodingSettings[keyof EncodingSettings] => {
	const encodingSettingsRes = apollo?.readQuery({ query: queries.encodingSettings });

	const settingsValue = encodingSettingsRes.encodingSettings[type];
	return settingsValue;
};

export const handlePresetChange = (preset: Preset) => {
	console.log('HandlePresetChange', preset);

	switch (preset) {
		case Preset.DesktopBaseline:
			presetValue = preset;
			showCustomControls = false;
			break;
		case Preset.AndroidBaseline:
			presetValue = preset;
			showCustomControls = false;
			break;
		case Preset.Developer:
			presetValue = preset;
			showCustomControls = true;
			break;
		default:
			return;
	}

	updateEncodingLevels(preset);
};

export const showClosePresentationControlsDialog = (context: TDialogContext, onCancel: () => void, onClose: () => void) => {
	context.addDialog(<InfoDialogTemplate
		header={'Close presentation controls'}
		message={`The presentation will continue to run in the background. 
		
		You can find the presentation controls again in the Presentation View app.`}
		callbacks={[
			{ callback: onCancel, label: 'Cancel' },
			{ callback: onClose, color: "primary", label: 'OK' }
		]}
	/>);
};
