import { CSSProperties } from "@material-ui/core/styles/withStyles";

export type Type = (selected: boolean) => CSSProperties;

const common = (selected: boolean): CSSProperties => 
({
	background: selected ? 'rgba(255, 255, 255, 0.07)' : 'none',

	'& p, .MuiTypography-root, .MuiSvgIcon-root': {
		opacity: selected ? '100% !important' : '60% !important',
	},

	'&:hover': {
		background: 'rgba(255, 255, 255, 0.07)',
		'& p, .MuiTypography-root, .MuiSvgIcon-root': {
			opacity: '100% !important',
		},
	},

	'&:active': {
		background: 'rgba(255, 255, 255, 0.2)',
		'& p, .MuiTypography-root, .MuiSvgIcon-root': {
			opacity: '100% !important',
		},
	},
});

export const web = (selected: boolean): CSSProperties => 
({
	...common(selected),
});

export const tablet = (selected: boolean): CSSProperties => 
({
	...common(selected),
});
