import queries from "../graphql/queries";
import Cookie from 'universal-cookie';

export const sttActive = (sttRes, sinkId) =>
{
	return sttRes.data?.speechRecognition.sinkId === sinkId &&
		!sttRes.data?.speechRecognition.loading &&
		sttRes.data?.speechRecognition.dialogOpen;
};

export async function getTokenOrRefresh(apollo) {
    const cookie = new Cookie();
    const speechRecognitionToken = cookie.get('speech-recognition-token');

    if (speechRecognitionToken === undefined) {
        console.log('No speech recognition token stored in cookie, making query...');

        const speechRecognitionTokenRes = await apollo.query({
            query: queries.speechRecognitionToken,
            fetchPolicy: 'network-only'
        });

        const token = speechRecognitionTokenRes.data?.speechRecognitionToken.token;
        const region = speechRecognitionTokenRes.data?.speechRecognitionToken.region;

        if (region && token)
        {
            cookie.set('speech-recognition-token', region + ':' + token, {maxAge: 540, path: '/'});
        }

        return speechRecognitionTokenRes.data?.speechRecognitionToken;
    } else {
        console.log('Azure Speech Recognition token fetched from cookie!');
        const idx = speechRecognitionToken.indexOf(':');
        return { token: speechRecognitionToken.slice(idx + 1), region: speechRecognitionToken.slice(0, idx) };
    }
}