import React, { useEffect, useState } from 'react';
import GlueDialog from '../common/glue-dialog';
import SelectUploadLocation from './select-upload-location';
import { sendWebControlMsg } from '../../util/control-util';
import UploadFileBrowser from './upload-file-browser';
import { useApolloClient, useQuery } from '@apollo/client';
import queries from '../../graphql/queries';
import { InventoryItem, BrowserFileUploadState } from '../../graphql/types-generated';
import InfoDialogTemplate from '../common/info-dialog-template';

const UploadMenu = (props: {
	open: boolean
	state?: BrowserFileUploadState
}) =>
{
	const apollo = useApolloClient();
	const [fileBrowserActive, setFileBrowserActive] = useState(false);

	const teamIdRes = useQuery(queries.currentTeamId);
	const loading = props.state?.status === 'Uploading';

	const onSelectLocation = (files: boolean) => {
		if (!files) {
			sendWebControlMsg("OpenDeviceFileBrowser");
			return;
		}
		setFileBrowserActive(true);
	}

	const onSelectTeamFiles = async (files: InventoryItem[]) => {
		const data = await Promise.all(files.map(async file => {
			const signInventoryItemGetRes = await apollo.query({
				query: queries.signInventoryItemGet,
				variables: { inventoryItemId: file.inventoryItemId }
			});

			return {
				name: file.name,
				fileSize: file.fileSize,
				signedUrl: signInventoryItemGetRes.data.signInventoryItemGet.itemURL
			};
		}));
		sendWebControlMsg("UploadTeamFiles", { items: data });
	}

	const onCancel = () => {
		sendWebControlMsg("CancelFileUpload");
	}

	useEffect(() => {
		if (!props.open) {
			setFileBrowserActive(false);
		}
	}, [props.open])

	return (
		<GlueDialog maxWidth='xl' open={props.open}>
			{!!props.state?.error ? (
				<InfoDialogTemplate
					isError
					message={props.state.error}
					callbacks={[
						{ callback: onCancel, color: 'primary', label: 'OK' },
					]}
				/>
			) : fileBrowserActive ? (
				<UploadFileBrowser
					loading={loading}
					teamId={teamIdRes.data?.currentTeamId}
					multipleAllowed={props.state?.multipleAllowed}
					acceptFilters={props.state?.acceptFilters}
					onSelect={onSelectTeamFiles}
					onCancel={onCancel}
				/>
			) : (
				<SelectUploadLocation
					onSelect={onSelectLocation}
					onCancel={onCancel}
				/>
			)}
		</GlueDialog>
	);
};

export default UploadMenu;
