import { CSSProperties } from "@material-ui/core/styles/withStyles";

export type Type = CSSProperties;

const common: Type =
{
	width: '472px',
	height: '265px',
};

export const web: Type =
{
	...common,
	width: '304px',
	height: '172px',
};

export const tablet: Type =
{
	...common,
};
