import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import GlueListItem from './glue-list-item';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { clickAudio, hoverAudio } from './common-vuplex-messages';
import GlueIcon from './glue-icon';
import GlueScrollablePopover from './glue-scrollable-popover';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

type DropdownItem = {
	id: string | number
	name: string
}

type DropdownProps = {
	width?: string
	value?: string | number | (string | number)[] | null
	defaultValue?: string | number | (string | number)[]
	items?: DropdownItem[]
	uiAudioMessage?: string
	uiHoverAudioMessage?: string
	uiSelAudioMessage?: string
	uiSelHoverAudioMessage?: string
	multiple?: boolean
	label?: string | React.ReactNode
	perpetualLabel?: boolean
	disabled?: boolean
	onChange?: (id: string | number, checked: boolean) => void
	onClose?: () => void
}

type DropdownHeaderProps = {
	open: boolean
	onPointerDown: () => void
	onPointerEnter: () => void
	anchorRef: React.RefObject<HTMLDivElement> | null
	multiple: boolean
	items?: DropdownItem[]
	selected?: string | number | (string | number)[]
	label?: string | React.ReactNode
	disabled?: boolean
	perpetualLabel: boolean
	classes: ClassNameMap
}

type DropdownStyleProps = {
	width?: string
	disabled?: boolean
	open: boolean
	invert: boolean
}

const idIsValid = (id?: string | number) =>
{
	if (!id)
		return id === 0 ? true : false;
	return true;
}

const useStyles = makeStyles((theme) => ({
	root: (props: DropdownStyleProps) => ({
		width: props.width,
		minWidth: !props.width ? theme.custom.glueDropdown.minWidth : undefined,
		height: theme.custom.glueDropdown.height,
	}),

	header: (props: DropdownStyleProps) => ({
		width: '100%',
		height: '100%',

		background: props.open ? theme.palette.secondary.light : theme.palette.secondary.main,
		opacity: props.disabled ? '30%' : '100%',

		borderTopLeftRadius: props.open && props.invert ? 0 : '4px',
		borderTopRightRadius: props.open && props.invert ? 0 : '4px',
		borderBottomLeftRadius: props.open && !props.invert ? 0 : '4px',
		borderBottomRightRadius: props.open && !props.invert ? 0 : '4px',

		cursor: 'pointer',
		display: 'inline-flex',
	}),

	clickArea: {
		display: 'inline-flex',
		alignItems: 'center',
		width: '100%',
		height: '100%',

		'& > :last-child': {
			alignItems: 'flex-end'
		},

		'&:hover': {
			filter: 'brightness(107%)'
		},

		'&:active': {
			filter: 'brightness(120%)'
		},
	},

	label: {
		width: '100%',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		textAlign: 'left',
		marginLeft: theme.glueSpacing('m'),
		marginRight: 'auto',
	},
}), { name: 'MuiGlueDropdown' });

const GlueDropdownHeader = (props: DropdownHeaderProps) => {
	const selectedId = !props.multiple ? (Array.isArray(props.selected) ? (props.selected.length === 1 ? props.selected[0] : undefined) : props.selected) : undefined;
	const label: string | React.ReactNode = (!props.perpetualLabel && idIsValid(selectedId)) ? props.items?.find(i => i?.id === selectedId)?.name ?? props.label : props.label;
	// This is the worst and I hate it, I hate web development
	const customLabel = !!label ? (typeof label !== 'string') : false;

	return (
		<div className={props.classes.header} ref={props.anchorRef} onWheel={(e) => e.stopPropagation()}>
			{customLabel && <div>{label}</div>}
			<div className={props.classes.clickArea} onPointerDown={props.onPointerDown} onPointerEnter={props.onPointerEnter}>
				{!customLabel && 
					<div className={props.classes.label}>
						{label}
					</div>	
				}
				<GlueIcon>
					{props.open ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
				</GlueIcon>
			</div>
		</div>
	);
}

const GlueDropdown = (props: DropdownProps) => 
{
	const [ open, setOpen ] = useState(false);
	const [ invert, setInvert ] = useState(false);
	const [ selected, setSelected ] = useState(props.defaultValue ?? []);

	const value = props.value ?? selected;

	const anchorRef = useRef<HTMLDivElement>(null);

	const classes = useStyles({ width: props.width, disabled: props.disabled, open, invert });

	const selectItem = (id: string | number) => {
		let checked = true;

		if (props.multiple)
		{
			const newSelected = Array.isArray(selected) ? [...selected] : [selected];
			const existingIndex = newSelected.findIndex(i => i === id);
			if (existingIndex !== -1)
			{
				newSelected.splice(existingIndex, 1);
				checked = false;
			}
			else 
			{
				newSelected.push(id);
			}
			setSelected(newSelected);
		}
		else
		{
			setSelected([id]);
		}

		if (props.onChange)
		{
			props.onChange(id, checked);
		}

		if (!props.multiple)
			setOpen(false);
	}

	const handleOpen = (state: boolean) => {
		if (props.disabled) return;

		setOpen(state);
		if (!state && props.onClose)
		{
			props.onClose();
		}
	}

	const drawHeader = (main: boolean) => {
		return (
			<GlueDropdownHeader 
				open={open}
				onPointerDown={() => { clickAudio(audiomessage); handleOpen(!open); }}
				onPointerEnter={() => {hoverAudio(hoveraudiomessage)}}
				anchorRef={main ? anchorRef : null} 
				multiple={!!props.multiple} 
				items={props.items}
				selected={value}
				label={props.label}
				perpetualLabel={!!props.perpetualLabel}
				classes={classes}
			/>
		);
	}

	const itemIsSelected = (item: DropdownItem) => {
		if (!idIsValid(item.id))
			return false;

		if (Array.isArray(value))
		{
			return value.includes(item.id);
		}
		else return value === item.id;
	}

	const audiomessage = !!props.uiAudioMessage ? props.uiAudioMessage : "Menu/Generic/Button/Press";
	const hoveraudiomessage = !!props.uiHoverAudioMessage ? props.uiHoverAudioMessage : "Menu/Generic/Button/HL";
	const selaudiomessage = !!props.uiSelAudioMessage ? props.uiSelAudioMessage : "Menu/Generic/Button/Press";
	const selhoveraudiomessage = !!props.uiSelHoverAudioMessage ? props.uiSelHoverAudioMessage : "Menu/Generic/Button/HL";

	return (
		<div className={classes.root}>
			{drawHeader(true)}
			<GlueScrollablePopover
				width={`${anchorRef.current?.offsetWidth}px`}
				open={open}
				invert={invert}
				setInvert={setInvert}
				horizontal='left'
				vertical={invert ? 'bottom' : 'top'}
				onClose={() => setOpen(false)}
				anchorRef={anchorRef}
				firstElement={drawHeader(false)}
			>
				{props.items?.map((item) => (
					<GlueListItem 
						key={item.id}
						checkbox={!!props.multiple}
						selected={itemIsSelected(item)}
						onPointerDown={() => selectItem(item?.id)}
						uiAudioMessage={selaudiomessage}
						uiHoverAudioMessage={selhoveraudiomessage}
					>
						{item?.name}
					</GlueListItem>
				))}
			</GlueScrollablePopover>
		</div>
	);
}

export default GlueDropdown;
