import React, { useState } from 'react';
import { Navigate, Route, Routes } from "react-router-dom";
import postVuplexMessage from '../../service/message-vuplex';
import queries from '../../graphql/queries';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core/styles';

import {isStandaloneDevice} from '../../util/platform-utils'

import SettingsViewHeader from './settings-view-header'
import General from '../settings/general';
import Sound from '../settings/sound';
import Display from '../settings/display';
import AddApps from '../settings/addapps';
import About from '../settings/about';
import { useUserSettings } from '../../service/user-settings';
import GlueScroll from '../common/glue-scroll';
// import Sharing from '../settings/sharing';

const useStyles = makeStyles(theme => ({
	contentArea: {
		...theme.custom.contentBase,
		height: '100%',
		margin: 'auto',
		display: 'flex',
		justifyContent: 'center',
		padding: `0 ${theme.glueSpacing('s')} 0`,
		...theme.custom.view.mainContent
	},

	scrollRoot: {
		width: '100%',
	},
}), { name: 'MuiGlueSettingsView' });

const SettingsView = (props) => {
	const classes = useStyles();

	const [dirty, setDirty] = useState(false);

	const apollo = useApolloClient();

	const { userSettings, setUserSettings, settingsLoading } = useUserSettings();
	const clientPlatformResult = useQuery(queries.clientPlatform);
	const clientPlatform = clientPlatformResult.data?.clientPlatform;
	const vr = clientPlatform?.PlatformType === "VR";
	const saHS = isStandaloneDevice(apollo);

	postVuplexMessage('Get Microphone devices');
	postVuplexMessage('Get all volumes');
	postVuplexMessage('Get display resolutions');

	if (settingsLoading)
	{
		// Maybe have a loading indicator?
		return null;
	}

	const saveChanges = () =>
	{
		postVuplexMessage("Apply display settings");
		setDirty(false);
	}

	const revertChanges = () =>
	{
		postVuplexMessage("Revert display settings");
		setDirty(false);
	}

	const setResolution = (newValue) =>
	{
		postVuplexMessage('Change display resolution', { value: newValue });
		setDirty(true);
	}

	const setRefreshRate = (newValue) =>
	{
		postVuplexMessage('Change display refresh rate', { value: newValue });
		setDirty(true);
	}

	const setFullscreen = (newValue) =>
	{
		postVuplexMessage('Change fullscreen mode', { value: newValue });
		setDirty(true);
	}

	const setPersonalSpaceProtection = (newValue) =>
	{
		setUserSettings({ personalSpaceProtection: newValue });
		postVuplexMessage('Set personal space', { value: newValue });
	}

	const setGrabbingMode = (newValue) => {
		setUserSettings({ vrGrabbingMode: newValue });
		postVuplexMessage('Set grabbing mode', { value: newValue });
	}

	const setVoiceCommunicationOnly = (newValue) => {
		setUserSettings({ voiceCommunicationOnly: newValue });
		postVuplexMessage('Set voice comm', { value: newValue });
	}

	const setForceTunneling = (newValue) =>
	{
		setUserSettings({ forceTunneling: newValue });
		postVuplexMessage('Set force tunneling', { value: newValue });
	}

	return (<>
		<SettingsViewHeader
			revert={revertChanges}
			save={saveChanges}
			dirty={dirty} vr={vr}
		/>

		<div className={classes.contentArea}>
			<div className={classes.scrollRoot}>
				<GlueScroll persistent>
					<Routes>
						<Route path={'/'} element={<Navigate to={'general'} />} />
						<Route path={'general'} element={
							<General
								settings={userSettings}
								saHS={saHS}
								updatePersonalSpace={setPersonalSpaceProtection}
								updateGrabMode={setGrabbingMode}
								updateForceTunneling={setForceTunneling}
							/>
						} />

						<Route path={'sound'} element={
							<Sound 
								settings={userSettings} 
								updateVoiceComm={setVoiceCommunicationOnly}
							/>
						} />

						{/* <Route path={'sharing'} element={
							<Sharing
							/>
						} /> */}

						{!vr && (
							<Route path={'display'} element={
								<Display
									setRefreshRate={setRefreshRate}
									setResolution={setResolution}
									setFullscreen={setFullscreen}
									applyChanges={saveChanges}
									dirty={!dirty}
								/>
							} />
						)}
						<Route path={'addapps'} element={<AddApps />} />
						<Route path={'about'} element={<About />} />
					</Routes>
				</GlueScroll>
			</div>
		</div>
	</>);
};

export default SettingsView;
