import React from 'react';
import GlueInputfield from '../common/glue-inputfield';
import { makeStyles, Typography } from '@material-ui/core';

import { GlueTheme } from '../../service/glue-theme';
import { useNickname } from './avatar-hooks';

const useStyles = makeStyles(theme => ({
	footer: {
		display: 'flex',
		justifyContent: 'left',
		alignItems: 'center',
		height: '60px',
	},

	mainContent: {
		display: 'flex',
		flexFlow: 'row nowrap',
		flex: '1 0 auto',
		alignItems: 'center',
		justifyContent: 'center'
	},

	nameInput: {
		display: 'flex',
		flexFlow: 'column nowrap',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '318px',
		height: '144px'
	},

	nameGuide: {
		fontSize: '20px',
		letterSpacing: '1px'
	},

	user: {
		marginLeft: '48px',
		marginBottom: '36px',
		color: theme.palette.primary.contrastText,
	},

	logged: {
		marginBottom: '36px',
		opacity: '0.7',
	}
}), { name: "MuiGlueAvatarOverview" });

const Overview = (props: {
	email: string
}) => {

	const classes = useStyles();
	const [ nickname, setNickname ] = useNickname();

	return (<>
		<div className={classes.mainContent}>
			<div className={classes.nameInput}>
				<GlueInputfield
					height={96}
					width={450}
					centerText
					simpleInput
					noOutline
					fontSize={'48px'}
					fontWeight={'bold'}
					backgroundColor={GlueTheme.palette.background.paper}
					maxLength={34}
					value={nickname}
					onChange={setNickname}
				/>
				<Typography className={classes.nameGuide}>
					Click to rename your avatar.
				</Typography>
			</div>
			
		</div>
		
		<div className={classes.footer}>
			{props.email ?
			<p className={classes.user}><span className={classes.logged}> Logged in as:</span> {props.email}</p>
			: null}
		</div>
	</>);
}

export default Overview;