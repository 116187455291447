import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useNavigate } from 'react-router-dom';

import postVuplexMessage from '../service/message-vuplex';
import HeroIcon from './common/hero-icon';
import queries from '../graphql/queries';

import HelpIcon from '../icons/hero/Help.png';
import HelpDisabledIcon from '../icons/Help_FacLimit.png'

const HelpHeroIcon = (props) =>
{
	const navigate = useNavigate();
	const audiomessage = "Tools/Help/Press"; 
	const hoveraudiomessage = "Tools/Help/HL";
	const uiDisabledResult = useQuery(queries.uiDisabled);
	const uiDisabled = uiDisabledResult.data?.uiDisabled;

	const onClickHelp = (toolbar) =>
	{
		if (toolbar)
		{
			postVuplexMessage('Request help view', null);
		}
		else
		{
			navigate('/view/help');
		}
	};

	return (
		<HeroIcon
			name="Help"
			disabled={uiDisabled}
			icon={uiDisabled ? HelpDisabledIcon : HelpIcon}
			noBackground={props.noBackground}
			uiAudioMessage = {audiomessage}
			uiHoverAudioMessage = {hoveraudiomessage}
			onPointerDown={() => onClickHelp(props.isToolbar)}
			hotkey="3"
			facLocked={uiDisabled}
		/>
	);
};

export default HelpHeroIcon;
