import { CSSProperties } from "@material-ui/core/styles/withStyles";

export type Type = CSSProperties;

const common: Type =
{
	width: '36px',
	minWidth: '36px',
	height: '36px',
	borderRadius: '18px',
};

export const web: Type =
{
	...common
};

export const tablet: Type =
{
	...common,

	width: '64px',
	minWidth: '64px',
	height: '64px',
	borderRadius: '32px'
};