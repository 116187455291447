import React from 'react';
// import postVuplexMessage from '../../service/message-vuplex';

// The purpose of this component is to provide a place for development time UI,
// such as displaying debug information in-app or providing inputs for adjusting
// different parameters at runtime.

const DevTools = () => {
	return (
		<div>
		</div>
	);
}

export default DevTools;
