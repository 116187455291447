import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import queries from '../../graphql/queries';
import { makeStyles } from '@material-ui/core';

import GlueInputfield from '../common/glue-inputfield';
import GlueButton from '../common/glue-button';
import PageSelector from '../common/page-selector';
import { sttActive } from '../../util/speech-utils';

import GlueDropdown from '../common/glue-dropdown';
import GlueListItem from '../common/glue-list-item';

const itemsPerPageCount = 20;
const maxLanguageSelection = 5;

const useStyles = makeStyles((theme) => ({
	root: {
		height: '970px',
		paddingBottom: theme.glueSpacing('s'),
		paddingRight: theme.glueSpacing('xl'),
		paddingLeft: theme.glueSpacing('xl')
	},

	header: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: `${theme.glueSpacing('m')} 0`
	},

	actions: {
		display: 'flex',
		gap: theme.glueSpacing('m'),
		marginBottom: theme.glueSpacing('xl')
	},

	pageSelector: {
		flex: '1 1 auto',
		display: 'flex',
		justifyContent: 'flex-end',
	},

	body: {
		display: 'grid',
		gridTemplateColumns: 'repeat(2, 1fr)',
		alignContent: 'baseline',
		columnGap: theme.glueSpacing('m'),
	},

	buttons: {
		display: 'flex',
		gap: theme.glueSpacing('m'),
		justifyContent: 'center',
		marginTop: theme.glueSpacing('xl')
	}

}), { name: 'MuiGlueLanguageSelection' });

const LanguageSelectionDialog = (props) =>
{
	const classes = useStyles();
	const [searchWord, setSearchWord] = useState('');
	const [page, setPage] = useState(0);
	const [selectedLanguages, setSelectedLanguages] = useState(props.selectedLanguages ?? []);

	const sttRes = useQuery(queries.speechRecognition);
	const searchSTTSinkId = "LanguageSelectionAppSearch";

	const languageSelectionFull = props.languageSelection;
	const startIndex = page * itemsPerPageCount;
	const endIndex = startIndex + itemsPerPageCount;

	const searchedLanguageList = languageSelectionFull.filter(language => language.name.toLowerCase().includes(searchWord.toLowerCase()));
	const languageSelection = searchedLanguageList.slice(startIndex, endIndex);
	const selectedLanguageList = languageSelectionFull.filter(language => selectedLanguages.includes(language.id));

	const pageCount = Math.ceil(searchedLanguageList.length / itemsPerPageCount);

	if (page >= pageCount && page !== 0)
	{
		setPage(0);
		return;
	}
	
	const isSelected = (value) =>
	{
		return selectedLanguages.includes(value);
	};

	const selectItem = (value) =>
	{
		if (selectedLanguages.includes(value))
		{
			setSelectedLanguages(selectedLanguages.filter(item => item !== value));
		}
		else if (selectedLanguages.length < maxLanguageSelection)
		{
			setSelectedLanguages(selectedLanguages.concat([value]));
		}
	};

	const commitChanges = () =>
	{
		props.onChange(selectedLanguages);
	}

	const closeAndCommit = () =>
	{
		commitChanges();
		props.closeDialog();
	}

	const closeaudiomessage = "Tools/Settings/General/Language/SelectView/Close/Press";
	const closehoveraudiomessage = "Tools/Settings/General/Language/SelectView/Close/HL";
	
	return (
		<div className={classes.root}>
			<div className={classes.header}>
				<h2>Pick your languages</h2>
			</div>
			<div className={classes.actions}>
				<GlueInputfield
					search
					autoFocus
					value={searchWord}
					sinkId={searchSTTSinkId} 
					speechInput={sttActive(sttRes, searchSTTSinkId)}
					onClear={()=>{ setSearchWord("") }}
					onChange={(value)=>{ setSearchWord(value) }}
					onSpeechChange={(value)=>{ setSearchWord(value) }}
					showClearButton={searchWord ? true : false}
					margin={"0px 0px 0px 0px"}
				/>
				<GlueDropdown
					label={`Current languages (${selectedLanguages.length}/${maxLanguageSelection})`}
					width={'480px'}
					multiple
					items={selectedLanguageList}
					value={selectedLanguages}
					onChange={(id, checked) => selectItem(id)}
				/>
				<div className={classes.pageSelector}>
					<PageSelector
						current={page}
						pageCount={pageCount}
						onPageChange={(pageNew) => setPage(pageNew)}
					/>
				</div>
			</div>
			<div className={classes.body}>
				{languageSelection.map((item) => (
					<GlueListItem
						color='paper'
						divider
						checkbox
						key={item.id}
						selected={isSelected(item.id)}
						onPointerDown={()=>{selectItem(item.id)}}
					>
						{item.name}
					</GlueListItem>
				))}
			</div>
			<div className={classes.buttons}>
				<GlueButton width={'144px'} uiAudioMessage={closeaudiomessage} uiHoverAudioMessage={closehoveraudiomessage} onPointerDown={props.closeDialog}>Cancel</GlueButton>
				<GlueButton width={'144px'} color={'primary'} uiAudioMessage={closeaudiomessage} uiHoverAudioMessage={closehoveraudiomessage} onPointerDown={closeAndCommit}>Done</GlueButton>
			</div>
		</div>
	);
};
export default LanguageSelectionDialog;
