import { CSSProperties } from "@material-ui/core/styles/withStyles";

export type Type = {
	borderRadius: string
	root: CSSProperties,
	header: CSSProperties,
	contentLabel: CSSProperties
};

const common: Type =
{
	borderRadius: '12px',
	root: {
		width: '668px',
	},
	header: {
		width: '100%',
        height: '88px'
    },
	contentLabel: {
		height: '64px'
	},
};

export const web: Type =
{
	...common,
	borderRadius: '12px',
	root: {
		width: '400px',
		filter: 'drop-shadow(0px 10px 10px rgba(0,0,0,0.5))',
	},
    header: {
		width: '100%',
        height: '52px'
    },
	contentLabel: {
		height: '36px'
	},
};

export const tablet: Type =
{
	...common
};