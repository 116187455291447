import React, { useState } from "react";

import { useQuery } from "@apollo/react-hooks";
import { makeStyles } from "@material-ui/core";

import queries from "../../graphql/queries";
import GlueScroll from "../common/glue-scroll";
import WebInputfield from "../../standalone-web/common/web-inputfield";

import OrgCard from "./org-card";
import GlueButton from "../common/glue-button";

import { usePromptDialogContext } from "../../util/prompt-dialog-context";
import CreateOrg from "../organization/create-org";

import { Organization } from "../../graphql/types-generated";


const useStyles = makeStyles((theme) => ({
	root: {
		flex: '1 1 auto'
	},

	search: {
		padding: '20px 0',
		display: 'flex',
		flexDirection: 'row',
		heigth: '36px',
		gap: theme.glueSpacing('m')
	},

	content: {
		marginTop: '15px',
		width: '100%',
		flex: '1',

		scrollbarWidth: 'thin',
		scrollbarColor: theme.palette.background.paper + theme.palette.background.default,

		display: 'flex',
		flexFlow: 'row wrap',
		justifyContent: 'flex-start',
		gap: '24px'
	},
}));

const Organizations = () =>
{
	const classes = useStyles();

	const { addDialog } = usePromptDialogContext();

	const [ searchWord, setSearchWord ] = useState('');

	const orgsRes = useQuery(queries.findOrganizationsByName, {
		skip: !searchWord,
		variables: {
			name: searchWord
		},
		fetchPolicy: 'network-only'
	});

	let orgs = orgsRes.data?.findOrganizationsByName ?? [];
	console.log(orgs)

	orgs = orgs.filter((org: Organization)  => org.name.toLowerCase().includes(searchWord.toLowerCase()));

	const createOrg = () =>
	{
		addDialog(<CreateOrg refetch={() => orgsRes.refetch()} />)
	}

	return(
		<div className={classes.root}>
			<div className={classes.search}>
				<GlueButton color="primary" onPointerDown={() => createOrg()} >Add org</GlueButton>
				<WebInputfield
					search
					width={'250px'}
					placeholder={"Search Organizations"}
					showClearButton={searchWord ? true : false}
					value={searchWord}
					onChange={(value: string) => setSearchWord(value)}
					onClear={() => { setSearchWord("") }}
				/>
			</div>
			<GlueScroll>
				<div className={classes.content}>
					{orgs.map((org: Organization, i: number) => (
						<OrgCard
							key={i}
							name={org.name}
							orgId={org.orgId}
						/>
					))}
				</div>
			</GlueScroll>
		</div>
	)
}

export default Organizations;