import React from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { useUserSettings } from '../service/user-settings';
import AvatarIcon from '../icons/MyAvatar.png';
import GlueIcon from './common/glue-icon';
import { useUserContext } from '../util/user-context';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex', 
		gap: theme.glueSpacing('m'), 
		paddingLeft: theme.glueSpacing('m'), 
		alignItems: 'center', 
		position: 'relative'
	},

	text: {
		maxWidth: '256px',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		overflow: 'hidden'
	},

	button: {
		...theme.custom.selfButton,
		background: theme.palette.primary.dark,
		overflow: 'hidden', 
	},

	clickArea: {
		width: '100%',
		height: '100%',
		position: 'absolute',
		left: 0,
		zIndex: 1,
		cursor: 'pointer',
		borderRadius: theme.custom.selfButton.borderRadius,

		'&:hover': {
			filter: 'brightness(107%)'
		},

		'&:active': {
			filter: 'brightness(120%)'
		},
	}
}), { name: 'MuiGlueSelfButton' });

const SelfButton = (props) =>
{
	const navigate = useNavigate();
	const classes = useStyles();

	const avatarPathTablet = '/view/avatarconfigurator/overview';
	const avatarPathWeb = '/my-avatar/overview';

	const gotoAvatar = () => {
		if (props.web)
		{
			navigate(avatarPathWeb);
		}
		else
		{
			navigate(avatarPathTablet);
		}
	}

	const { userSettings } = useUserSettings();
	const user = useUserContext();
	const nameText = (userSettings?.nickname ? userSettings?.nickname : user.email) ?? "";

	return (
		<div className={classes.root}>
			<div 
				className={classes.clickArea}
				onPointerDown= {gotoAvatar}
			/>
			<p className={classes.text}>{nameText}</p>
			<div
				className={classes.button}
			>
				<GlueIcon>
					<img src={AvatarIcon} alt={''} />
				</GlueIcon>
			</div>
		</div>
	);
};

export default SelfButton;
