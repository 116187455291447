import React from 'react';

import AvatarEditorTab from './avatar-editor-tab';

const Clothing = () => {

	const contents = [
		{
			Title: "Style",
			Properties: [
				{
					FeatureType: "Clothing"
				}
			]
		},
		{
			Title: "Eyewear",
			Properties: [
				{
					FeatureType: "Eyewear"
				}
			]
		},
		{
			Title: "Headwear",
			Properties: [
				{
					FeatureType: "Headwear",
					ExcludeColor: "Hair"
				},
				{
					FeatureType: "Veil",
				}
			]
		}
	];

	return (
		<AvatarEditorTab 
			contents={contents} 
			/>
	);
};

export default Clothing;