import { PersistentStorage } from "apollo3-cache-persist";
import postVuplexMessage from "../service/message-vuplex";

type CacheResponse = {
  serializedCache: string | null
};

let getCacheResponse: CacheResponse | null = null;

export const setGetCacheResponse = (serializedCache: string | null) => {
  getCacheResponse = { serializedCache };
}

const waitForGetItemResponse = () => {
  const maxTries = 20;
  let tries = 0;
  const checkForResponse = (resolve: (value: string | null) => void) => {
    tries++;
    if (tries >= maxTries) {
      console.warn('No cache response from Glue client! Make sure message listeners have been registered for this webview!');
      resolve(null);
      return;
    }

    if (!getCacheResponse) {
      setTimeout(() => checkForResponse(resolve), 100);
      return;
    }

    const result = getCacheResponse.serializedCache;
    getCacheResponse = null;
    resolve(result);
  }

  return new Promise((resolve: (value: string | null) => void) => checkForResponse(resolve));
}

export class ClientStorageWrapper implements PersistentStorage<string | null> {
  getItem(key: string): Promise<string | null> {
    const instanceId = window.sessionStorage.getItem('instance-id');
    postVuplexMessage('ApolloCachePersist.GetCache', { instanceId: instanceId });
    return waitForGetItemResponse();
  }

  removeItem(key: string): Promise<void> {
    const instanceId = window.sessionStorage.getItem('instance-id');
    postVuplexMessage('ApolloCachePersist.ClearCache', { instanceId: instanceId });
    return Promise.resolve();
  }

  setItem(key: string, value: string | null): Promise<void> {
    const instanceId = window.sessionStorage.getItem('instance-id');
    postVuplexMessage('ApolloCachePersist.SetCache', { instanceId: instanceId, serializedCache: value });
    return Promise.resolve();
  }
}