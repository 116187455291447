import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';

import WebInputfield from './common/web-inputfield';
import { useApolloClient, useQuery } from '@apollo/react-hooks';

import queries from '../graphql/queries';

import GlueScroll from '../component/common/glue-scroll';
import GlueButton from '../component/common/glue-button';


const useStyles = makeStyles((theme) => ({
	root: {

	},

	container: {
		padding: '25px'
	},

	orgBox: {
		display: 'flex',
		flexDirection: 'column',
		padding: '5px 0'
	},

	teamResultBox: {
		display: 'grid',
		gap: '35px',
		gridTemplateColumns: '2fr 1fr',
		padding: '0 30px',
		margin: '2px 0',
		background: theme.palette.background.paper,
		'& p': {
			fontSize: '16px'
		}
	},

	resultBox: {
		display: 'grid',
		gap: '35px',
		gridTemplateColumns: '2fr 1fr',
		'& p': {
			fontSize: '20px',
			color: theme.palette.primary.main
		}
	},

	buttons: {
		display: 'flex',
		gap: theme.spacing('l'),
		justifyContent: 'space-between'
	}
}));

const SearchUtility = (props) => {
	const classes = useStyles();
	const apollo = useApolloClient();
	const [searchEmail, setSearchEmail] = useState('');
	const [noFetch, setNoFetch] = useState(true);
	const teamsRes = useQuery(queries.userTeams, { skip: noFetch, variables: { userEmail: searchEmail }, fetchPolicy: 'network-only' });
	const [orphanedTeams, setOrphanedTeams] = useState();
	const [nonExistingOrgs, setNonExistingOrgs] = useState();
	const orgsRes = useQuery(queries.getUserOrganizations, { skip: noFetch, variables: { email: searchEmail }, fetchPolicy: 'network-only' });

	useEffect(() => {
		if (teamsRes.data) {
			const getOrpanedTeams = async (apollo, teams) => {
				const orphaned = []
				await Promise.all(teams.map(async (el) => {
					await apollo.query({
						query: queries.getOrgIdOfTeam,
						variables: {
							teamId: el.teamId
						}
					}).then(res => {
						if (!res.data.getOrgIdOfTeam)
							orphaned.push(el);
						console.log("Clear", res.data);
					}).catch(err => {
						console.error(err)
					});
				}));

				console.log("ORPHANED", orphaned.length)

				return setOrphanedTeams(orphaned);
			}
			getOrpanedTeams(apollo, teamsRes.data.userTeams)
		}
		if (orgsRes.data) {
			const getUserOrgs = async (apollo, orgs) => {
				console.log(" Testing orgs...", orgs)
				const orphaned = []
				await orgs.forEach(async (el) => {
					await apollo.query({
						query: queries.getOrg,
						variables: {
							orgId: el.orgId
						}
					}).then(res => {
						console.log("Org:", res.data.getOrg.name);
					}).catch(err => {
						console.error(err)
						orphaned.push(el)
					});
				});

				return setNonExistingOrgs(orphaned);
			}
			getUserOrgs(apollo, orgsRes.data.getUserOrganizations)
		}
	}, [teamsRes, orgsRes, apollo])

	const getSearchResult = () => {
		if (searchEmail !== '') {
			setNoFetch(false);
			teamsRes.refetch();
		}
	}

	const clearField = (event) => {
		setSearchEmail('');
		setNoFetch(true);
	}

	const TeamInfo = (props) => {
		if (!teamsRes.data)
			return '';

		const teamData = teamsRes.data.userTeams.find(team => team.teamId === props.teamId);

		console.log(teamData)
		if (!teamData) {
			console.log(`Data for teamId: ${props.teamId} not found in my Teams`)
			return '';
		}

		const teamRole = teamData.selfRole === 'superuser' ? 'moderator' : teamData.selfRole;

		return(
			<div className={classes.teamResultBox} key={props.key}>
				<p>{teamData.name}</p>
				<p>{teamRole}</p>
			</div>
		)
	}

	if (nonExistingOrgs && nonExistingOrgs.length > 0)
		console.error("FOUND:", nonExistingOrgs)

	if (orgsRes.data)
		console.log('Orgs res', orgsRes.data.getUserOrganizations)

	return (
		<div className={classes.root}>
			Enter full email address:
			<WebInputfield
				showClearButton
				onClear={() => clearField()}
				margin={'25px auto'}
				onChange={(value) => setSearchEmail(value.toLowerCase())}
				value={searchEmail}
			/>
			<div className={classes.buttons}>
				<GlueButton
					onPointerDown={() => getSearchResult()}
				>
					Search
				</GlueButton>
			</div>
			<GlueScroll>
				{orgsRes.data &&
					<div>
						Orgs:
						<div className={classes.container}>
							{orgsRes.data.getUserOrganizations.map((item, i) => <div className={classes.orgBox} key={i}>
								<div className={classes.resultBox}><p>{item.name}</p><p>{item.userRole}</p></div>
								<p>Teams:</p>
								{item.teams.map((itemInner, j) => <TeamInfo key={j} teamId={itemInner} />)}
							</div>)}
						</div>
					</div>}
				{(orphanedTeams && orphanedTeams.length) > 0 &&
					<div>
						USER ORPHANED TEAMS:
						{orphanedTeams.map((team, i) => (
							<h3 key={i}>{team.name}</h3>
						))}
					</div>
				}
				<br />
			</GlueScroll>

		</div>
	);
}

export default SearchUtility;