import React, { useState, useEffect } from 'react';
import { LinearProgress, Checkbox, makeStyles } from '@material-ui/core';
import GlueDialog from '../common/glue-dialog';
import GlueButton from '../common/glue-button';
import LoadingIndicator from '../common/loading-indicator';
import { BrowserDownloadState, BrowserFileImportState } from '../../graphql/types-generated';
import { isImportable } from '../../util/download-utils';
import DoneIcon from '@material-ui/icons/Done';
import GlueIcon from '../common/glue-icon';
import { useQuery } from '@apollo/client';
import queries from '../../graphql/queries';
import { sendWebControlMsg } from '../../util/control-util';
import { isAtHome } from '../../util/space-utils';

const useStyles = makeStyles(theme => ({
	root: {
		overflow: 'hidden',
	},

	header: {
		height: theme.custom.errorDialog.header.height,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		marginBottom: theme.glueSpacing('m')
	},

	body: {
		margin: `0 ${theme.glueSpacing('l')}`,
		width: '552px',
		display: 'flex',
		flexFlow: 'column',
		alignItems: 'center',
		textAlign: 'center',
		gap: theme.glueSpacing('xl')
	},

	file: {
		width:'100%',
		display: 'flex',
		flexFlow: 'column',
		alignItems: 'center',
		gap: theme.glueSpacing('s'),

		'& .MuiSvgIcon-root': {
			color: theme.palette.success.main
		}
	},

	progress: {
		width: '100%',
		height: theme.custom.icon.height,
		padding: '26px 0'
	},

	textSecondary: {
		opacity: '60%'
	},

	dialogCheckbox: {
		width: 'fit-content',
		justifySelf: 'center',
	},

	checkBoxLine: {
		display: 'flex',
		flexDirection: 'row',
		gap: theme.glueSpacing('s'),
		alignItems: 'center',
		height: theme.custom.icon.height
	},

	buttons: {
		margin: 'auto',
		width: 'fit-content',
		display: 'grid',
		gridAutoFlow: 'column',
		gap: theme.glueSpacing('m')
	},

	warning: {
		color: theme.palette.warning.main
	},

	error: {
		color: theme.palette.error.main
	}

}));


const DownloadMenu = (props: {
	open: boolean
	instanceId?: string
	fileUrl?: string
	onClose: () => void
}) =>
{
	const classes = useStyles();
	const [ addToFiles, setAddToFiles ] = useState(true);
	const [ importToSpace, setImportToSpace ] = useState(false);

	const dStateRes = useQuery<{ extBrowserDownloadState: BrowserDownloadState }>(queries.extBrowserDownloadState, {
		variables: {
			instanceId: props.instanceId,
			fileUrl: props.fileUrl
		}
	});
	const state = dStateRes.data?.extBrowserDownloadState;

	const iStateRes = useQuery<{ extBrowserFileImportState: BrowserFileImportState }>(queries.extBrowserFileImportState, {
		variables: {
			instanceId: props.instanceId,
			fileUrl: props.fileUrl
		}
	});
	const importState = iStateRes.data?.extBrowserFileImportState;

	const serverKeyRes = useQuery(queries.currentSpaceServerKey);
	const atHome = isAtHome(serverKeyRes);
	const teamIdRes = useQuery(queries.currentTeamId);
	
	const importable = (state && props.fileUrl) ? isImportable(props.fileUrl, state.fileInfo.fileType) : false;
	const loading = importState?.status === 'ImportingToSpace' || importState?.status === 'ImportingToFiles';

	const resetState = () => {
		setAddToFiles(true);
		setImportToSpace(false);
	}

	const onCancel = () => {
		sendWebControlMsg('CancelFileImport', { fileUrl: props.fileUrl });
		resetState();
		props.onClose();
	}

	const onImport = () => {
		sendWebControlMsg('ImportDownloadedFile', { 
			fileUrl: props.fileUrl,
			importToFiles: addToFiles,
			teamId: teamIdRes.data?.currentTeamId,
			serverKey: importToSpace ? (atHome ? 'nonsession' : serverKeyRes.data?.currentSpaceServerKey) : null
		});
	}

	useEffect(() => {
		if (importState && importState.status === 'Finished') {
			resetState();
			props.onClose();
		}
	}, [importState])

	const fileStatusContent = () => {
		switch(state?.status) {
			case 'Finished':
				return (
					<GlueIcon>
						<DoneIcon />
					</GlueIcon>
				);
			case 'Updated':
				return (
					<div className={classes.progress}>
						<LinearProgress 
							variant='indeterminate' 
							/>
					</div>
				);
			case 'Failed':
				return (
					<p className={classes.error}>Error downloading file</p>
				);
			default:
				return null;
		}
	}

	const importStatusContent = () => {
		switch(importState?.status) {
			case 'ImportingToSpace':
				return (
					<p>Importing to space...</p>
				);
			case 'ImportingToFiles':
				return (
					<p>Importing to files...</p>
				);
			case 'Failed':
				return (
					<p className={classes.error}>{importState?.error}</p>
				);
			default:
				return null;
		}
	}

	return (
		<GlueDialog maxWidth='xl' open={props.open}>
			<div className={classes.root}>
				<div className={classes.header}>
					<h2>Import into Glue</h2>
				</div>
				<div className={classes.body}>
					<div className={classes.file}>
						<p>{state?.fileInfo.fileName}</p>
						{!!importState ? importStatusContent() : fileStatusContent()}
					</div>
					<div className={classes.dialogCheckbox}>
						<div className={classes.checkBoxLine}>
						<Checkbox
							disabled={loading}
							checked={addToFiles}
							onChange={(e, checked) => setAddToFiles(checked)}
						/> 
							<p>Add to this team&apos;s Files</p>
						</div>
						<div className={classes.checkBoxLine}>
						<Checkbox 
							disabled={loading || !importable}
							checked={importToSpace}
							onChange={(e, checked) => setImportToSpace(checked)}
						/> 
							<p className={importable ? undefined : classes.textSecondary}>Import into this space</p>
						</div>
					</div>
					{!importable && (
						<p className={classes.warning}>
							This file type cannot be imported into Glue<br />spaces. You can still add it into files.
						</p>
					)}
					<div className={classes.buttons}>
						<GlueButton
							onPointerDown={onCancel}
							disabled={loading}
						>
							Cancel
						</GlueButton>
						<GlueButton
							color="primary"
							onPointerDown={onImport}
							disabled={(!addToFiles && !importToSpace) || loading || state?.status !== 'Finished'}
						>
							{loading ? <LoadingIndicator variant='button' /> : <p>Import</p>}
						</GlueButton>
					</div>
				</div>
			</div>
		</GlueDialog>
	);
};

export default DownloadMenu;
