import queries from "../graphql/queries";
import { postVuplexMessage } from '../service/message-vuplex';

export const AxesSupported = {
    None : 0b00000000,
    X : 0b00000001,
    Y : 0b00000010,
    Z : 0b00000100,
    XY : 0b00000011,
    XZ : 0b00000101,
    YZ : 0b00000110,
    XYZ : 0b00000111
}

export const applyValue = async (apolloparam, transformType, axis, value, support, keepProportions) =>
{
    const apollo = apolloparam;
    const transformRes = await apollo.query({ query: queries.objectTransform });
	const transform = transformRes.data?.transform;

    let floatValue = parseFloat(value ? value : "0.0");

    if (!isFinite(floatValue))
        return;

    if (transformType === "scale" && floatValue === 0)
        floatValue = 0.0001;

    let newVector = {
        __typename: 'Vector3',
        ...transform[transformType]
    };

    if (keepProportions)
    {
        let originalValue = newVector[axis];
        let change = floatValue / originalValue;
        if ((support.scale & AxesSupported.X) !== 0)
            newVector.x *= change;
        if ((support.scale & AxesSupported.Y) !== 0)
            newVector.y *= change;
        if ((support.scale & AxesSupported.Z) !== 0)
            newVector.z *= change;
    }
    else newVector[axis] = floatValue;

    apollo.writeQuery({
        query: queries.objectTransform,
        data: {
            transform: {
                __typename: 'Transform',
                [transformType]: newVector
            }
        }
    });

    postVuplexMessage(`Set Object ${transformType}`, {value: newVector});
};
