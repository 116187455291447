import React, { useState } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import GlueButton from '../common/glue-button';
import { makeStyles } from "@material-ui/core";
import CustomConsent from './custom-consent/custom-consent';
import { domainToConsentFormMap, consentFormContentsMap } from '../../util/custom-consent';
import { useUserContext } from '../../util/user-context';
import { setUserSettings } from '../../service/user-settings';
import { logFirebaseEvent } from '../../service/firebase/firebase';
import OldTutorialVideoPlayer from './old-tutorial-video-player';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		height: '100%',
		background: 'url(https://glue-public-resources.s3.eu-west-1.amazonaws.com/web-images/HomeBG_B.jpg)',
		padding: '32px',
		display: 'flex',
		gap: theme.glueSpacing('m'),
		justifyContent: 'center',
		alignItems: 'end'
	},

}), { name: 'MuiGlueWelcomePage' });

const WelcomeToGlue = (props) => {
	const classes = useStyles();
	const apollo = useApolloClient();
	const [ customConsentOpen, setCustomConsentOpen ] = useState(true);
	const [ tutorialState, setTutorialState ] = useState(null);

	const user = useUserContext();
	
	const domain = user.email && user.email.split('@')[1]

	const consentPages = domainToConsentFormMap[domain] ?? []
	const consentPageContents = consentPages.map(key => consentFormContentsMap[key])

	const onStartTutorial = async (vr) => {
		await logFirebaseEvent('tutorialStarted');
		if (vr) {
			setTutorialState('vr');
		}
		else setTutorialState('desktop');
	}

	const onSkipTutorial = async () => {
		await setUserSettings(apollo, { tutorialStarted: true });
		await logFirebaseEvent('tutorialSkipped');
	}

	const onCompleteTutorial = async () => {
		await setUserSettings(apollo, { tutorialStarted: true });
		await logFirebaseEvent('tutorialCompleted');
	}

	return (
		<>
			{ tutorialState == null ? (
				<div className={classes.root}>
					<GlueButton color='primary' onPointerDown={() => onStartTutorial(true)} >
						Intro video (VR)
					</GlueButton>
					<GlueButton color='primary' onPointerDown={() => onStartTutorial(false)} >
						Intro video (Desktop)
					</GlueButton>
					<GlueButton onPointerDown={onSkipTutorial}>
						Start using Glue
					</GlueButton>
				</div>
			) : tutorialState === 'desktop' ? (
				<OldTutorialVideoPlayer 
					url='https://glue-public-resources.s3.eu-west-1.amazonaws.com/web-images/LoginTutorial_Desktop_202109_720p.mp4' 
					posterUrl='https://glue-public-resources.s3.eu-west-1.amazonaws.com/web-images/GlueTutorial_202106.jpg'
					onCompleteTutorial={onCompleteTutorial}
				/>
			) : (
				<OldTutorialVideoPlayer 
					url='https://glue-public-resources.s3.eu-west-1.amazonaws.com/web-images/Glue+Tutorial+-+Intro+to+Glue_720p.mp4'
					posterUrl='https://glue-public-resources.s3.eu-west-1.amazonaws.com/web-images/GlueTutorial_202106.jpg'
					onCompleteTutorial={onCompleteTutorial}
				/>
			)}
			{consentPages.length > 0 && (
				<CustomConsent 
					open={customConsentOpen} 
					onClose={ () => setCustomConsentOpen(false) } 
					pages={consentPageContents}
				/>
			)}
		</>
	)
}

export default WelcomeToGlue