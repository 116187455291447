export type Type = {
	[variant: string]: {
		size: string
	}
};

export const common: Type =
{
	small: {
		size: '48px',
	},
	medium: {
		size: '48px',
	},
	large: {
		size: '80px'
	},
};

export const web: Type =
{
	...common,

	small: {
		size: '28px',
	},
	medium: {
		size: '36px',
	},
	large: {
		size: '70px'
	},
};

export const tablet: Type =
{
	...common
};
