type RoleNameMap = {
	[id: string]: string
}

export const maxAndroidImageSize = 4096; // Max width and/or height pixel resolution for imported Android images.

export const teamMemberRoleNameMap: RoleNameMap = {
	'guest': "Guest",
	'superuser': "Team Admin",
	'member': "Member"
};

export const orgMemberRoleNameMap: RoleNameMap = {
	'guest': "Guest",
	'admin': "Admin",
	'member': "User"
}

export const helpContentURLBase = "https://glue-collab-asset.s3.eu-west-1.amazonaws.com/helpcontent/";

export const licenseTiers = Object.freeze([
	{ id: 0, name: 'Free' },
	{ id: 1, name: 'Professional' },
	{ id: 2, name: 'Unrestricted'},
	{ id: 3, name: 'Tutorial' }
]);

export const teamAccessType = Object.freeze([
	{ id: 0, name: "Private" },
	{ id: 1, name: "Public" }
])
