import MF1_WomensSuit from './defaultavatars/MF1_WomensSuit.json';
import SM7_Suit from './defaultavatars/SM7_Suit.json';
import YF3_LooseShirt from './defaultavatars/YF3_LooseShirt.json';
import YM1_Hoodie from './defaultavatars/YM1_Hoodie.json';
import { Config } from './avatar-types';

const DefaultAvatars : Config[] = [
	MF1_WomensSuit,
	SM7_Suit,
	YF3_LooseShirt,
	YM1_Hoodie
];

export default DefaultAvatars;