export type Type = {
	buttonSize: string
	buttonRadius: string
	sizes: {
		small: string
		medium: string
		large: string
		extraLarge: string 
	}
}

const common: Type =
{
	buttonSize: '64px',
	buttonRadius: '32px',
	sizes: {
		small: '220px',
		medium: '240px',
		large: '280px',
		extraLarge: '420px'
	}
};

export const web: Type =
{
	...common,

	buttonSize: '36px',
	buttonRadius: '18px',
	sizes: {
		small: '125px',
		medium: '140px',
		large: '180px',
		extraLarge: '240px'
	}
};

export const tablet: Type =
{
	...common
};
