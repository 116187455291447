import React, { useState, useEffect, useRef } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { makeStyles, useTheme, Typography } from '@material-ui/core';
import { useApolloClient } from '@apollo/react-hooks';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import PersonIcon from '@material-ui/icons/Person';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { Close } from '@material-ui/icons';

import queries from '../../graphql/queries';
import mutations from '../../graphql/mutations';
import GlueButton from '../common/glue-button';
import GlueToggle from '../common/glue-toggle';
import PageSelector from '../common/page-selector';
import { getFileType, getHumanizedFileSize } from '../../util/file-utils';
import GlueDropdown from '../common/glue-dropdown';
import GlueInputfield from '../common/glue-inputfield';
import WebInputfield from '../../standalone-web/common/web-inputfield';
import postVuplexMessage from '../../service/message-vuplex';
import { sttActive } from '../../util/speech-utils';

import LoadingIndicator from '../common/loading-indicator';
import GlueListView, { SortDirection, alphabeticalSortFunc, dateSortFunc, simpleSortFunc } from '../common/glue-list-view';
import GlueIcon from '../common/glue-icon';
import FileThumbnail from './file-thumbnail';

import { useUserContext } from '../../util/user-context';
import { usePromptDialogContext } from '../../util/prompt-dialog-context';
import InfoDialogTemplate from '../common/info-dialog-template';

import { multipleFileDownload } from '../../util/file-backend-api-util';
import { downloadZipData, validDownloadExits } from '../../util/get-download-zip';
import AddFileDialog from './add-file-dialog';
import RenameFileDialog from './rename-file-dialog';
import FileDownloadMenuItem from './file-download-menu-item';
import { findSpecializedViewer} from './file-view-switcher';
import { glueDateDisplayFormat } from '../../util/app-utils';
import { InventoryItem, Maybe, MutationRemoveInventoryItemArgs, MutationUpdateInventoryItemArgs, ProcessingStatus, QueryTeamInventoryItemsArgs } from '../../graphql/types-generated';
import { setKeyboardActive } from '../../util/control-util';

const itemsPerPageMax = 48;

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100%',
		width: '100%',
		boxSizing: 'border-box',
		display: 'flex',
		flexFlow: 'column nowrap',
		overflow: 'hidden',
	},

	rootLoading: {
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},

	actions: {
		height: theme.custom.header.height,
		minHeight: theme.custom.header.height,
		display: 'flex',
		flexFlow: 'row nowrap'
	},

	actionsLeft: {
		flex: '1 0 auto',
		display: 'grid',
		gridAutoColumns: 'min-content',
		gridAutoFlow: 'column',
		gap: theme.glueSpacing('m'),
		alignItems: 'center'
	},

	actionsRight: {
		flex: '1 1 auto',
		display: 'flex',
		justifyContent: 'flex-end'
	},

	infoBar: {
		display: 'flex',
		flexDirection: 'row',
		height: '64px',
		width: '100%',
		alignItems: 'center',
		justifyContent: 'center'
	},

	downloadProgress: {
		color: '#FCC21F',
		textAlign: 'center',
	},

	downloadReady: {
		display: 'flex',
		flexDirection: 'row',
		gap: '12px',
		alignItems: 'center',
		textAlign: 'center',
	},

	loadingIndicator: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		minHeight: '600px'
	},

}), { name: 'MuiGlueFiles' });

const filterTypeAll = 'all';

const fileTypeOptions = [
	{ id: filterTypeAll, name: "All files" },
	{ id: 'model', name: 'Models'},
	{ id: 'image', name: 'Images'},
	{ id: 'link', name: 'Links'},
	{ id: 'pdf',  name: "PDF" },
	{ id: 'video',  name: "Videos" },
	{ id: 'other',  name: "Other" }
];

const matchesSearchTerm = (text: string, searchTerm: string) =>
{
	const tokens = searchTerm.split(' ');
	const textNormalized = text.toLowerCase();

	for (const token of tokens)
	{
		if (!textNormalized.includes(token.toLowerCase()))
			return false
	}

	return true;
};

// Team File listing
const Files = (props: {
	linkOnly?: boolean
	web?: boolean
}) =>
{
	const classes = useStyles();
	const user = useUserContext();
	const theme = useTheme();
	const apollo = useApolloClient();
	const { addDialog } = usePromptDialogContext();
	const downloadRef = useRef<HTMLAnchorElement>(null);
	const menuDownloadRef = useRef<HTMLAnchorElement>(null);
	const navigate = useNavigate();

	const teamIdRes = useQuery(queries.currentTeamId);
	const platformResult = useQuery(queries.clientPlatform);
	const clientPlatform = platformResult.data?.clientPlatform;
	const backendInfoRes = useQuery(queries.backendInfo);

	const [deleteItem] = useMutation<{ removeInventoryItem: InventoryItem }, MutationRemoveInventoryItemArgs>(mutations.removeInventoryItem);

	const [ renameItem ] = useMutation<{ updateInventoryItem: InventoryItem }, MutationUpdateInventoryItemArgs>(mutations.updateInventoryItem);

	const [ page, setPage ] = useState(0);
	const [ searchTerm, setSearchTerm ] = useState("");
	const [ myFilesFilter, setMyFilesFilter ] = useState(false);
	const [ typeFilter, setTypeFilter ] = useState(props.linkOnly ? 'link' : filterTypeAll);
	const [ selected, setSelected ] = useState<(string | number)[]>([]);
	const [currentTeam, setCurrentTeam] = useState(null);

	const [ zipData, setZipData ] = useState<Maybe<Partial<ProcessingStatus>>>(null);
	const [ zipState, setZipState ] = useState<Maybe<string>>(null)

	const sttRes = useQuery(queries.speechRecognition);
	const searchSTTSinkId = "FilesAppSearch";

	const tabletOpenResult = useQuery(queries.tabletOpen);

	useEffect(() => {
		setKeyboardActive(false, apollo);
	}, []);

	useEffect(() => {		
		if (tabletOpenResult.data.tabletOpen)
			postVuplexMessage('Enable hotkeys', { value: false });
		else
			postVuplexMessage('Enable hotkeys', { value: true });
		return () => {
			postVuplexMessage('Enable hotkeys', { value: true });
		};
	}, [tabletOpenResult.data.tabletOpen]);

	const downloadItemJSON = localStorage.getItem('download-zip');
	const downloadItem = downloadItemJSON ? JSON.parse(downloadItemJSON) : null;

	if(downloadItem && !zipData)
	{
		if (validDownloadExits('download-zip'))
			setZipData({ result: downloadItem.link })
	}

	useEffect(() => {
		if (currentTeam !== teamIdRes.data)
			// Clear selection if team changes.
			// Otherwise delete dialog counts in other team's files - resulting in misleading dialog.
			setSelected([])
			setCurrentTeam(teamIdRes.data)
		setPage(0);

		return () => {
			setPage(0)
		}
	}, [currentTeam, teamIdRes, setPage]);

	const filesRes = useQuery<{ teamInventoryItems: Maybe<Array<Maybe<InventoryItem>>> }, QueryTeamInventoryItemsArgs>(
		queries.teamInventoryItems,
		{
			skip: !teamIdRes.data?.currentTeamId,
			variables: {
				teamId: teamIdRes.data.currentTeamId
			},
			fetchPolicy: 'cache-and-network',
			errorPolicy: 'all'
		}
	);

	if (filesRes.error)
		console.error("Errors while querying team intentory items", filesRes.error.graphQLErrors);

	if (!teamIdRes.data || !filesRes.data?.teamInventoryItems || !user.email)
		return null;

	const isMatchingType = (type?: string) => {
		if (typeFilter === filterTypeAll) {
			return true;
		}
		else if (type === 'fbx' || type === 'obj' || type === 'gltf' || type === 'glb' || type === 'stl' || type === 'ply' || type === '3mf') {
			return typeFilter === 'model';
		}
		else if (type === 'jpeg' || type === 'png') {
			return typeFilter === 'image';
		}
		else if (type === 'link') {
			return typeFilter === 'link';
		}
		else if (type === 'pdf') {
			return typeFilter === 'pdf';
		}
		else if (type === 'mp4') {
			return typeFilter === 'video';
		}
		else return typeFilter === 'other';
	}

	const userAllowed: string[] = [];
	const filesFiltered: InventoryItem[] = [];

	filesRes.data.teamInventoryItems.forEach((item, i) => {
		// Filter out invalid items. These can appear if the backend data is corrupted.
		if (!item)
		{
			console.warn("Ignoring invalid item. i: " + i);
			return;
		}

		// Gather file types
		const fileType = getFileType(item);

		// Filter my created files
		if (myFilesFilter && item.creator !== user.email)
			return;

		// Filter by type
		if (!isMatchingType(fileType))
			return;

		// Filter by search term
		if (searchTerm && searchTerm.length > 0 && !matchesSearchTerm(item.name, searchTerm))
			return;

		// add file ids for non modification rights
		if (!user.team?.teamFilesEditOthers && item.creator === user.email)
		{
			userAllowed.push(item.inventoryItemId)
		}

		// Passed
		filesFiltered.push(item);
	});

	const pageCount = Math.ceil(filesFiltered.length / itemsPerPageMax);

	const ftaudiomessage = "Menu/TeamFiles/FileType/Press";
	const fthoveraudiomessage = "Menu/TeamFiles/FileType/HL";
	const ftselaudiomessage = "Menu/TeamFiles/FileType/Select/Press";
	const ftselhoveraudiomessage = "Menu/TeamFiles/FileType/Select/HL";

	// Functionality... (Should this be a service??)
	const onStartAddingFile = () =>
	{
		if (props.web)
		{
			addDialog(<AddFileDialog
				refetch={filesRes.refetch}
			/>);
		}
		else
		{
			postVuplexMessage("Start adding file", { value: teamIdRes.data.currentTeamId});
		}
	};

	const openPreview = (data: InventoryItem) => {
		apollo.writeQuery({
			query: queries.teamFilePreview,
			data: { teamFilePreview: data.inventoryItemId }
		});
	};

	const openPopupWindow = (url: string) =>
	{
		const popupWindow = window.open(url);
		popupWindow?.focus();
	};

	const onClickFile = async (data: InventoryItem) => {
		if (data.itemType === 'link')
		{
			if (props.web)
			{
				openPopupWindow(data.itemUrl);
			}
			else
			{
				navigate('/app/web-control', { state: { appname: 'Browser', appurl: data.itemUrl, isProdTool: false } });
			}
		}
		else
		{
			const signedURLRes = await apollo.query({
				query: queries.signInventoryItemGet,
				variables: { inventoryItemId: data.inventoryItemId }
			});

			if (!signedURLRes.data)
			{
				console.error("Getting a signed URL for the item failed", signedURLRes);
				return;
			}

			if (props.web)
			{
				openPopupWindow(signedURLRes.data.signInventoryItemGet.itemURL);
			}
			else
			{
				openPreview(data);
			}
		}
	};

	///// EDIT //////
	const onClickRename = (data: InventoryItem, name: string, visibility: string) =>
	{
		const fileRename = (name !== null) ? name : data.name;
		const changeVisibility = visibility ? visibility : data.permission;

		if ((fileRename !== data.name) || (changeVisibility !== data.permission))
		{
			renameItem({
				variables: {
					inventoryItemId: data.inventoryItemId,
					name: fileRename,
					permission: changeVisibility
				},
				refetchQueries: [{
					query: queries.teamInventoryItems,
					variables: {
						teamId: teamIdRes.data.currentTeamId,
					},
					fetchPolicy: "network-only"
				}],
			});
		}
		else
		{
			console.log("Skip rename");
		}
	};

	const showRenameDialog = (data: InventoryItem) => {
		addDialog(<RenameFileDialog filename={data.name} visibility={data.permission} onSave={(name: string, vis: string) => onClickRename(data, name, vis)}/>);
	}

	///// DELETE //////
	const showDeleteDialog = (data: InventoryItem) => {
		addDialog(<InfoDialogTemplate
			header={'Delete'}
			message={'Are you sure you want to delete this file?'}
			callbacks={[
				{ callback: () => null, label: 'Cancel' },
				{ callback: () => deleteFiles([data]), color: "destructive", label: 'Delete' }
			]}
		/>);
	}

	const deleteTeamFile = async (itemId: string) => {
		const deletion = await deleteItem({
			variables: {
				inventoryItemId: itemId,
			}
		})
		if(deletion.data)
		{
			setSelected(selected.filter(item => item !== deletion.data?.removeInventoryItem.inventoryItemId))
		}
		return deletion;
	}

	const deleteFiles = async(filesToDelete: InventoryItem[]) => 
	{
		if (filesToDelete.length <= 0) return;

		let errors = 0;
		let success = 0;
		const arr: string[] = [];

		const awaitingDelete = filesToDelete.map(item => deleteTeamFile(item.inventoryItemId).then(res => {
			if (!res.data?.removeInventoryItem.inventoryItemId) 
			{
				console.log("Error occurred")
				errors++;
			}
			else 
			{
				arr.push(res.data.removeInventoryItem.inventoryItemId)
				success++;
			}
		}).catch(err => {
			console.error(err);
			errors++;
		}));

		Promise.all(awaitingDelete).then(() => {
			if(success === awaitingDelete.length)
			{
				const multifile = awaitingDelete.length > 1 ? 'files were' : 'file was';
				addDialog(<InfoDialogTemplate 
					header={'Success!'}
					message={`${success} ${multifile} deleted.`}
				/>);
			}
			else if(errors > 0 && success > 0)
			{
				const errorString = errors > 1 ? 'files' : 'file';
				const successString = success > 1 ? 'files were' : 'file was';
				addDialog(<InfoDialogTemplate
					isError={true}
					message={`${errors} ${errorString} could not be deleted. ${success} ${successString} deleted.`}
				/>);
			}
			else
			{
				addDialog(<InfoDialogTemplate
					isError={true}
					message={"Something went wrong when deleting the files. Please try again."}
				/>);
			}
			const remainingSelection = selected.filter((el) => {
				return !arr.includes(el.toString());
			})
			setSelected(remainingSelection)
			filesRes.refetch();
		}).catch(e => {
			console.error(e)
			addDialog(<InfoDialogTemplate
				isError={true}
				message={"Something went wrong when deleting the files. Please try again."}
			/>);
		})
	}

	const onConfirmDelete = (filesToDelete: InventoryItem[]) => {
		deleteFiles(filesToDelete);
	}

	const confirmDelete = () => {
		const filesForDeletion = filesFiltered.filter(item => selected.includes(item.inventoryItemId))
		if (!user.team?.teamFilesEditOthers && !selected.some(item => userAllowed.includes(item.toString())))
		{
			addDialog(<InfoDialogTemplate
				header={'Delete'}
				message={'None of the selected files can be deleted. Only a Team Admin may delete files uploaded by others.'}
			/>);
		}
		else if (!user.team?.teamFilesEditOthers && selected.some(item => !userAllowed.includes(item.toString())))
		{
			const allowed = filesForDeletion.filter(item => userAllowed.includes(item.inventoryItemId));
			const notAllowed = selected.length - allowed.length;
			const selectionMessage = allowed.length > 1 ? `these ${allowed.length} files` : `this file`;
			const forbiddenFilesString = notAllowed > 1 ? `${notAllowed} files` : `${notAllowed} file`;
			addDialog(<InfoDialogTemplate
				header={'Delete'}
				message={`Are you sure you want to delete ${selectionMessage}? ${forbiddenFilesString} cannot be deleted. Only a Team Admin may delete files uploaded by others.`}
				callbacks={[
					{ callback: () => null, label: 'Cancel' },
					{ callback: () => onConfirmDelete(allowed), color: "destructive", label: 'Delete' }
				]}
			/>);
		}
		else
		{
			const selectionMessage = selected.length > 1 ? `these ${selected.length} files` : `this file`;
			addDialog(<InfoDialogTemplate
				header={'Delete'}
				message={`Are you sure you want to delete ${selectionMessage}?`}
				callbacks={[
					{ callback: () => null, label: 'Cancel' },
					{ callback: () => onConfirmDelete(filesForDeletion), color: "destructive", label: 'Delete' }
				]}
			/>);
		}
	}

	///// DOWNLOAD (WEB ONLY) //////
	const downloadBatch = async () =>
	{
		const filesToDownload = filesFiltered.filter(file => selected.includes(file.inventoryItemId));
		const urls = filesToDownload.map(item => item.itemUrl);
		const names = filesToDownload.map(item => item.name);
		
		const packageRes = await multipleFileDownload(urls, names, teamIdRes.data.currentTeamId, backendInfoRes.data)

		const getDownload = (packageRes: string) => {

			const callIntervall = setInterval(async () => {
				const link = await downloadZipData(packageRes, apollo)

				if (link?.statuscode === "Ready") {
					const expires = moment().add(30, 'minutes');

					const obj = {
						link: link.result,
						expires: expires
					}
					//Todo! File selections should be handeled better in the future. For now we just clear the checked files when the download is ready.
					setSelected([])
					localStorage.setItem('download-zip', JSON.stringify(obj))
					setZipData(link)
					setZipState(null)
					clearInterval(callIntervall);
				}
				else if (link?.statuscode === 'Failed') {
					addDialog(<InfoDialogTemplate
						isError={true}
						message={'An error occurred. Please try again.'}
					/>);
					setZipState(null)
					clearInterval(callIntervall);
				}
				else if (link?.progress)
				{
					setZipState(link.progress)
				}
			}, 500)
		}

		getDownload(packageRes);
	}

	const downloadZip = () =>
	{
		downloadRef.current?.click()
		clearDownload();
	}

	const clearDownload = () =>
	{
		localStorage.removeItem('download-zip');
		setZipData(null);
	}

	///// IMPORT (TABLET ONLY) /////
	const isImportable = (data: InventoryItem) => {
		if (props.web)
		{
			return data.itemType === 'link' ? false : true;
		}
		else
		{
			const viewer = findSpecializedViewer(data);
			return !!viewer && !!viewer.importFile;
		}
	}

	///// END FUNCTIONALITY /////

	const ownerSortFunc = (a: InventoryItem, b: InventoryItem, key: keyof InventoryItem, dir: SortDirection) =>
	{
		return a.creator === user.email ? -dir : dir
	}

	const menuDisabledFunc = (item: InventoryItem) => 
	{
		if (user.team?.teamFilesEditOthers)
			return false;
		if (item.creator === user.email)
			return false;
		return true;
	}

	const getThumbnailContent = (item: InventoryItem, key: keyof InventoryItem) =>
	{
		return (
			<FileThumbnail item={item}/>
		);
	}

	const urlContentFunc = (item: InventoryItem, key: keyof InventoryItem) =>
	{
		return (
		<Typography color={'primary'}>
			{item[key]}
		</Typography>
		);
	}

	const columns = [
		{key: 'thumbnailUrl' as const, width: '64px', contentFunc: getThumbnailContent, noSorting: true},
		{key: 'name' as const, label: 'file name', width: '1fr', contentFunc: undefined, sortFunc: alphabeticalSortFunc},
		{key: 'itemType' as const, label: 'type', width: '96px', contentFunc: (item: InventoryItem) => getFileType(item), sortFunc: simpleSortFunc},
		{key: 'fileSize' as const, label: 'size', width: '96px', contentFunc: (item: InventoryItem) => getHumanizedFileSize(item.fileSize ?? NaN), sortFunc: simpleSortFunc},
		{key: 'createdDate' as const, label: 'created', width: '200px', contentFunc: (item: InventoryItem, key: keyof InventoryItem) => glueDateDisplayFormat(item[key] as Maybe<string>), sortFunc: dateSortFunc},
		{key: 'creator' as const, icon: (<PersonIcon/>), width: '128px', contentFunc: (item: InventoryItem) => (item.creator === user.email) && (<GlueIcon><PersonIcon/></GlueIcon>), sortFunc: ownerSortFunc},
	];

	const linkOnlyColumns = [
		{key: 'name' as const, label: 'name', width: '1fr', contentFunc: undefined, sortFunc: alphabeticalSortFunc},
		{key: 'itemUrl' as const, label: 'url', width: '1fr', contentFunc: urlContentFunc, sortFunc: alphabeticalSortFunc},
		{key: 'creator' as const, icon: (<PersonIcon/>), width: '128px', contentFunc: (item: InventoryItem) => (item.creator === user.email) && (<GlueIcon><PersonIcon/></GlueIcon>), sortFunc: ownerSortFunc},
	]

	const downloadContent = (item: InventoryItem) => [
		props.web ? "Download" : "Import into space",
		<FileDownloadMenuItem web={props.web} key={'icon'} item={item} downloadRef={menuDownloadRef}/>
	];

	const menuOptions = [
		{key: 'download', content:  downloadContent, callback: () => menuDownloadRef.current?.click(), disabled: (item: InventoryItem) => !isImportable(item)},
		{key: 'edit', content: ["Edit", <CreateIcon key={'icon'}/>], callback: (item: InventoryItem) => showRenameDialog(item)},
		{key: 'delete', content: ["Delete", <DeleteIcon key={'icon'}/>], callback: (item: InventoryItem) => showDeleteDialog(item)},
	];

	const isLoading = filesRes.loading;

	return (
		<div className={classes.root}>
			{props.web && (
				<div className={classes.infoBar}>
					{zipData && <div className={classes.downloadReady}>Your files are ready
						<GlueButton
							color='primary'
							onPointerDown={() => downloadZip()}
						>
							<a role='button' tabIndex={0} style={{ display: 'none' }} ref={downloadRef} href={zipData.result ?? '#'} target='_blank' rel='noopener noreferrer' download ><div /></a>
							Download
						</GlueButton>
						<GlueButton
							variant={'icon'}
							color={'stealth'}
							onPointerDown={() => clearDownload()}
						>
							<Close/>
						</GlueButton>
					</div>}
					{zipState && <div className={classes.downloadProgress}>Preparing files for download {zipState}%</div>}
				</div>
			)}
			<div className={classes.actions}>
				<div className={classes.actionsLeft}>
					{clientPlatform?.OS !== "Android" && !props.linkOnly && (
						<GlueButton
							color="primary"
							onPointerDown={onStartAddingFile}
							uiAudioMessage="Menu/TeamFiles/Add/Press"
							uiHoverAudioMessage="Menu/TeamFiles/Add/HL"
						>
							Upload a file
						</GlueButton>
					)}
					
					{props.web ? (
						<WebInputfield
							width={theme.custom.files.inputWidth}
							search 
							value={searchTerm}
							onClear={()=>{ setSearchTerm("") }}
							onChange={(value: string) => {setSearchTerm(value); setPage(0)}}
							showClearButton={searchTerm ? true : false}
						/>
					) : (
						<GlueInputfield
							width={theme.custom.files.inputWidth}
							search 
							value={searchTerm}
							sinkId={searchSTTSinkId} 
							speechInput={sttActive(sttRes, searchSTTSinkId)}
							onClear={()=>{ setSearchTerm("") }}
							onChange={(value: string) => {setSearchTerm(value); setPage(0)}}
							onSpeechChange={(value: string)=>{ setSearchTerm(value) }}
							showClearButton={searchTerm ? true : false}
							margin={"0px 0px 0px 0px"}
						/>
					)}

					<GlueToggle
						toggled={myFilesFilter}
						onPointerDown={() => {setMyFilesFilter((value) => !value); setPage(0)}}
						uiAudioMessage={myFilesFilter ? "Menu/TeamFiles/MyFiles/Toggle/Off" : "Menu/TeamFiles/MyFiles/Toggle/On"}
						uiHoverAudioMessage="Menu/TeamFiles/MyFiles/HL" 
					>
						<PersonIcon />
					</GlueToggle>

					{!props.linkOnly && (
						<GlueDropdown
							width={theme.custom.files.dropdownWidth} 
							defaultValue={'all'}
							onChange={(id, checked) => {checked && setTypeFilter(id.toString()); setPage(0)}}
							items={fileTypeOptions}
							uiAudioMessage = {ftaudiomessage}
							uiHoverAudioMessage = {fthoveraudiomessage} 
							uiSelAudioMessage = {ftselaudiomessage}
							uiSelHoverAudioMessage = {ftselhoveraudiomessage} 
						/>
					)}

					{selected.length > 0 && (
						<>
							<GlueButton
								variant={'icon'}
								onPointerDown={() => confirmDelete()}
							>
								<DeleteIcon/>
							</GlueButton>

							{props.web && !zipData && !zipState && !props.linkOnly && (
								<GlueButton
									variant={'icon'}
									onPointerDown={() => downloadBatch()}
								>
									<CloudDownloadIcon/>
								</GlueButton>
							)}
						</>
					)}

				</div>

				<div className={classes.actionsRight}>
					<PageSelector
						current={page}
						pageCount={pageCount}
						onPageChange={(pageNew) => setPage(pageNew)}
					/>
				</div>
			</div>
			{isLoading ? (
				<div className={classes.loadingIndicator}>
					<LoadingIndicator />
				</div>
				) : (
					<GlueListView
						web={props.web}
						items={filesFiltered}
						overrideIdKey={'inventoryItemId'}
						columns={props.linkOnly ? linkOnlyColumns : columns}
						start={page * itemsPerPageMax}
						count={itemsPerPageMax}
						menuOptions={menuOptions}
						selected={selected}
						onChangeSelection={setSelected}
						menuDisabledFunc={menuDisabledFunc}
						onClickItem={onClickFile}
						defaultSortKey='createdDate'
					/>
				)}
		</div>
	);
};

export default Files;
