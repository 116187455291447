import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';

require('pdfjs-dist/webpack'); // Initializes the global state of the PDF.js library
import * as pdfjsLib from 'pdfjs-dist';
import { makeStyles } from '@material-ui/core';

const DOCUMENT_LOAD_RETRIES_COUNT_MAX = 5;

type PDFViewerProps = {
	url: URL,
	pageClass: string,
	// Zero based page index
	pageNumber: number,
	onSuccess(pageCount: number): void,
	onError(msg: string): void,
	onRender?(): void
};

type LoadState = {
	docURL: URL | null,
	docLoadTask: pdfjsLib.PDFDocumentLoadingTask | null,
	renderTask: pdfjsLib.RenderTask | null,
	doc: pdfjsLib.PDFDocumentProxy | null,
	page: pdfjsLib.PDFPageProxy | null,
};

type Rectangle = {
	width: number,
	height: number,
};

const useStyles = makeStyles(theme => ({
	root: {
		overflow: 'hidden',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},

	document: {
		display: 'block'
	}
}));

/**
 * @param {*} limitBox The outer box which defines the available space
 * @param {*} box The box to be scaled to fit inside the limitBox
 * @returns {number} Scale factor for box to fit inside limitBox
 */
const fitScale = (limitBox: Rectangle, box: Rectangle) =>
	Math.min(limitBox.width / box.width, limitBox.height / box.height);

const PdfViewer = (props: PDFViewerProps, ref: React.Ref<HTMLDivElement>) =>
{
	const classes = useStyles();

	const canvasRef = useRef<HTMLCanvasElement>(null);
	const containerRef = useRef<HTMLDivElement>(null);

	const pdfState = useRef<LoadState>({
		docURL: null,
		docLoadTask: null,
		renderTask: null,
		doc: null,
		page: null,
	});

	const fetchPdf = async (url: URL) =>
	{
		console.log("Load new PDF document", url.href);

		for (let retryIndex = 0; retryIndex < DOCUMENT_LOAD_RETRIES_COUNT_MAX; ++retryIndex)
		{
			if (retryIndex > 0)
			{
				console.warn("Retrying document load. Retry count: " + retryIndex);
			}

			await pdfState.current.docLoadTask?.destroy();
			pdfState.current.docLoadTask = null;
			await pdfState.current.doc?.destroy();
			pdfState.current.doc = null;
			pdfState.current.docURL = null;

			pdfState.current.docLoadTask = pdfjsLib.getDocument(url);
			pdfState.current.doc = await pdfState.current.docLoadTask.promise.catch(e => {
				console.error("Document loading error", e);
				props.onError?.(e)
				return null;
			});

			if (pdfState.current.doc)
				break;
		}

		if (!pdfState.current.doc)
		{
			throw new Error("Document loading failed");
		}

		console.log("Document loaded successfully");

		pdfState.current.docURL = url;
		props.onSuccess?.(pdfState.current.doc.numPages);
	};

	const renderPage = async (pageNumber: number) =>
	{
		console.log("Render new page", pageNumber, pdfState.current.renderTask);

		pdfState.current.renderTask?.cancel();
		pdfState.current.page?.cleanup();
		pdfState.current.page = null;

		if (!pdfState.current.doc)
			throw new Error("Document is not ready");

		pdfState.current.page = await pdfState.current.doc.getPage(pageNumber + 1)
		.catch(e => {
			console.error("Page loading failed", e);
			return null;
		});

		if (!pdfState.current.page)
			throw new Error("Page loading failed");

		const spaceAvailable = {
			width: containerRef.current?.offsetWidth ?? 0,
			height: containerRef.current?.offsetHeight ?? 0
		};

		// Page dimensions
		const pageBaseVP = pdfState.current.page.getViewport({ scale: 1 });
		// Page fitted to available space and matched to display device pixel density
		const renderVP = pdfState.current.page.getViewport({ scale: fitScale(spaceAvailable, pageBaseVP) * window.devicePixelRatio });
		
		if (!canvasRef.current)
			throw new Error("Canvas not ready yet");

		canvasRef.current.height = renderVP.height;
		canvasRef.current.width = renderVP.width;

		const canvasContext = canvasRef.current.getContext('2d');

		if (!canvasContext)
			throw new Error("Canvas context creation failed");

		pdfState.current.renderTask?.cancel();
		pdfState.current.renderTask = pdfState.current.page.render({
			canvasContext: canvasContext,
			viewport: renderVP,
		});

		await pdfState.current.renderTask.promise
		.then(() => {
			if (props.onRender) {
				props.onRender()
			}
		})
		.catch(e => {
			if (e.name === 'RenderingCancelledException')
			{
				console.log("PDF page rendering canceled");
			}
			else
			{
				console.error("PDF page rendering failed", e);
			}
		});
	};

	useEffect(() =>
	{
		const update = async () =>
		{
			if (!canvasRef.current || !props.url)
				return; // Can't do much without this

			if (props.url.href !== pdfState.current.docURL?.href)
			{
				await fetchPdf(props.url);
			}

			await renderPage(props.pageNumber);
		};

		update().catch(e => {
			console.error("PDF update failed", e);
		});
	}, [props.url, props.pageNumber, canvasRef.current]);

	return (
		<div className={clsx(classes.root, props.pageClass)} ref={containerRef}>
			<canvas className={classes.document} ref={canvasRef} />
		</div>
	);
}

export default PdfViewer;