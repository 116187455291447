import React, { useContext, useState } from 'react';
import { useApolloClient } from '@apollo/react-hooks';

import PromptDialog from '../standalone-web/common/prompt-dialog';
import queries from '../graphql/queries';

export type TDialogContext = {
	addDialog: (object: React.ReactNode) => void
	closePromptDialog: () => void
}

const defaultValue: TDialogContext = {
	addDialog: (object) => null,
	closePromptDialog: () => null
};
export const DialogContext: React.Context<TDialogContext> = React.createContext(defaultValue);

/**
 * @property {DialogContext} 
 * @returns {Object} { addDialog, closePromptDialog } functions
 */
export const usePromptDialogContext = (): TDialogContext =>
{
	return useContext(DialogContext);
}

const dialogs: React.ReactNode[] = [];

const getNext = (): React.ReactNode => {
	return dialogs.shift();
};

let isOpen = false;

const PromptDialogProvider = (props: {
	children: React.ReactNode
}) =>
{
	const apollo = useApolloClient();
	const [ currentDialog, setCurrentDialog ] = useState<React.ReactNode>(null)

	const openConfirmationDialog = () => 
	{
		isOpen = true;
		apollo.writeQuery({
			query: queries.promptDialogOpen,
			data: { promptDialogOpen: true }
		});
	}

	const addDialog = (object: React.ReactNode) =>
	{
		if (!object) {
			console.error("Dialog is missing context.");
			return;
		}

		dialogs.push(object)

		if (!isOpen) {
			setCurrentDialog(getNext());
			openConfirmationDialog();
		}
	}

	const closePromptDialog = () =>
	{
		if (dialogs.length > 0)
		{
			setCurrentDialog(getNext())
		}
		else 
		{
			isOpen = false;

			apollo.writeQuery({
				query: queries.promptDialogOpen,
				data: { promptDialogOpen: false }
			});
		}
	}

	return (
		<DialogContext.Provider value={{ addDialog, closePromptDialog }}>
			<PromptDialog currentDialog={currentDialog} />
			{props.children}
		</DialogContext.Provider>
	)
}

export default PromptDialogProvider;
