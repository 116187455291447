import { CSSProperties } from "@material-ui/core/styles/withStyles";

export type Type = CSSProperties;

const common : Type = {
    width: '100%'
};

export const web: Type = {
    ...common,
    
    '@media (min-width:1440px)': {
		width: '1280px'
	},

	'@media (min-width:1280px) and (max-width:1440px)': {
		width: '1060px'
	},

	'@media (min-width:1024px) and (max-width:1280px)': {
		width: '840px'
	},

	'@media (max-width:1024px)': {
		width: '620px'
	},
};

export const tablet: Type = {
    ...common
};