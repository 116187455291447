import * as THREE from 'three';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import { DRACOLoader} from "three/examples/jsm/loaders/DRACOLoader"

import AvatarFeatures from './avatarfeatures';
import { findMostSuitableVariant } from '../../service/avatar-configurator';

// Shared caches
const sharedTextureCache = {};
const baseMaterialCache = {};

// Loaders
const gltfLoader = new GLTFLoader();
// Optional: Provide a DRACOLoader instance to decode compressed mesh data
const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath( '/examples/js/libs/draco/' );
gltfLoader.setDRACOLoader( dracoLoader );

const texLoader = new THREE.TextureLoader();

const activeViews = [];
const bindPose = {};
let cubeMap = null;

const disposeMaterial = (material) =>
{
	if (!(material instanceof THREE.Material))
	{
		return;
	}

	for (const value of Object.values(material))
	{
		if (value instanceof THREE.Texture)
		{
			value.dispose();
		}
	}
	if (material.uniforms)
	{
		for (const value of Object.values(material.uniforms))
		{
			if (value)
			{
				const uniformValue = value.value;
				if (Array.isArray(uniformValue))
				{
					uniformValue.forEach(v => {
						if (v instanceof THREE.Texture)
						{
							v.dispose();
						}
					});
				}
				else if (uniformValue instanceof THREE.Texture)
				{
					uniformValue.dispose();
				}
			}
		}
	}

	material.dispose();
}


const loadAsset = async (asset, variantName, colors, sharedUniforms, modelCache) => {
	const loadMaterials = async (materials) =>
	{
		const loadMaterial = async (material) =>
		{
			const setUniforms = (material, instance, sharedUniforms) =>
			{
				if (!instance)
					return;

				// Set colors
				if (material.Colors)
				{
					instance.glueOSMetadata = {};
					instance.glueOSMetadata.ColorOptions = [];

					material.Colors.forEach(color => {
						let colorHexValue = '#7F7F7F'; // Default color = grey
						let colorId = asset.ColorOptions[color.ColorIndex].Id;
						if (colorId)
						{
							const colorInfo = colors.find(c => c.Id === colorId);
							if (colorInfo)
							{
								colorHexValue = colorInfo.ColorValue;
							}
						}

						let colorValue = new THREE.Color(colorHexValue);
						instance.uniforms[color.Uniform] = { value: colorValue };

						// Save some metadata
						instance.glueOSMetadata.ColorOptions.push({ Id: colorId, Uniform: color.Uniform, ColorValue: colorHexValue });
					});
				}

				// Set textures
				if (material.Textures)
				{
					material.Textures.forEach(texture => {
						// If texture doesn't exist, load it
						if (!sharedTextureCache[texture.TextureUrl])
						{
							sharedTextureCache[texture.TextureUrl] = texLoader.load(texture.TextureUrl);
							sharedTextureCache[texture.TextureUrl].encoding = THREE.sRGBEncoding;
						}

						instance.uniforms[texture.Uniform] = { value: sharedTextureCache[texture.TextureUrl] };
					});
				}

				// Set constant values
				for (let u in sharedUniforms)
				{
					instance.uniforms[u] = sharedUniforms[u];
				}
			};

			const getMaterialInstance = async (material) =>
			{
				// Base material already exists
				if (material.ShaderUrl)
				{
					if (baseMaterialCache[material.ShaderUrl])
					{
						return baseMaterialCache[material.ShaderUrl].clone();
					}
					else // Create new base material
					{
						const shaderObj = await fetch(material.ShaderUrl).then(response => response.json());
						const newMaterial = new THREE.ShaderMaterial({
							uniforms: shaderObj.Uniforms,
							defines: shaderObj.Defines,
							transparent: shaderObj.Transparent,
							vertexShader: shaderObj.Vert,
							fragmentShader: shaderObj.Frag
						});
						baseMaterialCache[material.ShaderUrl] = newMaterial;
						return newMaterial.clone();
					}
				}
				else
				{
					return null;
				}
			};

			const instance = await getMaterialInstance(material);
			setUniforms(material, instance, sharedUniforms);
			return instance;
		};

		if (materials)
		{
			const materialInstances = await Promise.all(materials.map(mat => loadMaterial(mat)));
			return materialInstances;
		}
		else return null;
	};

	const prepareLoadedAsset = (scene) =>
	{
		scene.traverse( child => {
			if ( child.isMesh ) {
				
				child.frustumCulled = false;

				// Clean up textures and materials
				if (child.material)
				{
					if (Array.isArray(child.material))
					{
						child.material.forEach(mat => {
							disposeMaterial(mat);
						});
					}
					else
					{
						disposeMaterial(child.material);
					}
				}

				// Record bones
				if ( child.skeleton )
				{
					child.skeleton.bones.forEach(bone => {
						if (!bindPose[bone.name])
						{
							bindPose[bone.name] = {
								position: bone.position.clone(),
								rotation: bone.quaternion.clone(),
								scale: bone.scale.clone()
							};
						}
					});
				}
			}
		});
	}

	const assignMaterials = (scene, materialInstances) =>
	{
		scene.traverse( child => {
			if ( child.isMesh ) {
				let rendererInfo = asset.Renderers.find(r => child.name.includes(r.RendererName));
				if (rendererInfo)
				{
					let submeshIndex = 0;
					if (rendererInfo.RendererName !== child.name && rendererInfo.RendererName)
					{
						submeshIndex = parseInt(child.name.substring(rendererInfo.RendererName.length +1))
					}

					let materialIndex = rendererInfo.SubmeshMaterialIndices[submeshIndex];
					// console.log("MATERIAL INDEX = " + materialIndex);

					const mat = materialInstances[materialIndex];
					if (mat)
					{
						child.material = mat;
						child.glueOSMetadata = mat.glueOSMetadata;
					}

					// Hide everything but the head
					if (rendererInfo.BodyPartIndex !== 1)
					{
						child.visible = false;
					}
				}

				if ( child.skeleton )
				{
					child.material.skinning = true;
				}

				if ( child.morphTargetDictionary )
				{
					child.material.morphTargets = true;
				}
			}
		});
	}

	const loadModel = async (modelUrl, modelCache, materialInstances) =>
	{
		if (!modelUrl)
		{
			return null;
		}

		if (modelCache[modelUrl])
		{
			const asset = modelCache[modelUrl];
			assignMaterials(asset, materialInstances);
			return asset;
		}
		else
		{
			return new Promise(resolve => {
				gltfLoader.load( modelUrl, gltf => {
					const asset = gltf.scene;
					prepareLoadedAsset(asset);
					modelCache[modelUrl] = asset;
	
					assignMaterials(asset, materialInstances);
					resolve(asset);
				});
			});
		}
	};

	if (!asset.ModelUrl || !asset.Materials)
	{
		return null;
	}

	const materialInstances = await loadMaterials(asset.Materials);
	let url = asset.ModelUrl;
	if (variantName && variantName !== "None")
	{
		if (variantName === "__Disabled")
		{
			url = null;
		}
		else
		{
			const foundVariant = asset.Variants.find(variant => variant.Name === variantName);
			url = foundVariant ? foundVariant.ModelUrl : url;
		}
	}
	const loadedScene = await loadModel(url, modelCache, materialInstances);

	return loadedScene;
}

const applyAssetMutationAsync = async (view, assetMutation, colors, shapeJoints, blendShapes) => {
	if (!view)
		return false;

	const type = assetMutation.Type;
	const variantName = assetMutation.VariantName;
	let assetJson = AvatarFeatures[type].Assets.find(a => a.Prefab === assetMutation.AssetName);

	if (assetJson)
	{
		view.setLoading(true);

		if (assetJson.AssetName !== "None" && assetJson.ModelUrl)
		{
			const loadedAsset = await loadAsset(assetJson, variantName, colors, view.sharedUniforms, view.modelCache);

			const existingAsset = view.assetsInScene[type];
			if (existingAsset)
			{
				view.scene.remove(existingAsset);
			}

			if (loadedAsset)
			{
				view.scene.add(loadedAsset);
				view.assetsInScene[type] = loadedAsset;
				setShape(view.assetsInScene[type], shapeJoints, blendShapes);
			}
		}
		else
		{
			const existingAsset = view.assetsInScene[type];
			if (existingAsset)
			{
				view.scene.remove(existingAsset);
			}
		}

		view.setLoading(false);
		view.render();
		return true;
	}

	return false;
}

export const applyAssetMutation = async (view, assetMutation, colors, shapeJoints, blendShapes) => {
	if (!view)
		return;

	if (view.currentAsyncOperation)
	{
		view.currentAsyncOperation.then(() => {
			view.currentAsyncOperation = applyAssetMutationAsync(view, assetMutation, colors, shapeJoints, blendShapes);
		});
	}
	else
	{
		view.currentAsyncOperation = applyAssetMutationAsync(view, assetMutation, colors, shapeJoints, blendShapes);
	}
}

const setShape = (scene, newShape, blendShapes) => {
	let processedBones = {};
	scene.traverse(child => {
		if (child.isMesh && child.skeleton)
		{
			child.skeleton.bones.forEach((bone, index) => {
				if (processedBones[bone.name])
					return;
				
				if (!bone.name.includes("Shape"))
					return;

				let shapeJoint = newShape.find(joint => joint.JointName === bone.name);
				if ( shapeJoint )
				{
					const bindBone = bindPose[bone.name];

					// Return to bind pose
					let position = bindBone.position.clone();
					let rotation = bindBone.rotation.clone();
					let scale = bindBone.scale.clone();

					// Apply new pose
					const deltaPos = new THREE.Vector3(-shapeJoint.DeltaPosition.x, shapeJoint.DeltaPosition.y, shapeJoint.DeltaPosition.z);
					deltaPos.multiplyScalar(100);
					position.add(deltaPos);
					child.skeleton.bones[index].position.set(position.x, position.y, position.z);
					
					child.skeleton.bones[index].quaternion.set(rotation.x, rotation.y, rotation.z, rotation.w);
					const deltaRotation = new THREE.Quaternion(shapeJoint.DeltaRotation.x, shapeJoint.DeltaRotation.y, shapeJoint.DeltaRotation.z, shapeJoint.DeltaRotation.w);
					let deltaEuler = new THREE.Euler().setFromQuaternion(bone.name.includes("_R_") ? deltaRotation.conjugate() : deltaRotation);
					child.skeleton.bones[index].rotateX(deltaEuler.z);
					child.skeleton.bones[index].rotateY(deltaEuler.y);
					child.skeleton.bones[index].rotateZ(deltaEuler.x);
				
					const deltaScale = new THREE.Vector3(shapeJoint.DeltaScale.x, shapeJoint.DeltaScale.y, shapeJoint.DeltaScale.z)
					scale.add(deltaScale);
					child.skeleton.bones[index].scale.set(scale.x, scale.y, scale.z);
				}
				else
				{
					const bindBone = bindPose[bone.name];

					// Return to bind pose
					let position = bindBone.position.clone();
					let rotation = bindBone.rotation.clone();
					let scale = bindBone.scale.clone();

					child.skeleton.bones[index].position.set(position.x, position.y, position.z);
					child.skeleton.bones[index].quaternion.set(rotation.x, rotation.y, rotation.z, rotation.w);
					child.skeleton.bones[index].scale.set(scale.x, scale.y, scale.z);
				}

				processedBones[bone.name] = true;
			});
	
			if ( child.morphTargetDictionary && blendShapes )
			{
				let allWeightsAreZero = true;
				for (let key in child.morphTargetDictionary)
				{
					let index = child.morphTargetDictionary[key];
					let bShape = blendShapes.find(s => s.BlendShapeName && s.BlendShapeName === key);
					let weight = bShape ? ( bShape.Weight / 100 ) : 0;
					child.morphTargetInfluences[index] = weight;

					if (weight !== 0)
					{
						allWeightsAreZero = false;
					}
				}
				// Dumb hack, maybe it's my shader's issue or three.js shits itself if there are morph targets but all the weights are 0
				child.material.morphTargets = !allWeightsAreZero;
			}
		}   
	});
};

export const applyShapeMutation = (view, shapeMutation) => {
	if (!view)
		return;

	if (view.currentAsyncOperation)
	{
		view.currentAsyncOperation.then(() => {
			for (let type in view.assetsInScene)
			{
				setShape(view.assetsInScene[type], shapeMutation.ShapeJoints, shapeMutation.BlendShapes);
			}
		});
	}
	else
	{
		for (let type in view.assetsInScene)
		{
			setShape(view.assetsInScene[type], shapeMutation.ShapeJoints, shapeMutation.BlendShapes);
		}
	}
}


const mutateColor = (view, colorMutation) => {
	const scene = view.scene;
	scene.traverse(child => {
		if (child.isMesh && child.skeleton)
		{
			if (child.glueOSMetadata && child.glueOSMetadata.ColorOptions)
			{
				let colorOption = child.glueOSMetadata.ColorOptions.find(c => c.Id === colorMutation.Id);
				if (colorOption)
				{
					let newColor = new THREE.Color(colorMutation.ColorValue);
					child.material.uniforms[colorOption.Uniform].value = newColor;
				}
			}
		}
	});
	view.render();
}

export const applyColorMutation = (view, colorMutation) => {
	if (!view)
		return;

	if (view.currentAsyncOperation)
	{
		view.currentAsyncOperation.then(() => {
			mutateColor(view, colorMutation);
		});
	}
	else mutateColor(view, colorMutation);
}

const loadAvatarAsync = async (view, avatar) => {
	if (view && avatar)
	{
		view.setLoading(true);

		const colors = avatar.Colors;
		const base = avatar.Base;
		const assets = avatar.Assets;
		const variantOverrides = avatar.VariantOverrides;

		if (base)
		{
			const baseAsset = AvatarFeatures.Base.Assets.find(asset => asset.Prefab && asset.Prefab === base.AssetName);
			if (baseAsset)
			{
				const loadedBase = await loadAsset(baseAsset, null, colors, view.sharedUniforms, view.modelCache);
				view.scene.add(loadedBase);
				view.assetsInScene.Base = loadedBase;
				setShape(loadedBase, avatar.ShapeJoints, avatar.BlendShapes);
			}
		}

		for (const asset of assets)
		{
			let assetJson = null;
	
			let assetType = asset.Type;
			// If type has been defined
			if (assetType && AvatarFeatures[assetType])
			{
				assetJson = AvatarFeatures[assetType].Assets.find(a => a.Prefab && a.Prefab === asset.AssetName);
			}
			else // If not, just look through all of the features
			{
				for (let type in AvatarFeatures)
				{
					assetJson = AvatarFeatures[type].Assets.find(a => a.Prefab && a.Prefab === asset.AssetName);
					if (assetJson)
					{
						assetType = type;
						break;
					}
				}
			}
	
			if (assetJson)
			{
				const variantName = findMostSuitableVariant(assetJson, variantOverrides);
				const loadedAsset = await loadAsset(assetJson, variantName, colors, view.sharedUniforms, view.modelCache);
				if (loadedAsset)
				{
					view.scene.add(loadedAsset);
					view.assetsInScene[assetType] = loadedAsset;
					setShape(loadedAsset, avatar.ShapeJoints, avatar.BlendShapes);
				}
			}
		}

		view.setLoading(false);
		view.render();
		return true;
	}
	return false;
};

export const loadAvatar = (view, avatar) => {
	if (!view)
		return;

	if (view.currentAsyncOperation)
	{
		view.currentAsyncOperation.then(() => {
			view.currentAsyncOperation = loadAvatarAsync(view, avatar);
		});
	}
	else
	{
		view.currentAsyncOperation = loadAvatarAsync(view, avatar);
	}
}

export const clearScene = (view) => {
	if (!view)
		return;

	if (view.currentAsyncOperation)
	{
		view.currentAsyncOperation.then(() => {
			view.clearScene();
		});
	}
	else view.clearScene();
}

export const setCameraTargetHeight = (view, height) =>
{
	if (!view)
		return;

	view.cameraTargetHeight = height;
}

export const setCameraTargetZoom = (view, zoom) =>
{
	if (!view)
		return;

	view.cameraTargetZoom = zoom;
}

const animateView = (view) => {
	if (!view)
		return;

	if (view.renderFrameCounter === 0)
	{
		disableRendering(view);
		return;
	}

	// Pedestal (camera height)
	const currentHeight = view.controls.target.y;
	const targetHeight = view.cameraTargetHeight;
	if (targetHeight && targetHeight !== currentHeight)
	{
		const pedestalSpeed = 0.0075;
		let diff = targetHeight - currentHeight;
		if (diff > 0 && diff > pedestalSpeed)
		{
			diff = pedestalSpeed;
		}
		else if (diff < 0 && diff < -pedestalSpeed)
		{
			diff = -pedestalSpeed;
		}
		
		view.controls.target.y += diff;
	}
		
	// Dolly (camera zoom)
	const cameraDist = new THREE.Vector3(view.camera.position.x - view.controls.target.x,
										view.camera.position.y - view.controls.target.y,
										view.camera.position.z - view.controls.target.z);
	const currentZoomLevel = cameraDist.length();
	const targetZoomLevel = view.cameraTargetZoom;
	if (targetZoomLevel && targetZoomLevel !== currentZoomLevel)
	{
		const dollySpeed = 0.02;
	
		let diff = targetZoomLevel - currentZoomLevel;
		if (diff > 0 && diff > dollySpeed)
		{
			diff = dollySpeed;
		}
		else if (diff < 0 && diff < -dollySpeed)
		{
			diff = -dollySpeed;
		}

		const newZoomLevel = currentZoomLevel + diff;
	
		const factor = newZoomLevel / currentZoomLevel;
	
		view.camera.position.x *= factor;
		view.camera.position.y *= factor;
		view.camera.position.z *= factor;
	}
	
	// required if controls.enableDamping or controls.autoRotate are set to true
	view.controls.update();
	
	// Rotate light direction with camera (Stupid hack)
	const cameraAngle = view.controls.getAzimuthalAngle();
	const lightDir = new THREE.Vector3(-1, 1, 1);
	lightDir.applyAxisAngle(new THREE.Vector3(0, 1, 0), cameraAngle);
	view.sharedUniforms._MainLightDirection.value = lightDir;
	
	view.render();
	
	view.animationHandle = requestAnimationFrame(() => animateView(view));
	view.renderFrameCounter--;
}

export const disableRendering = (view) => {
	if (!view || !view.animationHandle)
		return;

	cancelAnimationFrame(view.animationHandle);
	view.animationHandle = 0;
	view.renderFrameCounter = 0;
}

// Duration as in how many frames to render
// A duration of 0 means infinite rendering (until manually stopped)
export const enableRendering = (view, duration) => {
	if (!view)
		return;
	
	if (duration)
		view.renderFrameCounter += duration;
	else view.renderFrameCounter = Infinity;

	if (view.animationHandle)
		return;
	
	view.animationHandle = requestAnimationFrame(() => animateView(view));

}

export class AvatarView {
	constructor(canvas) {
		this.id = canvas.id;

		this.renderer = new THREE.WebGLRenderer({ alpha: true, canvas: canvas, antialias: true });
		this.renderer.gammaFactor = 2.2;
		this.renderer.outputEncoding = THREE.sRGBEncoding;
		this.renderer.toneMapping = THREE.ReinhardToneMapping;
		this.renderer.toneMappingExposure = 1.0;

		const aspectRatio = canvas.width / canvas.height;
		this.camera = new THREE.PerspectiveCamera( 50, aspectRatio, 0.01, 1000 );
		this.camera.position.set(0.345, 1.5, 1.09);

		this.controls = new OrbitControls( this.camera, this.renderer.domElement );

		this.controls.minPolarAngle = Math.PI/2.2;
		this.controls.maxPolarAngle = Math.PI/2.2;
		this.controls.target = new THREE.Vector3(0,1.5,0);
		this.controls.panSpeed = 0;
		this.controls.enableZoom = false;

		this.scene = new THREE.Scene();
		this.assetsInScene = {};

		this.sharedUniforms = {
			_EnvCube: { value: cubeMap },
			_CubeMipCount: { value: 12 },
			_SHAr: { value: new THREE.Vector4(0.1645742, 0.02420156, 0.01553166, 0.446695) },
			_SHAg: { value: new THREE.Vector4(0.1644136, 0.02413991, 0.01604533, 0.4458246) },
			_SHAb: { value: new THREE.Vector4(0.1645742, 0.02420156, 0.01553166, 0.446695) },
			_SHBr: { value: new THREE.Vector4(0.01560425, -0.001437792, 0.2203609, -0.001466655) },
			_SHBg: { value: new THREE.Vector4(0.01578498, -0.001233852, 0.2200781, -0.001338999) },
			_SHBb: { value: new THREE.Vector4(0.01560425, -0.001437792, 0.2203709, -0.001466655) },
			_SHC:  { value: new THREE.Vector4(0.003309059, 0.003438057, 0.003309059, 1) },
			_MainLightDirection: { value: new THREE.Vector3(.5,.5,1) },
			_MainLightColor: { value: new THREE.Vector3(.75,.75,.75) }
		};
		
		// Three doesn't support the cloning of skinned meshes, so they can't be shared.
		// Therefore each viewer has its own model cache :c
		// This takes some more memory
		this.modelCache = {};
		
		this.loading = false;
		this.currentAsyncOperation = null;
	
		this.animationHandle = requestAnimationFrame(() => animateView(this));
		this.renderFrameCounter = 120;
	}

	dispose() {
		const clearGeometry = (scene) => {
			scene.traverse(child => {
				if (child.isMesh)
				{
					child.geometry.dispose();
				}
			});
		}
	
		// Clear models
		for (const url in this.modelCache)
		{
			if (this.modelCache[url])
				clearGeometry(this.modelCache[url]);
			delete this.modelCache[url];
		}

		if (this.animationHandle)
			cancelAnimationFrame(this.animationHandle);

		this.renderer.dispose();
	}

	clearScene() {
		this.scene.traverse(child => {
			if (child.isMesh)
			{
				child.geometry.dispose();
				child.material.dispose();
			}
		});
		this.scene.clear();
	}

	setLoading(value) {
		this.loading = value;
		const event = new CustomEvent('onAvatarViewLoadingChanged', { detail: value });
		window.dispatchEvent(event);
	}

	render() {
		this.renderer.render( this.scene, this.camera );
	}
}

const initializeSharedResources = () => {
	console.log("Initializing shared avatar view resources!");

	cubeMap = new THREE.CubeTextureLoader().load([
		"https://glue-collab-asset.s3-eu-west-1.amazonaws.com/avatarconfigurator/env/px.png",
		"https://glue-collab-asset.s3-eu-west-1.amazonaws.com/avatarconfigurator/env/nx.png",
		"https://glue-collab-asset.s3-eu-west-1.amazonaws.com/avatarconfigurator/env/py.png",
		"https://glue-collab-asset.s3-eu-west-1.amazonaws.com/avatarconfigurator/env/ny.png",
		"https://glue-collab-asset.s3-eu-west-1.amazonaws.com/avatarconfigurator/env/pz.png",
		"https://glue-collab-asset.s3-eu-west-1.amazonaws.com/avatarconfigurator/env/nz.png",
	]);
	cubeMap.encoding = THREE.sRGBEncoding;
}

const cleanUpSharedResources = () => {
	console.log("Cleaning up shared avatar view resources!");

	// Clear textures
	for (const url in sharedTextureCache)
	{
		if (sharedTextureCache[url])
			sharedTextureCache[url].dispose();
		delete sharedTextureCache[url];
	}

	cubeMap.dispose();
	
	// Clear materials
	for (const url in baseMaterialCache)
	{
		if (baseMaterialCache[url])
			baseMaterialCache[url].dispose();
		delete baseMaterialCache[url];
	}
}

export const createAvatarView = (canvas) =>
{
	if (!canvas)
	{
		console.log("Cannot create avatar view with no canvas!");
		return null;
	}
	if (!canvas.id)
	{
		console.log(`Cannot create avatar view, canvas ${canvas} has no id!`);
		return null;
	}
	const existingView = activeViews.find(view => view.id === canvas.id);
	if (existingView)
	{
		console.log(`Cannot create avatar view, view with id ${canvas.id} already exists!`);
		return null;
	}

	const shouldInitialize = activeViews.length === 0;

	console.log(`Creating avatar view with id ${canvas.id}!`);
	const newView = new AvatarView(canvas);
	activeViews.push(newView);

	if (shouldInitialize)
	{
		initializeSharedResources();
	}

	return newView;
};

export const deleteAvatarView = (view) =>
{
	if (!view)
	{
		console.log(`Cannot delete avatar view that doesn't exist!`);
		return;
	}

	console.log(`Deleting avatar view ${view}`);

	view.dispose();

	const index = activeViews.findIndex(v => v === view);
	activeViews.splice(index, 1);

	const shouldCleanup = activeViews.length === 0;
	if (shouldCleanup)
	{
		cleanUpSharedResources();
	}
}