import React, { useEffect, useState } from 'react';
import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';

import mutations from '../../graphql/mutations';
import queries from '../../graphql/queries';

import GlueButton from '../common/glue-button';
import GlueInputfield from '../common/glue-inputfield';
import GlueDropdown from '../common/glue-dropdown';
import AssetSelector from './asset-selector';
import { setTrackVersionPointer as setSpaceTrackVersionPointer } from './space-asset-common';
import SingleTeamSearch from '../single-team-search';
import SpaceSelector from './space-selector';

const ManageSpace = (props) =>
{
	const apollo = useApolloClient();
	
	const [name, setName] = useState("");
	const [selectedAssetId, setSelectedAssetId] = useState("");
	const [selectedAssetVersion, setSelectedAssetVersion] = useState("");
	const [selectedSpaceId, setSelectedSpaceId] = useState(null);
	const [trackVersionPointer, setTrackVersionPointer] = useState('');
	const [targetTeamId, setTargetTeamId] = useState(null);

	const isCreating = !selectedSpaceId;

	const spaceRes = useQuery(queries.roomInfo, {
		variables: { roomId: selectedSpaceId },
		skip: !selectedSpaceId,
		fetchPolicy: 'network-only'
	});

	const assetRes = useQuery(queries.assetInfo, {
		variables: { assetId: selectedAssetId },
		skip: !selectedAssetId,
		fetchPolicy: 'network-only'
	});

	// Which Asset version matches the one apparently used by this Space.
	const matchingAssetVersion = assetRes.data?.assetInfo.versions?.find(
		version => version.baseUrl === spaceRes.data?.roomInfo.sceneUrl
	) ?? null;

	useEffect(() => {
		if (spaceRes.data)
		{
			const roomInfo = spaceRes.data.roomInfo;
			setName(roomInfo.name);
			setSelectedAssetId(roomInfo.assetId);
			setSelectedAssetVersion(matchingAssetVersion?.version ?? '');
			setTrackVersionPointer(roomInfo.trackVersionPointer ?? '');
		}
		else if (!isCreating)
		{
			setName("");
			setSelectedAssetId("");
			setSelectedAssetVersion("");
			setTrackVersionPointer('');
		}
	}, [spaceRes.data, assetRes.data, isCreating, matchingAssetVersion]);

	const [ crateSpaceMut ] = useMutation(mutations.createRoom);
	const [ updateSpaceMut ] = useMutation(mutations.updateRoom);

	const submit = async () =>
	{
		if (!assetRes.data)
		{
			console.error("Cannot save. Asset data has not been loaded.");
			return;
		}

		const refetchQueries = [
			{
				query: queries.myRooms,
				variables: {
					teamId: targetTeamId,
					first: 0
				},
				fetchPolicy: 'network-only'
			}
		];

		const assetVersion = assetRes.data.assetInfo.versions.find(
			ver => ver.version === selectedAssetVersion
		);

		if (!assetVersion)
		{
			console.error("Invalid selecter version. Not found on Asset.", selectedAssetVersion);
			return;
		}

		let spaceId = spaceRes.data?.roomInfo.roomId ?? null;

		if (isCreating)
		{
			console.log("Create space");

			const createRoomRes = await crateSpaceMut({
				variables: {
					name: name,
					teamId: targetTeamId,
					assetId: selectedAssetId,
					sceneUrl: assetVersion.baseUrl,
					sceneName: assetRes.data.assetInfo.sceneName
				},
				refetchQueries: refetchQueries
			});

			spaceId = createRoomRes.data.createRoom.roomId;

			setSelectedSpaceId(spaceId);
		}
		else
		{
			if (!spaceRes.data)
			{
				console.error("Cannot save. Space data has not been loaded.");
				return;
			}

			console.log("Update space");

			await updateSpaceMut({
				variables: {
					...spaceRes.data.roomInfo,
					updateOperationType: 0,
					name: name,
					sceneUrl: assetVersion.baseUrl,
					sceneName: assetRes.data.assetInfo.sceneName
				},
				refetchQueries: refetchQueries
			});
		}

		await setSpaceTrackVersionPointer(apollo, spaceId, trackVersionPointer);

		assetRes.refetch();
		spaceRes.refetch({ variables: { roomId: spaceId } });
	};

	const changeAssetSelection = (assetId) =>
	{
		setSelectedAssetId(assetId);
		setSelectedAssetVersion("");
		setTrackVersionPointer('');
	};

	const changeTrackVersionPointerSelection = (pointerName) =>
	{
		setTrackVersionPointer(pointerName);
		setSelectedAssetVersion(
			assetRes.data?.assetInfo.versionPointers.find(
				(item) => item.name === pointerName
			)?.version ?? ''
		);
	};

	const fixVersionToMatchPointer = () =>
	{
		const versionFromPointer = assetRes.data?.assetInfo.versionPointers?.find(
			(item) => item.name === trackVersionPointer
		)?.version ?? null;

		if (Number.isFinite(versionFromPointer))
		{
			setSelectedAssetVersion(versionFromPointer);
		}
	};

	const availableAssetVersions = assetRes.data?.assetInfo.versions?.map(
		version => ({
			id: version.version,
			name: String(version.version)
		})
	) ?? [];
	availableAssetVersions.unshift({ id: "", name: "None" });

	const versionPointerOptions = [{ id: '', name: 'None' }];
	if (Array.isArray(assetRes.data?.assetInfo.versionPointers))
	{
		versionPointerOptions.push(
			...(assetRes.data?.assetInfo.versionPointers.map(
				(item) => ({ id: item.name, name: item.name + " -> " + item.version })
			) ?? [])
		);
	}

	const selectedVersionAndVersionPointerMatch = (
		selectedAssetVersion === assetRes.data?.assetInfo.versionPointers?.find(
			(item) => item.name === trackVersionPointer
		)?.version ?? ''
	);

	return (
		<div>
			<h1>Manage Space</h1>

			<h2>Select Team</h2>
			<SingleTeamSearch onChange={(teamId) => setTargetTeamId(teamId)} selected={targetTeamId} />

			<h2>Select Space</h2>
			<SpaceSelector teamId={targetTeamId} selectedId={selectedSpaceId} onSelect={(value) => setSelectedSpaceId(value)} />

			{isCreating ? (
				<h2>Create a new Space</h2>
			) : (<>
				<h2>Edit Space</h2>
				<p>Space ID: {spaceRes.data?.roomInfo.roomId}</p>
			</>)}

			<div>
				<div>
					<label>
						Space name:
						<GlueInputfield
							simpleInput
							maxLength={128}
							width={'auto'}
							value={name}
							onChange={(value) => setName(value)}
							placeholder={"Space Asset name"}
						/>
					</label>
				</div>

				{isCreating && (
					<div>
						<label>
							Asset:
							<AssetSelector selectedId={selectedAssetId} onSelect={changeAssetSelection} />
						</label>
					</div>
				)}

				<div>
					<label>
						Track asset version pointer:
						<GlueDropdown
							defaultValue={trackVersionPointer}
							items={versionPointerOptions}
							onChange={(id, checked) => checked && changeTrackVersionPointerSelection(id)}
						/>
					</label>
				</div>

				<div>
					<label>
						Asset version:
						<GlueDropdown
							disabled={Boolean(trackVersionPointer)}
							defaultValue={selectedAssetVersion}
							onChange={(id, checked) => checked && setSelectedAssetVersion(id)}
							items={availableAssetVersions}
						/>
					</label>
				</div>

				{trackVersionPointer && !selectedVersionAndVersionPointerMatch && (<>
					<p>
						WARNING! The selected asset version and the version associated with the selected version pointer do not match!
					</p>
					<GlueButton onPointerDown={fixVersionToMatchPointer}>Fix version</GlueButton>
				</>)}
			</div>

			<GlueButton onPointerDown={() => submit()}>{isCreating ? "Create new" : "Save changes"}</GlueButton>

			{!isCreating && (
				<div>
					<h3>Currently using Asset info:</h3>
					<p>Asset ID: {spaceRes.data?.roomInfo.assetId}</p>
					<p>Asset name: {assetRes.data?.assetInfo.name}</p>
					<p>Tracking version pointer: {spaceRes.data?.roomInfo.trackVersionPointer ?? "None"}</p>
					<p>Using version: {matchingAssetVersion ? matchingAssetVersion.version : "Invalid"}</p>
					<p>Files at: {spaceRes.data?.roomInfo.sceneUrl}</p>
				</div>
			)}
		</div>
	);
};

export default ManageSpace;
