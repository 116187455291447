import { CSSProperties } from "@material-ui/core/styles/withStyles";

export type Type = {
	[variant: string]: CSSProperties
};

const common: Type =
{
	main: {
		padding: '0 18px',
		minWidth: '80px',
		height: '36px',
		borderRadius: '18px',
	},
	icon: {
		width: '36px',
		minWidth: '36px',
		height: '36px',
		borderRadius: '18px',
	}
};

export const web: Type =
{
	...common
};

export const tablet: Type =
{
	...common,

	main: {
		padding: '0 32px',
		minWidth: '120px',
		height: '64px',
		borderRadius: '32px',
	},
	icon: {
		width: '64px',
		minWidth: '64px',
		height: '64px',
		borderRadius: '32px',
	},
	compact: {
		padding: '0 24px',
		minWidth: '64px',
		height: '48px',
		borderRadius: '24px',
	},
	compactIcon: {
		width: '48px',
		minWidth: '48px',
		height: '48px',
		borderRadius: '24px',
	}

};