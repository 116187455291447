import { CSSProperties } from "@material-ui/core/styles/withStyles";

export type Type = {
	root: CSSProperties
	label: CSSProperties
};

const common: Type = {
	root: {
		height: '26px',
		padding: '0 8px'
	},

	label: {
		fontWeight: 600,
		fontSize: '14px',
		textTransform: 'uppercase',
		letterSpacing: '0.1em',
		lineHeight: '14px'
	}
};

export const web: Type = {
	...common,
};

export const tablet: Type = {
	...common,

	root: {
		height: '32px',
		padding: '0 8px'
	},

	label: {
		fontWeight: 600,
		fontSize: '18px',
		textTransform: 'uppercase',
		letterSpacing: '0.1em',
		lineHeight: '18px'
	}
};