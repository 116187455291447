import React, { useState, useEffect } from 'react';
import { makeStyles, IconButton } from '@material-ui/core';
import GlueDropdown from '../common/glue-dropdown';
import SliderSelector from '../common/slider-selector';
import SwitchSelector from '../common/switch-selector';
import MicActivityBar from '../mic-activity-bar';
import { useQuery } from '@apollo/react-hooks';
import queries from '../../graphql/queries';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VolumeDownIcon from '@material-ui/icons/VolumeDown';
import postVuplexMessage from '../../service/message-vuplex';
import { clickAudio, hoverAudio } from '../common/common-vuplex-messages';
import DSPControls from './dsp-controls';

const useStyles = makeStyles(theme => ({
	root: {
		width: '886px',
		margin: 'auto',
	},

	title: {
		marginBottom: '36px',
		marginTop: '48px',
	},

	row: {
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
		alignItems: 'center',
		height: '64px',
		marginBottom: '12px',

		'& > p': {
			opacity: '70%',
		},

		'& > div:second-child': {
			justifySelf: 'center'
		}
	},

	secondaryRow: {
		marginLeft: '32px'
	},

	parameterControlRow: {
		display: 'grid',
		alignItems: 'center',
		gridTemplateColumns: '1fr 5fr 1fr',
		margin: 0,
		gridColumnGap: '12px',

		'&> button': {
			opacity: '70%',
			transform: 'scale(0.7)',

			'&:disabled': {
				visibility: 'hidden'
			}
		}
	},

	dspControlRow: {
		display: 'grid',
		alignItems: 'center',
		gridTemplateColumns: '2fr 1fr 5fr 1fr',
		margin: 0,
		gridColumnGap: '12px',
		marginRight: '-75px',

		'&> button': {
			opacity: '70%',
			transform: 'scale(0.7)',

			'&:disabled': {
				visibility: 'hidden'
			}
		}
	},

	inputVolume: {
		margin: 'auto'
	},

	switch: {
		'& > div:first-child': {
			margin: '0 0 0 auto'
		}
	},
}), { name: 'MuiGlueSoundSettings' });

const Sound = (props) =>
{
	const classes = useStyles(props);
	const audioDevices = useQuery(queries.microphoneSettings);
	const masterVolumeRes = useQuery(queries.masterVolume);
	const environmentVolumeRes = useQuery(queries.environmentVolume);
	const toolVolumeRes = useQuery(queries.toolVolume);
	const [ masterVolume, setMasterVolume ] = useState(masterVolumeRes.data.masterVolume);
	const [ environmentVolume, setEnvironmentVolume ] = useState(environmentVolumeRes.data.environmentVolume);
	const [ toolVolume, setToolVolume ] = useState(toolVolumeRes.data.toolVolume);
	const [ voiceComm, setVoiceComm ] = useState(props.settings?.voiceCommunicationOnly);

	useEffect(() => {
		postVuplexMessage("Microphone.SetUpdatesActive", { value: true });
		return () => {
			postVuplexMessage("Microphone.SetUpdatesActive", { value: false });
		}
	});

	const AudioInputDevices = () =>
	{
		const audioMicList = audioDevices.data?.microphoneSettings.microphoneList.map((micName) => ({ id: micName, name: micName }));

		const HandleMicChange = (newValue) => 
		{
			postVuplexMessage('Change Microphone Device', { value: newValue });
		};

		const inputaudiomessage = "Tools/Settings/Sound/Input/Press";
		const inputhoveraudiomessage = "Tools/Settings/Sound/Input/HL";
		const inputselaudiomessage = "Tools/Settings/Sound/Input/Select/Press";
		const inputselhoveraudiomessage = "Tools/Settings/Sound/Input/Select/HL";
	
		return (
			<GlueDropdown
				width={'420px'} // 360px SignalProcessingPresets in dsp-control.js
				items={audioMicList}
				defaultValue={audioDevices.data?.microphoneSettings.currentAudioDevice}
				uiAudioMessage={inputaudiomessage}
				uiHoverAudioMessage={inputhoveraudiomessage}
				uiSelAudioMessage={inputselaudiomessage}
				uiSelHoverAudioMessage={inputselhoveraudiomessage}
				onChange={(id, checked) => checked && HandleMicChange(id)}
			/>
		)
	};

	const handleSoundChange = (slider, newValue) =>
	{
		postVuplexMessage('Change volume level', { name: slider, level: newValue });
		
		switch(slider)
		{
			case 'MasterVolume':
				return setMasterVolume(newValue);
			case 'EnvironmentVolume':
				return setEnvironmentVolume(newValue);
			case 'ToolVolume':
				return setToolVolume(newValue);
			default:
				return;
		}
	};

	const calculateSoundChange = (value, delta) =>
	{
		return value = Math.max(0, Math.min(value + delta, 1));
	};

	const MasterVolumeIconClick = (delta) => 
	{
		const value = calculateSoundChange(masterVolume, delta);
		handleSoundChange("MasterVolume", value);
	};

	const MasterVolumeSlider = (
		<div className={classes.parameterControlRow}>
			<IconButton
				onPointerDown={() => {MasterVolumeIconClick(-0.05); clickAudio("Tools/Settings/Sound/MasterVol/Less/Press")}}
				onPointerEnter={() => hoverAudio("Tools/Settings/Sound/MasterVol/Slider/HL")}
			>
				<VolumeDownIcon />
			</IconButton>
			<SliderSelector style={{ textAlign: 'center' }}
				isEnabled={true}
				isActive={true} 
				value={masterVolume}
				width={360}
				min={0}
				max={1}
				step={0.01}
				onChange={(e, newValue) => handleSoundChange("MasterVolume", newValue)}
				uiAudioMessage = {"Tools/Settings/Sound/MasterVol/Slider/Press"}
				uiReleaseAudioMessage = {"Tools/Settings/Sound/MasterVol/Slider/Release"}
				uiHoverAudioMessage = {"Tools/Settings/Sound/MasterVol/Slider/HL"}
			/>
			<IconButton 
				onPointerDown={() => {MasterVolumeIconClick(0.05); clickAudio("Tools/Settings/Sound/MasterVol/More/Press")}}
				onPointerEnter={() => hoverAudio("Tools/Settings/Sound/MasterVol/More/HL")}
			>
				<VolumeUpIcon />
			</IconButton>
		</div>
	);

	const EnvSoundsVolumeIconClick = (delta) =>
	{
		const value = calculateSoundChange(environmentVolume, delta);
		handleSoundChange("EnvironmentVolume", value);
	};

	const EnvironmentVolumeSlider = (
		<div className={classes.parameterControlRow}>
			<IconButton
				disabled={voiceComm}
				onPointerDown={() => {EnvSoundsVolumeIconClick(-0.05); clickAudio("Tools/Settings/Sound/EnvSounds/Less/Press")}}
				onPointerEnter={() => hoverAudio("Tools/Settings/Sound/EnvSounds/Slider/HL")}
			>
				<VolumeDownIcon />
			</IconButton>
			<SliderSelector //style={{ textAlign: 'center' }}
				isActive={!voiceComm} 
				disabled={!!voiceComm}
				value={!voiceComm ? environmentVolume : 0}
				height={8}
				width={360}
				min={0}
				max={1}
				step={0.01}
				onChange={(e, newValue) => handleSoundChange("EnvironmentVolume", newValue)}
				uiAudioMessage = {"Tools/Settings/Sound/EnvSounds/Slider/Press"}
				uiReleaseAudioMessage = {"Tools/Settings/Sound/EnvSounds/Slider/Release"}
				uiHoverAudioMessage = {"Tools/Settings/Sound/EnvSounds/Slider/HL"}
			/>
			<IconButton 
				disabled={!!voiceComm}
				onPointerDown={() => {EnvSoundsVolumeIconClick(0.05); clickAudio("Tools/Settings/Sound/EnvSounds/More/Press")}}
				onPointerEnter={() => hoverAudio("Tools/Settings/EnvSounds/MasterVol/More/HL")}
			>
				<VolumeUpIcon />
			</IconButton>
		</div>
	);

	const ToolSoundsVolumeIconClick = (delta) =>
	{
		const value = calculateSoundChange(toolVolume, delta);
		handleSoundChange("ToolVolume", value);
	};

	const ToolVolumeSlider = (
		<div className={classes.parameterControlRow}>
			<IconButton
				disabled={!!voiceComm}
				onPointerDown={() => {ToolSoundsVolumeIconClick(-0.05); clickAudio("Tools/Settings/Sound/ToolSounds/Less/Press")}}
				onPointerEnter={() => hoverAudio("Tools/Settings/Sound/ToolSounds/Slider/HL")}
			>
				<VolumeDownIcon />
			</IconButton>
			<SliderSelector style={{ textAlign: 'center' }}
				isActive={!!!voiceComm}
				disabled={!!voiceComm}
				value={!!!voiceComm ? toolVolume : 0}
				height={8}
				width={360}
				min={0}
				max={1}
				step={0.01}
				onChange={(e, newValue) => handleSoundChange("ToolVolume", newValue)}
				uiAudioMessage = {"Tools/Settings/Sound/ToolSounds/Slider/Press"}
				uiReleaseAudioMessage = {"Tools/Settings/Sound/ToolSounds/Slider/Release"}
				uiHoverAudioMessage = {"Tools/Settings/Sound/ToolSounds/Slider/HL"}
			/>
			<IconButton 
				disabled={props.settings?.voiceCommunicationOnly}
				onPointerDown={() => {ToolSoundsVolumeIconClick(0.05); clickAudio("Tools/Settings/Sound/ToolSounds/More/Press")}}
				onPointerEnter={() => hoverAudio("Tools/Settings/ToolSounds/MasterVol/More/HL")}
			>
				<VolumeUpIcon />
			</IconButton>
		</div>
	);

	const VoiceCommSwitchChange = () => 
	{
		const value = !!!voiceComm;
		props.updateVoiceComm(value);
		setVoiceComm(value);
	};
	const audiomessage = !!voiceComm ? "Tools/Settings/Sound/VoipOnly/Off" : "Tools/Settings/Sound/VoipOnly/On";
	const hoveraudiomessage = "Tools/Settings/Sound/VoipOnly/HL";
	
	const VoiceComm = (
		<div className={classes.switch}>
			<SwitchSelector 
				checked={!!voiceComm} 
				uiAudioMessage = {audiomessage}
				uiHoverAudioMessage = {hoveraudiomessage}
				onChange={VoiceCommSwitchChange}
				offText={'Off'}
				onText={'On'}
			/>
		</div>
	);
	return (
		<div className={classes.root}>
			<h1 className={classes.title}>Devices</h1>
			<div className={classes.row}>
				<p>Audio input volume</p>
				<div className={classes.inputVolume}>
					<MicActivityBar alwaysVisible={true} bgColor={'#292D33'} widthSize={'296px'} />
				</div>
			</div>
			<div className={classes.row}>
				<p>Your audio input device</p>
				<AudioInputDevices />
			</div>
			<h1 className={classes.title}>Volume Controls</h1>
			<div className={classes.row}>
				<p>Voice communication only</p>
				{VoiceComm}
			</div>
			<div className={classes.row}>
				<p>Master volume</p>
				{MasterVolumeSlider}
			</div>
			<div className={classes.row}>
				<p className={classes.secondaryRow}>Environmental sounds</p>
				{EnvironmentVolumeSlider}
			</div>
			<div className={classes.row}>
				<p className={classes.secondaryRow}>Tool sounds</p>
				{ToolVolumeSlider}
			</div>
			<h1 className={classes.title}>Signal processing</h1>
			<DSPControls/>
		</div>
	)
};

export default Sound;
