import { InventoryItem } from "../graphql/types-generated";

// Returns the file extension or a type name
export const getFileType = (data: InventoryItem) =>
{
	switch (data.itemType)
	{
		case 'link':
			return 'link';
		case 'image/jpeg':
			return 'jpeg';
		case 'image/png':
			return 'png';
		case 'video/mp4':
			return 'mp4';
		case 'application/pdf':
			return 'pdf';
		case 'application/octet-stream':
		{
			const type = data.originalName.slice(-4).toLowerCase();
			if (type === '.fbx')
			{
				return 'fbx';
			}
			else if (type === '.glb')
			{
				return 'glb';
			}
			else if (type === 'gltf')
			{
				return 'gltf';
			}
			else if (type === '.obj')
			{
				return 'obj';
			}
			else if (type === '.stl')
			{
				return 'stl';
			}
			else if (type === '.ply')
			{
				return 'ply';
			}
			else if (type === '.3mf')
			{
				return '3mf';
			}
			else
			{
				return undefined;
			}
		}
		case 'application/vnd.ms-pki.stl':
			return 'stl';
		default:
			return undefined;
	}
};

// Returns a string like "1.43 MB".
export const getHumanizedFileSize = (bytes: number) =>
{
	if (bytes < 1)
		return "0 KB";

	if (bytes < 1000)
		return "1 KB";

	const log = Math.log10(bytes);

	let power = 9;
	let unitName = "GB";

	if (log < 6)
	{
		power = 3;
		unitName = "KB";
	}
	else if (log < 9)
	{
		power = 6;
		unitName = "MB";
	}

	return Math.round(bytes / Math.pow(10, power)) + " " + unitName;
};

export const filterTypeAll = 'all';

export const fileTypeOptions = [
	{ id: filterTypeAll, name: "All files" },
	{ id: 'link', name: "Link"},
	{ id: 'jpeg', name: "JPEG"},
	{ id: 'mp4',  name: "MP4" },
	{ id: 'pdf',  name: "PDF" },
	{ id: 'png',  name: "PNG" },
	{ id: 'fbx',  name: "FBX" },
	{ id: 'obj',  name: "OBJ" },
	{ id: 'gltf',  name: "GLTF" },
	{ id: 'glb',  name: "GLB" },
	{ id: 'stl',  name: "STL" },
	{ id: 'ply',  name: "PLY" },
	{ id: '3mf',  name: "3MF" }
];