import React from 'react';
import { makeStyles } from '@material-ui/core';
import GlueToggle from '../common/glue-toggle';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import PauseIcon from '@material-ui/icons/Pause';
import PlayIcon from '@material-ui/icons/PlayArrow';
import Replay10Icon from '@material-ui/icons/Replay10';
import Forward10Icon from '@material-ui/icons/Forward10';
import RepeatIcon from '@material-ui/icons/Repeat';
import SliderSelector from '../common/slider-selector';
import GlueButton from '../common/glue-button';
import GlueIcon from '../common/glue-icon';

type VideoControlFooterProps = {
	muted: boolean
	volume: number
	paused: boolean
	loop: boolean
	onSetMuted: (value: boolean) => void
	onSetVolume: (value: number) => void
	onSetPaused: (value: boolean) => void
	onSetLooped: (value: boolean) => void
	onSkip: (t: number) => void
};

const useStyles = makeStyles(theme => ({
	root: {
		height: theme.custom.header.height,
		minHeight: theme.custom.header.height,
		padding: theme.custom.header.padding,
	},

	content: {
		display: 'grid',
		gridTemplateColumns: 'repeat(3, 1fr)',
		width: '100%',
		alignItems: 'center',
	},

	controlGroupLeft: {
		display: 'grid',
		gridTemplateColumns: 'auto 360px',
		gap: theme.glueSpacing('m'),
	},

	centerButtons: {
		display: 'grid',
		gridTemplateColumns: '88px 88px 88px',
		alignItems: 'center',
		justifyContent: 'center'
	},

	controlGroupRight: {
		display: 'flex',
		flexFlow: 'row nowrap',
		justifyContent: 'flex-end'
	},
}), { name: 'MuiGlueVideoControlFooter' });

const VideoControlFooter = (props: VideoControlFooterProps) => {
	const classes = useStyles();

	const muteonaudiomessage = "Menu/TeamFiles/VideoPlayer/Mute/Toggle/On";
	const muteoffaudiomessage = "Menu/TeamFiles/VideoPlayer/Mute/Toggle/Off";
	const mutehoveraudiomessage = "Menu/TeamFiles/VideoPlayer/Mute/HL";
	const volslideraudiomessage = "Menu/TeamFiles/VideoPlayer/VolSlider/Press";
	const volsliderreleaseaudiomessage = "Menu/TeamFiles/VideoPlayer/VolSlider/Release";
	const volsliderhoveraudiomessage = "Menu/TeamFiles/VideoPlayer/VolSlider/HL";
	const backaudiomessage = "Menu/TeamFiles/VideoPlayer/StepBack/Press";
	const backhoveraudiomessage = "Menu/TeamFiles/VideoPlayer/StepBack/HL";
	const playaudiomessage = "Menu/TeamFiles/VideoPlayer/Play/Press";
	const playhoveraudiomessage = "Menu/TeamFiles/VideoPlayer/Play/HL";
	const pauseaudiomessage = "Menu/TeamFiles/VideoPlayer/Pause/Press";
	const pausehoveraudiomessage = "Menu/TeamFiles/VideoPlayer/Pause/HL";
	const fwaudiomessage = "Menu/TeamFiles/VideoPlayer/StepFW/Press";
	const fwhoveraudiomessage = "Menu/TeamFiles/VideoPlayer/StepFW/HL";
	const looponaudiomessage = "Menu/TeamFiles/VideoPlayer/Loop/Toggle/On";
	const loopoffaudiomessage = "Menu/TeamFiles/VideoPlayer/Loop/Toggle/Off";
	const loophoveraudiomessage = "Menu/TeamFiles/VideoPlayer/Loop/HL";

	const setVolume = (value: number) => {
		props.onSetVolume(value);
		if (props.muted && value !== 0) {
			props.onSetMuted(false);
		}
	}

	const effectiveVolume = props.muted ? 0 : props.volume;
	const effectiveMuted = effectiveVolume === 0;

	return (
		<div className={classes.root}>
			<div className={classes.content}>
				<div className={classes.controlGroupLeft}>
					<GlueToggle
						color='stealth'
						toggled={!effectiveMuted}
						onPointerDown={() => props.onSetMuted(!props.muted)}
						uiAudioMessage = {props.muted ? muteoffaudiomessage : muteonaudiomessage }
						uiHoverAudioMessage = {mutehoveraudiomessage}
					>
						<GlueIcon>
							{effectiveMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
						</GlueIcon>
					</GlueToggle>
					<SliderSelector
						variant='icon'
						isActive={true}
						value={effectiveVolume}
						min={0.0}
						max={1.0}
						step={0.001}
						uiAudioMessage = {volslideraudiomessage}
						uiReleaseAudioMessage = {volsliderreleaseaudiomessage}
						uiHoverAudioMessage = {volsliderhoveraudiomessage}
						onChange={(event: React.ChangeEvent, newValue: number) => setVolume(newValue)}
					/>
				</div>
				<div className={classes.centerButtons}>
					<GlueButton
						color='stealth'
						variant='icon' 
						onPointerDown={() => props.onSkip(-10)}
						uiAudioMessage = {backaudiomessage}
						uiHoverAudioMessage = {backhoveraudiomessage}
					>
						<GlueIcon>
							<Replay10Icon />
						</GlueIcon>
					</GlueButton>
					<GlueButton
						color='stealth'
						variant='icon' 
						onPointerDown={() => props.onSetPaused(!props.paused)}
						uiAudioMessage = {props.paused ? playaudiomessage : pauseaudiomessage }
						uiHoverAudioMessage = {props.paused ? playhoveraudiomessage : pausehoveraudiomessage }
					>
						<GlueIcon>
							{props.paused ? <PlayIcon /> : <PauseIcon />}
						</GlueIcon>
					</GlueButton>
					<GlueButton
						color='stealth'
						variant='icon' 
						onPointerDown={() => props.onSkip(10)}
						uiAudioMessage = {fwaudiomessage}
						uiHoverAudioMessage = {fwhoveraudiomessage}	
					>
						<GlueIcon>
							<Forward10Icon />
						</GlueIcon>
					</GlueButton>
				</div>
				<div className={classes.controlGroupRight}>
					<GlueToggle
						color='stealth'
						toggled={props.loop}
						onPointerDown={() => props.onSetLooped(!props.loop)}
						uiAudioMessage = {props.loop ? loopoffaudiomessage : looponaudiomessage }
						uiHoverAudioMessage = {loophoveraudiomessage}
					>
						<GlueIcon>
							<RepeatIcon />
						</GlueIcon>
					</GlueToggle>
				</div>
			</div>
		</div>
	)
}

export default VideoControlFooter;
