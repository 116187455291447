import queries from "../graphql/queries";
import { setGetCacheResponse } from "../util/client-cache-persist";
import { checkTeamFileProcessingStatus } from "../util/file-backend-api-util";
import { refreshTeamFiles } from '../util/refresh-data';
import { joinSpace, refreshSpaces, requestCachedSpaceTemplates, startSession } from "../util/space-utils";
import { endTutorial, userHasTutorialOrg } from "../util/tutorial-utils";
import { setCurrentTeam } from "./choose-current-team";
import { logFirebaseEvent } from './firebase/firebase';
import { StartSpeechRecognitionContinuous, StopSpeechRecognition } from "./speech-to-text";
class Vector3
{
	constructor(args)
	{
		this.__typename = 'Vector3';
		this.x = Number(args.x);
		this.y = Number(args.y);
		this.z = Number(args.z);

		Object.seal(this);
	}
}

const sendToLocation = (loc, open, tabletOnly = true, state = null) =>
{
	if (tabletOnly && sessionStorage.getItem('ui') !== 'tablet')
		return;

	document.dispatchEvent(new CustomEvent("routeTo", { detail : { location: loc, state: state }}));
	console.log("Send to location", loc);
	
	if(open)
		postVuplexMessage("Open tablet", null);
};

// Map of topic -> listener callback
const messageListeners = {};

export let postVuplexMessage = (topic, args) =>
{
	if (!window.vuplex)
	{
		console.error("Vuplex is not ready yet!");
		return;
	}

	if (typeof topic != 'string' || topic.length === 0)
	{
		console.error("Invalid params for postVuplexMessage! Topic needs to be non-zero length string!");
		return;
	}

	window.vuplex.postMessage({
		topic: topic,
		args: args ? JSON.stringify(args) : null
	});
};

let onVuplexMessage = (vuplexMsg) =>
{
	const msg = JSON.parse(vuplexMsg.data);

	if (!msg)
		return;

	let listener = messageListeners[msg.topic];

	if (typeof listener !== 'function')
	{
		console.warn("No listener registered for message topic: " + msg.topic);
		return;
	}

	listener(msg);
};

export const initVuplexConnection = async () =>
{
	const waitForVuplex = window.sessionStorage.getItem('wait-for-vuplex') === 'yes';
	console.log("Should wait for Vuplex: " + waitForVuplex);

	if (waitForVuplex && !window.vuplex)
	{
		console.log("Waiting for Vuplex to initialize...");
		await new Promise((resolve, reject) =>
		{
			console.log("Vuplex was not ready; Hook an event listener");
			window.addEventListener('vuplexready', () => resolve());
		});
	}

	if (window.vuplex)
	{
		window.vuplex.addEventListener('message', onVuplexMessage.bind(this));
		
		console.log("Vuplex is enabled.");
	}
	else
	{
		console.log("Vuplex is not enabled.");
	}
};

export const requestInitData = () =>
{
	if (!window.vuplex) {
		console.error("Vuplex is not ready to request init data");
		return;
	}

	console.log("Requesting init data from Glue client...");
	window.vuplex.postMessage({ eventType: "loadingCompleted" });
};

export let registerMessageListener = (topic, callback) =>
{
	messageListeners[topic] = callback;
};

export let unregisterMessageListener = (topic) =>
{
	delete messageListeners[topic];
};

export const registerDataListeners = (apollo) =>
{
	registerMessageListener('Request team ID', (msg) => {
		const requestId = msg.args.value;

		const currentTeamId = apollo.readQuery({
			query: queries.currentTeamId
		});

		const ui = apollo.readQuery({
			query: queries.ui
		});

		if (ui.ui === 'tablet') {
			postVuplexMessage('Team ID response', { requestId: requestId, teamId: currentTeamId.currentTeamId });
		}
	});

	registerMessageListener('Microphone status changed', (msg) => {
		apollo.writeQuery({
			query: queries.microphoneEnabled,
			data: { microphoneEnabled: !!msg.args.value }
		});
	});

	registerMessageListener('Facilitation.DisableMicrophone', async (msg) => {

		const isFacilitatorResult = apollo.readQuery({
			query: queries.isFacilitator
		});

		if (isFacilitatorResult.isFacilitator)
		{
			apollo.writeQuery({
				query: queries.muteAllEnabled,
				data: { muteAllEnabled: msg.args.value }
			})	
			postVuplexMessage('Facilitation.EnableMicrophone');

			apollo.writeQuery({
				query: queries.microphoneLocked,
				data: { microphoneLocked: false }
			})
		}
		else {
			apollo.writeQuery({
				query: queries.microphoneLocked,
				data: { microphoneLocked: msg.args.value }
			})

			if(msg.args.value) 
			{
				apollo.writeQuery({
					query: queries.microphoneEnabled,
					data: { microphoneEnabled: false }
				});	
			}
		}
	});

	registerMessageListener('Facilitation.DisableUI', async (msg) => {

		const isFacilitatorResult = apollo.readQuery({
			query: queries.isFacilitator
		});

		if (isFacilitatorResult.isFacilitator)
		{
			apollo.writeQuery({
				query: queries.uiDisabledState,
				data: { uiDisabledState: msg.args.value }
			})
			postVuplexMessage('Facilitation.TabletDisabled', { value: false });
		}
		else {
			apollo.writeQuery({
				query: queries.uiDisabled,
				data: { uiDisabled: msg.args.value }
			})

			if (msg.args.value) 
			{
				postVuplexMessage('Close tablet');
				postVuplexMessage('Facilitation.TabletDisabled', { value: true })

				apollo.writeQuery({
					query: queries.toolbarMode,
					data: { toolbarMode: 'main' }
				});
			}
		}
	});

	registerMessageListener('Hand.SetActive', (msg) => {
		const ui = apollo.readQuery({
			query: queries.ui
		});
		
		if (ui.ui === 'hand')
		{
			apollo.writeQuery({
				query: queries.handUIActive,
				data: { handUIActive: !!msg.args.value }
			});
		}
	});

	registerMessageListener('Mouselook status changed', (msg) => {
		apollo.writeQuery({
			query: queries.mouselookEnabled,
			data: { mouselookEnabled: !!msg.args.value }
		});
	});

	registerMessageListener('VR mode status changed', (msg) => {
		sendToLocation('/', false);

		apollo.writeQuery({
			query: queries.clientPlatform,
			data: {
				clientPlatform: {
					PlatformType: Boolean(msg.args.value) ? 'VR' : 'KBAM'
				}
			}
		})
	});

	registerMessageListener('Hour preference status changed', (msg) => {
		// This is not used for anything. Has it even ever been?
		console.warn("The Vuplex message \"Hour preference status changed\" has been deprecated.");
	});

	registerMessageListener('Crash reports status changed', (msg) => {
		apollo.writeQuery({
			query: queries.crashReport,
			data: { crashReport: !!msg.args.value }
		});
	});

	registerMessageListener('Scroll focus status', (msg) => {
		apollo.writeQuery({
			query: queries.scrollFocus,
			data: { scrollFocus: !!msg.args.value }
		});
	});

	registerMessageListener('Client uptime', (msg) => {
		apollo.writeQuery({
			query: queries.uptime,
			data: {
				__typename: 'Uptime',
				uptime: msg.args.value
			}
		});
	});

	registerMessageListener('Battery status', (msg) => {
		apollo.writeQuery({
			query: queries.batteryStatus,
			data: {
				batteryStatus: {
					__typename: 'BatteryStatus',
					present: msg.args.present,
					charging: msg.args.charging,
					chargeLevel: msg.args.chargeLevel
				}
			}
		});
	});

	registerMessageListener('Current space changed', async (msg) => {
		const serverKey = msg.args.value;
		apollo.writeQuery({
			query: queries.currentSpaceServerKey,
			data: { currentSpaceServerKey: serverKey }
		});

		// If going home and in a tutorial org, end tutorial!
		if (!serverKey && userHasTutorialOrg(apollo)) {
			await endTutorial(apollo);
		}
	});

	registerMessageListener('Space loaded', async (msd) => {
		await refreshSpaces(apollo);
	});

	registerMessageListener('Request join space', async (msg) => {
		const ui = apollo.readQuery({
			query: queries.ui
		});

		if (ui?.ui !== 'tablet') {
			console.warn('Not main tablet, ignoring space join request');
			return;
		}

		const serverKey = msg.args.value;

		const currentTeamId = apollo.readQuery({
			query: queries.currentTeamId
		});

		const tryFindRoom = async (policy) => {
			const myRoomsRes = await apollo.query({
				query: queries.myRooms,
				variables: {
					teamId: currentTeamId.currentTeamId,
					first: 0
				},
				fetchPolicy: policy
			});
			
			const room = myRoomsRes.data?.myRooms.find(r => r.serverKey === serverKey);
			return room;
		}

		let room = await tryFindRoom('cache-first');
		
		if (!room) {
			console.log(`Room with server key ${serverKey} not found in cache!`);
			room = await tryFindRoom('network-only');
		}

		if (!room)
		{
			throw new Error(`Room with server key ${serverKey} not found`);
		}

		startSession(serverKey, () => null).then(sessionData => {
			joinSpace(room, sessionData, false);
		}).catch(err => {
			console.error(err.message);
		});
	});

	registerMessageListener('Space.JoinSpaceError', (msg) => {
		console.error(`Error joining space: ${msg.args}`);

		apollo.writeQuery({
			query: queries.joinSpaceError,
			data: { joinSpaceError: msg.args }
		});
	});

	registerMessageListener('Force current space', async (msg) => {
		// If room is null, the space is Home
		const room = msg.args.room;

		const teamId = room?.team?.teamId;
		if (!!teamId) {
			await setCurrentTeam(apollo, teamId);
		}

		apollo.writeQuery({
			query: queries.currentSpaceServerKey,
			data: { currentSpaceServerKey: room?.serverKey ?? null }
		});
	});

	registerMessageListener('Space.SetCachedTemplateUrls', (msg) => {
		// Remove platform info from URL
		const cleanedUrls = msg.args.map(url => url.substr(0, url.lastIndexOf("_")));

		apollo.writeQuery({
			query: queries.cachedSpaceTemplateUrls,
			data: { cachedSpaceTemplateUrls: cleanedUrls }
		});
	});

	registerMessageListener('Space.SetTemplateDownloadState', (msg) => {
		const downloadState = msg.args;
		if (downloadState.status === 'finished') {
			requestCachedSpaceTemplates();
		}

		apollo.writeQuery({
			query: queries.spaceTemplateDownloadState,
			data: { spaceTemplateDownloadState: msg.args }
		});
	});

	registerMessageListener('Microphone activity', (msg) => {
		apollo.writeQuery({
			query: queries.microphoneActivity,
			data: { microphoneActivity: msg.args.value }
		});
	});

	registerMessageListener('Available microphone devices', (msg) => {
		apollo.writeQuery({
			query: queries.microphoneSettings,
			data: {
				microphoneSettings: {
					__typename: 'MicrophoneSettings',
					microphoneList: msg.args.availableDevices,
					currentAudioDevice: msg.args.currentAudioDevice
				}
			}
		});
	});

	registerMessageListener('Current display settings', (msg) => {
		apollo.writeQuery({
			query: queries.displaySettings,
			data: {
				displaySettings: {
					__typename: 'DisplaySettings',
					availableResolutions: msg.args.availableResolutions,
					currentResolution: msg.args.currentResolution,
					availableRefreshRates: msg.args.availableRefreshRates,
					currentRefreshRate: msg.args.currentRefreshRate,
					fullScreen: msg.args.isFullscreen
				}
			}
		})
	});

	registerMessageListener('Master volume changed', (msg) => {
		apollo.writeQuery({
			query: queries.masterVolume,
			data: { masterVolume: msg.args.value }
		});
	});

	registerMessageListener('Environment volume changed', (msg) => {
		apollo.writeQuery({
			query: queries.environmentVolume,
			data: { environmentVolume: msg.args.value }
		});
	});

	registerMessageListener('Tool volume changed', (msg) => {
		apollo.writeQuery({
			query: queries.toolVolume,
			data: { toolVolume: msg.args.value }
		});
	});

	registerMessageListener('Client version', (msg) => {
		apollo.writeQuery({
			query: queries.clientVersion,
			data: { clientVersion: msg.args.value }
		});
	});

	registerMessageListener('Client hash', (msg) => {
		apollo.writeQuery({
			query: queries.clientHash,
			data: { clientHash: msg.args.value }
		});
	});
	
	registerMessageListener('Client platform', (msg) => {
		apollo.writeQuery({
			query: queries.clientPlatform,
			data: {
				clientPlatform: {
					__typename: 'ClientPlatform',
					OS: msg.args.OS,
					DeviceModel: msg.args.DeviceModel,
					PlatformType: msg.args.PlatformType,
					Capabilities: [...msg.args.Capabilities],
				}
			}
		});
	});

	registerMessageListener('File import loading', (msg) => {
		apollo.writeQuery({
			query: queries.fileImport,
			data: {
				fileImport: {
					__typename: 'FileImport',
					loading: msg.args.value,
				}
			}
		});
	});

	registerMessageListener('Tool status changed', (msg) => {
		if (msg.args.name === 'PointingTool')
		{
			apollo.writeQuery({
				query: queries.pointingToolStatus,
				data: {
					pointingToolStatus: {
						__typename: 'PointingToolStatus',
						active: msg.args.active,
						attached: msg.args.attached
					}
				}
			});
		}
		else if (msg.args.name === '3DMarker')
		{
			apollo.writeQuery({
				query: queries.draw3DToolStatus,
				data: {
					draw3DToolStatus: {
						__typename: 'Draw3DToolStatus',
						active: msg.args.active,
						attached: msg.args.attached,
						color: msg.args.args.value
					}
				}
			});
		}
		else
		{
			console.warn("Unknown tool status changed", msg);
		}
	});

	registerMessageListener('Pen reset request', (msg) => {
		apollo.writeQuery({
			query: queries.penResetRequest,
			data: { penResetRequest: msg.args.value }
		});
	});

	registerMessageListener('Tablet status change', (msg) => {
		apollo.writeQuery({
			query: queries.tabletOpen,
			data: { tabletOpen: msg.args.value }
		});
	});

	registerMessageListener('File upload status', async (msg) => {
		apollo.writeQuery({
			query: queries.fileUpload,
			data: {
				fileUpload: {
					__typename: 'FileUpload',
					uploadStatus: msg.args.status
				}
			}
		});

		if (msg.args.status !== 'uploading') {
			if (!!msg.args.processingStatusItemId) {
				await checkTeamFileProcessingStatus(msg.args.processingStatusItemId, apollo, () => null);
			}
			refreshTeamFiles(apollo);
		}
	});

	registerMessageListener('Camera status', (msg) => {
		apollo.writeQuery({
			query: queries.cameraStatus,
			data: {
				cameraStatus: {
					__typename: 'CameraStatus',
					resolution: msg.args.resolution,
					fov: msg.args.fov,
					selfieMode: msg.args.selfieMode,
					isUHD: msg.args.isUHD
				}
			}
		})
	});

	registerMessageListener('Locations', (msg) => {
		apollo.writeQuery({
			query: queries.locations,
			data: {
				locations: {
					__typename: 'Locations',
					names: msg.args.names
				}
			}
		})
	});

	registerMessageListener('Return to main menu', (msg) => {
			sendToLocation('/', false);
	});

	registerMessageListener('Send Firebase event', (msg) => {
		logFirebaseEvent(msg.args.value, {method: 'Glue client call'});
		console.log('Application quit event', msg.args.value);
	});

	registerMessageListener('Close Object transform edit', (msg) => {
		const clientPlatform = apollo.readQuery({
			query: queries.clientPlatform
		});
		
		const noteEdited = apollo.readQuery({
			query: queries.noteEdited
		});

		const ui = apollo.readQuery({
			query: queries.ui
		});

		if (clientPlatform?.clientPlatform?.PlatformType !== "VR")
		{
			if (noteEdited.noteEdited === false && ui.ui === 'tablet')
			{
				sendToLocation('/', true, true);
				postVuplexMessage("Close tablet");
			}
		}
		else
		{
			if (ui.ui === 'toolbar')
			{
				postVuplexMessage('Enable hotkeys', { value: true });

				apollo.writeQuery({
					query: queries.toolbarMode,
					data: { toolbarMode: 'main' }
				});
			}
		}
	});

	registerMessageListener('Open Object transform edit', (msg) => {

		apollo.writeQuery({
			query: queries.objectTransformSupport,
			data: {
				objectTransformSupport: {
					__typename: 'TransformSupport',
					move: msg.args.move,
					rotate: msg.args.rotate,
					scale: msg.args.scale
				}
			}
		});

		const clientPlatform = apollo.readQuery({
			query: queries.clientPlatform
		});

		if (clientPlatform?.clientPlatform?.PlatformType === "VR")
		{
			sendToLocation('/app/object-manipulation', true, true);
		}
		else
		{
			const ui = apollo.readQuery({
				query: queries.ui
			});

			// Tablet should never have the transform menu open in toolbar
			if (ui.ui === 'toolbar')
			{
				postVuplexMessage('Enable hotkeys', { value: false });

				apollo.writeQuery({
					query: queries.tabletOpen,
					data: { tabletOpen: false }
				});

				postVuplexMessage('Close tablet',null);

				apollo.writeQuery({
					query: queries.toolbarMode,
					data: { toolbarMode: 'transform' }
				});
			}
		}
	})

	registerMessageListener('Edit note', (msg) => {
		let textContent = msg.args.content;

		let weight = msg.args.fontStyle.toString().includes("bold") ? "bold" : "lighter";
		let style = msg.args.fontStyle.toString().includes("italic") ? "italic" : "";

		let horizontal = msg.args.alignment.toString().includes("Left") ? "left" :
		msg.args.alignment.toString().includes("Right") ? "right" : "center";

		let vertical = msg.args.alignment.toString().includes("Top") ? "flex-start" :
		msg.args.alignment.toString().includes("Bottom") ? "flex-end" : "center";

		if(textContent === " ")
		{
			textContent = "";
		}

		sendToLocation('/app/note', true, true, {
			noteState: {
				text: textContent,
				horizontalTextAlignment: horizontal,
				verticalTextAlignment: vertical,
				noteColor: msg.args.bgColor,
				fontFamily: msg.args.font,
				fontColor: msg.args.fontColor,
				fontSize: msg.args.fontSize.toFixed(),
				fontWeight: weight,
				fontStyle: style,
				width: msg.args.width * 100, // Conversion from meters to centimeters
				height: msg.args.height * 100,
				instanceId: msg.args.instanceId
			} 
		});
	});

	registerMessageListener('Duplicate note', (msg) => {
		let weight = msg.args.fontStyle.toString().includes("bold") ? "bold" : "lighter";
		let style = msg.args.fontStyle.toString().includes("italic") ? "italic" : "";

		const ui = apollo.readQuery({
			query: queries.ui
		});
		
		if (ui.ui === 'tablet')
		{
			postVuplexMessage("Place note", {
				content: msg.args.content,
				alignment: msg.args.alignment,
				bgColor: msg.args.bgColor,
				font: msg.args.font,
				fontColor: msg.args.fontColor,
				fontSize: msg.args.fontSize.toFixed(),
				fontWeight: weight,
				fontStyle: style,
				width: msg.args.width,
				height: msg.args.height,
				instanceId: msg.args.instanceId,
				isDuplicate: true,
				objectScale: msg.args.objectScale
			});

			const scaleVector = {
			__typename: 'Vector3',
			x: msg.args.objectScale.x,
			y: msg.args.objectScale.y,
			z: msg.args.objectScale.z
			};
			postVuplexMessage(`Set Object scale`, {value: scaleVector});
		}
		
	});

	registerMessageListener('Toggle settings menu', (msg) => {
		const isOpen = apollo.readQuery({
			query: queries.tabletOpen
		});

		if (isOpen.tabletOpen === true)
		{
			sendToLocation('/', false);
			postVuplexMessage('Close tablet', null);
		}
		else
		{
			sendToLocation('/view/settings/general', true);
		}
	});

	registerMessageListener('Toggle help menu', (msg) => {
		const isOpen = apollo.readQuery({
			query: queries.tabletOpen
		});

		if (isOpen.tabletOpen === true)
		{
			sendToLocation('/', false);
			postVuplexMessage('Close tablet', null);
		}
		else
		{
			sendToLocation('/view/help', true);
		}
	});

	registerMessageListener('Open exit dialog', (msg) => {
		const isOpen = apollo.readQuery({
			query: queries.tabletOpen
		});

		if (isOpen.tabletOpen !== true)
		{
			postVuplexMessage('Open tablet');
		}

		apollo.writeQuery({
			query: queries.exitDialogOpen,
			data: { exitDialogOpen: true }
		});
	});

	registerMessageListener('ApolloCachePersist.GetCacheResponse', (msg) => {
		const instanceId = window.sessionStorage.getItem('instance-id');
		if (instanceId !== msg.args.instanceId)
			return;
		
		setGetCacheResponse(msg.args.serializedCache);
	});

	registerMessageListener('ApolloCachePersist.ClearCacheResponse', (msg) => {
		const instanceId = window.sessionStorage.getItem('instance-id');
		if (instanceId !== msg.args.instanceId)
			return;
	});

	registerMessageListener('ApolloCachePersist.SetCacheResponse', (msg) => {
		const instanceId = window.sessionStorage.getItem('instance-id');
		if (instanceId !== msg.args.instanceId)
			return;
	});

	registerMessageListener('WebControl.DownloadStarted', (msg) => {
		apollo.writeQuery({
			query: queries.extBrowserLatestDownloadedFile,
			data: {
				extBrowserLatestDownloadedFile: msg.args.fileUrl
			},
			variables: {
				instanceId: msg.args.instanceId
			}
		});
	});

	registerMessageListener('WebControl.SetDownloadState', (msg) => {
		apollo.writeQuery({
			query: queries.extBrowserDownloadState,
			data: {
				extBrowserDownloadState: msg.args.state
			},
			variables: {
				instanceId: msg.args.instanceId,
				fileUrl: msg.args.fileUrl
			}
		});
	});

	registerMessageListener('WebControl.SetImportState', (msg) => {
		apollo.writeQuery({
			query: queries.extBrowserFileImportState,
			data: {
				extBrowserFileImportState: msg.args.state
			},
			variables: {
				instanceId: msg.args.instanceId,
				fileUrl: msg.args.fileUrl
			}
		});
	});

	registerMessageListener('WebControl.SetUploadState', (msg) => {
		apollo.writeQuery({
			query: queries.extBrowserFileUploadState,
			data: {
				extBrowserFileUploadState: msg.args.state
			},
			variables: {
				instanceId: msg.args.instanceId,
			}
		});
	});

	registerMessageListener('WebControl.CaptureScreenshotActive', (msg) => {
		console.log('capturescreenshot', msg, 'active ', msg.args.active);
		apollo.writeQuery({
			query: queries.captureScreenshotActive,
			data: {
				captureScreenshotActive: msg.args.active
			},
			variables: {
				instanceId: msg.args.instanceId,
			}
		});
	});

	registerMessageListener('Open context menu', (msg) => {
		// Sanitize header
		let header = msg.args.header.replace("(Clone)", "");

		const uiDisabledResult = apollo.readQuery({
			query: queries.uiDisabled
		});

		if(uiDisabledResult.uiDisabled)
			return;

		switch (header)
		{
			case "PointingTool":
				header = "Laser Pointer";
				break;
			case "MarkerLine":
				header = "3D Drawing";
				break;
			case "DrawTool":
				header = "3D Pen";
				break;
			case "WhiteboardPrefab":
				header = "Whiteboard";
				break;
			case "ImageAsset":
				header = "Image";
				break;
			case "ImportedModelRoot":
				header = "Model";
				break;
			case "PostItPrefab":
				header = "Note";
				break;
			default:
		}

		apollo.writeQuery({
			query: queries.context,
			data: {
				context: {
					__typename: 'Context',
					header: header,
					contextList: msg.args.contextList
				}
			}
		});
	});

	registerMessageListener('Clear context', (msg) => {
		apollo.writeQuery({
			query: queries.context,
			data: {
				context: {
					__typename: 'Context',
					header: "",
					contextList: []
				}
			}
		});
	});

	registerMessageListener('Set Object position', (msg) => {
		console.log('Set position ', msg.args);

		apollo.writeQuery({
			query: queries.objectTransform,
			data: {
				transform: {
					__typename: 'Transform',
					position: new Vector3(msg.args.value)
				}
			}
		});
	});

	registerMessageListener('Set Object rotation', (msg) => {
		console.log('Set rotation ', msg.args);

		apollo.writeQuery({
			query: queries.objectTransform,
			data: {
				transform: {
					__typename: 'Transform',
					rotation: new Vector3(msg.args.value)
				}
			}
		});
	});

	registerMessageListener('Set Object scale', (msg) => {
		console.log('Set scale ', msg.args);

		apollo.writeQuery({
			query: queries.objectTransform,
			data: {
				transform: {
					__typename: 'Transform',
					scale: new Vector3(msg.args.value)
				}
			}
		});
	});

	registerMessageListener('Set Object transform lock', (msg) => {
		apollo.writeQuery({
			query: queries.objectTransformLock,
			data: { objectTransformLock: !!msg.args.value }
		});
	});

	registerMessageListener('Set Object world space', (msg) => {
		apollo.writeQuery({
			query: queries.objectWorldSpace,
			data: { objectWorldSpace: !!msg.args.value }
		});
	});

	registerMessageListener('Remaining session time', (msg) => {
		apollo.writeQuery({
			query: queries.sessionTimeRemaining,
			data: { sessionTimeRemaining: msg.args.value }
		});
	});
	
	registerMessageListener('Set Gesture mode from client', (msg) => {
		apollo.writeQuery({
			query: queries.toolbarMode,
			data: { toolbarMode: msg.args.value ? 'gestures' : 'main' }
		});
	});

	registerMessageListener('Sharing overridden', (msg) => {
		apollo.writeQuery({
			query: queries.glueOSIsSharing,
			data: { glueOSIsSharing: false }
		});
	});

	registerMessageListener('WebControl.SetState', (msg) => {
		console.log(msg)
		apollo.writeQuery({
			query: queries.extBrowserState,
			data: {
				extBrowserState: msg.args.state
			},
			variables: {
				instanceId: msg.args.instanceId
			}
		});
	});

	registerMessageListener('WebControl.ContentSelect', (msg) => {
		const instanceId = window.sessionStorage.getItem('instance-id');
		if (instanceId !== msg.args.instanceId)
			return;

		if (document.activeElement instanceof HTMLElement)
			document.activeElement.blur();
	});

	registerMessageListener('WebControl.SetNavigation', (msg) => {
		console.log(msg)
		apollo.writeQuery({
			query: queries.extBrowserNavigation,
			data: {
				extBrowserNavigation: msg.args.navigation
			},
			variables: {
				instanceId: msg.args.instanceId
			}
		});
	});

	registerMessageListener('WebControl.SetSpeechRecognitionActive', (msg) => {
		const instanceId = window.sessionStorage.getItem('instance-id');
		if (instanceId !== msg.args.instanceId)
			return;

		console.log(msg);
		if (msg.args.active) {
			StartSpeechRecognitionContinuous(apollo, msg.args.instanceId)
		}
		else {
			StopSpeechRecognition(apollo, msg.args.instanceId);
			apollo.writeQuery({
				query: queries.speechRecognition,
				data: {
					speechRecognition: {
						dialogOpen: false
					}
				},
				variables: {
					instanceId: msg.args.instanceId
				}
			});
		}
	});

	registerMessageListener('VideoPlayer.SetState', (msg) => {
		// console.log(msg)
		apollo.writeQuery({
			query: queries.extVideoState,
			data: {
				extVideoState: msg.args.state
			},
			variables: {
				instanceId: msg.args.instanceId
			}
		});
	});

	registerMessageListener('VideoPlayer.SetMetadata', (msg) => {
		console.log('VideoPlayer.SetMetadata', msg);
		apollo.writeQuery({
			query: queries.extVideoMetadata,
			data: {
				extVideoMetadata: msg.args.metadata
			},
			variables: {
				instanceId: msg.args.instanceId
			}
		});
	});

	registerMessageListener('Document.SetState', (msg) => {
		console.log('Document.SetState', msg);
		apollo.writeQuery({
			query: queries.documentState,
			data: {
				documentState: {
					sharing: msg.args.sharing
				}
			},
			variables: {
				instanceId: msg.args.instanceId
			}
		});
	});

	registerMessageListener('Document.ImportStart', (msg) => {
		apollo.writeQuery({
			query: queries.documentImportActive,
			data: {
				documentImportActive: true
			},
			variables: {
				instanceId: msg.args.instanceId
			}
		});
	});

	registerMessageListener('Document.ImportComplete', (msg) => {
		apollo.writeQuery({
			query: queries.documentImportActive,
			data: {
				documentImportActive: false
			},
			variables: {
				instanceId: msg.args.instanceId
			}
		});
	});

	registerMessageListener('Document.Refresh', (msg) => {
		const sessionId = window.sessionStorage.getItem('sessionId');
		const objectId = window.sessionStorage.getItem('objectId');
		const instanceId = window.sessionStorage.getItem('instance-id');

		apollo.query({
			query: queries.sessionObjectInfo,
			fetchPolicy: 'network-only',
			skip: !sessionId || !objectId || !instanceId || instanceId !== msg.args.instanceId,
			variables: {
				sessionId: sessionId,
				objectId: objectId,
			},
		});
	});

	registerMessageListener('PresentationView.SetControlsActive', (msg) => {
		apollo.writeQuery({
			query: queries.presentationViewControls,
			data: { presentationViewControls: msg.args.value }
		});
	});
};

export default postVuplexMessage;
