import React from 'react';

import AvatarEditorTab from './avatar-editor-tab';

const Appearance = () => {
	
	const contents = [
		{
			Title: "Body",
			Properties: [
				{
					FeatureType: "Shape",
					TitleOverride: "Face",
					ColorId: "Skin",
					UseColorsFrom: "Base"
				}
			]
		},
		{
			Title: "Hair",
			Properties: [
				{
					FeatureType: "Hair",
					ColorId: "Hair"
				},
				{
					FeatureType: "Eyebrows",
					ColorId: "Eyebrows"
				},
				{
					FeatureType: "FacialHair",
					TitleOverride: "Facial hair",
					ColorId: "Beard"
				}
			]
		},
		{
			Title: "Body Decoration",
			Properties: [
				{
					FeatureType: "Marking",
					ColorId: "Marking1"
				}
			]
		}
	];

	return (
		<AvatarEditorTab 
			contents={contents} 
			/>
	);
};

export default Appearance;