import React from 'react';
import HeroIcon from './common/hero-icon';

import CameraIcon from '../icons/hero/Camera.png';

const CameraHeroIcon = (props) => {
	const hoveraudiomessage = "Menu/Camera/HL";
	const audiomessage = "Menu/Camera/Press";

	return (
		<HeroIcon
			name="Camera"
			icon={CameraIcon}
			route="/app/camera"
			disabled={props.disabled}
			uiAudioMessage={audiomessage}
			uiHoverAudioMessage={hoveraudiomessage}
		/>
	);
}

export default CameraHeroIcon;