import AvatarBaseV2 from './assets/base/AvatarBaseV2.json'

import ClothingDressShirtVest from './assets/clothing/ClothingDressShirtVest.json'
import ClothingHoodie from './assets/clothing/ClothingHoodie.json'
import ClothingMensCasualSuit from './assets/clothing/ClothingMensCasualSuit.json'
import ClothingPoloShirt from './assets/clothing/ClothingPoloShirt.json'
import ClothingTShirt from './assets/clothing/ClothingTShirt.json'
import ClothingWomensLooseShirt from './assets/clothing/ClothingWomensLooseShirt.json'
import ClothingWomensSuit from './assets/clothing/ClothingWomensSuit.json'
import MensBusinessSuit from './assets/clothing/MensBusinessSuit.json'
import Thawb from './assets/clothing/Thawb.json'
import Sari from './assets/clothing/Sari.json'
import ButtonDownShirt from './assets/clothing/ButtonDownShirt.json'

import HairAfroShort from './assets/hair/HairAfroShort.json'
import HairAfroWoman from './assets/hair/HairAfroWoman.json'
import HairLongCurly from './assets/hair/HairLongCurly.json'
import HairManBun from './assets/hair/HairManBun.json'
import HairOtsisPolkka from './assets/hair/HairOtsisPolkka.json'
import HairPonnari from './assets/hair/HairPonnari.json'
import HairShortSides from './assets/hair/HairShortSides.json'
import HairSivukampaus from './assets/hair/HairSivukampaus.json'
import HairWomenPuolipitka from './assets/hair/HairWomenPuolipitka.json'
import HairLongSleek from './assets/hair/HairLongSleek.json'
import HairPixie from './assets/hair/HairPixie.json'
import BobWithBangs from './assets/hair/BobWithBangs.json'
import HairFemaleLong from './assets/hair/HairFemaleLong.json'
import HairMenHalflong from './assets/hair/HairMenHalflong.json'
import HairPixieWavy from './assets/hair/HairPixieWavy.json'
import HairShort from './assets/hair/HairShort.json'
import HairReceedingShort from './assets/hair/HairReceedingShort.json'
import HairSidepart from './assets/hair/HairSidepart.json'

import BrowsMF1 from './assets/eyebrows/BrowsMF1.json'
import BrowsThick from './assets/eyebrows/BrowsThick.json'
import BrowsYF3 from './assets/eyebrows/BrowsYF3.json'
import TestEyebrows from './assets/eyebrows/TestEyebrows.json'

import BeardA from './assets/facialhair/BeardA.json'
import GoateeA from './assets/facialhair/GoateeA.json'
import MoustacheWalrus from './assets/facialhair/MoustacheWalrus.json'
import SideburnLong from './assets/facialhair/SideburnLong.json'
import SideburnStandard from './assets/facialhair/SideburnStandard.json'
import BeardB from './assets/facialhair/BeardB.json'
import BeardC from './assets/facialhair/BeardC.json'
import Payot from './assets/facialhair/Payot.json'
import PayotAndBeard from './assets/facialhair/PayotAndBeard.json'
import BandholzA from './assets/facialhair/BandholzA.json'
import BandholzB from './assets/facialhair/BandholzB.json'
import Lemmy from './assets/facialhair/Lemmy.json'
import Vandyke from './assets/facialhair/Vandyke.json'

import GlassesAviator from './assets/eyewear/GlassesAviator.json'
import GlassesLarge from './assets/eyewear/GlassesLarge.json'
import GlassesOval from './assets/eyewear/GlassesOval.json'
import GlassesRectangle from './assets/eyewear/GlassesRectangle.json'
import GlassesRound from './assets/eyewear/GlassesRound.json'
import GlassesTommin from './assets/eyewear/GlassesTommin.json'
import GlassesCateye from './assets/eyewear/GlassesCateye.json'
import GlassesHorn from './assets/eyewear/GlassesHorn.json'
import GlassesOversize from './assets/eyewear/GlassesOversize.json'
import GlassesSquare from './assets/eyewear/GlassesSquare.json'

// Headwear no_hair
import BeanieFull from './assets/headwear/BeanieFull.json'
import Dastar from './assets/headwear/Dastar.json'
import Turban from './assets/headwear/Turban.json'
import Keffiyeh from './assets/headwear/Keffiyeh.json'
import TurbanHijab from './assets/headwear/TurbanHijab.json'

// Headwear category_A
import BaseballCap from './assets/headwear/BaseballCap.json'
import Beanie from './assets/headwear/Beanie.json'
import Fedora from './assets/headwear/Fedora.json'
import Fez from './assets/headwear/Fez.json'
import Flatcap from './assets/headwear/Flatcap.json'
import Hardhat from './assets/headwear/Hardhat.json'
import Kippah from './assets/headwear/Kippah.json'
import Porkpie from './assets/headwear/Porkpie.json'
import Taqiyah from './assets/headwear/Taqiyah.json'
import CopCap from './assets/headwear/CopCap.json'

// Veils
import Hijab from './assets/veil/Hijab.json'
import Shayla from './assets/veil/Shayla.json'
import Chador from './assets/veil/Chador.json'
import Niqab from './assets/veil/Niqab.json'

// Marking
import BindiSmall from './assets/marking/BindiSmall.json'
import BindiMedium from './assets/marking/BindiMedium.json'
import BindiBig from './assets/marking/BindiBig.json'

import BasicAsian from './assets/shape/BasicAsian.json'
import BasicLatino from './assets/shape/BasicLatino.json'
import BroadEllipseRoundEyesF from './assets/shape/BroadEllipseRoundEyesF.json'
import BroDude from './assets/shape/BroDude.json'
import ChubbyAsian from './assets/shape/ChubbyAsian.json'
import DarkDude from './assets/shape/DarkDude.json'
import DiamondAlmondEyesF from './assets/shape/DiamondAlmondEyesF.json'
import DiamondHoodedEyesF from './assets/shape/DiamondHoodedEyesF.json'
import HeroAvatar01 from './assets/shape/HeroAvatar01.json'
import HeroAvatar02 from './assets/shape/HeroAvatar02.json'
import HeroAvatar03 from './assets/shape/HeroAvatar03.json'
import HeroAvatar04 from './assets/shape/HeroAvatar04.json'
import HipsterGirl from './assets/shape/HipsterGirl.json'
import Latina from './assets/shape/Latina.json'
import MF1 from './assets/shape/MF1.json'
import MF8 from './assets/shape/MF8.json'
import MM9 from './assets/shape/MM9.json'
import OldHipster from './assets/shape/OldHipster.json'
import OldLatina from './assets/shape/OldLatina.json'
import PearAlmondEyesF from './assets/shape/PearAlmondEyesF.json'
import PearRoundEyesM from './assets/shape/PearRoundEyesM.json'
import RoundRoundEyesMF from './assets/shape/RoundRoundEyesMF.json'
import SF3 from './assets/shape/SF3.json'
import SM7 from './assets/shape/SM7.json'
import SquareHoodedEyes from './assets/shape/SquareHoodedEyes.json'
import TriangularRoundEyes from './assets/shape/TriangularRoundEyes.json'
import YF3 from './assets/shape/YF3.json'
import YM1 from './assets/shape/YM1.json'

import Img_NothingSelected from './images/AvatarThumb_NothingSelected.png'
import { AssetDescriptor } from './avatar-types'

type AvatarFeatureList = {
	DisplayName: string,
	Assets: AssetDescriptor[]
}

const AvatarFeatures : Record<string, AvatarFeatureList> = {
	Base: {
		DisplayName: "Base model",
		Assets: [
			AvatarBaseV2 
		]
	},
	Clothing: {
		DisplayName: "Clothing style",
		Assets: [ 
			ClothingHoodie, 
			ClothingTShirt, 
			ClothingWomensLooseShirt, 
			ClothingPoloShirt, 
			ButtonDownShirt,
			ClothingDressShirtVest, 
			ClothingMensCasualSuit, 
			ClothingWomensSuit,
			MensBusinessSuit,
			Thawb,
			Sari,
			]
	},
	Hair: {
		DisplayName: "Hairstyle",
		Assets:[ 
			{
				Type: "Hair",
				Prefab: "None",
				ThumbnailUrl: Img_NothingSelected
			},
			HairShort,
			HairReceedingShort,
			HairAfroShort, 
			HairShortSides, 
			HairSivukampaus,
			HairSidepart,
			HairMenHalflong,
			HairManBun, 
			HairPonnari, 
			HairAfroWoman, 
			HairLongCurly, 
			HairWomenPuolipitka,
			HairLongSleek,
			HairFemaleLong,
			HairOtsisPolkka, 
			BobWithBangs,
			HairPixie,
			HairPixieWavy,
		]
	},
	Eyebrows: {
		DisplayName: "Eyebrow style",
		Assets:[ 
			{
				Type: "Eyebrows",
				Prefab: "None",
				ThumbnailUrl: Img_NothingSelected
			},
			BrowsMF1,
			BrowsThick,
			BrowsYF3,
			TestEyebrows
		]
	},
	FacialHair: {
		DisplayName: "Facial hair style",
		Assets: [ 
			{
				Type: "FacialHair",
				Prefab: "None",
				ThumbnailUrl: Img_NothingSelected
			},
			BeardA, 
			BeardB,
			BeardC,
			BandholzA,
			BandholzB,
			GoateeA,
			Vandyke,
			MoustacheWalrus,
			Lemmy,
			SideburnLong,
			SideburnStandard,
			Payot,
			PayotAndBeard,
			],
	},
	
	Eyewear: {
		DisplayName: "Eyewear style",
		Assets: [ 
			{
				Type: "Eyewear",
				Prefab: "None",
				ThumbnailUrl: Img_NothingSelected
			},
			GlassesAviator,
			GlassesLarge,
			GlassesOval,
			GlassesRectangle,
			GlassesRound,
			GlassesTommin,
			GlassesCateye,
			GlassesHorn,
			GlassesOversize,
			GlassesSquare
			],
	},

	Headwear: {
		DisplayName: "Headwear style",
		Assets: [
			{
				Type: "Headwear",
				Prefab: "None",
				ThumbnailUrl: Img_NothingSelected
			},
			Beanie,
			BeanieFull,
			Dastar,
			Turban,
			TurbanHijab,
			BaseballCap,
			CopCap,
			Fedora,
			Porkpie,
			Flatcap,
			Fez,
			Kippah,
			Taqiyah,
			Keffiyeh,
			Hardhat,
		]
	},

	Veil: {
		DisplayName: "Veil style",
		Assets: [
			{
				Type: "Veil",
				Prefab: "None",
				ThumbnailUrl: Img_NothingSelected
			},
			Hijab,
			Shayla,
			Chador,
			Niqab
		]
	},

	Marking: {
		DisplayName: "Marking style",
		Assets: [
			{
				Type: "Marking",
				Prefab: "None",
				ThumbnailUrl: Img_NothingSelected
			},
			BindiSmall,
			BindiMedium,
			BindiBig
		]
	},

	// Not sure if it's fine that there are assets and shapes mixed here, but it'll probably be fine for now
	Shape: {
		DisplayName: "Shape",
		Assets: [ 
			HeroAvatar01,
			HeroAvatar04,
			BasicAsian,
			BasicLatino,
			BroDude,
			DarkDude,
			MM9,
			OldHipster,
			PearRoundEyesM,
			SM7,
			YM1,
			SquareHoodedEyes,
			RoundRoundEyesMF,
			TriangularRoundEyes,
			HeroAvatar02,
			HeroAvatar03,
			BroadEllipseRoundEyesF,
			ChubbyAsian,
			DiamondAlmondEyesF,
			DiamondHoodedEyesF,
			HipsterGirl,
			Latina,
			MF1,
			MF8,
			OldLatina,
			PearAlmondEyesF,
			SF3,
			YF3,
		]
	}
}

export default AvatarFeatures;