import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import TransformInput from './transform-input';

import GlueButton from '../common/glue-button';
import InsertLink from '@material-ui/icons/InsertLink';

const useStyles = makeStyles((theme) => ({
	transformRoot: (props) => ({
		display: props.compact ? 'grid' : 'flex',
		gridTemplateColumns: props.compact ? '1fr min-content 1fr' : null
	}),

	mainArea: {
		display: 'grid',
	},

	fieldContainer: (props) => ({
		height: props.compact ? '48px' : '64px',
		display: 'flex'
	}),

	defaultLabel: {
		height: '48px', 
		margin: 'auto'
	},

	compactLabel: {
		textAlign: 'right',
		margin: 'auto 24px auto 0',
		width: '100px'
	},

	tabletButtons: {
		display: 'grid',
		gridTemplateColumns: 'repeat(2, min-content)',
		gap: '24px',
		justifySelf: 'center',
		marginTop: 'auto',
		alignSelf: 'flex-end'
	},

	linkContainer: (props) => ({
		width: '24px'
	}),

	link: (props) => ({
		height: '3px',
		background: 'white',
		marginTop: props.compact ? '23px' : '31px',
	}),

	keepProportionsContainer: (props) => ({
		marginTop: props.compact ? '0px' : '48px',
	})

}));

const TransformAxes = (props) => {
	const classes = useStyles(props);

	const [keepProportions, setKeepProportions] = useState(props.allowKeepProportions ? true : false);

	const defaultLabel = (text) => (
		<div className={classes.defaultLabel}>
			{text}
		</div>
	);

	const compactLabel = (text) => (
		<div className={classes.compactLabel}>
			{text}
		</div>
	);

	const keepProportionsButton = (
		(props.compact) ?
		<GlueButton
			variant='compactIcon'
			toggled={keepProportions}
			onPointerDown={() => setKeepProportions(!keepProportions)}
			disabled={props.disabled}
		>
			<InsertLink style={{fontSize: '36px'}}/>
		</GlueButton> : 
		<GlueButton
			toggled={keepProportions}
			onPointerDown={() => setKeepProportions(!keepProportions)}
			textTransform={'capitalize'}
			letterSpacing={'0px'}
			disabled={props.disabled}
		>
			Keep Proportions
		</GlueButton>
	);

	return (
		<div className={classes.transformRoot}>
			<div className={classes.mainArea}>
				{!props.compact ? defaultLabel(props.label) : null}
				<div className={classes.fieldContainer}>
					{props.compact ? compactLabel(props.label) : null}
					<TransformInput
						id={'x'}
						axis={'x'}
						label={'X'}
						value={props.value.x}
						drag={props?.dragAxis === 'x'}
						proportional={keepProportions}
						onChange={value => props.onChangeField('x', value, keepProportions)}
						onSubmit={() => props.onSubmitField('x')}
						onBlur={() => props.onSubmitField('x')}
						onClickDragArea={(e) => props.onDragBegin('x', e.screenX, keepProportions)}
						disabled={props.xDisabled}
						compact={props.compact}
					/>
					<div className={classes.linkContainer}>
						{keepProportions ? <div className={classes.link}/> : null}
					</div>
					<TransformInput
						id={'y'}
						axis={'y'}
						label={'Y'}
						value={props.value.y}
						drag={props?.dragAxis === 'y'}
						proportional={keepProportions}
						onChange={value => props.onChangeField('y', value, keepProportions)}
						onSubmit={() => props.onSubmitField('y')}
						onBlur={() => props.onSubmitField('y')}
						onClickDragArea={(e) => props.onDragBegin('y', e.screenX, keepProportions)}
						disabled={props.yDisabled}
						compact={props.compact}
					/>
					<div className={classes.linkContainer}>
						{keepProportions ? <div className={classes.link}/> : null}
					</div>
					<TransformInput
						id={'z'}
						axis={'z'}
						label={'Z'}
						value={props.value.z}
						drag={props?.dragAxis === 'z'}
						proportional={keepProportions}
						onChange={value => props.onChangeField('z', value, keepProportions)}
						onSubmit={() => props.onSubmitField('z')}
						onBlur={() => props.onSubmitField('z')}
						onClickDragArea={(e) => props.onDragBegin('z', e.screenX, keepProportions)}
						disabled={props.zDisabled}
						compact={props.compact}
					/>
					<div className={classes.linkContainer}>
						{keepProportions ? <div className={classes.link}/> : null}
					</div>
				</div>
			</div>
			<div className={classes.keepProportionsContainer}>
				{props.allowKeepProportions ? keepProportionsButton : null}
			</div>
		</div>
	);
}

export default TransformAxes