export const exchangeCodeToToken = async (authorizationCode, backendUri) =>
{
	const request = { 
        authorizationCode: authorizationCode, 
        redirectUri: window.location.protocol + '//' + window.location.host
    }

	console.log('Requesting cognito with return URL:');
	console.log(request.redirectUri);

	const authCodeExchangeResult = await fetch(backendUri + '/api/authenticated', {
		method: 'POST',
		cache: 'no-cache',
		body: JSON.stringify(request),
		headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
	})

	if (authCodeExchangeResult.ok) {
		const authCodeExchangeResultJSON = await authCodeExchangeResult.json();
		if (authCodeExchangeResultJSON.result == 1) {
			return authCodeExchangeResultJSON.id_token;
		}
	} else {
		return null;
	}
};

export const validateToken = async (token, backendUri) => {
	console.log('Validating backend token');

	const result = await fetch(backendUri + '/api/authenticated', {
		method: 'POST',
		cache: 'no-cache',
		body: JSON.stringify({ validateToken: true }),
		headers: {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
	});

	if (result.ok) {
		return result.json().then(parsed => parsed.tokenValid).catch(e => false);
	} else {
		return false;
	}
}