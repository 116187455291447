let channel = null;

const onMessage = (event) =>
{
	const msg = event.data;

	//console.log("Received broadcast message", msg);

	if (msg.topic === 'Navigate to')
	{
		if (msg.args.ui && sessionStorage.getItem('ui') !== msg.args.ui)
			return;

		document.dispatchEvent(new CustomEvent('routeTo', { detail : { location: msg.args.path } }))
	}
};

export const setupMessageBroadcast = () =>
{
	channel = new BroadcastChannel("MessageBroadcastChannel");
	channel.onmessage = onMessage;
};

/**
 * Send a message to _ALL_ GlueOS instances.
 * @param {*} msg 
 */
export const broadcastMessage = (msg) =>
{
	//console.log("Broadcasting message", msg);
	channel.postMessage(msg);
};
