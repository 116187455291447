import React, { useRef, useState } from 'react';
import FilterListIcon from '@material-ui/icons/FilterList';
import { makeStyles, useTheme } from '@material-ui/core';
import { Palette, PaletteColor } from '@material-ui/core/styles/createPalette';
import GlueListItem from './glue-list-item';
import GlueScrollablePopover from './glue-scrollable-popover';
import { clickAudio, hoverAudio } from './common-vuplex-messages';

type FilterListItem = {
	id: string
	name: string
}

type FilterListProps = {
	width?: string
	open: boolean
	value?: string[]
	items?: FilterListItem[]
	color?: keyof Palette
	uiAudioMessage?: string
	uiHoverAudioMessage?: string
	uiSelAudioMessage?: string
	uiSelHoverAudioMessage?: string
	onToggleOpen: () => void
	onChange: (id: string, checked: boolean) => void
};

const useStyles = makeStyles((theme) => ({
	button: (props: FilterListProps) => ({
		...theme.custom.glueButton['icon'],
		background: (props.value?.length && props.value.length > 0) ? (theme.palette[props.color ?? 'secondary'] as PaletteColor).active : (theme.palette[props.color ?? 'secondary'] as PaletteColor).main,
		color: (props.value?.length && props.value.length > 0) ? (theme.palette[props.color ?? 'secondary'] as PaletteColor).textActive : (theme.palette[props.color ?? 'secondary'] as PaletteColor).contrastText,
	}),

	clickArea: {
		...theme.custom.glueButton['icon'],
		width: '100%',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		cursor: 'pointer',

		'&:hover': {
			filter: 'brightness(107%)'
		},

		'&:active': {
			filter: 'brightness(120%)'
		},
	},
}), { name: 'MuiGlueFilterList' });

const GlueFilterList = (props: FilterListProps) =>
{
	const classes = useStyles(props);
	const theme = useTheme();
	const [ invert, setInvert ] = useState(false);
	const anchorRef = useRef<HTMLDivElement>(null);

	const audiomessage = !!props.uiAudioMessage ? props.uiAudioMessage : "Menu/Generic/Button/Press";
	const hoveraudiomessage = !!props.uiHoverAudioMessage ? props.uiHoverAudioMessage : "Menu/Generic/Button/HL";
	const selaudiomessage = !!props.uiSelAudioMessage ? props.uiSelAudioMessage : "Menu/Generic/Button/Press";
	const selhoveraudiomessage = !!props.uiSelHoverAudioMessage ? props.uiSelHoverAudioMessage : "Menu/Generic/Button/HL";

	const toggleOpen = () => {
		clickAudio(audiomessage);
		if (props.onToggleOpen) {
			props.onToggleOpen();
		}
	}

	const onClickItem = (id: string) => {
		let checked = true;

		if (props.value?.includes(id)) {
			checked = false;
		}

		if (props.onChange)
		{
			props.onChange(id, checked);
		}
	}

	return (<>
		<div className={classes.button}>
			<div className={classes.clickArea}
				ref={anchorRef}
				onPointerDown={toggleOpen}
				onPointerEnter={() => {hoverAudio(hoveraudiomessage)}}	
			>
				<FilterListIcon/>
			</div>
		</div>
		<GlueScrollablePopover
			width={props.width}
			open={!!props.open}
			invert={invert}
			setInvert={setInvert}
			horizontal='right'
			vertical={invert ? 'top' : 'bottom'}
			verticalOffset={theme.glueSpacing('s')}
			onClose={() => props.onToggleOpen && props.onToggleOpen()}
			anchorRef={anchorRef}
		>
			{props.items?.map((item) => (
				<GlueListItem 
					key={item.id}
					checkbox
					selected={props.value?.includes(item.id)}
					onPointerDown={() => onClickItem(item?.id)}
					uiAudioMessage={selaudiomessage}
					uiHoverAudioMessage={selhoveraudiomessage}
				>
					{item?.name}
				</GlueListItem>
			))}
		</GlueScrollablePopover>
	</>);
}

export default GlueFilterList;
