import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';

import queries from '../graphql/queries';
import SystemMenu from './system-menu';
import { isAtHome } from '../util/space-utils';
import SelfButton from './self-button';

import GlueLogo64 from '../images/GlueLogo_64.png';
import GlueLogo36 from '../images/GlueLogo_36.png';
import { useNavigate } from 'react-router-dom';
import { Room } from '../graphql/types-generated';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		height: theme.custom.header.height,
		minHeight: theme.custom.header.height,
		background: theme.palette.background.default,
		padding: theme.custom.header.padding,
		display: 'flex',
		justifyContent: 'center',
	},

	content: {
		...theme.custom.contentBase,
		display: 'grid',
		gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
		alignItems: 'center',
		justifyContent: 'center',
		gap: theme.glueSpacing('m')
	},

	logo: {
		cursor: 'pointer'
	},

	spaceName: {
		flex: '1 0 auto',
		textAlign: 'center',

		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis'
	},

	buttons: {
		display: 'flex',
		justifyContent: 'flex-end',
		gap: theme.glueSpacing('m')
	},
}), { name: 'MuiGlueMainMenuHeader' });

const MainMenuHeader = (props: {
	web?: boolean
}) =>
{
	const classes = useStyles();
	const navigate = useNavigate();

	const currentTeamIdResult = useQuery(queries.currentTeamId);

	const currentSpaceServerKeyResult = useQuery(queries.currentSpaceServerKey, {
		skip: !currentTeamIdResult.data
	});

	const atHome = isAtHome(currentSpaceServerKeyResult);

	const currentSpaceResult = useQuery<{ spaceByServerKey: Room }>(queries.spaceByServerKey, {
		skip: !currentSpaceServerKeyResult.data?.currentSpaceServerKey || !currentTeamIdResult.data?.currentTeamId,
		variables: {
			teamId: currentTeamIdResult.data?.currentTeamId,
			serverKey: currentSpaceServerKeyResult.data?.currentSpaceServerKey
		}
	});

	let currentSpaceName = "";
	if (atHome)
	{
		currentSpaceName = "Home";
	}
	else if (currentSpaceResult.data)
	{
		const room = currentSpaceResult.data.spaceByServerKey;
		if (room)
			currentSpaceName = room.name;
		else
			console.warn("Current space is not in the currently selected team.");
	}

	const homePagePath = props.web ? "/" : "/view/main";

	return (
		<div className={classes.root}>
			<div className={classes.content}>
				<img onPointerDown={() => navigate(homePagePath)} src={props.web ? GlueLogo36 : GlueLogo64} alt='' className={classes.logo}/>
				{!props.web ? (
					<h1 className={classes.spaceName} title={currentSpaceName}>{currentSpaceName}</h1>
				) : (
					<div/>
				)}
				<div className={classes.buttons}>
					<SelfButton web={props.web}/>
					<SystemMenu />
				</div>
			</div>
		</div>
	);
};

export default MainMenuHeader;
