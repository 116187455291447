import React from 'react';
import { makeStyles, Slider, Typography } from '@material-ui/core';
import { clickAudio, hoverAudio } from '../common/common-vuplex-messages';
import { convertToHHMMSS } from '../../util/time-util';

type VideoTimelineProps = {
	time: number
	duration: number
	onSetTime: (t: number, commit?: boolean) => void
};

const useStyles = makeStyles(theme => ({
	root: {
		height: theme.custom.header.height,
		minHeight: theme.custom.header.height,
		padding: theme.custom.header.padding,
		display: 'flex',
		flexFlow: 'column',
		justifyContent: 'center',
	},

	content: {
		display: 'grid',
		gridTemplateColumns: '172px auto 172px',
		gap: theme.glueSpacing('m'),
		alignItems: 'center',

		'&>*:first-child': {
			textAlign: 'right'
		},

		'&>*:last-child': {
			textAlign: 'left'
		}
	},

	sliderRoot: {
		height: '8px',
	},
	
	sliderTrack: {
		height: '8px',
		borderRadius: '4px',
		color: theme.palette.secondary.light
	},

	sliderRail: {
		height: '8px',
		borderRadius: '4px',
		color: theme.palette.secondary.main,
		opacity: 1
	},

	sliderThumb: {
		width: '32px',
		height: '32px',
		marginTop: '-12px',
		marginLeft: '-16px',
		color: theme.palette.secondary.active
	}
}), { name: 'MuiGlueVideoTimeline' });

const VideoTimeline = (props: VideoTimelineProps) => {
	const classes = useStyles();

	const timeaudiomessage = "Menu/TeamFiles/VideoPlayer/TimeLine/Press";
	const timereleaseaudiomessage = "Menu/TeamFiles/VideoPlayer/TimeLine/Release";
	const timehoveraudiomessage = "Menu/TeamFiles/VideoPlayer/TimeLine/HL";

	return (
		<div className={classes.root}>
			<div className={classes.content}>
				<Typography variant='overline'>{convertToHHMMSS(props.time)}</Typography>
				<Slider
					classes={{root: classes.sliderRoot, track: classes.sliderTrack, rail: classes.sliderRail, thumb: classes.sliderThumb}}
					value={props.time}
					min={0}
					max={props.duration}
					onPointerDown={() => {clickAudio(timeaudiomessage)} }
					onPointerUp={() => {clickAudio(timereleaseaudiomessage)}} 
					onPointerEnter={() => {hoverAudio(timehoveraudiomessage)}} 
					onChange={(event, newValue) => props.onSetTime(newValue as number)}
					onChangeCommitted={(event, newValue) => props.onSetTime(newValue as number, true)}
				/>
				<Typography variant='overline'>{convertToHHMMSS(props.duration)}</Typography>
			</div>
		</div>
	)
}

export default VideoTimeline;
