import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Route, Routes } from 'react-router';

import ManageSpace from './manage-space';
import AssetSelector from './asset-selector';
import ManageAsset from './manage-asset';
import MassUploadSpaceAssets from './mass-upload-space-assets';
import MassCopyAssetVersionPointers from './mass-copy-asset-version-pointers';
import MassCreateSpaces from './mass-create-spaces';
import MassConvertSpaces from './mass-convert-spaces';
import MassConvertAssets from './mass-convert-assets';
import TabHeader from '../common/tab-header';
import GlueScroll from '../common/glue-scroll';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		height: '100%',
		overflow: 'hidden',
		flexFlow: 'column nowrap',
		alignItems: 'stretch',
		userSelect: 'text', // The child components are developer tools so copying text is sometimes needed.
	},

	content: {
		padding: '0 24px 0 24px',
		marginTop: '15px',
	},

	panel: {
		display: 'flex',
		flexFlow: 'column nowrap',
		alignItems: 'stretch'
	}
}));

const SpaceAssetsApp = (props) =>
{
	const classes = useStyles();

	const [selectedAssetId, setSelectedAssetId] = useState('');

	const tabInfos = [
		{ name: "Asset", path: "asset" },
		{ name: "Space", path: "space" },
		{ name: "Mass upload", path: "mass-upload-assets" },
		{ name: "Mass copy pointers", path: "mass-copy-version-pointers" },
		{ name: "Mass create Spaces", path: "mass-create-spaces" },
		{ name: "Mass edit Spaces", path: "mass-edit-spaces" },
		{ name: "Mass convert Assets", path: "mass-convert-assets" },
	];

	return (
		<div className={classes.root}>
			<TabHeader tabInfos={tabInfos} />
			<GlueScroll>
				<div className={classes.content}>
					<Routes>
						<Route path={'asset'} element={<>
							<div className={classes.panel}>
								<h1>Manage Asset</h1>
								<AssetSelector selectedId={selectedAssetId} onSelect={(value) => setSelectedAssetId(value)} />
								<ManageAsset targetId={selectedAssetId} onChangeTarget={(assetId) => setSelectedAssetId(assetId)} />
							</div>
						</>} />

						<Route path={'space'} element={<>
							<div className={classes.panel}>
								<ManageSpace />
							</div>
						</>} />

						<Route path={'mass-upload-assets'} element={<>
							<div className={classes.panel}>
								<h1>Mass upload Assets</h1>
								<MassUploadSpaceAssets />
							</div>
						</>} />

						<Route path={'mass-copy-version-pointers'} element={<>
							<div className={classes.panel}>
								<h1>Mass copy version pointers</h1>
								<MassCopyAssetVersionPointers />
							</div>
						</>} />

						<Route path={'mass-create-spaces'} element={<>
							<div className={classes.panel}>
								<h1>Mass create spaces</h1>
								<MassCreateSpaces />
							</div>
						</>} />

						<Route path={'mass-edit-spaces'} element={<>
							<div className={classes.panel}>
								<h1>Mass edit spaces</h1>
								<MassConvertSpaces />
							</div>
						</>} />

						<Route path={'mass-convert-assets'} element={<>
							<div>
								<h1>Mass convert Assets</h1>
								<MassConvertAssets />
							</div>
						</>} />
					</Routes>
				</div>
			</GlueScroll>
		</div>
	);
};

export default SpaceAssetsApp;
