import React from 'react';
import GlueButton from '../common/glue-button';
import { Theme, makeStyles } from '@material-ui/core';

type FacilitationToggleProps = {
	label: string
	toggled?: boolean
	children?: React.ReactNode
	noBorder?: boolean
	onPointerDown?: () => void
}

const useStyles = makeStyles<Theme, FacilitationToggleProps>((theme) => ({
	root: (props) => ({
		display: 'flex',
		flexFlow: 'column',
		gap: theme.glueSpacing('xs'),
		alignItems: 'center',

		'&>.MuiGlueButton-root': {
			width: '80px',
			minWidth: '80px',
			height: '80px',
			padding: 0,
			borderRadius: '16px',
			borderStyle: 'solid',
			borderWidth: props.noBorder ? '0px' : '2px',
			background: props.toggled ? theme.palette.facilitationToggle.active : theme.palette.facilitationToggle.main,
			borderColor: props.toggled ? theme.palette.facilitationToggle.textActive : theme.palette.facilitationToggle.contrastText,
		},
	}),

	contents: {
		display: 'flex',
		width: '100%',
		height: '100%',
	},

	text: (props) => ({
		opacity: props.toggled ? '100%' : '60%'
	}),
}), { name: 'MuiGlueFacilitationToggle'});

const FacilitationToggle = (props: FacilitationToggleProps) => 
{
	const classes = useStyles(props);

	return (
		<div className={classes.root}>
			<GlueButton onPointerDown={props.onPointerDown}>
				<div className={classes.contents}>
					{props.children}
				</div>
			</GlueButton>
			<p className={classes.text}>{props.label}</p>
		</div>
	)
};

export default FacilitationToggle;
