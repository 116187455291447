import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Routes, Route } from 'react-router-dom';

import ClockTabsHeader from './clock-tabs-header';
import ClockNow from './clock-now';
import { ClockStopwatchControls, ClockStopwatchDisplay } from './clock-stopwatch';
import { ClockTimerDisplay, ClockTimerControls } from './clock-timer';
import postVuplexMessage from '../../service/message-vuplex';
import CloseButton from '../common/close-button';


const useStyles = makeStyles((theme) => ({
	root: {
		height: '100%',

		display: 'flex',
		justifyContent: 'center',
		flexFlow: 'column nowrap',
		
		background: theme.palette.background.paper
	},

	header: {
		height: theme.custom.header.height,
		display: 'grid',
		gridTemplateColumns: '64px 1fr 64px',
		justifyContent: 'space-around',
		alignItems: 'center'
	},

	tabContent: {
		flex: '1 1 auto',

		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexFlow: 'column nowrap',
	},

	displayContainer: {
		marginTop: 'auto'
	},

	controlsContainer: {
		margin: 'auto 0 32px 0'
	}
}));

const Clock3D = (props) =>
{
	const classes = useStyles();

	const closeButtonClicked = (e) =>
	{
		const instanceId = window.sessionStorage.getItem('instance-id');

		if (!instanceId)
		{
			console.log("Instance ID not defined. Cannot close.")
			return;
		}

		postVuplexMessage("Clock.Close", { value: instanceId });
	};

	return (
		<div className={classes.root}>
			<div className={classes.header}>
				<div/>
				<ClockTabsHeader />
				<CloseButton onClose={closeButtonClicked}/>
			</div>

			<div className={classes.tabContent}>
				<Routes>
					<Route path={'now'} element={
						<ClockNow showTimezoneName showTimezoneNumeric />
					} />

					<Route path={'stopwatch'} element={<>
						<div className={classes.displayContainer}>
							<ClockStopwatchDisplay />
						</div>
						<div className={classes.controlsContainer}>
							<ClockStopwatchControls />
						</div>
					</>} />

					<Route path={'timer'} element={<>
						<div className={classes.displayContainer}>
							<ClockTimerDisplay />
						</div>
						<div className={classes.controlsContainer}>
							<ClockTimerControls />
						</div>
					</>} />
				</Routes>
			</div>
		</div>
	);
};

export default Clock3D;
