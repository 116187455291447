import axios, { CancelTokenSource } from 'axios';
import queries from '../graphql/queries';
import mutations from '../graphql/mutations';
import { ApolloClient } from '@apollo/client';
import { BackendInfo, InventoryItem, ProcessingStatus, TeamFileCreationParams } from '../graphql/types-generated';

export const uploadTeamFile = async (
	apollo: ApolloClient<unknown>, 
	teamId: string, 
	file: File,
	creationParams?: TeamFileCreationParams,
	setProgress?: (uploadProgress: number) => void, 
	setCancelSource?: (uploadCancelSource?: CancelTokenSource) => void, 
	onCancel?: () => void) => {
	const contentType = !!file.type ? file.type : 'application/octet-stream';
	
	const createFileRes = await apollo.mutate({
		mutation: mutations.createTeamFileDeferred,
		variables: {
			teamId: teamId,
			itemType: contentType,
			creationParams: creationParams
		}
	});
	const signedUploadURLBody = createFileRes.data.createTeamFileDeferred;

	const cancellationSource = axios.CancelToken.source();

	const uploadFileConfig = {
		headers: {
			'Content-Type': contentType,
			'x-amz-tagging': signedUploadURLBody.tagsStr
		},
		cancelToken: cancellationSource.token,
		onUploadProgress: (progressEvent: ProgressEvent) => {
			const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
			if (setProgress)
				setProgress(percentCompleted)
		},
	};

	const tags = new URLSearchParams(signedUploadURLBody.tagsStr);
	const statusItemId = tags.get('statusItemId');
	if (!statusItemId)
		throw new Error("Status item ID not found");

	if (setCancelSource)
		setCancelSource(cancellationSource);

	try {
		await axios.put(
			signedUploadURLBody.uploadURL,
			file,
			uploadFileConfig
		);
	}
	catch (e) 
	{
		if (e === cancellationSource.token.reason) 
		{
			if (onCancel)
				onCancel();
			console.log("Uploading file was canceled", e);
		}
		else 
		{
			console.error("Uploading file failed", e);
		}

		if (setCancelSource)
			setCancelSource(undefined);

		return;
	}

	if (setCancelSource)
		setCancelSource(undefined);

	return { statusItemId };
}

// Copypaska from space assets
export const checkTeamFileProcessingStatus = (
	statusItemId: string, 
	apollo: ApolloClient<unknown>, 
	statusInfoCallback?: ((statusInfo: ProcessingStatus) => void) | null) => new Promise<Partial<InventoryItem>>((resolve, reject) =>
{
	const checkStatus = async () =>
	{
		const statusRes = await apollo.query({
			query: queries.processingStatusInfo,
			fetchPolicy: 'network-only',
			errorPolicy: 'all',
			variables: {
				statusItemId: statusItemId
			}
		}).catch(e => {
			console.error("Status item query failed", e);
			return null;
		});

		const statusInfo: ProcessingStatus = statusRes?.data?.processingStatusInfo ?? null;
		if (!statusInfo)
		{
			reject(null);
			return;
		}

		console.log("Team file processing status check", statusInfo);

		if (statusInfoCallback)
			statusInfoCallback(statusInfo);

		if (statusInfo.statuscode === 'Processing' || statusInfo.statuscode === 'Upload')
		{
			setTimeout(checkStatus, 250);
		}
		else if (statusInfo.statuscode === 'Failed')
		{
			if (statusInfo.result) {
				const parsedError = JSON.parse(statusInfo.result);
				reject(new Error(parsedError.error));
			} else reject(new Error('Unknown error'));
		}
		else if (statusInfo.statuscode === 'Finished')
		{
			const parsedResult = await new Promise<Partial<InventoryItem>>((resolve, reject) => { try { if (!statusInfo.result) {throw{}} resolve(JSON.parse(statusInfo.result)) } catch (e) { reject(e) }}).catch(e => undefined);
			if (!parsedResult) {
				reject(null);
				return;
			}
			resolve(parsedResult);
		}
		else
		{
			console.error("Status item has unexpected statuscode", statusInfo);
			reject();
		}
	};

	checkStatus();
});

export const multipleFileDownload = async (filesUrls: string[], filesNames: string[], teamId: string, backendInfo: BackendInfo) =>
{
	const requestBody = JSON.stringify({
		batchDownload: true,
		teamId: teamId,
		files: filesUrls,
		names: filesNames
	});

	console.log(requestBody)

	const downloadUrlRes = await fetch(
		backendInfo.backendUri + '/api/upload-file',
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + backendInfo.backendToken
			},
			body: requestBody
		}
	);

	console.log(downloadUrlRes)
	const responseBody = await downloadUrlRes.json();

	return responseBody.downloadId;
}

export const createThumbnailUrl = async (apollo: ApolloClient<unknown>, teamId: string, file: File) =>
{
	try {
		const upload = await uploadTeamFile(
			apollo,
			teamId,
			file
		);

		if (upload && upload.statusItemId) 
		{
			const fileInfo = await checkTeamFileProcessingStatus(upload.statusItemId, apollo, () => null);
			
			if (fileInfo.thumbnailUrl) {
				return fileInfo.thumbnailUrl;
			}
		}
		else 
		{
			console.error("Thumbnail generation failed");
		}
	}
	catch (e) 
	{
		console.error(e);
	}
	return null;
}
