import React from 'react';

import { makeStyles } from '@material-ui/core';
import { Route, Routes } from "react-router-dom";

import Overview from './overview';
import Appearance from './appearance';
import Clothing from './clothing';

import AvatarViewer from './avatar-viewer';
import ConfirmIndicator from '../common/confirmation-indicator.js'

import GlueEditor from '../common/glue-editor';

const useStyles = makeStyles((theme) => ({
	overview: {
		...theme.custom.avatarEditor.overview,
	},

	confirmIndicatorWrapper: {
		display: 'flex',
		position: 'absolute',
		background: '#00000080',
		left: '0',
		right: '0',
		top: '0',
		bottom: '0',
		alignItems: 'center',
		justifyContent: 'center',
	},

	confirmIndicator: {
		backgroundColor: 'transparent',
		height: '300px',
		width: '300px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-around',
	},
}), { name: 'MuiGlueAvatarEditor' });

const AvatarEditor = (props: {
	email: string
	avatarViewWidth: string
	avatarViewHeight: string
	currentTab: string
	showConfirm: boolean
	onConfirmAnimCompleted: () => void
}) =>
{
	const classes = useStyles();

	return (
	<>
		<GlueEditor previewContent={
			<AvatarViewer
				width={props.avatarViewWidth}
				height={props.avatarViewHeight}
				currentTab={props.currentTab}
			/>}
			open={true}>
			<Routes>
				<Route path={'overview'} element={
					<div className={classes.overview}>
						<Overview email={props.email}/>
					</div>
				} />

				<Route path={'appearance'} element={<Appearance />} />

				<Route path={'clothing'} element={<Clothing />} />
			</Routes>
		</GlueEditor>
		{props.showConfirm &&
			(<div className={classes.confirmIndicatorWrapper}>
				<div className={classes.confirmIndicator}>
					<ConfirmIndicator loop={false} onComplete={props.onConfirmAnimCompleted} />
				</div>
			</div>)}
	</>
	);
}

export default AvatarEditor;