import React from 'react';
import postVuplexMessage from '../service/message-vuplex';
import HeroIcon from './common/hero-icon';

import WhiteboardIcon from '../icons/hero/Whiteboard.png';

const spawnWhiteboard = () =>
{
	const objectToSpawn = "whiteboard";
	console.log("Spawn object whiteboard");
	postVuplexMessage("Spawn object", { id: objectToSpawn, args: null });
};

const WhiteboardHeroIcon = (props) =>
{
	const hoveraudiomessage = "Menu/Whiteboard/HL";
	const audiomessage = "Menu/Whiteboard/Press";

	return (
		<HeroIcon
			name="Whiteboard"
			icon={WhiteboardIcon}
			route="/" 
			onPointerDown={spawnWhiteboard}
			uiAudioMessage={audiomessage}
			uiHoverAudioMessage={hoveraudiomessage}
		/>
	);
};

export default WhiteboardHeroIcon;
