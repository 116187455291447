import React, { ChangeEvent, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core';

import ImagePreviewComponent from '../common/image-preview-component';
import GlueButton from '../common/glue-button';
import SpaceThumbnailPlaceholder from '../../images/SpaceThumbnail_Placeholder.png'
import { useUserContext } from '../../util/user-context';
import { Maybe } from '../../graphql/types-generated';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexFlow: 'column',
		gap: theme.glueSpacing('s'),
	},

	input: {
		display: 'none'
	},

	errorText: {
		color: theme.palette.error.main
	},

	imageChangeButtons: {
		display: 'flex',
		gap: theme.glueSpacing('m'),
		flexFlow: 'row'
	},
}), { name: 'MuiGlueSpaceThumbnailEdit' });

const SpaceThumbnailEdit = (props: {
	web?: boolean
	thumbnail?: File | Maybe<string>
	custom?: boolean
	setCustomThumbnail: (file: File) => void
	onUseDefault: () => void
	noDefault?: boolean
	disabled?: boolean
}) =>
{
	const classes = useStyles();
	const inputRef = useRef<HTMLInputElement>(null);
	const user = useUserContext();
	const [ error, setError ] = useState<string>('');

	const selectNewThumbnailImage = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.files) {
			if (event.target.files.length > 1) {
				setError('Please select one thumbnail file');
				return;
			}
			const file = event.target.files[0] as File;
			if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
				setError('Please choose a valid image file');
				return;
			}

			setError('');
			props.setCustomThumbnail(file);
		}
	}

	const clearCustomThumbnail = () => {
		if (inputRef.current) {
			inputRef.current.value = '';
		}
		props.onUseDefault();
	}

	return (
		<div className={classes.root}>
			<ImagePreviewComponent image={props.thumbnail ? props.thumbnail : SpaceThumbnailPlaceholder} />
			{props.web && <>
				<input
					className={classes.input}
					type="file"
					multiple={false}
					accept="image/png, image/jpeg"
					ref={inputRef}
					onChange={selectNewThumbnailImage}
				/>
				{user.team?.spaceEdit && (
					<>
						<div className={classes.errorText}>
							{error}
						</div>
						<div className={classes.imageChangeButtons}>
							<GlueButton
								width={'100%'}
								onPointerDown={() => inputRef.current?.click()}
								disabled={!!props.disabled}
							>
								New thumbnail
							</GlueButton>
							{props.custom && <GlueButton
								width={'100%'}
								onPointerDown={clearCustomThumbnail}
								disabled={!!props.disabled}
							>
								{props.noDefault ? 'Clear' : 'Use default'}
							</GlueButton>}
						</div>
					</>
				)}
			</>}
		</div>
	);
}

export default SpaceThumbnailEdit;