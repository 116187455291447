import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardMedia, CardActionArea } from '@material-ui/core';

import { clickAudio, hoverAudio } from './common-vuplex-messages';

type CardProps = {
	title?: string
	image?: string
	active?: boolean
	showIcon?: boolean
	children?: React.ReactNode
	onPointerDown?: (e: React.PointerEvent) => void
};

const useStyles = makeStyles((theme) => ({
	root: (props: CardProps) => ({
		height: theme.custom.glueCard.size,
		width: theme.custom.glueCard.size,
		borderRadius: theme.custom.glueCard.borderRadius,
		background: theme.palette.secondary.dark,
		overflow: 'hidden',
		...theme.custom.selectOutline(props.active, { borderRadius: theme.custom.glueCard.borderRadius }),
	}),

	actionArea:
	{
		height: `calc(${theme.custom.glueCard.size} / 4 * 3)`,
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'stretch',
		flexFlow: 'column nowrap'
	},

	imageArea: {
		height: `calc(${theme.custom.glueCard.size} / 2)`,
		display: 'flex',
		alignItems: 'center',
		background: theme.palette.secondary.main,
		overflow: 'hidden'
	},

	media: {
		height: (props: CardProps) => props.showIcon ? '80px' : '100%',
		objectFit: (props: CardProps) => props.showIcon ? 'contain' : 'cover',
	},

	split: {
		display: 'grid',
		gridTemplateRows: 'repeat(2, 1fr)',
		height: '100%',
		overflow: 'hidden'
	},

	header: {
		height: `calc(${theme.custom.glueCard.size} / 4)`,
		textAlign: 'center',
		overflow: 'hidden',
		padding: `${theme.glueSpacing('xs')} ${theme.glueSpacing('s')} ${theme.glueSpacing('xs')} ${theme.glueSpacing('s')}`,
		display: 'flex',
		justifyContent: 'center',

		'& p': {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			...theme.custom.glueCard.font
		}
	},

	actions: {
		padding: theme.glueSpacing('xs')
	}
}), { name: 'MuiGlueCard' });

const GlueCard = (props: CardProps) =>
{
	const classes = useStyles(props);
	const audiomessage = "Menu/TeamSpaces/Team/Press";
	const hoveraudiomessage = "Menu/TeamSpaces/Team/HL";

	const onClick = (e: React.PointerEvent) => {
		if (props.onPointerDown) {
			props.onPointerDown(e);
		}
		clickAudio(audiomessage);
	}

	return (
		<Card className={classes.root}>
			<CardActionArea
				classes={{ root: classes.actionArea }}
				onPointerDown={onClick}
				onPointerEnter={() => {hoverAudio(hoveraudiomessage)}}
			>
				<div className={classes.imageArea}>
					<CardMedia
						classes={{ root: classes.media }}
						component="img"
						image={props.image}
					/>
				</div>
				<div className={classes.header}>
					<p>{props.title}</p>
				</div>
			</CardActionArea>
			<div className={classes.actions}>
				{props.children}
			</div>
		</Card>
	);
}

export default GlueCard;
