import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import queries from '../../graphql/queries';
import WebIcon from '@material-ui/icons/Web';
import AppHeader from '../common/app-header';
import GlueButton from '../common/glue-button';
import { DefaultUrl } from './glue-urls';
import Files from '../files/files';
import { BrowserState } from '../../graphql/types-generated';
import CloseButton from '../common/close-button';
import postVuplexMessage from '../../service/message-vuplex';
import { usePromptDialogContext } from '../../util/prompt-dialog-context';
import { showClosePresentationControlsDialog } from '../../util/sharing-utils';

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100%',
		width: '100%',
		boxSizing: 'border-box',
		display: 'flex',
		flexFlow: 'column nowrap',

		'& .custom-items-left': {
			margin: '0px',
			width: '64px',
		}
	},

	content: {
		height: '100%',
		padding: `0 ${theme.glueSpacing('s')}`,
		display: 'flex',
		flexFlow: 'column nowrap',
		overflow: 'hidden',
	}
}), { name: 'MuiGlueBookmarks' });


const Bookmarks = () => {
	const classes = useStyles();

	const dialogContext = usePromptDialogContext();

	const ui = useQuery(queries.ui).data?.ui;
	const instanceId = window.sessionStorage.getItem('instance-id');
	const stateRes = useQuery<{ extBrowserState: BrowserState }>(queries.extBrowserState, {
		variables: {
			instanceId: instanceId
		}
	});

	const navigate = useNavigate();

	const audiomessage = "Menu/Browser/Open/Press"
	const hoveraudiomessage = "Menu/Browser/Open/HL"

	const url = stateRes.data?.extBrowserState.url ?? DefaultUrl;
	const sharing = !!stateRes.data?.extBrowserState.sharing;

	const externalTablet = ui === 'webcontrol';

	// Copypasta but it'll do for now
	const tryClose = () => {
		if (sharing) {
			showClosePresentationControlsDialog(dialogContext, () => null, close);
		}
		else {
			close();
		}
	}

	const close = () => {
		postVuplexMessage('Enable hotkeys', { value: true });
		postVuplexMessage('WebControl.Close', { instanceId: instanceId });
	}

	const openBrowser =
	<div>
		<GlueButton 
			variant='icon' 
			color="primary"
			uiAudioMessage = {audiomessage}
			uiHoverAudioMessage = {hoveraudiomessage} 
			onPointerDown={() =>
				navigate('/app/web-control', { state: { appname: 'Browser', appurl: url, isProdTool: false }})
			}
		>
			<WebIcon/>
		</GlueButton>
	</div>

	return (
		<div className={classes.root}>
			<AppHeader 
				hideBackButton={externalTablet}
				title="Bookmarks"
				children={openBrowser}
				secondChildren={externalTablet && <CloseButton onClose={tryClose} />}
			/>
			<div className={classes.content}>
				<Files linkOnly/>
			</div>
		</div>
	);
};

export default Bookmarks;
