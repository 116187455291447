import { CSSProperties } from "@material-ui/core/styles/withStyles";

export type Type = {
	[variant: string]: CSSProperties
};

const common: Type = {
	main: {
		width: '200px',
		height: '200px'
	},

	icon: {
		width: '68px',
		height: '68px'
	},

	button: {
		width: '36px',
		height: '36px'
	}
};

export const web: Type = {
    ...common,
    
	main: {
		width: '150px',
		height: '150px'
	},

	icon: {
		width: '38px',
		height: '38px'
	},

	button: {
		width: '18px',
		height: '18px'
	}
};

export const tablet: Type = {
    ...common
};