class EditorHistory<Type> {
	history: string[];
	position: number;

	constructor(initialState: Type) {
		// I'm saving history as strings, as to not accidentally modify it via some reference
		this.history = [ JSON.stringify(initialState) ];
		this.position = 0;
	}

	length() : number {
		return this.history.length;
	}

	dirty() : boolean {
		return this.history.length > 1;
	}

	// Push a completely new state
	pushState(state: Type) {
		if (this.position < this.history.length - 1)
		{
			this.revertToPoint(this.position);
		}
		this.history.push(JSON.stringify(state));
		this.position++;
	}

	// Push just one property, keep everything else the same
	pushValue(prop: keyof Type, value: Type[keyof Type]) {
		const newState : Type = this.currentState();
		newState[prop] = value;
		this.pushState(newState);
	}

	// Push multiple properties, keep everything else the same
	pushValueMultiple(props: Partial<Type>) {
		const previousState : Type = this.currentState();
		const newState : Type = { ...previousState, ...props };
		this.pushState(newState);
	}

	// Return to first point in history
	revertAll() {
		const firstState = this.history[0];
		this.history = [ firstState ];
		this.position = 0;
	}

	// Return to given point in history, clear everything afterwards
	revertToPoint(pointInHistory: number) {
		const entriesToDelete = this.history.length - pointInHistory - 1;
		this.history.splice(pointInHistory + 1, entriesToDelete);
		this.position = pointInHistory;
	}

	// Keep latest state, delete everything else
	clear() {
		const lastState = this.history[this.history.length - 1];
		this.history = [ lastState ];
		this.position = 0;
	}

	// Returns state at given point in history
	getState(pointInHistory: number) : Type {
		return JSON.parse(this.history[pointInHistory]);
	}

	// Returns a single value at given point in history
	getValue(prop: keyof Type, pointInHistory: number) : Type[keyof Type] {
		const state = this.getState(pointInHistory);
		return state[prop];
	}

	getValueMultiple(props: (keyof Type)[], pointInHistory: number) : Partial<Type> {
		const state = this.getState(pointInHistory);
		const result: Partial<Type> = {};
		props.forEach(prop => {
			result[prop] = state[prop];
		});

		return result;
	}

	currentState() : Type {
		return this.getState(this.position);
	}

	currentPosition() : number {
		return this.position;
	}

	currentValue(prop : keyof Type) : Type[keyof Type] {
		return this.getValue(prop, this.position);
	}

	currentValueMultiple(props: (keyof Type)[]) : Partial<Type> {
		return this.getValueMultiple(props, this.position);
	}

	undo() {
		if (this.position >= 1)
		{
			return this.getState(--this.position);
		}
		else return this.getState(this.position);
	}

	redo() {
		if (this.position < this.history.length - 1)
		{
			return this.getState(++this.position);
		}
		else return this.getState(this.position);
	}

	moveTo(pointInHistory: number) {
		this.position = pointInHistory;
	}
}

export default EditorHistory;