import React, { useState } from 'react';
import { useApolloClient, useQuery } from '@apollo/react-hooks';

import queries from '../../graphql/queries';
import mutations from '../../graphql/mutations';
import GlueButton from '../common/glue-button';

const MassConvertAssets = () =>
{
	const apollo = useApolloClient();
	const myAssetsRes = useQuery(queries.myAssets, {
		variables: {
			typeFilter: 'space'
		}
	});

	const [inProgress, setInProgress] = useState(false);

	const magic = async () =>
	{
		setInProgress(true);

		for (const asset of myAssetsRes.data.myAssets)
		{
			await apollo.mutate({
				mutation: mutations.upgradeAssetToVersioned,
				variables: {
					assetId: asset.assetId,
				}
			}).catch((e) => {
				console.error("Failed to upgrade Asset. Error: ", e, asset);
			});
		}

		setInProgress(false);
	};

	return (
		<div>
			<p>Convert all Assets to versioned.</p>
			<p>Total assets: {myAssetsRes.data?.myAssets.length}</p>
			<ul>
				{myAssetsRes.data?.myAssets.map((asset) => (
					<li key={asset.assetId}>{asset.name} :: {asset.assetId}</li>
				))}
			</ul>
			<p>Use with causion! This will take a while. Be patient.</p>
			<GlueButton disabled={inProgress} onPointerDown={magic}>Convert all Assets</GlueButton>
			{inProgress && (
				<p>Working. Monitor console for errors. Pleaset wait...</p>
			)}
		</div>
	);

};

export default MassConvertAssets;
