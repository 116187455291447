import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: theme.custom.icon,
}), { name: 'MuiGlueIcon' });

const GlueIcon = (props) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			{props.children}
		</div>
	);
}

export default GlueIcon;