import queries from '../graphql/queries';
import { GlobalSettings } from '../graphql/types-generated';
import { useQuery } from '@apollo/client';

type Version = {
	major: number
	minor: number
	patch: number
}

const versionStringToMajorMinorPatch = (string: string): Version | null => {
	let result = null;

	const array = string.split('.');
	if (array.length === 3)
	{
		result = { major: parseInt(array[0]), minor: parseInt(array[1]), patch: parseInt(array[2]) };
	}

	return result;
};

const compareVersions = (a: Version, b: Version) => {
	if (a.major !== b.major)
	{
		return a.major - b.major;
	}
	else if (a.minor !== b.minor)
	{
		return a.minor - b.minor;
	}
	else if (a.patch !== b.patch)
	{
		return a.patch - b.patch;
	}
	else return 0;
};


export const useClientVersionCheck = () => {
	const clientHashResult = useQuery<{ clientHash: string }>(queries.clientHash);
	const clientHash = clientHashResult?.data?.clientHash;
	const hashIsValid = !!clientHash && clientHash !== "null";

	const clientVersionResult = useQuery<{ clientVersion: string }>(queries.clientVersion);
	const clientVersionStr = clientVersionResult?.data?.clientVersion;

	const clientPlatformResult = useQuery(queries.clientPlatform);
	const clientPlatform = clientPlatformResult.data?.clientPlatform;
	const platformIsValid = !!clientPlatform.OS;
	const platformIsMacOrWin = clientPlatform?.OS === "Windows" || clientPlatform?.OS === "MacOS";
	const showPromptOnThisPlatform = !platformIsValid || platformIsMacOrWin;

	const globalSettingsResult = useQuery(queries.globalSettings);
	const globalSettings: GlobalSettings | undefined = globalSettingsResult?.data?.globalSettings;
	const latestClientVersions = globalSettings?.latestClientVersions;
	const minimumSupportedClientVersionStr = globalSettings?.minimumSupportedClientVersion;

	const newVersionAvailable = (!!latestClientVersions && hashIsValid) ? !latestClientVersions.some(e => e.versionHash === clientHash) : false;

	const clientVersion = !!clientVersionStr ? versionStringToMajorMinorPatch(clientVersionStr) : {major: 0, minor: 0, patch: 0};
	const minimumSupportedClientVersion = !!minimumSupportedClientVersionStr ? versionStringToMajorMinorPatch(minimumSupportedClientVersionStr) : {major: 0, minor: 0, patch: 0};
	const forceUpdate = (!!clientVersion && !!minimumSupportedClientVersion) ? compareVersions(clientVersion, minimumSupportedClientVersion) < 0 : false;

	return { newVersionAvailable, forceUpdate, showPromptOnThisPlatform };
}
