import React from 'react';
import HeroIcon from './common/hero-icon';

import Draw3DIcon from '../icons/hero/3D_Draw.png';

const Draw3DHeroIcon = (props) =>
{
	const hoveraudiomessage = "Menu/3DDraw/HL";
	const audiomessage = "Menu/3DDraw/Press";

	return (
		<HeroIcon
			name="3D Draw"
			icon={Draw3DIcon}
			route="/app/draw-3d" 
			disabled={props.disabled}
			uiAudioMessage={audiomessage}
			uiHoverAudioMessage={hoveraudiomessage} 
		/>
	);
};

export default Draw3DHeroIcon;