import queries from '../graphql/queries';
import * as speechSdk from "microsoft-cognitiveservices-speech-sdk";
import { SpeechRecognizer } from 'microsoft-cognitiveservices-speech-sdk';
import { getUserSettings } from './user-settings';
import postVuplexMessage from './message-vuplex';
import { getTokenOrRefresh } from '../util/speech-utils';
import { ApolloClient } from '@apollo/client';
import { MicrophoneSettings } from '../graphql/types-generated';

let currentRecognizer: SpeechRecognizer | null;
let currentSinkId: string;
let currentPreviewText: string;

export const SetCurrentSpeechRecognitionSinkId = (apollo: ApolloClient<unknown>, instanceId: string | null, sinkId: string) =>
{
    currentSinkId = sinkId;
    console.log('Speech recognition SinkId for instanceId:' + instanceId + ' set to:' + currentSinkId);
}

export const StartSpeechRecognitionContinuous = async (apollo: ApolloClient<unknown>, instanceId: string) =>
{
    currentPreviewText = '';
    const userSettings = await getUserSettings(apollo);
	const currentLanguage = userSettings.sttCurrentLanguage ?? JSON.parse(localStorage.getItem('sttCurrentLanguage') ?? 'en-US'); // Fall back to old local storage
    console.log('Starting speech recognition in language: ' + currentLanguage + ' for instanceId:' + instanceId + ', sinkId:' + currentSinkId);

    const tokenObj = await getTokenOrRefresh(apollo);
    if (tokenObj?.token === '')
    {
        console.log('Unable to fetch token for speech recognition service!');
        apollo.writeQuery({
            query: queries.speechRecognition,
            data: {
                speechRecognition: {
                    sinkId: currentSinkId,
                    text: '',
                    previewText: '',
                    loading: false,
                    running: false,
                    listening: false,
                    dialogOpen: true,
                    failed: true
                },
            },
            variables: {
                instanceId: instanceId
            }
        });
        return;
    }

    const speechConfig = speechSdk.SpeechConfig.fromAuthorizationToken(tokenObj.token, tokenObj.region);
    speechConfig.speechRecognitionLanguage = currentLanguage;

    const micSettingsResult = await apollo.query<MicrophoneSettings>({ query: queries.microphoneSettings });
    const currentMicrophone = micSettingsResult.data.currentAudioDevice;
    if (!currentMicrophone) {
        console.log("No current audio device found, falling back to system default microphone.");
    }
    const audioConfig = currentMicrophone ? speechSdk.AudioConfig.fromMicrophoneInput(currentMicrophone) : speechSdk.AudioConfig.fromDefaultMicrophoneInput();
    const recognizer = new speechSdk.SpeechRecognizer(speechConfig, audioConfig);
    currentRecognizer = recognizer;

    recognizer.sessionStarted = () => {
        currentPreviewText = '';
        apollo.writeQuery({
            query: queries.speechRecognition,
            data: {
                speechRecognition: {
                    text: '',
                    previewText: currentPreviewText,
                    loading: false,
                    listening: true
                }
            },
            variables: {
                instanceId: instanceId
            }
        });
    }

    recognizer.recognizing = (s, e) =>
    {
        apollo.writeQuery({
            query: queries.speechRecognition,
            data: {
                speechRecognition: {
                    text: '',
                    previewText: currentPreviewText + e.result.text,
                    listening: true
                }
            },
            variables: {
                instanceId: instanceId
            }
        });
    }

    recognizer.recognized = (s, e) => {
        if (e.result.reason == speechSdk.ResultReason.RecognizedSpeech) {
            currentPreviewText += e.result.text + ' ';
            apollo.writeQuery({
                query: queries.speechRecognition,
                data: {
                    speechRecognition: {
                        text: '',
                        previewText: currentPreviewText,
                        listening: false
                    }
                },
                variables: {
                    instanceId: instanceId
                }
            });
        }
        else if (e.result.reason == speechSdk.ResultReason.NoMatch) {
            console.log('Speech recognition canceled: Speech could not be recognized.');
        }
    };

    recognizer.canceled = (s, e) => {
        console.log(`Speech recognition canceled: Reason=${e.reason}`);

        if (e.reason == speechSdk.CancellationReason.Error) {
            console.log(`Speech recognition canceled: ErrorCode=${e.errorCode}`);
            console.log(`Speech recognition canceled: ErrorDetails=${e.errorDetails}`);
            console.log('Speech recognition canceled: Did you set the speech resource key and region values?');
        }

        StopSpeechRecognition(apollo, instanceId);
    };

    recognizer.sessionStopped = (s, e) => {
        console.log('\n    Speech recognition session stopped event.');
        StopSpeechRecognition(apollo, instanceId);
    };

    recognizer.speechEndDetected = (s, e) => {
        console.log('\n    Speech end detected.');
    }

    apollo.writeQuery({
        query: queries.speechRecognition,
        data: {
            speechRecognition: {
                sinkId: currentSinkId,
                text: '',
                previewText: '',
                loading: true,
                running: true,
                listening: true,
                dialogOpen: true,
                failed: false
            }
        },
        variables: {
            instanceId: instanceId
        }
    });

    recognizer.startContinuousRecognitionAsync();

    postVuplexMessage('Speech to text state', { instanceId: instanceId, sinkId: currentSinkId, active: true });
}

export const RestartSpeechRecognitionContinuous = (apollo: ApolloClient<unknown>, instanceId: string | null) =>
{
    currentPreviewText = '';
    apollo.writeQuery({
        query: queries.speechRecognition,
        data: {
            speechRecognition: {
                sinkId: currentSinkId,
                text: '',
                previewText: '',
                listening: true,
            },
        },
        variables: {
            instanceId: instanceId
        }
    });
}

export const StopSpeechRecognition = (apollo: ApolloClient<unknown>, instanceId: string | null) =>
{
    if (currentRecognizer === null)
        return;

    console.log('Stopping speech to text transcription');

    currentRecognizer?.stopContinuousRecognitionAsync();
    currentRecognizer?.close();
    currentRecognizer = null;

    currentPreviewText = '';

    apollo.writeQuery({
        query: queries.speechRecognition,
        data: {
            speechRecognition: {
                running: false,
                loading: false,
                listening: false
            }
        },
        variables: {
            instanceId: instanceId
        }
    });

    postVuplexMessage('Speech to text state', { instanceId: instanceId, sinkId: currentSinkId, active: false });
}
