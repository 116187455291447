import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useQuery, useApolloClient } from '@apollo/react-hooks';
import queries from '../../graphql/queries';
import { postVuplexMessage } from '../../service/message-vuplex';

import GlueButton from '../common/glue-button';
import Lock from '@material-ui/icons/Lock';
import LockOpen from '@material-ui/icons/LockOpen';
import Language from '@material-ui/icons/Language';
import { isAtHome } from '../../util/space-utils';

import TransformAxes from './transform-axes';
import { AxesSupported, applyValue } from '../../util/transform-utils';

const useStyles = makeStyles((theme) => ({
	root: (props) => ({
		width: '100%',
		height: props.compact ? '200px': '935px',
		display: 'grid',
		gridTemplateColumns: props.compact ? '1fr min-content 1fr' : null,
		gridTemplateRows: props.compact ? null : '0.5fr min-content 1fr' ,
		justifyContent: 'center',
		alignItems: props.compact ? 'flex-end' : 'center',
		padding: props.compact ? '8px' : '24px',
		fontSize: props.compact ? '24px' : '30px',

		'&:last-child': {
			alignItems: props.compact ? 'flex-end' : ''
		}
	}),

	transformContainer: (props) => ({
		display: 'grid',
		gap: props.compact ? '20px' : '52px',
		marginLeft: props.compact ? '0px' : '254px'
	}),

	tabletButtons: {
		display: 'grid',
		gridTemplateColumns: 'repeat(2, min-content)',
		gap: '24px',
		justifySelf: 'center',
		marginTop: 'auto',
		alignSelf: 'flex-end'
	},

	hudLockButton: (props) => ({
		'& > button': {
			marginLeft: props.compact ? 'auto' : '222px'
		}
	})
}));

const TransformUI = (props) => {
	const classes = useStyles(props);
	const apollo = useApolloClient();
	const transformRes = useQuery(queries.objectTransform);
	const transform = transformRes.data?.transform;
	const supportRes = useQuery(queries.objectTransformSupport);
	const support = supportRes.data?.objectTransformSupport;
	const lockRes = useQuery(queries.objectTransformLock);
	const locked = lockRes.data?.objectTransformLock;
	const worldSpaceRes = useQuery(queries.objectWorldSpace);
	const worldSpace = worldSpaceRes.data?.objectWorldSpace;
	const currentSpaceServerKeyResult = useQuery(queries.currentSpaceServerKey);
	const atHome = isAtHome(currentSpaceServerKeyResult);

	// TODO: Add loading indicator?
	const [editedField, setEditedField] = useState(null);

	const [drag, setDrag] = useState(null);
	const [dragPosition, setDragPosition] = useState(null);

	useEffect(() => {
		return(() => {
			postVuplexMessage('Enable hotkeys', { value: true });
		})
	}, [])

	const toggleLockOpen = () => {
		postVuplexMessage("Set Object transform lock", {value:  !locked});
		
		apollo.writeQuery({
			query: queries.objectTransformLock,
			data: { objectTransformLock: !locked }
		});
	}

	const toggleCoordinateSpace = () => {
		postVuplexMessage("Set Object world space", {value: !worldSpace});

		apollo.writeQuery({
			query: queries.objectWorldSpace,
			data: { objectWorldSpace: !worldSpace }
		});
	}

	const lockButton =
	(
		(props.compact) ?
		<GlueButton
			variant='compactIcon'
			toggled={locked}
			onPointerDown={() => toggleLockOpen()}
		>
			{!locked ? <LockOpen style={{fontSize: '36px'}}/> : <Lock style={{fontSize: '36px'}}/>}
		</GlueButton> : 
		<GlueButton
			toggled={locked}
			onPointerDown={() => toggleLockOpen()}
			textTransform={'capitalize'}
			letterSpacing={'0px'}
		>
			{!locked ? 
			<>
				Lock Transform
				<LockOpen style={{ marginLeft: 'auto' }} /> 
			</>
			: 
			<>
				Unlock Transform
				<Lock style={{ marginLeft: 'auto' }}/>
			</>
			}
		</GlueButton>
	);

	const coordSpaceButton =
	(
		(props.compact) ?
		<GlueButton
			variant='compactIcon'
			toggled={worldSpace}
			onPointerDown={() => toggleCoordinateSpace()}
		>
			<Language style={{fontSize: '36px'}}/>
		</GlueButton> : 
		<GlueButton
			toggled={worldSpace}
			onPointerDown={() => toggleCoordinateSpace()}
			textTransform={'capitalize'}
			letterSpacing={'0px'}
		>
			World Coordinates <Language style={{marginLeft: 'auto'}} />
		</GlueButton>
	);

	const getLastValidFieldValue = (transformType, axis) =>
	{
		return parseFloat(transform[transformType][axis].toFixed(2));
	}

	const getFieldValue = (transformType, axis) =>
	{
		if (editedField && editedField.transformType === transformType && editedField.axis === axis && !editedField.drag)
		{
			return editedField.value;
		}
		else return getLastValidFieldValue(transformType, axis);
	}

	const resetFieldValue = (transformType, axis) =>
	{
		if (editedField?.transformType !== transformType || editedField?.axis !== axis)
			return;

		let newEditedField = {...editedField};
		newEditedField.value = getLastValidFieldValue(transformType, axis);
		setEditedField(newEditedField);
	}

	const editField = (transformType, axis, value, keepProportions) =>
	{
		setDrag(null);
		setEditedField({ transformType: transformType, axis: axis, value: value, keepProportions: keepProportions });
		applyValue(apollo, transformType, axis, value, support, keepProportions);
	}

	const posValue = {
		x: getFieldValue('position', 'x'),
		y: getFieldValue('position', 'y'),
		z: getFieldValue('position', 'z')
	}

	const rotValue = {
		x: getFieldValue('rotation', 'x'),
		y: getFieldValue('rotation', 'y'),
		z: getFieldValue('rotation', 'z')
	}

	const sclValue = {
		x: getFieldValue('scale', 'x'),
		y: getFieldValue('scale', 'y'),
		z: getFieldValue('scale', 'z')
	}

	const startDrag = (transformType, axis, startValue, startPos, keepProportions) =>
	{
		setEditedField(null);

		// Make sure value is a proper float
		let floatValue = parseFloat(startValue ? startValue : "0.0");

		if (!isFinite(floatValue))
			floatValue = getLastValidFieldValue(transformType, axis);

		setDrag({ transformType: transformType, axis: axis, keepProportions: keepProportions, startPos: startPos, startValue: floatValue });
	}

	useEffect(() => {
		if (drag)
		{
			const dragRate = drag.transformType === 'rotation' ? 0.2 : 0.0025;

			let diff = dragPosition - drag.startPos;
			let newValue = parseFloat(drag.startValue) + (diff * dragRate);
			applyValue(apollo, drag.transformType, drag.axis, newValue, support, drag.keepProportions);
		}
	}, [drag, dragPosition, support, applyValue])

	useEffect(() => {
		const onDrag = (e) =>
		{
			setDragPosition(e.screenX);
		}
	
		const onDragEnd = () =>
		{
			setDrag(null);
		}

		document.addEventListener('pointermove', onDrag);
		document.addEventListener('pointerup', onDragEnd);

		return () => {
			document.removeEventListener('pointermove', onDrag);
			document.removeEventListener('pointerup', onDragEnd);
		}
	}, [])

	return (
		<div className={classes.root}>
			{props.compact ? 
				<div>
					{coordSpaceButton}
				</div> : 
				<div></div>
			}
			<div className={classes.transformContainer}>
					<TransformAxes 
						transform={'position'}
						label={'Position'}
						value={posValue}
						dragAxis={drag?.transformType === 'position' ? drag.axis : null}
						onChangeField={(axis, value) => editField('position', axis, value, false, false)}
						onSubmitField={(axis) => resetFieldValue('position', axis)}
						onDragBegin={(axis, startPos) => startDrag('position', axis, posValue[axis], startPos)}
						xDisabled={locked || !support.move}
						yDisabled={locked || !support.move}
						zDisabled={locked || !support.move}
						compact={props.compact}
					/>
					<TransformAxes 
						transform={'rotation'}
						label={'Rotation'}
						value={rotValue}
						dragAxis={drag?.transformType === 'rotation' ? drag.axis : null}
						onChangeField={(axis, value) => editField('rotation', axis, value, false, false)}
						onSubmitField={(axis) => resetFieldValue('rotation', axis)}
						onDragBegin={(axis, startPos) => startDrag('rotation', axis, rotValue[axis], startPos)}
						xDisabled={locked || !support.rotate}
						yDisabled={locked || !support.rotate}
						zDisabled={locked || !support.rotate}
						compact={props.compact}
					/>
					<TransformAxes 
						transform={'scale'}
						label={'Scale'}
						value={sclValue}
						dragAxis={drag?.transformType === 'scale' ? drag.axis : null}
						onChangeField={(axis, value, keepProportions) => editField('scale', axis, value, keepProportions, false)}
						onSubmitField={(axis) => resetFieldValue('scale', axis)}
						onDragBegin={(axis, startPos, keepProportions) => startDrag('scale', axis, sclValue[axis], startPos, keepProportions)}
						xDisabled={locked || (support.scale & AxesSupported.X) === 0}
						yDisabled={locked || (support.scale & AxesSupported.Y) === 0}
						zDisabled={locked || (support.scale & AxesSupported.Z) === 0}
						allowKeepProportions={true}
						compact={props.compact}
					/>
			</div>
			{!props.compact && 
				<div className={classes.tabletButtons}>
					{coordSpaceButton}
					{!atHome && 
						<div>
							{lockButton}
						</div>
					}
				</div>
			}
			
			{props.compact && !atHome && <div className={classes.hudLockButton}>
				{lockButton}
			</div>}
		</div>
	)
}

export default TransformUI;
