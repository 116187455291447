import React, { useState } from 'react';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import {ReactComponent as ClearIcon} from '../../icons/Close.svg';
import SVGIconWrapper from '../common/svg-icon-wrapper';

import { COMMON_ASSET_VERSION_POINTER_NAMES, setTrackVersionPointer } from './space-asset-common';
import queries from '../../graphql/queries';
import GlueButton from '../common/glue-button';
import CommonTextValuePicker from '../common/common-text-value-picker';

const useStyles = makeStyles((theme) => ({
	root: {

	},

	targetSpace: {
		background: theme.palette.background.paper,
		marginBottom: '32px',
	},

	error: {
		background: 'red',
		border: '4px solid red'
	},

	targetSpaceHeader: {
		display: 'grid',
		gridTemplateColumns: '1fr min-content',
		alignItems: 'center'
	}
}));

const MassConvertSpaces = () =>
{
	const classes = useStyles();
	const apollo = useApolloClient();

	const currentTeamIdRes = useQuery(queries.currentTeamId);
	const mySpacesRes = useQuery(queries.myRooms, {
		variables: {
			teamId: currentTeamIdRes.data?.currentTeamId
		},
		skip: !currentTeamIdRes.data?.currentTeamId
	});

	const [filterTrackPointerName, setFilterTrackPointerName] = useState('');

	const [pointerName, setPointerName] = useState('');
	const [targetSpaces, setTargetSpaces] = useState([]);
	const [applyInProgress, setApplyInProgress] = useState(false);
	const [applyStatusMsg, setApplyStatusMsg] = useState("");

	const findSpaces = async () =>
	{
		if (!mySpacesRes.data?.myRooms)
		{
			console.error("Spaces not ready.");
			return;
		}

		const filtered = mySpacesRes.data.myRooms.filter(
			(item) => (
				(
					!filterTrackPointerName &&
					!item.trackVersionPointer
				) || (
					filterTrackPointerName &&
					item.trackVersionPointer &&
					item.trackVersionPointer === filterTrackPointerName
				)
			)
		);

		setTargetSpaces(filtered);
	};

	const removeFromTargets = (i) =>
	{
		targetSpaces.splice(i, 1);
		setTargetSpaces([...targetSpaces]);
	};

	const apply = async () =>
	{
		setApplyInProgress(true);
		setApplyStatusMsg("Updating target Spaces. Please wait...");

		const leftOverSpaces = [];

		for (let i = 0; i < targetSpaces.length; ++i)
		{
			const space = targetSpaces[i];
			const response = await setTrackVersionPointer(apollo, space.roomId, pointerName);

			if (!response.ok)
			{
				space.error = response.status;
				leftOverSpaces.push(space);
			}
		}

		if (leftOverSpaces.length > 0)
		{
			setApplyStatusMsg("There were problems updating some Spaces");
		}
		else
		{
			setApplyStatusMsg("All target Spaces have been updated");
		}

		setTargetSpaces(leftOverSpaces);
		setApplyInProgress(false);

		mySpacesRes.refetch();
	};

	return (
		<div className={classes.root}>
			<h3>Search criteria:</h3>

			<label>
				Filter by tracking version pointer name:
				<CommonTextValuePicker
					placeholder={"Pointer name"}
					value={filterTrackPointerName}
					onChange={(value) => setFilterTrackPointerName(value)}
					options={['', ...COMMON_ASSET_VERSION_POINTER_NAMES]}
				/>
			</label>

			<GlueButton onPointerDown={findSpaces}>Find Spaces</GlueButton>

			{mySpacesRes.loading && (
				<i>Loading...</i>
			)}

			<h3>Modifications:</h3>
			<label>
				Set tracking version pointer name
				<CommonTextValuePicker
					value={pointerName}
					onChange={(value) => setPointerName(value)}
					placeholder={"Pointer name"}
					options={COMMON_ASSET_VERSION_POINTER_NAMES}
				/>
			</label>

			<h3>Target Spaces:</h3>
			<ul>
				{targetSpaces.map((space, index) => (
					<li key={space.roomId} className={clsx(classes.targetSpace, (space.error && (classes.error)))}>
						<div className={classes.targetSpaceHeader}>
							<div>{space.name}</div>
							<GlueButton
								variant='icon'
								onPointerDown={() => removeFromTargets(index)}
							>
								<SVGIconWrapper><ClearIcon /></SVGIconWrapper>
							</GlueButton>
						</div>
						<div>Tracking: {space.trackVersionPointer}</div>
					</li>
				))}
			</ul>

			<GlueButton disabled={applyInProgress} onPointerDown={apply}>Apply</GlueButton>
			<p>Status: {applyStatusMsg}</p>
		</div>
	);
};

export default MassConvertSpaces;
