import React from 'react';
import { makeStyles } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import { clickAudio, hoverAudio } from '../common/common-vuplex-messages';

const useStyles = makeStyles(theme => ({
	root: {
		alignItems: 'center',
		justifyContent: 'center',
		display: 'flex',

		"& .MuiSlider-thumb": {
			height: '12px',
			width: '12px'
		}
	},
	thumb: {
		backgroundColor: props => props.ThumbComponent ? 'transparent' : props.isActive ? '#fff' : '#808080',

		"&:focus,&:hover,&$active": {
			boxShadow: 'none'
		},

		'& .outerRing': {
			height: '32px',
			width: '32px',
			backgroundColor: 'white',
			borderRadius: '16px',
			position: 'fixed'
		},

		'& .innerRing': {
			height: '24px',
			width: '24px',
			backgroundColor: props => props.thumbColor ? 'black' : theme.palette.primary.main,
			borderRadius: '12px',
			position: 'fixed'
		},

		'& .indicatorRing': {
			height: '16px',
			width: '16px',
			backgroundColor: props => props.thumbColor,
			borderRadius: '8px',
			position: 'fixed',
			zIndex: props => props.thumbColor ? 1 : -1
		}
	},
	active: {},
	track: {
		height: props => props.height ? props.height : '16px',
		borderRadius: '12px',
		opacity: 1,
		position: 'absolute',
		margin: 'auto',
		top: '0',
		bottom: '0',
		color: props => props.transparent ? 'transparent' : props.isActive ? theme.palette.primary.main : '#5a5a5a',
	},
	rail: {
		height: props => props.height ? props.height : '8px',
		borderRadius: props => props.height ? props.height / 2 : 4,
		opacity: 1,
		position: 'absolute',
		margin: 'auto',
		top: '0',
		bottom: '0',
		color: props => props.backgroundImage ? 'transparent' : props.isActive ? '#808080' : '#5a5a5a',
		backgroundImage: props => props.backgroundImage,
	},
	mark: {
		backgroundColor: '#ffffff',
		height: props => props.height,
		width: '2px',
		bottom: '0',
		top: '0',
		opacity: 0.4,
		margin: 'auto',
	},
}), { name: "MuiGlueSliderSelector" });

const GlueSliderThumbComponent = (props) => {
	return (
		<span {...props}>
			<span className="outerRing" />
			<span className="innerRing" />
			<span className="indicatorRing" />
		</span>
	);
}

const SliderSelector = (props) => {
	const classes = useStyles(props);
	const audiomessage = !!props.uiAudioMessage ? props.uiAudioMessage : "Tools/SlideSelector/Press";
	const releaseaudiomessage = !!props.uiReleaseAudioMessage ? props.uiReleaseAudioMessage : "Tools/SlideSelector/Release";
	const hoveraudiomessage = !!props.uiHoverAudioMessage ? props.uiHoverAudioMessage : "Tools/SlideSelector/HL";

	return (
		<div className={classes.root}>
			<Slider classes={{ rail: classes.rail, track: classes.track, thumb: classes.thumb, mark: classes.mark }}
				ThumbComponent={GlueSliderThumbComponent}
				style={props.style}
				onChange={props.onChange}
				onDragStop={props.onDragStop}
				onChangeCommitted={props.onChangeCommitted}
				onPointerDown={() => {clickAudio(audiomessage)} }
				onPointerUp={() => {clickAudio(releaseaudiomessage)}} 
				onPointerEnter={() => {hoverAudio(hoveraudiomessage)}}
				defaultValue={props.defaultValue}
				value={props.value}
				step={props.step}
				min={props.min}
				max={props.max}	
				marks={props.marks}	
				disabled={props.disabled}
			/>
		</div>
	);
};

export default SliderSelector;
