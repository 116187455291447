import { useState, useEffect } from 'react';
import { configuratorInitialized, 
	getCurrentAvatar, 
	getAvatarName,
	setAvatarName,
	avatarIsDirty} from '../../service/avatar-configurator';
import { AvatarDirtiness, OnAvatarChangedEvent, OnNameChangedEvent } from './avatar-types';

export const useAvatar = (): OnAvatarChangedEvent =>
{
	if (!configuratorInitialized()) {
		throw new Error("Trying to use getAvatar hook while configurator is not initialized")
	}
	const defaultAvatar = { avatar: getCurrentAvatar(), respawn: false };
	const [ avatar, setAvatar ] = useState<OnAvatarChangedEvent>(defaultAvatar);

	const updateAvatar = (e: Event) =>
	{
		setAvatar((e as CustomEvent<OnAvatarChangedEvent>).detail);
	}

	useEffect(() => {
		window.addEventListener('onAvatarChanged', updateAvatar);
		return () => {
			window.removeEventListener('onAvatarChanged', updateAvatar);
		}
	});

	return avatar;
}

export const useNickname = () =>
{
	const [ nickname, setNickname ] = useState(configuratorInitialized() ? getAvatarName() : null);

	const updateName = (e: Event) =>
	{
		setNickname((e as CustomEvent<OnNameChangedEvent>).detail.nickname);
	}

	useEffect(() => {
		window.addEventListener('onAvatarNameChanged', updateName);
		return () => {
			window.removeEventListener('onAvatarNameChanged', updateName);
		}
	});

	return [ nickname, setAvatarName ];
}

export const useAvatarDirty = () =>
{
	const [ dirty, setDirty ] = useState<AvatarDirtiness>({ nicknameDirty: false, avatarDirty: false });

	const updateDirtiness = (e: Event) =>
	{
		setDirty(avatarIsDirty());
	}

	useEffect(() => {
		window.addEventListener('onAvatarChanged', updateDirtiness);
		window.addEventListener('onAvatarNameChanged', updateDirtiness);
		return () => {
			window.removeEventListener('onAvatarChanged', updateDirtiness);
			window.removeEventListener('onAvatarNameChanged', updateDirtiness);
		}
	});

	return dirty;
}