import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import queries from '../graphql/queries';
import { makeStyles } from '@material-ui/core/styles';
import { Navigate, Route, Routes } from "react-router-dom";

import MainMenuHeader from './main-menu-header';
import MainView from './views/main-view';
import MyAvatarView from './views/my-avatar-view';
import HelpView from './views/help-view';
import SettingsView from './views/settings-view';
import OrganizationView from './views/organization-view';
import AdministrativeView from './sys-admin-tools/administrative-view';

const useStyles = makeStyles(theme => ({
	root: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
}), { name: 'MuiGlueMainMenu' });

const MainMenu = (props) =>
{
	const classes = useStyles();

	const uiMode = useQuery(queries.ui);
	const web = uiMode.data?.ui === 'web';

	return (
		<div className={classes.root}>
			<MainMenuHeader web={web}/>
			<Routes>
				<Route path='/' element={<Navigate to={'main'} />} />
				<Route path={web ? 'my-avatar/*' : 'avatarconfigurator/*'} element={<MyAvatarView web={web}/>} />
				<Route path={'main/*'} element={<MainView web={web}/>} />
				<Route path={'organization/*'} element={<OrganizationView web={web} />} />

				{web ? (
					<Route path={'sys-admin-tools/*'} element={<AdministrativeView />} />
				) : (<>
					<Route path={'help/*'} element={<HelpView />} />
					<Route path={'settings/*'} element={<SettingsView />} />
				</>)}
			</Routes>
		</div>
	);
}

export default MainMenu;
