import React from 'react';
import InputField from './glue-inputfield';
import GlueDropdown from './glue-dropdown';

const DropdownSelectorInput = (props) =>
{
	const inputField = (
		<InputField
			centerText
			backgroundColor={'black'}
			simpleInput
			width={'96px'}
			onInputChange={props.onInputChange}
			value={props.inputValue}
			onSubmit={props.onSubmit}
			onBlur={props.onBlur}
		/>
	);

	return (
		<GlueDropdown
			width={props.width}
			value={props.value}
			onChange={props.onChange}
			items={props.items}
			label={inputField}
			perpetualLabel
		/>
	);
};

export default DropdownSelectorInput;
