import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useNavigate, useLocation, matchPath } from 'react-router-dom';
import GlueTab from './glue-tab';

import { AudioMessageTypeProps } from '../../types/audio-messages';

type TabRowProps = {
	tabInfos: TabInfoProps[],
	onClickTab?: (s: string) => void | null,
	audioProps?: AudioMessageTypeProps
}

export type TabInfoProps = {
	name: string,
	path: string,
	audioProps?: AudioMessageTypeProps
}

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexFlow: 'row nowrap',
		justifyContent: 'center',
		alignItems: 'center',
		gap: theme.glueSpacing('xl'),
	},
}), { name: 'MuiGlueTabRow' });

const TabRow = (props: TabRowProps) => {
	const classes = useStyles();
	const navigate = useNavigate();
	const location = useLocation();

	const onClickTab = (tabInfo: TabInfoProps) => {
		navigate(tabInfo.path);
		if (props.onClickTab)
			props.onClickTab(tabInfo.name);
	}

	const isToggled = (path: string, currentLocation: string) =>
	{
		if (matchPath(path, currentLocation)) {
			return true;
		}
		const relativePathValue = currentLocation.split('/');
		if (path === relativePathValue[relativePathValue.length - 1]) {
			return true;
		}
		return false;
	}

	return (
		<div className={classes.root}>
			{props.tabInfos.map((tabInfo: TabInfoProps, i: number) => (
				<GlueTab
					key={i}
					label={tabInfo.name}
					toggled={isToggled(tabInfo.path, location.pathname)}
					onPointerDown={() => onClickTab(tabInfo)}
					uiAudioMessage = {tabInfo.audioProps?.uiaudiomessage}
					uiHoverAudioMessage = {tabInfo.audioProps?.uihoveraudiomessage}
					textTransform={'uppercase'}
					letterSpacing={'2px'}
				/>
			))}
		</div>
	)
}

export default TabRow;