import React, { ReactNode } from 'react';
import { makeStyles, ButtonBase } from '@material-ui/core';
import { hoverAudio, clickAudio } from './common-vuplex-messages';
import { Palette, PaletteColor } from '@material-ui/core/styles/createPalette';

type ToggleProps = {
	color?: keyof Palette,
	toggled?: boolean,
	uiAudioMessage?: string,
	uiHoverAudioMessage?: string,
	disabled?: boolean,
	onPointerDown?: (e: React.PointerEvent) => void
	onClick?: () => void,
	children?: ReactNode[] | ReactNode
}

const useStyles = makeStyles((theme) => ({
	root: (props: ToggleProps) => ({
		...theme.custom.glueToggle,

		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',

		background: props.toggled ? (theme.palette[props.color ?? 'secondary'] as PaletteColor).active : (theme.palette[props.color ?? 'secondary'] as PaletteColor).main,
		color: props.toggled ? (theme.palette[props.color ?? 'secondary'] as PaletteColor).textActive : (theme.palette[props.color ?? 'secondary'] as PaletteColor).contrastText,

		'&:hover': {
			filter: 'brightness(1.25)'
		},

		'&:active': {
			filter: 'brightness(1.50)'
		},

		'&:disabled': {
			opacity: '50%'
		}
	}),
}), { name: 'MuiGlueToggle' });

const onClickIcon = (props: ToggleProps, e: React.PointerEvent) =>
{
	if (typeof props.onPointerDown === 'function')
	{
		props.onPointerDown(e);
	}
};

const GlueToggle = (props: ToggleProps) => 
{
	const classes = useStyles(props);
	const audiomessage = !!props.uiAudioMessage ? props.uiAudioMessage : "Menu/Generic/Button/Press";
	const hoveraudiomessage = !!props.uiHoverAudioMessage ? props.uiHoverAudioMessage : "Menu/Generic/Button/HL";

	return (
		<ButtonBase className={classes.root} disabled={props.disabled}
			onPointerDown={(e) => {onClickIcon(props, e); clickAudio(audiomessage)} }
			onPointerEnter={() => {hoverAudio(hoveraudiomessage)}}
			onClick={props.onClick}
		>
			{props.children}
		</ButtonBase>
	);
};

export default GlueToggle;
