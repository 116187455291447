import postVuplexMessage from "./message-vuplex";
import { getUserSettings } from "./user-settings";

import { ApolloClient } from "@apollo/client";
import queries from "../graphql/queries";

const sendClientSettings = async (apollo: ApolloClient<unknown>) =>
{
	const userSettings = await getUserSettings(apollo);
	const emailRes = await apollo.query<{ myEmail: string }>({
		query: queries.myEmail
	});
	const email = emailRes.data.myEmail;

	postVuplexMessage("Set nickname", { value: userSettings.nickname ? userSettings.nickname : email });
	if (userSettings.avatar?.config)
	{
		postVuplexMessage("Set avatar", userSettings.avatar.config);
		postVuplexMessage("Respawn avatar", null);
	}
	postVuplexMessage("Set personal space", { value: userSettings.personalSpaceProtection });
	postVuplexMessage("Set grabbing mode", { value: userSettings.vrGrabbingMode });
	postVuplexMessage("Set voice comm", { value: userSettings.voiceCommunicationOnly });
	postVuplexMessage('Change speech to text language', { value: userSettings.sttCurrentLanguage });
	postVuplexMessage('Set force tunneling', { value: userSettings.forceTunneling });
	postVuplexMessage('Set HandUI opacity', { value: userSettings.handUiOpacity });
}

export const initializeClient = async (apollo: ApolloClient<unknown>) => 
{
	return Promise.all([sendClientSettings(apollo)]);
};