import React from 'react'
import { Dialog, makeStyles } from '@material-ui/core'
import GlueScroll from './common/glue-scroll'
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined'
import GlueButton from './common/glue-button'
import Pagination from './common/pagination'

const useStyles = makeStyles(theme => ({
	root: {
		width: '1152px',
		height: '976px',
		overflow: 'hidden',
		display: 'flex',
		flexFlow: 'column',
		gap: theme.glueSpacing('m'),
		paddingBottom: theme.glueSpacing('xl')
	},

	topBar: {
		height: '64px',
		display: 'grid',
		gridTemplateColumns: '1fr 3fr 1fr',
		overflow: 'hidden'
	},

	title: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center'
	},

	topRight: {
		display: 'flex',
		justifyContent: 'end',
		gap: theme.glueSpacing('m')
	},

	closeButton: {
		width: theme.custom.icon.width
	},

	scrollContent: {
		width: '960px',
		marginRight: '40px',
		marginLeft: 'auto',
	}
}), { name: 'MuiGluePolicyDialog' })

const PolicyDialog = (props) => {
	const classes = useStyles()

	return (
		<Dialog
			open={props.open}
			maxWidth={false}
		>
			<div className={classes.root}>
				<div className={classes.topBar}>
					<div />
					<div className={classes.title}>
						<h1>{props.title}</h1>
					</div>
					<div className={classes.topRight}>
						{props.pageCount && props.pageCount > 1 && (
							<Pagination current={props.currentPage} pageCount={props.pageCount}/>
						)}
						<div className={classes.closeButton}>
							{!props.disableCloseBtn && (
								<GlueButton variant='icon' color='stealth' onPointerDown={props.onCloseDialog}>
									<CloseOutlinedIcon />
								</GlueButton>
							)}
						</div>
					</div>
				</div>
				<GlueScroll key={props.currentPage}>
					<div className={classes.scrollContent}>
						{props.children}
					</div>
				</GlueScroll>
			</div>
		</Dialog>
	)
}

export default PolicyDialog
