import { CSSProperties } from "@material-ui/core/styles/withStyles";

type DisplayFont = {
	[variant: string]: CSSProperties
}

export type Type = {
	displayRow: CSSProperties
	displayFont: DisplayFont
};

const common: Type =
{
	displayRow: {
		display: 'grid',
		gap: '24px',
		gridTemplateColumns: '64px 64px 16px 64px 64px 16px 64px 64px',
		gridTemplateRows: 'min-content',
		justifyItems: 'center'
	},
	displayFont: {
		toolbar: {
			fontSize: '36px',
			lineHeight: '36px',
			fontWeight: 700
		},
		handui: {
			fontSize: '32px',
			lineHeight: '32px',
			fontWeight: 600
		},
		full: {
			fontSize: '160px',
			lineHeight: '160px',
			fontWeight: 700
		}
	}
};

export const web: Type =
{
	...common,
};

export const tablet: Type =
{
	...common
};
