import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import GlueButton from './common/glue-button'

const useStyles = makeStyles(theme => ({
	vrBody: {
		flex: '1 0 auto',
		height: '144px',
		background: theme.palette.background.paper,
		display: 'flex',
		justifyContent: 'center',
		
		flexDirection: 'row',
		padding: '40px 56px 0 56px',

		'& button:hover':{
			background: theme.palette.secondary.dark,
		}
	},
}));

const ToolbarVR = (props) => {
	const classes = useStyles();

	return (
		<div className={classes.vrBody}>
			<GlueButton
				variant="compact"
				onPointerDown={() => props.toggleVR(false)}
				color='primary'
				textTransform={'none'}
			>
			Exit VR mode
			</GlueButton>
		</div>
	);
}

export default ToolbarVR;