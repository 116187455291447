import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import queries from '../../graphql/queries';
import { getFileType } from '../../util/file-utils';

const useStyles = makeStyles((theme) => ({
	root: {
		...theme.custom.icon,
		background: theme.palette.background.paper,
		borderRadius: '4px',

		'&> img': {
			height: '100%',
			width: '100%',
			objectFit: 'cover',
			borderRadius: '4px',
		}
	},
}), { name: 'MuiGlueFileThumbnail' });

const FileThumbnail = (props) => {
	const classes = useStyles();

	const filetype = getFileType(props.item);

	const showThumbnailForType = (filetype) =>
	{
		const accepted = ['png', 'jpeg'];
		return accepted.some((acceptedType) => acceptedType === filetype);
	};

	const signInventoryItemGetRes = useQuery(
		queries.signInventoryItemGet,
		{
			skip: !showThumbnailForType(filetype),
			variables: { inventoryItemId: props.item.inventoryItemId },
			fetchPolicy: 'cache-and-network'
		}
	);

	const thumbnailUrl = !!signInventoryItemGetRes.data?.signInventoryItemGet.thumbnailURL ? (
		signInventoryItemGetRes.data?.signInventoryItemGet.thumbnailURL
	) : ( 
		null
	);

	return (
		<div className={classes.root}>
			{thumbnailUrl ? (
				<img src={thumbnailUrl} alt="" />
			) : (
				<Typography variant='overline'>{filetype}</Typography>
			)}
		</div>
	);
}

export default FileThumbnail;
