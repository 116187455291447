import React from 'react';
import { makeStyles } from '@material-ui/core';
import { clickAudio, hoverAudio } from './common-vuplex-messages';
import GlueIcon from './glue-icon';

const useStyles = makeStyles((theme) => ({
	root: ({selected, disabled}) => ({
		maxWidth: '100%',
		height: theme.custom.glueListItem.height,

		color: theme.palette.secondary.contrastText,
		opacity: disabled ? '50%' : '100%',
		paddingLeft: theme.glueSpacing('m'),
		display: 'flex',
		alignItems: 'center',
		gap: theme.glueSpacing('s'),
		...theme.custom.hoverOverlay(selected),

		'& p': {
			width: '100%',
			whiteSpace: 'nowrap',
			opacity: selected ? '100%' : '60%',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			marginRight: 'auto'
		},
	}),
}), { name: 'MuiGlueMenuItem' });

const GlueMenuItem = (props) => {
	const classes = useStyles(props);

	const audiomessage = !!props.uiAudioMessage ? props.uiAudioMessage : "Menu/Generic/Button/Press";
	const hoveraudiomessage = !!props.uiHoverAudioMessage ? props.uiHoverAudioMessage : "Menu/Generic/Button/HL";

	const onClick = () => {
		if (!props.disabled)
		{
			clickAudio(audiomessage);
			if (props.onPointerDown)
				props.onPointerDown();
		}
	}

	const formatChildren = (children) =>
	{
		const count = React.Children.count(children);
		const formattedResult = [];

		for (let i = 0; i < count; i++)
		{
			if (i === 0) // First child is label
			{
				if (count === 1)
				{
					formattedResult.push(
						<>
							<p>{children}</p>
							<GlueIcon/>
						</>
					);
				}
				else
				{
					formattedResult.push(
						<p key={'label'}>{children[0]}</p>
					);
				}
			}
			else if (i === 1) // 2nd child is icon
			{
				formattedResult.push(
					<GlueIcon key={'iconContainer'}>
						{children[1]}
					</GlueIcon>
				);
			}
			else // The rest is just random garbage
			{
				formattedResult.push(
					<>
						{children[i]}
					</>
				);
			}
		}

		return formattedResult;
	}

	return (
		<div className={classes.root} onPointerDown={onClick} onPointerEnter={() => {hoverAudio(hoveraudiomessage)}}>
			{formatChildren(props.children)}
		</div>
	);
}

export default GlueMenuItem;