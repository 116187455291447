import React, { useState, useEffect } from 'react';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core';
import GlueDropdown from '../common/glue-dropdown';
import GlueInputfield from '../common/glue-inputfield';
import queries from '../../graphql/queries';
import SpaceTile from './space-tile';
import PageSelector from '../common/page-selector';
import LoadingIndicator from '../common/loading-indicator';
import { sttActive } from '../../util/speech-utils';
import WebInputfield from '../../standalone-web/common/web-inputfield';
import GlueButton from '../common/glue-button';
import { usePromptDialogContext } from "../../util/prompt-dialog-context";
import { useUserContext } from '../../util/user-context';
import SpaceTemplatesDialog from './space-templates-dialog';
import { Room, SpaceTemplateDownloadState, TeamAssetAccessResponse } from '../../graphql/types-generated';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddIcon from '@material-ui/icons/Add';
import GlueIcon from '../common/glue-icon';
import { refreshSpaces, requestCachedSpaceTemplates } from '../../util/space-utils';
import GeneralDialogTemplate from '../common/general-dialog-template';
import errorImage from '../../images/ErrorIcon_Tablet.png';

// Config
const tilesPerPageCount = 8;

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		gap: theme.glueSpacing('m'),
		height: '100%'
	},

	header: {
		height: theme.custom.header.height,
		minHeight: theme.custom.header.height,
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		gap: theme.glueSpacing('m'),
	},

	pageSelector: {
		marginLeft: 'auto'
	},

	body: {
		width: '100%',
		gap: theme.glueSpacing('m'),
		...theme.custom.spaces.body,
	},

	noSpacesMessage: {
		height: '100%',
		minHeight: '200px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},

	loadingIndicator: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		minHeight: '600px'
	},

	webBottomPadding: {
		height: '100px',
		minHeight: '100px'
	},
}), { name: 'MuiGlueSpaces' });

const sortByName = (rooms: Room[]) => {
	return rooms.sort(function(a: Room, b: Room) {
		const x = a.name.toLowerCase(); const y = b.name.toLowerCase();
		return ((x < y) ? -1 : ((x > y) ? 1 : 0));
	});
}

const Spaces = (props: {
	web?: boolean
}) =>
{
	const classes = useStyles();
	const theme = useTheme();
	const user = useUserContext();
	const apollo = useApolloClient();

	const { addDialog } = usePromptDialogContext();

	const [page, setPage] = useState<number>(0);
	const [sortingMethod, setSortingMethod] = useState<string | number>('name');
	const [searchWord, setSearchWord] = useState<string>('');

	const currentRoomServerKey = useQuery(queries.currentSpaceServerKey);
	const teamIdResult = useQuery(queries.currentTeamId);
	const currentOrgRes = useQuery(queries.currentOrgId);
	const sttRes = useQuery(queries.speechRecognition);
	const assetAccessRes = useQuery(queries.teamAssetsAccess, {
		skip: !teamIdResult.data?.currentTeamId,
		variables: {
			teamId: teamIdResult.data.currentTeamId,
			typeFilter: 'space'
		}
	});

	const [teamId, setTeamId] = useState(teamIdResult.data?.currentTeamId); // Currently displaying this team's spaces

	const searchSTTSinkId = "SpacesAppSearch";
	const newSpaceLabel = props.web ? "Create a new space" : <GlueIcon><AddIcon /></GlueIcon>;
	const manageTemplatesLabel = props.web ? "Manage templates" : "Templates";
	
	const selectSortingMethod = (id: string | number, checked: boolean) => {
		if (checked)
			setSortingMethod(id);
	};

	const myRoomsRes = useQuery(queries.myRooms, {
		skip: !teamIdResult.data?.currentTeamId,
		variables: {
			teamId: teamIdResult.data.currentTeamId,
			first: 0
		},
		notifyOnNetworkStatusChange: true,
		fetchPolicy: "network-only"
	});

	const cachedTemplatesRes = useQuery<{ cachedSpaceTemplateUrls: string[] }>(queries.cachedSpaceTemplateUrls);
	const cachedTemplateUrls = cachedTemplatesRes.data?.cachedSpaceTemplateUrls ?? [];

	const templateDownloadRes = useQuery<{ spaceTemplateDownloadState: SpaceTemplateDownloadState }>(queries.spaceTemplateDownloadState);
	const templateDownloadState = templateDownloadRes.data?.spaceTemplateDownloadState;

	const joinErrorRes = useQuery(queries.joinSpaceError);

	const sortOptions = [
		{ id: 'name', name: 'Sort by name' },
		{ id: 'users', name: 'Sort by users'}
	];

	const sortaudiomessage = "Menu/TeamSpaces/Sort/Press";
	const sorthoveraudiomessage = "Menu/TeamSpaces/Sort/HL";
	const sortselaudiomessage = "Menu/TeamSpaces/Sort/Select/Press";
	const sortselhoveraudiomessage = "Menu/TeamSpaces/Sort/Select/HL";

	useEffect(() => {
		// Refetch rooms if cached room data doesn't match teamId
		if (teamIdResult.data && teamIdResult.data.currentTeamId !== teamId)
		{
			setPage(0);
			setTeamId(teamIdResult.data.currentTeamId);
			myRoomsRes.refetch();
		}
	}, [teamIdResult, teamId, myRoomsRes]);

	useEffect(() => {
		requestCachedSpaceTemplates();
	}, []);

	useEffect(() => {
		if (!!joinErrorRes.data?.joinSpaceError) {
			addDialog(
				<GeneralDialogTemplate
					header={'Error joining space'}
					body={(
						<>
							<p>Joining space failed with the following message:</p>
							<p>{joinErrorRes.data.joinSpaceError}</p>
						</>
					)}
					image={errorImage}
					callbacks={[
						{ label: 'OK', callback: () => {
							apollo.writeQuery({
								query: queries.joinSpaceError,
								data: { joinSpaceError: '' }
							});
						} },
					]}
				/>
			);
		}
	}, [joinErrorRes]);

	let spaces = myRoomsRes.data?.myRooms ?? [];
	let pageCount = 0;

	if (myRoomsRes.data)
	{
		spaces = spaces.filter((space: Room) => space.name.toLowerCase().includes(searchWord.toLowerCase()));

		switch(sortingMethod)
		{
			case 'name':
				spaces = sortByName(spaces);
				break;
			case 'users':
				spaces = spaces.sort(function(a: Room, b: Room) {
					return	(b.session?.participants?.length ?? 0) < (a.session?.participants?.length ?? 0) ? -1 :
							(b.session?.participants?.length ?? 0) > (a.session?.participants?.length ?? 0) ? 1
							: 0;
				});
				break;
			default:
				console.error("Unhandled sort method", sortingMethod);
				return 0;
		}
		
		const tilesTotalCount = spaces ? spaces.length : 0;
		pageCount = Math.ceil(tilesTotalCount / tilesPerPageCount);

		if (!props.web)
		{
			const startIndex = page * tilesPerPageCount;
			const endIndex = startIndex + tilesPerPageCount;
	
			spaces = spaces.slice(startIndex, endIndex);
	
			// Clamp the current page
			if (page > pageCount - 1)
			{
				const newPage = Math.max(0, pageCount - 1);
				if (newPage !== page)
					setPage(newPage);
			}
		}
	}

	const listingContent = () => 
	{
		if (isLoading)
		{
			return (
				<div className={classes.loadingIndicator}>
					<LoadingIndicator />
				</div>
			);
		}
		else if (!teamId)
		{
			return (
				<div className={classes.noSpacesMessage}>
					<h2>You are not a member in any team.</h2>
				</div>
			);
		}
		else if (!user.team?.spaceRead)
		{
			return (
				<div className={classes.noSpacesMessage}>
					<h2>You don&apos;t have permission to view this team&apos;s spaces.</h2>
				</div>
			);
		}
		else if (myRoomsRes.data?.myRooms.length === 0)
		{
			return (
				<div className={classes.noSpacesMessage}>
					<h2>This team doesn&apos;t have any spaces yet.</h2>
				</div>
			);
		}
		else 
		return (
			<div className={classes.body}>
				{spaces.map((room: Room, i: number) => (
					<SpaceTile
						key={i}
						teamId={teamIdResult.data.currentTeamId}
						roomInfo={room}
						refetch={() => myRoomsRes.refetch()}
						currentRoomServerKey={currentRoomServerKey.data.currentSpaceServerKey}
						cachedTemplateUrls={cachedTemplateUrls}
						templateDownloadState={templateDownloadState}
						web={props.web}
					/>
				))}
			</div>
		);
	}

	const isLoading = myRoomsRes.networkStatus === 4 || (!!teamId && !myRoomsRes.data); // 4 = refetch
	
	return (
		<div className={classes.root}>
			<div className={classes.header}>
				<GlueButton variant='icon' color='secondary' onPointerDown={async () => await refreshSpaces(apollo)}>
					<GlueIcon>
						<RefreshIcon />
					</GlueIcon>
				</GlueButton>
				{user.team?.spaceEdit &&
					<GlueButton 
						color='primary'
						onPointerDown={() => addDialog(<SpaceTemplatesDialog 
							title='Create a new space'
							teamId={teamId}
							orgId={currentOrgRes.data.currentOrgId}
							mode='createSpace'
							refreshRooms={() => myRoomsRes.refetch()}
							web={props.web}
						/>)}
						variant={props.web ? 'main' : 'icon'}
					>
						{newSpaceLabel}
					</GlueButton>}
				{(user.team?.assetCreate || assetAccessRes.data?.teamAssetsAccess.some((access: TeamAssetAccessResponse) => access.access.edit)) &&
					<GlueButton
						color='secondary'
						onPointerDown={() => addDialog(<SpaceTemplatesDialog 
							title='Space templates'
							teamId={teamId}
							orgId={currentOrgRes.data.currentOrgId}
							mode='manageTemplates'
							refreshRooms={() => myRoomsRes.refetch()}
							web={props.web}
						/>)}
					>
						{manageTemplatesLabel}
					</GlueButton>}
				{props.web ? (
					<WebInputfield
						width={theme.custom.spaces.inputWidth}
						search 
						value={searchWord}
						onClear={()=>{ setSearchWord("") }}
						onChange={(value: string)=>{ setSearchWord(value); setPage(0) }}
						showClearButton={searchWord ? true : false}
					/>
				) : (
					<GlueInputfield
						width={theme.custom.spaces.inputWidth}
						search 
						value={searchWord}
						sinkId={searchSTTSinkId} 
						speechInput={sttActive(sttRes, searchSTTSinkId)}
						onClear={()=>{ setSearchWord("") }}
						onChange={(value: string)=>{ setSearchWord(value); setPage(0) }}
						onSpeechChange={(value: string)=>{ setSearchWord(value) }}
						showClearButton={searchWord ? true : false}
						margin={"0px 0px 0px 0px"}
					/>
				)}
				<GlueDropdown
					width={theme.custom.spaces.dropdownWidth} 
					items={sortOptions}
					value={sortingMethod}
					uiAudioMessage = {sortaudiomessage}
					uiHoverAudioMessage = {sorthoveraudiomessage} 
					uiSelAudioMessage = {sortselaudiomessage}
					uiSelHoverAudioMessage = {sortselhoveraudiomessage} 
					onChange={selectSortingMethod} 
				/>

				{!props.web && !isLoading && (
					<>
						<div className={classes.pageSelector}>
							<PageSelector
								current={page}
								pageCount={pageCount}
								onPageChange={setPage}
              />
						</div>
					</>
				)}
			</div>

			{listingContent()}

			{props.web && <div className={classes.webBottomPadding}/>}
		</div>
	);
};

export default Spaces;
