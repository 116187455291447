import React from "react";
import { makeStyles } from "@material-ui/core";
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import GlueButton from "./glue-button";


type DialogHeaderProps = {
	width?: string | number,
	header?: string,
	plain?: boolean,
	closeDialog: (e: React.PointerEvent) => void
}

const useStyles = makeStyles((theme) => ({
	root: (props: DialogHeaderProps) => ({
		position: 'relative',
		display: 'flex',
		minHeight: theme.custom.glueButton.main.height,

		'& > button': {
			position: 'absolute',
			top: 0,
			right: 0
		}
	}),

	headerContainer: {
		display: 'flex',

		'& > h2': {
			margin: 'auto',
			textAlign: 'center',
			marginLeft: `calc(${theme.custom.glueButton.main.height} + ${theme.glueSpacing('m')})`,
			marginRight: `calc(${theme.custom.glueButton.main.height} + ${theme.glueSpacing('m')})`,
		}
	}
}))

const DialogHeader = (props: DialogHeaderProps) =>
{
	const classes = useStyles(props);

	return (
		<div className={classes.root}>
			<div className={classes.headerContainer}>
				<h2>{props.header}</h2>
			</div>
			{!props.plain && 
					<GlueButton
						onPointerDown={e => props.closeDialog(e)}
						color="stealth"
						variant='icon'
					>
						<CloseOutlinedIcon />
					</GlueButton>
				}
		</div>
	);
}

export default DialogHeader;