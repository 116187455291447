import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core';
import queries from '../graphql/queries';
import OrgIcon from './organization/org-icon';
import LoadingIndicator from './common/loading-indicator';
import GlueIcon from './common/glue-icon';
import TutorialOrgBubble from '../images/OrgBubble_TutorialSpace.png';
import { Organization, Team } from '../graphql/types-generated';
import TeamSelectorDrawer from './team-selector-drawer';
import GlueButton from './common/glue-button';

type TeamSelectorProps = {
	onChange: (value: string) => void
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: theme.custom.teamSelector.buttonWidth,

		'&>.MuiGlueButton-root': {
			width: '100%',
			textAlign: 'left',
			padding: 0,
		},
	},

	mainButtonContent: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		gap: theme.glueSpacing('s'),
		paddingRight: theme.glueSpacing('m'),
	},

	noTeamsContent: {
		padding: `0 ${theme.glueSpacing('m')}`,
		width: '100%',
		textAlign: 'center'
	},

	buttonLoading: {
		margin: 'auto'
	},

	textLabel: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},

}), { name: 'MuiGlueTeamSelector' });

const TeamSelector = (props: TeamSelectorProps) =>
{
	const classes = useStyles();

	const uiRes = useQuery<{ ui: string }>(queries.ui);
	const ui = uiRes.data?.ui;
	const hideTutorial = ui === 'web';
	
	const currentTeamIdRes = useQuery<{ currentTeamId: string }>(queries.currentTeamId);
	const currentTeamId = currentTeamIdRes.data?.currentTeamId;
	const currentTeamInfoRes = useQuery<{ teamInfo: Team }>(queries.teamInfo, {
		skip: !currentTeamId,
		variables: {
			teamId: currentTeamId
		}
	});
	const currentTeam = currentTeamInfoRes.data?.teamInfo;

	const currentOrgRes = useQuery<{ currentOrgId: string }>(queries.currentOrgId);
	const currentOrgId = currentOrgRes.data?.currentOrgId;

	const myOrgsRes = useQuery<{ getUserOrganizations: Organization[] }>(queries.getUserOrganizations, { fetchPolicy: "cache-and-network" });
	let organizations = myOrgsRes.data?.getUserOrganizations ?? [];

	if (hideTutorial) {
		organizations = organizations.filter(item => !item.isTutorial);
	}

	const [drawerOpen, setDrawerOpen] = useState(false);

	const currentOrg = (currentOrgId && organizations.length > 0) ? (
		organizations.find(item => item.orgId === currentOrgId)
	) : (
		undefined
	);

	const onClickMainButton = () =>
	{
		setDrawerOpen(!drawerOpen);
		myOrgsRes.refetch();
	};

	// Can't use result.loading because it will return true even if the cached data is already available
	const isLoading = (myOrgsRes.networkStatus === 4 || !myOrgsRes.data)  || (currentTeamInfoRes.networkStatus === 4 || !currentTeamInfoRes.data);

	return (
		<div className={classes.root}>
			<GlueButton
				onPointerDown={onClickMainButton}
			>
				{ isLoading ? (
					<div className={classes.buttonLoading}>
						<LoadingIndicator variant='button' />
					</div>
				) : (
					<>
						{currentOrg && currentTeam ? (
							<div className={classes.mainButtonContent}>
								<GlueIcon>
									<OrgIcon
										name={currentOrg.name}
										id={currentOrg.orgId && currentOrg.orgId.slice(4)}
										variant='small'
										typeVariant='h3'
										imageUrl={currentOrg.isTutorial && TutorialOrgBubble}
									/>
								</GlueIcon>
								<p className={classes.textLabel}>{currentTeam.name}</p>
								</div>
						) : (
							<div className={classes.noTeamsContent}>
							{organizations.length > 0 ? (
								<p className={classes.textLabel}>Select teams</p>
							) : (
								<p className={classes.textLabel}>No team memberships</p>
							)}
							</div>
						)}
					</>
				)}
			</GlueButton>

			<TeamSelectorDrawer 
				web={ui == 'web'}
				open={drawerOpen}
				loading={isLoading}
				organizations={organizations}
				currentOrgId={currentOrgId}
				currentTeamId={currentTeamId}
				onClose={() => setDrawerOpen(false)}
				onChange={props.onChange}
			/>
		</div>
	);
};

export default TeamSelector;
