import React, { useEffect, useState } from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import moment from 'moment-timezone';
import { Palette, PaletteColor } from '@material-ui/core/styles/createPalette';
type ClockNowProps = {
	variant?: 'full' | 'toolbar' | 'handui'
	onClick?: () => void
	showTimezoneName?: boolean
	showTimezoneNumeric?: boolean
	color?: keyof Palette
}

const useStyles = makeStyles<Theme, ClockNowProps>((theme) => ({
	root: {
		display: 'flex',
		flexFlow: 'column nowrap',
		justifyContent: 'center',
		alignItems: 'stretch',
		
		textAlign: 'center',
		fontWeight: 700
	},

	time: ({ variant, color }) => ({
		fontSize: theme.custom.clock.displayFont[variant ?? 'full'].fontSize,
		lineHeight: theme.custom.clock.displayFont[variant ?? 'full'].lineHeight,
		fontWeight: theme.custom.clock.displayFont[variant ?? 'full'].fontWeight,
		color: (theme.palette[color ?? 'secondary'] as PaletteColor).contrastText
	}),

	timezoneName: {
		fontSize: '32px'
	},

	timezoneNumeric: {
		fontSize: '32px',
		fontWeight: 400,
		color: 'gray'
	}
}));

const ClockNow = (props: ClockNowProps) =>
{

	const classes = useStyles(props);

	const [ currentTime, setCurrentTime ] = useState(moment());

	// Update the clock
	useEffect(() => {
		const updateTime = () =>
		{
			setCurrentTime(moment());
		};

		const intervalId = setInterval(updateTime, 1000);

		return () =>
		{
			clearInterval(intervalId);
		};
	}, []);

	const onClick = () =>
	{
		if (props.onClick)
			props.onClick();
	}

	return (
		<div className={classes.root} onPointerDown={onClick}>
			<div className={classes.time}>{currentTime.format('HH:mm')}</div>
			
			{props.showTimezoneName &&
				<div className={classes.timezoneName}>{moment.tz.guess()}</div>
			}
			
			{props.showTimezoneNumeric &&
				<div className={classes.timezoneNumeric}>UTC {currentTime.format('Z')}</div>
			}
		</div>
	);
};

export default ClockNow;
