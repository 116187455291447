import { CSSProperties } from "@material-ui/core/styles/withStyles";

export type Type = {
	root: CSSProperties
	image: CSSProperties
	header: CSSProperties
	body: CSSProperties
	buttons: CSSProperties
};

const common: Type = {
	root: {
		overflow: 'hidden'
	},
	image: {
		width: '240px',
		height: '240px',
		display: 'block',
		margin: 'auto'
	},
	header: {
		textAlign: 'center',
		height: '64px'
	},
	body: {
		textAlign: 'center',
		display: 'block'
	},
	buttons: {
		margin: 'auto',
		width: 'fit-content',
		display: 'grid',
		gridAutoFlow: 'column'
	},
};

export const web: Type = {
	...common,

	image: {
		...common.image,
		width: '120px',
		height: '120px'
	},

	header: {
		...common.header,
		height: '36px'
	}
};

export const tablet: Type = {
	...common
};