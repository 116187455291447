import React, { useEffect, useState } from "react";
import { useQuery, useMutation, useApolloClient } from "@apollo/react-hooks";
import { makeStyles } from "@material-ui/core";
import { useNavigate } from 'react-router-dom';

import GlueButton from "../common/glue-button";

import queries from "../../graphql/queries";
import mutations from "../../graphql/mutations";

import { usePromptDialogContext } from "../../util/prompt-dialog-context";

import WebInputfield from "../../standalone-web/common/web-inputfield";
import GlueListView, { alphabeticalSortFunc, simpleSortFunc } from "../common/glue-list-view";
import { Lock } from '@material-ui/icons';

import CreateTeamDialog from "../teams/create-team";
import { useUserContext } from "../../util/user-context";
import InfoDialogTemplate from "../common/info-dialog-template";
import MemberAddDialog from "../members/member-add-dialog";
import GlueInputfield from "../common/glue-inputfield";
import EditTeam from "../teams/edit-team";
import { getMemberOrgTeams } from "../../util/org-utils";
import GeneralDialogTemplate from "../common/general-dialog-template";

import warningSign from "../../images/ErrorIcon_Web.png"

import { restorePreviousTeam, setCurrentTeam } from "../../service/choose-current-team";
import { sttActive } from "../../util/speech-utils";

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100%',
		width: '100%',
		boxSizing: 'border-box',
		display: 'flex',
		flexFlow: 'column nowrap',
		overflow: 'hidden',
	},

	actionBar: {
		width: '100%',
		height: theme.custom.header.height,
		display: 'flex',
		flexDirection: 'row',
		gap: theme.glueSpacing('m'),
		alignItems: 'center'
	},
}));

const OrgTeams = (props) =>
{
	const classes = useStyles();

	const navigate = useNavigate();
	const apollo = useApolloClient();

	const { addDialog } = usePromptDialogContext();
	const user = useUserContext();

	const orgIdRes = useQuery(queries.currentOrgId);

	const currentTeamIdRes = useQuery(queries.currentTeamId);

	const getOrgTeamsRes = useQuery(queries.getOrgTeams, {
		skip: !orgIdRes.data,
		variables: {
			orgId: orgIdRes.data.currentOrgId
		},
		fetchPolicy: 'network-only'
	});

	const allTeamsRes = useQuery(queries.myTeams);

	const searchSTTSinkId = 'OrgTeamsFilterTerm';
	const sttRes = useQuery(queries.speechRecognition);

	const [ filterTerm, setFilterTerm ] = useState('');

	/** @type {Array} */
	let teams = getOrgTeamsRes.data?.getOrgTeams ?? [];

	if (teams && teams.length > 0) {
		console.log("TEAMS", teams)
	}

	// Filter
	const filterKeys = ['name'];
	teams = teams?.filter((team) =>
		!filterTerm || filterKeys.some((key) => String(team[key]).toLowerCase().includes(filterTerm))
	);

	const selectorItems = allTeamsRes.data?.myTeams.map(
		(item) => ({
			id: item.teamId,
			name: item.name
		}
		)) ?? [];

	useEffect(() => {
		if (user.administrator) {
			allTeamsRes.refetch({ userRole: 'administrator' });
		}
	}, [user.administrator, allTeamsRes])

	let itemsToFilter = [];

	if (getOrgTeamsRes.data) {
		itemsToFilter = getOrgTeamsRes.data.getOrgTeams.map(item => item.teamId);
	}

	if (itemsToFilter.length > 0) {
		selectorItems.filter(item => !itemsToFilter.includes(item.id))
	}

	const memberContentFunc = (item) => {
		return item.members.length;
	}

	const arrayLengthSortFunc = (a, b, key, dir) =>
	{
		return a[key].length < b[key].length ? -dir : dir;
	}

	const privateContentFunc = (item) => {
		return (item.teamAccessType === 0 ? <Lock /> : null)
	}

	const privateSortFunc = (a, b, key, dir) =>
	{
		const privacySortOrder = [0, 1];
		return (privacySortOrder.indexOf(a.teamAccessType) < privacySortOrder.indexOf(b.teamAccessType) ? -1 : 1) * dir;
	}

	const columns = [
		{ key: 'name', label: 'team', width: '2fr', contentFunc: undefined, sortFunc: alphabeticalSortFunc },
		{ key: 'members', label: 'members', width: '1fr', contentFunc: memberContentFunc, sortFunc: arrayLengthSortFunc },
		{ key: 'roomCount', label: 'spaces', width: '2fr', contentFunc: undefined, sortFunc: simpleSortFunc },
		{ key: 'teamAccessType', label: 'private', width: '1fr', contentFunc: privateContentFunc, sortFunc: privateSortFunc },
	];

	const joinContentFunc = (team) =>
	{
		if (team.members.some(member => member.email === user.email))
		{
			return "Leave this team";
		}
		else return "Join this team";
	}

	const [joinMut] = useMutation(mutations.userJoinTeam);
	const [leaveMut] = useMutation(mutations.userLeaveTeam);

	const requestJoinOrLeave = async (team, leave) =>
	{
		if (leave)
		{
			await leaveMut({
				variables: {
					teamId: team.teamId,
					memberId: user.email
				},
				refetchQueries: [{
					query: queries.getOrgTeams,
					variables: {
						orgId: orgIdRes.data.currentOrgId
					},
					fetchPolicy: 'network-only'
				}]
			}).then(async (res) => {
				if (res.data.userLeaveTeam.teamId === currentTeamIdRes.data.currentTeamId) {
					console.log("Leaving current team, handling change")
					await apollo.writeQuery({
						query: queries.currentTeamId,
						data: {
							currentTeamId: null
						}
					})
					const nextTeam = getOrgTeamsRes.data.getOrgTeams.find(item => item.members.some(mem => mem.email === user.email) && 
						item.teamId !== res.data.userLeaveTeam.teamId);
					if (nextTeam) 
					{
						await setCurrentTeam(apollo, nextTeam.teamId)
					}
					else
					{
						await restorePreviousTeam(apollo);
					}
					navigate('/')
				}
			}).catch(err => {
				console.error(err);
			});
		}
		else
		{
			await joinMut({
				variables: {
					teamId: team.teamId,
					memberId: user.email,
					role: 'member'
				}
			}).then(res => {
				getOrgTeamsRes.refetch();
			}).catch(err => {
				console.error(err);
			});
		}
	}

	const checkAvailableRemoval = async (team) =>
	{
		if (team.members.some(member => member.email === user.email)) {
			const req = getMemberOrgTeams(getOrgTeamsRes.data?.getOrgTeams, user.email);

			if (req.length === 1) {
				addDialog(<GeneralDialogTemplate
					header={'Leave your last team?'}
					image={warningSign}
					body={"If you leave this team, you will also be removed from this organization."}
					callbacks={[
						{ label: 'Cancel', callback: () => null },
						{ label: "Leave", color: 'destructive', callback: () => requestJoinOrLeave(team, true)}
					]}
				/>)
			} else {
				requestJoinOrLeave(team, true);
			}
		}
		else
		{
			requestJoinOrLeave(team, false);
		}
	}

	const menuOptionsJoin = [
		{ key: 'join', content: joinContentFunc, callback: checkAvailableRemoval },
	];

	const [deleteTeamMut] = useMutation(mutations.deleteTeam);

	const removeTeam = (team) => {

		if (getOrgTeamsRes.data?.getOrgTeams.length === 1) {
			console.log("Can not remove last team!")
			addDialog(<InfoDialogTemplate 
				isError={true}
				message={'This is the last team in the organization and can not be removed. If you want to remove the entire organization, please contact Glue Support.'}
			/>)
			return;
		}
		addDialog(
			<InfoDialogTemplate
				header={'DELETE?'}
				message={'Are you sure you want to permanently delete this team, including all its spaces and files? NOTE! Any members for whom this is the only team will also be removed from the organization.'}
				callbacks={[
					{ label: 'Cancel', callback: () => null },
					{ label: 'Delete', color: 'destructive', callback: () => removePermanently(team.teamId) }
				]}
			/>
		)
	}

	const removePermanently = async (teamId) => 
	{
		await deleteTeamMut({
			variables: {
				teamId: teamId
			}
		}).then(res => {
			addDialog(
				<InfoDialogTemplate
					header={'Success!'}
					message={'Team Deleted Successfully'}
					callbacks={[
						{ label: 'OK', color: "primary", callback: () => getOrgTeamsRes.refetch() }
					]}
				/>
			)
		}).catch(err => {
			addDialog(
				<InfoDialogTemplate
					isError={true}
					message={"ERROR!!! " + err.message}
				/>
			)
		})
	}

	const menuOptionsEdit = [
		{key: 'modify', content: 'Modify team', callback: (team) => addDialog(<EditTeam team={team} refetch={() => getOrgTeamsRes.refetch()} />)},
		{key: 'addmembers', content: "Manage members", callback: (team) => addDialog(<MemberAddDialog web={props.web} teamId={team.teamId} orgId={orgIdRes.data?.currentOrgId} refresh={() => getOrgTeamsRes.refetch()} teamAdmin={false} />)},
		{key: 'delete', content: "Delete this team", callback: removeTeam},
	];

	const getMenuOptions = () =>
	{
		let options = [];
		if (user.org?.teamsJoin)
		{
			options = options.concat(menuOptionsJoin);
		}
		if (user.org?.teamsEdit)
		{
			options = options.concat(menuOptionsEdit);
		}

		return options;
	}

	const menuDisabledFunc = (item) => 
	{
		return !user.org?.teamsEdit && !user.org?.teamsJoin;
	}

	return(
		<div className={classes.root}>
			<div className={classes.actionBar}>
				{(user.administrator || user.org?.teamCreate) && 
					<GlueButton 
						color="primary" 
						onPointerDown={() => addDialog(<CreateTeamDialog 
							orgId={orgIdRes.data?.currentOrgId} 
							isSysAdmin={user.administrator}
							refetch={() => getOrgTeamsRes.refetch()} />)} 
					>Create a new team</GlueButton>}
				{props.web ? (
					<WebInputfield 
						search
						width={'200px'}
						value={filterTerm}
						onClear={() => { setFilterTerm(""); }}
						onChange={(value) => { setFilterTerm(value.toLowerCase()); }}
						showClearButton={filterTerm ? true : false}
						placeholder='Search'
					/>
				) : (
					<GlueInputfield
						search
						width={'256px'}
						value={filterTerm}
						onClear={() => { setFilterTerm(""); }}
						onChange={(value) => { setFilterTerm(value.toLowerCase()); }}
						showClearButton={filterTerm ? true : false}
						placeholder='Search'
						sinkId={searchSTTSinkId}
						speechInput={sttActive(sttRes, searchSTTSinkId)}
						onSpeechChange={(value) => { setFilterTerm(value.toLowerCase()); }}
					/>
				)}
			</div>
			<GlueListView
				disableSelection
				web={props.web}
				items={teams}
				overrideIdKey={'teamId'}
				columns={columns}
				menuOptions={getMenuOptions()}
				menuDisabledFunc={menuDisabledFunc}
				defaultSortKey={'name'}
				defaultSortDir={1}
			/>
		</div>
	);
}

export default OrgTeams;