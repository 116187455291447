import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core';

import GlueScroll from '../common/glue-scroll';
import GlueEditor from "../common/glue-editor";
import GlueListItem from "../common/glue-list-item";

import ThumbnailVideo from "../common/thumbnail-video";
import { helpContentURLBase } from '../../defines';

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.glueSpacing('s'),
		height: ({useGrid}) => useGrid ? '880px' : '760px',
		display: 'flex',
	},

	sideMenu: {
		display: 'flex',
		flexFlow: 'column nowrap',
		gap: theme.glueSpacing('s'),
		padding: '0px 12px 0 0',
		width: '468px'
	},

	content: {
		maxWidth: ({open}) => open ? '780px' : '900px',
		margin: ({open}) => open ? `0px 48px 24px 112px` : `0 150px 24px 256px`
	},

	mainContent: {
		display: 'grid',
		gridTemplateColumns: ({useGrid, open}) => useGrid ? open ? '600px' : '1fr 1fr' : '1fr',
		gap: ({useGrid}) => useGrid ? '24px' : 0,
		paddingRight: '74px'
	},

	pageListing: {
		height: '64px',
		display: 'grid',
		gridTemplateColumns: '64px 1fr',
		gap: theme.glueSpacing('s'),
		alignItems: 'center',
	},

	checkMark: {
		width: '64px'
	},

	subheader: {
		marginTop: theme.glueSpacing('l')
	},

	infoImage: {
		paddingTop: theme.glueSpacing('l'),
		maxWidth: '400px',
		pointerEvents: 'none'
	},

	toolIcon: {
		background: theme.palette.background.paper,

		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '96px',
		height: '96px',
		margin: theme.glueSpacing('s'),
		borderRadius: '50%',

		'& > img': {
			width: '64px',
			height: '64px'
		}
	},

	infoParagraph: {
		paddingTop: theme.glueSpacing('m'),
	}	
}));

const GuideDetails = (props) =>
{
	const detail = props.currentDetail.toLowerCase().replace(/\s/g, '');
	const blob = props.blob ? props.blob : '';
	const baseUrl = helpContentURLBase +blob + detail;
	const contentUrl = baseUrl +"/text.txt";
	const [pages, setPages] = useState([]);
	const [currentPage, setCurrentPage] = useState(0)
	const [drawerOpen, setDrawerOpen] = useState(false);

	const classes = useStyles({open: drawerOpen, useGrid: props.useGrid});

	useEffect(() => {
		function assignPages(newPages)
		{
			if(newPages.toString() !== pages.toString())
			{
				setPages([...newPages]);
				setCurrentPage(0);
				setDrawerOpen(newPages.length > 1)
			}
		}

		fetch(contentUrl, {
			cache: 'no-cache'
		})
		.then(function(res) { return res.json()})
		.then(function(data) {
			if (data.pages)
			{
				assignPages(data.pages);
			}
		});
	}, [contentUrl, baseUrl, currentPage, pages])

	const helpMenu = (
		pages.map((page, i) =>
			<GlueListItem
				noTruncate
				key={i}
				selected={currentPage === i}
				onPointerDown={() => setCurrentPage(i)}
			>
				{page.title}
			</GlueListItem>
		)
	)

	const selectContent = (c, index) => {
			switch(c.guideContent.type) {
				case 'image':
					return <>
								<img
									key={index}
									className={classes.infoImage}
									src={baseUrl + c.guideContent.data}
									alt={''}
								/>
								{c.guideContent.description &&
									<p style={{fontStyle: 'italic'}}>
										{c.guideContent.description}
									</p>
								}
							</>
				case 'video':
					return <div>
								<ThumbnailVideo
									key={index}
									url={c.guideContent.data}
									id={c.guideContent.id}
									title={c.guideContent.title}
									description={c.guideContent.description}
								/>
							</div>
				case 'text':
					return <div
								key={index}
								className={classes.infoParagraph}
								dangerouslySetInnerHTML={{__html: c.guideContent.data}}
							/>
				case 'list':
					return <ul key={index}>
								{c.guideContent.data.split("|").map((item, i) =>
									<li key={i}>{item}</li>
								)}
							</ul>
				case 'subheader':
					return <h2 className={classes.subheader}>{c.guideContent.data}</h2>
				default: 
					return
			}
	}

	const helpContent = pages.length > 0 && pages[currentPage] !== undefined ? 
	(
		<GlueScroll key={currentPage}>
			<div className={classes.content}>
				<h1>{pages[currentPage].title}</h1>
				<div className={classes.mainContent}>
					{pages[currentPage].content.map((cnt, i) => 
						selectContent(cnt, i)
					)}
				</div>
				
			</div>
		</GlueScroll>
	) : (
		null
	);

	return (
		<div className={classes.root}>
			<GlueEditor
				isMenu
				showDrawerControls
				open={drawerOpen}
				onToggleOpen={() => setDrawerOpen(!drawerOpen)}
				previewContent={
						helpContent
				}
			>
				<GlueScroll>
					<div className={classes.sideMenu}>
						{helpMenu}
					</div>
				</GlueScroll>
			</GlueEditor>
			
			
		</div>
	);
}

export default GuideDetails;