import React from 'react';
import { makeStyles } from '@material-ui/core';
import { clickAudio, hoverAudio } from './common-vuplex-messages';
import CheckIcon from '@material-ui/icons/Check';
import { Checkbox } from '@material-ui/core';
import GlueIcon from './glue-icon';

const useStyles = makeStyles((theme) => ({
	root: {
		maxWidth: '100%',
		height: theme.custom.glueListItem.height,
	},
	
	background: ({selected, noTruncate, disabled}) => ({
		width: '100%',
		height: '100%',
		color: theme.palette.secondary.contrastText,
		opacity: disabled ? '50%' : '100%',
		padding: `0px ${theme.glueSpacing('s')}`,
		display: 'flex',
		alignItems: 'center',
		gap: theme.glueSpacing('s'),
		...theme.custom.hoverOverlay(selected),

		'& p': {
			width: '100%',
			whiteSpace: noTruncate ? 'none' : 'nowrap',
			opacity: selected ? '100%' : '60%',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			marginRight: 'auto',
			lineHeight: 1
		},
	}),

	divider: {
		display:  ({divider}) => divider ? 'block' : 'none',
		height: '2px',
		background: '#fff',
		opacity: '20%',
		position: 'relative',
		bottom: '2px'
	}

}), { name: 'MuiGlueListItem' });

const GlueListItem = (props) => {
	const classes = useStyles(props);

	const audiomessage = !!props.uiAudioMessage ? props.uiAudioMessage : "Menu/Generic/Button/Press";
	const hoveraudiomessage = !!props.uiHoverAudioMessage ? props.uiHoverAudioMessage : "Menu/Generic/Button/HL";

	const onClick = () => {
		if (!props.disabled)
		{
			clickAudio(audiomessage);
			if (props.onPointerDown)
				props.onPointerDown();
		}
	}

	return (
		<div className={classes.root}>
		<div className={classes.background} onPointerDown={onClick} onPointerEnter={() => {hoverAudio(hoveraudiomessage)}}>
			{props.checkbox ? 
				<GlueIcon>
					<Checkbox checked={props.selected}/>
				</GlueIcon>
				:
				<GlueIcon>
					{props.selected && <CheckIcon/>}
				</GlueIcon>}
			<p>{props.children}</p>
		</div>
		<div className={classes.divider}/>
		</div>
	);
}

export default GlueListItem;