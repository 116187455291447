import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Navigate, Route, Routes, useMatch, useNavigate } from "react-router-dom";

import LaunchIcon from '@material-ui/icons/Launch';

import ClockNow from './clock/clock-now';
import { ClockStopwatchDisplay, ClockStopwatchControls } from './clock/clock-stopwatch';
import { ClockTimerDisplay, ClockTimerControls } from './clock/clock-timer';
import AppHeader from './common/app-header';
import GlueButton from './common/glue-button';
import postVuplexMessage from '../service/message-vuplex';
import ClockTabsHeader from './clock/clock-tabs-header';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexFlow: 'column nowrap',
		height: '100%'
	},

	appContent: {
		flex: '1 0 auto',
		
		display: 'flex',
		flexFlow: 'column nowrap',
		justifyContent: 'flex-start',
		alignItems: 'center',

		paddingTop: '256px'
	},

	display: {
		flex: '0 0 auto',
	},

	controls: {
		flex: '0 0 auto',
		margin: 'auto 0 64px 0'
	}
}));

const ClockApp = (props) =>
{
	const classes = useStyles(props);
	const navigate = useNavigate();
	const matchCurrentTab = useMatch('/app/clock/:tab');

	const launchExternalClock = () =>
	{
		postVuplexMessage("Spawn object", { id: 'clock', args: matchCurrentTab.params.tab });
		postVuplexMessage("Close tablet", null);
		navigate('/');
	};

	const headerActions =
	(
		<GlueButton variant='icon' onPointerDown={launchExternalClock}>
			<LaunchIcon />
		</GlueButton>
	);

	return (
		<div className={classes.root}>
			<AppHeader title={"Clock"} secondChildren={headerActions} />
			<ClockTabsHeader />

			<div className={classes.appContent}>
				<Routes>
					<Route path={'/'} element={<Navigate to={'now'} />} />

					<Route path={'now'} element={
						<ClockNow showTimezoneName showTimezoneNumeric />
					} />

					<Route path={'stopwatch'} element={<>
						<div className={classes.display}>
							<ClockStopwatchDisplay />
						</div>
						<div className={classes.controls}>
							<ClockStopwatchControls />
						</div>
					</>} />

					<Route path={'timer'} element={<>
						<div className={classes.display}>
							<ClockTimerDisplay compensateForButtons />
						</div>
						<div className={classes.controls}>
							<ClockTimerControls />
						</div>
					</>} />
				</Routes>
			</div>
		</div>
	);
};

export default ClockApp;
