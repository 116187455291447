import React from 'react';

import { DialogTitle, DialogActions, makeStyles } from '@material-ui/core';
import GlueButton from './common/glue-button';
import GlueDialog from './common/glue-dialog';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import queries from '../graphql/queries';
import postVuplexMessage from '../service/message-vuplex';

const useStyles = makeStyles((theme) => ({
	dialogActions: {
		gap: '16px',
		marginTop: '48px'
	},
}));

const paper = {
	style: {
		width: '640px',
		height: '400px',
		margin: '0px',
		borderRadius: '44px',
		justifyContent: 'center',
		allignItems: 'center'
	}
};

const ExitDialog = (props) =>
{
	const classes = useStyles();
	const apollo =  useApolloClient();
	const isOpen = useQuery(queries.exitDialogOpen);

	const returnHome = () =>
	{
		postVuplexMessage('Return home', null);
		handleClose();
	};

	const handleClose = () =>
	{
		apollo.writeQuery({
			query: queries.exitDialogOpen,
			data: { exitDialogOpen: false }
		});
	};

	const cancelaudiomessage = "Menu/ReturnHome/Cancel/Press";
	const cancelhoveraudiomessage = "Menu/ReturnHome/Cancel/HL";
	const okaudiomessage = "Menu/ReturnHome/Ok/Press";
	const okhoveraudiomessage = "Menu/ReturnHome/Ok/HL";

	return(
		<GlueDialog PaperProps={paper}
			open={isOpen.data.exitDialogOpen}
			onClose={handleClose}
		>
			<DialogTitle disableTypography={true}>
				<h2>Return Home</h2>
			</DialogTitle>
			<DialogActions className={classes.dialogActions}>
				<GlueButton 
					onPointerDown={() => handleClose()}
					uiAudioMessage = {cancelaudiomessage}
					uiHoverAudioMessage = {cancelhoveraudiomessage} 
				>
					Cancel
				</GlueButton>
				<GlueButton 
					color="primary" 
					onPointerDown={() => returnHome()}
					uiAudioMessage = {okaudiomessage}
					uiHoverAudioMessage = {okhoveraudiomessage} 
				>
					OK
				</GlueButton>
			</DialogActions>
		</GlueDialog>
	);
};

export default ExitDialog;