import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import WebInputfield from '../../standalone-web/common/web-inputfield';
import GlueButton from '../common/glue-button';
import GlueInputfield from '../common/glue-inputfield';
import SpaceThumbnailEdit from './space-thumbnail-edit';
import LoadingIndicator from '../common/loading-indicator';
import { Maybe } from '../../graphql/types-generated';

const useStyles = makeStyles((theme) => ({
	root: {
		overflow: 'hidden',
	},

	header: {
		height: theme.custom.errorDialog.header.height,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center'
	},

	body: {
		margin: `0 ${theme.glueSpacing('l')}`,
		width: theme.custom.imagePreview.width,
		display: 'flex',
		flexFlow: 'column',
		gap: theme.glueSpacing('xl')
	},

	joiner: {
		width: 'auto',
		'& > p': {
			margin: `${theme.glueSpacing('xs')} 0`,
		}
	},

	buttons: {
		margin: 'auto',
		width: 'fit-content',
		display: 'grid',
		gridAutoFlow: 'column',
		gap: theme.glueSpacing('m')
	}
}), { name: 'MuiGlueSpaceTemplateDetailsDialog' });

const SpaceTemplateDetailsDialog = (props: {
	title: string
	defaultName: string
	buttonLabel: string
	web?: boolean
	canCreate?: boolean
	onCreate: (name: string, customThumbnail?: File) => void
	onCancel: () => void
	children?: React.ReactNode
	errorMsg?: string
	onClearError?: () => void
	loading?: boolean
	thumbnailUrl?: Maybe<string>
	existingCustomThumbnail?: boolean
	noDefaultThumbnail?: boolean
	onUseDefaultThumbnail?: () => void
	onSetCustomThumbnail?: () => void
}) =>
{
	const classes = useStyles();

	const [ name, setName ] = useState<string>(props.defaultName);
	const [ errorMsg, setErrorMsg ] = useState<string>('');
	const [ customThumbnail, setCustomThumbnail ] = useState<File | undefined>(undefined);

	const showErrorMsg = () => {
		return !!props.errorMsg || !!errorMsg || !name;
	}

	const errorMessage = (): string | undefined => {
    if (props.errorMsg) {
      return props.errorMsg
    } else if (errorMsg) {
			return errorMsg;
	} else if (!name) {
		return 'Name cannot be empty';
	} else return undefined;
	}

	const onSetCustomThumbnail = (file: File) => {
		setCustomThumbnail(file);
		if (props.onSetCustomThumbnail) {
			props.onSetCustomThumbnail();
		}
	}

	const onUseDefaultThumbnail = () => {
		setCustomThumbnail(undefined);
		if (props.existingCustomThumbnail && props.onUseDefaultThumbnail) {
			props.onUseDefaultThumbnail();
		}
	}

	const clearError = () => {
		setErrorMsg('');

		if (!!props.onClearError) {
			props.onClearError();
		}
	}

	return (
		<div className={classes.root}>
			<div className={classes.header}>
				<h2>{props.title}</h2>
			</div>
			<div className={classes.body}>
				<div className={classes.joiner}>
					<p>Name</p>
					{props.web ? (
						<WebInputfield
							disabled={!!props.loading}
							maxLength={60}
							simpleInput
							width={'100%'}
							onChange={(value: string) => {setName(value); clearError()}}
							value={name}
							hasError={true}
							error={showErrorMsg()}
							errorMessage={errorMessage()}
						/>
					) : (
						<GlueInputfield
							disabled={!!props.loading}
							maxLength={60}
							simpleInput
							width={'100%'}
							onChange={(value: string) => {setName(value); clearError()}}
							value={name}
							hasError={true}
							error={showErrorMsg()}
							errorMessage={errorMessage()}
						/>
					)}
				</div>
				<SpaceThumbnailEdit
					web={props.web}
					custom={!!customThumbnail || props.existingCustomThumbnail}
					thumbnail={customThumbnail ? customThumbnail : props.thumbnailUrl} 
					setCustomThumbnail={onSetCustomThumbnail}
					onUseDefault={onUseDefaultThumbnail}
					noDefault={props.noDefaultThumbnail}
					disabled={props.loading}
				/>
				{props.children}
				<div className={classes.buttons}>
					<GlueButton
						onPointerDown={props.onCancel}
						disabled={props.loading}
					>
						Cancel
					</GlueButton>
					<GlueButton
						color="primary"
						onPointerDown={() => props.onCreate(name, customThumbnail)}
						disabled={!name || !props.canCreate || props.loading}
					>
						{props.loading ? <LoadingIndicator variant='button' /> : <p>{props.buttonLabel}</p>}
					</GlueButton>
				</div>
			</div>
		</div>
	);
};
export default SpaceTemplateDetailsDialog;
