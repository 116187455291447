import React from "react";
import { makeStyles } from "@material-ui/core";
import { useMutation, useQuery } from "@apollo/react-hooks";
import mutations from "../../graphql/mutations";
import queries from "../../graphql/queries";
import WebInputfield from "../../standalone-web/common/web-inputfield";
import { usePromptDialogContext } from "../../util/prompt-dialog-context";
import GlueButton from "../common/glue-button";
import InfoDialogTemplate from "../common/info-dialog-template";
import GlueListView, { alphabeticalSortFunc, SortDirection } from "../common/glue-list-view";

import Delete from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme) => ({
	root: {

	},

	users: {
		display: 'flex',
		flexDirection: 'row',
	},

	buttons: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		gap: '12px'
	},

	dialog: {
		display: 'flex',
		flexDirection: 'column',
		gap: theme.glueSpacing('s'),
		padding: theme.glueSpacing('m')
	}
}));

const isInvalidAdminEmail = (email: string) =>
{
	if (email.includes('@fake.fi'))
	{
		return false;
	}
	else if (email.includes('@glue.work')) 
	{
		return false;
	}
	return true;
}

const SysAdmins = () =>
{
	const classes = useStyles();
	const { addDialog, closePromptDialog } = usePromptDialogContext();

	const sysAdminsRes = useQuery<{ sysAdmins: string[] }>(queries.sysAdmins);

	const [ addSysAdminMut ] = useMutation(mutations.addSysAdmin);
	const [ removeSysAdminMut ] = useMutation(mutations.removeSysAdmin);

	const admins = sysAdminsRes.data?.sysAdmins ?? [];

	const addAdminCall = async (newMemberEmail: string) =>
	{
		closePromptDialog();

		if (isInvalidAdminEmail(newMemberEmail)) {
			addDialog(<InfoDialogTemplate
				isError={true}
				message={'Only email domains of @glue.work or @fake.fi are allowed'}
			/>);
			return;
		}

		if (admins.some(admin => admin === newMemberEmail)) {
			addDialog(<InfoDialogTemplate
				isError={true}
				message={`${newMemberEmail} is already a system admin`}
			/>);
			return;
		}

		await addSysAdminMut({
			variables: {
				email: newMemberEmail,
			},
			refetchQueries: [{ query: queries.sysAdmins }]
		}).then(res => {
			addDialog(<InfoDialogTemplate
				header={'Success'}
				message={`${newMemberEmail} was successfully added as a system administrator`}
			/>)
		}).catch(e => {
			addDialog(<InfoDialogTemplate
				isError={true}
				message={e.message}
			/>)
		});
	}

	const removeAdmin = async (email: string) =>
	{
		await removeSysAdminMut({
			variables: {
				email: email,
			},
			refetchQueries: [{ query: queries.sysAdmins }]
		}).then(res => {
			addDialog(<InfoDialogTemplate
				header={'Success'}
				message={`Successfully removed ${email} system administrator status`}
			/>)
		}).catch(e => {
			addDialog(<InfoDialogTemplate
				isError={true}
				message={e.message}
			/>)
		});
	}

	const removeAdminCheck = (email: string) =>
	{
		addDialog(<InfoDialogTemplate
			header={'Remove Admin?'}
			message={`Are you sure you want to remove ${email} as a systemadmin?`}
			callbacks={[
				{label: 'Cancel', callback: () => closePromptDialog()},
				{label: 'OK', color: 'destructive', callback: () => removeAdmin(email)}
			]}
		/>)
	}

	const addNewAdmin = () =>
	{
		let newMemberEmail = '';

		const setEmail = (e: string) =>
		{
			newMemberEmail = e;
		}

		addDialog(
			<div className={classes.dialog}>
				<WebInputfield 
					width={'350px'}
					onChange={(e: string) => setEmail(e)}
				/>
				<div className={classes.buttons}>
					<GlueButton onPointerDown={() => closePromptDialog()}>Cancel</GlueButton>
					<GlueButton color="primary" onPointerDown={() => addAdminCall(newMemberEmail)}>Add</GlueButton>
				</div>
			</div>
		)
	}

	// The list component was never meant to be abused in this way but whatever
	const adminsFormattedForListView = admins.map(admin => {
		return {
			email: admin,
			remove: null
		};
	})

	const columns = [
		{key: 'email' as const, label: 'email address', width: '1fr', contentFunc: undefined, sortFunc: alphabeticalSortFunc},
		{key: 'remove' as const, label: 'remove', width: '96px', contentFunc: (item: {email: string, remove: null}) => <GlueButton onPointerDown={() => removeAdminCheck(item.email)}><Delete></Delete></GlueButton>, noSorting: true},
	];

	return(
		<div className={classes.root}>
			<GlueButton color="primary" onPointerDown={() => addNewAdmin()}>Add admin</GlueButton>
			<GlueListView
				disableSelection
				columns={columns}
				items={adminsFormattedForListView}
				overrideIdKey={'email'} 
				selected={[]} 
				defaultSortKey={"email"} 
				defaultSortDir={SortDirection.Ascending}
			/>
		</div>
	)
}

export default SysAdmins;