import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import clsx from 'clsx';

import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import GlueButton from '../common/glue-button';
import queries from '../../graphql/queries';
import { useQuery, useApolloClient } from '@apollo/react-hooks';
import { clickAudio } from '../common/common-vuplex-messages';
import ClockStatusIndicator from './clock-statusindicator';
import { useNavigate } from 'react-router-dom';
import { broadcastMessage } from '../../service/message-broadcast';
import postVuplexMessage from '../../service/message-vuplex';

const duration1Day = moment.duration(1, 'days').subtract(1, 'seconds');
const durationZero = moment.duration(0);

const timerAlarmStart = 'Apps/Clock/Timer/Alarm/Start';
const timerAlarmStop = 'Apps/Clock/Timer/Alarm/Stop';

const isMain = () =>
{
	return sessionStorage.getItem('ui') === 'tablet';
};

const useStyles = makeStyles((theme) => ({
	displayFull: {
		fontSize: '160px',
		fontWeight: 700,
		lineHeight: '160px',

		position: 'relative'
	},

	displayRow: theme.custom.clock.displayRow,

	adjustmentButtons: {
		position: 'absolute',
		marginTop: '24px',
	},

	adjustmentButtonsTop: {
		marginTop: '-48px',
	},

	units: {
		position: 'absolute',
		top: '150px',
		left: '80px',
		width: '500px',

		display: 'flex',
		flexFlow: 'row nowrap',
		justifyContent: 'space-between',

		fontSize: '24px',
		textTransform: 'uppercase',
		opacity: '0.7',
		lineHeight: '24px',
		fontWeight: 700,
	},

	controls: {
		display: 'grid',
		gap: '24px',
		gridTemplateColumns: "160px 160px",
		justifyContent: 'center'
	}
}));

const endTimer = (apollo, alarm = false) =>
{
	apollo.writeQuery({
		query: queries.clockTimer,
		data: {
			clockTimer: {
				__typename: 'ClockTimer',
				timeLeft: 0,
				startedAt: 0,
				running: false,
				alarm: alarm
			}
		}
	});

	clickAudio(alarm ? timerAlarmStart : timerAlarmStop);
};

const alarmTimeout = (apollo) =>
{
	console.log("Clock timer expired!");
	endTimer(apollo, true);
};

export const setupAlarmMonitoring = (apollo) =>
{
	let alarmTimeoutId = 0;

	const timerStateChanged = (res) =>
	{
		//console.log("ALARM MONITOR: Alarm changed", alarmTimeoutId);
		clearTimeout(alarmTimeoutId);

		if (res.data.clockTimer.running)
		{
			alarmTimeoutId = setTimeout(
				alarmTimeout,
				moment.duration(res.data.clockTimer.timeLeft).asMilliseconds(),
				apollo
			);

			//console.log("ALARM MONITOR: Started timeout", alarmTimeoutId);
		}
	};

	apollo.watchQuery({ query: queries.clockTimer })
		.subscribe(timerStateChanged);
};

const getTimeLeft = (state) =>
{
	const startedAt = moment(state.startedAt);
	const elapsedRunning = state.running ? moment.duration(moment().diff(startedAt)) : moment.duration(0);
	
	const timeLeftOld = moment.duration(state.timeLeft);

	return timeLeftOld.subtract(elapsedRunning);
};

const modPick = (apollo, delta) =>
{
	const data = apollo.readQuery({ query: queries.clockTimer });

	const timeLeftOld = moment.duration(data.clockTimer.timeLeft);

	let timeLeftNew = timeLeftOld.add(delta, 'seconds');

	if (timeLeftNew.valueOf() > duration1Day.valueOf())
		timeLeftNew = duration1Day;
	else if (timeLeftNew.valueOf() < durationZero.valueOf())
		timeLeftNew = durationZero;

	apollo.writeQuery({
		query: queries.clockTimer,
		data: {
			clockTimer: {
				__typename: "ClockTimer",
				timeLeft: timeLeftNew.valueOf(),
				startedAt: 0,
				running: false,
				alarm: false
			}
		}
	});
};

export const ClockTimerDisplay = (props) =>
{
	const apollo = useApolloClient();
	const navigate = useNavigate();

	const clockTimerResult = useQuery(queries.clockTimer);

	const [ timeLeft, setTimeLeft ] = useState(moment.duration(0));

	const showPickers = !props.toolbar &&
		!clockTimerResult.data?.clockTimer.running &&
		!clockTimerResult.data?.clockTimer.alarm &&
		!props.disablePickers;

	const classes = useStyles({ props, showPickers});

	useEffect(() => {
		let intervalId = 0;

		const updateDisplay = () =>
		{
			//console.log("TIMER DISPLAY: Update", intervalId);

			const data = apollo.readQuery({ query: queries.clockTimer });
			const timeLeft = getTimeLeft(data.clockTimer);

			setTimeLeft(timeLeft);
		};

		intervalId = setInterval(updateDisplay, 250);
		//console.log("TIEMER DISPLAY: Start updating", intervalId);

		return () =>
		{
			//console.log("TIEMER DISPLAY: Stop updating", intervalId);
			clearInterval(intervalId);
		};
	}, [apollo]);

	const upaudiomessage = "Apps/Clock/Timer/Set/Up/Press";
	const uphoveraudiomessage = "Apps/Clock/Timer/Set/Up/HL";
	const downaudiomessage = "Apps/Clock/Timer/Set/Down/Press";
	const downhoveraudiomessage = "Apps/Clock/Timer/Set/Down/HL";

	if (props.toolbar)
	{
		const alarm = clockTimerResult.data.clockTimer.alarm;
		const displayText = `${String(timeLeft.hours()).padStart(2, '0')}.${String(timeLeft.minutes()).padStart(2, '0')}.${String(timeLeft.seconds()).padStart(2, '0')}`;

		const onClickToolbar = () =>
		{
			if (alarm)
			{
				endTimer(apollo, false);
			}
			else
			{
				if (isMain())
					navigate('/app/clock/timer');
				else
				{
					postVuplexMessage('Open tablet', null);
					broadcastMessage({ topic: 'Navigate to', args: { ui: 'tablet', path: '/app/clock/timer' }});
				}
			}
		};

		return (
			<ClockStatusIndicator
				icon={<HourglassEmptyIcon />}
				text={displayText}
				alarm={alarm}
				onPointerDown={onClickToolbar}
			/>
		);
	}
	else
	{
		return (
			<div className={classes.displayFull}>
				{showPickers && (<div className={clsx(classes.displayRow, classes.adjustmentButtons, classes.adjustmentButtonsTop)}>
					<GlueButton variant='icon' onPointerDown={() => modPick(apollo, 36000)} uiAudioMessage = {upaudiomessage} uiHoverAudioMessage = {uphoveraudiomessage}><KeyboardArrowUpIcon /></GlueButton>
					<GlueButton variant='icon' onPointerDown={() => modPick(apollo, 3600)} uiAudioMessage = {upaudiomessage} uiHoverAudioMessage = {uphoveraudiomessage}><KeyboardArrowUpIcon /></GlueButton>
					<div></div>
					<GlueButton variant='icon' onPointerDown={() => modPick(apollo, 600)} uiAudioMessage = {upaudiomessage} uiHoverAudioMessage = {uphoveraudiomessage}><KeyboardArrowUpIcon /></GlueButton>
					<GlueButton variant='icon' onPointerDown={() => modPick(apollo, 60)} uiAudioMessage = {upaudiomessage} uiHoverAudioMessage = {uphoveraudiomessage}><KeyboardArrowUpIcon /></GlueButton>
					<div></div>
					<GlueButton variant='icon' onPointerDown={() => modPick(apollo, 10)} uiAudioMessage = {upaudiomessage} uiHoverAudioMessage = {uphoveraudiomessage}><KeyboardArrowUpIcon /></GlueButton>
					<GlueButton variant='icon' onPointerDown={() => modPick(apollo, 1)} uiAudioMessage = {upaudiomessage} uiHoverAudioMessage = {uphoveraudiomessage}><KeyboardArrowUpIcon /></GlueButton>
				</div>)}

				<div className={classes.displayRow}>
					<div>{Math.floor(timeLeft.hours() / 10)}</div>
					<div>{timeLeft.hours() % 10}</div>
					<div>.</div>
					<div>{Math.floor(timeLeft.minutes() / 10)}</div>
					<div>{timeLeft.minutes() % 10}</div>
					<div>.</div>
					<div>{Math.floor(timeLeft.seconds() / 10)}</div>
					<div>{timeLeft.seconds() % 10}</div>
				</div>

				<div className={classes.units}>
					<div>Hours</div>
					<div>Minutes</div>
					<div>Seconds</div>
				</div>

				{showPickers && (<>
					<div className={clsx(classes.displayRow, classes.adjustmentButtons)}>
						<GlueButton variant='icon' onPointerDown={() => modPick(apollo, -36000)} uiAudioMessage = {upaudiomessage} uiHoverAudioMessage = {uphoveraudiomessage}><KeyboardArrowDownIcon /></GlueButton>
						<GlueButton variant='icon' onPointerDown={() => modPick(apollo, -3600)} uiAudioMessage = {downaudiomessage} uiHoverAudioMessage = {downhoveraudiomessage}><KeyboardArrowDownIcon /></GlueButton>
						<div></div>
						<GlueButton variant='icon' onPointerDown={() => modPick(apollo, -600)} uiAudioMessage = {downaudiomessage} uiHoverAudioMessage = {downhoveraudiomessage}><KeyboardArrowDownIcon /></GlueButton>
						<GlueButton variant='icon' onPointerDown={() => modPick(apollo, -60)} uiAudioMessage = {downaudiomessage} uiHoverAudioMessage = {downhoveraudiomessage}><KeyboardArrowDownIcon /></GlueButton>
						<div></div>
						<GlueButton variant='icon' onPointerDown={() => modPick(apollo, -10)} uiAudioMessage = {downaudiomessage} uiHoverAudioMessage = {downhoveraudiomessage}><KeyboardArrowDownIcon /></GlueButton>
						<GlueButton variant='icon' onPointerDown={() => modPick(apollo, -1)} uiAudioMessage = {downaudiomessage} uiHoverAudioMessage = {downhoveraudiomessage}><KeyboardArrowDownIcon /></GlueButton>
					</div>
				</>)}

				
			</div>
		);
	}
};

export const ClockTimerControls = (props) =>
{
	const classes = useStyles(props);
	const apollo = useApolloClient();

	const timerResult = useQuery(queries.clockTimer);

	const start = () =>
	{
		const data = apollo.readQuery({ query: queries.clockTimer });

		apollo.writeQuery({
			query: queries.clockTimer,
			data: {
				clockTimer: {
					__typename: 'ClockTimer',
					timeLeft: data.clockTimer.timeLeft,
					startedAt: moment.now().valueOf(),
					running: true,
					alarm: false
				}
			}
		});
	};

	const pause = () =>
	{
		const data = apollo.readQuery({ query: queries.clockTimer });
		
		const timeLeftOld = moment.duration(data.clockTimer.timeLeft);
		const delta = moment().diff(moment(data.clockTimer.startedAt));
		
		let timeLeft = timeLeftOld.subtract(delta);

		if (timeLeft.valueOf() < durationZero.valueOf())
			timeLeft = durationZero;

		apollo.writeQuery({
			query: queries.clockTimer,
			data: {
				clockTimer: {
					__typename: 'ClockTimer',
					timeLeft: timeLeft.valueOf(),
					startedAt: 0,
					running: false,
					alarm: false
				}
			}
		});
	};

	const cancelaudiomessage = "Apps/Clock/Timer/Cancel/Press"; 
	const cancelhoveraudiomessage = "Apps/Clock/Timer/Cancel/HL";
	const startaudiomessage = "Apps/Clock/Timer/Start/Press"; 
	const starthoveraudiomessage = "Apps/Clock/Timer/Start/HL";
	const pauseaudiomessage = "Apps/Clock/Timer/Pause/Press"; 
	const pausehoveraudiomessage = "Apps/Clock/Timer/Pause/HL";

	return (
		<div className={classes.controls}>
			<GlueButton 
				onPointerDown={() => endTimer(apollo, false)}
				uiAudioMessage = {cancelaudiomessage}
				uiHoverAudioMessage = {cancelhoveraudiomessage}
			>
				Cancel
			</GlueButton>

			{timerResult.data?.clockTimer.running ? (
				<GlueButton 
					color="primary" 
					onPointerDown={pause}
					uiAudioMessage = {pauseaudiomessage}
					uiHoverAudioMessage = {pausehoveraudiomessage} 
				>
					Pause
				</GlueButton>
			) : (
				timerResult.data?.clockTimer.alarm ? (
					<GlueButton
						color="destructive"
						onPointerDown={() => endTimer(apollo, false)}
						uiAudioMessage = {startaudiomessage}
						uiHoverAudioMessage = {starthoveraudiomessage}
					>
						Stop
					</GlueButton>
				) : (
					<GlueButton
						color="primary" 
						onPointerDown={start}
						uiAudioMessage = {startaudiomessage}
						uiHoverAudioMessage = {starthoveraudiomessage} 
					>
						Start
					</GlueButton>
				)
			)}
		</div>
	);
};
