import React from 'react';

import GlueButton from './glue-button';
import { OpenInNew } from '@material-ui/icons';

type OpenInNewButtonProps = {
	onOpenInNew?: (e: React.PointerEvent) => void
	uiAudioMessage?: string
	uiHoverAudioMessage?: string
}

const OpenInNewButton = (props: OpenInNewButtonProps) =>
{
	const onOpenInNew = (e: React.PointerEvent) =>
	{
		if (props.onOpenInNew)
			props.onOpenInNew(e);
	}

	return (
		<GlueButton 
			variant='icon' 
			color='stealth' 
			onPointerDown={onOpenInNew} 
			uiAudioMessage={props.uiAudioMessage}
			uiHoverAudioMessage={props.uiHoverAudioMessage}
		>
			<OpenInNew/>
		</GlueButton>
	);
}

export default OpenInNewButton;