import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import queries from '../graphql/queries';

import postVuplexMessage from '../service/message-vuplex';

import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
import { usePromptDialogContext } from '../util/prompt-dialog-context';
import InfoDialogTemplate from './common/info-dialog-template';
import GlueEllipsisMenu from './common/glue-ellipsis-menu';
import GlueMenuItem from './common/glue-menu-item';
import GlueDialog from './common/glue-dialog';
import CodeLogin from '../standalone-web/code-login';
import { logFirebaseEvent } from '../service/firebase/firebase';
import { useUserContext } from '../util/user-context';
import { isAtHome } from '../util/space-utils';

import { logOut } from '../util/app-utils';

const SystemMenu = (props) =>
{
	const { addDialog } = usePromptDialogContext();
	const [ open, setOpen ] = useState(false);
	const [ deviceCodeOpen, setDeviceCodeOpen ] = useState(false);
	const uiMode = useQuery(queries.ui);
	const navigate = useNavigate();

	const currentSpaceServerKeyResult = useQuery(queries.currentSpaceServerKey);
	const atHome = isAtHome(currentSpaceServerKeyResult);

	const user = useUserContext();

	const showLogoutConfirmation = uiMode.data?.ui === 'tablet';

	const onClickLogOut = (e) =>
	{
		logOut()
	};

	const onClickQuit = (e) =>
	{
		logFirebaseEvent("quit");
		postVuplexMessage('Quit', null);
	};

	const selaudiomessage = "Menu/SystemMenu/Select/Press";
	const selhoveraudiomessage = "Menu/SystemMenu/Select/HL";

	const showLogoutDialog = () => {
		addDialog(<InfoDialogTemplate
			header={'Log out of Glue?'}
			message={'Logging out will also quit Glue.'}
			callbacks={[
				{ callback: () => null, label: 'Cancel' },
				{ callback: onClickLogOut, color: "primary", label: 'Log out' }
			]}
		/>);
	}

	const showQuitDialog = () => {
		addDialog(<InfoDialogTemplate
			header={'Quit Glue?'}
			message={'This will keep you logged in when you return.'}
			callbacks={[
				{ callback: () => null, label: 'Cancel' },
				{ callback: onClickQuit, color: "primary", label: 'Quit' }
			]}
		/>);
	}

	const organizationPath = uiMode.data?.ui === 'web' ? '/organization' : '/view/organization';

	return (
		<>
			<GlueEllipsisMenu vertical open={open} onToggleOpen={() => setOpen(!open)}>
				{uiMode.data?.ui === 'web' && (
				<GlueMenuItem
					onPointerDown={() => { setOpen(false); setDeviceCodeOpen(true); }}
					uiAudioMessage={selaudiomessage}
					uiHoverAudioMessage={selhoveraudiomessage}
				>
					Code login
				</GlueMenuItem>)}
				<GlueMenuItem
					onPointerDown={() => { setOpen(false); navigate(organizationPath); }}
					uiAudioMessage={selaudiomessage}
					uiHoverAudioMessage={selhoveraudiomessage}
					disabled={Boolean(!user.org?.orgInfoRead || !atHome)}
				>
					Organization
				</GlueMenuItem>
				{(uiMode.data?.ui === 'web' && user.administrator) && <GlueMenuItem
					onPointerDown={() => { setOpen(false); navigate('/sys-admin-tools'); }}
					uiAudioMessage={selaudiomessage}
					uiHoverAudioMessage={selhoveraudiomessage}
				>
					System Admin Tools
				</GlueMenuItem>}
				<GlueMenuItem
					onPointerDown={() => { setOpen(false); showLogoutConfirmation ? showLogoutDialog() : onClickLogOut(); }}
					uiAudioMessage={selaudiomessage}
					uiHoverAudioMessage={selhoveraudiomessage}
				>
					Log out
				</GlueMenuItem>
				{uiMode.data?.ui === 'tablet' && (
				<GlueMenuItem
					onPointerDown={() => { setOpen(false); showQuitDialog(); }}
					uiAudioMessage={selaudiomessage}
					uiHoverAudioMessage={selhoveraudiomessage}
				>
					Quit<PowerSettingsNew/>
				</GlueMenuItem>)}
			</GlueEllipsisMenu>
			<GlueDialog 
				onClose={() => setDeviceCodeOpen(false)}
				open={deviceCodeOpen}>
					<CodeLogin closeDialog={() => setDeviceCodeOpen(false)}/>
			</GlueDialog>
		</>
	);
};

export default SystemMenu;
