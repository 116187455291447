import { useMutation } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import mutations from '../../graphql/mutations';
import queries from '../../graphql/queries';
import GlueButton from '../common/glue-button';
import GlueInputfield from '../common/glue-inputfield';

class ServerComponentData
{
	name = "";
	downloadUsername = "";
	downloadPassword = "";
	imageType = 'Registry'; // Required by the GraphQL API, but always the same.
	imageUrl = "";
	imageTag = "";
	startupArgs = "";
}

const useStyles = makeStyles((theme) => ({
	root: {},

	form: {
		marginBottom: '2em'
	}
}));

const ServerComponentEditor = (props) =>
{
	const isCreatingNew = !props.componentId;

	const [formData, setFormData] = useState(new ServerComponentData());

	const [createServerComponentMut] = useMutation(mutations.createServerComponent);
	const [updateServerComponentMut] = useMutation(mutations.updateServerComponent);

	const classes = useStyles();

	useEffect(() => {
		if (isCreatingNew)
		{
			setFormData(new ServerComponentData());
		}
		else
		{
			const old = props.serverComponents.find(
				item => item.componentId === props.componentId
			);
	
			if (!!old)
			{
				const init = new ServerComponentData();

				init.name = old.name ?? "";
				init.downloadUsername = old.downloadUsername ?? "";
				init.downloadPassword = old.downloadPassword ?? "";
				init.imageUrl = old.imageUrl ?? "";
				init.imageTag = old.imageTag ?? "";
				init.startupArgs = old.startupArgs ?? "";

				setFormData(init);
			}
		}
	}, [props.componentId, props.serverComponents, isCreatingNew]);

	const submit = () =>
	{
		const mutationOptions = {
			variables: {
				...formData,
				assetId: props.assetId,
			},
			refetchQueries: [
				{ query: queries.assetServerComponents, variables: { assetId: props.assetId } },
				{ query: queries.myAssets, variables: { typeFilter: 'space' } }
			]
		};

		if (isCreatingNew)
		{
			createServerComponentMut(mutationOptions);
		}
		else
		{
			mutationOptions.variables.componentId = props.componentId;
			updateServerComponentMut(mutationOptions);
		}

		if (!!props.onClose)
			props.onClose();
	};

	return (
		<div className={classes.root}>
			<div className={classes.form}>
				<label>
					Component registry username:
					<GlueInputfield
						simpleInput
						width={'auto'}
						value={formData.downloadUsername}
						onChange={(value) => setFormData({ ...formData, downloadUsername: value })}
					/>
				</label>

				<label>
					Component registry password:
					<GlueInputfield
						simpleInput
						width={'auto'}
						value={formData.downloadPassword}
						onChange={(value) => setFormData({ ...formData, downloadPassword: value })}
					/>
				</label>

				<label>
					Component name:
					<GlueInputfield
						simpleInput
						width={'auto'}
						value={formData.name}
						onChange={(value) => setFormData({ ...formData, name: value })}
					/>
				</label>

				<label>
					Component image URL:
					<GlueInputfield
						simpleInput
						width={'auto'}
						value={formData.imageUrl}
						onChange={(value) => setFormData({ ...formData, imageUrl: value })}
					/>
				</label>

				<label>
					Component image tag:
					<GlueInputfield
						simpleInput
						width={'auto'}
						value={formData.imageTag}
						onChange={(value) => setFormData({ ...formData, imageTag: value })}
					/>
				</label>

				<label>
					Container entrypoint cmd and args:
					<GlueInputfield
						simpleInput
						width={'auto'}
						value={formData.startupArgs}
						onChange={(value) => setFormData({ ...formData, startupArgs: value })}
					/>
				</label>
			</div>

			<div>
				<GlueButton onPointerDown={submit}>
					{isCreatingNew ? "Add server component" : "Save"}
				</GlueButton>
			</div>
		</div>
	);
};

export default ServerComponentEditor;
