import { useApolloClient, useMutation, useQuery } from "@apollo/react-hooks";
import { makeStyles, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import mutations from "../../graphql/mutations";
import queries from "../../graphql/queries";
import WebInputfield from "../../standalone-web/common/web-inputfield";
import { usePromptDialogContext } from "../../util/prompt-dialog-context";
import GlueButton from "../common/glue-button";
import SwitchSelector from "../common/switch-selector";
import { teamAccessType } from "../../defines";
import GlueInputfield from "../common/glue-inputfield";
import { isValidTeamName } from "./create-team";
import InfoDialogTemplate from "../common/info-dialog-template";

const useStyles = makeStyles((theme) => ({
	root: {
		width: theme.custom.createTeamDialog.width,
		padding: `0 ${theme.glueSpacing('l')}`,
		display: 'flex',
		flexDirection: 'column',
		gap: theme.glueSpacing('xl'),

		'&> h2': {
			textAlign: 'center'
		}
	},

	input: {
		display: 'flex',
		flexFlow: 'column',
		gap: theme.glueSpacing('xs'),
	},

	switch: {
		display: 'flex',
		justifyContent: 'center',
	},

	buttonSection: {
		display: 'flex',
		flexDirection: 'row',
		gap: theme.glueSpacing('m'),
		justifyContent: 'center'
	}
}));

const EditTeam = (props) =>
{
	const classes = useStyles();
	const theme = useTheme();

	const apollo = useApolloClient();

	const currentOrgIdRes = useQuery(queries.currentOrgId);
	const uiMode = useQuery(queries.ui);
	const web = uiMode.data?.ui === 'web';

	const { addDialog, closePromptDialog } = usePromptDialogContext();

	const [ initialRender, setInitialRender ] = useState(true);
	const [ teamName, setTeamName ] = useState('');
	const [ teamAccess, setTeamAccess ] = useState(null);

	const [modifyMutation] = useMutation(mutations.updateTeam);

	useEffect(() => {
		if (!initialRender) return;

		setTeamAccess(props.team.teamAccessType ?? 0);
		setTeamName(props.team.name);

		setInitialRender(false);

	}, [initialRender, setInitialRender, props.team])

	const handleCheckState = (checked) =>
	{
		if (checked)
		{
			setTeamAccess(teamAccessType.find(item => item.name === 'Private').id);
		}
		else
		{
			setTeamAccess(teamAccessType.find(item => item.name === 'Public').id);
		}
	}

	const updateTeam = async () =>
	{
		if (teamName.length > 0 && teamName !== props.team.name)
		{
			const valid = await isValidTeamName(teamName, apollo, currentOrgIdRes.data?.currentOrgId);
			if (!valid)
			{
				console.error("Team name validation failed...");
				addDialog(<InfoDialogTemplate
					isError={true}
					message={'The team name must contain 1-60 characters and be unique in your organization.'}
				/>)
				closePromptDialog();
				return;
			}
		}

		const vars = {
			...props.team,
			name: teamName ?? props.team.name,
			teamAccessType: teamAccess ?? props.team.teamAccessType
		}

		const mut = await modifyMutation({
			variables: vars
		});

		if (mut.data)
		{
			console.log(mut.data)
			props.refetch();
		}
		closePromptDialog();
	}

	return(
		<div className={classes.root}>
			<h2>Modify this team</h2>
			<div className={classes.input}>
				<p>Team name</p>
				{web ? (
					<WebInputfield
						width={theme.custom.createTeamDialog.inputWidth}
						value={teamName}
						maxLength={60}
						onChange={(value) => setTeamName(value)}
						placeholder={props.team.name}
					/>
				) : (
					<GlueInputfield
						simpleInput
						width={theme.custom.createTeamDialog.inputWidth}
						value={teamName}
						onChange={(value) => setTeamName(value)}
						placeholder={props.team.name}
					/>
				)}
			</div>
			<div className={classes.switch}>
				<SwitchSelector
					text={'Private team'}
					checked={teamAccess === teamAccessType.find(item => item.name === 'Private').id}
					onChange={handleCheckState}
				/>
			</div>
			<div className={classes.buttonSection}>
				<GlueButton onPointerDown={() => closePromptDialog()}>Cancel</GlueButton>
				<GlueButton 
					color="primary"
					onPointerDown={() => updateTeam()}
				>
					Done
				</GlueButton>
			</div>
		</div>
	)
}

export default EditTeam;
