export type Type = {
	rootPadding: string
	actionGap: string
	actionBarGap: string
	memberHeight: string
	memberTableColumnTemplate: string
	searchFieldWidth: string
	memberCellPadding: string
	invitationPendingColor: string
};

const common: Type =
{
	rootPadding: '72px 0 0 0',
	actionGap: '18px',
	actionBarGap: '36px',
	memberHeight: '50px',
	memberTableColumnTemplate: '1fr 300px',
	searchFieldWidth: '240px',
	memberCellPadding: '18px',
	invitationPendingColor: '#FCC21F'
};

export const web: Type =
{
	...common
};

export const tablet: Type =
{
	...common,

	rootPadding: '24px 18px 0 18px',
	actionBarGap: '24px',
	searchFieldWidth: '384px',
	memberHeight: '64px',
	memberCellPadding: '36px'
};
