import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useQuery, useMutation, useApolloClient } from '@apollo/react-hooks';
import queries from '../../graphql/queries';
import mutations from '../../graphql/mutations';

import GlueButton from '../common/glue-button';

import WebInputfield from '../../standalone-web/common/web-inputfield';
import GlueScroll from '../common/glue-scroll';
import { usePromptDialogContext } from '../../util/prompt-dialog-context';
import GlueInputfield from '../common/glue-inputfield';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import DialogHeader from '../common/dialog-header';
import SwitchSelector from '../common/switch-selector';
import LoadingIndicator from '../common/loading-indicator';
import { changeTeamMemberRole } from '../../util/team-utils';

import Check from '@material-ui/icons/Check';
import GlueTag from '../common/glue-tag';

const themeVar = (theme, key) =>
	theme.custom.teamMemberAdd?.[key] ?? null;

const useStyles = makeStyles((theme) => ({
	root: {
		width: themeVar(theme, 'rootWidth'),
		height: themeVar(theme, 'rootHeight'),
		display: 'grid',
		gridTemplateRows: 'min-content 1fr min-content',
		overflow: 'hidden'
	},

	topContent: {
		display: 'flex',
		flexFlow: 'column',
		gap: theme.glueSpacing('m'),
		padding: `0 ${theme.glueSpacing('l')}`,
	},

	title: {
		textAlign: 'center'
	},

	input: {
		display: 'flex',
		gap: theme.glueSpacing('m'),
	},

	list: {
		display: 'flex',
		flexFlow: 'column',
		overflow: 'hidden',
		paddingLeft: theme.glueSpacing('l'),
		margin: `${theme.glueSpacing('xl')} 0`
	},

	listItem: {
		height: themeVar(theme, 'listItemHeight'),
		display: 'flex',
		flexFlow: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		color: theme.palette.secondary.contrastText,
		padding: theme.glueSpacing('s'),

		'&:hover': {
			filter: 'brightness(107%)'
		},

		'&:active': {
			filter: 'brightness(120%)'
		},
		'& > p': {
			color: theme.palette.warning.main,
			width: themeVar(theme, 'buttonWidthSmall'),
			textAlign: 'center'
		},
		'&>button:last-child': {
			width: themeVar(theme, 'buttonWidthSmall'),
		},
	},

	innerListView: {
		display: 'flex',
		flexDirection: 'row',
		color: theme.palette.success.main,
		alignItems: 'center',
		justifyContent: 'center',
		gap: theme.glueSpacing('m')
	},

	divider: {
		height: theme.custom.listView.dividerThickness,
		background: 'white',
		opacity: '20%',
	},

	button: {
		marginTop: theme.glueSpacing('xl'),
		marginBottom: theme.glueSpacing('xl'),
		alignSelf: 'center',

		'&>button': {
			width: themeVar(theme, 'buttonWidth'),
			margin: 'auto'
		}
	},

	footer: {
		justifyContent: 'center',
		alignItems: 'center',
		padding: themeVar(theme, 'footerPadding'),
		display: 'flex',
		flexDirection: 'row',
		background: theme.palette.secondary.dark,
		borderRadius: themeVar(theme, 'footerBorderRadius'),
		
		'& > p': {
			maxWidth: '80%',
			marginLeft: theme.glueSpacing('m')
		},
		'&> button:last-child': {
			marginLeft: theme.glueSpacing('xl'),
			width: themeVar(theme, 'buttonWidth')
		}
	}
}));

const MemberAddDialog = (props) =>
{
	const classes = useStyles(props);
	const apollo = useApolloClient();
	const { closePromptDialog } = usePromptDialogContext();

	const [updating, setUpdating] = useState(null);

	const getOrgRes = useQuery(queries.getOrg, {
		skip: !props.orgId,
		variables: {
			orgId: props.orgId
		}
	});

	const teamInfoRes = useQuery(queries.teamInfo, {
		skip: !props.teamId,
		variables: {
			teamId: props.teamId
		},
		fetchPolicy: 'network-only'
	})

	const [ searchWord, setSearchWord ] = useState('');
	const [ hovered, setHovered ] = useState(null);

	const users = getOrgRes.data?.getOrg.members ?? [];
	const filteredUsers = users.filter(user => !searchWord || user.email.includes(searchWord.toLowerCase()));

	const [joinMut] = useMutation(mutations.userJoinTeam);

	const addUser = async (email) =>
	{
		setUpdating(email)
		await joinMut({
			variables: {
				teamId: props.teamId,
				memberId: email,
				role: 'member'
			}
		}).then(res => {
			if (props.refresh)
				props.refresh();
			teamInfoRes.refetch();
		}).catch(err => {
			console.error(err);
		});
		setUpdating(null)
	}

	const isOrgAdmin = (member) =>
	{
		return getOrgRes.data.getOrg.members.find(mem => mem.email === member).memberRole === 'admin';
	}

	const canSetAsTeamAdmin = (email) =>
	{
		const orgMemberData = getOrgRes.data.getOrg.members.find(mem => mem.email === email);
		// TODO: Get rid of this hardcoded shite
		if (!orgMemberData || orgMemberData.memberRole === 'guest') {
			return false;
		}
		return true;
	}

	const isTeamAdmin = (email) =>
	{
		const member = teamInfoRes.data?.teamInfo.members.find(member => member.email === email);

		if (member && member.memberRole === 'superuser') {
			return true;
		}
		return false;
	}

	const updateMember = async(email) =>
	{
		const member = teamInfoRes.data?.teamInfo.members.find(member => member.email === email);

		// Hardcoded role names.....
		const newRole = member.memberRole === 'superuser' ? 'member' : 'superuser';

		setUpdating(email);
		try {
			await changeTeamMemberRole(apollo, props.teamId, email, newRole);
			if (props.refresh) {
				props.refresh();
			}
			teamInfoRes.refetch();
		}
		catch (err) {
			// Error not shown to user in any way??
			console.error(err);
		}
		setUpdating(null);
	}

	const modifyUserButton = (email) =>
	{
		if (teamInfoRes.data && !teamInfoRes.data?.teamInfo.members.some(member => member.email === email))
		{
			if (updating === email || teamInfoRes.loading) {
				return(
					<LoadingIndicator variant='button' />
				)
			}
			return (
				<div>
					<GlueButton
						color={'primary'}
						onPointerDown={() => addUser(email)}
						disabled={teamInfoRes.loading}
					>
						Add
					</GlueButton>
				</div>
			);
		} else {
			return <>
				{(!props.teamAdmin && canSetAsTeamAdmin(email) && !isOrgAdmin(email)) && (updating === email ? <LoadingIndicator variant='button' /> : <SwitchSelector text={'Team Admin:'} checked={isTeamAdmin(email)} onChange={() => updateMember(email)} />)}
				<div className={classes.innerListView}>
					{isOrgAdmin(email) && <GlueTag label={'admin'} color='warning' />}
					<div><Check /></div>
				</div>
			</>
		}
	}

	const memberLabel = (email) =>
	{
		if (updating === email || teamInfoRes.loading) {
			return(
				<LoadingIndicator variant='button' />
			)
		}
		if (teamInfoRes.data && teamInfoRes.data?.teamInfo.members.some(member => member.email === email)) {
			return (
				<div className={classes.innerListView}>
					{isOrgAdmin(email) && (<GlueTag label={'admin'} color='warning' />)}
					<div><Check /></div>
				</div>
			)
		}
		else return <p></p>
	}

	return (
		<div className={classes.root}>
			<div className={classes.topContent}>
				<DialogHeader header={'Manage team members'} plain />
				{/* Search for member by email */}
				{props.web ? (
					<WebInputfield 
						search
						placeholder={'Search'}
						value={searchWord}
						onChange={setSearchWord}
						onClear={() => setSearchWord('')}
						showClearButton={searchWord ? true : false}
					/>
				) : (
					<div className={classes.input}>
						<GlueInputfield
							search
							placeholder={'Search'}
							value={searchWord}
							onChange={setSearchWord}
							onClear={() => setSearchWord('')}
							showClearButton={searchWord ? true : false}
						/>
					</div>
				)}
			</div>

			{/* Another custom list implementation... */}
			<div className={classes.list}>
				<GlueScroll persistent>
					{filteredUsers.map(user => (
						<>
							<div className={classes.listItem} key={user.email} onMouseOver={() => hovered === null && setHovered(user.email)} onMouseLeave={() => setHovered(null)}>
								{user.email}{hovered === user.email ? modifyUserButton(user.email) : memberLabel(user.email)}
							</div>
							<div className={classes.divider} />
						</>
					))}
				</GlueScroll>
			</div>
			<div>
				<div className={classes.button}>
					<GlueButton color='primary' onPointerDown={closePromptDialog}>Done</GlueButton>
				</div>
				{props.teamAdmin && 
					<div className={classes.footer}>
						<InfoOutlinedIcon fontSize={'12px'} />
						<p>If you can not find the people you want to add, they are not users in your organization. Please contact your organization admin to have them invited.</p>
					</div>}
			</div>
		</div>
	);
};

export default MemberAddDialog;