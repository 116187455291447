import React, { useState } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { usePromptDialogContext } from '../../util/prompt-dialog-context';
import { Asset } from '../../graphql/types-generated';
import InfoDialogTemplate from '../common/info-dialog-template';
import mutations from '../../graphql/mutations';
import { makeStyles } from '@material-ui/core';
import GlueButton from '../common/glue-button';
import LoadingIndicator from '../common/loading-indicator';
import WebInputfield from '../../standalone-web/common/web-inputfield';
import GlueInputfield from '../common/glue-inputfield';

const useStyles = makeStyles((theme) => ({
	root: {
	},

	header: {
		height: theme.custom.errorDialog.header.height,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center'
	},

	body: {
		margin: `0 ${theme.glueSpacing('l')}`,
		width: theme.custom.imagePreview.width,
	},

	textArea: {
		marginBottom: theme.glueSpacing('xl'),
		marginTop: theme.glueSpacing('m')
	},

	buttons: {
		margin: 'auto',
		width: 'fit-content',
		display: 'grid',
		gridAutoFlow: 'column',
		gap: theme.glueSpacing('m')
	}
}), { name: 'MuiGlueEditTemplateDescriptionDialog' });

const EditTemplateDescriptionDialog = (props: {
	asset: Asset
	teamId: string
	web?: boolean
}) =>
{
	const classes = useStyles();
	const apollo = useApolloClient();
	const { addDialog, closePromptDialog } = usePromptDialogContext();

	const [ errorMsg, setErrorMsg ] = useState<string>('');
	const [ loading, setLoading ] = useState<boolean>(false);
	const [ text, setText ] = useState(props.asset.description ?? '');

	const editDescription = async (description: string) => {
		setLoading(true);

		try {
			if (!description) {
				throw new Error('Description cannot be empty');
			}

			await apollo.mutate({
				mutation: mutations.updateAssetDescription,
				variables: {
					assetId: props.asset.assetId,
					description: description
				}
			});
		}
		catch (err) {
			setErrorMsg((err as Error).message);
			setLoading(false);
			return;
		}

		closePromptDialog();
		addDialog(<InfoDialogTemplate
			header={'Success!'}
			message={'Space template description was successfully updated.'}
		/>);
	}

	const descriptionMaxLength = 256;

	return (
		<div className={classes.root}>
			<div className={classes.header}>
				<h2>Edit space description</h2>
			</div>
			<div className={classes.body}>
				<div className={classes.textArea}>
				{!!props.web ? (
					<WebInputfield
						simpleInput
						value={text}
						onChange={setText}
						multiline
						width='100%'
						rows={10}
						maxLength={descriptionMaxLength}
						placeholder='Enter a description for the space template'
						error={!!errorMsg}
						errorMessage={errorMsg}
					/>
				) : (
					<GlueInputfield
						simpleInput
						value={text}
						onChange={setText}
						multiline
						width='100%'
						rows={10}
						maxLength={descriptionMaxLength}
						placeholder='Enter a description for the space template'
						error={!!errorMsg}
						errorMessage={errorMsg}
					/>
				)}
				</div>
				<div className={classes.buttons}>
					<GlueButton
						onPointerDown={closePromptDialog}
						disabled={loading}
					>
						Cancel
					</GlueButton>
					<GlueButton
						color="primary"
						onPointerDown={() => editDescription(text)}
						disabled={loading}
					>
						{loading ? <LoadingIndicator variant='button' /> : <p>Save</p>}
					</GlueButton>
				</div>
			</div>
		</div>
	);
};
export default EditTemplateDescriptionDialog;
