import React from "react";
import { makeStyles, Card } from "@material-ui/core";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	root: {
		width: '250px',
		height: '100px',
		padding: theme.glueSpacing('s'),
		
		'& > h3': {
			height: '50%',
			width: 'fit-content',
			maxWidth: '250px',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		}
	}
}));

type OrgCardProps = {
	name: string,
	orgId: string
}

const OrgCard = (props: OrgCardProps) =>
{
	const classes = useStyles();
	const navigate = useNavigate();

	return(<>
		<Card onPointerDown={() => navigate(`${props.orgId}`)} className={classes.root}>
			<h3>{props.name}</h3>
			<p>{props.orgId}</p>
		</Card>
	</>);
};

export default OrgCard;