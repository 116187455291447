import React from 'react';
import ColorSelector from '../common/color-selector';
import { Card, CardMedia, CardActionArea } from '@material-ui/core';

import { makeStyles } from '@material-ui/core';

import Img_NothingSelected from './images/AvatarThumb_NothingSelected.png'
import AvatarFeatures from './avatarfeatures';

import { GlueAccordion, GlueAccordionContentBlock } from '../common/glue-accordion';
import { AssetDescriptor, Avatar, Color, EditedProperty, FeatureProperty, FeatureContainerContents } from './avatar-types';

const useStyles = makeStyles((theme) => ({
	itemRoot: {
		display: 'grid',
		gridAutoFlow: 'column',
		gap: theme.glueSpacing('m')
	},

	cardRoot: {
		width: '140px',
		borderRadius: '4px',
	},

	cardActionArea: {
		height: '140px',
		width: '140px',
		backgroundImage: `linear-gradient(0, #63686e, #454b52)`
	},

	cardImage: {
		height: '140px',
		width: '140px',
	}
}), { name: 'MuiGlueFeatureContainer' });

const FeatureArea = (props: {
	color?: Color
	feature?: AssetDescriptor,
	setEditedProperty: (editedProperty: EditedProperty) => void
}) =>
{
	const classes = useStyles();

	return (
		<div className={classes.itemRoot}>
			{props.color ?
				<ColorSelector
					color={props.color.ColorValue}
					onPointerDown={() => props.setEditedProperty && props.setEditedProperty({ Color: props.color })}
				/>
				:
				<div></div>
			}
			{props.feature ?
			<Card className={classes.cardRoot}>
				<CardActionArea className={classes.cardActionArea}>
					<CardMedia
						component="img"
						className={classes.cardImage}
						onPointerDown={() => props.setEditedProperty({ Feature: props.feature })}
						image={props.feature.ThumbnailUrl}
					/>
				</CardActionArea>
			</Card>
			:
			<div className={classes.cardRoot}></div>
			}
		</div>
	);
}

const FeatureSubgroup = (props: {
	avatar: Avatar,
	prop: FeatureProperty,
	setEditedProperty: (editedProperty: EditedProperty) => void
}) =>
{
	const avatar = props.avatar;

	const findFeature = (type: string): AssetDescriptor =>
	{
		const noFeatureSelected = {
			Prefab: "None",
			ThumbnailUrl: Img_NothingSelected,
			Type: type
		}

		if (type === "Shape")
		{
			const shape = AvatarFeatures.Shape.Assets.find(s => s.PresetName && avatar.config.PresetName.includes(s.PresetName));
			return shape ? shape : noFeatureSelected;
		}

		if (type === "Base")
		{
			const base = AvatarFeatures.Base.Assets.find(b => b.Prefab === avatar.config.Base.AssetName)
			return base ? base : noFeatureSelected;
		}

		const asset = avatar.config.Assets.find(a => a.Type === type);
		if (asset)
		{
			const feature = AvatarFeatures[type].Assets.find(f => f.Prefab === asset.AssetName);
			return feature ? feature : noFeatureSelected;
		}
		return noFeatureSelected;
	}

	const findColor = (id: string) =>
	{
		return avatar.config.Colors.find(c => c.Id === id);
	}
	
	const prop = props.prop;
	const feature = findFeature(prop.FeatureType);
	const useColorsFrom: AssetDescriptor = prop.UseColorsFrom ? findFeature(prop.UseColorsFrom) : feature;

	return (
		<>
			<GlueAccordionContentBlock
				key={prop.ColorId}
				label={prop.TitleOverride ?? prop.FeatureType}
				beginSubgroup
			>
				<FeatureArea
					feature={feature}
					color={(prop.ColorId && feature.Prefab !== "None") ? findColor(prop.ColorId) : undefined}
					setEditedProperty={props.setEditedProperty}
				/>
			</GlueAccordionContentBlock>
		
			{useColorsFrom && useColorsFrom.ColorOptions && (useColorsFrom.ColorOptions.map(color => color.Id !== prop.ColorId && prop.ExcludeColor !== color.Id &&
			(<GlueAccordionContentBlock key={color.Id} label={color.DisplayName}>
				<FeatureArea
					color={findColor(color.Id)}
					setEditedProperty={props.setEditedProperty}
				/>
			</GlueAccordionContentBlock>)))}
		</>);
}

const FeatureContainer = (props: {
	avatar: Avatar,
	contents: FeatureContainerContents,
	open: boolean,
	toggleOpen: () => void,
	setEditedProperty: (editedProperty: EditedProperty) => void
}) =>
{
    return (
		<GlueAccordion title={props.contents.Title} open={props.open} onToggleOpen={props.toggleOpen}>
			{props.contents.Properties.map(prop => 
				<FeatureSubgroup
					key={prop.FeatureType}
					prop={prop}
					setEditedProperty={props.setEditedProperty}
					avatar={props.avatar}
				/>)
			}
		</GlueAccordion>
    );
}

export default FeatureContainer