
import moment from 'moment';
import queries from '../graphql/queries';
import { ApolloClient } from '@apollo/client';
import { ProcessingStatus, QueryProcessingStatusInfoArgs } from '../graphql/types-generated';


export const downloadZipData = async (itemId: string, apollo: ApolloClient<unknown>) =>
{
	if (!itemId) {
		return;
	}

	let zipData = null;

	const processInfoRes = await apollo.query<{ processingStatusInfo: ProcessingStatus }, QueryProcessingStatusInfoArgs>({
		query: queries.processingStatusInfo,
		fetchPolicy: 'network-only',
		errorPolicy: 'all',
		variables: {
			statusItemId: 'status:' + itemId
		}
	});

	if (processInfoRes.error) {
		console.log(processInfoRes.error)
	}

	if (processInfoRes.data) {
		zipData = processInfoRes.data.processingStatusInfo;
	}

	return zipData;
}

export const validDownloadExits = (storageKey: string) =>
{
	const json = localStorage.getItem(storageKey);
	if (!json) {
		return false;
	}
	
	const downloadItem = JSON.parse(json);

	if(!downloadItem)
		return false;

	const timeNow = moment();
	const expiry = moment(downloadItem.expires);

	if (expiry.valueOf() < timeNow.valueOf()) {
		console.log("Link expired...");
		localStorage.removeItem('download-zip');
		return false;
	}
	
	return true;
}