import React, { useState, useEffect } from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { useUserSettings } from '../service/user-settings';

import AppHeader from './common/app-header';
import postVuplexMessage from '../service/message-vuplex';
import queries from '../graphql/queries';
import ColorSelector from './common/color-selector';
import GlueEditor from './common/glue-editor';
import ColorPicker from './common/color-picker';
import GlueButton from './common/glue-button';
import LoadingIndicator from './common/loading-indicator';

const toolName = "3DMarker"

const colorPallette = [
	'#f2f2f2',
	'#ffea63',
	'#ffab66',
	'#ff8787',
	'#fa98d6',
	'#8c90ff',
	'#70dafa',
	'#a5f26d'
];

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100%',
		display: 'flex',
		flexFlow: 'column nowrap',
	},

	appContent: {
		height: '100%',
		display: 'flex',
		flexFlow: 'column nowrap',
		justifyContent: 'space-evenly',
		alignItems: 'center'
	},

	pickGroup: {
		display: 'flex',
		flexFlow: 'column nowrap',
		width: '100%',
		alignItems: 'center'
	},

	pickArea: {
		background: `radial-gradient(circle closest-side at center, ${theme.palette.secondary.main}, ${theme.palette.background.default})`,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		height: '680px',
		width: '680px'
	},

	swatchBar: {
		display: 'grid',
		gridTemplateColumns: 'repeat(8, 64px)',
		gap: theme.glueSpacing('s'),
		marginBottom: theme.glueSpacing('s')
	},

	loadingIndicator: {
		margin: 'auto'
	},
}));

const Draw3DApp = (props) =>
{
	const classes = useStyles(props);
	const apollo = useApolloClient();

	const tabletOpenResult = useQuery(queries.tabletOpen);
	const draw3DToolStatusRes = useQuery(queries.draw3DToolStatus);
	const requestReset = useQuery(queries.penResetRequest);

	const [drawerOpen, setDrawerOpen] = useState(false);

	const selectedColor = draw3DToolStatusRes.data ? draw3DToolStatusRes.data.draw3DToolStatus.color : '#FFFFFF';
	const isToolAttached = draw3DToolStatusRes.data ? draw3DToolStatusRes.data.draw3DToolStatus.attached : true;
	const [lastCustomColor, setLastCustomColor] = useState(selectedColor);

	const { userSettings, setUserSettings, settingsLoading } = useUserSettings();

	const [ colorSelectionIndex, setColorSelectionIndex ] = useState(-1);

	const resetTool = () =>
	{
		postVuplexMessage("Change tool", { name: toolName, state: 1 });
	};

	const despawnAttachedTool = () =>
	{
		postVuplexMessage("Change tool", { name: toolName, state: 2 });
	};

	const onColorSelected = (color) =>
	{
		setLastCustomColor(color);
		postVuplexMessage("Change tool color", { name: toolName, args: { value: color } });
	};

	const handleDrawer = (isDrawerOpen) => {
		let newVector = {x:0.0, y:-250.0, z:-170.0}
		if (isDrawerOpen)
		{
			newVector = {x:350, y:-250, z:-170};
		}
		postVuplexMessage("Set spawn position", {name: toolName, value: newVector});
		setDrawerOpen(isDrawerOpen);
	}

	useEffect(() => {
		if (tabletOpenResult.data.tabletOpen)
			resetTool();
		else
			despawnAttachedTool();

		return () => {
			despawnAttachedTool();
		};
	}, [tabletOpenResult.data.tabletOpen]);

	useEffect(() => {
		if (requestReset.data.penResetRequest === true)
			resetTool();
			apollo.writeQuery({
				query: queries.penResetRequest,
				data: { penResetRequest: false }
			});
		handleDrawer(false);
	}, [requestReset.data.penResetRequest, apollo])

	const customPickerClose = () =>
	{
		handleDrawer(false);
		setLastCustomColor(selectedColor);
	};

	const customPickerCancel = () =>
	{
		handleDrawer(false)
		postVuplexMessage("Change tool color", { name: toolName, args: { value: lastCustomColor } });
	};

	const editColor = (color, mode, selection) =>
	{
		postVuplexMessage("Change tool color", { name: toolName, args: { value: color } });
		setColorSelectionIndex(selection);
	}

	const saveColors = (colors) => 
	{
		setUserSettings({ savedPenColors: colors });
	}

	if (settingsLoading)
	{
		return (
			<div className={classes.loadingIndicator}>
				<LoadingIndicator/>
			</div>
		)
	}

	return (
		<div className={classes.root}>
			<AppHeader title="3D Draw"></AppHeader>
			<div className={classes.appContent}>
				<GlueEditor
					floatControls
					color={selectedColor}
					open={drawerOpen}
					showDrawerControls={!drawerOpen}
					onToggleOpen={() => handleDrawer(!drawerOpen)}
					previewContent={
						<Tooltip
							arrow
							placement="top"
							open={isToolAttached}
							title="Pick Me!"
						>
							<div className={classes.pickGroup}>
								<div className={classes.pickArea} onPointerDown={resetTool}>
								{!isToolAttached && 
									<GlueButton onPointerDown={resetTool}>
										<p>Tap to return pen</p>
									</GlueButton>
								}
								</div>
							</div>
						</Tooltip>
					}
				>
					<ColorPicker
						displayName={"pen color"}
						color={selectedColor}
						presetColors={colorPallette}
						onSetColor={editColor}
						onConfirm={customPickerClose}
						onCancel={customPickerCancel}
						onSaveColor={saveColors}
						onDeleteColor={saveColors}
						savedColors={userSettings.savedPenColors}
						currentSelection={colorSelectionIndex}
					/>
				</GlueEditor>
				{!drawerOpen && 
					<div className={classes.swatchBar}>
						{colorPallette.map((color, i) => (
							<ColorSelector
								key={i}
								color={color}
								onPointerDown={() => onColorSelected(color)}
								toggled={color === selectedColor.toLowerCase()}
							/>
						))}
					</div>
				}
			</div>
		</div>
	);
}

export default Draw3DApp;
