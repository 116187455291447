import React from 'react';
import { makeStyles } from '@material-ui/core';

import TransformUI from './transform-ui';

const useStyles = makeStyles((theme) => ({
	root: {
		height: 'fit-content',
		background: theme.palette.background.paper,
	}
}));

const TransformToolbar = (props) => {
	const classes = useStyles(props);

	return (
		<div className={classes.root}>
			<TransformUI compact/>
		</div>
	);
};

export default TransformToolbar;