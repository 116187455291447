export type Type = {
    height: string
    underlineThickness: string
};

const common: Type = {
    height: '64px',
    underlineThickness: '4px',
};

export const web: Type = {
    ...common,
    
    height: '36px',
    underlineThickness: '2px'
};

export const tablet: Type = {
    ...common
};