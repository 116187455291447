import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import queries from '../graphql/queries';

import HeroIcon from './common/hero-icon';
import FacilitationIcon from '../icons/hero/Facilitation.png';
import { isAtHome } from '../util/space-utils';

const FacilitationHeroIcon = () =>
{
	const currentSpaceServerKeyResult = useQuery(queries.currentSpaceServerKey);
	const atHome = isAtHome(currentSpaceServerKeyResult);

	const currentTeamRes = useQuery(queries.currentTeamId);

	const myAccessInfo = useQuery(queries.myAccessInfo, {
		skip: !currentTeamRes.data,
		variables: {
			teamId: currentTeamRes.data.currentTeamId
		},
		fetchPolicy: 'network-only'
	});

	const hoveraudiomessage = "Menu/Browser/HL";
	const audiomessage = "Menu/Browser/Press";

	if(!myAccessInfo?.data?.myAccessInfo?.team?.isFacilitator)
	{
		return null;
	}

	return (
		<HeroIcon
			name="Facilitation"
			icon={FacilitationIcon} 
			route="/app/facilitation"
			disabled={atHome}
			uiAudioMessage={audiomessage}
			uiHoverAudioMessage={hoveraudiomessage} 
		/>
	);
}

export default FacilitationHeroIcon;
