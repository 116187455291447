/**
 * Use this helper function when you need to forawrd a ref outside of
 * your component (your component is wrapped with forwardRef(YourComponent))
 * and also use the same ref internally via React.useRef.
 * 
 * 
 * Example:
 * 
 * const YourComponent = (props, ref) =>
 * {
 * 	const myRef = useRef();
 *  return (<SomeComponent ref={getAssignerFnForRefs([myRef, ref])} />);
 * };
 * 
 * export default forwardRef(YourComponent);
 * 
 */
const getAssignerFnForRefs = (refs = []) =>
{
	return (el) => {
		refs.forEach((ref) => {
			if (typeof ref === 'function')
			{
				ref(el);
			}
			else if (ref)
			{
				ref.current = el;
			}
		});
	};
};

export default getAssignerFnForRefs;
