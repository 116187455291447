import React from 'react'
import { useApolloClient } from '@apollo/client';
import InfoDialogTemplate from '../common/info-dialog-template';
import queries from '../../graphql/queries';
import { Maybe } from '../../graphql/types-generated';
import { forceCloseSession } from '../../util/space-utils';

const ForceStopSessionDialog = (props: {
	serverKey: string,
	status?: Maybe<string>,
	onSuccess?: () => void
	onError?: (error: Error) => void
}) =>
{
	const apollo = useApolloClient();

	const forceStopSession = async () => {
		const backendInfoRes = await apollo.query({query: queries.backendInfo});

		await forceCloseSession(backendInfoRes.data, props.serverKey, props.status)
		.then(res => {
			if (props.onSuccess) {
				props.onSuccess();
			}
		})
		.catch(err => {
			if (props.onError) {
				props.onError(err);
			}
		});
	}

	return (
		<InfoDialogTemplate
			header={"Force Stop Session?"}
			message={"Are you sure you want to stop this session? This action can not be undone."}
			callbacks={[
				{ callback: () => null, label: 'Cancel' },
				{ callback: forceStopSession, label: 'Force Stop Session', color: "destructive" }
			]}
		/>
	);
}

export default ForceStopSessionDialog;
