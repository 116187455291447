import { useMutation, useQuery } from '@apollo/react-hooks';
import { Card, makeStyles } from '@material-ui/core';
import React, { useEffect, useReducer, useState } from 'react';
import queries from '../../graphql/queries';
import mutations from '../../graphql/mutations';
import GlueRadioGroup from '../../standalone-web/common/glue-radio-group';
import WebInputfield from '../../standalone-web/common/web-inputfield';
import { usePromptDialogContext } from '../../util/prompt-dialog-context';
import GlueButton from '../common/glue-button';

import { Storage } from '@material-ui/icons';
import InfoDialogTemplate from '../common/info-dialog-template';
import { useUserContext } from '../../util/user-context';
import LoadingIndicator from '../common/loading-indicator';

import { Orchestrator, Cluster } from '../../graphql/types-generated';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',

		'& > p': {
			marginTop: '10px'
		},

		'& > h2': {
			margin: `${theme.glueSpacing('xl')} 0`
		}
	},

	orchestratorMap: {
		display: 'flex',
		flexFlow: 'row wrap',
		gap: theme.glueSpacing('m'),

		'& h2:first-child': {
			marginBottom: '25px'
		}
	},

	orchCard: {
		width: '300px',
		height: '150px',
		padding: theme.glueSpacing('s')
	},

	orchCardHeader: {
		display: 'flex',
		height: '60%',
		gap: theme.glueSpacing('s'),

		'& > h3': {
			width: 'fit-content',
			maxWidth: '250px',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		}
	},

	divider: {
		height: '2px',
		width: '100%',
		background: 'white'
	},

	addModal: {
		display: 'flex',
		flexDirection: 'column',
		minWidth: '500px',
		padding: theme.glueSpacing('s'),

		'& > h2': {
			marginBottom: '20px'
		},

		'& > div:last-child': {
			marginTop: theme.glueSpacing('l')
		}
	},

	addModalBtns: {
		display: 'flex',
		gap: theme.glueSpacing('s'),
		justifyContent: 'space-between'
	}
}));

const nodeTypes = [
	{id: 'sessionhost', name: 'Session Host'}
]

const enum ACTION_TYPES {
	INIT,
	SET
}

type SetAction = {
	type: ACTION_TYPES,
	payload: {
		name: string,
		value: string | Orchestrator
	}
}

const initialOrchestratorState : Orchestrator = {
	orchestratorId: '',
	name: '',
	url: '',
	managementPort: undefined,
	startPort: undefined,
	endPort: undefined,
	username: '',
	password: '',
	permission: 'private',
	type: 'sessionhost',
	mediaServerStreamCount: undefined,
	sessionCount: undefined
}

const nodeReducer = (state: Orchestrator, action: SetAction): Orchestrator =>
{
	switch(action.type)
	{
		case ACTION_TYPES.INIT:
			return action.payload.value as Orchestrator;
		case ACTION_TYPES.SET:
			return { ...state, [action.payload.name]: action.payload.value }
		default: 
			return state
	}
}

const initializerFunction = (state: Orchestrator): Orchestrator =>
{
	return {
		orchestratorId: state.orchestratorId,
		name: state.name ?? undefined,
		url: state.url ?? undefined,
		managementPort: state.managementPort ?? undefined,
		startPort: state.startPort ?? undefined,
		endPort: state.endPort ?? undefined,
		username: state.username ?? undefined,
		password: state.password ?? undefined,
		permission: state.permission ?? undefined,
		type: state.type ?? undefined,
		mediaServerStreamCount: undefined,
		sessionCount: undefined
	}
}

const NodeDialog = (props: {
	orchestrator?: Orchestrator,
	close: () => void,
	test?: (or: Orchestrator) => void,
	update?: (or: Orchestrator) => void,
	create?:(or: Orchestrator) => void,
	delete?: (or: Orchestrator) => void
}) =>
{
	const classes = useStyles();

	const [canCreate, setCanCreate] = useState(false);

	const [showPassword, setShowPassword] = useState(false);
	const [attachedClusters, setAttachedClusters] = useState<Cluster[] | null>(null)
	const [nodeData, dispatchNodeData] = useReducer(nodeReducer, initialOrchestratorState);

	const clustersRes = useQuery(queries.myClusters, {
		variables: {
			userRole: 'administrator'
		}
	});

	useEffect(() => {
		if (props.orchestrator && clustersRes.data && attachedClusters === null)
		{
			const clus = clustersRes.data.myClusters.filter((clust: Cluster) => clust.attachedOrchestrators?.includes(props.orchestrator?.orchestratorId ?? ''));

			setAttachedClusters(clus)
		}
	}, [props])

	useEffect(() => {
		if (props.orchestrator) {
			dispatchNodeData({
				type: ACTION_TYPES.INIT,
				payload: { name: '', value: initializerFunction(props.orchestrator) }
			})
		}
	}, [])

	useEffect(() => {
		if (nodeData !== undefined && nodeData?.name && nodeData?.url && nodeData?.managementPort && nodeData?.username && nodeData?.password) {
			setCanCreate(true)
		} else {
			setCanCreate(false)
		}
	})

	const updateMode = props.orchestrator;

	const saveOrCreateButton = () =>
	{
		if (props.update) {
			return props.update(nodeData as Orchestrator);
		}
		if (props.create) {
			return props.create(nodeData as Orchestrator);
		}
	}

	return(
		<div className={classes.addModal}>
			{updateMode ? <h2>Host Node Details</h2> : <h2>Create a new group node</h2>}
			Node type:
			<GlueRadioGroup 
				options={nodeTypes}
				value={nodeData?.type}
				onChange={(val: string) => dispatchNodeData({
					type: ACTION_TYPES.SET,
					payload: { name: 'type', value: val }
				})}
			/>
			Node name
			<WebInputfield 
				value={nodeData?.name}
				onChange={(val: string) => dispatchNodeData({
					type: ACTION_TYPES.SET,
					payload: { name: 'name', value: val }
				})}
				placeholder={'Enter node name'}
			/>
			Node URL
			<WebInputfield
				value={nodeData?.url}
				onChange={(val: string) => dispatchNodeData({
					type: ACTION_TYPES.SET,
					payload: { name: 'url', value: val }
				})}
				placeholder={'Enter node URL'}
			/>
			Node management port
			<WebInputfield
				value={nodeData?.managementPort}
				onChange={(val: string) => dispatchNodeData({
					type: ACTION_TYPES.SET,
					payload: { name: 'managementPort', value: val }
				})}
				placeholder={'Enter management port'}
			/>
			{nodeData.type === nodeTypes[0].id &&
				<div className={classes.addModalBtns}>
					<div>
						Session port range start
						<WebInputfield
							width={'240px'}
							value={nodeData.startPort}
							onChange={(val: string) => dispatchNodeData({
								type: ACTION_TYPES.SET,
								payload: { name: 'startPort', value: val }
							})}
							placeholder={'Enter sesstion port range start'}
						/>
					</div>
					<div>
						Session port range end
						<WebInputfield
							width={'240px'}
							value={nodeData.endPort}
							onChange={(val: string) => dispatchNodeData({
								type: ACTION_TYPES.SET,
								payload: { name: 'endPort', value: val }
							})}
							placeholder={'Enter sesstion port range end'}
						/>
					</div>
				</div>
			}
			<div className={classes.addModalBtns}>
				<div>
					Node management username
					<WebInputfield
						width={'240px'}
						value={nodeData.username}
						onChange={(val: string) => dispatchNodeData({
							type: ACTION_TYPES.SET,
							payload: { name: 'username', value: val }
						})}
						placeholder={'Enter node management username'}
					/>
				</div>
				<div>
					Node management password
					<WebInputfield
						width={'240px'}
						type={showPassword ? 'text' : 'password'}
						value={nodeData.password}
						onChange={(val: string) => dispatchNodeData({
							type: ACTION_TYPES.SET,
							payload: { name: 'password', value: val }
						})}
						placeholder={'Enter node management password'}
					/>
					<GlueButton onPointerDown={() => setShowPassword(!showPassword)}>Show Password</GlueButton>
				</div>
			</div>
			{attachedClusters &&
				<div>
					Host groups using this node:
					{attachedClusters.map((clust: Cluster) => (
						<h3 key={clust.clusterId}>{clust.name}</h3>
					))}
				</div>}
			<div className={classes.addModalBtns}>
				<GlueButton onPointerDown={() => props.close()} >Cancel</GlueButton>
				{updateMode &&
					<GlueButton onPointerDown={() => props.test ? props.test(nodeData as Orchestrator) : () => null}>Test Connection</GlueButton>}
				<GlueButton
					disabled={!canCreate}
					onPointerDown={() => { saveOrCreateButton(); props.close() }} 
					color='primary' 
				>
					{updateMode ? 'Save Changes' : 'Create'}
				</GlueButton>
				{updateMode && 
					<GlueButton 
						color="destructive"
						onPointerDown={() => {props.close(); props.delete && props.delete(nodeData as Orchestrator)}}
					>
						Delete
					</GlueButton>}
			</div>
		</div>
	)
}

const HostNodes = () =>
{
	const classes = useStyles();
	const user = useUserContext();

	const { addDialog, closePromptDialog } = usePromptDialogContext();

	const adminTeamIdRes = useQuery(queries.adminTeamId);

	const orchestratorsRes = useQuery(queries.myOrchestrators);

	const [sessionHosts, setSessionHosts] = useState([]);

	const [createOrchestratorMut] = useMutation(mutations.createOrchestrator);
	const [updateOrchestratorMut] = useMutation(mutations.updateOrchestrator);
	const [testConnectionMut] = useMutation(mutations.testConnection);
	const [deleteOrchestratorMut] = useMutation(mutations.removeOrchestrator);

	useEffect(() => {
		setSessionHosts(orchestratorsRes.data?.myOrchestrators.filter((orch: Orchestrator) => orch.type === 'sessionhost') ?? [])
	}, [orchestratorsRes])

	const createOrchestrator = async (data: Orchestrator) =>
	{
		await createOrchestratorMut({
			variables: {
				...data
			}
		}).then(res => {
			console.log(res);
			orchestratorsRes.refetch()
			addDialog(<InfoDialogTemplate 
				header={'Success'}
				message={'The new orchestrator was created.'}
			/>)
			orchestratorsRes.refetch();
		}).catch(err => {
			console.error(err);
			addDialog(<InfoDialogTemplate 
				isError={true}
				message={'Something went wrong.'}
			/>)
		});
	}

	const updateOrchestrator = async (data: Orchestrator) =>
	{
		await updateOrchestratorMut({
			variables: {
				...data
			}
		}).then(res => {
			console.log(res)
			orchestratorsRes.refetch()
			addDialog(<InfoDialogTemplate 
				header={'Success'}
				message={'The update was successful.'}
			/>)
		}).catch(err => {
			addDialog(<InfoDialogTemplate 
				isError={true}
				message={'Something went wrong.'}
			/>)
			console.error(err)
		});
	}

	const deleteOrchestrator = async (data: Orchestrator) =>
	{
		if (!adminTeamIdRes.data.adminTeamId)
		{
			console.error("Missing admin team ID!");
			return;
		}
		await deleteOrchestratorMut({
			variables: {
				orchestratorId: data.orchestratorId,
				userId: user.email,
				accessTeamId: adminTeamIdRes.data.adminTeamId
			}
		}).then(res => {
			orchestratorsRes.refetch()
			addDialog(<InfoDialogTemplate 
				header={'Success!'}
				message={`Orchestrator ${data.name} was deleted.`}
			/>)
		}).catch(err => {
			addDialog(<InfoDialogTemplate 
				isError={true}
				message={'Something went wrong.'}
			/>)
			console.error(err)
		});
	}

	const testConnection = async (data: Orchestrator) =>
	{
		await testConnectionMut({
			variables: {
				...data
			}
		}).then(res => {
			const responseRaw = res.data.testConnection;
			if (responseRaw.statusCode !== 200)
			{
				const message = JSON.parse(responseRaw.body)
				addDialog(<InfoDialogTemplate
					isError={true}
					message={`Connection Error on ${data.name}! ${message.Error}`}
				/>)
			} else {
				addDialog(<InfoDialogTemplate
					header={'Connection Test'}
					message={'Connection test completed successfully on ' + data.name}
				/>)
			}
			closePromptDialog();
		}).catch(err => {
			console.error(err)
			addDialog(<InfoDialogTemplate 
				isError={true}
				message={'Something went wrong.'}
			/>)
		});
	}

	const getOrchStateText = (orch: Orchestrator) =>
	{
		const availablePorts = (orch.endPort && orch.startPort && orch.endPort - orch.startPort + 1) ?? undefined;
		let sessionStatsText = 'No sessions running';
		if (orch.sessionCount && orch.sessionCount > 0)
		{
			sessionStatsText = orch.sessionCount + '/' + availablePorts + ' Session(s) running';
		}
		return sessionStatsText;
	}

	const OrchestratorDialog = (orch: Orchestrator) =>
	{
		addDialog(
			<NodeDialog
				close={() => closePromptDialog()}
				orchestrator={orch}
				update={(or: Orchestrator) => updateOrchestrator(or)}
				test={(or: Orchestrator) => testConnection(or)}
				delete={(or: Orchestrator) => addDialog(<InfoDialogTemplate
					header={'DELETE?'}
					message={`Are you sure you want to delete ${or.name} permanently?`}
					callbacks={[
						{ label: 'Cancel', callback: () => closePromptDialog() },
						{ label: 'Delete', color: 'destructive', callback: () => deleteOrchestrator(or) }
					]}
				/>)}
			/>
		)
	}

	return(
		<div className={classes.root}>
			<div>
				<GlueButton 
					color='primary'
					onPointerDown={() => addDialog(
						<NodeDialog
							close={() => closePromptDialog()}
							create={(values: Orchestrator) => createOrchestrator(values)}
						/>
					)}
				>
					Create Node
				</GlueButton>
			</div>
			<h2>Session Hosts:</h2>
			<div className={classes.orchestratorMap}>
				{orchestratorsRes.data ?
					(sessionHosts.map((orch: Orchestrator, i: number) => (
						<Card key={i} className={classes.orchCard}
							onPointerDown={() => OrchestratorDialog(orch)}
						>
							<div className={classes.orchCardHeader}>
								<Storage />
								<h3>{orch.name}</h3>
							</div>
							<div className={classes.divider} />
							<p>{getOrchStateText(orch)}</p>
						</Card>
					))) : (
					<div>
						<LoadingIndicator />
					</div>
					)
				}
			</div>
		</div>
	)
}

export default HostNodes;