import { gql } from '@apollo/client';

const typeDefs = gql`
	type BatteryStatus {
		present: Boolean,
		charging: Boolean,
		chargeLevel: Float
	}

	type PointingToolStatus {
		active: Boolean,
		attached: Boolean
	}

	type Draw3DToolStatus {
		active: Boolean,
		attached: Boolean,
		color: String
	}

	type ClockStopwatch {
		elapsedTotal: Int,
		startedAt: Int,
		running: Boolean
	}

	type ClockTimer {
		timeLeft: Int
		startedAt: Int
		running: Boolean
		alarm: Boolean
	}

	type Locations {
		names: [String]
	}

	type FileImport {
		loading: Boolean
	}

	type FileUpload {
		uploadStatus: String
	}

	type Context {
		header: String
		contextList: [String]
	}

	type Vector3 {
		x: Float!
		y: Float!
		z: Float!
	}

	type Transform {
		position: Vector3!
		rotation: Vector3!
		scale: Vector3!
	}

	type TransformSupport {
		move: Boolean!
		rotate: Boolean!
		scale: Int!
	}

	type ClientPlatformInfo {
		OS: String!
		DeviceModel: String!
		PlatformType: String!
		Capabilities: [String!]!
	}

	type BrowserState {
		url: String!
		title: String!
		sharing: Boolean!
	}

	type BrowserNavigation {
		canGoBack: Boolean!
		canGoForward: Boolean!
	}

	type FileInfo {
		fileName: String!
		fileType: String!
		fileSizeBytes: Int!
	}

	type BrowserDownloadState {
		fileInfo: FileInfo!
		status: String!
		progress: Int!
	}

	type BrowserFileImportState {
		status: String!
		spaceImportProgress: Int!
		filesImportProgress: Int!
		error: String
	}

	type BrowserFileUploadState {
		status: String!
		multipleAllowed: Boolean!
		acceptFilters: [String!]!
		error: String
	}

	type BrowserAppState {
		appname: String
		appurl: String
		isProdTool: Boolean
	}

	type SpeechRecognition {
		previewText: String
		text: String
		loading: Boolean
		running: Boolean
		sinkId: String
		dialogOpen: Boolean
		failed: Boolean
	}

	type SpeechRecognitionToken {
		token: String!
		region: String!
	}

	type TimeRange {
		start: Float!
		end: Float!
	}

	type VideoPlayerState {
		time: Float!
		frame: Int!
		playbackRate: Float!
		volume: Float!
		muted: Boolean!
		playing: Boolean!
		paused: Boolean!
		finished: Boolean!
		loop: Boolean!
		buffering: Boolean!
		seeking: Boolean!
		sharing: Boolean!
		inventoryItemId: ID!
		bufferedTimes: [TimeRange!]!
	}

	type VideoMetadata {
		duration: Float!
		durationFrames: Int!
		frameRate: Float!
		hasAudio: Boolean!
	}

	type ExternalBrowserInfo {
		instanceId: String!
		sharing: Boolean!
	}

	type MicrophoneSettings {
		microphoneList: [String!]!
		currentAudioDevice: String
	}
	
	type EmailValidationResult {
		email: String!
		verdict: String!
		suggestion: String
	}

	type SpaceTemplateDownloadState {
		sceneUrl: String!
		status: String!
		progress: Int!
		error: String
	}

	type BackendInfo {
		backendUri: String!
		backendToken: String!
	}

	input TeamFileCreationParams {
		name: String!
		permission: String!
		generateFilePostfix: Boolean
	}

	type SessionData {
		sessionServicesPort: Int!
		sessionId: String!
		sessionServicesUrl: String!
		streamingServerUrl: String!
		dailyMeetingToken: String!
	}

	type SessionError {
		errorCode: Int!
		errorDescription: String!
		errorDetails: String
	}

	type SessionStartStatus {
		data: SessionData
		message: String
		error: SessionError
	}
`;

export default typeDefs;
