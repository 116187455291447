import React from 'react';
import { makeStyles, IconButton } from '@material-ui/core';

import GlueButton from '../common/glue-button';
import { postVuplexMessage } from '../../service/message-vuplex';
import moment from 'moment';
import { getHumanizedFileSize } from '../../util/file-utils';
import { clickAudio, hoverAudio } from '../common/common-vuplex-messages';

import SVGIconWrapper from '../common/svg-icon-wrapper';
import {ReactComponent as ClearIcon} from '../../icons/Close.svg';
import Import3DImage from '../../images/3D-Import.png';

export const isViewable = (inventoryItemInfo) => {
	const str = inventoryItemInfo.originalName.slice(-4).toLowerCase();
	const is3dObject = (inventoryItemInfo.itemType === "application/octet-stream" || inventoryItemInfo.itemType === 'application/vnd.ms-pki.stl') &&
		(str === '.fbx' || str === '.glb' || str === '.obj' || str === '.stl' || str === '.ply' || str === '.3mf' || str === 'gltf')
	return is3dObject;
};

const useStyles = makeStyles((theme) => ({
	root: {
		flex: '1 0 auto',

		display: 'flex',
		height: '928px',
		flexFlow: 'column nowrap',
		alignItems: 'center',
		textAlign: 'center',
		position: 'relative',

		background: theme.palette.background.paper,

		'&> button': {
			float: "right",
			position: 'absolute',
			top: '0px',
			right: '0px',
			padding: '0',
			margin: '12px',
		},

		'&> h2': {
			margin: '20px',
		}
	},

	displayWindow: {
		margin: '5% 20% 0% 20%',
		justifySelf: 'end'
	},

	fileInfo: {
		height: '500px',
		width: '500px',
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
		flexFlow: 'column',
		borderRadius: '25px',

		background: theme.palette.background.paper,

		'&> p': {
			marginTop: '0px',
			marginBottom: '5px'
		}
	},

	title: {
		display: 'inline-block',
		maxWidth: '700px',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		textAlign: 'center',
	},

	image: (props) => {
		const size = props.size ? props.size : '280px';

		return {
			width: size,
			height: size,
			display: 'block'
		};
	}
}));

export const importFile = (teamId, data) => 
{
	postVuplexMessage("Import model", { teamId: teamId, fileUrl: data.signedURLs.itemURL });
	postVuplexMessage("Close tablet", null);
};

const FileView3D = (props) => 
{
	const classes = useStyles();

	const importAndClosePreview = (teamId, data) => 
	{
		importFile(teamId, data);
		closePreview();
	};

	const closePreview = e => props.closePreview(e);

	const importaudiomessage = "Menu/TeamFiles/3DImport/Import/Press";
	const importhoveraudiomessage = "Menu/TeamFiles/3DImport/Import/HL";
	const closeaudiomessage = "Menu/TeamFiles/3DImport/Close/Press";
	const closehoveraudiomessage = "Menu/TeamFiles/3DImport/Close/HL";

	return (
		<div className={classes.root}>
			<h2 className={classes.title}>{props.itemInfo.name}</h2>
			<IconButton
				size={'small'}
				style={{ borderRadius: '50%' }}
				onPointerDown={e => {closePreview(e); clickAudio(closeaudiomessage)}}
				onPointerEnter={() => {hoverAudio(closehoveraudiomessage)}}
			>
				<SVGIconWrapper>
					<ClearIcon />
				</SVGIconWrapper>
			</IconButton>
			<div className={classes.fileInfo}>
				<img className={classes.image} src={Import3DImage} alt="" />

				<p>filesize: {getHumanizedFileSize(props.itemInfo.fileSize)}</p>
				<p>last modified: {moment(props.itemInfo.updatedDate).format('YYYY-MM-DD HH:mm')}</p>
				<div style={{ margin: '15px' }}>
					<GlueButton
						color={'primary'}
						onPointerDown={() => importAndClosePreview(props.teamId, {
							...props.itemInfo,
							signedURLs: props.signedURLs
						})}
						uiAudioMessage={importaudiomessage}
						uiHoverAudioMessage={importhoveraudiomessage}
					>
						Import
					</GlueButton>
				</div>
			</div>
			<div className={classes.displayWindow}>
				<h2>Please be mindful that importing very large files may impact performance.</h2>
				<p style={{ marginBottom: '2px' }}>For further information, please refer to 3D Model Import Guidelines on the Glue website:</p>
				<p style={{ marginTop: '0px' }}>www.glue.work/3d-import</p>
			</div>
		</div>
	);
};

export default FileView3D;
