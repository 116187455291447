import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core';

import GlueInputField from '../common/glue-inputfield';

import XDragIcon from '../../icons/DragX.png'
import YDragIcon from '../../icons/DragY.png'
import ZDragIcon from '../../icons/DragZ.png'
import XDragBlueIcon from '../../icons/DragX_Blue.png'
import YDragBlueIcon from '../../icons/DragY_Blue.png'
import ZDragBlueIcon from '../../icons/DragZ_Blue.png'

const transformPalette = {
	x: {
		light: '#D52224',
		dark: '#8C1618',
	},
	y: {
		light: '#75A74B',
		dark: '#446629',
	},
	z: {
		light: '#007EDE',
		dark: '#0053A6',
	},
	dragArea: {
		text: '#fff'
	},
	inputField: {
		background: '#0E1012',
		outline: '#808080',
		outlineFocused: '#007EDE',
		outlineProportional: '#fff'
	}
}

const useStyles = makeStyles((theme) => ({
	root: (props) => ({
		display: 'flex',
		width: props.compact ? '192px' : '256px',
		height: props.compact ? '48px' : '64px',
		textAlign: 'left',
		justifyContent: 'right',
		alignItems: 'center',
		background: props.drag ? theme.palette.secondary.active : transformPalette[props.axis].light,
		borderRadius: '999999px',
		gridAutoFlow: 'column',
		gap: '4px',
		opacity: props.disabled ? '50%' : '100%'
	}),

	dragArea: (props) => ({
		width: '50%',
		height: '100%',
		alignItems: 'center',
		justifyContent: 'center',

		'& > p': {
			color: props.drag ? theme.palette.secondary.textActive : transformPalette.dragArea.text,
			margin: '0 2px'
		},

	}),

	imageArea: {
		display: 'block',
		backgroundSize: 'cover',
		width: '60%',
		height: '70%',
		margin: 'auto',
		marginTop: '10%'
	}
}));

const getOutlineColor = (theme, props) => {
	if (props.drag)
		return transformPalette.inputField.outlineFocused;
	else if (props.proportional)
		return transformPalette.inputField.outlineProportional;
	else return transformPalette.inputField.outline;
};

const TransformInput = (props) =>
{
	const classes = useStyles(props);
	const theme = useTheme();
	let dragSrc = null;
	let dragSelSrc = null;

	switch(props.label)
	{
		case 'X':
			dragSrc = XDragIcon;
			dragSelSrc = XDragBlueIcon;
			break;
		case 'Y':
			dragSrc = YDragIcon;
			dragSelSrc = YDragBlueIcon;
			break;
		case 'Z':
			dragSrc = ZDragIcon;
			dragSelSrc = ZDragBlueIcon;
			break;
		default:
			console.error("Undefined label in transform UI");
			return 0;
	}				

	return (
		<div className={classes.root} >
			<div className={classes.dragArea} onPointerDown={props.disabled || props.drag ? null : props.onClickDragArea}>
				<div className={classes.imageArea} style={{ backgroundImage: `url(${props.drag ? dragSelSrc : dragSrc})` }}>

				</div>
			</div>
			<GlueInputField 
				simpleInput 
				height={props.compact ? '48px' : '64px'}
				width={'50%'}
				backgroundColor={transformPalette.inputField.background}
				customFocusBackground={transformPalette.inputField.background}
				onChange={props.onChange} 
				value={props.value} 
				maxLength={props.maxLength} 
				onSubmit={props.onSubmit} 
				outlineColor={getOutlineColor(theme, props)}
				centerText
				disabled={props.disabled}
				setFocus={(value) => value ? null : props.onBlur()}
			/>
		</div>
	)
}

export default TransformInput;