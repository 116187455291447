import React, { useEffect, useRef } from 'react';
import queries from '../graphql/queries';
import { useQuery, useApolloClient } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core';
import postVuplexMessage from '../service/message-vuplex';

import Lock from '@material-ui/icons/Lock';
import LockOpen from '@material-ui/icons/LockOpen';
import GlueMenuItem from './common/glue-menu-item';
import CloseButton from './common/close-button';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexFlow: 'column nowrap',

		width: '360px',
		background: theme.palette.background.paper,
		borderRadius: ({ android }) =>
			android ? '0px' : '4px',
		padding: theme.glueSpacing('s')
	},

	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},

	label: {
		overflow: 'hidden',
		textAlign: 'left',
		margin: `0 ${theme.glueSpacing('m')}`,
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis'
	},

	tabContent: {
		marginTop: theme.glueSpacing('s'),
	},

	lockIcon: {
		color: theme.palette.stealth.contrastText
	},

	additionalData: {
		width: '100%',
		alignItems: 'center',
		justifyContent: 'center',
		'& > p': {
			textAlign: 'center',
			margin: '5px 0 5px 0'
		}
	}
}));

const parseContextList = (contextList) =>
{
	let instanceId = 0;
	const options = [];
	let locked = false;

	contextList.forEach((item) => {
		const lockMatches = item.match(/^Lock:(.*):(True|False)$/);
		if (lockMatches)
		{
			instanceId = Number(lockMatches[1]);
			locked = (lockMatches[2] === 'True');
		}
		else
		{
			options.push({ id: item, name: item, disabled: false });
		}
	});

	options.forEach(option => {
		option.disabled = locked && ["Delete", "Manipulate object", "Edit"].some(id => id === option.id);
	});

	// Always have "Delete" be the last option
	const deleteIndex = options.findIndex(option => option.id === "Delete");
	if (deleteIndex !== -1)
	{
		options.push(options.splice(deleteIndex, 1)[0]);
	}

	return { instanceId, options, locked };
};

const ContextMenu = (props) =>
{
	const apollo = useApolloClient();

	const currentContext = useQuery(queries.context);
	const clientPlatformResult = useQuery(queries.clientPlatform);
	const clientPlatform = clientPlatformResult.data?.clientPlatform;

	const classes = useStyles({ android: clientPlatform?.OS === "Android" });

	const sizeRef = useRef(null);
	const reliefSpacing = 1;

	const { instanceId, options, locked } = parseContextList(currentContext.data.context.contextList);

	const closeContextMenu = () =>
	{
		postVuplexMessage("Context hide", null);
	};

	const onSelect = (name) =>
	{
		console.log("Sending value = " + name);
		postVuplexMessage("Context select", { value: name });

		if (name === 'Delete')
		{
			postVuplexMessage('Close Object transform edit');
		}
		else if (name === 'Edit')
		{
			postVuplexMessage('Close Object transform edit');
			postVuplexMessage('Open tablet');
		}
	};

	useEffect(() => {
		//FIXME the context lins may be insufficient check in the future
		if (sizeRef.current && currentContext.data.context.contextList.length > 0)
		{
			postVuplexMessage(
				"Context size",
				{
					width: sizeRef.current.offsetWidth + reliefSpacing,
					height: sizeRef.current.offsetHeight + reliefSpacing
				}
			);
		}
	}, [currentContext.data, sizeRef]);

	const toggleLockOpen = () =>
	{
		const newLockState = !locked;
		postVuplexMessage("Set Object transform lock", { locked:  newLockState, instanceId: instanceId });

		apollo.writeQuery({
			query: queries.objectTransformLock,
			data: { objectTransformLock: newLockState }
		});

		closeContextMenu();
	};

	const contextOptions = (
		<>
			{instanceId !== 0 && ( // If object is dynamic, it can be locked / unlocked
				<GlueMenuItem 
					key={'lock'}
					onPointerDown={() => toggleLockOpen()}
				>
					{locked ? "Unlock" : "Lock"}
					{locked ? <Lock className={classes.lockIcon}/> : <LockOpen/>}
				</GlueMenuItem>
			)}
			{options.map(({id, name, disabled}) => (
				<GlueMenuItem 
					key={id}
					onPointerDown={() => onSelect(name)}
					disabled={disabled}
				>
					{name}
				</GlueMenuItem>
			))}
		</>
	);

	return (
		<div className={classes.root} ref={sizeRef}>
			<div className={classes.header}>
				<div className={classes.label}>
					<h2>{currentContext.data.context.header}</h2>
				</div>
				
				<CloseButton onClose={closeContextMenu}/>
			</div>
			{/* Placeholder for future */}
			{/* <div className={classes.additionalData}>
				<p>{}</p>
			</div> */}
			<div className={classes.tabContent}>
				{contextOptions}
			</div>
		</div>
	);
};

export default ContextMenu;