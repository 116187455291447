import React, { useEffect, useState } from 'react';
import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import { makeStyles, TextField } from '@material-ui/core';
import moment from 'moment';

import mutations from '../../graphql/mutations';
import queries from '../../graphql/queries';

import { usePromptDialogContext } from '../../util/prompt-dialog-context';
import GlueButton from '../common/glue-button';
import GlueDialog from '../common/glue-dialog';
import GlueInputfield from '../common/glue-inputfield';
import UploadSpaceAsset from './upload-space-asset';
import ServerComponentEditor from './server-component-editor';
import GlueScroll from '../common/glue-scroll';
import InfoDialogTemplate from '../common/info-dialog-template';
import ImagePreviewComponent from '../common/image-preview-component';
import { assetVisibility, uploadAssetThumbnailImage } from './space-asset-common';
import checkStatusItem from '../../util/check-status-item';

import SpaceThumbnail_Placeholder from '../../images/SpaceThumbnail_Placeholder.png';
import SetAssetVersionPointer from './set-asset-version-pointer';
import GlueDropdown from '../common/glue-dropdown';
import LinkTeams from './link-teams';
import { AssetVersion, ServerComponent, VersionPointer } from '../../graphql/types-generated';

const useStyles = makeStyles(theme => ({
	root: {

	},

	assetDetails: {
		display: 'flex',
		gap: '12px',
		flexFlow: 'column nowrap'
	},

	assetDetailsFormContainer: {
		display: 'grid',
		gridTemplateColumns: '1fr min-content',
		gap: '32px'
	},

	assetVisibility: {
		display: 'flex',
		gap: theme.glueSpacing('m'),
		alignItems: 'center'
	},

	existingVersions: {
		display: 'flex',
		height: '280px'
	},

	listSlab: {
		background: theme.palette.background.paper,
		marginBottom: '1em',
		display: 'grid',
		gridTemplateColumns: '1fr min-content min-content',
		alignItems: 'center'
	},

	linkTeamsStaging: {
		width: '100%',
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
		gap: '32px'
	},

	teamSlab: {
		background: theme.palette.background.paper,
		borderRadius: '16px',
		padding: '16px',
		marginBottom: '12px',
		display: 'grid',
		width: '80%',
		gridTemplateColumns: '1fr min-content',
		alignItems: 'center'
	},

	thumbnail: {
		display: 'block',
		width: '296px',
		height: '168px'
	}
}));

const ManageAsset = (props: {
	targetId: string
	onChangeTarget: (assetId: string) => void

}) =>
{
	const classes = useStyles();

	const apollo = useApolloClient();

	const backendInfoRes = useQuery(queries.backendInfo);
	const assetInfoRes = useQuery(queries.assetInfo, {
		variables: {
			assetId: props.targetId
		},
		skip: !props.targetId,
		fetchPolicy: "network-only"
	});
	const serverComponentsRes = useQuery(queries.assetServerComponents, {
		variables: {
			assetId: props.targetId
		},
		skip: !props.targetId,
		fetchPolicy: "network-only"
	});

	const [name, setName] = useState("");
	const [thumbnailUrl, setThumbnailUrl] = useState("");
	const [description, setDescription] = useState("");
	const [visibility, setVisibility] = useState<string>(assetVisibility.limited);
	const [showServerComponentEditor, setShowServerComponentEditor] = useState(false);
	const [targetServerComponentId, setTargetServerComponentId] = useState<string | undefined>(undefined);
	const [showRemoveServerComponentConfirmation, setShowRemoveServerComponentConfirmation] = useState(false);
	const [localThumnailFile, setLocalThumbnailFile] = useState<File | undefined>();

	const isCreatingNew = !props.targetId;

	useEffect(() => {
		if (assetInfoRes.data)
		{
			const assetInfo = assetInfoRes.data.assetInfo;
			setName(assetInfo.name);
			setThumbnailUrl(assetInfo.thumbnailUrl ? assetInfo.thumbnailUrl : "");
			setDescription(assetInfo.description ? assetInfo.description : "");
			setVisibility(assetInfo.permission);
		}
		else
		{
			setName("");
			setThumbnailUrl("");
			setDescription("");
			setVisibility(assetVisibility.limited);
		}

		setLocalThumbnailFile(undefined);
	}, [assetInfoRes]);

	const [ crateAssetMut ] = useMutation(mutations.createAsset);
	const [ updateAssetMut ] = useMutation(mutations.updateAsset);
	const [ assetRemoveServerComponentMut ] = useMutation(mutations.removeServerComponent);

	const { addDialog } = usePromptDialogContext();

	const visibilityOptions = [
		{ id: assetVisibility.public, name: 'Public' },
		{ id: assetVisibility.limited, name: 'Limited'},
		{ id: assetVisibility.team, name: 'Team (Custom)'},
		{ id: assetVisibility.org, name: 'Org (Custom)'},
	];

	const assignLocalFileAsThumbnail = async () =>
	{
		if (!assetInfoRes.data?.assetInfo || !localThumnailFile || !backendInfoRes.data)
		{
			console.error("Thumbnails upload failed");
			return;
		}

		const statusItemId = await uploadAssetThumbnailImage(
			apollo,
			localThumnailFile,
			assetInfoRes.data?.assetInfo.assetId,
		);

		if (!statusItemId)
		{
			addDialog(<InfoDialogTemplate
				isError={true}
				message={"Thumbnail upload failed"}
			/>);
			return;
		}

		const statusRes = await new Promise((resolve, reject) =>
		{
			const check = async () => {
				const statusInfo = await checkStatusItem(apollo, statusItemId);
				console.log("Thumbnail upload status", statusInfo);
	
				if (!statusInfo || statusInfo.statuscode === 'Failed')
				{
					resolve(false);
					return;
				}
	
				if (statusInfo.statuscode === 'Finished')
				{
					resolve(true);
					return;
				}

				setTimeout(check, 500);
			};

			check();
		});

		if (statusRes)
		{
			addDialog(<InfoDialogTemplate
				message={"The new thumbnail has been uploaded."}
			/>);
			assetInfoRes.refetch();
		}
		else
		{
			addDialog(<InfoDialogTemplate
				isError={true}
				message={"Thumbnail upload failed"}
			/>);
		}

		setLocalThumbnailFile(undefined);
	};

	const submit = async () =>
	{
		if (isCreatingNew)
		{
			const createAssetRes = await crateAssetMut({
				variables: {
					name: name,
					type: 'space',
					thumbnailUrl: thumbnailUrl,
					description: description,
					permission: visibility
				},
				refetchQueries: [{
					query: queries.myAssets,
					variables: { typeFilter: 'space' },
					fetchPolicy: 'network-only'
				}],
				errorPolicy: 'all'
			});

			if (createAssetRes.errors)
			{
				console.error("Creating Asset failed", createAssetRes.errors);
				addDialog(<InfoDialogTemplate
					isError={true}
					message={"Creating Asset failed"}
				/>);
			}
			else
			{
				props.onChangeTarget(createAssetRes.data.createAsset.assetId);
			}
		}
		else
		{
			if (!assetInfoRes.data)
			{
				console.error("Cannot save. Original Asset info has not been loaded");
				return;
			}

			const updateAssetRes = await updateAssetMut({
				variables: {
					...assetInfoRes.data.assetInfo,
					name: name,
					thumbnailUrl: thumbnailUrl,
					description: description,
					permission: visibility
				},
				refetchQueries: [{
					query: queries.myAssets,
					variables: { typeFilter: 'space' },
					fetchPolicy: 'network-only'
				}],
				errorPolicy: 'all'
			});

			if (updateAssetRes.errors)
			{
				console.error("Updating Asset failed", updateAssetRes.errors);
				addDialog(<InfoDialogTemplate
					isError={true}
					message={"Updating Asset failed"}
				/>);
			}

			assetInfoRes.refetch();
		}
	};

	const upgradeToVersioned = async () =>
	{
		await apollo.mutate({
			mutation: mutations.upgradeAssetToVersioned,
			variables: {
				assetId: props.targetId,
			}
		}).catch((e) => {
			console.error("Failed to upgrade Asset. Error: ", e);
		});

		assetInfoRes.refetch();
	};

	const archive = async (value: boolean) => {
		await updateAssetMut({
			variables: {
				assetId: props.targetId,
				archived: value
			},
			refetchQueries: [{
				query: queries.assetInfo,
				variables: {
					assetId: props.targetId
				},
				fetchPolicy: "network-only"
			}, {
				query: queries.myAssets,
				variables: { typeFilter: 'space' },
				fetchPolicy: 'network-only'
			}],
			errorPolicy: 'all'
		});
	}

	const onCloseServerComponentEditor = () =>
	{
		setShowServerComponentEditor(false);
		setTargetServerComponentId(undefined);
	};

	const removeServerComponent = async () =>
	{
		if (!targetServerComponentId)
			return;

		await assetRemoveServerComponentMut({
			variables: {
				assetId: props.targetId,
				componentId: targetServerComponentId,
			},
			refetchQueries: [
				{
					query: queries.assetInfo,
					variables: { assetId: props.targetId },
					fetchPolicy: 'network-only'
				}
			]
		});

		setTargetServerComponentId(undefined);
		setShowRemoveServerComponentConfirmation(false);
	}

	return (
		<div className={classes.root}>
			<div className={classes.assetDetails}>
				{isCreatingNew ? (
					<h2>Create new Space Asset</h2>
				) : (<>
					<h2>Edit Asset details</h2>
					<p>{assetInfoRes.data?.assetInfo.assetId}</p>
					<p>{assetInfoRes.data?.assetInfo.archived && "Archived"}</p>
				</>)}

				<div className={classes.assetDetailsFormContainer}>
					<div>
						<GlueInputfield
							simpleInput
							maxLength={128}
							width={'auto'}
							value={name}
							onChange={(value: string) => setName(value)}
							placeholder={"Name"}
							disabled={assetInfoRes.data?.assetInfo.archived}
						/>
						<GlueInputfield
							simpleInput
							width={'auto'}
							value={thumbnailUrl}
							onChange={(value: string) => setThumbnailUrl(value)}
							placeholder={"Thumbnail URL"}
							disabled={assetInfoRes.data?.assetInfo.archived}
						/>
						<p>Current asset visibility: {assetInfoRes.data ? visibilityOptions.find(option => option.id === assetInfoRes.data?.assetInfo.permission)?.name : '-'}</p>
						<div className={classes.assetVisibility}>
							<p>Change visibility:</p>
							<GlueDropdown
								items={visibilityOptions}
								value={visibility} 
								onChange={(id, checked) => checked && setVisibility(id as string)}
							/>
						</div>
						<TextField
							label="description"
							value={description}
							onChange={(e) => setDescription(e.target.value)}
							multiline
							fullWidth
							disabled={assetInfoRes.data?.assetInfo.archived}
						/>
					</div>

					{assetInfoRes.data && (
						<div>
							<ImagePreviewComponent
								image={
									localThumnailFile ??
									// Work around browser caching behavior. The image is likely to change even though the URL stays the same.
									(thumbnailUrl ? thumbnailUrl + '?t=' + Math.floor(Math.random() * Number.MAX_SAFE_INTEGER) : null) ??
									SpaceThumbnail_Placeholder
								}
							/>

							<input
								type="file"
								accept="image/png, image/jpeg"
								onChange={(ev) => setLocalThumbnailFile(ev.target.files?.item(0) ?? undefined)}
								disabled={assetInfoRes.data?.assetInfo.archived}
							/>

							{localThumnailFile && (<>
								<p>Showing a preview of your local thumbnail candidate</p>
								<div>
									<GlueButton onPointerDown={assignLocalFileAsThumbnail}>Set as thumbnail</GlueButton>
									<GlueButton onPointerDown={() => setLocalThumbnailFile(undefined)}>Clear</GlueButton>
								</div>
							</>)}
						</div>
					)}
				</div>

				<GlueButton width={'350px'} disabled={name.length < 1} onPointerDown={() => submit()}>{isCreatingNew ? "Create" : "Save"}</GlueButton>

				{!isCreatingNew && !assetInfoRes.data?.assetInfo.versions && (
					<GlueButton width={'350px'} onPointerDown={upgradeToVersioned} disabled={!assetInfoRes.data}>Upgrade to versioned</GlueButton>
				)}
				{!isCreatingNew && (
					<GlueButton width={'350px'} onPointerDown={() => archive(!assetInfoRes.data?.assetInfo.archived)} disabled={!assetInfoRes.data}>
						{assetInfoRes.data?.assetInfo.archived ? "Unarchive asset" : "Archive asset"}
					</GlueButton>
				)}
			</div>
			{!isCreatingNew && !assetInfoRes.data?.assetInfo.archived && (<>
				<h2>Server components</h2>

				<ul>
					{serverComponentsRes.data?.assetServerComponents?.map((serverComponent: ServerComponent) => (
						<li key={serverComponent.componentId} className={classes.listSlab}>
							<div>{serverComponent.name}</div>
							<GlueButton
								onPointerDown={() => {
									setTargetServerComponentId(serverComponent.componentId);
									setShowServerComponentEditor(true);
								}}
							>
								Edit
							</GlueButton>
							<GlueButton
								onPointerDown={() => {
									setTargetServerComponentId(serverComponent.componentId);
									setShowRemoveServerComponentConfirmation(true)}
								}
							>
								Remove
							</GlueButton>
						</li>
					))}
				</ul>

				<GlueButton
					onPointerDown={() => {
						setShowServerComponentEditor(true);
						setTargetServerComponentId(undefined);
					}}
				>
					Add server component
				</GlueButton>

				<GlueDialog
					open={showServerComponentEditor}
					onClose={onCloseServerComponentEditor}
				>
					<ServerComponentEditor
						assetId={props.targetId}
						serverComponents={serverComponentsRes.data?.assetServerComponents}
						componentId={targetServerComponentId}
						onClose={onCloseServerComponentEditor}
					/>
				</GlueDialog>

				<GlueDialog
					open={showRemoveServerComponentConfirmation}
					onClose={() => setShowRemoveServerComponentConfirmation(false)}
				>
					<p>Are you sure?</p>
					<GlueButton onPointerDown={() => removeServerComponent()}>Remove</GlueButton>
				</GlueDialog>

				<LinkTeams
					asset={assetInfoRes.data?.assetInfo}
				/>
				<hr />

				<p>Scene name: {assetInfoRes.data?.assetInfo.sceneName}</p>

				<div className={classes.existingVersions}>
					<h2>Existing versions</h2>
					<GlueScroll>
						<ul>
							{assetInfoRes.data?.assetInfo.versions?.map((versionInfo: AssetVersion) => (
								<li key={versionInfo.baseUrl}>
									<div>Version: {versionInfo.version}</div>
									<div>Date: {moment(versionInfo.date).toLocaleString()}</div>
									<div>Uploader: {versionInfo.userId}</div>
									<div>Files: {versionInfo.baseUrl}</div>
								</li>
							))}
						</ul>
					</GlueScroll>
				</div>

				<div>
					<h2>Version pointers</h2>
					<ul>
						{assetInfoRes.data?.assetInfo.versionPointers?.map((pointer: VersionPointer) => (
							<li key={pointer.name}>
								<div>Name: {pointer.name}</div>
								<div>Version: {pointer.version}</div>
							</li>
						))}
					</ul>
				</div>

				<div>
					<h2>Add new Asset version</h2>
					<p>Select and upload a new Space Asset Package. It will be added to this Asset as a new version.</p>
					<UploadSpaceAsset targetAssetId={props.targetId} />
				</div>
				<div>
					<h2>Set version pointer</h2>
					<SetAssetVersionPointer assetId={props.targetId} />
				</div>
			</>)}
		</div>
	);
};

export default ManageAsset;
