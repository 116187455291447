import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import ClockStatusIndicator from './clock/clock-statusindicator';
import queries from '../graphql/queries';

export const SessionTimer = (props) =>
{
	const remainingTimeRes = useQuery(queries.sessionTimeRemaining);

	const displayRemaining = "Timeout in " + String(remainingTimeRes.data.sessionTimeRemaining / (60 * 1000)) + " min";

	return (
		<ClockStatusIndicator
			text={displayRemaining}
		/>
	);
}