import React from 'react';
import { useNavigate } from "react-router-dom";
import { usePromptDialogContext } from '../../util/prompt-dialog-context';
import InfoDialogTemplate from '../common/info-dialog-template';
import TabRow from '../common/tab-row';
import ViewHeader from './view-header';

const SettingsViewHeader = (props) => {

	const navigate = useNavigate();

	const displayTab = { path: '/view/settings/display', name: "Display", audiomessage: "Tools/Settings/Sound/Press", hoveraudiomessage: "Tools/Settings/Sound/HL" };

	let tabInfos =
	[
	{ path: '/view/settings/general', name: "General", audiomessage: "Tools/Settings/General/Press", hoveraudiomessage: "Tools/Settings/General/HL" },
	{ path: '/view/settings/sound', name: "Sound", audiomessage: "Tools/Settings/Sound/Press", hoveraudiomessage: "Tools/Settings/Sound/HL" },
	{ path: '/view/settings/addapps', name: "Apps", audiomessage: "Tools/Settings/Sound/Press", hoveraudiomessage: "Tools/Settings/Sound/HL" },
	// { path: '/view/settings/sharing', name: "Sharing", audiomessage: "Tools/Settings/Sound/Press", hoveraudiomessage: "Tools/Settings/Sound/HL" },
	{ path: '/view/settings/about', name: "About", audiomessage: "Tools/Settings/About/Press", hoveraudiomessage: "Tools/Settings/About/HL" }
	];

	if (!props.vr)
	{
		tabInfos.splice(1, 0, displayTab);
	}

	const { addDialog } = usePromptDialogContext();

	const showSaveDialog = () => {
		addDialog(<InfoDialogTemplate
			header={'Apply changes before exiting?'}
			message={"You have made unsaved changes to your display settings, which haven't been applied yet."}
			callbacks={[
				{ callback: exitWithoutSaving, label: 'Just exit' },
				{ callback: saveAndExit, color: "primary", label: 'Apply and exit' }
			]}
		/>);
	}

	const onBackButtonAction = () =>
	{
		if (props.dirty)
		{
			showSaveDialog();
		}
		else
		{
			navigate('/');
		}
	}

	const saveAndExit = () =>
	{
		props.save();
		navigate('/');
	}

	const exitWithoutSaving = () =>
	{
		props.revert();
		navigate('/');
	}

	return (
		<ViewHeader 
			title={"Settings"} 
			onClose={onBackButtonAction}
			centerContent={<TabRow tabInfos={tabInfos}/>}
		/>
	)
};

export default SettingsViewHeader;
