import React, { useReducer, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";

import GlueButton from "../common/glue-button";
import GlueDropdown from "../common/glue-dropdown";
import WebInputfield from "../../standalone-web/common/web-inputfield";
import { ArrowBack } from "@material-ui/icons";

import queries from "../../graphql/queries";
import mutations from "../../graphql/mutations";

import InfoDialogTemplate from "../common/info-dialog-template";

import { usePromptDialogContext } from "../../util/prompt-dialog-context";
import { licenseTiers, orgMemberRoleNameMap } from "../../defines";
import CreateTeamDialog from "../teams/create-team";
import AddOrgMemberDialog from "../organization/add-org-member";
import MoveTeamDialog from "./move-team-dialog";
import MemberDetailsDialog from "../members/member-details-dialog";
import RemoveMemberDialog from "../organization/remove-member-dialog";

import { OrganizationMember, Team } from "../../graphql/types-generated";

const useStyles = makeStyles((theme) => ({
	root: {
		height: '85%',
		display: 'flex',
		flexDirection: 'column',
		gap: theme.glueSpacing('s')
	},

	infoBar: {
		width: '100%',
		height: '68px',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		gap: theme.glueSpacing('xl'),
	},

	content: {
		display: 'flex',
		flexDirection: 'column',
		gap: theme.glueSpacing('s')
	},

	buttons: {
		display: 'flex',
		flexDirection: 'row',
		gap: theme.glueSpacing('m')
	},

	rootLoading: {
		backgroundColor: 'transparent',
		height: '256px',
		width: '256px',
		margin: 'auto',
		marginTop: '200px'
	},

	domains: {
		display: 'flex',
		flexDirection: 'row',
		gap: theme.glueSpacing('m'),
	},

	overview: {
		display: 'grid',
		gridTemplateColumns: 'repeat(3, 1fr)'
	},

	scrollArea: {
		display: 'flex',
		flex: '1 1 auto',
		flexFlow: 'column wrap',
		padding: '0 5px',
		gap: theme.glueSpacing('m')
	},

	memberList: {
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
		gap: theme.glueSpacing('l')
	}
}));

type state = Array<string>

enum ActionType {
	Init,
	Add,
	Remove
}

type Action = {
	type: ActionType.Init,
	payload: Array<string>
} | {
	type: ActionType.Add | ActionType.Remove,
	payload: string
}

const AdminOrgView = (props: {
	return: () => void
}) =>
{
	const classes = useStyles();

	const { addDialog, closePromptDialog } = usePromptDialogContext();

	const { orgId } = useParams();

	const orgRes = useQuery(queries.getOrg, {
		skip: !orgId,
		variables: {
			orgId: orgId
		},
		fetchPolicy: 'network-only'
	});

	const licenseRes = useQuery(queries.subscriptionOfOrg, {
		skip: !orgId,
		variables: {
			orgId: orgId
		}
	});

	const orgTeamsRes = useQuery(queries.getOrgTeams, {
		skip: !orgId,
		variables: {
			orgId: orgId
		}
	});

	const [removeOrganisaztion] = useMutation(mutations.removeOrg);
	const [updateSubscription] = useMutation(mutations.updateSubscription);
	const [updateOrgMut] = useMutation(mutations.updateOrg);
	const [removeMemberMut] = useMutation(mutations.removeOrganizationMembers);

	const [ doms, domsReducer ] = useReducer(
		(state: state, action: Action) => {
			switch(action.type) {
				case ActionType.Init:
					return state = action.payload;
				case ActionType.Add:
					return [...state, action.payload]
				case ActionType.Remove:
					return state.filter(item => item !== action.payload)
				default:
					return state
			}
		},
		[]
	);

	const [ licenseTier, setLicenseTier ] = useState<number | string>();
	const [ seats, setSeats ] = useState(0);
	const [ orgName, setOrgName ] = useState(-1);
	const [ newDom, setNewDom ] = useState<string>('');
	const [ addMemberDialogOpen, setAddMemberDialogOpen ] = useState(false);
	const [ detailDialogUserId, setDetailDialogUserId ] = useState<string | null>(null);

	const domains = orgRes.data?.getOrg.emailDomains ?? [];

	useEffect(() => {
		
		if (domains.length > 0 && doms.length === 0) {
			domsReducer({ type: ActionType.Init, payload: domains })
		}
			
	}, [doms, domains]);

	const removeMemberRequest = (email: string | null) => {
		if (!email)
			return;

		let hasOwnableDoms = false;

		const userDom = email.split('@');
		if (orgRes.data.getOrg.emailDomains.includes('@' + userDom[1])) {
			hasOwnableDoms = true;
		}
		const message = `You are about to remove ${email} from ${orgRes.data.getOrg.name}. Are you sure?`;
		let removeAccountsMsg = null;
		const header = 'Remove user';
		if (hasOwnableDoms) {
			removeAccountsMsg = <p>This is a <b>{`${orgRes.data.getOrg.emailDomains}`}</b> account. How would you like to deal with it?</p>
		}

		addDialog(<RemoveMemberDialog
			callback={() => removeOrgMember(email)}
			onClose={() => closePromptDialog()}
			header={header}
			removeAccoMsg={removeAccountsMsg}
			message={message}
		/>)
	}

	const removeOrgMember = async (memberEmail: string) =>
	{
		await removeMemberMut({
			variables: {
				orgId: orgId,
				memberEmails: [memberEmail]
			}
		}).then(res => {
			orgRes.refetch();
		}).catch(err => {
			console.error(err.message);
		});
		closePromptDialog();
	}

	const removeCheck = (orgId: string | undefined) =>
	{
		if (!orgId)
			return;
		addDialog(<InfoDialogTemplate 
			header={'Delete?'}
			message={'Are you sure you want to delete this organization?'}
			callbacks={[
				{ label: 'Cancel', callback: () => null },
				{ label: 'Delete', color: 'destructive', callback: () => removeOrgan(orgId)}
			]}
		/>)
	}

	const removeOrgan = async (orgId: string) => {
		await removeOrganisaztion({
			variables: {
				orgId: orgId
			}
		}).catch(err => {
			addDialog(<InfoDialogTemplate 
				isError={true}
				message={'The organization could not be removed. ' + err.message}
			/>)
		});
		addDialog(<InfoDialogTemplate 
			header={'Success'}
			message={'The organization was removed.'}
		/>)
		props.return();
	}

	const updateLice = async () => {
		if (!orgId)
			return;
		
		await updateSubscription({
			variables: {
				orgId: orgId,
				licenseTier: licenseTier ?? licenseRes.data.subscriptionOfOrg.licenseTier,
				numberOfSeats: seats ? seats : licenseRes.data.subscriptionOfOrg.numberOfSeats
			}
		}).catch(err => {
			addDialog(<InfoDialogTemplate
				isError={true}
				message={err.message}
			/>)
		});
	}

	const setOrgDomain = async () => 
	{
		if (!orgId)
			return;

		await updateOrgMut({
			variables: {
				orgId: orgId,
				name: orgName !== -1 ? orgName : orgRes.data.getOrg.name,
				emailDomains: JSON.stringify({
					emailDomains: doms
				})
			}
		}).catch(e => {
			addDialog(<InfoDialogTemplate
				isError={true}
				message={e.message}
			/>)
		});
	}

	const saveSettings = async () =>
	{
		await updateLice();
		await setOrgDomain();
		licenseRes.refetch();
		orgRes.refetch();
	}

	const handleNewDomain = () =>
	{
		if (!newDom.includes('@'))
			return;

		if (doms.includes(newDom))
			return;

		domsReducer({ type: ActionType.Add, payload: newDom });
		setNewDom('')
	}

	return(
		<div className={classes.root}>
			<div className={classes.infoBar}>
				<GlueButton variant="icon" onPointerDown={() => props.return()}>
					<ArrowBack />
				</GlueButton>
				
				<h1>{orgRes.data?.getOrg.name ?? ''}</h1>
				<h1>{orgId}</h1>
			</div>
			
			<div className={classes.overview}>
				<div className={classes.scrollArea}>
					<div className={classes.content}>
						Org name:
						{orgRes.data && 
							<WebInputfield
								placeholder={'Rename org'}
								maxLength={60}
								value={orgName !== -1 ? orgName : orgRes.data.getOrg.name}
								onChange={setOrgName}
							/>}
						License: {licenseRes.data && licenseTiers[licenseRes.data.subscriptionOfOrg.licenseTier].name}
						{licenseRes.data && 
							<GlueDropdown
								items={licenseTiers.concat()}
								value={licenseTier ?? licenseRes.data.subscriptionOfOrg.licenseTier}
								label={'Set license tier'}
								onChange={(id, checked) => { checked && setLicenseTier(id) }}
							/>}
						Number of licenses: {licenseRes.data && licenseRes.data.subscriptionOfOrg.numberOfSeats}
						{licenseRes.data && licenseTier !== 0 &&
							<WebInputfield
								numeric
								pattern={'[0-9]*'}
								value={seats ?? licenseRes.data.subscriptionOfOrg.numberOfSeats}
								onChange={setSeats}
								placeholder={'Set new amount of licenses'}
							/>}
						{doms.length > 0 && 
							<p>Ownership of domains:</p>}
						{doms.length > 0 && doms.map((item, i) => (
							<div className={classes.domains} key={i}>
								<h3>{item}</h3>
								<GlueButton onPointerDown={() => domsReducer({ type: ActionType.Remove, payload: item })}>Remove</GlueButton>
							</div>
						))}
						Add new organization owned domain:
						<WebInputfield
							placeholder={'ex. @glue.work'}
							value={newDom}
							onChange={(value: string) => setNewDom(value)}
						/>
						<GlueButton disabled={newDom === ''} onPointerDown={() => handleNewDomain()}>Add domain</GlueButton>
					</div>
					<div className={classes.buttons}>
						<GlueButton onPointerDown={() => saveSettings()}>Save Changes</GlueButton>
						<GlueButton color="destructive" onPointerDown={() => removeCheck(orgId)}>Delete Org</GlueButton>
					</div>
				</div>
				<div className={classes.scrollArea}>
					TEAMS
					<div>
						<GlueButton onPointerDown={() => addDialog(<CreateTeamDialog orgId={orgId ? orgId : ''} isSysAdmin refetch={() => orgTeamsRes.refetch()} />)}>Add Team</GlueButton>
						{orgTeamsRes.data?.getOrgTeams.map((item: Team, i: number) => (
							<h3 onPointerDown={() => addDialog(
								<MoveTeamDialog teamId={item.teamId} orgId={orgId ? orgId : ''} refetch={() => orgTeamsRes.refetch()}/>
							)} key={i}>{item.name}</h3>
						))}
					</div>
				</div>
				<div className={classes.scrollArea}>
					MEMBERS
					<div>
						<GlueButton onPointerDown={() => setAddMemberDialogOpen(true)}>Add member</GlueButton>
					</div>
					{orgRes.data?.getOrg.members.map((member: OrganizationMember, i: number) => (
						<div className={classes.memberList} key={i}>
							<h3>{member.email}</h3>
							{member.invitationStatus === 'pending' ? 
								<div className={classes.memberList}>
									<h3 style={{ color: 'yellow' }}>pending</h3>
									<GlueButton onPointerDown={() => setDetailDialogUserId(member.email)}>Info</GlueButton>
								</div> : 
								<div className={classes.memberList}>
									<h3>{orgMemberRoleNameMap[member.memberRole]}</h3>
									<GlueButton onPointerDown={() => setDetailDialogUserId(member.email)}>Info</GlueButton>
								</div> 
							}
						</div>
						
					))}
					<AddOrgMemberDialog 
						orgId={orgId ?? ''}
						open={!!orgId && addMemberDialogOpen}
						onClose={() => setAddMemberDialogOpen(false)}
						refetch={() => orgRes.refetch()}
					/>

					<MemberDetailsDialog
						orgId={orgId}
						userId={detailDialogUserId}
						onRemoveMember={() => { removeMemberRequest(detailDialogUserId); setDetailDialogUserId(null) }}
						onClose={() => setDetailDialogUserId(null)}
					/>
				</div>
			</div>
			
		</div>
	)
}

export default AdminOrgView;