import { makeStyles } from "@material-ui/core";
import React, { useMemo, useRef } from "react";

const useStyles = makeStyles((theme) => ({
	root: (props) => ({
		borderRadius: '4px',
		width: theme.custom.imagePreview.width,
		height: theme.custom.imagePreview.height,
		display: 'block',
		margin: 'auto',
		objectFit: 'cover'
	})
}));

/* 
	Preview component for images, this component supports previewing local images or image urls
*/
const ImagePreviewComponent = (props) =>
{
	const classes = useStyles(props);
	const previewRef = useRef();

	const selectNewThumbnailImage = (image) => 
	{
		if (previewRef.current)
		{
			const reader = new FileReader();

			reader.addEventListener("load", () => {
				previewRef.current.src = reader.result;
			});

			if (image)
			{
				reader.readAsDataURL(image);
			}
		}
		else
		{
			console.error("Missing reference for preview window")
		}
	};

	const displayImage = useMemo(() => 
	{
		if (props.image instanceof Blob)
			return selectNewThumbnailImage(props.image);
		else
			return props.image;
	}, [props.image]);

	return (
		<img className={classes.root} ref={previewRef} src={displayImage} alt={''} />
	);
};

export default ImagePreviewComponent;