import React from 'react'
import SiemensEmployeesConsentForm from "../component/welcome/custom-consent/siemens/siemens-employees-consent-form"
import SiemensPrivacyPolicyStatement from "../component/welcome/custom-consent/siemens/siemens-privacy-policy-statement"

export const domainToConsentFormMap = Object.freeze({
	'siemens.com': ['siemensemployees', 'siemensprivacy'],
})

export const consentFormContentsMap = Object.freeze({
	'siemensemployees': {
		title: 'Siemens Terms of Use',
		content: <SiemensEmployeesConsentForm />
	},
	'siemensprivacy': {
		title: 'Siemens Data Privacy Notice',
		content: <SiemensPrivacyPolicyStatement />
	}
})