import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import GlueButton from "../common/glue-button";
import { useApolloClient, useQuery } from "@apollo/react-hooks";
import queries from "../../graphql/queries";
import { usePromptDialogContext } from "../../util/prompt-dialog-context";
import { useUserContext } from "../../util/user-context";
import SpaceTemplateDetailsDialog from './space-template-details-dialog';
import mutations from "../../graphql/mutations";
import ResetSpaceDialog from "./reset-space-dialog";
import ResetSessionJournalDialog from "./reset-session-journal-dialog";
import DeleteSpaceDialog from "./delete-space-dialog";
import { Room, Session, Asset } from '../../graphql/types-generated';
import ForceStopSessionDialog from "./force-stop-session-dialog";
import { createThumbnailUrl } from "../../util/file-backend-api-util";

const useStyles = makeStyles((theme) => ({
	editButtons: {
		display: 'flex',
		gap: theme.glueSpacing('m'),
		flexFlow: 'column',
	},

	buttonContent: {
		display: 'flex',
		flexDirection: 'row',
		gap: theme.glueSpacing('m'),
		justifyContent: 'center',
	},

	spaceInfo: {
		display: 'grid',
		gap: '12px',
		gridTemplateColumns: '1fr 1fr',
		width: 'auto',
	},

	spaceInfoBox: {
		'& p:first-child': {
			opacity: '60%'
		}
	},

	spaceInfoBoxLast: {
		margin: 0,
		textAlign: 'right',

		'& p:first-child': {
			opacity: '60%'
		}
	},
}), { name: 'MuiGlueSpaceSettingsDialog' });

const SpaceSettingsDialog = (props: {
	roomInfo: Room
	session?: Session
	teamId: string
	web?: boolean
	refetch: () => void
}) => {

	const classes = useStyles(props);
	const apollo = useApolloClient();
	const user = useUserContext();

	const { addDialog, closePromptDialog } = usePromptDialogContext();

	const [ errorMsg, setErrorMsg ] = useState<string>('');
	const [ loading, setLoading ] = useState<boolean>(false);
	const [ defaultThumbnail, setDefaultThumbnail ] = useState<boolean>(false);

	const assetInfoRes = useQuery(queries.assetInfo, {
		skip: !props.roomInfo?.assetId,
		variables: { assetId: props.roomInfo?.assetId },
		fetchPolicy: 'network-only'
	});
	const asset: Asset | undefined = assetInfoRes.data?.assetInfo;

	const hasExistingCustomThumbnail = !defaultThumbnail && asset && props.roomInfo.thumbnailUrl !== asset?.thumbnailUrl;

	const editSpace = async (name: string, customThumbnail?: File) =>
	{
		setLoading(true);

		let thumbnailUrl: string | null = null;
		if (customThumbnail) {
			thumbnailUrl = await createThumbnailUrl(apollo, props.teamId, customThumbnail);
		} else if (defaultThumbnail) {
			thumbnailUrl = '';
		}

		await apollo.mutate({
			mutation: mutations.updateSpaceInfo,
			variables: {
				roomId: props.roomInfo.roomId,
				name: name,
				thumbnailUrl: thumbnailUrl
			}
		}).then(res => {
			props.refetch();
			closePromptDialog();
		}).catch(err => {
			setErrorMsg((err as Error).message);
			setLoading(false);
			return;
		});
	}

	return (
		<SpaceTemplateDetailsDialog
			title='Space Settings'
			defaultName={props.roomInfo.name}
			buttonLabel='Save'
			web={props.web}
			canCreate={!!asset}
			onCreate={editSpace}
			onCancel={closePromptDialog}
			errorMsg={errorMsg}
			onClearError={() => setErrorMsg('')}
			loading={loading}
			thumbnailUrl={defaultThumbnail ? asset?.thumbnailUrl : props.roomInfo.thumbnailUrl}
			existingCustomThumbnail={hasExistingCustomThumbnail}
			onUseDefaultThumbnail={() => setDefaultThumbnail(true)}
			onSetCustomThumbnail={() => setDefaultThumbnail(false)}
		>
			<div className={classes.spaceInfo}>
				<div className={classes.spaceInfoBox}>
					<p>Space template</p>
					<p>{asset?.name ?? "-"}</p>
				</div>
				<div className={classes.spaceInfoBoxLast}>
					<p>Session status</p>
					<p>{props.session?.sessionState ?? "-"}</p>
				</div>
			</div>
			<div className={classes.editButtons}>
				<div className={classes.buttonContent}>
					<GlueButton
						width={'100%'}
						disabled={!!props.session && props.session.sessionState !== "Stopped"}
						onPointerDown={() => { closePromptDialog(); addDialog(<ResetSpaceDialog roomId={props.roomInfo.roomId} onSuccess={props.refetch} />) }}
					>
						Reset space
					</GlueButton>
					<GlueButton 
						width={'100%'}
						color="destructive"
						disabled={!!props.session && props.session.sessionState !== "Stopped"}
						onPointerDown={() => { closePromptDialog(); addDialog(<DeleteSpaceDialog roomId={props.roomInfo.roomId} onSuccess={props.refetch} />) }}
					>
						Delete space
					</GlueButton>
				</div>
				{user.administrator && (
					<GlueButton 
						disabled={!props.session || props.session?.sessionState === "Stopped"}
						onPointerDown={() => { closePromptDialog(); addDialog(<ForceStopSessionDialog serverKey={props.roomInfo.serverKey ?? ''} status={props.session?.sessionState} onSuccess={props.refetch} />) }}
					>
						Force Stop Session
					</GlueButton>
				)}
				{user.administrator && (
					<GlueButton
						width={'100%'}
						disabled={!!props.session && props.session.sessionState !== "Stopped"}
						onPointerDown={() => { closePromptDialog(); addDialog(<ResetSessionJournalDialog roomId={props.roomInfo.roomId} onSuccess={props.refetch} />) }}
					>
						Reset space journal
					</GlueButton>
				)}
			</div>
		</SpaceTemplateDetailsDialog>
	);
}

export default SpaceSettingsDialog;
