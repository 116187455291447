// This is not robust at all
export const is3dObject = (fileUrl: string, fileType: string) =>
{
	const str = fileUrl.slice(-4).toLowerCase();
	return (fileType === "application/octet-stream" || fileType === 'application/vnd.ms-pki.stl') &&
		(str === '.fbx' || str === '.glb' || str === '.obj' || str === '.stl' || str === '.ply' || str === '.3mf' || str === 'gltf');
}

export const isImage = (fileType: string) =>
{
	return fileType === "image/jpeg" || fileType === "image/png";
}

export const isImportable = (fileUrl: string, fileType: string) =>
{
	return (is3dObject(fileUrl, fileType) || isImage(fileType));
}
