import React from 'react';
import HeroIcon from './common/hero-icon';

import PointerIcon from '../icons/hero/Laser_Pointer.png';

const LaserPointerHeroIcon = (props) =>
{
	const hoveraudiomessage = "Menu/LaserPointer/HL";
	const audiomessage = "Menu/LaserPointer/Press";

	return (
		<HeroIcon
			name="Laser Pointer"
			icon={PointerIcon}
			route="/app/laser-pointer"
			disabled={props.disabled}
			uiAudioMessage={audiomessage}
			uiHoverAudioMessage={hoveraudiomessage} 
		/>
	);
};

export default LaserPointerHeroIcon;