import React from 'react';
import { useNavigate } from "react-router-dom";
import HeroIcon from '../common/hero-icon';
import { useQuery } from '@apollo/client';
import queries from '../../graphql/queries';

const ProdToolHeroIcon = (props: {
	appname: string,
	appurl: string,
	iconurl: string,
	disabled?: boolean
}) => {
	const navigate = useNavigate();
	const hoveraudiomessage = "Menu/Browser/HL";
	const audiomessage = "Menu/Browser/Press";

	const instanceId = window.sessionStorage.getItem('instance-id');
	const latestUrlRes = useQuery<{ browserLatestUrl: string }>(queries.browserLatestUrl, {
		skip: !instanceId || !props.appname,
		variables: {
			instanceId: instanceId,
			appId: props.appname
		}
	});

	return (<>
		<HeroIcon
			name={props.appname}
			icon={props.iconurl}
			onPointerDown={() => {
				navigate('/app/web-control', { state: { appname: props.appname, appurl: latestUrlRes.data?.browserLatestUrl ?? props.appurl, isProdTool: true } })
			}}
			disabled={props.disabled}
			uiAudioMessage={audiomessage}
			uiHoverAudioMessage={hoveraudiomessage} 
		/>
	</>);
}

export default ProdToolHeroIcon;
