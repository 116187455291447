import React, { useState, useRef, forwardRef } from 'react';
import { makeStyles, useTheme, Tooltip } from '@material-ui/core';

import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

import TextInput from './text-input';
import GlueButton from './glue-button';
import { clickAudio, hoverAudio } from './common-vuplex-messages';
import postVuplexMessage from '../../service/message-vuplex';
import SpeechToTextButton from './speech-to-text-button';
import getAssignerFnForRefs from '../../util/assigner-for-refs';
import { useApolloClient, useQuery } from '@apollo/client';
import queries from '../../graphql/queries';
import { SetCurrentSpeechRecognitionSinkId } from '../../service/speech-to-text';

const calculateButtonSize = (theme, props) => {
	return props.height ? props.height : '64px';
};

const setOutlineFocused = (theme, props) => {
	if (props.warning === true)
		return theme.palette.warning.main;
	if (props.error === true)
		return theme.palette.error.main;
	if (props.focusedOutlineColor)
		return props.focusedOutlineColor;
	else if (props.speechInput === true)
		return theme.palette.speech.main;
	else
		return theme.palette.primary.active;
};

const setOutline = (theme, props) => {
	if (props.outlineColor)
		return props.outlineColor;
	else if (props.speechInput === true)
		return theme.palette.speech.main;
	else if (props.noOutline)
		return 'transparent' 
	else
		return '#808080';
};

const setBackgroundFocused = (theme, props) => {
	if(props.customFocusBackground)
	{
		return props.customFocusBackground;
	}
	if (props.error === true)
		return theme.palette.background.error;
	else return theme.palette.background.default;
};

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'contents',
	},

	searchfield: {
		height: ({ props }) => props.height ?? (props.multiline ? 'auto' : null) ?? '64px',
		width: ({ props }) => props.width ?? '400px',
		margin: ({ props }) => props.margin ?? null,
		padding: ({ props }) => props.search ? 0 : '0 12px 0 12px',
		backgroundColor: ({ props }) => props.backgroundColor ?? '#000',
		
		borderRadius: '12px',//'99999999px',
		borderStyle: 'solid',
		borderColor: ({ props }) => setOutline(theme, props),

		display: 'flex',
		flexFlow: 'row nowrap',
		alignItems: 'center',
		position: 'relative'
	},

	searchButton: {
		flex: '0 0 auto',
		width: ({ props }) => calculateButtonSize(theme, props),
		height: ({ props }) => calculateButtonSize(theme, props),

		padding: 0,
		background: 'none'
	},

	clearButton: {
		flex: '0 0 auto',
		width: ({ props }) => calculateButtonSize(theme, props),
		height: ({ props }) => calculateButtonSize(theme, props),
		color: '#808080',
		background: 'transparent',

		'&:hover': {
			color: ({ props }) => props.color ?? theme.palette.primary.contrastText,
			background: 'transparent'
		}
	},

	inputRoot: {
		flex: '1 1 auto'
	},

	input: ({ props }) => ({
		textAlign: props.centerText ? 'center' : 'left',
		color: props.color ?? props.error ? theme.palette.error.mainText : theme.palette.primary.contrastText,
		fontSize: props.fontSize ?? '24px',
		fontWeight: props.fontWeight ?? null,
		fontStyle: props.fontStyle ?? null
	}),
}), { name: "MuiGlueInputField" });

/**
 * Passing in "search" as a prop enables the search field parameters
 * where a button with search icon and a clear field button appears
*/
const GlueInputfield = (props, ref) => {
	const theme = useTheme();
	const classes = useStyles({ props });

	const apollo = useApolloClient();
	const instanceId = window.sessionStorage.getItem('instance-id') ?? '';

	const inputRef = useRef();
	const [hasFocus, setHasFocus] = useState(false);
	const { error, warning } = props;

	const { data } = useQuery(queries.mouselookEnabled);

	const setFocus = (value) => 
	{
		setHasFocus(value);
		
		if(value && data.mouselookEnabled)
			postVuplexMessage('Toggle mouselook', null);

		postVuplexMessage('Enable hotkeys', { value: !value });

		if (props.setFocus)
		{
			props.setFocus(value);
		}

		if (value)
			SetCurrentSpeechRecognitionSinkId(apollo, instanceId, props.sinkId);
	};

	const delay = ms => new Promise(
		resolve => setTimeout(resolve, ms)
	);

	const setInputFocus = async () =>
	{
		SetCurrentSpeechRecognitionSinkId(apollo, instanceId, props.sinkId);
		await delay(10);
		inputRef.current.focus();
	};

	const hoveraudiomessage = "Tools/InputField/HL";
	const searchaudiomessage = "Tools/InputField/Search/Press";
	const searchhoveraudiomessage = "Tools/InputField/Search/HL";
	const clearaudiomessage = "Tools/InputField/Clear/Press";
	const clearhoveraudiomessage = "Tools/InputField/Clear/HL";

	const onClickClose = () => 
	{
		if (props.onClose)
			props.onClose();

		clickAudio(searchaudiomessage);
	};

	const onClickClear = () => 
	{
		props.onClear();
		SetCurrentSpeechRecognitionSinkId(apollo, instanceId, props.sinkId);
		clickAudio(clearaudiomessage);
	};

	const handleSubmitKey = (value) => 
	{
		if (props.onSubmit)
		{
			props.onSubmit(value)
		}
		inputRef.current?.blur();
		SetCurrentSpeechRecognitionSinkId(apollo, instanceId, props.sinkId);
	}

	const handleSpeechToText = (speech) => {
		if (speech && props.onSpeechChange)
		{
			let str = speech.substring(0, speech.length - 2)
			props.onSpeechChange((props.value === undefined ? '' : props.value) + str.replace(",", ""))
		}
	}

	return (
		<div className={classes.root}>
			<Tooltip
				open={!!props.errorMessage}
				arrow
				title={props.errorMessage}
				enterDelay={300}
				disableHoverListener
				disableFocusListener
			>
				<div
					className={classes.searchfield}
					style={{
						borderColor: (warning || error || hasFocus) && setOutlineFocused(theme, props),
						background: (warning || error || hasFocus) && setBackgroundFocused(theme, props),
					}}
					onPointerEnter={() => { hoverAudio(hoveraudiomessage) }}
				>
					{props.search && (
						<GlueButton
							variant='icon'
							classes={{ root: classes.searchButton }}
							onPointerDown={onClickClose}
							onPointerEnter={() => { hoverAudio(searchhoveraudiomessage) }}
							onClick={setInputFocus}
						>
							<SearchRoundedIcon />
						</GlueButton>
					)}

					<TextInput
						onBlur={props.onBlur}
						numbers={props.numbers}
						style={props.style}
						disabled={props.disabled}
						autoFocus={props.autoFocus}
						autoCursor={props.autoCursor}
						rootStyle={classes.inputRoot}
						inputStyle={classes.input}
						onChange={props.onInputChange ? props.onInputChange : props.onChange}
						value={props.value}
						ref={getAssignerFnForRefs([inputRef, ref])}
						onSubmitKey={() => handleSubmitKey(props.value)}
						focusInput={setFocus}
						placeholder={props.placeholder}
						sinkId={props.sinkId}
						maxLength={props.maxLength}
						multiline={props.multiline}
						rows={props.rows}
						delayInSeconds={props?.delayInSeconds ?? 0}
					/>

					{props.showClearButton && (
						<GlueButton
							variant='icon'
							classes={{ root: classes.clearButton }}
							onPointerDown={onClickClear}
							onPointerEnter={() => { hoverAudio(clearhoveraudiomessage) }}
							onClick={setInputFocus}
						>
							<CloseOutlinedIcon />
						</GlueButton>
					)}
				</div>
			</Tooltip>
			{!props.simpleInput && (
				<SpeechToTextButton
					onSpeechChange={(speech) => { handleSpeechToText(speech); }}
					sinkId={props.sinkId}
					setFocus={setInputFocus}
					invertedSpeechButton={props.invertedSpeechButton}
				/>
			)}
		</div>
	);
};

export default forwardRef(GlueInputfield);
