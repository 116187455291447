import React from 'react';
import { makeStyles, ButtonBase } from '@material-ui/core';

type GlueTabProps = {
	key?: number,
	label?: string,
	toggled?: boolean,
	onPointerDown?: (e: React.PointerEvent) => void,
	onClick?: () => void,
	uiAudioMessage?: string,
	uiHoverAudioMessage?: string
	textTransform?: string,
	letterSpacing?: string | number
}

const useStyles = makeStyles((theme) => ({
	tab: {
		height: theme.custom.glueTab.height,
		paddingLeft: theme.glueSpacing('xs'),
		paddingRight: theme.glueSpacing('xs'),
		whiteSpace: 'nowrap',
		display: 'inline-block',
		
		'&:hover': {
			filter: 'brightness(107%)'
		},

		'&:active': {
			filter: 'brightness(120%)'
		},

		'&:disabled': {
			opacity: '50%'
		}
	},

	text: (toggled) => ({
		position: 'relative',
		'& > h3': {
			color: 'white',
			opacity: toggled ? '1' : '0.6',
		},
	}),

	underline: {
		height: theme.custom.glueTab.underlineThickness, 
		width: '100%', 
		backgroundColor: theme.palette.secondary.textActive,
		position: 'absolute',
		left: '0'
	}
}), { name: 'MuiGlueTab' });


const GlueTab = (props: GlueTabProps) => {
	const classes = useStyles({toggled: props.toggled});

	return (
		<ButtonBase className={classes.tab} onPointerDown={props.onPointerDown}>
			<div className={classes.text}>
				<h3>{props.label}</h3>
				{props.toggled && <div className={classes.underline}/>}
			</div>
		</ButtonBase>
	);
}

export default GlueTab;