import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";

import GlueButton from '../common/glue-button';
import GlueCard from '../common/glue-card';
import PageSelector from '../common/page-selector';

import GuideDetails from '../help/guide-details';

import WhiteboardIcon from '../../icons/hero/Whiteboard.png';
import NoteIcon from '../../icons/hero/Note.png';
import Draw3DIcon from '../../icons/hero/3D_Draw.png';
import PointerIcon from '../../icons/hero/Laser_Pointer.png';
import BrowserIcon from '../../icons/hero/Browser.png';
import DesktopViewIcon from '../../icons/hero/DesktopView.png';
import CameraIcon from '../../icons/hero/Camera.png';
import ClockIcon from '../../icons/hero/Clock.png';
import LocationsIcon from '../../icons/hero/Locations.png';

import Accessibility from '../../images/help/Accessibility.jpg'
import Avatars from '../../images/help/Avatars.jpg'
import BasicControls from '../../images/help/BasicControls.jpg'
import Interacting from '../../images/help/Interacting.jpg'
import Privacy from '../../images/help/Privacy.jpg'
import Teams from '../../images/help/Teams.jpg'
import Productivity from '../../images/help/ProductivityTools.jpg'

import ArrowBackIosSharp from '@material-ui/icons/ArrowBackIosSharp';
import ViewHeader from './view-header';
import TabRow from '../common/tab-row';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexFlow: 'column nowrap',
		flex: '1 0 auto',
	},

	footer: {
		display: 'flex',
		background: theme.palette.background.paper,
		justifyContent: 'center',
		alignItems: 'center',
		height: '96px',
	},

	body: {
		display: 'grid',
		gridTemplateColumns: '296px 296px 296px 296px',
		gap: theme.glueSpacing('m'),
		width: '100%',
		justifyContent: 'center',
		alignContent: 'center'
	},

	toolbar: {
		minHeight: '88px',
		display: 'flex',
		justifyContent: 'flex-end',
		padding: '12px 48px 12px',
	},

	imageContainer: {
		display: 'flex',
		flexFlow: 'row',
		alignItems: 'center',
		justifyContent: 'center'
	},

	contentImg: {
		height: '512px',
	},

	contentText: {
		margin: 'auto',
		height: '100px',
		fontSize: '28px',
		width: '70%',
		textAlign: 'center',
		color: 'black',
		whiteSpace: 'pre-line',
	},

	tabs: (props) => ({
		minHeight: props.web ? '48px' : '88px',
		display: 'flex',
		flexFlow: 'row nowrap',
		alignItems: 'center',
		padding: '8px',
		gap: '24px'
	}),
}), { name: 'MuiGlueHelpView' });

const HelpView = (props) =>
{
	const classes = useStyles();
	const navigate = useNavigate();

	const [guideOpen, setGuideOpen] = useState(false);
	const [guideDetail, setGuideDetail] = useState("Detail");

	const openDetail = (detail) => {
		setGuideDetail(detail);
		setGuideOpen(true);
	}

	const title = "Help";

	const tabInfos = [
		{ path: '/view/help/help-basics', name: "Essentials", audiomessage: "Apps/Avatar/Overview/Press", hoveraudiomessage: "Apps/Avatar/Overview/HL" },
		{ path: '/view/help/app-guides', name: "App guides", audiomessage: "Apps/Avatar/Styles/Press", hoveraudiomessage: "Apps/Avatar/Styles/HL" },
		{ path: '/view/help/tutorials', name: "Video Library", audiomessage: "Apps/Avatar/Colors/Press", hoveraudiomessage: "Apps/Avatar/Colors/HL" }
	];

	const basics = [
		{title: 'Basic controls and movement', image: BasicControls},
		{title: 'User interface', image: Teams},
		{title: 'Interacting with the virtual world', image: Interacting},
		{title: 'Avatars', image: Avatars},
		{title: 'Accessibility', image: Accessibility},
		{title: 'Privacy', image: Privacy}
	];

	const appGuides = [
		{title: 'Notes', icon: NoteIcon},
		{title: 'Whiteboard', icon: WhiteboardIcon},
		{title: '3D Drawing', icon: Draw3DIcon},
		{title: 'Laser Pointer', icon: PointerIcon},
		{title: 'Browser', icon: BrowserIcon},
		{title: 'Desktop View', icon: DesktopViewIcon},
		{title: 'Camera', icon: CameraIcon},
		{title: 'Locations', icon: LocationsIcon},
		{title: 'Clock', icon: ClockIcon},
		{title: 'Productivity tool integrations', image: Productivity}
	];

	let guidesToShow = [];

	const guideHeader = 
	(
		<div className={classes.tabs}>
			<GlueButton variant='icon' color={'stealth'} onPointerDown={() => setGuideOpen(false)}>
				<ArrowBackIosSharp/>
			</GlueButton>
			<h3>{guideDetail}</h3>
		</div>
	);

	const [page, setPage] = useState(0);
	let pageCount;
	const tilesPerPageCount = 8;

	const startIndex = page * tilesPerPageCount;
	const endIndex = startIndex + tilesPerPageCount;
	let tilesTotalCount = 0;

	const basicsContent = () => {
		guidesToShow = basics;

		tilesTotalCount = guidesToShow.length;
		pageCount = Math.ceil(tilesTotalCount / tilesPerPageCount);
		guidesToShow = guidesToShow.slice(startIndex, endIndex);

		return guideOpen ? 
			(<GuideDetails blob={'essentials/'} currentDetail={guideDetail}></GuideDetails>
			) : (
				<>
					<div className={classes.toolbar}>
						<PageSelector
							current={page}
							pageCount={pageCount}
							onPageChange={setPage}>
						</PageSelector>
					</div>
					<div className={classes.body}>
						{guidesToShow.map((app, i) => (
							<GlueCard
								key={i}
								title={app.title}
								image={app.image}
								onPointerDown={() => openDetail(app.title)}
							/>
							))
						}
					</div>
				</>
			);
	}

	const appGuidesContent = () => {
		guidesToShow = appGuides;

		tilesTotalCount = guidesToShow.length;
		pageCount = Math.ceil(tilesTotalCount / tilesPerPageCount);
		guidesToShow = guidesToShow.slice(startIndex, endIndex);

		return guideOpen ? 
			(<GuideDetails blob={'appguides/'} currentDetail={guideDetail}></GuideDetails>
			) : (
				<>
					<div className={classes.toolbar}>
						<PageSelector
							current={page}
							pageCount={pageCount}
							onPageChange={setPage}>
						</PageSelector>
					</div>
					<div className={classes.body}>
						{guidesToShow.map((app, i) => (
							<GlueCard
								showIcon={app.icon}
								key={i}
								title={app.title}
								image={app.icon ? app.icon : app.image}
								onPointerDown={() => openDetail(app.title)}
							/>
							))
						}
					</div>
				</>
			);
	}

	const tutorialsContent = () => {
		return <GuideDetails useGrid currentDetail={'tutorials'}></GuideDetails>
	}

	const faqContent = () => {
		return <GuideDetails currentDetail={'faq'}></GuideDetails>
	}

	const onTabClick = () => 
	{
		setGuideOpen(false);
		setPage(0);
	}

	return (
		<div className={classes.root}>
			<ViewHeader 
				title={title} 
				onClose={() => navigate('/')}
				centerContent={<TabRow onClickTab={onTabClick} tabInfos={tabInfos}/>}
			/>
			{guideOpen && guideHeader}

			<Routes>
				<Route path={'/'} element={<Navigate to={'help-basics'}/>} />
				<Route path={'help-basics'} element={<>{basicsContent()}</>} />
				<Route path={'app-guides'} element={<>{appGuidesContent()}</>} />
				<Route path={'tutorials'} element={<>{tutorialsContent()}</>} />
				<Route path={'faq'} element={<>{faqContent()}</>} />
			</Routes>
		</div>
	);
};

export default HelpView;