import React, { useState } from 'react';
import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import { makeStyles, InputLabel } from '@material-ui/core';

import mutations from '../../graphql/mutations';
import queries from '../../graphql/queries';
import GlueButton from '../common/glue-button';
import SVGIconWrapper from '../common/svg-icon-wrapper';
import GlueDropdown from '../common/glue-dropdown';
import WebInputfield from '../../standalone-web/common/web-inputfield';
import SpaceThumbnail_Placeholder from '../../images/SpaceThumbnail_Placeholder.png';

import AssetSelector from './asset-selector';
import { setTrackVersionPointer as setSpaceTrackVersionPointer } from '../../component/space-asset/space-asset-common';
import { usePromptDialogContext } from '../../util/prompt-dialog-context';

import {ReactComponent as ClearIcon} from '../../icons/Close.svg';
import InfoDialogTemplate from '../common/info-dialog-template';
import TeamSearch from '../team-search';

const useStyles = makeStyles((theme) => ({
	root: {

	},

	error: {
		background: 'red',
		border: '4px solid red'
	},

	dropdowns: {
		display: 'flex',
		gap: '64px',

		'& > div': {
			width: '100%'
		}
	},

	teamAndSceneInfo: {
		display: 'flex',
		flexFlow: 'column',
		margin: '12px 0 0 24px'
	},

	teamSlab: {
		background: theme.palette.background.paper,
		borderRadius: '16px',
		padding: '16px',
		marginBottom: '12px',
		display: 'grid',
		width: '80%',
		gridTemplateColumns: '1fr min-content',
		alignItems: 'center'
	},

	image: {
		borderRadius: '25px',
		width: '300px',
		height: '175px',
		display: 'block',
		margin: '0 36px 24px 0',
		position: 'relative',
		backgroundSize: 'cover',
		background: theme.palette.secondary.main,

		'& > button': {
			padding: '2px',
			width: '36px',
			height: '36px',
			position: 'absolute',
			right: '12px',
			top: '12px',
			background: theme.palette.secondary.main
		}
	},

	assetDetailArea: {
		width: '100%'
	},

	assetDetails: {
		display: 'flex',
		marginTop: '24px'
	},

	spaceCreation: {
		marginBottom: '48px'
	}
}));

const MassCreateSpaces = () =>
{
	const classes = useStyles();
	const apollo = useApolloClient();

	const [pointerName, setPointerName] = useState('');
	const { addDialog } = usePromptDialogContext();

	const [selectedTeams, setSelectedTeams] = useState([]);
	const [selectedAssetId, setSelectedAssetId] = useState('');
	const [selectedSpace, setSelectedSpace] = useState('');

	const [sceneThumbnail, setSceneThumbnail] = useState(SpaceThumbnail_Placeholder);
	const [newSpaceName, setNewSpaceName] = useState('');

	const creationAllowed = selectedTeams.length > 0 && selectedAssetId && newSpaceName;

	const assetRes = useQuery(queries.assetInfo, {
		variables: { assetId: selectedSpace?.assetId },
		skip: !selectedSpace,
		fetchPolicy: 'network-only'
	});

	const versionPointerOptions = [{ id: '', name: 'None' }];
	if (Array.isArray(assetRes.data?.assetInfo.versionPointers))
	{
		versionPointerOptions.push(
			...(assetRes.data?.assetInfo.versionPointers.map(
				(item) => ({ id: item.name, name: item.name + " -> " + item.version })
			) ?? [])
		);
	}

	const [crateSpaceMut] = useMutation(mutations.createRoom);

	const teamsRes = useQuery(queries.myTeams, {
		variables: {
			// TODO Change the query role based on the real user role.
			userRole: 'administrator'
		}
	});

	const stageTeam = (team) =>
	{
		if (!selectedTeams.some(t => t.teamId === team.teamId))
			setSelectedTeams([team, ...selectedTeams]);
	};

	const unstageTeam = (team) =>
	{
		setSelectedTeams(selectedTeams.filter(t => t !== team));
	}

	const assetsResult = useQuery(queries.myAssets, {
		variables: {
			typeFilter: 'space'
		}
	});
	const availableAssets = [];
	if (assetsResult.data)
	{
		assetsResult.data.myAssets.forEach(item => {
			availableAssets.push(item);
		});
	}

	const onAssetSelect = (event) =>
	{
		setSelectedAssetId(event)
		const space = availableAssets.find((item) => item.assetId === event);

		if (space)
		{
			setSelectedSpace(space);
			setSceneThumbnail(space.thumbnailUrl);
		}
		else
		{
			setSelectedSpace('');
			setSceneThumbnail(SpaceThumbnail_Placeholder);
		}
	}
	

	const createSpaces = async () =>
	{
		if (!((selectedSpace !== '') && newSpaceName))
		{
			addDialog(<InfoDialogTemplate
				header={'Error!'}
				message={''}
				variable={newSpaceName}
			/>);
			return;
		}

		const selectedAsset = availableAssets.find((item) => item.assetId === selectedSpace.assetId);

		if (!selectedAsset || !assetRes.data)
		{
			addDialog(<InfoDialogTemplate
				header={'Error!'}
				message={'Unable to create spaces'}
				variable={newSpaceName}
			/>);
			return;
		}

		if (pointerName === '')
		{
			addDialog(<InfoDialogTemplate
				header={'Error!'}
				message={'Please select a version pointer'}
				variable={newSpaceName}
			/>);
			return;
		}

		for (const team of selectedTeams)
		{
			const createRoomRes = await crateSpaceMut({
				variables: {
					name: newSpaceName,
					teamId: team.teamId,
					assetName: selectedAsset.name,
					assetId: selectedAsset.assetId,
					sceneUrl: undefined, //baseUrl,
					sceneName: selectedAsset.sceneName
				},
				refetchQueries: [{
					query: queries.myRooms,
					variables: {
						teamId: team.teamId,
						first: 0
					},
					fetchPolicy: 'network-only'
				}]
			});

			const roomId = createRoomRes.data.createRoom.roomId;

			await setSpaceTrackVersionPointer(apollo, roomId, pointerName);
		}

		addDialog(<InfoDialogTemplate
			header={'Error!'}
			message={'New spaces were created with name'}
			variable={newSpaceName}
		/>);
	};

	const stagedTeams = selectedTeams.map((team) => (
		<div className={classes.teamSlab} key={team.teamId}>
			<div>{teamsRes.data.myTeams.find((item) => item.teamId === team.teamId)?.name} - {team.teamId}</div>
			<GlueButton variant='icon' onPointerDown={() => unstageTeam(team)}>
			<SVGIconWrapper><ClearIcon /></SVGIconWrapper>
			</GlueButton>
		</div>
	));

	return (
		<div className={classes.root}>
			<div className={classes.dropdowns}>
				<div>
					<TeamSearch onChange={(team) => stageTeam(team)} selected={selectedTeams} />

					{selectedTeams.length > 0 && (
						<div className={classes.teamLinking}>
							<div className={classes.teamAndSceneInfo}>
								<p>Selected teams:</p>
								{stagedTeams}
							</div>
						</div>
					)}
				</div>

				<div className={classes.assetDetailArea}>
					<AssetSelector selectedId={selectedAssetId} onSelect={(value) => onAssetSelect(value)} />
					{selectedAssetId &&
						<div className={classes.assetDetails}>
							<div>
								<img className={classes.image} src={sceneThumbnail} alt={""}></img>
								<p>Space asset name: {selectedSpace.name}</p>
								<p>Space asset ID: {selectedAssetId}</p>
							</div>
							<div>
								<label>
									Track asset version pointer:
									<GlueDropdown
										defaultValue={pointerName}
										items={versionPointerOptions}
										onChange={(id, checked) => checked && setPointerName(id)}
									/>
								</label>
							</div>
						</div>
					}
				</div>
			</div>
			
			<div className={classes.spaceCreation}>
				<InputLabel>Space Name</InputLabel>
				<WebInputfield
					maxLength={200}
					placeholder={'Enter a name for the new space'}
					onChange={(value) => setNewSpaceName(value)}
					value={newSpaceName}
				/>
			</div>
			<GlueButton
				color="primary"
				onPointerDown={createSpaces}
				disabled={!creationAllowed}
			>
				Create
			</GlueButton>
		</div>
	);
};

export default MassCreateSpaces;
