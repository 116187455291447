import moment from 'moment';
import queries from "../graphql/queries";
import { ApolloClient } from "@apollo/client";
import { EmailValidationResult } from "../graphql/types-generated";

export const logOut = () =>
{
	document.dispatchEvent(new CustomEvent("logOut"));
};

export const glueDateDisplayFormat = (date?: string | null) => moment(date).format('YYYY-MM-DD HH:mm');

export const validateEmail = async (apollo: ApolloClient<unknown>, email: string, source: string | null) => {
	const validationRes = await apollo.query<{ emailAddressValidity: EmailValidationResult }>({
		query: queries.emailAddressValidity,
		variables: {
			email: email,
			source: source
		}
	}).catch(error => {
		console.error(`Email validation error ${error}`);
	});

	if (!validationRes) {
		console.warn('Using fallback email validation which might give incorrect results');
		return {
			email,
			verdict: /.+@.+/.test(email) ? 'Valid' : 'Invalid'
		};
	}
	else return validationRes.data.emailAddressValidity;
}