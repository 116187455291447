import React, { useEffect, useCallback } from 'react';

import { makeStyles } from '@material-ui/core';
import AppHeader from '../common/app-header';
import TransformUI from './transform-ui';
import CloseButton from '../common/close-button';
import { useNavigate } from 'react-router-dom';
import postVuplexMessage from '../../service/message-vuplex';
import { useQuery } from '@apollo/react-hooks';
import queries from '../../graphql/queries';

const useStyles = makeStyles((theme) => ({
	base: {
		flex: '1 1 flex',

		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	}
}));

const TransformUITablet = (props) =>
{
	const navigate = useNavigate();	
	const classes = useStyles();

	const clientPlatformResult = useQuery(queries.clientPlatform);
	const clientPlatform = clientPlatformResult.data?.clientPlatform;
	const vrModeEnabled = clientPlatform?.PlatformType === "VR";

	const closeAppAndTablet = () =>
	{
		postVuplexMessage("Close tablet");
		closeApp();
	};

	const closeApp = useCallback(() =>
	{
		postVuplexMessage('Close Object transform edit');
		navigate('/');
	}, [history]);

	// Close app if not in VR mode
	useEffect(() => {
		if (!!!vrModeEnabled)
		{
			closeApp();
		}
	}, [vrModeEnabled, closeApp]);

	return(
		<div>
			<AppHeader 
				title={'Object transform'}
				secondChildren={<CloseButton onClose={closeAppAndTablet}/>}
				onBackButtonAction={() => closeApp()}
			></AppHeader>
			<div className={classes.root}>
				<TransformUI/>
			</div>
		</div>
	);
}

export default TransformUITablet;