import React from 'react';
import { makeStyles, Typography } from "@material-ui/core";
import { Palette, PaletteColor } from '@material-ui/core/styles/createPalette';

type TagProps = {
	color: keyof Palette
	label: string
}

const useStyles = makeStyles((theme) => ({
	root: (props: TagProps) => ({
		...theme.custom.tag.root,
		background: (theme.palette[props.color] as PaletteColor).main,
		width: 'fit-content',
		display: 'flex',
		alignItems: 'center',
		borderRadius: '4px'
	}),

	label: (props: TagProps) => ({
		...theme.custom.tag.label,
		color: (theme.palette[props.color] as PaletteColor).contrastText,
	})
}), { name: 'MuiGlueTag' });

const GlueTag = (props: TagProps) =>
{
	const classes = useStyles(props);

	return (
		<div className={classes.root}>
			<Typography className={classes.label} variant='overline'>
				{props.label}
			</Typography>
		</div>
	)
}

export default GlueTag;
