import React from 'react';

import TabHeader from '../common/tab-header';

export const clockTabInfos = [
	{ name: "Clock", path: "now", audiomessage: "Apps/Clock/Clock/Press", hoveraudiomessage: "Apps/Clock/Clock/HL" },
	{ name: "Stopwatch", path: "stopwatch", audiomessage: "Apps/Clock/StopWatch/Press", hoveraudiomessage: "Apps/Clock/StopWatch/HL" },
	{ name: "Timer", path: "timer", audiomessage: "Apps/Clock/Timer/Press", hoveraudiomessage: "Apps/Clock/Timer/HL" }
];

const ClockTabsHeader = (props) =>
{
	return (
		<TabHeader tabInfos={clockTabInfos} />
	);
};

export default ClockTabsHeader;
