import React from 'react';
import { Switch, Theme, makeStyles } from '@material-ui/core';
import { clickAudio, hoverAudio } from './common-vuplex-messages';

type SwitchSelectorProps = {
	checked: boolean
	onChange: (value: boolean) => void
	text?: string
	offText?: string
	onText?: string
	disabled?: boolean
	toggle?: boolean
	uiAudioMessage?: string
	uiHoverAudioMessage?: string
}

const useStyles = makeStyles<Theme, SwitchSelectorProps>((theme) => ({
	root: {
		display: ({ onText }) => onText ? 'grid' : 'flex',
		gridTemplateColumns: '1fr 64px 1fr', 
		alignItems: 'center',
		gap: theme.glueSpacing('m'),
		width: 'fit-content',

		'& p:first-child': {
			opacity: props => props.checked && props.offText ? '50%': '100%',
			textAlign: 'right',
		},

		'& p:last-child': {
			opacity: props => props.checked ? '100%' : '50%',
		},
	},
	
	switchRoot: {
		width: '64px',
		height: '32px',
		padding: 0,
		display: 'flex',
		'& .Mui-checked': {
			transform: 'translateX(32px)',
			color: theme.palette.switch.textActive,
			'& + $track': {
				opacity: 1,
				backgroundColor: theme.palette.switch.active,
			},
		},
	},

	switchBase: {
		padding: '4px',
		color: props => props.toggle ? theme.palette.switch.textActive : theme.palette.switch.contrastText,
		width: '32px',
		height: '32px'
	},

	thumb: {
		width: '24px',
		height: '24px',
		boxShadow: 'none',
	},

	track:  (props) => ({
		borderRadius: '16px',
		opacity: '1.0',
		backgroundColor: props.toggle ? theme.palette.switch.active : theme.palette.switch.main,
	}), 
}), { name: "MuiGlueSwitchSelector" });

// A SwitchSelector can either have an 'offText' on the left and an 'onText' on the right,
// OR just 'text' on the left that is always active
// OR no text at all if no text props are set
const SwitchSelector = (props: SwitchSelectorProps) =>
{
	const classes = useStyles(props);
	const audiomessage = !!props.uiAudioMessage ? props.uiAudioMessage : "Menu/Generic/Button/Press";
	const hoveraudiomessage = !!props.uiHoverAudioMessage ? props.uiHoverAudioMessage : "Menu/Generic/Button/HL";

	return (
			<div className={classes.root}>
				<p>
					{props.offText ?? props.text}
				</p>
				<Switch classes={{ track: classes.track, thumb: classes.thumb, switchBase: classes.switchBase, root: classes.switchRoot, }}
					onChange={() => null}
					onPointerDown={() => {clickAudio(audiomessage); props.onChange(!props.checked);} }
					onPointerEnter={() => {hoverAudio(hoveraudiomessage)}} 
					checked={props.checked}
					disabled={props.disabled}
				/>
				{(!props.text && props.onText) && (
					<p>
						{props.onText}
					</p>
				)}
			</div>
	);
};

export default SwitchSelector;
