import React, { useState } from 'react';
import GlueEditor from '../common/glue-editor'
import NotePreview from './note-preview';
import TextMenu from './text-menu'

const NoteEditor = (props: {
	sinkId: string
	onSaveColors: (val: string[]) => void
}) =>
{
	const [ drawerOpen, setDrawerOpen ] = useState(false);
	const [ colorPickerOpen, setColorPickerOpen ] = useState(false);

	return (
		<GlueEditor
			open={drawerOpen}
			showDrawerControls
			onToggleOpen={() => setDrawerOpen(!drawerOpen)}
			previewContent={
				<NotePreview 
					drawerOpen={drawerOpen} 
					sinkId={props.sinkId} 
					colorPickerOpen={colorPickerOpen}
				/>
			}>
				<TextMenu
					onSaveColors={props.onSaveColors}
					toggleColorPickerOpen={() => setColorPickerOpen(!colorPickerOpen)}
				/>
		</GlueEditor>
	);
}

export default NoteEditor;