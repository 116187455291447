import { useQuery, useMutation, ApolloClient } from '@apollo/react-hooks';
import queries from '../graphql/queries';
import mutations from '../graphql/mutations';
import postVuplexMessage from './message-vuplex';
import { Avatar } from '../component/avatarconfigurator/avatar-types';
import { pickRandomDefaultAvatar } from './avatar-configurator';
import { dspValues, DspValues } from '../util/dsp-utils';
import { encodingValues, EncodingValues } from '../util/sharing-utils';

type Settings = {
	nickname: string,
	avatar: Avatar,
	dspValues: DspValues,
	encodingValues: EncodingValues,
	externalApps: string[][],
	firstLogin: boolean,
	forceTunneling: boolean,
	personalSpaceProtection: boolean,
	recentTeams: string[], // Array of teamId:s
	savedNoteColors: string[], // Array of hex values
	savedPenColors: string[], // Same
	sttCurrentLanguage: string,
	sttLanguageList: string[],
	tutorialStarted: boolean,
	voiceCommunicationOnly: boolean,
	vrGrabbingMode: boolean,
	handUiOpacity: number
}

const defaultSettings: Settings = {
	nickname: "",
	avatar: pickRandomDefaultAvatar(),
	dspValues: dspValues,
	encodingValues: encodingValues,
	externalApps: [[],[]],
	firstLogin: false,
	forceTunneling: false,
	personalSpaceProtection: false,
	recentTeams: [],
	savedNoteColors: [],
	savedPenColors: [],
	sttCurrentLanguage: 'en-US',
	sttLanguageList: ['en-US', 'fi-FI', 'hu-HU', 'pl-PL', 'sv-SE'],
	tutorialStarted: false,
	voiceCommunicationOnly: false,
	vrGrabbingMode: false,
	handUiOpacity: 0.3
}

const parseSettings = (blob: string) : Settings =>
{
	let parsedBlob : Partial<Settings> = {};
	if (blob)
	{
		try {
			parsedBlob = JSON.parse(blob);
		}
		catch (e)
		{
			return defaultSettings;
		}
	}

	return { ...defaultSettings, ...parsedBlob };
};

export const getUserSettings = async (apollo: ApolloClient<unknown>) =>
{
	const mySettingsRes = await apollo.query({ query: queries.mySettings }).catch((error) => {
		console.log(error);
		return null;
	});

	if (!mySettingsRes || !mySettingsRes.data)
		throw new Error("Failed to fetch user settings!");
	
	const blob: string = mySettingsRes?.data?.mySettings;

	return parseSettings(blob);
};

export const setUserSettings = async (apollo: ApolloClient<unknown>, settingsToModify: Partial<Settings>) =>
{
	for (const key in settingsToModify)
	{
		const setting = key as keyof Settings;
		postVuplexMessage("Set setting", { id: setting, value: settingsToModify[setting] });
	}

	await apollo.mutate({
		mutation: mutations.updateUserSettings,
		variables: {
			blob: JSON.stringify(settingsToModify)
		},
		refetchQueries: [{ query: queries.mySettings }],
		awaitRefetchQueries: true
	});
};

// React hook version of above
export const useUserSettings = (options?: {
	onMutationCompleted: (data: unknown) => void
}) =>
{
	const mySettingsRes = useQuery(queries.mySettings, { fetchPolicy: 'cache-first' });
	const [ updateSettings, mutation ] = useMutation(mutations.updateUserSettings, {onCompleted: options?.onMutationCompleted});
	
	const blob = mySettingsRes?.data?.mySettings;

	const settings = !mySettingsRes.loading ? parseSettings(blob) : null;

	const setSettings = (settingsToModify: Partial<Settings>) =>
	{
		for (const key in settingsToModify)
		{
			const setting = key as keyof Settings;
			postVuplexMessage("Set setting", { id: setting, value: settingsToModify[setting] });
		}

		updateSettings({ 
			variables: { 
				blob: JSON.stringify(settingsToModify) }, 
			refetchQueries: [{ query: queries.mySettings }] });
	}

	return { userSettings: settings, setUserSettings: setSettings, settingsLoading: mySettingsRes.loading, mutationLoading: mutation.loading };
};