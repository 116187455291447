import React from 'react';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import queries from "../../graphql/queries";
import { Theme, makeStyles } from '@material-ui/core';
import postVuplexMessage from '../../service/message-vuplex';
import Announce_ON from '../../icons/Announce_ON.png';
import MuteAll_ON from '../../icons/MuteAll_ON.png';
import Regroup from '../../icons/Regroup.png';
import NoTablet from '../../icons/NoTablet.png';
import FacilitationToggle from './facilitation-toggle';

type FacilitationSessionProps = {
	spawned?: boolean
}

const useStyles = makeStyles<Theme, FacilitationSessionProps>((theme => ({
	root: {
		margin: 'auto',
	},

	controls: ({ spawned}) => ( {
		width: spawned ? '750px':'1030px',
		margin: 'auto',
		background: theme.palette.background.paper,
		borderRadius: spawned ? '0px':'44px',
		marginTop: '24px',
		padding: '24px 72px 64px 72px',

		'&> h1': {
			marginTop: '0px',
			marginBottom: theme.glueSpacing('xl'),
			textAlign: 'center',
		},

		'&> h3': {
			marginTop: '50px',
			marginBottom: '50px',
			textAlign: 'center',
		}
	}),

	logo: {
		display: 'block',
		margin: 'auto',
	},

	imageToggle: {
		borderWidth:'2px',
		borderColor: theme.palette.red.main
	},

	controlsGrid: ({ spawned}) => ({
		display: 'grid',
		marginBottom: '5px',
		gridTemplateColumns: spawned ? '150px 150px 150px 150px' : '200px 200px 200px 200px',
		gap: '24px 28px',
		width: '100%',
		justifyContent: 'center',
		alignContent: 'center'
	}),	

})));

const FacilitationSession = (props: FacilitationSessionProps) => {
	const classes = useStyles(props);
	const apollo = useApolloClient();

	const muteAllResult = useQuery(queries.muteAllEnabled);
	const muteAllEnabled = muteAllResult.data?.muteAllEnabled;
	const noTabletResult = useQuery(queries.uiDisabledState);
	const uiDisabledState = noTabletResult.data?.uiDisabledState;
	const announcerModeResult = useQuery(queries.announcerModeEnabled);
	const announcerModeEnabled = announcerModeResult.data?.announcerModeEnabled

	const toggleDisableUI = () =>
	{
		postVuplexMessage("Facilitation.DisableUIState", { value: !uiDisabledState });
		apollo.writeQuery({
			query: queries.uiDisabledState,
			data: { uiDisabledState: !uiDisabledState }
		})
	}

	const toggleMuteAll = () =>
	{
		postVuplexMessage("Facilitation.MuteAll", { value: !muteAllEnabled });
		apollo.writeQuery({
			query: queries.muteAllEnabled,
			data: { muteAllEnabled: !muteAllEnabled }
		})
	}

	const toggleAnnouncerMode = () => 
	{
		postVuplexMessage("Facilitation.EnableAnnouncer", { value: !announcerModeEnabled });
		apollo.writeQuery({
			query: queries.announcerModeEnabled,
			data: { announcerModeEnabled: !announcerModeEnabled }
		})
	}

	const RegroupFunction = ()=>
	{
		postVuplexMessage('Regroup');
	};

	return (
		<div className={classes.root}>
			<div className={classes.controls}>
				<h1>Session control</h1>
				<div className={classes.controlsGrid}>
					<FacilitationToggle 
						toggled={muteAllEnabled}
						onPointerDown={() => toggleMuteAll()}
						label='Mute All'
					>
						<img 
							src={MuteAll_ON}
						/>
					</FacilitationToggle>
					<FacilitationToggle 
						toggled={uiDisabledState}
						onPointerDown={() => toggleDisableUI()}
						label='Disable UIs'
					>
						<img
							src={NoTablet}
						/>
					</FacilitationToggle>
					<FacilitationToggle
						toggled={announcerModeEnabled}
						onPointerDown={() => toggleAnnouncerMode()}
						label='Announce'
					>
						<img 
							src={Announce_ON}
						/>

					</FacilitationToggle>
					<FacilitationToggle
						onPointerDown={() => RegroupFunction()}
						label='Regroup'
						noBorder={true}
					>
						<img
							src={Regroup}
						/>
					</FacilitationToggle>
				</div>
			</div>
		</div>
	)
};

export default FacilitationSession;
