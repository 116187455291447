import { FormControlLabel, makeStyles, RadioGroup } from '@material-ui/core';
import React from 'react';
import GlueRadioButton from './glue-radio-button';

const useStyles = makeStyles((theme) => ({
	root: (props) => ({
		display: 'flex',
		flexDirection: props.horizontal ? 'row': 'column'
	}),

	itemLabel: {
		'.Mui-checked + &': {
			opacity: '100%'
		}
	},
}));

const GlueRadioGroup = (props) =>
{
	const classes = useStyles(props);

	const handleChange = (event) =>
	{
		props.onChange(event.target.value);
	}

	return (
		<RadioGroup className={classes.root} value={props.value} onChange={e => handleChange(e)}>
			{props.options.map((item, i) => (
				<FormControlLabel
					key={i}
					classes={{ label: classes.itemLabel }}
					value={item.id} 
					control={<GlueRadioButton/>} 
					label={item.name}
					disabled={item.disabled}
				/>
			))}
		</RadioGroup>
	);
}

export default GlueRadioGroup;