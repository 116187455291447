import React, { ChangeEvent, useState } from 'react';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core/styles';

import GlueButton from '../common/glue-button';
import queries from '../../graphql/queries';
import mutations from '../../graphql/mutations';
import { assetVisibility, checkSpaceAssetUploadUploadStatus, COMMON_ASSET_VERSION_POINTER_NAMES, setVersionPointer, uploadSpaceAssetPackageAsync } from './space-asset-common';
import CommonTextValuePicker from '../common/common-text-value-picker';
import { getMetadataFromSpaceAssetPackage } from '../../util/space-asset-package-utils';
import { Asset, VersionPointer } from '../../graphql/types-generated';
import { CancelTokenSource } from 'axios';
import { usePromptDialogContext } from '../../util/prompt-dialog-context';
import InfoDialogTemplate from '../common/info-dialog-template';

const useStyles = makeStyles((theme) => ({
	root: {

	},

	todoEntry: {
		background: (props: { isUploading?: boolean }) => props.isUploading ? 'green' : theme.palette.background.paper,
		margin: '32px 0 32px 0',
		padding: '32px'
	}
}));

class UploadToDoInfo
{
	file: File;
	assetId: string;
	cancelSource?: CancelTokenSource | null;
	
	constructor(file: File, assetId: string)
	{
		this.file = file;
		this.assetId = assetId;

		// Upload cancel handle. See Axios docs.
		this.cancelSource = null;
	}
}

const MassUploadToDoEntry = (props: {
	todo: UploadToDoInfo
	progress: number
	assignToPointerName: string
}) =>
{
	const isUploading = props.progress >= 0;

	const classes = useStyles({ isUploading });

	const assetInfoRes = useQuery(queries.assetInfo, {
		variables: {
			assetId: props.todo.assetId
		},
		skip: !props.todo.assetId
	});

	const oldPointerVersion = assetInfoRes.data?.assetInfo.versionPointers?.find(
		(item: VersionPointer) => item.name === props.assignToPointerName
	)?.version ?? "Not found";

	return (
		<div className={classes.todoEntry}>
			<h3>Package</h3>
			<div>Name: {props.todo.file.name}</div>

			<hr />

			<h3>Found matching Asset</h3>
			<div>Name: {assetInfoRes.data?.assetInfo.name}</div>
			<div>ID: {assetInfoRes.data?.assetInfo.assetId}</div>
			<div>Scene name: {assetInfoRes.data?.assetInfo.sceneName}</div>
			<div>Old pointer version: {oldPointerVersion}</div>

			<hr />

			<h3>Status</h3>
			{props.progress >= 0 ? (
				<div>Upload progress: {Math.round(props.progress * 100)}%</div>
			) : (
				<div>Not uploading</div>
			)}
		</div>
	);
};


const MassUploadSpaceAssets = () =>
{
	const classes = useStyles({});

	const apollo = useApolloClient();
	const { addDialog } = usePromptDialogContext();

	const myAssetsRes = useQuery(queries.myAssets, {
		variables: {
			typeFilter: 'space'
		}
	});

	const assetIsCustom = (asset: Asset) => {
		return asset.permission === assetVisibility.team || asset.permission === assetVisibility.org;
	}

	// We don't want to accidentally mass upload into customer assets
	let builtInAssets: Asset[] = myAssetsRes.data?.myAssets ?? [];
	builtInAssets = builtInAssets.filter(asset => !assetIsCustom(asset));

	const [todoEntries, setTodoEntries] = useState<UploadToDoInfo[]>([]);
	const [progress, setProgress] = useState(-1);
	const [uploadingPackageName, setUploadingPackageName] = useState<string | null>(null);
	const [isUploading, setIsUploading] = useState(false);
	const [assignToPointerName, setAssignToPointerName] = useState('');

	const onFilesChange = async (e: ChangeEvent<HTMLInputElement>) =>
	{
		const fileList = e.target.files;
		if (!fileList) {
			return;
		}

		console.log("Files changed", fileList);
		const newTodoEntries = [];

		const matchPackageFilenameRegex = /.+\.tar$/;

		for (const file of fileList)
		{
			if (!matchPackageFilenameRegex.test(file.name))
			{
				console.error("File does not seem to be a Space Asset Package", file);
				continue;
			}

			const metaObj = await getMetadataFromSpaceAssetPackage(file);

			if (!metaObj)
			{
				console.error("Failed to find a metadata file inside a package.");
				break;
			}

			const matchingAsset = builtInAssets.find(item => item.name === metaObj.assetName);
			if (!matchingAsset)
			{
				console.error("No matching existing Asset was found", metaObj);
				continue;
			}
			
			const duplicateNames = builtInAssets.some(item => item.name === metaObj.assetName && item !== matchingAsset);
			if (duplicateNames)
			{
				addDialog(<InfoDialogTemplate
					isError={true}
					message={`Found multiple built-in assets with name ${metaObj.assetName}. Make sure all the assets have unique names to use mass upload.`}
				/>);
				continue;
			}

			const todoEntry = new UploadToDoInfo(file, matchingAsset.assetId);
			newTodoEntries.push(todoEntry);

			console.log("Added to queue", todoEntry, metaObj);
		}

		setTodoEntries(newTodoEntries);
		setProgress(-1);
		setUploadingPackageName(null);
	};

	const uploadPackages = async () =>
	{
		console.log("Start uploading packages");

		setIsUploading(true);

		for (let i = 0; i < todoEntries.length; ++i)
		{
			const todoEntry = todoEntries[i];

			setUploadingPackageName(todoEntry.file.name);

			console.log("Upload " + todoEntry.file.name);

			const signedUploadURLRes = await apollo.mutate({
				mutation: mutations.generateAssetUploadURL,
				variables: {
					fileType: todoEntry.file.type,
					assetId: todoEntry.assetId
				}
			});
		
			const signedUploadURLBody = signedUploadURLRes.data.generateAssetUploadURL;
			console.log(signedUploadURLBody);

			const uploadRes = await uploadSpaceAssetPackageAsync(
				signedUploadURLBody,
				todoEntry.file,
				setProgress,
				(cancelSource) => todoEntry.cancelSource = cancelSource,
			);

			console.log("Upload result", uploadRes);

			if (assignToPointerName)
			{
				const processingResult = await checkSpaceAssetUploadUploadStatus(uploadRes.statusItemId, apollo, null)
				.catch(e => {
					console.error("Processing Space Asset package failed", e);
				});

				if (processingResult) {
					const setPointerRes = await setVersionPointer(apollo, todoEntry.assetId, assignToPointerName, processingResult.version.version);
					console.log("Set pointer result", setPointerRes);
				}
			}
		}

		setIsUploading(false);

		myAssetsRes.refetch();
	};

	return (
		<div className={classes.root}>
			<p>Keep the browser console open and check it for potential error messages.</p>

			<hr />

			<div>
				<label>
					Select packages and meta files:
					<input type="file" multiple disabled={isUploading} onChange={onFilesChange} />
				</label>
				<p>Only select Space Asset Package files like <i>example.tar</i>.</p>
			</div>

			<div>
				<label>
					Assing to version pointer:
					<CommonTextValuePicker
						value={assignToPointerName}
						onChange={(value: string) => setAssignToPointerName(value)}
						placeholder={"Version pointer name"}
						options={COMMON_ASSET_VERSION_POINTER_NAMES}
					/>
				</label>
			</div>

			<div>
				{todoEntries.map((todoEntry, i) => (
					<MassUploadToDoEntry
						key={todoEntry.file.name}
						todo={todoEntry}
						assignToPointerName={assignToPointerName}
						progress={uploadingPackageName === todoEntry.file.name ? progress : -1}
					/>
				))}

				{todoEntries.length > 0 && (
					<GlueButton disabled={isUploading} onPointerDown={uploadPackages}>Start uploading</GlueButton>
				)}
			</div>
		</div>
	);
};

export default MassUploadSpaceAssets;
