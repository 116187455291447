import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core';
import SwitchSelector from '../common/switch-selector';
import GlueDropdown from '../common/glue-dropdown';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { postVuplexMessage } from '../../service/message-vuplex';
import queries from '../../graphql/queries';
import GlueButton from '../common/glue-button';
import LanguageSelectionDialog from './language-selection-dialog';
import { isAtHome } from '../../util/space-utils';
import GlueDialog from '../common/glue-dialog';
import { setUserSettings } from '../../service/user-settings';

const languageSelectionFull = [
	{ id: 'ca-ES', name: 'Catalan (Spain)' },
	{ id: 'en-NZ', name: 'English (New Zealand)' },
	{ id: 'de-DE', name: 'German (Germany)' },
	{ id: 'hr-HR', name: 'Croatian (Croatia)' },
	{ id: 'en-PH', name: 'English (Philippines)' },
	{ id: 'el-GR', name: 'Greek (Greece)' },
	{ id: 'cs-CZ', name: 'Czech (Czech Republic)' },
	{ id: 'en-SG', name: 'English (Singapore)' },
	{ id: 'hu-HU', name: 'Hungarian (Hungary)' },
	{ id: 'da-DK', name: 'Danish (Denmark)' },
	{ id: 'en-ZA', name: 'English (South Africa)' },
	{ id: 'it-IT', name: 'Italian (Italy)' },
	{ id: 'nl-NL', name: 'Dutch (Netherlands)' },
	{ id: 'en-GB', name: 'English (United Kingdom)' },
	{ id: 'ga-IE', name: 'Irish(Ireland)' },
	{ id: 'en-AU', name: 'English (Australia)' },
	{ id: 'en-US', name: 'English (United States)' },
	{ id: 'lv-LV', name: 'Latvian (Latvia)' },
	{ id: 'en-CA', name: 'English (Canada)' },
	{ id: 'et-EE', name: 'Estonian(Estonia)' },
	{ id: 'lt-LT', name: 'Lithuanian (Lithuania)' },
	{ id: 'en-HK', name: 'English (Hong Kong)' },
	{ id: 'fi-FI', name: 'Finnish (Finland)' },
	{ id: 'mt-MT', name: 'Maltese(Malta)' },
	{ id: 'en-IN', name: 'English (India)' },
	{ id: 'fr-CA', name: 'French (Canada)' },
	{ id: 'nb-NO', name: 'Norwegian (Bokmål) (Norway)' },
	{ id: 'en-IE', name: 'English (Ireland)' },
	{ id: 'fr-FR', name: 'French (France)' },
	{ id: 'pl-PL', name: 'Polish (Poland)' },
	{ id: 'pt-BR', name: 'Portuguese (Brazil)' },
	{ id: 'es-CR', name: 'Spanish (Costa Rica)' },
	{ id: 'es-PY', name: 'Spanish (Paraguay)' },
	{ id: 'pt-PT', name: 'Portuguese (Portugal)' },
	{ id: 'es-CU', name: 'Spanish (Cuba)' },
	{ id: 'es-PE', name: 'Spanish (Peru)' },
	{ id: 'ro-RO', name: 'Romanian (Romania)' },
	{ id: 'es-DO', name: 'Spanish (Dominican Republic)' },
	{ id: 'es-PR', name: 'Spanish (Puerto Rico)' },
	{ id: 'ru-RU', name: 'Russian (Russia)' },
	{ id: 'es-EC', name: 'Spanish (Ecuador)' },
	{ id: 'es-ES', name: 'Spanish (Spain)' },
	{ id: 'sk-SK', name: 'Slovak (Slovakia)' },
	{ id: 'es-SV', name: 'Spanish (El Salvador)' },
	{ id: 'es-UY', name: 'Spanish (Uruguay)' },
	{ id: 'sl-SI', name: 'Slovenian (Slovenia)' },
	{ id: 'es-GT', name: 'Spanish (Guatemala)' },
	{ id: 'es-US', name: 'Spanish (USA)' },
	{ id: 'es-AR', name: 'Spanish (Argentina)' },
	{ id: 'es-HN', name: 'Spanish (Honduras)' },
	{ id: 'es-VE', name: 'Spanish (Venezuela)' },
	{ id: 'es-BO', name: 'Spanish (Bolivia)' },
	{ id: 'es-MX', name: 'Spanish (Mexico)' },
	{ id: 'sv-SE', name: 'Swedish (Sweden)' },
	{ id: 'es-CL', name: 'Spanish (Chile)' },
	{ id: 'es-NI', name: 'Spanish (Nicaragua)' },
	{ id: 'tr-TR', name: 'Turkish (Turkey)' },
	{ id: 'es-CO', name: 'Spanish (Colombia)' },
	{ id: 'es-PA', name: 'Spanish (Panama)' },
];

const useStyles = makeStyles(theme => ({
	root: {
		width: '886px',
		margin: 'auto',
	},

	title: {
		marginBottom: '36px',
		marginTop: '48px',
	},

	row: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: '64px',
		marginBottom: '12px',

		'& > p': {
			opacity: '70%',
		},
	},

	clearButton: {
		marginRight: '-38px',
	},

	grabbingMode: {
		marginRight: '-102px',
	},

	switch: {
		marginRight: '-60px'
	},

	dialogPaper: {
		maxHeight: 'none'
	},

	slider: {
		width: '420px'
	}
}));

const General = (props) => 
{
	const apollo = useApolloClient();
	const currentSpaceServerKeyResult = useQuery(queries.currentSpaceServerKey);
	const atHome = isAtHome(currentSpaceServerKeyResult);
	
	const crash = useQuery(queries.crashReport);

	const clientPlatformResult = useQuery(queries.clientPlatform);
	const clientPlatform = clientPlatformResult.data?.clientPlatform;
	const vrModeEnabled = clientPlatform?.PlatformType === "VR";
	
	const [ languageDialog, setlanguageDialog] = useState(false);
	const [ unsavedSTTLanguage, setUnsavedSTTLanguage ] = useState(null);
	const [ selectedLanguages, setSelectedLanguages ] = useState(null);

	const [ personalSpace, setPersonalSpace ] = useState(props.settings?.personalSpaceProtection);
	const [ grabbingMode, setGrabbingMode ] = useState(props.settings?.vrGrabbingMode);
	const [ forceTunneling, setForceTunneling ] = useState(props.settings?.forceTunneling);

	const classes = useStyles();

	const sortedLanguages = languageSelectionFull.sort((a, b) => a.name.localeCompare(b.name));

	if (!selectedLanguages)
	{
		if (props.settings?.sttLanguageList)
		{
			setSelectedLanguages(props.settings.sttLanguageList);
		}
	}

	if (!unsavedSTTLanguage)
	{
		if (props.settings?.sttCurrentLanguage)
		{
			setUnsavedSTTLanguage(props.settings.sttCurrentLanguage);
		}
	}

	const ChangeSelectedLanguages = (list) =>
	{
		setSelectedLanguages(list);
		setUserSettings(apollo, { sttLanguageList: list, sttCurrentLanguage: unsavedSTTLanguage });
	};

	const FiendItemByID = (language) =>
	{
		return languageSelectionFull.find(item => item.id === language).name;
	};

	const SpeechToText = () => 
	{
		const speechToTextList = selectedLanguages.map((language) => ({ id: language, name: FiendItemByID(language) }));
		const sortedSpeechToTextList = speechToTextList.sort((a,b) => a.name.localeCompare(b.name));

		const handleSTTChange = (newValue) => {
			if (newValue === "more")
			{
				setlanguageDialog(true);
			}
			else
			{
				setUnsavedSTTLanguage(newValue);
				setUserSettings(apollo, { sttLanguageList: selectedLanguages, sttCurrentLanguage: newValue });
			}
		};

		const langaudiomessage = "Tools/Settings/General/Language/Press";
		const langhoveraudiomessage = "Tools/Settings/General/Language/HL";
		const langselaudiomessage = "Tools/Settings/General/Language/Select/Press";
		const langselhoveraudiomessage = "Tools/Settings/General/Language/Select/HL";

		const defaultSTTValue = (selectedLanguages.includes(unsavedSTTLanguage))? unsavedSTTLanguage : selectedLanguages[0];

		return (
			<div style={{ marginLeft: '130px' }}>
				<GlueDropdown
					width={'420px'}
					items={sortedSpeechToTextList.concat([{id: 'more', name: 'Modify Your Language List'}])}
					defaultValue={defaultSTTValue}
					uiAudioMessage = {langaudiomessage}
					uiHoverAudioMessage = {langhoveraudiomessage} 
					uiSelAudioMessage = {langselaudiomessage}
					uiSelHoverAudioMessage = {langselhoveraudiomessage} 
					onChange={(id, checked) => checked && handleSTTChange(id)}
				/>
			</div>
		);
	};

	const audiomessagePersonalSpace = !!personalSpace ? "Tools/Settings/General/PersonalSpace/Off" : "Tools/Settings/General/PersonalSpace/On";
	const hoveraudiomessagePersonalSpace = "Tools/Settings/General/PersonalSpace/HL";

	const PersonalSpaceChange = () => 
	{
		const value = !personalSpace;
		props.updatePersonalSpace(value);
		setPersonalSpace(value);
	};

	const PersonalSpace = (
		<div className={classes.switch}>
			<SwitchSelector 
				checked={!!personalSpace}
				uiAudioMessage = {audiomessagePersonalSpace}
				uiHoverAudioMessage = {hoveraudiomessagePersonalSpace} 
				onChange={PersonalSpaceChange}
				offText={'Off'}
				onText={'On'}
			/>
		</div>
	);

	const audiomessageGrabbingMode = !!grabbingMode ? "Tools/Settings/General/GrabbingMode/Off" : "Tools/Settings/General/GrabbingMode/On";
	const hoveraudiomessageGrabbingMode = "Tools/Settings/General/GrabbingMode/HL";

	const GrabbingModeChange = () => 
	{
		const value = !grabbingMode;
		props.updateGrabMode(value);
		setGrabbingMode(value)
	};

	const GrabbingMode = (
		<div className={classes.grabbingMode}>
			<SwitchSelector 
				checked={!!grabbingMode} 
				uiAudioMessage = {audiomessageGrabbingMode}
				uiHoverAudioMessage = {hoveraudiomessageGrabbingMode}
				onChange={GrabbingModeChange}
				offText={'Hold'}
				onText={'Toggle'}
			/>
		</div>
	);

	const audiomessageCrashReport = crash.data.crashReport ? "Tools/Settings/General/CrashReports/Off" : "Tools/Settings/General/CrashReports/On";
	const hoveraudiomessageCrashReport = "Tools/Settings/General/CrashReports/HL";

	const CrashReportChange = () => 
	{
		postVuplexMessage('Set crash reports', { value: !crash.data.crashReport });
	};

	const CrashReport = (
		<div className={classes.switch}>
			<SwitchSelector 
				checked={crash.data.crashReport} 
				uiAudioMessage = {audiomessageCrashReport}
				uiHoverAudioMessage = {hoveraudiomessageCrashReport}
				onChange={CrashReportChange}
				offText={'Off'}
				onText={'On'}
			/>
		</div>
	);

	const ForceTunnelingChange = () =>
	{
		const value = !forceTunneling;
		props.updateForceTunneling(value);
		setForceTunneling(value);
	};

	const ForceTunneling = (
		<div className={classes.switch}>
			<SwitchSelector
				disabled={!atHome}
				checked={forceTunneling}
				uiAudioMessage = {audiomessageGrabbingMode}
				uiHoverAudioMessage = {hoveraudiomessageGrabbingMode}
				onChange={ForceTunnelingChange}
				offText={'Off'}
				onText={'On'}
			/>
		</div>
	);

	const ClearCache = () => 
	{
		postVuplexMessage('Clear assets cache');
	};

	const handleCloseDialog = () => 
	{
		setlanguageDialog(false);
	};

	const audiomessage = "Tools/Settings/General/ClearCache/Press";
	const hoveraudiomessage = "Tools/Settings/General/ClearCache/HL";
	
	return (
		<div className={classes.root}>
			<h1 className={classes.title}>Regional Settings</h1>
			<div className={classes.row}>
				<p>Speech to Text language</p>
				<div>
					<SpeechToText />
				</div>
			</div>
			<h1 className={classes.title}>Using Glue</h1>
			<div className={classes.row}>
				<p>Personal space protection</p>
				<div>
					{PersonalSpace}
				</div>
			</div>
			{vrModeEnabled && (
				<div className={classes.row}>
					<p>Grabbing mode</p>
					<div>
						{GrabbingMode}
					</div>
				</div>
			)}
			<div className={classes.row}>
				<p>Send crash reports</p>
				<div>
					{CrashReport}
				</div>
			</div>
			<div className={classes.row}>
				<p>Always use proxy tunneling</p>
				<div>
					{ForceTunneling}
				</div>
			</div>
			<div className={classes.row}>
				<p>Clear local cache</p>
				<div className={classes.clearButton}>
					<GlueButton 
						disabled={!atHome}
						onPointerDown={() => ClearCache()}
						uiAudioMessage = {audiomessage}
						uiHoverAudioMessage = {hoveraudiomessage}
						>Clear
					</GlueButton>
				</div>
			</div>
			<GlueDialog 
				PaperProps={{ classes: { root: classes.dialogPaper } }} 
				fullWidth={true} 
				maxWidth={'lg'} 
				open={languageDialog} 
				onClose={handleCloseDialog}
			>
				<LanguageSelectionDialog 
					closeDialog={handleCloseDialog}
					languageSelection={sortedLanguages}
					selectedLanguages={selectedLanguages}
					onChange={ChangeSelectedLanguages}
				/>
			</GlueDialog>
		</div>
	)
};

export default General;
