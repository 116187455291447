import React from 'react';
import postVuplexMessage from '../service/message-vuplex';
import HeroIcon from './common/hero-icon';

import DesktopViewIcon from '../icons/hero/DesktopView.png';
import DesktopViewDisabledIcon from '../icons/hero/DesktopView_OFF.png';

const spawnDesktopView = () =>
{
	const objectToSpawn = "desktopview";
	console.log("Spawn object desktop view.");
	postVuplexMessage("Spawn object", { id: objectToSpawn, args: null });
};

const DesktopViewHeroIcon = (props) =>
{
	const hoveraudiomessage = "Menu/DesktopView/HL";
	const audiomessage = "Menu/DesktopView/Press";

	return (
		<HeroIcon
			name="Desktop View"
			icon={props.disabled ? DesktopViewDisabledIcon : DesktopViewIcon}
			route="/"
			onPointerDown={spawnDesktopView}
			disabled={props.disabled}
			uiAudioMessage={audiomessage}
			uiHoverAudioMessage={hoveraudiomessage} 
		/>
	);
}

export default DesktopViewHeroIcon;