import React from 'react';
import { makeStyles, ButtonBase } from '@material-ui/core';
import { hoverAudio, clickAudio } from './common-vuplex-messages';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { Palette, PaletteColor } from '@material-ui/core/styles/createPalette';

type ButtonProps = {
	toggled?: boolean
	variant?: 'main' | 'icon' | 'compact' | 'compactIcon'
	color?: keyof Palette
	width?: string | number
	disabled?: boolean
	uiAudioMessage?: string
	uiHoverAudioMessage?: string
	children?: React.ReactNode
	onClick?: () => void
	onPointerDown?: (e: React.PointerEvent) => void
}

const useStyles = makeStyles((theme) => ({
	root: (props: ButtonProps) => ({
		...theme.custom.glueButton[props.variant ?? 'main'],
		width: props.width ?? theme.custom.glueButton[props.variant ?? 'main'].width,

		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',

		background: props.toggled ? (theme.palette[props.color ?? 'secondary'] as PaletteColor).active : (theme.palette[props.color ?? 'secondary'] as PaletteColor).main,
		color: props.toggled ? (theme.palette[props.color ?? 'secondary'] as PaletteColor).textActive : (theme.palette[props.color ?? 'secondary'] as PaletteColor).contrastText,

		'&:hover': {
			filter: 'brightness(1.25)'
		},

		'&:active': {
			filter: 'brightness(1.50)'
		},

		'&:disabled': {
			opacity: '50%'
		}
	}),

	textLabel: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	}
}), { name: 'MuiGlueButton' });

const filterChildren = (children: React.ReactNode, classes: ClassNameMap) =>
{
	const incoming = Array.isArray(children) ? children : [children];
	const accepted = [];
	
	for (let i = 0; i < incoming.length; ++i)
	{
		const child = incoming[i];

		if (!child)
			continue;

		if (typeof child === 'string')
		{
			// Plain text needs to be wrapped in an element so that 'text-overflow: ellipsis'
			// works reliably on all browsers in this flex context.

			accepted.push(<div key={i} className={classes.textLabel}>{child}</div>);
			continue;
		}

		accepted.push(React.cloneElement(child, { key: i, ...child.props }));
	}

	return accepted;
}

const onClickIcon = (props: ButtonProps, e: React.PointerEvent) =>
{
	if (props.onPointerDown)
	{
		props.onPointerDown(e);
	}
};

const GlueButton = (props: ButtonProps) => 
{
	const classes = useStyles(props);
	const audiomessage = !!props.uiAudioMessage ? props.uiAudioMessage : "Menu/Generic/Button/Press";
	const hoveraudiomessage = !!props.uiHoverAudioMessage ? props.uiHoverAudioMessage : "Menu/Generic/Button/HL";

	const children = filterChildren(props.children, classes);

	return (
		<ButtonBase className={classes.root} disabled={props.disabled}
			onPointerDown={(e) => {onClickIcon(props, e); clickAudio(audiomessage)} }
			onPointerEnter={() => {hoverAudio(hoveraudiomessage)}}
			onClick={props.onClick}
		>
			{children}
		</ButtonBase>
	);
};

export default GlueButton;
