import React from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import GlueButton from './glue-button';
import AppsIcon from '@material-ui/icons/Apps';

type AppHeaderProps = {
	selected?: boolean,
	onBackButtonAction?: (e: React.PointerEvent) => void,
	exitPath?: string,
	children?: React.ReactNode,
	secondChildren?: React.ReactNode,
	title?: string,
	hideBackButton?: boolean
}

const useStyles = makeStyles((theme) => ({
	root:(props: AppHeaderProps) => ({
		width: '100%',
		height: theme.custom.header.height,
		minHeight: theme.custom.header.height,
		padding: theme.custom.header.padding,
		// This is kind of bad, but I don't know how else to do it
		background: props.selected ? `linear-gradient(${theme.palette.secondary.dark}, ${theme.palette.background.paper})` : `linear-gradient(${theme.palette.background.paper}, ${theme.palette.background.default})`,

		display: 'grid',
		gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'center',
		margin: 0,
	}),

	appTitle: {
		textAlign: 'center',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},

	itemsLeft: {
		display: 'flex',
		gap: theme.glueSpacing('m')
	},

	itemsRight: {
		display: 'flex',
		gap: theme.glueSpacing('m'),
		marginLeft: 'auto'
	},

	empty: {
		width: theme.custom.glueButton.icon.width
	}
}), { name: 'MuiGlueAppHeader' });

const AppHeader = (props: AppHeaderProps) => {
	const classes = useStyles(props);
	const audiomessage = "Tools/BackButton/Press"; 
	const hoveraudiomessage = "Tools/BackButton/HL";

	const navigate = useNavigate();

	const backButtonAction = (ev: React.PointerEvent) =>
	{
		if (props.onBackButtonAction)
			props.onBackButtonAction(ev);
		else
			navigate(props.exitPath ?? '/');
	};

	return (
		<div className={classes.root}>
			<div className={classes.itemsLeft}>
				{props.hideBackButton ? (
					<div className={classes.empty} />
				) : (
					<GlueButton
						variant='icon'
						onPointerDown={backButtonAction}
						uiAudioMessage={audiomessage}
						uiHoverAudioMessage={hoveraudiomessage}
					>
						<AppsIcon />
					</GlueButton>
				)}
				{props.children}
			</div>

			<h1 className={classes.appTitle}>{props.title}</h1>
			
			<div className={classes.itemsRight}>
				{props.secondChildren}
			</div>
		</div>
	);
};

export default AppHeader;