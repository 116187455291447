import React from 'react'
import { useApolloClient } from '@apollo/client';
import { usePromptDialogContext } from '../../util/prompt-dialog-context';
import InfoDialogTemplate from '../common/info-dialog-template';
import mutations from '../../graphql/mutations';

const DeleteSpaceDialog = (props: {
	roomId: string
	onSuccess?: () => void
	onError?: (error: Error) => void
}) =>
{
	const { addDialog } = usePromptDialogContext();
	const apollo = useApolloClient();

	const deleteSpace = async () => {
		await apollo.mutate({
			mutation: mutations.removeSpace,
			variables: {
				roomId: props.roomId
			}
		}).then(res => {
			addDialog(<InfoDialogTemplate
				header={'Success!'}
				message={"The space was successfully deleted."}
			/>);
			if (props.onSuccess) {
				props.onSuccess();
			}
		})
		.catch(err => {
			addDialog(<InfoDialogTemplate
				header={'Failed to delete space!'}
				message={(err as Error).message}
			/>);
			if (props.onError) {
				props.onError(err);
			}
		});
	}

	return (
		<InfoDialogTemplate
			header={"Delete?"}
			message={"Are you sure you want to delete the space? This action can not be undone."}
			callbacks={[
				{ callback: () => null, label: 'Cancel' },
				{ callback: deleteSpace, label: 'Delete', color: "destructive" }
			]}
		/>
	);
}

export default DeleteSpaceDialog;
