import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';

import * as serviceWorker from './serviceWorker';
import './index.css';
import App from './App';
import { initGlueOS } from './initialization';

const startReact = async (apollo: ApolloClient<InMemoryCache>) => {
	ReactDOM.render(
		(
			<React.StrictMode>
				<BrowserRouter>
					<ApolloProvider client={apollo}>
						<App />
					</ApolloProvider>
				</BrowserRouter>
			</React.StrictMode>
		),
		document.getElementById('root')
	);
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

const index = async () =>
{
	const res = await initGlueOS();
	if (!res) {
		console.error("GlueOS initialization failed");
		return;
	}

	await startReact(res.apollo);
};

index();
