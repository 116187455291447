import React, { useState } from 'react'
import { makeStyles, useTheme } from "@material-ui/core";
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined'
import GlueButton from '../common/glue-button';
import { usePromptDialogContext } from '../../util/prompt-dialog-context';
import { sttActive } from '../../util/speech-utils';
import { useMutation, useQuery } from '@apollo/client';
import queries from '../../graphql/queries';
import GlueInputfield from '../common/glue-inputfield';
import GlueDropdown from '../common/glue-dropdown';
import LoadingIndicator from '../common/loading-indicator';
import { Asset } from '../../graphql/types-generated';
import moment from 'moment';
import SpaceTemplateList from './space-template-list';
import SpaceThumbnail_Placeholder from '../../images/SpaceThumbnail_Placeholder.png';
import CreateSpaceDialog from './create-space-dialog';
import CreateTemplateDialog from './create-template-dialog';
import WebInputfield from '../../standalone-web/common/web-inputfield';
import GlueFilterList from '../common/glue-filter-list';
import GlueTag from '../common/glue-tag';
import mutations from '../../graphql/mutations';
import InfoDialogTemplate from '../common/info-dialog-template';
import EditTemplateDialog from './edit-template-dialog';
import { assetVisibility } from '../space-asset/space-asset-common';
import EditTemplateDescriptionDialog from './edit-template-description-dialog';
import { useUserContext } from '../../util/user-context';
import PageSelector from '../common/page-selector';

const searchSTTSinkId = "SpaceTemplatesSearch";

const useStyles = makeStyles((theme) => ({
	root: {
		...theme.custom.spaceTemplatesDialog.root,
		overflow: 'hidden',
		display: 'flex',
		flexFlow: 'column',
		gap: theme.glueSpacing('l')
	},

	top: {
		display: 'flex',
		flexFlow: 'column',
		gap: theme.glueSpacing('m')
	},

	topBar: {
		height: theme.custom.errorDialog.header.height,
		minHeight: theme.custom.errorDialog.header.height,
		display: 'grid',
		gridTemplateColumns: '1fr 3fr 1fr',
		overflow: 'hidden'
	},

	title: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center'
	},

	closeButton: {
		marginLeft: 'auto'
	},

	searchBar: {
		display: 'flex',
		justifyContent: 'space-between',
		paddingLeft: theme.glueSpacing('l'),
		paddingRight: theme.custom.spaceTemplatesDialog.filterPadding
	},

	search: {
		display: 'flex',
		gap: theme.glueSpacing('m')
	},

	bottom: {
		overflow: 'hidden',
		display: 'flex',
		flexFlow: 'column',
		flexGrow: 1,
		gap: theme.glueSpacing('s')
	},

	loading: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '680px',
	},

	content: {
		display: 'flex',
		paddingLeft: theme.glueSpacing('l'),
		flex: 1,
		overflow: 'hidden'
	},

	leftContent: {
		display: 'flex',
		flexFlow: 'column',
		marginRight: theme.glueSpacing('l'),
		gap: theme.glueSpacing('s')
	},

	temp: {
		width: theme.custom.spaceTemplatesDialog.tempContentWidth,
		display: 'flex',
		justifyContent: 'center',
		textAlign: 'center',
		paddingTop: '88px'
	},

	details: {
		display: 'flex',
		flexFlow: 'column',
		flexGrow: 1,
		gap: theme.glueSpacing('l')
	},

	thumbnail: {
		width: theme.custom.imagePreview.width,
		height: theme.custom.imagePreview.height,
		borderRadius: '4px',
		objectFit: 'cover'
	},

	info: {
		width: theme.custom.imagePreview.width,
		display: 'flex',
		flexFlow: 'column',
		flexGrow: 1,
		gap: theme.glueSpacing('m'),
		overflow: 'hidden',

		'& h2': {
			overflow: 'hidden',
			textOverflow: 'ellipsis'
		},
	},

	tags: {
		display: 'flex',
		gap: theme.glueSpacing('s'),
		paddingBottom: theme.custom.tag.root.height
	},

	createSpaceButtons: {
		margin: 'auto',
		display: 'flex',
		gap: theme.glueSpacing('m')
	}
}), { name: 'MuiGlueSpaceTemplatesDialog' });

const SpaceTemplatesDialog = (props: {
	title: string
	teamId: string
	orgId: string
	mode: 'createSpace' | 'manageTemplates'
	web?: boolean
	refreshRooms: () => void
}) =>
{
	const classes = useStyles();
	const theme = useTheme();
	const user = useUserContext();
	const [searchWord, setSearchWord] = useState<string>('');
	const [sortingMethod, setSortingMethod] = useState<string | number>('date');
	const [filters, setFilters] = useState<string[]>(['public', 'custom', 'team', 'org']);
	const [filterListOpen, setFilterListOpen] = useState(false);
	const [selectedId, setSelectedId] = useState<string>('');
	const [ page, setPage ] = useState(0);
	const sttRes = useQuery(queries.speechRecognition);
	const { addDialog, closePromptDialog } = usePromptDialogContext();

	const teamAssetsRes = useQuery(queries.myTeamAssets, {
		skip: !props.teamId,
		variables: { 
			teamId: props.teamId,
			typeFilter: 'space',
		},
		fetchPolicy: 'network-only'
	});

	const hiddenAssetsRes = useQuery(queries.hiddenAssetIds, {
		skip: !props.teamId,
		variables: { 
			teamId: props.teamId,
		},
		fetchPolicy: 'network-only'
	});

	const sortaudiomessage = "Menu/TeamSpaces/Sort/Press";
	const sorthoveraudiomessage = "Menu/TeamSpaces/Sort/HL";
	const sortselaudiomessage = "Menu/TeamSpaces/Sort/Select/Press";
	const sortselhoveraudiomessage = "Menu/TeamSpaces/Sort/Select/HL";

	const onPressCreateSpace = () => {
		if (!selectedAsset) {
			throw new Error('Asset is required');
		}
		closePromptDialog();
		addDialog(
			<CreateSpaceDialog 
				asset={selectedAsset}
				teamId={props.teamId}
				refreshRooms={props.refreshRooms}
				web={props.web}
			/>
		);
	}

	const onPressCreateTemplate = () => {
		closePromptDialog();
		addDialog(
			<CreateTemplateDialog 
				teamId={props.teamId}
				web={props.web}
			/>
		);
	}

	const onPressEditTemplate = (asset: Asset) => {
		closePromptDialog();
		addDialog(
			<EditTemplateDialog
				asset={asset} 
				teamId={props.teamId}
				web={props.web}
			/>
		);
	}

	const onPressEditDescription = (asset: Asset) => {
		closePromptDialog();
		addDialog(
			<EditTemplateDescriptionDialog 
				asset={asset} 
				teamId={props.teamId}
				web={props.web}
			/>
		);
	}

	const [setVisibleInTeam] = useMutation(mutations.setAssetVisibilityInTeam);
	const [setVisibleInOrg] = useMutation(mutations.setAssetVisibilityInOrg);
	const [promoteAsset] = useMutation(mutations.promoteTeamAssetToOrg);

	const setVisible = async (asset: Asset, visible: boolean) => {
		if (asset.permission === assetVisibility.public || asset.permission === assetVisibility.org) {
			await setVisibleInOrg({
				variables: {
					orgId: props.orgId,
					assetId: asset.assetId,
					visibility: visible
				},
				refetchQueries: [{
					query: queries.hiddenAssetIds,
					variables: {
						teamId: props.teamId,
					},
					fetchPolicy: 'network-only',
				}],
				awaitRefetchQueries: true,
				errorPolicy: 'all'
			});
			return;
		}
		
		await setVisibleInTeam({
			variables: {
				teamId: props.teamId,
				assetId: asset.assetId,
				visibility: visible
			},
			refetchQueries: [{
				query: queries.hiddenAssetIds,
				variables: {
					teamId: props.teamId,
				},
				fetchPolicy: 'network-only',
			}],
			awaitRefetchQueries: true,
			errorPolicy: 'all'
		});
	}

	const selectSortingMethod = (id: string | number, checked: boolean) => {
		if (checked) {
			setSortingMethod(id);
		}
	};

	const compareDates = (a: Asset, b: Asset): number => {
		const aProdPointer = a.versionPointers?.find(item => item.name === "production");
		const aProdVersion = aProdPointer?.version;
		const aProdVersionDate = aProdVersion ? a.versions?.find(version => version.version === aProdVersion)?.date : null;

		const bProdPointer = b.versionPointers?.find((item) => item.name === "production");
		const bProdVersion = bProdPointer?.version;
		const bProdVersionDate = bProdVersion ? b.versions?.find(version => version.version === bProdVersion)?.date : null;

		const dateA = moment(aProdVersionDate);
		const dateB = moment(bProdVersionDate);

		if (dateA.isSame(dateB))
			return 0;
		
		if (dateA.isValid() && !dateB.isValid())
			return -1;
		
		if (!dateA.isValid() && dateB.isValid())
			return 1;

		return dateA.isAfter(dateB) ? -1 : 1;
	}

	const compareNames = (a: Asset, b: Asset): number => {
		return a.name.localeCompare(b.name);
	}

	const sortOptions = [
		{ id: 'date', name: 'Sort by most recent'},
		{ id: 'name', name: 'Sort by name' },
	];

	const teamAssets: Asset[] = teamAssetsRes.data?.myTeamAssets ?? [];

	let displayAssets: Asset[] = teamAssets.filter((asset: Asset) => {
		// Only assets with production pointer
		if (!asset.versionPointers || !asset.versionPointers.find((item) => item.name === "production")) {
			return false;
		}
		return true;
	});
	displayAssets = displayAssets.filter((asset: Asset) => !searchWord || asset.name.toLowerCase().includes(searchWord.toLowerCase()));

	// Treat hidden assets as if they're archived
	const hiddenAssetIds: string[] = hiddenAssetsRes.data?.hiddenAssetIds ?? [];
	const assetIsHidden = (asset: Asset) => hiddenAssetIds.some(id => id === asset.assetId);
	const assetIsCustom = (asset: Asset) => (asset.permission === assetVisibility.team || asset.permission === assetVisibility.org);
	const assetIsBuiltIn = (asset: Asset) => (asset.permission === assetVisibility.public || asset.permission === assetVisibility.limited);
	const assetHasOrgTag = (asset: Asset) => (asset.permission === assetVisibility.org || asset.permission === assetVisibility.public || (asset.permission === assetVisibility.limited && asset.allowedOrgs?.includes(props.orgId)));
	const assetHasTeamTag = (asset: Asset) => !assetHasOrgTag(asset);

	// Apply filters
	displayAssets = displayAssets.filter((asset: Asset) => {
		let result = true;
		if (!filters.includes('hidden') && assetIsHidden(asset)) {
			result = false;
		}
		if (!filters.includes('public') && assetIsBuiltIn(asset)) {
			result = false;
		}
		if (!filters.includes('custom') && assetIsCustom(asset)) {
			result = false;
		}
		if (!filters.includes('team') && assetHasTeamTag(asset)) {
			result = false;
		}
		if (!filters.includes('org') && assetHasOrgTag(asset)) {
			result = false;
		}
		return result;
	});

	switch (sortingMethod) {
		case 'date':
			displayAssets = displayAssets.sort(compareDates);
			break;
		case 'name':
			displayAssets = displayAssets.sort(compareNames);
			break;
		default:
			break;
	}

	const selectedAsset = displayAssets.find(asset => asset.assetId === selectedId);
	const loading = teamAssetsRes.loading || hiddenAssetsRes.loading;

	const filterOptions = [
		{ id: 'public', name: 'Built-in templates' },
		{ id: 'custom', name: 'Custom templates'},
		{ id: 'team', name: 'Team templates'},
		{ id: 'org', name: 'Org templates'},
		...(props.mode === 'manageTemplates' ? [{ id: 'hidden', name: 'Archived templates'}] : [])
	];

	const setFilter = (id: string, checked: boolean) => {
		if (!checked) {
			setFilters(prev => prev.filter(i => i !== id));
		}
		else {
			const newFilters = [...filters];
			newFilters.push(id);
			setFilters(newFilters);
		}
	}

	const promoteToOrgVisibility = (asset: Asset) => {
		const callback = async (asset: Asset) => {
			await promoteAsset({
				variables: {
					assetId: asset.assetId
				},
				refetchQueries: [{
					query: queries.myTeamAssets,
					variables: { 
						teamId: props.teamId,
						typeFilter: 'space',
					},
					fetchPolicy: 'network-only'
				}],
				awaitRefetchQueries: true,
				errorPolicy: 'all'
			}).then(res => {
				addDialog(<InfoDialogTemplate
					header={'Success!'}
					message={"The space template was successfully promoted to org visibility."}
				/>);
			}).catch(err => {
				addDialog(<InfoDialogTemplate
					header={'Failed to promote space template!'}
					message={(err as Error).message}
				/>);
			});
		}

		closePromptDialog();
		addDialog(<InfoDialogTemplate
			header={"Promote to org visibility?"}
			message={"After making this space template visible to the whole organization, only organization admins can edit it. This action can not be undone."}
			callbacks={[
				{ callback: () => null, label: 'Cancel' },
				{ callback: () => callback(asset), label: 'Promote', color: "primary" }
			]}
		/>);
	}

	const templatesPerPage = 25;
	const pageCount = Math.ceil(displayAssets.length / templatesPerPage);

	return (
		<div className={classes.root}>
			<div className={classes.top}>
				<div className={classes.topBar}>
					<div />
					<div className={classes.title}>
						<h1>{props.title}</h1>
					</div>
					{props.mode === 'manageTemplates' && (
						<div className={classes.closeButton}>
							<GlueButton variant='icon' color='stealth' onPointerDown={closePromptDialog}>
								<CloseOutlinedIcon />
							</GlueButton>
						</div>
					)}
				</div>
				<div className={classes.searchBar}>
					<div className={classes.search}>
						{props.web ? (
							<WebInputfield
								width={theme.custom.spaceTemplatesDialog.inputWidth}
								search 
								value={searchWord}
								onClear={()=>{ setSearchWord("") }}
								onChange={setSearchWord}
								showClearButton={searchWord ? true : false}
							/>
						) : (
							<GlueInputfield
								width={theme.custom.spaceTemplatesDialog.inputWidth}
								search 
								value={searchWord}
								sinkId={searchSTTSinkId} 
								speechInput={sttActive(sttRes, searchSTTSinkId)}
								onClear={()=>{ setSearchWord("") }}
								onChange={setSearchWord}
								onSpeechChange={(value: string)=>{ setSearchWord(value) }}
								showClearButton={searchWord ? true : false}
							/>
						)}
						<GlueDropdown
							width={theme.custom.spaceTemplatesDialog.sortWidth}
							value={sortingMethod}
							items={sortOptions}
							uiAudioMessage = {sortaudiomessage}
							uiHoverAudioMessage = {sorthoveraudiomessage} 
							uiSelAudioMessage = {sortselaudiomessage}
							uiSelHoverAudioMessage = {sortselhoveraudiomessage} 
							onChange={selectSortingMethod} 
						/>
						<GlueFilterList
							open={filterListOpen}
							value={filters}
							items={filterOptions}
							onToggleOpen={() => setFilterListOpen(!filterListOpen)}
							onChange={setFilter}
						/>
					</div>
					{pageCount > 1 && <PageSelector 
						current={page}
						pageCount={pageCount}
						onPageChange={setPage}
					/>}
				</div>
			</div>
			<div className={classes.bottom}>
				{loading ? (
					<div className={classes.loading}>
						<LoadingIndicator />
					</div>
				) : (
					<>
						<div className={classes.content}>
							{selectedAsset ? (
								<div className={classes.leftContent}>
									<div className={classes.details}>
										<img className={classes.thumbnail} src={selectedAsset.thumbnailUrl ?? SpaceThumbnail_Placeholder} alt={''} />
										<div className={classes.info}>
											<h2>{selectedAsset.name}</h2>
											<p>{selectedAsset.description}</p>
										</div>
									</div>
									<div className={classes.tags}>
										{assetHasTeamTag(selectedAsset) && (
											<GlueTag color='red' label='team'/>
										)}
										{assetHasOrgTag(selectedAsset) && (
											<GlueTag color='yellow' label='org'/>
										)}
										{assetIsCustom(selectedAsset) && (
											<GlueTag color='blue' label='custom'/>
										)}
										{assetIsHidden(selectedAsset) && (
											<GlueTag color='green' label='archived'/>
										)}
									</div>
								</div>
							): (
								<div className={classes.temp}>
									{props.mode === 'manageTemplates' ? (
										<h2>Select a space template,<br/>or add a new one.</h2>
									) : (
										<h2>Select a space template<br/>to begin.</h2>
									)}
								</div>
							)}
							<SpaceTemplateList
								web={props.web}
								canCreate={user.team?.assetCreate}
								items={displayAssets}
								hidden={hiddenAssetIds}
								selectedId={selectedId}
								onSelectAsset={setSelectedId}
								onEditAsset={onPressEditTemplate}
								onCreateAsset={onPressCreateTemplate}
								onSetVisible={setVisible}
								onPromote={promoteToOrgVisibility}
								onEditDescription={onPressEditDescription}
								showOptions={props.mode === 'manageTemplates'}
								page={page}
								templatesPerPage={templatesPerPage}
							/>
						</div>
					</>
				)}
				{props.mode === 'createSpace' && (
					<div className={classes.createSpaceButtons}>
						<GlueButton onPointerDown={closePromptDialog}>Cancel</GlueButton>
						<GlueButton 
							color='primary' 
							onPointerDown={onPressCreateSpace} 
							disabled={!selectedAsset}
						>
							Select
						</GlueButton>
					</div>
				)}
			</div>
		</div>
	);
}

export default SpaceTemplatesDialog;
