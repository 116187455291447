import React from 'react';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core';

import queries from '../../graphql/queries';

import GlueButton from '../common/glue-button';
import SwitchSelector from '../common/switch-selector';
import LoadingIndicator from '../common/loading-indicator';
import { postVuplexMessage } from '../../service/message-vuplex';
import { maxAndroidImageSize } from '../../defines';

import openDocumentView from './open-document-view';
import OpenInNewButton from '../common/open-in-new-button';
import CloseButton from '../common/close-button';

export const isViewable = (inventoryItemInfo) => {
	const isImage = inventoryItemInfo.itemType === "image/jpeg" ||
		inventoryItemInfo.itemType === "image/jpeg" ||
		inventoryItemInfo.itemType === "image/png"
	return isImage;
};

const useStyles = makeStyles((theme) => ({
	root: {
		flex: '1 0 auto',
		height: '928px',
		display: 'flex',
		flexFlow: 'column nowrap',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'center',
		position: 'relative',
		borderRadius: '25px',

		background: theme.palette.background.paper,
	},

	header: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		background: theme.palette.background.paper,
		height: theme.custom.header.height,
		minHeight: theme.custom.header.height,
		padding: theme.custom.header.padding,
		alignItems: 'center',
	},

	appContent: {
		flex: '1 0 auto',
		overflow: 'hidden',
		display: 'flex',
		flexFlow: 'column nowrap',
		justifyContent: 'center',
		alignItems: 'center',
		objectFit: 'contain'
	},

	image: {
		height: '840px',
		maxWidth: '100%',
		maxHeight: '100%',
		objectFit: 'contain'
	},

	loadingIndicator: {
		backgroundColor: 'transparent',
		position: 'absolute',
		zIndex: '1',
	},

	headerLeft: {
		display: 'flex',
		gap: theme.glueSpacing('m'),
		marginLeft: theme.glueSpacing('l')
	},

	headerCenter: {
		display: 'flex',
		gap: theme.glueSpacing('m')
	},

	headerRight: {
		display: 'flex',
		gap: theme.glueSpacing('m')
	},

	title: {
		display: 'inline-block',
		maxWidth: '700px',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		textAlign: 'center',
	},
}));

export const importFile = (teamId, data) => {
	console.log('importFile', teamId, ', data', data);
	// Do not allow Android devices to import >4k images due to memory constraints.
	const url = data.platform === "Android" &&
		(data.imageWidth > maxAndroidImageSize ||
			data.imageHeight > maxAndroidImageSize) ?
		data.signedURLs.thumbnailURL : data.signedURLs.itemURL;
	postVuplexMessage("Import image", { teamId: teamId, fileUrl: url });
};

const FileViewImage = (props) => {
	const classes = useStyles();
	const apollo = useApolloClient();
	const importing = useQuery(queries.fileImport);
	const clientPlatformResult = useQuery(queries.clientPlatform);
	const clientPlatform = clientPlatformResult.data?.clientPlatform;

	const importAndClosePreview = (teamId, data) => {
		importFile(teamId, data);
	};

	const closePreview = () => props.closePreview();

	const openView = (sharing) => {
		openDocumentView({
			apollo: apollo,
			inventoryItemId: props.itemInfo.inventoryItemId,
			itemUrl: props.itemInfo.itemUrl,
			itemType: props.itemInfo.itemType,
			sharing: sharing
		});

		closePreview();

		if (clientPlatform?.PlatformType !== "VR") {
			postVuplexMessage("Close tablet", null);
		}
	}

	const presentaudiomessage = "Menu/TeamFiles/ImageViewer/Present/Press";
	const presenthoveraudiomessage = "Menu/TeamFiles/ImageViewer/Present/HL";
	const importaudiomessage = "Menu/TeamFiles/ImageViewer/Import/Press";
	const importhoveraudiomessage = "Menu/TeamFiles/ImageViewer/Import/HL";
	const closeaudiomessage = "Menu/TeamFiles/ImageViewer/Close/Press";
	const closehoveraudiomessage = "Menu/TeamFiles/ImageViewer/Close/HL";

	return (
		<div className={classes.root}>
			{importing.data.fileImport.loading && (
				<div className={classes.loadingIndicator}>
					<LoadingIndicator />
				</div>
			)}

			<div className={classes.header}>
				<div className={classes.headerLeft}>
					<SwitchSelector
							text="Present"
							onChange={() => openView(true)}
							uiAudioMessage={presentaudiomessage}
							uiHoverAudioMessage={presenthoveraudiomessage}
							checked={false}
					/>
					<GlueButton
						onPointerDown={() => importAndClosePreview(props.teamId, {
							...props.itemInfo,
							platform: clientPlatform?.OS,
							signedURLs: props.signedURLs
						})}
						uiAudioMessage={importaudiomessage}
						uiHoverAudioMessage={importhoveraudiomessage}
					>
						Import
					</GlueButton>
				</div>
				<div className={classes.headerCenter}>
					<h2 className={classes.title}>{props.itemInfo.name}</h2>
				</div>
				<div className={classes.headerRight}>
					<OpenInNewButton onOpenInNew={() => openView(false)} />
					<CloseButton
						onClose={closePreview}
						uiAudioMessage={closeaudiomessage}
						uiHoverAudioMessage={closehoveraudiomessage}
					/>
				</div>
			</div>
			<div className={classes.appContent}>
				<img className={classes.image} src={props.signedURLs.itemURL} alt={""} />
			</div>
		</div>
	);
};

export default FileViewImage;
