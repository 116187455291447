import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';

import { useQuery } from '@apollo/react-hooks';
import queries from '../../graphql/queries';

import TextInput from '../common/text-input';
import ColorSelector from '../common/color-selector';
import { useNote } from './note-hooks';
import { colorPresets, getNoteProperty, setNoteProperty, setNoteText, setNoteTextDelayed } from '../../service/note-editor';
import { Note } from './note-types';

type NotePreviewStyleProps = {
	note: Note | null
	width: number
	height: number
	speechActive: boolean
	scaleFactor: number
	heightMargin: string
	widthMargin: string
};

const useStyles = makeStyles(theme => ({
	textField: (props: NotePreviewStyleProps) => ({
		display: 'flex',
		alignItems: props.note?.verticalTextAlignment ? props.note.verticalTextAlignment : 'center',
		width: props.width,
		height: props.height,
		margin: 0,
		padding: 0,
		outline: 'none',
		backgroundColor: props.note?.noteColor,
		outlineColor: props.speechActive ? theme.palette.speech.main : 'none' ,
		outlineStyle: props.speechActive ? 'solid' : 'none',
		transform: `scale(${props.scaleFactor})`
	}),

	input: (props: NotePreviewStyleProps) => ({
		maxHeight: '90%',
		display: 'flex',
		outline: 'none',
		alignItems: 'center',
		width: props.width,
		color: props.note?.fontColor,
		marginTop: props.heightMargin,
		marginRight: props.widthMargin,
		marginBottom: props.heightMargin,
		marginLeft: props.widthMargin,
		lineHeight: props.note?.fontSize + 'px',
		fontSize: props.note?.fontSize + 'px',
		fontFamily: props.note?.fontFamily,
		fontWeight: props.note?.fontWeight,
		fontStyle: props.note?.fontStyle,
		textAlign: props.note?.horizontalTextAlignment,
		letterSpacing: '0',
		overflow: 'hidden',
		textOverflow: "clip",
		resize: 'none'
	}),

	noteArea: {
		height: '936px',
		width: '640px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},

	presetColorParent: {
		position: 'fixed',
		bottom: '32px',
		height: '48px',
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},

	presetColors: {
		display: 'grid',
		gridTemplateColumns: 'repeat(8, 64px)',
		gap: theme.glueSpacing('s')
	},

	noteDimensionText: {
		position: 'fixed',
		bottom: '40px',
		right: '32px',
		color: theme.palette.secondary.dark
	},
}));

const NotePreview = (props: {
	sinkId: string
	drawerOpen: boolean
	colorPickerOpen: boolean
}) => {
	const note = useNote();

	const noteSafeZone = 726;
	const drawerWidth = 766;
	const dpi = 72;

	const width = Math.round((dpi * (note?.width ?? 0) * 10) / 25.4);
	const height = Math.round((dpi * (note?.height ?? 0) * 10) / 25.4);

	const instanceId = window.sessionStorage.getItem('instance-id') ?? '';

	const speechState = useQuery(queries.speechRecognition, {
		variables: {
			instanceId: instanceId
		}
	}).data?.speechRecognition;

	const [ editedText, setEditedText ] = useState<string | undefined>(note?.text);
	const textInputRef = useRef();

	// This is a dirty hack which force updates our TextArea's height
	// We set it to a random value after any change (e.g. font-size change) 
	// Joona: I was complaining about this but it seems we have to keep it after all :c
	// (Again it's because of MUI)
	const [randomKey, setRandomKey] = useState(0);

	const editText = (newText: string) =>
	{
		setEditedText(newText);
		setNoteTextDelayed(newText, 500);
	}

	const commitEditedText = () =>
	{
		if (!!editedText) {
			setNoteText(editedText);
		}
	}

	const concatenate = (base: string, suffix: string) => {
		return (base ?? "").concat(base ? " " : "", suffix);
	}

	useEffect(() => {
		setEditedText(note?.text);
	}, [note]);

	useEffect(() => {
		if (!speechState?.running && speechState?.sinkId === props.sinkId)
		{
			setNoteText(concatenate(getNoteProperty('text') as string, speechState?.text));
		}
	}, [speechState]);

	const forceRefreshInput = () => {
		setRandomKey(Math.random());
	}

	useEffect(() => {
		window.addEventListener('refreshNoteInput', forceRefreshInput);
		return () => {
			window.removeEventListener('refreshNoteInput', forceRefreshInput);
		}
	});

	const getScaleFactor = () => {
		let scale = Math.min((props.drawerOpen === true ? 
			window.innerWidth - drawerWidth :
			window.innerWidth - noteSafeZone) / width, 720 / height
		);
		if (props.drawerOpen && scale > 1)
		{
			scale = 1;
		}
		return scale;
	}

	const classes = useStyles({
		width: width,
		height: height,
		widthMargin: `${width * 0.05}px`,
		heightMargin: `${height * 0.05}px`,
		note: note,
		speechActive: speechState?.running && !speechState?.loading,
		scaleFactor: getScaleFactor(),
	});

	const ColorSwatchItem = (props: {
		value: string
		active: boolean
	}) => {
		return <ColorSelector
			onPointerDown={() => setNoteProperty('noteColor', props.value)}	
			color={props.value}
			toggled={props.active}
		/>;
	}

	const SwatchList = (props: {
		class: string
	}) => {
		return (
			<div className={props.class}>
				{colorPresets.map((color, index) => <ColorSwatchItem key={index} value={color} active={color === note?.noteColor}/>)}
			</div>
		);
	}

	const notePlaceholderText = "Type here";

	return (
		<div className={classes.noteArea}>
			<TextInput
				key={randomKey}
				rootStyle={classes.textField}
				inputStyle={classes.input}
				value={(speechState?.running && speechState?.sinkId === props.sinkId && editedText) ? concatenate(editedText, speechState?.text) : editedText}
				onChange={editText}
				placeholder={notePlaceholderText}
				ref={textInputRef}
				multiline={true}
				onSpaceKey={speechState?.running ? null : commitEditedText}
				autoFocus={!props.drawerOpen}
				sinkId={props.sinkId}
				disabled={props.colorPickerOpen}
			/>
			{props.drawerOpen ? null : 
				<>
					<div className={classes.presetColorParent}>
						<SwatchList class={classes.presetColors}/>
					</div>
					<div className={classes.noteDimensionText}>
						{/* {noteDimensions} */} 
					</div>
				</>
			}
			
		</div>
	);
}

export default NotePreview;