import { CSSProperties } from "@material-ui/core/styles/withStyles";

export type Type = CSSProperties;

const common: Type = {
    height: '80px',
	padding: '8px'
};

export const web: Type = {
    ...common,
    
    height: '70px',
};

export const tablet: Type = {
    ...common
};