import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { HeroIconContext } from './common/hero-icon';

import MicHeroIcon from './mic-hero-icon';
import MouselookHeroIcon from './mouselook-hero-icon';
import HomeHeroIcon from './home-hero-icon';
import TabletHeroIcon from './tablet-hero-icon.js';
import HelpHeroIcon from './help-hero-icon.js';
import SettingsHeroIcon from './settings-hero-icon.js';
import GesturesHeroIcon from './gestures-hero-icon.js'

const useStyles = makeStyles(theme => ({
	actions: {
		flex: '1 0 auto',
		height: '144px',
		background: theme.palette.background.paper,
		display: 'flex',
		justifyContent: 'space-between',
		
		flexDirection: 'row',
		padding: '12px 56px 0 56px',
	}
}));

const ToolbarMain = (props) => {
	const classes = useStyles(props);

	return (
		<div className={classes.actions}>
			<HeroIconContext.Provider value={props.heroIconContext}>
				<MouselookHeroIcon noBackground />
				<GesturesHeroIcon
					noBackground
					onPointerDown={() => props.onModeChange('gestures')}
				/>
				<MicHeroIcon noBackground />
				<TabletHeroIcon isToolbar={props.isToolbar} noBackground />
				<HelpHeroIcon isToolbar={props.isToolbar} noBackground />
				<SettingsHeroIcon isToolbar={props.isToolbar} noBackground />
				<HomeHeroIcon noBackground />
			</HeroIconContext.Provider>
		</div>
	);
};

export default ToolbarMain;