import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import AppHeader from '../common/app-header';
import { postVuplexMessage } from '../../service/message-vuplex';
import LaunchIcon from '@material-ui/icons/Launch';
import ArrowBackIosSharpIcon from '@material-ui/icons/ArrowBackIosSharp';
import ArrowForwardIosSharpIcon from '@material-ui/icons/ArrowForwardIosSharp';
import RefreshSharpIcon from '@material-ui/icons/RefreshSharp';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import GlueButton from '../common/glue-button';
import GlueToggle from '../common/glue-toggle';
import GlueIcon from '../common/glue-icon';
import GlueInputfield from '../common/glue-inputfield';
import { useApolloClient, useQuery } from '@apollo/client';
import queries from '../../graphql/queries';
import mutations from '../../graphql/mutations';
import CloseButton from '../common/close-button';
import SwitchSelector from '../common/switch-selector';
import { BrowserState, BrowserNavigation, BrowserFileUploadState, BrowserAppState, ExternalBrowserInfo } from '../../graphql/types-generated';
import { useLocation, useNavigate } from 'react-router-dom';
import { sendWebControlMsg, sendWebControlMsgToInstance } from '../../util/control-util';
import DownloadMenu from './download-menu';
import UploadMenu from './upload-menu';
import SpeechToTextModal from '../../component/common/speech-to-text-modal';
import { SetCurrentSpeechRecognitionSinkId } from '../../service/speech-to-text';
import { useUserContext } from '../../util/user-context';
import LoadingIndicator from '../common/loading-indicator';
import { usePromptDialogContext } from '../../util/prompt-dialog-context';
import { showClosePresentationControlsDialog } from '../../util/sharing-utils';
import InfoDialogTemplate from '../common/info-dialog-template';

const useStyles = makeStyles(theme => ({
	root: {

	},

	appHeaderButtons: {
		display: 'flex',
		gap: theme.glueSpacing('m')
	},

	header: {
		display: 'grid',
		gridTemplateColumns: 'repeat(3, min-content) 1fr',
		gap: theme.glueSpacing('m'),
		height: theme.custom.header.height,
		minHeight: theme.custom.header.height,
		padding: theme.custom.header.padding,
	},

	browserControls: {
		display: 'grid',
		gridTemplateColumns: '1fr repeat(2, min-content)',
		gap: theme.glueSpacing('m'),
	},

	inputField: {
		display: 'flex',
		gap: theme.glueSpacing('s')
	},

	webContent: {
		width: '1366px',
		height: '768px',
		margin: '8px 0',
		padding: `0 ${theme.glueSpacing('s')}`,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},

	footer: {
		display: 'grid',
		gridTemplateColumns: 'repeat(2, min-content) 1fr repeat(2, min-content)',
		gap: theme.glueSpacing('m'),
		height: theme.custom.header.height,
		minHeight: theme.custom.header.height,
		padding: theme.custom.header.padding,
	},

	titleBox: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		paddingLeft: '64px',
		overflow: 'hidden',
		whiteSpace: 'nowrap'
	},

	title: {
		maxWidth: '100%',
		position: 'relative',
		height: '36px',

		'&> p': {
			lineHeight: '36px',
			textOverflow: 'ellipsis',
			overflow: 'hidden'
		}
	},

	icon: {
		position: 'absolute',
		bottom: 0,
		left: `calc(-36px - ${theme.glueSpacing('m')})`,
		width: '36px',
		height: '36px'
	}
}), { name: 'MuiGlueWebControl' });

const WebControl = () => {
	const classes = useStyles();

	const dialogContext = usePromptDialogContext();

	const [urlInputValue, setUrlInputValue] = useState<string | undefined>();
	const [bookmarked, setBookmarked] = useState<boolean>(false);
	const [initialized, setInitialized] = useState<boolean>(false);
	const navigate = useNavigate();
	const apollo = useApolloClient();
	const user = useUserContext();

	const ui = useQuery(queries.ui).data?.ui;
	const instanceId = window.sessionStorage.getItem('instance-id');
	const webControlSTTSinkId = 'WebControlSinkId' + instanceId;
	SetCurrentSpeechRecognitionSinkId(apollo, instanceId, webControlSTTSinkId);
	const stateRes = useQuery<{ extBrowserState: BrowserState }>(queries.extBrowserState, {
		variables: {
			instanceId: instanceId
		}
	});

	if (!stateRes.data?.extBrowserState)
		postVuplexMessage('WebControl.WebStateRequest', { instanceId: instanceId });

	const navigationRes = useQuery<{ extBrowserNavigation: BrowserNavigation }>(queries.extBrowserNavigation, {
		variables: {
			instanceId
		}
	});
	const canGoBack = !!navigationRes.data?.extBrowserNavigation?.canGoBack;
	const canGoForward = !!navigationRes.data?.extBrowserNavigation?.canGoForward;

	const screenshotCaptureActiveRes = useQuery<{ captureScreenshotActive: boolean }>(queries.captureScreenshotActive, {
		variables: {
			instanceId: instanceId
		}
	});
	const captureScreenshotActive = !!screenshotCaptureActiveRes.data?.captureScreenshotActive;

	const downloadRes = useQuery<{ extBrowserLatestDownloadedFile: string }>(queries.extBrowserLatestDownloadedFile, {
		variables: {
			instanceId: instanceId
		}
	});
	const uploadRes = useQuery<{ extBrowserFileUploadState: BrowserFileUploadState }>(queries.extBrowserFileUploadState, {
		variables: {
			instanceId: instanceId
		}
	});
	const sttRes = useQuery(queries.speechRecognition, {
		variables: {
			instanceId: instanceId
		}
	})
	const sttModalOpen = !!sttRes.data?.speechRecognition.dialogOpen;
	const sttRunning = !!sttRes.data?.speechRecognition.running;

	const presentAudioMessage = "Menu/TeamFiles/ImageViewer/Present/Press";
	const presentHoverAudioMessage = "Menu/TeamFiles/ImageViewer/Present/HL";
	const stopPresentingAudioMessage = "Menu/TeamFiles/ImageViewer/StopPresenting/Press";
	const stopPresentingHoverAudioMessage = "Menu/TeamFiles/ImageViewer/StopPresenting/HL";
	const takeScreenshotAudioMessage = "Apps/Camera/Shutter/Press";

	const sharing = !!stateRes.data?.extBrowserState.sharing;

	// App state can be stored in either session storage or location state, check both:
	const location = useLocation();
	const locationState = location.state as (BrowserAppState | undefined);
	const appname = window.sessionStorage.getItem('appname');
	const title = (!!appname ? appname : locationState?.appname) ?? 'Browser';
	const showBrowserControls = (window.sessionStorage.getItem('isProdTool') !== 'true') && !locationState?.isProdTool;

	const downloadMenuOpen = !!downloadRes.data?.extBrowserLatestDownloadedFile;
	const uploadMenuOpen = !!uploadRes.data?.extBrowserFileUploadState && uploadRes.data.extBrowserFileUploadState.status !== 'Finished';

	const externalTablet = ui === 'webcontrol';
	const platformResult = useQuery(queries.clientPlatform);
	const clientPlatform = platformResult.data?.clientPlatform;
	const platformIsAndroid = clientPlatform?.OS === 'Android';

	const externalBrowserInfoRes = useQuery<{ externalBrowserInfo: ExternalBrowserInfo }>(queries.externalBrowserInfo);
	const externalBrowserInfo = externalBrowserInfoRes.data?.externalBrowserInfo;

	const tryClose = () => {
		if (sharing) {
			showClosePresentationControlsDialog(dialogContext, cancelClose, close);
			postVuplexMessage('WebControl.HideContent', { instanceId: instanceId });
		}
		else {
			close();
		}
	}

	const cancelClose = () => {
		postVuplexMessage('WebControl.ShowContent', { instanceId: instanceId });
	}

	const close = () => {
		console.log('close');
		apollo.writeQuery({
			query: queries.externalBrowserInfo,
			data: {
				externalBrowserInfo: {
					__typename: 'ExternalBrowserInfo',
					instanceId: '',
					sharing: false
				}
			}
		});
		postVuplexMessage('Enable hotkeys', { value: true });
		postVuplexMessage('WebControl.Close', { instanceId: instanceId });
	}

	const cancelOverride = () => {
		postVuplexMessage('WebControl.ShowContent', { instanceId: instanceId });
	}

	const overrideBrowser = (withSharing: boolean) => {
		onDetach(withSharing);
	}

	// Bookmark stuff
	const currentTeamIdResult = useQuery(queries.currentTeamId);
	const currentTeamId = currentTeamIdResult.data?.currentTeamId;

	const bookmarkCurrentUrl = async () => {
		apollo.mutate({
			mutation: mutations.createInventoryItem,
			variables: {
				name: '',
				itemUrl: stateRes.data?.extBrowserState.url,
				itemType: 'link',
				teamId: currentTeamId,
				fileSize: 0,
				permission: 'public'
			}
		});

		setBookmarked(true);
	}

	const removeBookmark = () => {
		// TODO
	}

	const setExtBrowserVisibility = (value: boolean) => {
		sendWebControlMsg("SetContentVisibility", { visible: value });
	}

	const setDownloadMenuVisibility = (value: boolean) => {
		setExtBrowserVisibility(!value);

		if (!value) {
			apollo.writeQuery({
				query: queries.extBrowserLatestDownloadedFile,
				data: {
					extBrowserLatestDownloadedFile: null
				},
				variables: {
					instanceId: instanceId
				}
			});
		}
	}

	const onSubmitUrl = () => {
		if (!instanceId) {
			console.error("Instance ID not defined.");
			return;
		}

		sendWebControlMsg('OpenUrl', { url: urlInputValue });
	}

	const setPresentation = (value: boolean) => {
		if (!instanceId) {
			console.error("Instance ID not defined.");
			return;
		}

		sendWebControlMsg('SetPresentationActive', { active: value });

		if (platformIsAndroid && instanceId === externalBrowserInfo?.instanceId)
		{
			apollo.writeQuery({
				query: queries.externalBrowserInfo,
				data: {
					externalBrowserInfo: {
						__typename: 'ExternalBrowserInfo',
						instanceId: instanceId,
						sharing: value
					}
				}
			});
		}
	}

	const onDetach = (present: boolean) => {
		if (platformIsAndroid && externalBrowserInfo?.instanceId !== undefined)
		{
			postVuplexMessage('Enable hotkeys', { value: true });
			if (externalBrowserInfo.instanceId !== '')
				sendWebControlMsgToInstance('SetPresentationActive', externalBrowserInfo.instanceId, { active: false });
			postVuplexMessage('WebControl.Close', { instanceId: externalBrowserInfo.instanceId });
			apollo.writeQuery({
				query: queries.externalBrowserInfo,
				data: {
					externalBrowserInfo: {
						__typename: 'ExternalBrowserInfo',
						instanceId: '',
						sharing: false
					}
				}
			});
		}

		const params = new URLSearchParams(locationState as Record<string, string> ?? {});

		sendWebControlMsg('DetachContent',
			{
				present: present,
				controlSearchParams: params.toString()
			});

		// Clear previous state, as it is no longer accurate
		// (Should the client do this instead?)
		apollo.writeQuery<{ extBrowserState: BrowserState }>({
			query: queries.extBrowserState,
			data: {
				extBrowserState: {
					url: '',
					title: '',
					sharing: false
				}
			},
			variables: {
				instanceId: instanceId
			}
		});

		postVuplexMessage('Close tablet', null);
		navigate('/');
	}

	const saveLatestUrl = () => {
		apollo.writeQuery({
			query: queries.browserLatestUrl,
			data: {
				browserLatestUrl: stateRes.data?.extBrowserState.url,
			},
			variables: {
				instanceId: instanceId,
				appId: title
			}
		});
	}

	const activateOverrideDialog = (shouldShare: boolean) => {
		dialogContext.addDialog(<InfoDialogTemplate 
			header={'Replace external browser'}
			message={`You already have an external web browser open. 
			
			${shouldShare ? 'Do you want to replace it with this content and start presenting?' : 'Do you want to replace it with this content?'}`}
			callbacks={[
				{ callback: cancelOverride, label: 'Cancel' },
				{ callback: () => overrideBrowser(shouldShare), color: "primary", label: 'OK' }
			]}
		/>)
		postVuplexMessage('WebControl.HideContent', { instanceId: instanceId });
	}

	const onPresent = (value: boolean) => {
		if (externalTablet) {
			setPresentation(value);
		}
		else if (externalBrowserInfo?.instanceId === '' || (externalBrowserInfo?.sharing ?? true) || !platformIsAndroid) {
			onDetach(true);
		}
		else {
			activateOverrideDialog(true);
		}
	}

	useEffect(() => {
		setDownloadMenuVisibility(downloadMenuOpen);
	}, [downloadMenuOpen]);

	useEffect(() => {
		setExtBrowserVisibility(!(uploadMenuOpen || sttModalOpen || !initialized));
	}, [uploadMenuOpen, sttModalOpen, apollo, initialized]);

	useEffect(() => {
		sendWebControlMsg('SpeechRecognitionState', { active: sttRunning });
	}, [apollo, sttRunning]
	);

	useEffect(() => {
		if (locationState?.appurl !== stateRes.data?.extBrowserState.url) {
			sendWebControlMsg('OpenUrl', { url: locationState?.appurl });
		}

		if (!externalTablet) {
			postVuplexMessage('Open tablet', null);
		}

		// Fake loading time
		const fakeLoadingTimeout = setTimeout(() => {
			setInitialized(true);
			setExtBrowserVisibility(true);
		}, 2500);

		return () => {
			if (fakeLoadingTimeout) {
				clearTimeout(fakeLoadingTimeout);
			}
			setExtBrowserVisibility(false);
		}
	}, []);

	useEffect(() => {
		setUrlInputValue(undefined);
		setBookmarked(false);
		if (initialized) {
			saveLatestUrl();
		}
	}, [stateRes.data?.extBrowserState.url, initialized]);

	useEffect(() => {
		if (!externalTablet || !platformIsAndroid)
			return;

		apollo.writeQuery({
			query: queries.externalBrowserInfo,
			data: {
				externalBrowserInfo: {
					__typename: 'ExternalBrowserInfo',
					instanceId: instanceId,
					sharing: sharing
				}
			}
		});
	}, [sharing]);


	return (
		<div className={classes.root}>
			<AppHeader
				selected={false}
				hideBackButton={externalTablet}
				title={title}
				children={
					<SwitchSelector
						text="Present"
						onChange={onPresent}
						uiAudioMessage={sharing ? stopPresentingAudioMessage : presentAudioMessage}
						uiHoverAudioMessage={sharing ? stopPresentingHoverAudioMessage : presentHoverAudioMessage}
						checked={stateRes?.data?.extBrowserState?.sharing ?? false}
						disabled={!initialized}
					/>
				}
				secondChildren={
					<div className={classes.appHeaderButtons}>
						{externalTablet ? (
							<CloseButton disabled={!initialized} onClose={() => tryClose()} />
						) : (
							<GlueButton
								disabled={!initialized}
								variant='icon' 
								onPointerDown={() => (externalBrowserInfo?.instanceId === '' || !platformIsAndroid) ? onDetach(false) : activateOverrideDialog(false)}
							>
								<GlueIcon>
									<LaunchIcon />
								</GlueIcon>
							</GlueButton>
						)}
					</div>
				}
			/>
			<div className={classes.header}>
				<GlueButton variant='icon' onClick={() => sendWebControlMsg('GoBack')} disabled={!canGoBack} >
					<GlueIcon>
						<ArrowBackIosSharpIcon />
					</GlueIcon>
				</GlueButton>
				<GlueButton variant='icon' onClick={() => sendWebControlMsg('GoForward')} disabled={!canGoForward} >
					<GlueIcon>
						<ArrowForwardIosSharpIcon />
					</GlueIcon>
				</GlueButton>
				<GlueButton variant='icon' onClick={() => sendWebControlMsg('Reload')}>
					<GlueIcon>
						<RefreshSharpIcon />
					</GlueIcon>
				</GlueButton>
				{showBrowserControls && (
					<div className={classes.browserControls}>
						<div className={classes.inputField}>
							<GlueInputfield
								delayInSeconds={0.3}
								maxLength={2048}
								width={'100%'}
								value={urlInputValue ?? stateRes.data?.extBrowserState.url}
								onChange={setUrlInputValue}
								onSubmit={onSubmitUrl}
								sinkId={webControlSTTSinkId}
								onSpeechChange={(value: string) => { setUrlInputValue(value ? value : ''); }}
								showClearButton
								onClear={(value: string) => { setUrlInputValue(value ? value : ''); }}
							/>
						</div>
						<GlueToggle
							toggled={bookmarked}
							disabled={!stateRes.data || !user.team?.teamFilesEditOwned}
							onPointerDown={bookmarked ? removeBookmark : bookmarkCurrentUrl}
						>
							<GlueIcon>
								{bookmarked ? <BookmarkIcon /> : <BookmarkBorderIcon />}
							</GlueIcon>
						</GlueToggle>
						<GlueButton
							variant='icon'
							onPointerDown={() => navigate('../browser')}
						>
							<GlueIcon>
								<BookmarksIcon />
							</GlueIcon>
						</GlueButton>
					</div>
				)}
			</div>
			<div className={classes.webContent}>
				{!initialized && (
					<LoadingIndicator />
				)}
			</div>
			<div className={classes.footer}>
				<GlueButton variant='icon' onClick={() => sendWebControlMsg('ZoomOut')}>
					<GlueIcon>
						<ZoomOutIcon />
					</GlueIcon>
				</GlueButton>
				<GlueButton variant='icon' onClick={() => sendWebControlMsg('ZoomIn')}>
					<GlueIcon>
						<ZoomInIcon />
					</GlueIcon>
				</GlueButton>
				<div className={classes.titleBox}>
					{!!initialized && (
						<div className={classes.title}>
							<img className={classes.icon} src={`https://s2.googleusercontent.com/s2/favicons?domain=${stateRes.data?.extBrowserState.url}&sz=64`} alt='' />
							<p>{stateRes.data?.extBrowserState.title}</p>
						</div>
					)}
				</div>
				<GlueButton
					disabled={captureScreenshotActive}
					variant='icon'
					onClick={() => sendWebControlMsg('CaptureScreenshot', { teamId: currentTeamId })}
					uiAudioMessage={takeScreenshotAudioMessage}
				>
					{captureScreenshotActive && (
						<LoadingIndicator variant='icon' />
					)}
					<GlueIcon>
						<PhotoCameraIcon />
					</GlueIcon>
				</GlueButton>
			</div>
			<DownloadMenu
				open={downloadMenuOpen}
				instanceId={instanceId ?? undefined}
				fileUrl={downloadRes.data?.extBrowserLatestDownloadedFile}
				onClose={() => setDownloadMenuVisibility(false)}
			/>
			<UploadMenu
				open={uploadMenuOpen}
				state={uploadRes.data?.extBrowserFileUploadState}
			/>
			<SpeechToTextModal />
		</div>
	);
}

export default WebControl;
