import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import queries from '../../graphql/queries';

import Version from '../version';

import GlueLogo_WebLogin from '../../images/GlueLogo_WebLogin.png';

const useStyles = makeStyles(theme => ({
	root: {
		margin: 'auto',
	},

	about: {
		width: '1030px',
		margin: 'auto',
		background: theme.palette.background.paper,
		borderRadius: '44px',
		marginTop: '64px',
		padding: '64px 72px 64px 72px',

		'&> h3': {
			marginTop: '50px',
			marginBottom: '0px',
			textAlign: 'center',
		}
	},

	logo: {
		display: 'block',
		margin: 'auto',
	},

	title: {
		marginBottom: '21px',
		marginTop: '48px',
	},

	content: {
		width: '886px',
		margin: 'auto',
	},

	row: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: '40px',

		'&> p': {
			opacity: '70%',
			padding: '15px 0px',
			marginBottom: '12px',
			marginTop: '0px',
		}
	},

	software: {
		marginTop: '60px',

		'&> p': {
			opacity: '70%',
			padding: '15px 0px',
			marginBottom: '12px',
			marginTop: '0px',
		}
	},

}));

const About = (props) => {
	const classes = useStyles(props);
	const clientVersion = useQuery(queries.clientVersion);
	const clientHash = useQuery(queries.clientHash);

	return (
		<div className={classes.root}>
			<div className={classes.about}>
				<img className={classes.logo} src={GlueLogo_WebLogin} alt={"Glue Collaboration"}/>
				<h3>Glue is a modern collaboration platform uniquely combining cloud and edge computing with immersive 3D graphics and virtual reality technology. It is intended for business professionals who need remote collaboration to be as efficient as face-to-face meetings.</h3>
			</div>
			<div className={classes.content}>
				<h1 className={classes.title}>Version</h1>
				<div className={classes.row}>
					<p>Your current Glue client version: </p>
					<div><p>{clientVersion.data.clientVersion}-{clientHash.data.clientHash}</p></div>
				</div>
				<div className={classes.row}>
					<p>Your current GlueOS version: </p>
					<div><Version /></div>
				</div>
				<div className={classes.software}>
					<p>This software uses libraries from the FFmpeg project under the LGPLv2.1</p>
				</div>
			</div>
		</div>
	)
};

export default About;
