import React from "react";

const SiemensPrivacyPolicyStatement = () => {
		return (
			<div>
				<div>
					<div>
						<div>
							<div>
								<p>
									June 2022
									<br />
									<br />
									We (meaning the specific company identified as being the
									operator of the website that directed you to this Privacy
									Notice) believe that protecting the security and privacy of
									your personal data is important. This Privacy Notice
									explains how we collect, store, use, disclose and transfer
									(hereinafter “process”) your personal data. The personal
									data that we collect about you depends on the context of
									your interactions with us, the products. services and
									features that you use, your location, and applicable law.
									<br />
								</p>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div>
						<div>
							<div>
								<div>
									<h2>
										<br />
										1. Processing of personal data related to your use of our websites, applications and online services
										<br />
									</h2>
									<br />
								</div>
								<div>
									<div>
										<div>
											<div>
												<div>
													<div>
														<p>
															<strong>
																Categories of personal data
																processed, purpose of the
																processing
																<br />
															</strong>
														</p>
														<p>
															When visiting our websites or using
															our applications, or online
															services (each an “                                            <strong>Online Offering</strong>”),
															we process information which you
															have actively and voluntarily
															provided about yourself, or which
															has been generated by us in
															connection with your use of the
															Online Offerings, and includes the
															following categories of personal
															data:
														</p>
														<ul>
															<li>
																Your contact information,
																including name, e-mail address,
																telephone number;
															</li>
															<li>
																Further personal data that you
																provide by filling in forms in
																our Online Offerings;
															</li>
															<li>
																Information submitted as part
																of a support request, survey or
																comment or forum post; and
															</li>
															<li>
																Information on your interaction
																with the Online Offering,
																including your device and user
																identifier, information on your
																operating system, sites and
																services accessed during your
																visit, the date and time of
																each visitor request.
															</li>
														</ul>
														<p>
															We process your personal data for
															the following purposes:
														</p>
														<ul>
															<li>
																To provide the Online
																Offering&apos;s services and
																functions which includes
																creating and administering your
																online account, updating,
																securing, and troubleshooting,
																providing support, as well as
																improving and developing our
																Online Offerings;
															</li>
															<li>
																To bill your use of the Online
																Offering;
															</li>
															<li>
																To verify your identity;
															</li>
															<li>
																To answer and fulfill your
																requests or instructions;
															</li>
															<li>
																To process your order or to
																provide you with access to
																specific information or offers;
															</li>
															<li>
																To contact you with information
																and offers concerning our
																products and services, to send
																you further marketing
																information or to contact you
																in the context of customer
																satisfaction surveys as
																explained in Section 3; and
															</li>
															<li>
																As reasonably necessary to
																enforce the Online Offering&apos;s
																terms, to establish or preserve
																a legal claim or defense, to
																prevent fraud or other illegal
																activities, including attacks
																on our information technology
																systems.
															</li>
														</ul>
														<p>
															<strong>
																Cookies
																<br />
																<br />
															</strong>
															Our Online Offerings may use
															cookies, i.e., small files
															containing certain information that
															are stored on your device. If used
															by us without your consent, these
															cookies are strictly necessary to
															provide certain functionalities of
															an Online Offering to you or to
															provide you with a service that you
															requested via the Online Offering.
															Other cookies (e.g., cookies for
															marketing purposes) will only be
															used if you have given your
															consent.
														</p>
														<p>
															<strong>
																Online Offerings provided by
																your organization
																<br />
																<br />
															</strong>
															Our Online Offerings may be
															provided to you for your use by the
															organization to which you belong,
															such as our enterprise customers.
															If your organization provides you
															with access to an Online Offering,
															our processing of personal data
															provided by or collected from you
															or your organization in connection
															with the Online Offering&apos;s content
															is performed under the direction of
															your organization and is subject to
															a data processing agreement between
															your organization and us. In such
															instance, your organization is
															responsible for any personal data
															contained in such content and you
															should direct any questions about
															how personal data contained in such
															content is used to your
															organization
														</p>
														<p>
															<strong>
																Links to other websites
																<br />
																<br />
															</strong>
															This Privacy Notice applies only to
															Online Offerings that link to this
															Privacy Notice and not to other
															websites or applications of our
															affiliates that have their own
															Privacy Notice or which are
															operated by third parties. This
															Privacy Notice also does not apply
															in situations where we only process
															information on behalf of our
															Business Partners, e.g., when we
															act as a web hosting or cloud
															provider. We may provide links to
															other websites and applications
															which we believe may be of interest
															to you. We are not responsible for
															the privacy practices of such other
															websites or applications
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div>
							<div>
								<div>
									<h2>
										<br />
										2. Processing of Personal data related to your business relationship with us
										<br />
									</h2>
									<br />
								</div>
								<div>
									<div>
										<div>
											<div>
												<div>
													<div>
														<p>
															<strong>
																Categories of personal data
																processed and purpose of the
																processing
															</strong>
														</p>
														<p>
															In the context of the business
															relationship with us, we may
															process the following categories of
															personal data of consumers and
															contact persons at (prospective)
															customers, suppliers, vendors and
															partners (each a “                                            <strong>Business Partner</strong>
															”):
														</p>
														<ul>
															<li>
																Contact information, such as
																full name, job position, work
																address, work telephone number,
																work mobile phone number, work
																fax number and work email
																address;
															</li>
															<li>
																Payment data, such as data
																necessary for processing
																payments and fraud prevention,
																including credit/debit card
																numbers, security code numbers
																and other related billing
																information;
															</li>
															<li>
																Further information necessarily
																processed in a project or
																contractual relationship with
																us or voluntarily provided by
																the Business Partner, such as
																personal data relating to
																orders placed, payments made,
																requests, and project
																milestones;
															</li>
															<li>
																Personal data collected from
																publicly available resources
																(including business and
																employment oriented social
																networks and websites),
																integrity data bases and credit
																agencies; and
															</li>
															<li>
																If legally required for
																Business Partner compliance
																screenings: date of birth, ID
																numbers, identity cards and
																information about relevant and
																significant litigation or other
																legal proceedings against
																Business Partners.
															</li>
														</ul>
														<p>
															We may process the personal data
															for the following purposes:
														</p>
														<ul>
															<li>
																Communicating with Business
																Partners about our products,
																services and projects, e.g. by
																responding to inquiries or
																requests or providing you with
																information about purchased
																products;
															</li>
															<li>
																Planning, performing and
																managing the (contractual)
																relationship with Business
																Partners; e.g. by performing
																transactions and orders of
																products or services,
																processing payments, performing
																accounting, auditing, billing
																and collection activities,
																arranging shipments and
																deliveries, facilitating
																repairs and providing support
																services;
															</li>
															<li>
																Administrating and performing
																market analysis, sweepstakes,
																contests, or other customer
																activities or events;
															</li>
															<li>
																Contacting you with information
																and offers concerning our
																products and services, sending
																you further marketing messages
																and conducting customer
																satisfaction surveys as
																explained in Section 3;
															</li>
															<li>
																Maintaining and protecting the
																security of our products,
																services and websites,
																preventing and detecting
																security threats, fraud or
																other criminal or malicious
																activities;
															</li>
															<li>
																Ensuring compliance with legal
																obligations (such as record
																keeping obligations), export
																control and customs, Business
																Partner compliance screening
																obligations (to prevent
																white-collar or money
																laundering crimes), and our
																policies or industry standards;
																and
															</li>
															<li>
																Solving disputes, enforce our
																contractual agreements and to
																establish, exercise or defend
																legal claims.
															</li>
														</ul>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div>
							<div>
								<div>
									<h2>
										<br />
										3. Processing of personal data for customer satisfaction surveys and for direct marketing
										<br />
									</h2>
									<br />
								</div>
								<div>
									<div>
										<div>
											<div>
												<div>
													<div>
														<p>
															Where and as permitted under
															applicable law, we may process your
															contact information for direct
															marketing purposes (e.g. trade show
															invitations, newsletters with
															further information and offers
															concerning our products and
															services) and to carry out customer
															satisfactions surveys, in each case
															also by e-mail. You may object to
															the processing of your contact data
															for these purposes at any time by
															writing to contact@siemens.com 
															or by using the opt-out mechanism
															provided in the respective
															communication you received.
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div>
							<div>
								<div>
									<h2>
										<br />
										4. Transfer and disclosure of personal data
										<br />
									</h2>
									<br />
								</div>
								<div>
									<div>
										<div>
											<div>
												<div>
													<div>
														<p>
															We may transfer your personal data
															to:
														</p>
														<ul>
															<li>
																other affiliated companies or
																third parties - e.g. sales
																partners or suppliers - in
																connection with your use of the
																Online Offerings or our
																business relationship with you;
															</li>
															<li>
																third parties which provide IT
																services to us and which
																process such data only for the
																purpose of such services (e.g.,
																hosting or IT maintenance and
																support services); and
															</li>
															<li>
																third parties in connection
																with complying with legal
																obligations or establishing,
																exercising or defending rights
																or claims (e.g., for court and
																arbitration proceedings, to
																regulators, law enforcement and
																government authorities, to
																attorneys and consultants).
															</li>
														</ul>
														<p>
															The recipients of your personal
															data may be located outside of the
															country in which you reside.
															Personal data published by you on
															Online Offerings (such as chat
															rooms or forums) may be globally
															accessible to other registered user
															of the respective Online Offering.
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div>
							<div>
								<div>
									<h2>
										<br />
										5. Retention Periods
										<br />
									</h2>
									<br />
								</div>
								<div>
									<div>
										<div>
											<div>
												<div>
													<div>
														<p>
															Unless indicated otherwise at the
															time of the collection of your
															personal data (e.g. within a form
															completed by you), we erase your
															personal data if the retention of
															that personal data is no longer
															necessary for the purposes for
															which they were collected or
															otherwise processed, or to comply
															with legal obligations (such as
															retention obligations under tax or
															commercial laws).
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div>
							<div>
								<div>
									<h2>
										<br />
										6.	Your rights
										<br />
									</h2>
									<br />
								</div>
								<div>
									<div>
										<div>
											<div>
												<div>
													<div>
														<p>
															The data protection laws in the
															jurisdiction in which you reside
															may entitle you to specific rights
															in relation to your personal data.
														</p>
														<p>
															In particular, and subject to the
															legal requirements, you may be
															entitled to
														</p>
														<ul>
															<li>
																Obtain from us confirmation as
																to whether or not personal data
																concerning you are being
																processed, and where that is
																the case, access to the
																personal data;
															</li>
															<li>
																Obtain from us the correction
																of inaccurate personal data
																concerning you;
															</li>
															<li>
																Obtain from us the erasure of
																your personal data;
															</li>
															<li>
																Obtain from us restriction of
																processing regarding your
																personal data;
															</li>
															<li>
																Data portability concerning
																personal data, which you
																actively provided; and;
															</li>
															<li>
																Object, on grounds relating to
																your particular situation, to
																further processing of personal
																data concerning you.
															</li>
														</ul>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div>
							<div>
								<div>
									<h2>
										<br />
										7. Security
										<br />
									</h2>
									<br />
								</div>
								<div>
									<div>
										<div>
											<div>
												<div>
													<div>
														<p>
															To protect your personal data
															against accidental or unlawful
															destruction, loss, use, or
															alteration and against unauthorized
															disclosure or access, we use
															adequate physical, technical and
															organizational security measures.
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div>
							<div>
								<div>
									<h2>
										<br />
										8. Data Privacy Contact
										<br />
									</h2>
									<br />
								</div>
								<div>
									<div>
										<div>
											<div>
												<div>
													<div>
														<p>
															Our Data Privacy Organization
															provides support with any data
															privacy related questions,
															comments, concerns or complaints or
															in case you wish to exercise any of
															your data privacy related rights.
															The Data Privacy Organization may
															be contacted at: dataprotection@siemens.com.
															<br />
														</p>
														<p>
															The Data Privacy Organization will
															always use reasonable efforts to
															address and settle any requests or
															complaints you bring to its
															attention. Besides contacting the
															Data Privacy Organization, you
															always have the right to approach
															the competent data protection
															authority with your request or
															complaint.
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div>
							<div>
								<div>
									<h2>
										<br />
										9. Processing under the EU&apos;s General Data Protection Regulation: controller, legal basis and international transfers
										<br />
									</h2>
									<br />
								</div>
								<div>
									<div>
										<div>
											<div>
												<div>
													<div>
														<p>
															This section applies and provides
															you with further information if
															your personal data is processed by
															one of our companies located in the
															European Economic Area.
														</p>
														<p>
															<strong>Data Controller</strong>
														</p>
														<p>
															The specific company identified on
															this page as being the operator of
															this website is the data controller
															in the meaning of the General Data
															Protection Regulation for the
															processing activities described in
															this Privacy Notice.
														</p>
														<p>
															In the course of our business
															relationship with you, we may share
															Business Partner contact
															information with affiliated Siemens
															companies. We and these Siemens
															companies are jointly responsible
															for the proper protection of your
															personal data (Art. 26 General Data
															Protection Regulation). To allow
															you to effectively exercise your
															data subject rights in the context
															of this joint controllership, we
															entered into an agreement with
															these Siemens companies granting
															you the right to centrally exercise
															your data subject rights under
															section 6 of this Privacy Notice
															against Siemens Aktiengesellschaft,
															Germany.
														</p>
														<p>
															To exercise your rights, you may
															reach out to: dataprotection@siemens.com.
														</p>
														<p>
															<strong>
																Legal basis of the processing
															</strong>
														</p>
														<p>
															The General Data Protection
															Regulation requires us to provide
															you with information on the legal
															basis of the processing of your
															personal data.
														</p>
														<p>
															The legal basis for our processing
															data about you is that such
															processing is necessary for the
															purposes of
														</p>
														<ul>
															<li>
																exercising our rights and
																performing our obligations
																under any contract we make with
																you (Article 6 (1) (b) General
																Data Protection Regulation) (“
																<strong>
																	Contract Performance
																</strong>
																”);
															</li>
															<li>
																Compliance with our legal
																obligations (Article 6 (1) (c)
																General Data Protection
																Regulation) (“
																<strong>
																	Compliance with Legal
																	Obligations
																</strong>
																”); and/or
															</li>
															<li>
																Legitimate interests pursued by
																us (Article 6 (1) (f) General
																Data Protection Regulation) (“
																<strong>
																	Legitimate Interest
																</strong>
																”). Generally, the legitimate
																interest pursued by us in
																relation to our use of your
																personal data is the efficient
																performance or management of
																(i) your use of the Online
																Offerings, and/or (ii) our
																business relationship with you.
																Where the below table states
																that we rely on our legitimate
																interest for a given purpose,
																we are of the opinion that our
																legitimate interest is not
																overridden by your interests
																and rights or freedoms, given
																(i) the regular reviews and
																related documentation of the
																processing activities described
																herein, (ii) the protection of
																your personal data by our data
																privacy processes, including
																our Binding Corporate Rules on
																the Protection of Personal
																Data (https://assets.new.siemens.com/siemens/assets/api/uuid:34016021-5967-4c51-bda0-c8a0368eebba/version:1560762303/summary-of-third-party-rights-en.pdf), (iii) the transparency we
																provide on the processing
																activity, and (iv) the rights
																you have in relation to the
																processing activity. If you
																wish to obtain further
																information on this balancing
																test approach, please contact
																our Data Privacy Organization
																at: dataprotection@siemens.com.
															</li>
														</ul>
														<p>
															In some cases, we may ask if you
															consent to the relevant use of your
															personal data. In such cases, the
															legal basis for us processing that
															data about you may (in addition or
															instead) be that you have consented
															(Article 6 (1) (a) General Data
															Protection Regulation) (“                                            <strong>Consent</strong>”).
														</p>
													</div>
												</div>
											</div>
										</div>
										<div>
											<div>
												<div>
													<div>
														<p>
															<u>
																<strong>Processing of</strong>
																<strong>
																	personal data in the
																	context of Online Offerings
																	- Purpose and Legal Basis
																</strong>
																<br />
															</u>
														</p>
														<p>
															<strong>
																To provide the Online
																Offering&apos;s services and
																functions which includes
																creating and administering your
																online account, updating,
																securing, and troubleshooting,
																providing support, as well as
																improving and developing our
																Online Offerings
															</strong>
														</p>
														<ul>
															<li>
																Contract Performance (Article 6
																(1) (b) General Data Protection
																Regulation)
															</li>
															<li>
																Legitimate Interest (Article 6
																(1) (f) GDPR)
															</li>
														</ul>
														<p>
															<strong>
																To bill your use of the Online
																Offering
															</strong>
														</p>
														<ul>
															<li>
																Contract Performance (Article 6
																(1) (b) General Data Protection
																Regulation)
															</li>
															<li>
																Legitimate Interest (Article 6
																(1) (f) GDPR
															</li>
														</ul>
														<p>
															<strong>
																To verify your identity
															</strong>
														</p>
														<ul>
															<li>
																Contract Performance (Article 6
																(1) (b) GDPR)
															</li>
															<li>
																Legitimate Interest (Article 6
																(1) (f) GDPR)
															</li>
														</ul>
														<p>
															<strong>
																To answer and fulfill your
																requests or instructions
															</strong>
														</p>
														<ul>
															<li>
																Contract Performance (Article 6
																(1) (b) GDPR)
															</li>
															<li>
																Legitimate Interest (Article 6
																(1) (f) GDPR)
															</li>
														</ul>
														<p>
															<strong>
																To process your order or to
																provide you with access to
																specific information or offers
															</strong>
														</p>
														<ul>
															<li>
																Contract Performance (Article 6
																(1) (b) GDPR)
															</li>
															<li>
																Legitimate Interest (Article 6
																(1) (f) GDPR)
															</li>
														</ul>
														<p>
															<strong>
																To send you marketing
																information or to contact you
																in the context of customer
																satisfaction surveys as further
																explained in Section 3
															</strong>
														</p>
														<ul>
															<li>
																Consent, if voluntarily
																provided (Article 6 (1) (a)
																GDPR)
															</li>
															<li>
																Legitimate Interest (Article 6
																(1) (f) GDPR)
															</li>
														</ul>
														<p>
															<strong>
																As reasonably necessary to
																enforce the Online Offering&apos;s
																terms, to establish or preserve
																a legal claim or defense, to
																prevent fraud or other illegal
																activities, including attacks
																on our information technology
																systems
															</strong>
														</p>
														<ul>
															<li>
																Compliance with Legal
																Obligations (Article 6 (1) (c)
																GDPR
															</li>
															<li>
																Legitimate Interest (Article 6
																(1) (f) GDPR)
															</li>
														</ul>
														<p>
															<u>
																<strong>
																	Processing of personal data
																	related to your business
																	relationship with us -
																	Purpose and Legal Basis
																</strong>
															</u>
														</p>
														<p>
															<strong>
																Communicating with Business
																Partners about our products,
																services and projects, e.g. by
																responding to inquiries or
																requests or providing you with
																technical information about
																purchased products
															</strong>
														</p>
														<ul>
															<li>
																Contract Performance (Article 6
																(1) (b) GDPR)
															</li>
															<li>
																Legitimate Interest (Article 6
																(1) (f) GDPR)
															</li>
														</ul>
														<p>
															<strong>
																Planning, performing and
																managing the (contractual)
																relationship with Business
																Partners; e.g. by performing
																transactions and orders of
																products or services,
																processing payments, performing
																accounting, auditing, billing
																and collection activities,
																arranging shipments and
																deliveries, facilitating
																repairs and providing support
																services;
															</strong>
														</p>
														<ul>
															<li>
																Contract Performance (Article 6
																(1) (b) GDPR)
															</li>
															<li>
																Compliance with Legal
																Obligations (Article 6 (1) (c)
																GDPR)
															</li>
														</ul>
														<p>
															<strong>
																Administrating and performing
																market analysis, sweepstakes,
																contests, or other customer
																activities or events;
															</strong>
														</p>
														<ul>
															<li>
																Consent, if voluntarily
																provided (Article 6 (1) (a)
																GDPR)
															</li>
															<li>
																Legitimate Interest (Article 6
																(1) (f) GDPR)
															</li>
														</ul>
														<p>
															<strong>
																Conducting customer
																satisfaction surveys and direct
																marketing activities as further
																explained in Section 3;
															</strong>
														</p>
														<ul>
															<li>
																Consent, if voluntarily
																provided (Article 6 (1) (a)
																GDPR)
															</li>
															<li>
																Legitimate Interest (Article 6
																(1) (f) GDPR)
															</li>
														</ul>
														<p>
															<strong>
																Maintaining and protecting the
																security of our products,
																services and websites,
																preventing and detecting
																security threats, fraud or
																other criminal or malicious
																activities;
															</strong>
														</p>
														<ul>
															<li>
																Legitimate Interest (Article 6
																(1) (f) GDPR)
															</li>
														</ul>
														<p>
															<strong>
																Ensuring compliance with legal
																obligations (such as record
																keeping obligations), export
																control and customs, Business
																Partner compliance screening
																obligations (to prevent
																white-collar or money
																laundering crimes), and our
																policies or industry standards;
																and
															</strong>
														</p>
														<ul>
															<li>
																Compliance with Legal
																Obligations (Article 6 (1) (c)
																GDPR)
															</li>
															<li>
																Legitimate Interest (Article 6
																(1) (f) GDPR)
															</li>
														</ul>
														<p>
															<strong>
																Solving disputes, enforce our
																contractual agreements and to
																establish, exercise or defend
																legal claims.
															</strong>
														</p>
														<ul>
															<li>
																Compliance with Legal
																Obligations (Article 6 (1) (c)
																GDPR)
															</li>
															<li>
																Legitimate Interest (Article 6
																(1) (f) GDPR)
															</li>
														</ul>
													</div>
												</div>
											</div>
										</div>
										<div>
											<div>
												<div>
													<div>
														<p>
															<strong>
																International data transfers
															</strong>
														</p>
														<p>
															In the event that we transfer your
															personal data outside the European
															Economic Area, we ensure that your
															data is protected in a manner which
															is consistent with the General Data
															Protection Regulation. Therefore,
															and if required by applicable law,
															we take the following measures:
														</p>
														<ul>
															<li>
																We share your personal data
																with affiliated companies
																outside the European Economic
																Area only if they have
																implemented our Binding
																Corporate Rules („BCR“) for the
																protection of personal data.
																Further information about the
																BCR can be found here 
																https://assets.new.siemens.com/siemens/assets/api/uuid:34016021-5967-4c51-bda0-c8a0368eebba/summary-of-third-party-rights-en.pdf.
															</li>
															<li>
																We transfer personal data to
																external recipients outside the
																European Economic Area only if
																the recipient has (i) entered
																into EU Standard Contractual
																Clauses (http://ec.europa.eu/justice/data-protection/international-transfers/transfer/index_en.html) 
																with us or (ii) implemented
																Binding Corporate Rules (http://ec.europa.eu/justice/data-protection/international-transfers/binding-corporate-rules/index_en.htm)
																in its organization. You may
																request further information
																about the safeguards
																implemented in relation to
																specific transfers by
																contacting dataprotection@siemens.com.
															</li>
														</ul>
														<p>
															<strong>
																Your competent data protection
																authority
															</strong>
														</p>
														<p>
															<strong> </strong>
														</p>
														<p>
															In case of data privacy related
															concerns and requests, we encourage
															you to contact our Data Privacy
															Organization at ataprotection@siemens.com. 
															Besides contacting the Data
															Privacy Organization, you always
															have the right to approach the
															competent data protection authority
															with your request or complaint.
														</p>
														<p>
															A list and contact details of local
															data protection authorities is
															available here (http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm).
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div>
							<div>
								<div>
									<h2>
										<br />
										10. Further information for US residents
										<br />
									</h2>
									<br />
								</div>
								<div>
									<div>
										<div>
											<div>
												<div>
													<div>
														<p>
															If you are a U.S. resident, then
															please take note of the following:
														</p>
														<p>
															<strong>Do Not Track</strong>
														</p>
														<p>
															At this time our Online Offerings
															do not recognize or respond to “Do
															Not Track” browser signals. For
															more information on “Do Not Track”,
															please visit your browser&apos;s support
															page.
														</p>
														<p>
															<strong>Usage by Children</strong>
														</p>
														<p>
															This Online Offering is not
															directed to children under the age
															of thirteen. We will not knowingly
															collect personal data from children
															under the age of thirteen without
															insisting that they seek prior
															parental consent if required by
															applicable law. We will only use or
															disclose personal data about a
															child to the extent permitted by
															law, to seek parental consent,
															pursuant to local law and
															regulations or to protect a child.
														</p>
														<p>
															<strong>State Rights</strong>
														</p>
														<p>
															Depending on the US state in which
															you reside, you may have special
															rights with respect to your
															personal data. For information
															regarding any of those rights,
															please click here (https://new.siemens.com/us/en/general/legal/us-internet-privacy-notice-state-rights.html).
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div>
							<div>
								<div>
									<h2>
										<br />
										11. Processing under the Brazilian General Data Protection Law
										<br />
									</h2>
									<br />
								</div>
								<div>
									<div>
										<div>
											<div>
												<div>
													<div>
														<p>
															This section applies and provides
															you with further information if the
															processing by one of our companies
															(i) occurs in Brazilian territory,
															(ii) concerns the data of
															individuals located in Brazilian
															territory, (iii) comprises personal
															data collected in Brazilian
															territory or (iv) has as its
															objective the offer or supply of
															goods or services to individuals
															located in Brazilian territory. In
															these cases the Brazilian General
															Data Protection Law (Lei Geral de
															Proteção de Dados - LGPD) applies
															to the processing of your personal
															data and the following additions
															and/or deviations apply to sections
															2, 5, 6, 9, of this Data Privacy
															Notice:
														</p>
														<p>
															<strong>Retention Periods</strong>
														</p>
														<p>
															As allowed under article 16 of LGPD
															we may retain your personal data to
															comply with legal or regulatory
															obligations (such as retention
															obligations under tax or commercial
															laws), during the legal statute of
															limitation period, or for the
															regular exercise of rights in
															judicial, administrative or
															arbitration proceedings.
														</p>
														<p>
															<strong>Your rights</strong>
														</p>
														<p>
															Additionally to the rights
															mentioned in this Data Privacy
															Notice, you are entitled under LGPD
															to:
														</p>
														<ul>
															<li>
																In case you understand your
																data is not being processed in
																accordance with the applicable
																data protection law or in an
																excessive way, request us to
																anonymize, block or delete
																unnecessary or excessive
																personal data or;
															</li>
															<li>
																Request information regarding
																the public and/or private
																entities we shared your
																personal data with;
															</li>
															<li>
																Be informed about the
																possibility of not giving your
																consent to process your data
																and the consequences of not
																giving the consent in case we
																request your consent to process
																your data;
															</li>
															<li>
																Revoke at any time your consent
																to our processing of your
																personal data in case we
																request your consent to process
																your data
															</li>
														</ul>
														<p>
															<strong>
																Legal basis of the processing
															</strong>
														</p>
														<p>
															The Brazilian General Data
															Protection Law requires us to
															provide you with information on the
															legal basis of the processing of
															your personal data.
														</p>
														<p>
															The legal basis for our processing
															is:
														</p>
														<ul>
															<li>
																Article 7 V LGPD (“Contract
																Performance”);
															</li>
															<li>
																Article 7 II LGPD (“Compliance
																with Legal Obligations”);
															</li>
															<li>
																Article 10 I and II LGPD
																(“Legitimate Interest”).
															</li>
															<li>
																Article 7 I LGPD (“Consent”).
															</li>
														</ul>
														<p>
															<strong>
																International transfers
															</strong>
														</p>
														<p>
															Following the LGPD requirements
															defined in the Article 33 of
															Brazilian General Data Protection
															Law, in the event that we transfer
															your personal data outside the
															Brazilian territory, we ensure that
															your data is protected in a manner
															which is consistent with the
															Brazilian General Data Protection
															Law, we will follow the applicable
															law and decisions imposed by the
															proper authority.
														</p>
														<p>
															<strong>
																Your competent data protection
																contact
															</strong>
														</p>
														<p>
															If this section applies, you may
															also contact our Brazilian Data
															Privacy Organization at
															dataprivacy.br@siemens.com.
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div>
							<div>
								<div>
									<h2>
										<br />
										12. Processing under South Africa&apos;s Protection of Personal Information Act
										<br />
									</h2>
									<br />
								</div>
								<div>
									<div>
										<div>
											<div>
												<div>
													<div>
														<p>
															For Business Partners and users
															located in South Africa, please
															take note of the following:
														</p>
														<p>
															In terms of section 1 of the
															Protection of Personal Information
															Act, 2013 (“POPI”), “personal data”
															or “personal information” includes
															“information relating to an
															identifiable, living, natural
															person, and where it is applicable,
															an identifiable, existing, juristic
															person.”
														</p>
														<p>
															The corresponding legal grounds and
															conditions for lawful processing of
															personal data in South Africa are
															contained in Sections 8 to 25 of
															POPI, and relate to
															“Accountability”; “Processing
															limitation”; “Purpose
															specification”; “Further processing
															limitation”; “Information quality”;
															“Openness”; “Security safeguards”
															and “Data subject participation”.
														</p>
														<p>
															In terms of section 69 of POPI, the
															processing of personal information
															of a data subject for the purposes
															of direct marketing by means of any
															form of electronic communication,
															including automatic calling
															machines, facsimile machines, sms&apos;s
															or e-mail is prohibited unless the
															data subject has provided consent
															to the processing, or is, subject
															to further conditions, an existing
															customer of the responsible party.
														</p>
														<p>
															For purposes of a Data Subject
															exercising its rights further
															enquiries and the exercise of its
															rights in relation to access,
															objection to, and complaints in
															respect of the processing of
															personal data, the contact
															particulars of the Information
															Regulator of South Africa, are as
															follows:
														</p>
														<p>
															JD House, 27 Stiemens Street
														</p>
														<p>
															Braamfontein
														</p>
														<p>
															Johannesburg
														</p>
														<p>
															2001
														</p>
														<p>
															PO Box 31533
														</p>
														<p>
															Braamfontein
														</p>
														<p>
															Johannesburg
														</p>
														<p>
															2017
														</p>
														<p>
															Complaints:
															complaints.IR@justice.gov.za
														</p>
														<p>
															General enquiries:
															inforeg@justice.gov.za
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div>
							<div>
								<div>
									<h2>
										<br />
										13. Processing under People&apos;s Republic of China Personal Information Protection Law
										<br />
									</h2>
									<br />
								</div>
								<div>
									<div>
										<div>
											<div>
												<div>
													<div>
														<p>
															This section applies and provides
															you with further information if the
															processing by one of our companies
															is located within the borders of
															People&apos;s Republic of China (“PRC”)
															or concerns the data of individuals
															within the borders of PRC.
														</p>
														<p>
															<strong>
																Processing of sensitive
																personal information
															</strong>
														</p>
														<p>
															According to the PIPL, sensitive
															personal information means personal
															information that, once leaked or
															illegally used, may easily cause
															harm to the dignity of natural
															persons grave harm to personal or
															property security, including
															information on biometric
															characteristics, religious beliefs,
															specially-designated status,
															medical health, financial accounts,
															individual location tracking, etc.
															as well as the personal information
															of minors under the age of 14.
														</p>
														<p>
															In addition to the payment data
															mentioned in section 2 of this Data
															Private Notice, we will, in
															principle, not process your
															sensitive personal information. In
															case your sensitive personal
															information will be proceed, we
															will notify you about the necessity
															of processing and effects on the
															individual&apos;s rights and interests,
															and obtain your specific consent if
															applicable.
														</p>
														<p>
															<strong>
																Transfer and disclosure of
																personal data
															</strong>
														</p>
														<p>
															Following the requirements defined
															in the Article 23 of PIPL,
															additionally to the contents
															mentioned in section 4, we, in
															principle, will not transfer or
															share your personal information to
															third party controllers, unless (1)
															obtain your specific consent if
															applicable, or (2) to fulfill the
															statutory duties under local laws
															and regulations.
														</p>
														<p>
															<strong>
																International Transfer
															</strong>
														</p>
														<p>
															You acknowledge that your data will
															be transferred and proceed outside
															of PRC. We will follow the
															applicable laws and decisions
															imposed by the competent authority
															and ensure that your data is
															protected in a manner which is
															consistent with the PRC Personal
															Information Protection Law. If you
															or the company you work for is a
															Business Partner, please be aware
															that Siemens is a multi-national
															company, and for the purpose of
															concluding or fulfilling the
															contract/agreement with you or the
															company you work for, you
															understand and agree that we may
															transfer your personal information
															to foreign affiliated companies.
														</p>
														<p>
															<strong>
																Legal Basis of the processing
															</strong>
														</p>
														<p>
															The PIPL requires us to provide you
															with information on the legal basis
															of the processing of your personal
															data.
														</p>
														<p>
															The legal basis for our processing
															is:
														</p>
														<ul>
															<li>
																PIPL Article 13(2) (“Contract
																Performance”);
															</li>
															<li>
																PIPL Article 13(3) (“Statutory
																duties and responsibilities”)
															</li>
															<li>
																PIPL Article 13(6) (“Process
																publicly available data”);
															</li>
															<li>
																PIPL Article 13(1) (“Consent”)
															</li>
														</ul>
														<p>
															<strong>Usage by Children</strong>
														</p>
														<p>
															This Online Offering is not
															directed to children under the age
															of fourteen (14). We will not
															knowingly collect personal data
															from children under the age of
															fourteen (14) without prior
															parental consent if required by
															applicable law. We will only use or
															disclose personal data about a
															child to the extent permitted by
															law, to seek parental consent,
															pursuant to local law and
															regulations or to protect a child.
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
};

export default SiemensPrivacyPolicyStatement