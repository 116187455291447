import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import GlueCard from '../common/glue-card'
import PariticipantCountIndicator from '../participant-count-indicator';

import { usePromptDialogContext } from '../../util/prompt-dialog-context';
import GotoSpaceDialog from './goto-space-dialog';
import SpaceSettingsDialog from './space-settings-dialog';

import SpaceThumbnailPlaceholder from '../../images/SpaceThumbnail_Placeholder.png'
import { Room, Session, SpaceTemplateDownloadState } from '../../graphql/types-generated';
import GlueEllipsisMenu from '../common/glue-ellipsis-menu';
import GlueMenuItem from '../common/glue-menu-item';
import { useUserContext } from '../../util/user-context';
import ResetSpaceDialog from './reset-space-dialog';
import ResetSessionJournalDialog from './reset-session-journal-dialog';
import DeleteSpaceDialog from './delete-space-dialog';
import ForceStopSessionDialog from "./force-stop-session-dialog";
import { useQuery } from '@apollo/client';
import queries from '../../graphql/queries';

import DoneIcon from '@material-ui/icons/Done';
import GetAppIcon from '@material-ui/icons/GetApp';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import GlueIcon from '../common/glue-icon';
import { LinearProgress } from '@material-ui/core';
import GlueButton from '../common/glue-button';
import postVuplexMessage from '../../service/message-vuplex';

const useStyles = makeStyles((theme) => ({
	actions: {
		display: 'flex',
		justifyContent: 'space-between'
	},

	infoOverlay: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: `calc(${theme.custom.glueCard.size} / 2)`,
		display: 'flex',
		flexFlow: 'column',
		alignItems: 'center',
		justifyContent: 'flex-end',
		overflow: 'hidden',
		pointerEvents: 'none',
	},

	error: {
		width: '100%',
		background: theme.palette.error.dark,
		color: theme.palette.error.contrastText,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		gap: theme.glueSpacing('s'),
		padding: theme.glueSpacing('xs'),

		'&>.MuiSvgIcon-root': {
			fontSize: '24px',
		},
	},

	cachedIcon: {
		color: '#1cb458',
	},

	downloadProgress: {
		width: '100%',
		padding: theme.glueSpacing('s'),
	},
}), { name: 'MuiGlueSpaceTile' });

const SpaceTile = (props: {
	teamId: string
	roomInfo: Room
	web?: boolean
	refetch: () => void
	currentRoomServerKey: string
	cachedTemplateUrls?: string[]
	templateDownloadState?: SpaceTemplateDownloadState
}) =>
{
	const classes = useStyles();
	const user = useUserContext();
	const { addDialog } = usePromptDialogContext();
	const [ menuOpen, setMenuOpen ] = useState<boolean>(false);

	const sessionRes = useQuery(queries.sessionInfo, {
		skip: !props.roomInfo.serverKey,
		variables: {
			roomId: props.roomInfo.roomId
		},
	fetchPolicy: "network-only"
	});
	const session: Session | undefined = sessionRes.data?.sessionInfo;

	const roomInfo = props.roomInfo;
	const thumbnailUrl = roomInfo?.thumbnailUrl ? roomInfo.thumbnailUrl : SpaceThumbnailPlaceholder;
	const participantCount = session && session.participants ? session.participants.length : 0;
	const sceneUrl = roomInfo.sceneUrl ?? '';
	const templateCached = !!props.cachedTemplateUrls && !!sceneUrl && props.cachedTemplateUrls.some(url => url === sceneUrl);
	const downloadStatus = !!props.templateDownloadState ? props.templateDownloadState.status : undefined;
	const downloadingThisTemplate = !!props.templateDownloadState && props.templateDownloadState?.sceneUrl.localeCompare(sceneUrl) === 0;
	const downloadProgress = props.templateDownloadState?.progress ?? 0;
	const menuVisible = user.administrator || user.team?.spaceEdit;

	const handleOpen = () =>
	{
		addDialog(<GotoSpaceDialog 
			web={props.web}
			thumbnailUrl={thumbnailUrl}
			roomInfo={roomInfo}
			currentRoomServerKey={props.currentRoomServerKey}
		/>);
	};

	const downloadTemplate = () =>
	{
		postVuplexMessage('Space.DownloadTemplate', sceneUrl);
	}

	const CacheStatus = () => 
	(
		<>
			{templateCached ? (
				<div className={classes.cachedIcon}>
					<GlueIcon>
						<DoneIcon />
					</GlueIcon>
				</div>
			) : (
				<GlueButton
					variant='icon'
					color='stealth'
					onPointerDown={downloadTemplate}
					disabled={downloadStatus === 'downloading'}
				>
					<GlueIcon>
						<GetAppIcon />
					</GlueIcon>
				</GlueButton>
			)}
		</>
	)

	return (
		<GlueCard
			image={thumbnailUrl}
			active={props.currentRoomServerKey === roomInfo?.serverKey}
			title={roomInfo?.name}
			onPointerDown={() => handleOpen()}
		>
			<div className={classes.actions}>
				<PariticipantCountIndicator participantCount={participantCount} />
				{!props.web && <CacheStatus />}
				{menuVisible ? (
					<GlueEllipsisMenu vertical color='stealth' open={menuOpen} onToggleOpen={() => setMenuOpen(!menuOpen)}>
						<GlueMenuItem 
							disabled={!sessionRes.data}
							onPointerDown={() => {
								addDialog(<SpaceSettingsDialog
									roomInfo={props.roomInfo}
									session={session}
									teamId={props.teamId}
									web={props.web}
									refetch={props.refetch}
								/>);
								setMenuOpen(false);
							}}
						>
							Edit
						</GlueMenuItem>
						<GlueMenuItem 
							disabled={!sessionRes.data || (!!session &&  session.sessionState !== "Stopped")}
							onPointerDown={() => { 
								addDialog(<ResetSpaceDialog roomId={props.roomInfo.roomId} />);
								setMenuOpen(false);
							}}
						>
							Reset
						</GlueMenuItem>
						<GlueMenuItem 
							disabled={!sessionRes.data || (!!session &&  session.sessionState !== "Stopped")}
							onPointerDown={() => {
								addDialog(<DeleteSpaceDialog roomId={props.roomInfo.roomId} onSuccess={props.refetch} />);
								setMenuOpen(false);
							}}
						>
							Delete
						</GlueMenuItem>
						{user.administrator && 
							<GlueMenuItem 
								disabled={!sessionRes.data || !session || session?.sessionState === "Stopped"}
								onPointerDown={() => {
									addDialog(<ForceStopSessionDialog serverKey={props.roomInfo.serverKey ?? ''} status={session?.sessionState} />);
									setMenuOpen(false);
								}}
							>
								Force Stop
							</GlueMenuItem>
						}
						{user.administrator && 
							<GlueMenuItem 
								disabled={!sessionRes.data || (!!session &&  session.sessionState !== "Stopped")}
								onPointerDown={() => { 
									addDialog(<ResetSessionJournalDialog roomId={props.roomInfo.roomId} />);
									setMenuOpen(false);
								}}
							>
								Reset Space Journal
							</GlueMenuItem>
						}
					</GlueEllipsisMenu>
				) : (<GlueIcon />)}
			</div>
			<div className={classes.infoOverlay}>
				{downloadingThisTemplate && downloadStatus !== 'finished' && (
					<>
						{downloadStatus === 'failed' ? (
							<div className={classes.error}>
								<ErrorOutlineIcon />
								<p>{props.templateDownloadState?.error?.includes('corrupted') ? 'Validation failed' : 'Download failed'}</p>
							</div>
						) : (
							<div className={classes.downloadProgress}>
								<LinearProgress
									variant='determinate'
									value={downloadProgress}
								/>
							</div>
						)}
					</>
				)}
			</div>
		</GlueCard>
	);
};

export default SpaceTile;
