import React from 'react';

import GlueButton from './glue-button';
import { Close } from '@material-ui/icons';

type CloseButtonProps = {
	onClose?: (e: React.PointerEvent) => void
	disabled?: boolean
	uiAudioMessage?: string
	uiHoverAudioMessage?: string
}

const CloseButton = (props: CloseButtonProps) =>
{
	const onClose = (e: React.PointerEvent) =>
	{
		if (props.onClose)
			props.onClose(e);
	}

	return (
		<GlueButton 
			variant='icon' 
			color='stealth' 
			disabled={props.disabled}
			onPointerDown={onClose} 
			uiAudioMessage={props.uiAudioMessage}
			uiHoverAudioMessage={props.uiHoverAudioMessage}
		>
			<Close/>
		</GlueButton>
	);
}

export default CloseButton;
