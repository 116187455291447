import queries from "../graphql/queries";

/**
 * @param {ApolloClient} apollo Apollo client instance
 * @param {string} statusItemId
 * @returns 
 */
const checkStatusItem = async (apollo, statusItemId) =>
{
	const statusRes = await apollo.query({
		query: queries.processingStatusInfo,
		fetchPolicy: 'network-only',
		errorPolicy: 'all',
		variables: {
			statusItemId: statusItemId
		}
	}).catch(e => {
		console.error("Status item query failed", e);
		return null;
	});

	return statusRes?.data?.processingStatusInfo ?? null;
};

export default checkStatusItem;
