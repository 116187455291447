import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';

/* Example:
 * 
 * // The name does not really matter here as long as it makes sense
 * import SpinnySquareAnimData from '../animation/spinny-square.json';
 * import AnimatedGraphic from './common/animated-graphic';
 * ...
 * <AnimatedGraphic data={SpinnySquareAnimData} />
 */

const AnimatedGraphic = (props) =>
{
	const containerRef = useRef();
	
	useEffect(() => {
		const animItem = lottie.loadAnimation({
			container: containerRef.current,
			renderer: 'canvas',
			animationData: props.data,
			loop: props.loop
		});

		if (props.onComplete) {
			animItem.addEventListener("complete", props.onComplete);
		}

		return () => {
			lottie.destroy(animItem.name);
		};
	});

	return (
		<div ref={containerRef} />
	);
};

export default AnimatedGraphic;
