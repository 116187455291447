export type Type = {
	width: string
	inputWidth: string
};

const common: Type = {
	width: '320px',
    inputWidth: '256px',
};

export const web: Type = {
	...common
};

export const tablet: Type = {
	width: '416px',
    inputWidth: '320px',
};